import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 9.5 6 A 1.50015 1.50015 0 1 0 9.5 9 L 10 9 L 10 11 C 10 16.929898 13.772437 21.88495 19 23.917969 L 19 24.082031 C 13.772437 26.11505 10 31.070102 10 37 L 10 39 L 9.5 39 A 1.50015 1.50015 0 1 0 9.5 42 L 11.5 42 L 36.5 42 L 38.5 42 A 1.50015 1.50015 0 1 0 38.5 39 L 38 39 L 38 37 C 38 31.070102 34.227563 26.11505 29 24.082031 L 29 23.917969 C 34.227563 21.88495 38 16.929898 38 11 L 38 9 L 38.5 9 A 1.50015 1.50015 0 1 0 38.5 6 L 36.5 6 L 11.5 6 L 9.5 6 z M 13 9 L 35 9 L 35 11 C 35 16.018458 31.650951 20.222469 27.080078 21.554688 A 1.50015 1.50015 0 0 0 26 22.994141 L 26 25.005859 A 1.50015 1.50015 0 0 0 27.080078 26.445312 C 31.650951 27.777531 35 31.981542 35 37 L 35 39 L 31.349609 39 C 30.654699 35.57668 27.628336 33 24 33 C 20.371664 33 17.345301 35.57668 16.650391 39 L 13 39 L 13 37 C 13 31.981542 16.349049 27.777531 20.919922 26.445312 A 1.50015 1.50015 0 0 0 22 25.005859 L 22 22.994141 A 1.50015 1.50015 0 0 0 20.919922 21.554688 C 16.349049 20.222469 13 16.018458 13 11 L 13 9 z M 20 16 C 20 18.209 21.791 20 24 20 C 26.209 20 28 18.209 28 16 L 20 16 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
