import moment from 'moment';
import { FunctionComponent } from 'react';

export interface IProps {
  order: any;
  handleRowClick: () => void;
}

const ScansRow: FunctionComponent<IProps> = (props) => {
  var order = props.order;

  return (
    <div>
      <div style={{ padding: '10px 15px' }} className="field route" onClick={props.handleRowClick}>
        <div className="row col">
          <div className="col-sm-6">
            <div>
              <span style={{ opacity: '0.7' }}>Order #{order.OrderId}</span> for {order.AttendeeName}
            </div>
          </div>
          <div className="col-sm-6" style={{ textAlign: 'right' }}>
            <div style={{ opacity: '0.7' }}>
              {order.ScannedTicketCount} of {order.TicketCount} tickets scanned
            </div>
          </div>
        </div>
      </div>
      {order.Tickets.map((ticket, index) => {
        var timestamp = moment(ticket.DateScanned).fromNow();
        if (ticket.DateScanned != null) {
          var duration = moment.duration(moment().diff(moment(ticket.DateScanned)));
          var seconds = duration.asSeconds();

          if (seconds < 5) {
            timestamp = 'just now';
          }
        }

        var ticketRow = (
          <div key={`TicketRow_${props.order.OrderId}_${ticket.GroupName + ticket.SeatName}_${index}`} className="field info" style={{ opacity: ticket.DateScanned == null ? '0.5' : '1' }}>
            <div className="row col">
              <div className="col-xs-2">
                <label>{ticket.GroupName + ticket.SeatName}</label>
              </div>
              <div className="col-xs-10" style={{ textAlign: 'right' }}>
                {ticket.DateScanned == null ? null : (
                  <div>
                    Scanned by <span style={{ fontWeight: 'bold' }}>{ticket.ScannedBy}</span> {timestamp}
                  </div>
                )}
              </div>
            </div>
          </div>
        );

        return ticketRow;
      })}
    </div>
  );
};

export default ScansRow;
