import { IRequest } from '../interfaces/IRequest';

export enum RequestState {
  Awaiting,
  Refusing,
  Refused,
  Accepting,
  Accepted,
}

export default class RequestHelper {
  public static getState(request: IRequest) {
    if (request.OrderId) return RequestState.Accepted;
    if (request.RefusedDate) return RequestState.Refused;
    return RequestState.Awaiting;
  }
}
