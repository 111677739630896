import React from 'react';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import { ITour } from '../../../interfaces/ITour';
import { IImageUploadRequest } from '../ImageUploader';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  tour: ITour;
  onTourUpdated: (e: ITour) => void;
  onImageRequested: (request: IImageUploadRequest) => void;
  imageRequests: { [key: string]: IImageUploadRequest };
  globalOptions: JSX.Element;
  setBusyMessage: (value: string) => void;
}

const MarketingSection: React.FC<IProps> = (props) => {
  const { tour, onTourUpdated, imageRequests, onImageRequested } = props;

  if (tour.TourTag == null) {
    tour.TourTag = '';
  }

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Marketing</h2>
            <div className="section event-information">
              <div className="section">
                <InfoLabel
                  text="Do you have a Facebook Ads Pixel ID?"
                  tooltip="Visit Facebooks Business documentation on creating your own event pixel. This can then be used to track throuoghput from your Facebook Ads to Seaty. We will tell Facebook whenever somebody buys tickets for your events to help you better target your audience."
                />
                <InfoInput labelText="Pixel ID" onChange={(value) => onTourUpdated({ ...tour, FacebookPixelId: value })} value={tour.FacebookPixelId} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="info">
            Visit the <strong>Facebook Business</strong> documentation on creating your own event pixel. <br />
            <br />
            This can be used to track throuoghput from your Facebook Ads to Seaty. We will tell Facebook whenever somebody buys tickets for your events to help you better target your audience.
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingSection;
