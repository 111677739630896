import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import DateHelper from '../../../../helpers/DateHelper';
import RequestHelper, { RequestState } from '../../../../helpers/RequestHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import { IRequest } from '../../../../interfaces/IRequest';
import RequestModal from './RequestModal';
import './Requests.scss';
import linq from 'linq';

interface IRowProps {
  event: IEvent;
  request: IRequest;
  onClick: (request: IRequest) => void;
}

const AdminRequestsRow: React.FC<IRowProps> = ({ event, request, onClick }) => {
  const state = RequestHelper.getState(request);

  return (
    <tr onClick={() => onClick(request)} className="route" key={request.Id}>
      <td>
        {state == RequestState.Accepted && <div className="good">Accepted</div>}
        {state == RequestState.Refused && <div className="bad">Refused</div>}
        {state == RequestState.Awaiting && <div className="default">Pending</div>}
      </td>
      <td>
        {request.RequestedByName != null && request.RequestedByName.length > 1 ? <span>{request.RequestedByName} </span> : null}
        <div style={{ opacity: '0.7' }}>{request.RequestedByEmail}</div>
      </td>
      <td>
        <div>{request.TotalCount} tickets for</div>
        <div>{DateHelper.asDateAtTimeAmPm(event.Dates.filter((d) => d.Id === request.EventDateId)[0].Date)}</div>
      </td>
    </tr>
  );
};

interface IProps {
  event: IEvent;
}

const AdminRequests: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const { event } = props;
  const eventTag = event.EventTag;

  useEffect(() => {
    loadData(false);
  }, []);

  const loadData = (silent) => {
    setLoading(!silent);
    AdminApi.request('GET', `/api/Requests?eventId=${event.Id}&showAll=true`).then((result) => {
      setResponse(result);
      setLoading(false);
    });
  };

  if (loading || !event || !event.Dates || event.Dates.length == 0) return <Loader inline>Loading requests...</Loader>;

  const requests = response && response.Requests && response.Requests.length > 0 ? response.Requests : [];

  const pendingRequests = linq
    .from(requests)
    .where((r) => RequestHelper.getState(r) == RequestState.Awaiting)
    .toArray();
  const acceptedRequests = linq
    .from(requests)
    .where((r) => RequestHelper.getState(r) != RequestState.Awaiting)
    .toArray();

  return (
    <>
      <Helmet>
        <title>#{eventTag} Requests</title>
        <meta name="description" content={`Manage requests for your event.`} />
      </Helmet>

      {selectedRequest && (
        <RequestModal
          event={event}
          request={selectedRequest}
          onClose={() => {
            setSelectedRequest(null);
            loadData(true);
          }}
        />
      )}

      {requests.length == 0 ? (
        <p>There are no ticket requests for this event.</p>
      ) : (
        <>
          {pendingRequests.length > 0 && (
            <>
              <div className="toolbar">
                <div className="title">Pending Requests</div>
              </div>
              <div className="orderoverview" style={{ paddingBottom: '30px' }}>
                <div className="container-outer" style={{ minHeight: '300px' }}>
                  <div className="container-inner" style={{ backgroundColor: 'white' }}>
                    <table className="table grid-table">
                      <thead>
                        <tr>
                          <th style={{ width: '100px' }} className="grid-header">
                            Status
                          </th>
                          <th className="grid-header">Requested by</th>
                          <th className="grid-header">Tickets</th>
                        </tr>
                      </thead>

                      <tbody className="smooth-details">
                        {pendingRequests.map((request: IRequest) => (
                          <AdminRequestsRow onClick={(r) => setSelectedRequest(r)} key={`Request_${request.Id}`} event={event} request={request} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}

          {acceptedRequests.length > 0 && (
            <>
              <div className="toolbar">
                <div className="title">Actioned Requests</div>
              </div>
              <div className="orderoverview" style={{ paddingBottom: '100px' }}>
                <div className="container-outer" style={{ minHeight: '300px' }}>
                  <div className="container-inner" style={{ backgroundColor: 'white' }}>
                    <table className="table grid-table">
                      <thead>
                        <tr>
                          <th style={{ width: '100px' }} className="grid-header">
                            Status
                          </th>
                          <th className="grid-header">Requested by</th>
                          <th className="grid-header">Tickets</th>
                        </tr>
                      </thead>

                      <tbody className="smooth-details">
                        {acceptedRequests.map((request: IRequest) => (
                          <AdminRequestsRow onClick={(r) => setSelectedRequest(r)} key={`Request_${request.Id}`} event={event} request={request} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdminRequests;
