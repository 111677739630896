import linq from 'linq';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import AdminApi from '../../../../../api/AdminApi';
import Loader from '../../../../../components/Loader';
import CurrencyHelper from '../../../../../helpers/CurrencyHelper';
import SVGHelper from '../../../../../helpers/SVGHelper';
import UserHelper from '../../../../../helpers/UserHelper';
import { IOrganisation } from '../../../../../interfaces/IOrganisation';
import { IStatementRow } from './IStatementRow';
import './StatementPage.scss';
import StatementTotalsTable from './StatementTotalsTable';
import { IEvent } from '../../../../../interfaces/IEvent';

interface IProps {
  ticketGuid?: string;
  organisation: IOrganisation;
  monthString?: string;
  event?: IEvent;
  showTransactions: boolean;
  includeNoneOnlineOrders?: boolean;
}

const AdminStatement: React.FC<IProps> = (props) => {
  const { organisation, monthString, showTransactions, event, includeNoneOnlineOrders } = props;
  const organisationTag = organisation.OrganisationTag;

  const invoicePeriod = monthString && moment.utc(monthString);

  const [busyMessage, setBusyMessage] = useState('Loading Statement...');
  const [ledgerPurchases, setLedgerPurchases] = useState<Array<IStatementRow>>([]);
  const [ledgerRefunds, setLedgerRefunds] = useState<Array<IStatementRow>>([]);
  const [ledgerOrders, setLedgerOrders] = useState<Array<IStatementRow>>([]);

  useEffect(() => {
    setBusyMessage('Getting data...');

    Promise.all([
      AdminApi.request('GET', `/api/LedgerPurchase?eventId=${event ? event.Id : ''}&date=${monthString ? monthString : ''}&organisationTag=${organisationTag}`).then(
        (purchasesResponse: Array<IStatementRow>) => {
          setLedgerPurchases(purchasesResponse ? purchasesResponse : []);
        },
      ),
      AdminApi.request('GET', `/api/LedgerRefund?eventId=${event ? event.Id : ''}&date=${monthString ? monthString : ''}&organisationTag=${organisationTag}`).then(
        (refundsResponse: Array<IStatementRow>) => {
          setLedgerRefunds(refundsResponse ? refundsResponse : []);
        },
      ),
      includeNoneOnlineOrders
        ? AdminApi.request('GET', `/api/LedgerAdminOrder?eventId=${event ? event.Id : ''}&date=${monthString ? monthString : ''}&organisationTag=${organisationTag}`).then(
            (ordersResponse: Array<IStatementRow>) => {
              setLedgerOrders(ordersResponse ? ordersResponse : []);
            },
          )
        : Promise.resolve(),
    ]).finally(() => {
      setBusyMessage(null);
    });
  }, [monthString, event, includeNoneOnlineOrders]);

  if (busyMessage) return <Loader inline>{busyMessage}</Loader>;

  if (busyMessage || !ledgerPurchases || !ledgerRefunds || !ledgerOrders || !organisation) return null;

  let eventGroups = linq
    .from(ledgerPurchases)
    .concat(ledgerRefunds)
    .concat(ledgerOrders)
    .groupBy((l) => l.EventId);

  eventGroups = eventGroups.orderBy((e) => e.first().EventName).thenBy((e) => e.first().EventTag);

  return (
    <div className="statement-page">
      <Helmet>
        {event ? (
          <>
            <title>#{event.EventTag} Statement</title>
            <meta name="description" content={`Event statement.`} />
          </>
        ) : (
          <>
            <title>
              #{organisation.OrganisationTag} {invoicePeriod.format('MMMM YYYY')} Statement
            </title>
            <meta name="description" content={`Statement for the month of ${invoicePeriod.format('MMMM YYYY')}.`} />
          </>
        )}
      </Helmet>

      <div className="container-outer" style={{ margin: '0' }}>
        <div className="container-inner">
          <div className="section-to-print">
            <div className="summary">
              <img style={{ width: '275px', marginBottom: '30px' }} alt="Seaty.co.uk" src={SVGHelper.get('Seaty_LetsTalkTickets')} />

              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%' }}>
                      <p>
                        <span style={{ fontWeight: 900 }}>Date generated</span>
                        <br />
                        {moment().format('dddd Do MMMM YYYY')}
                        <br />
                      </p>
                      <br />
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <span style={{ fontWeight: 900 }}>Generated by</span>
                      <br />
                      {UserHelper.currentUser.Name}
                      <br />
                      <span style={{ fontWeight: 900 }}>Organisation</span>
                      <br />
                      {organisation.Name}
                      <br />
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
              <br />

              <StatementTotalsTable
                invoicePeriod={event ? '' : invoicePeriod.format('MMMM YYYY')}
                organisation={organisation}
                ledgerOrders={ledgerOrders}
                ledgerPurchases={ledgerPurchases}
                ledgerRefunds={ledgerRefunds}
                showVAT={false}
              />

              <br />
              <br />

              <p style={{ fontStyle: 'italic' }}>All Seaty fees are collected automatically at the time of ticket purchase.</p>

              <br />

              <p style={{ fontSize: '12px' }}>All purchases in this statement were placed in agreement with terms of service found at http://Seaty.co.uk/Docs/TermsOfService.</p>

              <br />
              <br />
              <div className="pagebreak"></div>

              {eventGroups.select((eventData) => {
                const anyConnect = eventData.any((e) => e.StripeConnect);

                return (
                  <React.Fragment key={eventData.first().EventId}>
                    <p>
                      <div style={{ fontWeight: 900 }}>{eventData.first().EventName}</div>
                      <Link to={`/${eventData.first().EventTag}`} style={{ fontWeight: 400 }}>
                        #{eventData.first().EventTag}
                      </Link>
                    </p>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <th style={{ width: '10%' }}>{showTransactions && `Order #`}</th>
                          <th style={{ width: '15%' }}>{showTransactions && `Date`}</th>
                          <th style={{ width: '10%' }}>{showTransactions && `Type`}</th>
                          <th style={{ width: '10%', textAlign: 'right' }}>Amount</th>
                          {anyConnect && <th style={{ width: '10%', textAlign: 'right' }}>Stripe fee</th>}
                          <th style={{ width: '10%', textAlign: 'right' }}>Seaty Fee</th>
                        </tr>

                        {showTransactions &&
                          eventData
                            .orderBy((e) => e.DateCompleted)
                            .toArray()
                            .map((o) => {
                              return (
                                <tr key={o.OrderId}>
                                  <td>{o.OrderId}</td>
                                  <td>{moment(o.RefundAmount && o.RefundAmount > 0 ? o.RefundDate : o.DateCompleted).format('D MMM YYYY HH:mm')}</td>
                                  {o.RefundId ? (
                                    <td>Refund</td>
                                  ) : o.PurchaseId && o.PurchaseId > 0 ? (
                                    o.OrderId ? (
                                      <td>
                                        Purchase
                                        {/* {o.AbsorbFee ? 'Absorbed' : 'Passed'} */}
                                      </td>
                                    ) : (
                                      <td>
                                        Balance Payment
                                        {/* {o.AbsorbFee ? 'Absorbed' : 'Passed'} */}
                                      </td>
                                    )
                                  ) : (
                                    <td>Order</td>
                                  )}
                                  <td style={{ textAlign: 'right' }}>
                                    {o.RefundAmount && o.RefundAmount > 0
                                      ? `-${CurrencyHelper.formatCurrency(o.CurrencySymbol, o.RefundAmount - (!o.AbsorbFee ? o.GatewayFeeRefunded + o.SeatyFeeGBPRefunded : 0))}`
                                      : CurrencyHelper.formatCurrency(o.CurrencySymbol, o.TotalPrice)}
                                  </td>

                                  {o.StripeConnect ? (
                                    <td style={{ textAlign: 'right' }}>
                                      {o.RefundAmount && o.RefundAmount > 0
                                        ? `-${CurrencyHelper.formatCurrency(o.CurrencySymbol, o.GatewayFeeRefunded)}`
                                        : CurrencyHelper.formatCurrency(o.CurrencySymbol, o.GatewayFee)}
                                    </td>
                                  ) : anyConnect ? (
                                    <td></td>
                                  ) : null}

                                  <td style={{ textAlign: 'right' }}>
                                    {o.RefundAmount && o.RefundAmount > 0
                                      ? `-${CurrencyHelper.formatCurrency(o.CurrencySymbol, o.StripeConnect ? o.SeatyFeeRefunded : o.SeatyFeeRefunded + o.GatewayFeeRefunded)}`
                                      : CurrencyHelper.formatCurrency(o.CurrencySymbol, o.StripeConnect ? o.SeatyFee : o.SeatyFee + o.GatewayFee)}
                                  </td>
                                </tr>
                              );
                            })}

                        <tr>
                          <td colSpan={4} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                            {CurrencyHelper.formatCurrency(
                              eventData.first().CurrencySymbol,
                              linq
                                .from(eventData)
                                .sum((o) => (o.RefundAmount && o.RefundAmount > 0 ? 0 - o.RefundAmount + (!o.AbsorbFee ? o.GatewayFeeRefunded + o.SeatyFeeGBPRefunded : 0) : o.TotalPrice)),
                            )}
                          </td>

                          {anyConnect && (
                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                              {CurrencyHelper.formatCurrency(
                                eventData.first().CurrencySymbol,
                                linq.from(eventData).sum((o) => (o.StripeConnect ? (o.RefundAmount && o.RefundAmount > 0 ? -o.GatewayFeeRefunded : o.GatewayFee) : 0)),
                              )}
                            </td>
                          )}

                          <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                            {CurrencyHelper.formatCurrency(
                              eventData.first().CurrencySymbol,
                              linq
                                .from(eventData)
                                .sum((o) =>
                                  o.StripeConnect
                                    ? o.RefundAmount && o.RefundAmount > 0
                                      ? 0 - o.SeatyFeeRefunded
                                      : o.SeatyFee
                                    : o.RefundAmount && o.RefundAmount > 0
                                      ? 0 - (o.SeatyFeeRefunded + o.GatewayFeeRefunded)
                                      : o.SeatyFee + o.GatewayFee,
                                ),
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <br />
                    <br />
                  </React.Fragment>
                );
              })}

              <br />

              <p style={{ fontSize: '12px' }}>All purchases in this statement were placed in agreement with terms of service found at http://Seaty.co.uk/Docs/TermsOfService.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminStatement;
