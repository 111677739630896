import React, { FunctionComponent, useEffect, useState } from 'react';
import GeneralApi from '../api/GeneralApi';
import Button from '../components/Button';
import BlockInfo, { InfoType } from '../components/BlockInfo';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import SolidLine from '../components/SolidLine';
import SpacerTable from '../components/SpacerTable';
import GAHelper from '../helpers/GAHelper';
import LocationHelper from '../helpers/LocationHelper';
import ThemeHelper from '../helpers/ThemeHelper';

export interface IProps {
  onClose: () => void;
}

const VerifyUserModal = ((props) => {
  const [busyMessage, setBusyMessage] = useState(null);
  const [verifyComplete, setVerifyComplete] = useState(false);
  const [guid] = useState(LocationHelper.getQueryVariable('verifyGuid'));

  useEffect(() => {
    GAHelper.modal(`Verify User`);
  }, []);

  const verifyAccount = () => {
    setBusyMessage('Verifying account...');

    GeneralApi.request('POST', `/api/Verify`, {
      guid: guid,
    })
      .then((value) => {
        setBusyMessage(null);
        if (value) {
          setVerifyComplete(true);
        }
      })
      .catch((message) => {
        setBusyMessage(null);
      });
  };

  useEffect(() => {
    verifyAccount();
  }, []);

  return (
    <>
      <Modal theme={ThemeHelper.getDefaultTheme()} onCloseClick={props.onClose}>
        <div className="content">
          <div className="ticket-rip" />

          <div className="body">
            {busyMessage ? (
              <Loader inline={true}>{busyMessage}</Loader>
            ) : (
              <>
                <table className="blocks">
                  <tbody>
                    <BlockInfo type={InfoType.Info}> All done, thank you for verifying your account!</BlockInfo>
                  </tbody>
                </table>

                <div className="spacer-x2" />

                {verifyComplete ? (
                  <SpacerTable>
                    <Button className="large" onExecute={props.onClose} text={`Close`} />
                  </SpacerTable>
                ) : (
                  <SpacerTable>
                    <Button className="large" onExecute={props.onClose} text={`Close`} />
                  </SpacerTable>
                )}
              </>
            )}
            <SolidLine />

            <SpacerTable className="small-font">
              All accounts are subject to the Seaty <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
            </SpacerTable>
          </div>

          <div className="ticket-rip bottom" />
        </div>
      </Modal>
    </>
  );
}) as FunctionComponent<IProps>;

export { VerifyUserModal };
