export default class JsonFileHelper {
  public static download(url: string): Promise<any> {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'json';
      xhr.onload = () => {
        var status = xhr.status;
        if (status === 200) {
          return resolve(xhr.response);
        } else {
          return reject(null);
        }
      };
      xhr.send();
    });
  }
}
