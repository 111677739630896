import React from 'react';
import { Link } from 'react-router-dom';
import LocationHelper from '../../../../helpers/LocationHelper';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import { AdminSection } from '../../AdminSections';
import SideMenu from '../../../../components/SideMenu';
import SVGOutlineMembership from '../../../../svg/outline/SVGOutlineMembership';
import SVGOutlineMembershipType from '../../../../svg/outline/SVGOutlineMembershipType';

interface IProps {
  organisation: IOrganisation;
}

const AdminMemberMenu: React.FC<IProps> = (props) => {
  const urlDetails = LocationHelper.getLocation().pathname;
  const section = urlDetails.split('/')[3];

  return (
    <SideMenu>
      <Link className={`has-image ${section == AdminSection.Members ? 'selected' : ''}`} to={`/Organisation/${props.organisation.OrganisationTag}/${AdminSection.Members}`}>
        <SVGOutlineMembership />
        Members
      </Link>
      <Link className={`has-image ${section == AdminSection.MemberTypes ? 'selected' : ''}`} to={`/Organisation/${props.organisation.OrganisationTag}/${AdminSection.MemberTypes}`}>
        <SVGOutlineMembershipType /> Member Types
      </Link>
    </SideMenu>
  );
};

export default AdminMemberMenu;
