import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="48px" height="48px">
      <path d="M 32 8.6425781 C 31.627 8.6425781 31.254766 8.8109844 31.009766 9.1464844 L 25.238281 17.048828 C 24.646281 17.859828 25.224516 19 26.228516 19 L 30 19 L 30 30 L 19 30 L 19 26.228516 C 19 25.224516 17.859828 24.645328 17.048828 25.236328 L 9.1464844 31.007812 C 8.4754844 31.497812 8.4754844 32.500234 9.1464844 32.990234 L 17.048828 38.761719 C 17.859828 39.353719 19 38.773531 19 37.769531 L 19 34 L 30 34 L 30 45 L 26.228516 45 C 25.224516 45 24.645328 46.140172 25.236328 46.951172 L 31.007812 54.853516 C 31.497812 55.524516 32.500234 55.524516 32.990234 54.853516 L 38.761719 46.951172 C 39.353719 46.140172 38.773531 45 37.769531 45 L 34 45 L 34 34 L 45 34 L 45 37.771484 C 45 38.775484 46.140172 39.353719 46.951172 38.761719 L 54.853516 32.990234 C 55.524516 32.500234 55.524516 31.499766 54.853516 31.009766 L 46.951172 25.238281 C 46.140172 24.646281 45 25.224516 45 26.228516 L 45 30 L 34 30 L 34 19 L 37.771484 19 C 38.775484 19 39.353719 17.859828 38.761719 17.048828 L 32.990234 9.1464844 C 32.745234 8.8109844 32.373 8.6425781 32 8.6425781 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
