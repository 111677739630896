import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64">
      <path
        d="M32.2,3.6c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S38.9,3.6,32.2,3.6z M32.2,6.2c5.1,0,9.3,4.2,9.3,9.3
		c0,2.2-0.7,4.1-2,5.7c-0.7-1.6-1.9-2.9-3.4-3.8c0.9-1,1.5-2.3,1.5-3.7c0-2.9-2.4-5.3-5.3-5.3S27,10.9,27,13.8c0,1.4,0.6,2.7,1.5,3.7
		c-0.7,0.4-1.3,0.8-1.8,1.3c-0.7,0.7-1.3,1.6-1.7,2.6c-1.3-1.6-2.1-3.6-2.1-5.8C22.9,10.4,27.1,6.2,32.2,6.2z M32.3,11.2
		c1.5,0,2.7,1.2,2.7,2.7s-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7S30.9,11.2,32.3,11.2z M32.3,19.2c2.5,0,4.6,1.7,5.2,4.1
		c-1.5,1-3.3,1.6-5.2,1.6c-1.9,0-3.6-0.6-5.1-1.5c0.2-1,0.7-1.9,1.4-2.6C29.6,19.7,30.9,19.2,32.3,19.2z"
      />
      <g>
        <path d="M19.3,20.8L18,19.1c-4.4,3.4-7.6,8.4-8.7,14l2.1,0.4C12.4,28.3,15.2,23.8,19.3,20.8z" />
        <path
          d="M40.7,56.4c-2.7,1.2-5.6,1.9-8.8,1.9c-3.1,0-6.1-0.7-8.8-1.9l-0.9,1.9c2.9,1.4,6.2,2.1,9.7,2.1c3.4,0,6.7-0.8,9.7-2.1
			L40.7,56.4z"
        />
        <path d="M46.3,19.4L45,21c3.8,3.1,6.6,7.4,7.5,12.5l2.1-0.4C53.5,27.6,50.5,22.7,46.3,19.4z" />
      </g>
      <path
        d="M50.4,34.8c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S57,34.8,50.4,34.8z M50.4,37.4c5.1,0,9.3,4.2,9.3,9.3
		c0,2.2-0.7,4.1-2,5.7c-0.7-1.6-1.9-2.9-3.4-3.8c0.9-1,1.5-2.3,1.5-3.7c0-2.9-2.4-5.3-5.3-5.3s-5.3,2.4-5.3,5.3
		c0,1.4,0.6,2.7,1.5,3.7c-0.7,0.4-1.3,0.8-1.8,1.3c-0.7,0.7-1.3,1.6-1.7,2.6c-1.3-1.6-2.1-3.6-2.1-5.8C41.1,41.6,45.2,37.4,50.4,37.4
		z M50.5,42.4c1.5,0,2.7,1.2,2.7,2.7c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7C47.8,43.6,49,42.4,50.5,42.4z M50.5,50.4
		c2.5,0,4.6,1.7,5.2,4.1c-1.5,1-3.3,1.6-5.2,1.6c-1.9,0-3.6-0.6-5.1-1.5c0.2-1,0.7-1.9,1.4-2.6C47.7,50.9,49.1,50.4,50.5,50.4z"
      />
      <path
        d="M13.6,34.8c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S20.2,34.8,13.6,34.8z M13.6,37.4c5.1,0,9.3,4.2,9.3,9.3
		c0,2.2-0.7,4.1-2,5.7c-0.7-1.6-1.9-2.9-3.4-3.8c0.9-1,1.5-2.3,1.5-3.7c0-2.9-2.4-5.3-5.3-5.3s-5.3,2.4-5.3,5.3
		c0,1.4,0.6,2.7,1.5,3.7c-0.7,0.4-1.3,0.8-1.8,1.3c-0.7,0.7-1.3,1.6-1.7,2.6C5.1,51,4.3,49,4.3,46.8C4.3,41.6,8.5,37.4,13.6,37.4z
		 M13.7,42.4c1.5,0,2.7,1.2,2.7,2.7c0,1.5-1.2,2.7-2.7,2.7S11,46.5,11,45.1C11,43.6,12.2,42.4,13.7,42.4z M13.7,50.4
		c2.5,0,4.6,1.7,5.2,4.1c-1.5,1-3.3,1.6-5.2,1.6c-1.9,0-3.6-0.6-5.1-1.5c0.2-1,0.7-1.9,1.4-2.6C10.9,50.9,12.3,50.4,13.7,50.4z"
      />
    </svg>
  );
}) as FunctionComponent<IProps>;
