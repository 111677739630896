import GeneralApi from '../api/GeneralApi';
import EnvironmentVariables from '../EnvironmentVariables';
import { IOrganisation } from '../interfaces/IOrganisation';
import { IUser } from '../interfaces/IUser';
import Constants from './Constants';
import JWTHelper from './JWTHelper';

export default class UserHelper {
  public static currentUser: IUser = null;
  public static guestUser: IUser = null;

  public static isCurrentUserAdmin(organisation: IOrganisation): boolean {
    if (!UserHelper.currentUser || !UserHelper.currentUser.Guid) return false;
    if (UserHelper.currentUser.Id === 1) return true;
    if (!organisation || !organisation.Roles) return false;
    return organisation.Roles.filter((id) => id === UserHelper.currentUser.Guid).length > 0;
  }

  public static getSessionId(): string {
    if (UserHelper.currentUser) {
      return UserHelper.currentUser.SessionId;
    } else if (UserHelper.guestUser) {
      return UserHelper.guestUser.SessionId;
    } else {
      UserHelper.getGuestToken();
    }
  }

  public static load(): Promise<IUser> {
    let token = localStorage.getItem(Constants.SeatyUserVar);
    if (token) {
      const user = JWTHelper.parseJwt(token);

      if (!user) {
        localStorage.removeItem(Constants.SeatyUserVar);
        token = null;
      }
    }

    if (token) {
      const user = JWTHelper.parseJwt(token);

      if (!user) return null;
      user.Token = token;
      user.Guest = (user as any).IsGuest == 'true';

      if (user.Guest) {
        UserHelper.guestUser = user;
      } else {
        user.Id = parseInt((user as any).UserId);
        user.Events = JSON.parse(user.Events as any);
        user.Organisations = user.Organisations ? JSON.parse(user.Organisations as any) : [];
        user.OrganisationTags = user.OrganisationTags ? JSON.parse(user.OrganisationTags as any) : [];
        user.Tours = user.Tours ? JSON.parse(user.Tours as any) : [];
        UserHelper.currentUser = user;
      }

      return Promise.resolve(user);
    } else {
      return UserHelper.getGuestToken();
    }
  }

  public static getGuestToken(): Promise<IUser> {
    return fetch(
      `${EnvironmentVariables.GENERAL_API_URL}/api/Guest?sessionId=${
        UserHelper.guestUser && UserHelper.guestUser.SessionId && UserHelper.guestUser.SessionId.length > 0 ? UserHelper.guestUser.SessionId : ''
      }`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((guest: any) => {
        const user = JWTHelper.parseJwt(guest);
        localStorage.removeItem(Constants.SeatyUserVar);
        localStorage.setItem(Constants.SeatyUserVar, guest);
        user.Token = guest;
        user.Guest = (user as any).IsGuest == 'true';
        UserHelper.guestUser = user;
        return user;
      });
  }

  public static refreshUserToken(): Promise<any> {
    if (this.currentUser.Guest) return;

    return GeneralApi.request('GET', '/api/Login').then((result) => {
      localStorage.setItem(Constants.SeatyUserVar, result);
      UserHelper.load();

      return result;
    });
  }

  public static requestRegisterAuthCode(email: string, password: string, firstName: string, secondName: string): Promise<any> {
    return GeneralApi.request(
      'POST',
      '/api/RegisterAuthCode',
      {
        email: email,
        password: password,
        firstName: firstName,
        secondName: secondName,
      },
      true,
    ).then((result) => {
      return result;
    });
  }

  public static requestSignInAuthCode(email: string, password: string): Promise<any> {
    return GeneralApi.request(
      'POST',
      '/api/LoginAuthCode',
      {
        email: email,
        password: password,
      },
      true,
    ).then((result) => {
      return result;
    });
  }

  public static signIn(email: string, password: string, authCode: string): Promise<any> {
    return GeneralApi.request(
      'POST',
      '/api/Login',
      {
        email: email,
        password: password,
        authCode: authCode,
      },
      true,
    ).then((result) => {
      result.Guest = false;

      localStorage.setItem(Constants.SeatyUserVar, result.token);
      UserHelper.load();

      return result;
    });
  }
}
