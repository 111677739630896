import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px">
      <path d="M50,10c-22.093,0-40,17.91-40,40s17.907,40,40,40c22.09,0,40-17.91,40-40S72.09,10,50,10z M30,56.667	c-3.685,0-6.667-2.985-6.667-6.667c0-3.679,2.982-6.667,6.667-6.667c3.682,0,6.667,2.988,6.667,6.667	C36.667,53.682,33.682,56.667,30,56.667z M50,56.667c-3.685,0-6.667-2.985-6.667-6.667c0-3.679,2.982-6.667,6.667-6.667	c3.682,0,6.667,2.988,6.667,6.667C56.667,53.682,53.682,56.667,50,56.667z M70,56.667c-3.685,0-6.667-2.985-6.667-6.667	c0-3.679,2.982-6.667,6.667-6.667c3.682,0,6.667,2.988,6.667,6.667C76.667,53.682,73.682,56.667,70,56.667z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
