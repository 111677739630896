import linq from 'linq';
import React, { useState } from 'react';
import Dropdown from '../../../components/Dropdown';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import { QuestionTypes } from '../../../enums/QuestionTypes';
import GuidHelper from '../../../helpers/GuidHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IQuestion } from '../../../interfaces/IQuestion';
import QuestionCriteria from './QuestionCriteria';
import SVGDown from '../../../svg/SVGDown';
import SVGTrash from '../../../svg/SVGTrash';
import { IDropDownItem } from '../../../components/DropdownItem';

interface IProps {
  question: IQuestion;
  event: IEvent;
  onEventUpdated: () => void;
  handleDeleteClick: (question: IQuestion) => void;
}

const Question: React.FC<IProps> = (props) => {
  const { question, event, onEventUpdated } = props;
  const [expanded, setExpanded] = useState(!question.Id || question.Id <= 0);

  const getEventTickets = () => {
    var tickets = [];

    event.AllocatedCategoryGroups.forEach((group) => {
      tickets = tickets.concat(group.Categories);
    });

    event.UnallocatedCategoryGroups.forEach((group) => {
      tickets = tickets.concat(group.Categories);
    });

    return tickets;
  };

  if (question.QuestionType == null) {
    question.QuestionType = 0;
  }

  var criterias = question.Criteria;
  if (criterias == null) {
    criterias = [];
  }

  var tickets = getEventTickets();

  if (question.Criteria == null) {
    question.Criteria = [];
  }

  if (question.Answers == null) {
    question.Answers = [];
  }

  const ticketsNotInCriteria = tickets.filter((t) => criterias.filter((c) => c.Guid === t.Guid).length === 0);

  return (
    <>
      <div className="expandable-row">
        <div className="main-area flex_from_left">
          <div className="info">
            <strong>{question.Name.length == 0 ? 'Question' : question.Name}</strong>
          </div>
        </div>
        <div className="flex_from_right">
          <button style={{ marginLeft: '5px' }} className={`admin-button icon ${expanded ? 'flip' : ''}`} onClick={() => setExpanded(!expanded)}>
            <SVGDown />
          </button>

          <button style={{ marginLeft: '5px' }} className="admin-button icon bad" onClick={() => props.handleDeleteClick(question)}>
            <SVGTrash />
          </button>
        </div>
      </div>

      {expanded && (
        <>
          <div className="field">
            <div className="row">
              <div className="col-sm-7">
                <InfoLabel
                  text="What is your question?"
                  tooltip="This questions will be asked to your attendees BEFORE they complete an order. Try to keep questions to the point and only use them when necessary, the more you ask and the more complicated the checkout process is, the less likely people are to go through will a purchase!"
                ></InfoLabel>

                <InfoInput
                  autoFocus={true}
                  labelText="Question"
                  onChange={(value) => {
                    question.Name = value;
                    onEventUpdated();
                  }}
                  value={question.Name}
                />
              </div>
              <div className="col-sm-3 col-sm-offset-2" style={{ paddingTop: '4px' }}></div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="section">
                  {criterias.length > 0 ? (
                    <InfoLabel text="Question criteria:" tooltip="This criteria outlines which tickets this question will be asked for."></InfoLabel>
                  ) : (
                    <InfoLabel
                      text="No specific tickets are required to activate this question, all attendees will be asked this question for each order (not per ticket)."
                      tooltip="With no criteria your question will be asked only once for each whole order. If you have a specific ticket criteria, the question will be asked for each ticket in the criteria."
                    ></InfoLabel>
                  )}
                  {criterias.map((criteria) => (
                    <QuestionCriteria
                      key={`QuestionCriteria_${criteria.Guid}`}
                      onCriteriaUpdated={() => onEventUpdated()}
                      onRemoveClick={() => {
                        question.Criteria = question.Criteria.filter((c) => c.Guid !== criteria.Guid);
                        onEventUpdated();
                      }}
                      currency={event.CurrencySymbol}
                      tickets={linq
                        .from(ticketsNotInCriteria.concat(tickets.filter((t) => t.Guid === criteria.Guid)))
                        .orderBy((t) => t.Name)
                        .toArray()}
                      criteria={criteria}
                    />
                  ))}
                </div>
              </div>
              <div className="col-sm-3" style={{ paddingBottom: '15px' }}>
                {ticketsNotInCriteria.length > 0 && (
                  <button
                    className="admin-button confirm"
                    onClick={() => {
                      var ticket = linq
                        .from(getEventTickets())
                        .where(
                          (t) =>
                            linq
                              .from(question.Criteria)
                              .where((c) => c.Guid == t.Guid)
                              .firstOrDefault() == null,
                        )
                        .firstOrDefault();

                      question.Criteria.push({ Guid: ticket.Guid });
                      onEventUpdated();
                    }}
                  >
                    Add Criteria
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="field">
            <InfoLabel text="Is this question mandatory?" tooltip="If this question is mandatory then attendees will be forced to answer it to order tickets." />
            <div className="input-label-group">
              <Switch
                trueLabel="Yes, attendees must answer this question"
                falseLabel="No, this question is optional"
                onChange={(value) => {
                  question.Required = value;
                  onEventUpdated();
                }}
                checked={question.Required}
              ></Switch>
            </div>
          </div>
          <div className="field">
            <InfoLabel
              text="What type of question is this?"
              tooltip="There are different types of questions with different ways to answer them, choose the one you want from the options below."
            ></InfoLabel>

            <div className="row">
              <div className="col-sm-7">
                <Dropdown
                  onChange={(item: IDropDownItem) => {
                    question.QuestionType = parseInt(item.value);
                    onEventUpdated();
                  }}
                  value={question.QuestionType.toString()}
                  items={[
                    { value: '0', description: 'Attendee chooses answer from predefined list' },
                    { value: '1', description: 'Attendee answers by entering text' },
                  ]}
                  description="Question type"
                />
              </div>
            </div>
          </div>

          {question.QuestionType === QuestionTypes.Dropdown && (
            <div className="field">
              <div className="row">
                <div className="col-sm-12">
                  <div className="section">
                    {question.Answers.length > 0 ? (
                      <InfoLabel text="Question answer:" tooltip="This is the list of answers the user will be able to choose from for this question."></InfoLabel>
                    ) : (
                      <InfoLabel
                        text="No answers defined."
                        tooltip="In order for you user to be able to answer this question, you will need to fill in answers, you must have at least two answers available, else it would not make sense to ask the question!"
                      ></InfoLabel>
                    )}
                    {question.Answers.map((answer, index) => (
                      <div className="row">
                        <div className="col-sm-7" style={{ paddingTop: '4px' }}>
                          <InfoInput
                            labelText={`Answer #${index + 1}`}
                            onChange={(value) => {
                              answer.Text = value;
                              onEventUpdated();
                            }}
                            value={answer.Text}
                          />
                        </div>

                        <div className="col-sm-3 col-sm-offset-2" style={{ paddingTop: '4px' }}>
                          <button
                            className="admin-button danger"
                            onClick={() => {
                              question.Answers = question.Answers.filter((q) => q !== answer);
                              onEventUpdated();
                            }}
                          >
                            Remove Answer
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-sm-3">
                  <button
                    className="admin-button confirm"
                    onClick={() => {
                      question.Answers.push({ Text: '', Guid: GuidHelper.new() });
                      onEventUpdated();
                    }}
                  >
                    Add Answer
                  </button>
                </div>
              </div>
            </div>
          )}
          <div style={{ marginTop: '30px' }} className="spacer"></div>
        </>
      )}
    </>
  );
};

export default Question;
