import { FunctionComponent, useState } from 'react';
import AdminApi from '../../api/AdminApi';
import Block from '../Block';
import BlockHeader from '../BlockHeader';
import BlockTextBox from '../BlockTextBox';
import Button from '../Button';
import SpacerTable from '../SpacerTable';

interface IProps {
  onSaved: (value: string) => void;
  notes: string;
  seatName?: string;
  groupName?: string;
  orderNumber: number;
}

const AdminNotesInput: FunctionComponent<IProps> = (props) => {
  const [notes, setNotes] = useState<string>(props.notes);

  const save = () => {
    AdminApi.request('POST', '/api/AdminNote', {
      notes: notes,
      OrderNumber: props.orderNumber,
      Name: props.seatName,
      Group: props.groupName,
    })
      .then(() => {
        props.onSaved(notes);
      })
      .catch((message) => alert(message));
  };

  return (
    <>
      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>Order #{props.orderNumber} Admin Notes</BlockHeader>
          </Block>
          <Block>
            <BlockHeader>Notes</BlockHeader>
            <BlockTextBox
              autoFocus={true}
              onKeyPress={(e) => {
                var ENTER = 13;
                if (e.charCode == ENTER) {
                  e.preventDefault();
                  save();
                }
              }}
              value={notes}
              onChange={(e) => {
                setNotes(e);
              }}
              rows={2}
            />
          </Block>
        </tbody>
      </table>
      <div className="spacer" />
      <SpacerTable>
        <Button className={'confirm'} onExecute={save} text={'Save note'} />
      </SpacerTable>
    </>
  );
};

export default AdminNotesInput;
