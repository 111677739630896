import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px">
      <path d="M 6.5 1 C 5.675781 1 5 1.675781 5 2.5 L 5 4 L 2.5 4 C 1.675781 4 1 4.675781 1 5.5 L 1 12.5 C 1 13.324219 1.675781 14 2.5 14 L 8.5 14 C 9.324219 14 10 13.324219 10 12.5 L 10 11 L 12.5 11 C 13.324219 11 14 10.324219 14 9.5 L 14 4.292969 L 10.707031 1 Z M 6.5 2 L 10 2 L 10 5 L 13 5 L 13 9.5 C 13 9.78125 12.78125 10 12.5 10 L 10 10 L 10 7.292969 L 6.707031 4 L 6 4 L 6 2.5 C 6 2.21875 6.21875 2 6.5 2 Z M 11 2.707031 L 12.292969 4 L 11 4 Z M 2.5 5 L 6 5 L 6 8 L 9 8 L 9 12.5 C 9 12.78125 8.78125 13 8.5 13 L 2.5 13 C 2.21875 13 2 12.78125 2 12.5 L 2 5.5 C 2 5.21875 2.21875 5 2.5 5 Z M 7 5.707031 L 8.292969 7 L 7 7 Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
