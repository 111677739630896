import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd">
      <path d="M9.172,16.243l7.071,-7.071c0.39,-0.391 0.39,-1.024 -0,-1.415c-0.391,-0.39 -1.024,-0.39 -1.415,0l-7.071,7.071c-0.39,0.391 -0.39,1.024 0,1.415c0.391,0.39 1.024,0.39 1.415,-0Z" />
      <path d="M16.243,14.828l-7.071,-7.071c-0.391,-0.39 -1.024,-0.39 -1.415,0c-0.39,0.391 -0.39,1.024 0,1.415l7.071,7.071c0.391,0.39 1.024,0.39 1.415,-0c0.39,-0.391 0.39,-1.024 -0,-1.415Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
