import React, { useCallback } from 'react';

import Loader from './Loader';

export interface IProps {
  onExecute?(): void;
  text: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  style?: any;
}

const Button: React.FC<React.PropsWithChildren<IProps>> = ({ disabled, loading, onExecute, style, className, text, children }) => {
  const execute = useCallback(() => {
    if (disabled || loading) {
      return;
    }
    onExecute?.();
  }, [disabled, loading, onExecute]);

  return (
    <button style={style} tabIndex={0} className={`button${disabled ? ' disabled' : ''}${loading ? ' loading' : ''}${className ? ' ' + className : ''}`} onClick={onExecute ? execute : null}>
      {loading ? (
        <Loader inline />
      ) : (
        <>
          {text && text}
          {children && children}
        </>
      )}
    </button>
  );
};

export default Button;
