import moment from 'moment';
import { FunctionComponent } from 'react';
import { IHistoryRow } from './IHistoryRow';

export interface IProps {
  data: IHistoryRow;
  handleRowClick: (data: any) => void;
}

const HistoryRow: FunctionComponent<IProps> = (props) => {
  const { data, handleRowClick } = props;

  return (
    <>
      <tr onClick={handleRowClick} className={`${'route'}`} style={{ display: 'table-row' }}>
        <td>{moment(data.Date).format(`D MMM YYYY, HH:mm`)}</td>
      </tr>
    </>
  );
};

export default HistoryRow;
