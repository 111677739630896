import AdminApi from '../api/AdminApi';

export interface IOrganisationMember {
  Id?: number;
  Email?: string;
  FriendlyName?: string;
  FriendlyNalength?: number;
  id?: number;
  email?: string;
  eventId?: number;
  organisationId?: number;
  removeIfExists?: boolean;
  friendlyName?: string;
  memberTypeId?: number;
}

export default class MemberService {
  public static UpdateOrganisationMember(member: IOrganisationMember): Promise<IOrganisationMember> {
    return AdminApi.request('POST', '/api/Member', member);
  }

  public static UpdateOrganisationMemberType(member: IOrganisationMember): Promise<IOrganisationMember> {
    return AdminApi.request('POST', '/api/MemberType', member);
  }
}
