import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AdminApi from '../api/AdminApi';
import loaderState, { Loader, LoaderHelper } from '../atoms/loaderState';
import orderSearchState from '../atoms/orderSearchState';
import EnvironmentVariables from '../EnvironmentVariables';
import UserHelper from '../helpers/UserHelper';
import { IEvent } from '../interfaces/IEvent';
import { IEventDate } from '../interfaces/IEventDate';
import SVGBanking from '../svg/SVGBanking';
import SVGCompare from '../svg/SVGCompare';
import SVGEditEvent from '../svg/SVGEditEvent';
import SVGExcel from '../svg/SVGExcel';
import SVGRoster from '../svg/SVGFeed';
import SVGLock from '../svg/SVGLock';
import SVGOrders from '../svg/SVGOrders';
import SVGRequest from '../svg/SVGRequest';
import SVGScales from '../svg/SVGScales';
import SVGScan from '../svg/SVGScan';
import SVGSummary from '../svg/SVGSummary';
import SVGTicket from '../svg/SVGTicket';
import organisationMenuState from '../atoms/organisationMenuState';
import tourMenuState from '../atoms/tourMenuState';
import SVGTour from '../svg/SVGTour';
import SVGEvent from '../svg/SVGEvent';
import SVGOrganisation from '../svg/SVGOrganisation';
import SVGSearch from '../svg/SVGSearch';
import eventMenuState from '../atoms/eventMenuState';
import { IOrganisation } from '../interfaces/IOrganisation';
import { ITour } from '../interfaces/ITour';

export interface IAdminMenuEventProps {
  event: IEvent;
  organisation: IOrganisation;
  tour: ITour;
  eventTag?: string;
  onCloseClick: () => void;
  eventDate?: IEventDate;
  requestCount?: number;
}

const AdminMenuEvent: FunctionComponent<IAdminMenuEventProps> = (props) => {
  const { onCloseClick, eventDate, event, requestCount } = props;
  const [loaders, setLoaders] = useRecoilState(loaderState);
  const [, setOrderSearch] = useRecoilState(orderSearchState);

  if (!event) {
    return null;
  }

  const eventId = event.Id;
  const eventTag = event.EventTag;

  return (
    <div className="admin-menu">
      <h1>
        Event <strong>#{event.EventTag.toUpperCase()}</strong>
      </h1>
      <div className="buttons">
        <div className="admin-menu-background"></div>

        <Link onClick={onCloseClick} to={'/' + eventTag}>
          <SVGEvent />
          <label>Landing</label>
        </Link>

        <button onClick={() => setOrderSearch(event)}>
          <SVGSearch />
          <label>Find orders</label>
        </button>

        <Link onClick={onCloseClick} className="edit" to={'/Organisation/' + event.Organisation.OrganisationTag + '/Event/' + eventTag + '/Summary'}>
          <SVGSummary />
          <label>Summary</label>
        </Link>

        <Link onClick={onCloseClick} className="edit" to={'/Organisation/' + event.Organisation.OrganisationTag + '/Event/' + eventTag + '/Banking'}>
          <SVGBanking />
          <label>Banking</label>
        </Link>

        <Link onClick={onCloseClick} className="edit" to={'/Organisation/' + event.Organisation.OrganisationTag + '/Event/' + eventTag + '/Feed'}>
          <SVGOrders />
          <label>Feed</label>
        </Link>

        <Link onClick={onCloseClick} className="edit" to={'/Organisation/' + event.Organisation.OrganisationTag + '/Event/' + eventTag + '/Balances'}>
          <SVGScales />
          <label>Balances</label>
        </Link>

        {event.RequestingEnabled && (
          <Link onClick={onCloseClick} className="request" to={'/Organisation/' + event.Organisation.OrganisationTag + '/Event/' + eventTag + '/Requests'}>
            <SVGRequest />
            <label>Requests</label>
            {requestCount > 0 && <div className="badge">{requestCount}</div>}
          </Link>
        )}

        <Link onClick={onCloseClick} className="scans" to={'/Organisation/' + event.Organisation.OrganisationTag + '/Event/' + eventTag + '/Scans'}>
          <SVGScan />
          <label>Scans</label>
        </Link>

        <Link onClick={onCloseClick} className="scans" to={'/Organisation/' + event.Organisation.OrganisationTag + '/Event/' + eventTag + '/Roster'}>
          <SVGRoster />
          <label>Roster</label>
        </Link>

        <Link onClick={onCloseClick} className="locks" to={'/Organisation/' + event.Organisation.OrganisationTag + '/Event/' + eventTag + '/Locks'}>
          <SVGLock />
          <label>Locks</label>
        </Link>

        <Link onClick={onCloseClick} className="blank" to={eventDate ? `/${eventTag}/BlankTickets/${moment(eventDate.DateAsString).format('DDMMMYYYY/HHmm')}` : `/${eventTag}/BlankTickets`}>
          <SVGTicket />
          <label>Blank ticket</label>
        </Link>

        {eventDate && (
          <Link onClick={onCloseClick} className="blank" to={`/${eventTag}/SampleTickets/${moment(eventDate.DateAsString).format('DDMMMYYYY/HHmm')}`}>
            <SVGTicket />
            <label>Sample tickets</label>
          </Link>
        )}

        <Link onClick={onCloseClick} className="edit" to={'/Organisation/' + event.Organisation.OrganisationTag + '/Event/' + eventTag + '/Edit'}>
          <SVGEditEvent />
          <label>Edit</label>
        </Link>

        <button
          className="excel"
          onClick={() => {
            LoaderHelper.add(setLoaders, loaders, Loader.ExcelExport, 'Exporting data...');

            var xhr = new XMLHttpRequest();
            xhr.open('POST', `${EnvironmentVariables.ADMIN_API_URL}/api/EventSpreadsheet?eventId=${eventId}`, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function () {
              if (this.status === 200) {
                var filename = '';
                var disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                  var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                  var matches = filenameRegex.exec(disposition);
                  if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                var type = xhr.getResponseHeader('Content-Type');

                var blob;
                if (typeof File === 'function') {
                  try {
                    blob = new File([this.response], filename, { type: type });
                  } catch (e) {
                    /* Edge */
                  }
                }
                if (typeof blob === 'undefined') {
                  blob = new Blob([this.response], { type: type });
                }

                if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
                  // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                  (window.navigator as any).msSaveBlob(blob, filename);
                } else {
                  var URL = window.URL || (window as any).webkitURL;
                  var downloadUrl = URL.createObjectURL(blob);

                  if (filename) {
                    // use HTML5 a[download] attribute to specify filename
                    var a = document.createElement('a');
                    // safari doesn't support this yet
                    if (typeof a.download === 'undefined') {
                      (window as any).location = downloadUrl;
                    } else {
                      a.href = downloadUrl;
                      a.download = filename;
                      document.body.appendChild(a);
                      a.click();
                    }
                  } else {
                    (window as any).location = downloadUrl;
                  }

                  setTimeout(function () {
                    URL.revokeObjectURL(downloadUrl);
                  }, 100); // cleanup
                }
              }

              LoaderHelper.remove(setLoaders, loaders, Loader.ExcelExport);
            };
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.setRequestHeader('Authorization', `Bearer ${UserHelper.currentUser.Token}`);
            xhr.send();
          }}
        >
          <SVGExcel />
          <label>Export data</label>
        </button>
      </div>
    </div>
  );
};

export default AdminMenuEvent;
