import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="26px" height="26px">
      <path d="M 6 0 C 4.34375 0 3 1.34375 3 3 L 3 23 C 3 24.65625 4.34375 26 6 26 L 20 26 C 21.65625 26 23 24.65625 23 23 L 23 17.8125 L 21 19.5625 L 21 23 C 21 23.550781 20.550781 24 20 24 L 6 24 C 5.449219 24 5 23.550781 5 23 L 5 3 C 5 2.449219 5.449219 2 6 2 L 20 2 C 20.550781 2 21 2.449219 21 3 L 21 6.4375 L 23 8.1875 L 23 3 C 23 1.34375 21.65625 0 20 0 Z M 16.5 6.46875 C 16.25 6.542969 16 6.796875 16 7.46875 L 16 10 L 10 10 C 9.449219 10 9 10.449219 9 11 L 9 15 C 9 15.550781 9.449219 16 10 16 L 16 16 L 16 18.5 C 16 19.78125 17 19.5 17 19.5 L 24.5 13 L 17 6.5 C 17 6.5 16.75 6.394531 16.5 6.46875 Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
