import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 12.5 6 C 8.9280619 6 6 8.9280619 6 12.5 L 6 35.5 C 6 39.071938 8.9280619 42 12.5 42 L 35.5 42 C 39.071938 42 42 39.071938 42 35.5 L 42 12.5 C 42 8.9280619 39.071938 6 35.5 6 L 12.5 6 z M 12.5 9 L 35.5 9 C 37.450062 9 39 10.549938 39 12.5 L 39 14 L 9 14 L 9 12.5 C 9 10.549938 10.549938 9 12.5 9 z M 9 17 L 39 17 L 39 35.5 C 39 37.450062 37.450062 39 35.5 39 L 12.5 39 C 10.549938 39 9 37.450062 9 35.5 L 9 17 z M 24 20.5 C 23.446 20.5 22.952484 20.851953 22.771484 21.376953 L 21.486328 25.115234 L 17.220703 25.115234 C 16.663703 25.115234 16.168234 25.472 15.990234 26 C 15.812234 26.528 15.9925 27.110266 16.4375 27.447266 L 19.892578 30.068359 L 18.722656 33.814453 C 18.555656 34.352453 18.752891 34.936719 19.212891 35.261719 C 19.672891 35.586719 20.290234 35.579234 20.740234 35.240234 L 24 32.798828 L 27.259766 35.240234 C 27.489766 35.412234 27.763109 35.5 28.037109 35.5 C 28.299109 35.5 28.563109 35.420719 28.787109 35.261719 C 29.247109 34.936719 29.445344 34.351453 29.277344 33.814453 L 28.107422 30.068359 L 31.5625 27.447266 C 32.0065 27.110266 32.187766 26.527047 32.009766 25.998047 C 31.831766 25.470047 31.336297 25.115234 30.779297 25.115234 L 26.513672 25.115234 L 25.228516 21.376953 C 25.047516 20.852953 24.554 20.5 24 20.5 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
