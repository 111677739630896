import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import NumberHelper from '../../helpers/NumberHelper';
import { ISeatingPlanSeat } from '../../interfaces/ISeatingPlanSeat';
import SVGHelper from '../../helpers/SVGHelper';

export interface IProps {
  seat: ISeatingPlanSeat;
  clickStart: (seat: ISeatingPlanSeat) => void;
  clickEnd: (seat: ISeatingPlanSeat) => void;
  showCategoryColours: boolean;
  height: number;
  width: number;
  hideOrders: boolean;
  bottomHalf: boolean;
  leftHalf: boolean;
  rightHalf: boolean;
  disabled?: boolean;
  disableOffset: boolean;
  mobileAndTablet: boolean;
  click?: (seat: ISeatingPlanSeat) => void;
  onDragStart?: (data) => void;
  onDragEnd?: () => void;
  onDrag?: (data) => void;
  seatScale: number;
  hideIcon: boolean;
  isMiddleSeat: boolean;
}

const SeatIconScales = {
  wheelchair: 0.6,
  info: 0.5,
};

const SeatingPlanSeat: FunctionComponent<IProps> = (props) => {
  const { seat, mobileAndTablet } = props;
  const { SeatCategory } = seat;
  const seatRef = useRef();

  var vectorStyle: React.CSSProperties = {
    fill: null,
  };

  if (SeatCategory != null && !seat.Selected && props.showCategoryColours) {
    vectorStyle.fill = SeatCategory.Colour;
  }

  var seatText = seat.Group + seat.Name;

  if (seat.Selected) {
    vectorStyle.fill = '#08202b';
  } else if (props.disabled) {
    vectorStyle.fill = null;
  }

  const xOffset = seat.OffsetX || 0.0;
  const yOffset = seat.OffsetY || 0.0;

  let x = NumberHelper.roundDecimal((seat.Column - 1) * props.width + props.width / 2);
  let y = NumberHelper.roundDecimal((seat.Row - 1) * props.height + props.height / 2);

  let style = null;
  const useOffset = !props.disableOffset && (xOffset != 0.0 || yOffset != 0.0);

  if (useOffset) {
    x = x + NumberHelper.roundDecimal(xOffset * props.width);
    y = y + NumberHelper.roundDecimal(yOffset * props.width);
    //style = { transform: `translate(${NumberHelper.roundDecimal(xOffset * props.width)}px, ${NumberHelper.roundDecimal(yOffset * props.height)}px)` };
  }

  const [dragging, setDragging] = useState(false);
  const [draggedElement, setDraggedElement] = useState(null);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [dragOffsetX, setOffsetStartX] = useState(0);
  const [dragOffsetY, setOffsetStartY] = useState(0);

  const handleMouseDown = (e, g) => {
    e.preventDefault();
    setDragging(true);
    setDraggedElement(g);
    setDragStartX(e.clientX || e.touches[0].clientX);
    setDragStartY(e.clientY || e.touches[0].clientY);
    setOffsetStartX(seat.OffsetX);
    setOffsetStartY(seat.OffsetY);

    if (props.onDragStart) {
      props.onDragStart({
        dragOffsetY: dragOffsetY,
        dragOffsetX: dragOffsetX,
        seat: seat,
        startX: e.clientX || e.touches[0].clientX,
        startY: e.clientY || e.touches[0].clientY,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!dragging || !draggedElement) return;

    const deltaX = (e.clientX || e.touches[0].clientX) - dragStartX;
    const deltaY = (e.clientY || e.touches[0].clientY) - dragStartY;

    if (props.onDrag) {
      props.onDrag({
        movementX: e.movementX,
        movementY: e.movementY,
        dragOffsetY: dragOffsetY,
        dragOffsetX: dragOffsetX,
        seat: seat,
        dragEndX: deltaX,
        dragEndY: deltaY,
        dragStartX: dragStartX,
        dragStartY: dragStartY,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    setDraggedElement(null);
    if (props.onDragEnd) {
      props.onDragEnd();
    }
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('touchmove', handleMouseMove);
      document.addEventListener('touchend', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleMouseUp);
    };
  }, [dragging]);

  const radius = (props.width / 2) * 0.85 * props.seatScale;
  const fontSize = 11 * props.seatScale;

  const hasIcon = SeatCategory && SeatCategory.Icon && SeatCategory.Icon.length > 0;

  return (
    <g
      ref={seatRef}
      style={style}
      onMouseDown={(e) => {
        if (props.onDrag) {
          handleMouseDown(e, props.seat);
        }
        if (!mobileAndTablet) {
          props.clickStart(props.seat);
        }
      }}
      onTouchStart={(e: React.TouchEvent<HTMLOrSVGElement>) => {
        if (props.onDrag) {
          handleMouseDown(e, props.seat);
        }
        if (!props.onDrag && mobileAndTablet) {
          props.clickStart(props.seat);
        }
      }}
      onMouseUp={!mobileAndTablet ? () => props.clickEnd(props.seat) : null}
      onTouchEnd={mobileAndTablet ? () => props.clickEnd(props.seat) : null}
      onClick={() => {
        !props.onDrag && props.click && props.click(props.seat);
      }}
      className={
        'seat' +
        (props.onDrag && dragging ? ' dragging' : '') +
        (seat.Selected ? ' selected' : '') +
        (props.disabled ? ' disabled' : '') +
        (seat.Locked && !(seat.IsOrdered || seat.IsPurchased || seat.IsRequested) ? ' locked' : '') +
        (props.isMiddleSeat ? ' middle-seat' : '')
      }
      data-row={seat.Row}
      data-column={seat.Column}
    >
      <circle className="vector" style={vectorStyle} cx={x} cy={y} r={radius} />

      {hasIcon && !seat.Selected && !props.hideIcon ? (
        <>
          {SeatCategory.Icon == 'wheelchair' && (
            <image
              x={1 + x - props.width / 2 + (props.width - props.width * SeatIconScales.wheelchair) / 2}
              y={y - props.width / 2 + (props.width - props.width * SeatIconScales.wheelchair) / 2}
              height={props.width * SeatIconScales.wheelchair}
              width={props.width * SeatIconScales.wheelchair}
              className="seat__icon"
              href={SVGHelper.get('Wheelchair_Light')}
            />
          )}
          {SeatCategory.Icon == 'info' && (
            <image
              x={x - props.width / 2 + (props.width - props.width * SeatIconScales.info) / 2}
              y={y - props.width / 2 + (props.width - props.width * SeatIconScales.info) / 2}
              height={props.width * SeatIconScales.info}
              width={props.width * SeatIconScales.info}
              className="seat__icon"
              href={SVGHelper.get('Info_Solo_Light')}
            />
          )}
        </>
      ) : (
        seatText &&
        seatText.length > 0 && (
          <text
            fontSize={fontSize}
            textAnchor="middle"
            alignmentBaseline="middle"
            height={props.height}
            width={props.width}
            x={x}
            y={y + 1}
          >
            {seatText}
          </text>
        )
      )}
    </g>
  );
};

export default SeatingPlanSeat;
