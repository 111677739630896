import linq from 'linq';
import { FunctionComponent, useState } from 'react';
import AdminApi from '../../../api/AdminApi';
import CurrencyHelper from '../../../helpers/CurrencyHelper';
import DateHelper from '../../../helpers/DateHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IOrder } from '../../../interfaces/IOrder';
import { IPurchase } from '../../../interfaces/IPurchase';
import Block from '../../Block';
import BlockHeader from '../../BlockHeader';
import BlockInfo, { InfoType } from '../../BlockInfo';
import BlockTextBox from '../../BlockTextBox';
import Button from '../../Button';
import CheckBox from '../../CheckBox';
import Loader from '../../Loader';
import SpacerTable from '../../SpacerTable';

export interface IProps {
  event: IEvent;
  order: IOrder;
  onBackToOrderClick: () => void;
}

const CancelSection: FunctionComponent<IProps> = (props) => {
  const { event, order, onBackToOrderClick } = props;
  const tickets = linq
    .from(order.Seats)
    .orderBy((s) => s.Group)
    .thenBy((s) => s.Name)
    .toArray();
  const purchase: IPurchase = order.Purchase;

  const [addRefund, setAddRefund] = useState(false);
  const [refundHandlingFee, setRefundHandlingFee] = useState(false);
  const [cancellingReason, setCancellingReason] = useState('');
  const [busyMessage, setBusyMessage] = useState(null);
  const [reloadMessage, setReloadMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedTickets, setSelectedTickets] = useState([
    ...linq
      .from(tickets)
      .where((t) => !t.Cancelled)
      .toArray(),
  ]);

  var allTicketsSelected =
    selectedTickets.length ===
    linq
      .from(tickets)
      .where((t) => !t.Cancelled)
      .toArray().length;

  const cancelSelectedTickets = (order) => {
    setBusyMessage('Cancelling tickets...');
    setErrorMessage(null);

    var eventDateId = order.EventDateId;
    var reason = cancellingReason;

    var cancellationSeats = linq
      .from(order.Seats)
      .where(function (s: any) {
        return linq
          .from(selectedTickets)
          .where((t) => !t.Cancelled)
          .toArray()
          .includes(s);
      })
      .select(function (seat: any) {
        return {
          group: seat.Group,
          name: seat.Name,
          seatCategoryId: seat.SeatCategoryId,
          ticketCategoryId: seat.TicketCategoryId,
          orderId: order.Id,
        };
      })
      .toArray();

    var cancellation = {
      eventDateId: eventDateId,
      reason: reason,
      seats: cancellationSeats,
      addRefund: addRefund,
      refundHandlingFee: refundHandlingFee,
    };

    AdminApi.request('DELETE', `/api/Order`, cancellation)
      .then(() => {
        setBusyMessage(null);
        setReloadMessage('The tickets have been cancelled.');

        AdminApi.request('POST', '/api/EventDateHandshake', { eventDateId: eventDateId });
      })
      .catch((message) => {
        setErrorMessage(message);
        setBusyMessage(null);
      });
  };

  if (reloadMessage != null) {
    return (
      <>
        <table className="blocks">
          <tbody>
            <BlockInfo type={InfoType.Info}>{reloadMessage}</BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button onExecute={props.onBackToOrderClick} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  }

  if (busyMessage != null) {
    return <Loader inline={true}>{busyMessage}</Loader>;
  }

  var ticketComponents = [];

  const hasDiscounts = order.Discounts != null && order.Discounts.length > 0;

  tickets.forEach((ticket) => {
    var editOrderAdminNotesInput = null;

    const showCheckbox = !ticket.Cancelled;

    const content = (
      <>
        <BlockHeader>
          <td className="ticket-font" style={{ color: ticket.SeatCategoryColour }}>
            {ticket.Group + ticket.Name} {ticket.SeatCategoryName}
          </td>
          <td className="right">
            <span style={{ color: ticket.CategoryColour }}>
              {ticket.CategoryName} {ticket.PriceAsString}
            </span>
          </td>
        </BlockHeader>

        {!showCheckbox &&
          ticket.QuestionAnswers &&
          ticket.QuestionAnswers.map((qa) => (
            <div className="question" style={{ marginTop: '6px' }}>
              <BlockHeader>{qa.QuestionName}</BlockHeader>
              {qa.Text}
            </div>
          ))}

        {ticket.AdminNotes && ticket.AdminNotes.length > 0 && (
          <span className="userNotes" style={{ display: 'block', maxWidth: '100%' }}>
            {ticket.AdminNotes}
          </span>
        )}
        {ticket.Cancelled && <div style={{ display: 'block' }}>Ticket cancelled on {ticket.CancelledDate}</div>}

        {editOrderAdminNotesInput}
      </>
    );

    if (showCheckbox) {
      if (hasDiscounts) {
        ticketComponents.push(<Block key={ticket.Group + ticket.Name}>{content}</Block>);
      } else {
        ticketComponents.push(
          <CheckBox
            key={ticket.Group + ticket.Name}
            checked={selectedTickets.includes(ticket)}
            onBoxClick={() => {
              if (selectedTickets.includes(ticket)) {
                const index = selectedTickets.indexOf(ticket);
                if (index > -1) {
                  selectedTickets.splice(index, 1);
                }
              } else {
                selectedTickets.push(ticket);
              }

              setSelectedTickets([...selectedTickets]);
            }}
          >
            {content}
          </CheckBox>,
        );
      }
    } else {
      ticketComponents.push(<Block key={ticket.Group + ticket.Name}>{content}</Block>);
    }
  });

  var discountAmount = 0;
  if (order.Discounts != null && order.Discounts.length > 0) {
    discountAmount = linq.from(order.Discounts).sum(function (d: any) {
      return d.Amount;
    });
  }

  const isPurchase = order.Purchase != null;
  const availableTickets = linq
    .from(tickets)
    .where((t) => !t.Cancelled)
    .toArray();

  return (
    <>
      <SpacerTable>
        <h1>Order #{props.order.Id}</h1>
      </SpacerTable>
      <div className="spacer" />
      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>Attendee</BlockHeader>
            {order.AttendeeName}
            <br />
            {order.Email}
          </Block>
          <Block>
            <BlockHeader>Event Date</BlockHeader>
            {DateHelper.asDateAtTimeAmPm((order.EventDate as any).DateAsString)}
          </Block>
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <h1>Tickets</h1>
      </SpacerTable>
      <div className="spacer" />
      <SpacerTable>
        <Button
          onExecute={() => {
            const value = selectedTickets.length != availableTickets.length;

            availableTickets.forEach((ticket) => {
              if (value && !selectedTickets.includes(ticket)) {
                selectedTickets.push(ticket);
              } else if (!value && selectedTickets.includes(ticket)) {
                const index = selectedTickets.indexOf(ticket);
                if (index > -1) {
                  selectedTickets.splice(index, 1);
                }
              }

              setSelectedTickets([...selectedTickets]);
            });
          }}
          text={selectedTickets.length != availableTickets.length ? `Select all tickets` : 'De-select all tickets'}
        />
      </SpacerTable>
      <div className="spacer" />
      <table className="blocks tickets">
        <tbody>
          {ticketComponents}

          {hasDiscounts ? (
            <>
              {order.Discounts.map((discount) => (
                <Block key={discount.Name}>
                  <BlockHeader>{discount.Name}</BlockHeader>
                  {CurrencyHelper.formatCurrency(event.CurrencySymbol, discount.Amount)}
                </Block>
              ))}

              <BlockInfo type={InfoType.Info}>This order currently has discounts applied to it. When you cancel an order with discounts the entire order and all tickets will be cancelled.</BlockInfo>
            </>
          ) : null}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <h1>Cancellation</h1>
      </SpacerTable>
      <div className="spacer" />
      <table className="blocks tickets">
        <tbody>
          {!isPurchase && (
            <CheckBox checked={addRefund} onBoxClick={() => setAddRefund(!addRefund)}>
              {'Add a refund of ' + event.CurrencySymbol + (linq.from(selectedTickets).sum((s: any) => s.PriceAsInt) / 100.0 - discountAmount / 100.0).toFixed(2)}
            </CheckBox>
          )}

          <Block>
            <BlockHeader>Why are these tickets being cancelled?</BlockHeader>
            <BlockTextBox
              placeholder={'You must enter a reason for cancelling these tickets.'}
              value={cancellingReason}
              onChange={(e) => {
                setCancellingReason(e);
              }}
              rows={2}
            />
          </Block>

          {purchase && !hasDiscounts && (
            <>
              {purchase.PurchaseCosts &&
                purchase.PurchaseCosts.HandlingFee != null &&
                purchase.PurchaseCosts.HandlingFee > 0 &&
                (purchase.HandlingFeeRefundId ? (
                  <>
                    <Block>Handling fee of {CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PurchaseCosts.HandlingFee)} has already been refunded.</Block>
                  </>
                ) : (
                  <CheckBox onBoxClick={() => setRefundHandlingFee(!refundHandlingFee)} checked={refundHandlingFee}>
                    Refund handling fee of {CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PurchaseCosts.HandlingFee)}
                  </CheckBox>
                ))}

              {purchase.PurchaseCosts && !purchase.AbsorbFees && purchase.PurchaseCosts.TotalFees > 0 && (
                <>
                  <Block>
                    <BlockHeader>Card fee paid by attendee</BlockHeader>
                    {CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PurchaseCosts.TotalFees)}
                  </Block>
                  <BlockInfo type={InfoType.Info}>Seaty card processing fees are only returned to the attendee when all tickets in an order are cancelled. </BlockInfo>
                </>
              )}

              {purchase.PurchaseCosts && purchase.AbsorbFees && purchase.PurchaseCosts.TotalFees > 0 && (
                <>
                  <Block>
                    <BlockHeader>Card fee paid by your organisation</BlockHeader>
                    {CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PurchaseCosts.TotalFees)}
                  </Block>
                  <BlockInfo type={InfoType.Info}>Seaty card processing fees are only returned to your organisation when all tickets in an order are cancelled. </BlockInfo>
                </>
              )}

              <Block>
                <BlockHeader>Total refund to attendee</BlockHeader>
                {CurrencyHelper.formatCurrency(
                  event.CurrencySymbol,
                  linq.from(selectedTickets).sum((s: any) => s.PriceAsInt) +
                    (purchase.AbsorbFees ? 0 : allTicketsSelected ? purchase.PurchaseCosts.TotalFees : 0) +
                    (refundHandlingFee ? purchase.PurchaseCosts.HandlingFee : 0),
                )}
              </Block>
            </>
          )}

          {errorMessage && <BlockInfo type={InfoType.Error}>{errorMessage}</BlockInfo>}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <Button className="bad" disabled={cancellingReason.length <= 4} onExecute={() => cancelSelectedTickets(order)} text={`Cancel ${selectedTickets.length} tickets`} />
      </SpacerTable>

      <div className="spacer" />
      <SpacerTable>
        <Button onExecute={onBackToOrderClick} text={'Back to order'} />
      </SpacerTable>
    </>
  );
};

export default CancelSection;
