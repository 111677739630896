import linq from 'linq';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { IEvent } from '../../../../interfaces/IEvent';

import EventSummary_TicketSalesRow from './EventSummary_TicketSalesRow';
import { ITicketCategory } from '../../../../interfaces/ITicketCategory';
import { IDiscount } from '../../../../interfaces/IDiscount';

export interface IEventDateSale {
  Discounts: IDiscount[];
  EventDate: string;
  EventDateAsString: string;
  EventDateId: number;
  PriceAsInt: number;
  PriceAsString: string;
  SoldTickets: number;
  TicketsByCategory: ITicketCategory[];
}

export enum EventSummaryMode {
  ByDateTime = 'byDateTime',
  ByDay = 'byDay',
  ByTicketType = 'byTicketType',
}

export interface IProps {
  currency: string;
  eventDateSales: Array<IEventDateSale>;
  merchandise: boolean;
  event: IEvent;
  mode: EventSummaryMode;
}

const EventSummary_TicketSales: FunctionComponent<IProps> = (props) => {
  var event = props.event;

  const hasSeatingPlans =
    !props.merchandise && event.UseSeatingPlan && event.SeatingPlans && event.SeatingPlans.length > 0;

  var ticketsEnum = linq.from(props.eventDateSales).orderBy((m) => moment(m.EventDateAsString).unix());

  var totalSoldTickets = ticketsEnum.sum((t) => {
    return (t as any).SoldTickets;
  });

  var totalTickets = ticketsEnum.sum((t) => {
    const eventDate: any = linq.from(event.Dates).firstOrDefault((ed) => ed.Id == t.EventDateId);
    if (!eventDate) return 0;

    const categoryGroupsInDate = linq
      .from(event.UnallocatedCategoryGroups)
      .where(
        (g: any) =>
          g.Merchandise == props.merchandise &&
          linq.from(g.Categories).any((c: any) => eventDate.GATicketCategoryIds.includes(c.Id)),
      );
    const totalGeneralAdmissionTickets = categoryGroupsInDate.sum((c: any) => c.Quantity);
    return (hasSeatingPlans ? linq.from(event.SeatingPlans).sum((s) => s.SeatCount) : 0) + totalGeneralAdmissionTickets;
  });

  const dayFormat = 'ddd Do MMM YY';

  const getEventDateTotals = (eventDateSummary: IEventDateSale) => {
    const eventDate: any = linq.from(event.Dates).firstOrDefault((ed) => ed.Id == eventDateSummary.EventDateId);
    if (!eventDate)
      return {
        total: 0,
        amount: '0/0',
        soldTickets: 0,
        totalTickets: 0,
        percentage: 0,
      };

    const categoryGroupsInDate = linq
      .from(event.UnallocatedCategoryGroups)
      .where(
        (g: any) =>
          g.Merchandise == props.merchandise &&
          linq.from(g.Categories).any((c: any) => eventDate.GATicketCategoryIds.includes(c.Id)),
      );
    const totalGeneralAdmissionTickets = categoryGroupsInDate.sum((c: any) => c.Quantity);

    var totalTicketsForEventDate =
      (hasSeatingPlans ? linq.from(event.SeatingPlans).sum((s) => s.SeatCount) : 0) + totalGeneralAdmissionTickets;
    var percentage =
      eventDateSummary.SoldTickets > totalTicketsForEventDate
        ? 100.0
        : (eventDateSummary.SoldTickets / totalTicketsForEventDate) * 100.0;
    var amount = eventDateSummary.SoldTickets + '/' + totalTicketsForEventDate;
    var totalDiscounts = linq.from(eventDateSummary.Discounts).sum((t) => {
      return (t as any).Amount;
    });

    return {
      total: eventDateSummary.PriceAsInt - totalDiscounts,
      amount: amount,
      soldTickets: eventDateSummary.SoldTickets,
      totalTickets: totalTicketsForEventDate,
      percentage: percentage,
      totalDiscounts: totalDiscounts,
    };
  };

  const getDateTimeNode = (eventDateSummary: IEventDateSale, totals: any, name: string) => {
    var children = [];

    eventDateSummary.Discounts.forEach((discount) => {
      children.push(
        <EventSummary_TicketSalesRow
          key={'category_discount_row_' + eventDateSummary.EventDateId + '_' + name + '_' + discount.Id}
          title={discount.Name}
          titleColour={'#41abbb'}
          subtitle={''}
          subtitleColour={'#41abbb'}
          percentage={null}
          amount={`${discount.Quantity}`}
          total={'-' + CurrencyHelper.formatCurrency(props.currency, discount.Amount)}
        />,
      );
    });

    linq
      .from(eventDateSummary.TicketsByCategory)
      .where((tc) => tc.SeatCategory)
      .groupBy((tc) => tc.SeatCategory.Id)
      .toArray()
      .forEach((group) => {
        const seatCategory = group.first().SeatCategory;
        if (group.count() == 1) {
          const ticketCategory = group.first();

          children.push(
            <EventSummary_TicketSalesRow
              key={'category_row_' + eventDateSummary.EventDateId + '_' + name + '_' + ticketCategory.Id}
              subtitle={ticketCategory.Name}
              subtitleColour={ticketCategory.Colour}
              title={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Name : ''}
              titleColour={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Colour : ''}
              percentage={null}
              amount={ticketCategory.SeatCount + '/' + seatCategory.SeatCount}
              total={ticketCategory.PriceAsString}
              people={
                ticketCategory.People > 1 && ticketCategory.People != ticketCategory.SeatCount && ticketCategory.People
              }
            />,
          );
        } else {
          const rows = group
            .select((ticketCategory) => {
              return (
                <EventSummary_TicketSalesRow
                  key={'category_row_' + eventDateSummary.EventDateId + '_' + name + '_' + ticketCategory.Id}
                  title={ticketCategory.Name}
                  titleColour={ticketCategory.Colour}
                  percentage={null}
                  amount={ticketCategory.SeatCount}
                  total={ticketCategory.PriceAsString}
                  people={
                    ticketCategory.People > 1 &&
                    ticketCategory.People != ticketCategory.SeatCount &&
                    ticketCategory.People
                  }
                />
              );
            })
            .toArray();

          children.push(
            <EventSummary_TicketSalesRow
              child={rows}
              key={'category_seatcategory_row_' + eventDateSummary.EventDateId + '_' + name + '_' + seatCategory.Id}
              title={seatCategory.Name}
              titleColour={seatCategory.Colour}
              subtitle={''}
              percentage={null}
              amount={group.sum((g) => g.SeatCount) + '/' + seatCategory.SeatCount}
              total={CurrencyHelper.formatCurrency(
                props.currency,
                group.sum((g) => g.PriceAsInt),
              )}
            />,
          );
        }
      });

    linq
      .from(eventDateSummary.TicketsByCategory)
      .where((tc) => !tc.SeatCategory)
      .toArray()
      .forEach((ticketCategory) => {
        children.push(
          <EventSummary_TicketSalesRow
            key={'category_row_' + eventDateSummary.EventDateId + '_' + name + '_' + ticketCategory.Id}
            title={ticketCategory.Name}
            titleColour={ticketCategory.Colour}
            subtitle={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Name : ''}
            subtitleColour={ticketCategory.SeatCategory ? ticketCategory.SeatCategory.Colour : ''}
            percentage={null}
            peopleWording={ticketCategory.Merchandise ? 'Item' : 'People'}
            wording={ticketCategory.Merchandise ? 'Merch Sale' : 'Ticket'}
            amount={ticketCategory.SeatCount}
            total={ticketCategory.PriceAsString}
            people={
              ticketCategory.People > 1 && ticketCategory.People != ticketCategory.SeatCount && ticketCategory.People
            }
          />,
        );
      });

    return (
      <EventSummary_TicketSalesRow
        key={'date_row_' + eventDateSummary.EventDateId + '_' + name}
        child={children.length == 0 ? null : children}
        title={name}
        subtitle={''}
        percentage={totals.percentage}
        amount={totals.amount}
        total={CurrencyHelper.formatCurrency(props.currency, totals.total)}
      />
    );
  };

  let rows = [];

  if (props.mode == EventSummaryMode.ByTicketType) {
    rows = linq
      .from(props.eventDateSales)
      .selectMany((d) => d.TicketsByCategory as ITicketCategory[])
      .groupBy((t: ITicketCategory) => t.Id)
      .toArray()
      .map((group) => {
        const totalSeats = group.sum((g) => g.SeatCount);
        const totalPrice = group.sum((g) => g.PriceAsInt);
        const totalDiscounts = group.sum(
          (g) => g.Discounts && g.Discounts.length > 0 && linq.from(g.Discounts).sum((d) => d.Amount),
        );

        return (
          <>
            {totalDiscounts > 0 && (
              <EventSummary_TicketSalesRow
                key={`type_row_discounts_${group.first().Name}`}
                title={group.first().Name + ' Discounts'}
                titleColour={group.first().Colour}
                subtitle={''}
                subtitleColour={'#41abbb'}
                percentage={null}
                amount={totalDiscounts}
                total={'-' + CurrencyHelper.formatCurrency(props.currency, totalDiscounts)}
              />
            )}
            <EventSummary_TicketSalesRow
              key={`type_row_${group.first().Name}`}
              title={group.first().Name}
              titleColour={group.first().Colour}
              subtitle={group.first().SeatCategory ? group.first().SeatCategory.Name : ''}
              subtitleColour={group.first().SeatCategory ? group.first().SeatCategory.Colour : ''}
              amount={totalSeats}
              total={CurrencyHelper.formatCurrency(props.currency, totalPrice)}
            />
          </>
        );
      });
  } else {
    rows = linq
      .from(props.eventDateSales)
      .groupBy((d) => moment(d.EventDateAsString).format('YYYY-MM-DD'))
      .orderBy((g) => moment(g.first().EventDateAsString).unix())
      .toArray()
      .map((group) => {
        if (group.count() > 1) {
          const day = moment(group.first().EventDateAsString);
          const eventDateTotals = linq.from(group.toArray().map((g) => getEventDateTotals(g)));

          if (props.mode == EventSummaryMode.ByDay) {
            const groupMerged: IEventDateSale = {
              Discounts: group.selectMany((g) => g.Discounts).toArray(),
              EventDate: null,
              EventDateAsString: moment(group.first().EventDateAsString).format('YYYY-MM-DD'),
              EventDateId: 0,
              PriceAsInt: eventDateTotals.sum((p) => p.total),
              PriceAsString: CurrencyHelper.formatCurrency(
                props.currency,
                eventDateTotals.sum((p) => p.total),
              ),
              SoldTickets: eventDateTotals.sum((p) => p.soldTickets),
              TicketsByCategory: group
                .selectMany((g) => g.TicketsByCategory)
                .groupBy((t) => t.Id)
                .select((g) => {
                  return {
                    Id: g.first().Id,
                    Name: g.first().Name,
                    Colour: g.first().Colour,
                    PriceAsInt: g.sum((t) => t.PriceAsInt),
                    PriceAsString: CurrencyHelper.formatCurrency(
                      props.currency,
                      g.sum((t) => t.PriceAsInt),
                    ),
                    SeatCount: g.sum((t) => t.SeatCount),
                    People: g.sum((t) => t.People),
                    SeatCategory: g.first().SeatCategory
                      ? {
                          Id: g.first().SeatCategory.Id,
                          Name: g.first().SeatCategory.Name,
                          Colour: g.first().SeatCategory.Colour,
                          SeatCount: g.first().SeatCategory.SeatCount * group.count(),
                        }
                      : null,
                    Merchandise: g.first().Merchandise,
                    Discounts: g.selectMany((t) => t.Discounts).toArray(),
                  };
                })
                .toArray(),
            };

            var dateString = moment(group.first().EventDateAsString).format(dayFormat);

            const mergedTotals = {
              total: eventDateTotals.sum((p) => p.total),
              amount: eventDateTotals.sum((p) => p.soldTickets) + '/' + eventDateTotals.sum((p) => p.totalTickets),
              soldTickets: eventDateTotals.sum((p) => p.soldTickets),
              totalTickets: eventDateTotals.sum((p) => p.totalTickets),
              percentage: eventDateTotals.sum((p) => p.percentage) / eventDateTotals.count(),
            };

            return (
              <React.Fragment key={'date_row_byDay_' + day.format('YYYY-MM-DD')}>
                {getDateTimeNode(groupMerged, mergedTotals, dateString)}
              </React.Fragment>
            );
          }

          return (
            <EventSummary_TicketSalesRow
              key={'date_row_byDateTime_' + day.format('YYYY-MM-DD')}
              child={group
                .orderBy((g) => moment(g.EventDateAsString).unix())
                .toArray()
                .map((g) => {
                  var timeString = moment(g.EventDateAsString).format('h:mma');
                  const totals = getEventDateTotals(g);
                  return getDateTimeNode(g, totals, timeString);
                })}
              title={day.format(dayFormat)}
              subtitle={''}
              percentage={eventDateTotals.sum((p) => p.percentage) / eventDateTotals.count()}
              amount={`${eventDateTotals.sum((t) => t.soldTickets)}/${eventDateTotals.sum((t) => t.totalTickets)}`}
              total={CurrencyHelper.formatCurrency(
                props.currency,
                eventDateTotals.sum((p) => p.total),
              )}
            />
          );
        } else {
          var dateString = moment(group.first().EventDateAsString).format(dayFormat);
          var timeString = moment(group.first().EventDateAsString).format('h:mma');
          const totals = getEventDateTotals(group.first());
          return getDateTimeNode(group.first(), totals, dateString + ' at ' + timeString);
        }
      });
  }

  return (
    <div style={{ borderRadius: '6px', overflow: 'none' }}>
      {
        <EventSummary_TicketSalesRow
          key="event_row"
          child={rows}
          title={'Total'}
          subtitle={''}
          percentage={(totalSoldTickets / totalTickets) * 100.0}
          amount={totalSoldTickets + '/' + totalTickets}
          total={CurrencyHelper.formatCurrency(
            props.currency,
            ticketsEnum.sum((t) => {
              return (t as any).PriceAsInt;
            }) -
              ticketsEnum.sum((t) => {
                return t.Discounts && t.Discounts.length > 0 && linq.from(t.Discounts).sum((d) => d.Amount);
              }),
          )}
        />
      }
    </div>
  );
};

export default EventSummary_TicketSales;
