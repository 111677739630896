import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24">
      <path d="M6,1v2H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-1V1h-2v2H8V1H6z M5,8h14v11H5V8z" />
      <path d="M10.1,9.8c-0.2,0-0.4,0.2-0.4,0.4V11c0,0.2,0.2,0.4,0.4,0.4h0.8c0.2,0,0.4-0.2,0.4-0.4v-0.8c0-0.2-0.2-0.4-0.4-0.4H10.1z" />
      <path d="M13.3,9.8c-0.2,0-0.4,0.2-0.4,0.4V11c0,0.2,0.2,0.4,0.4,0.4h0.8c0.2,0,0.4-0.2,0.4-0.4v-0.8c0-0.2-0.2-0.4-0.4-0.4H13.3z" />
      <path d="M16.4,9.8c-0.2,0-0.4,0.2-0.4,0.4V11c0,0.2,0.2,0.4,0.4,0.4h0.8c0.2,0,0.4-0.2,0.4-0.4v-0.8c0-0.2-0.2-0.4-0.4-0.4H16.4z" />
      <path d="M7,12.7c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h0.8c0.2,0,0.4-0.2,0.4-0.4v-0.8c0-0.2-0.2-0.4-0.4-0.4H7z" />
      <path d="M10.1,12.7c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h0.8c0.2,0,0.4-0.2,0.4-0.4v-0.8c0-0.2-0.2-0.4-0.4-0.4H10.1z" />
      <path d="M13.3,12.7c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h0.8c0.2,0,0.4-0.2,0.4-0.4v-0.8c0-0.2-0.2-0.4-0.4-0.4H13.3z" />
      <path d="M16.4,12.7c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h0.8c0.2,0,0.4-0.2,0.4-0.4v-0.8c0-0.2-0.2-0.4-0.4-0.4H16.4z" />
      <path d="M7,15.6c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h0.8c0.2,0,0.4-0.2,0.4-0.4V16c0-0.2-0.2-0.4-0.4-0.4H7z" />
      <path d="M10.1,15.6c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h0.8c0.2,0,0.4-0.2,0.4-0.4V16c0-0.2-0.2-0.4-0.4-0.4H10.1z" />
      <path d="M13.3,15.6c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4h0.8c0.2,0,0.4-0.2,0.4-0.4V16c0-0.2-0.2-0.4-0.4-0.4H13.3z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
