import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
      <path d="M27.483,13.752L16.248,2.517c-0.689-0.689-1.807-0.689-2.497,0L2.517,13.752c-0.689,0.689-0.689,1.807,0,2.497 l11.235,11.235c0.689,0.689,1.807,0.689,2.497,0l11.235-11.235C28.172,15.559,28.172,14.441,27.483,13.752z M16.212,8l-0.2,9h-2.024 l-0.2-9H16.212z M15.003,22.189c-0.828,0-1.323-0.441-1.323-1.182c0-0.755,0.494-1.196,1.323-1.196c0.822,0,1.316,0.441,1.316,1.196 C16.319,21.748,15.825,22.189,15.003,22.189z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
