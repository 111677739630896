import linq from 'linq';
import React from 'react';
import NumberHelper from '../../../../helpers/NumberHelper';
import { IOrganisation } from '../../../../interfaces/IOrganisation';

interface IProps {
  organisation: IOrganisation;
  hide?: boolean;
  events: any;
  createEventClick: (organisation: IOrganisation) => void;
}

const OrganisationRow: React.FC<IProps> = (props) => {
  var soldTickets = linq.from(props.events).sum((e: any) => e.SoldTickets);

  if (!NumberHelper.isNumeric(soldTickets)) {
    soldTickets = 0;
  }

  var availableTickets = linq.from(props.events).sum((e: any) => e.AvailableTickets);

  if (isNaN(availableTickets)) {
    availableTickets = 0;
  }

  var soldTicketsValue = linq.from(props.events).sum((e: any) => e.SoldTicketsValue);

  var soldPercentage = (soldTickets / availableTickets) * 100.0;
  if (isNaN(soldPercentage)) {
    soldPercentage = 0;
  }

  if (props.hide) return null;

  return (
    <>
      <tr className="event-group organisation">
        <td className="event-image-container">
          <img className="event-image" style={{ display: 'inline-block' }} src={props.organisation.LogoUrl} />
        </td>
        <td>
          <div className="event-details">
            {props.organisation.Name}
            {props.organisation.OrganisationTag && props.organisation.OrganisationTag.length > 0 && <div className="">#{props.organisation.OrganisationTag}</div>}
          </div>
        </td>
        <td>
          {availableTickets > 0 && (
            <>
              {soldPercentage.toFixed(1)}
              <span style={{ color: '#b2bbc3' }}>%</span>
            </>
          )}
        </td>
        <td>
          {soldTickets}
          <span style={{ color: '#b2bbc3' }}>/{availableTickets}</span>
        </td>
        <td style={{ textAlign: 'right' }}>
          <span style={{ color: '#b2bbc3' }}>{props.events[0].CurrencySymbol}</span>
          <span>{(soldTicketsValue / 100.0).toFixed(2)}</span>
        </td>
      </tr>
    </>
  );
};

export default OrganisationRow;
