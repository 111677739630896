import { FunctionComponent } from 'react';
import NumberHelper from '../../helpers/NumberHelper';

export interface IProps {
  clickStart: (c: number, r: number) => void;
  clickEnd: (c: number, r: number) => void;
  w: number;
  c: number;
  r: number;
  mobileAndTablet: boolean;
}

const SeatingPlanPlaceholder: FunctionComponent<IProps> = (props) => {
  const { mobileAndTablet, w, c, r, clickStart, clickEnd } = props;
  let x = NumberHelper.roundDecimal((c - 1) * w + w / 2);
  let y = NumberHelper.roundDecimal((r - 1) * w + w / 2);

  return (
    <circle
      onMouseDown={!mobileAndTablet ? () => clickStart(c, r) : null}
      onMouseUp={!mobileAndTablet ? () => clickEnd(c, r) : null}
      onTouchStart={mobileAndTablet ? () => clickStart(c, r) : null}
      onTouchEnd={mobileAndTablet ? () => clickEnd(c, r) : null}
      className="placeholder"
      cx={x}
      cy={y}
      r={(props.w / 2) * 0.85}
      data-row={r}
      data-column={c}
    />
  );
};

export default SeatingPlanPlaceholder;
