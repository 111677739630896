export default class GuidHelper {

  public static guidPart() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  public static new() {
    return (
      GuidHelper.guidPart() +
      GuidHelper.guidPart() +
      '-' +
      GuidHelper.guidPart() +
      '-' +
      GuidHelper.guidPart() +
      '-' +
      GuidHelper.guidPart() +
      '-' +
      GuidHelper.guidPart() +
      GuidHelper.guidPart() +
      GuidHelper.guidPart()
    );
  }
}
