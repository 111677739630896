import React from 'react';
import InfoLabel from '../../../components/InfoLabel';
import ImageUploader, { IImageUploadRequest, ImageRequestGroup } from '../ImageUploader';
import Toolbar from '../toolbar/Toolbar';
import { IOrganisation } from '../../../interfaces/IOrganisation';

export interface IProps {
  organisation: IOrganisation;
  onImageRequested: (request: IImageUploadRequest) => void;
  imageRequests: { [key: string]: IImageUploadRequest };
  globalOptions: JSX.Element;
}

const ImageSection: React.FC<IProps> = (props) => {
  const { organisation, imageRequests, onImageRequested } = props;

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields" style={{ maxWidth: '400px' }}>
            <h2>Image</h2>
            <div className="section" style={{ marginBottom: '0' }}>
              <div className="section">
                <InfoLabel text="What is your organisations main image?" tooltip="This image will represent your organisation in searches and be visible on the main organisation page. "></InfoLabel>

                <ImageUploader
                  organisationId={organisation.Id}
                  url={imageRequests[ImageRequestGroup.Organisation] ? imageRequests[ImageRequestGroup.Organisation].url : organisation.LogoUrl}
                  onChange={(request) => onImageRequested(request)}
                  group={ImageRequestGroup.Organisation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageSection;
