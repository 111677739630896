import Cookies from 'js-cookie';
import linq from 'linq';
import { FunctionComponent, useEffect, useState } from 'react';
import { IEvent } from '../../../../interfaces/IEvent';
import ReactApexChart from 'react-apexcharts';

export interface IProps {
  event: IEvent;
  rowData: any;
  getName: (name) => string;
  includeReferrals: boolean;
  setSelectedEmail: (email) => void;
  showMembers: boolean;
}

const AdminBalancesChart: FunctionComponent<IProps> = (props) => {
  const height = 100 + props.rowData.length * (props.showMembers ? 40 : 25);

  const ticketsSeries = {
    name: 'Ticket Sales',
    data: props.rowData.map((rowData) => {
      var ticketCount = rowData.Tickets - (props.includeReferrals ? rowData.ReferralsGiven : 0);

      var quota = 0;
      if (rowData.Quota != null && rowData.Quota > 0) {
        quota = rowData.Quota;
      }

      if (rowData.MemberType == null || rowData.MemberType.length == 0) {
        rowData.MemberType = 'Member';
      }

      return ticketCount;
    }),
  };

  const referralsSeries = {
    name: 'Referrals',
    data: props.rowData.map((rowData) => {
      return rowData.ReferralsReceived;
    }),
  };

  const state: any = {
    series: props.includeReferrals ? [ticketsSeries, referralsSeries] : [ticketsSeries],
    options: {
      chart: {
        type: 'bar',
        height: height,
        stacked: true,
        events: {
          xAxisLabelClick: function (event, chartContext, config) {
            console.log(event);
            console.log(chartContext);
            console.log(config);

            props.setSelectedEmail(props.rowData[config.labelIndex].Email);
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              formatter: function (val, opt) {
                if (props.includeReferrals) {
                  const tickets = opt.w.config.series[0].data[opt.dataPointIndex];
                  const referrals = opt.w.config.series[1].data[opt.dataPointIndex];

                  return referrals > 0 ? (tickets > 0 ? referrals + tickets : '') : '';
                }

                return '';
              },
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 500,
              },
            },
          },
        },
      },
      colors: props.includeReferrals ? ['#102b35', '#625afa', '#8ccfb9'] : ['#102b35', '#8ccfb9'],
      dataLabels: {
        formatter: function (val) {
          return val;
        },
      },
      xaxis: {
        categories: props.rowData.map((rowData) => {
          if (!props.showMembers) return props.getName(rowData);

          if (rowData.Quota && rowData.Quota > 0) {
            return [props.getName(rowData), `${props.event.Organisation.MembershipsEnabled && rowData.MemberType ? rowData.MemberType : ''} (${rowData.Quota} Quota)`];
          } else {
            return [props.getName(rowData), `${props.event.Organisation.MembershipsEnabled && rowData.MemberType ? rowData.MemberType : ''}`];
          }
        }),
        labels: {},
      },
      yaxis: {
        title: {
          text: undefined,
        },
        labels: {
          offsetY: props.showMembers ? 6 : 2,
          style: {
            cssClass: props.showMembers ? 'apexcharts-label-button apexcharts-label-button-two-lines' : 'apexcharts-label-button',
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
        show: true,
        showForSingleSeries: true,
        customLegendItems: props.includeReferrals ? ['Tickets', 'Referrals'] : ['Tickets'],
        markers: {
          fillColors: props.includeReferrals ? ['#102b35', '#625afa'] : ['#102b35'],
        },
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="bar" height={height} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default AdminBalancesChart;
