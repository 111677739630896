import React, { useState } from 'react';
import './Slider.scss';

// Define the props for the component
interface SliderProps {
  min: number;
  max: number;
  step: number;
  initialZoom: number;
  onChange: (value: number) => void;
}

// A functional component using the SliderProps
const Slider: React.FC<SliderProps> = ({ min, max, step, initialZoom, onChange }) => {
  const [zoom, setZoom] = useState<number>(initialZoom);

  // Handle the change in slider
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setZoom(newValue);
    onChange(newValue); // Notify parent component about the change
  };

  return (
    <div className="slider-container">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={zoom}
        onChange={handleSliderChange}
        className="slider" // You can define this class in your CSS for styling
      />
    </div>
  );
};

export default Slider;
