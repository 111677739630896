import React, { useEffect, useState } from 'react';
import GeneralApi from '../api/GeneralApi';
import Loader from '../components/Loader';
import UserHelper from '../helpers/UserHelper';
import { IEvent } from '../interfaces/IEvent';
import { IOrder } from '../interfaces/IOrder';
import { ITicket } from '../interfaces/ITicket';
import IUserOrderOverview from '../interfaces/IUserOrderOverview';
import BalanceSection from '../views/UserEventOverview/sections/BalanceSection';
import DashboardSection from '../views/UserEventOverview/sections/DashboardSection';
import NotesSection from '../views/UserEventOverview/sections/NotesSection';
import OrderSection from '../views/UserEventOverview/sections/OrderSection';
import OrdersSection from '../views/UserEventOverview/sections/OrdersSection';
import PaymentsSection from '../views/UserEventOverview/sections/PaymentsSection';
import ReferralsSection from '../views/UserEventOverview/sections/ReferralsSection';
import RequestsSection from '../views/UserEventOverview/sections/RequestsSection';
import ShareTicketsSection from '../views/UserEventOverview/sections/ShareTicketsSection';

export interface IProps {
  event: IEvent;
}

export enum Section {
  Dashboard = 'Dashboard',
  Orders = 'Orders',
  Order = 'Order',
  Payments = 'Payments',
  Referrals = 'Referrals',
  Requests = 'Requests',
  Balance = 'Balance',
  Notes = 'EditNotes',
  ShareTickets = 'ShareTickets',
  Refunds = 'Refunds',
}

const UserEventOverview: React.FC<IProps> = (props) => {
  const { event } = props;

  const [loading, setLoading] = useState<string>('Loading your tickets');
  const [overview, setOverview] = useState<IUserOrderOverview>(null);
  const [selectedOrder, setSelectedOrder] = useState<IOrder>(null);
  const [selectedTicket, setSelectedTicket] = useState<ITicket>(null);
  const [section, setSection] = useState<Section>(Section.Dashboard);

  useEffect(() => {
    loadUserOrderOverview();
  }, []);

  const loadUserOrderOverview = () => {
    setLoading('Loading your tickets');

    GeneralApi.request('POST', '/api/UserOrderOverview', {
      eventId: event.Id,
      email: UserHelper.currentUser.Email,
      includeCancelled: true,
    })
      .then((result) => {
        setOverview(result);
        setLoading(null);
      })
      .catch((message) => {
        alert(message);
        setLoading(null);
      });
  };

  if (overview == null || loading) return <Loader inline={true}>Loading event...</Loader>;

  const sections: { [key: string]: any } = {};
  sections[Section.Dashboard] = <DashboardSection setSection={setSection} overview={overview} event={event} loadUserOrderOverview={loadUserOrderOverview} />;
  sections[Section.Orders] = <OrdersSection setSection={setSection} overview={overview} event={event} setSelectedOrder={setSelectedOrder} />;
  sections[Section.Order] = <OrderSection setSection={setSection} selectedOrder={selectedOrder} event={event} setSelectedTicket={setSelectedTicket} />;
  sections[Section.Notes] = <NotesSection overview={overview} setSection={setSection} selectedOrder={selectedOrder} setOverview={setOverview} selectedTicket={selectedTicket} />;
  sections[Section.ShareTickets] = <ShareTicketsSection setSection={setSection} selectedOrder={selectedOrder} setLoading={setLoading} />;
  sections[Section.Referrals] = <ReferralsSection setSection={setSection} overview={overview} />;
  sections[Section.Requests] = <RequestsSection setSection={setSection} overview={overview} event={event} />;
  sections[Section.Payments] = <PaymentsSection refunds={false} setSection={setSection} overview={overview} />;
  sections[Section.Refunds] = <PaymentsSection refunds={true} setSection={setSection} overview={overview} />;
  sections[Section.Balance] = (
    <BalanceSection
      onPaymentAccepted={() => {
        setSection(Section.Dashboard);
        loadUserOrderOverview();
      }}
      setSection={setSection}
      overview={overview}
      event={event}
    />
  );
  if (sections[section]) return sections[section];
};

export default UserEventOverview;
