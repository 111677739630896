import React, { useState } from 'react';
import GeneralApi from '../../../api/GeneralApi';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import Button from '../../../components/Button';
import BlockInfo from '../../../components/BlockInfo';
import SpacerTable from '../../../components/SpacerTable';
import BlockTextBox from '../../../components/BlockTextBox';
import EmailHelper from '../../../helpers/EmailHelper';
import { IOrder } from '../../../interfaces/IOrder';
import { Section } from '../../../modals/UserEventOverview';

export interface IProps {
  setSection: (value: Section) => void;
  setLoading: (value: string) => void;
  selectedOrder: IOrder;
}

const ShareTicketsSection: React.FC<IProps> = (props) => {
  const { setSection, setLoading, selectedOrder } = props;

  const [shareTicketsEmail, setShareTicketsEmail] = useState('');

  const shareTickets = () => {
    if (!EmailHelper.validate(shareTicketsEmail)) {
      alert('Please enter a valid email address');
      return;
    }

    var value = shareTicketsEmail;

    setLoading('Sharing your tickets...');

    GeneralApi.request('POST', '/api/ShareTickets', {
      SentToEmail: value,
      OrderId: selectedOrder.Id,
    })
      .then((response) => {
        setLoading(null);
        setSection(Section.Order);
        alert('You have shared your tickets!');
      })
      .catch((message) => {
        alert(message);
        setLoading(null);
      });
  };

  return (
    <>
      <SpacerTable>
        <h1>Share your Tickets!</h1>
      </SpacerTable>

      <div className="spacer" />
      <table className="blocks">
        <tbody>
          <BlockInfo>
            Send a copy of the {selectedOrder.Seats.length} tickets for order #{selectedOrder.Id} to any email address that you want. Just remember that tickets can only be used once at an event and
            when you share them with someone they will have access to print them. Only share tickets with someone you trust!
          </BlockInfo>
          <Block>
            <BlockHeader>Recipient Email</BlockHeader>
            <BlockTextBox
              onKeyPress={(e) => {
                if (e.charCode == 13) shareTickets();
              }}
              type="email"
              autoFocus
              value={shareTicketsEmail}
              onChange={(e) => setShareTicketsEmail(e)}
            />
          </Block>
        </tbody>
      </table>
      <div className="spacer" />
      <SpacerTable>
        <Button className="confirm" disabled={!EmailHelper.validate(shareTicketsEmail)} onExecute={shareTickets} text={'Share Tickets'} />
      </SpacerTable>
      <div className="spacer" />
      <SpacerTable>
        <Button onExecute={() => setSection(Section.Order)} text={'Back to order'} />
      </SpacerTable>
    </>
  );
};

export default ShareTicketsSection;
