import linq from 'linq';
import { IDiscount } from '../interfaces/IDiscount';
import { IOrderSummary } from '../interfaces/IOrderSummary';
import { ITicket } from '../interfaces/ITicket';

interface IFees {
  organisationFee: number;
  handlingFee: number;
  seatyFee: number;
  allFees: number;
  total: number;
}

export default class OrderHelper {
  public static getFees(
    _total: number,
    isCardPayment: boolean,
    feeMin: number,
    feePercentage: number,
    eventHandlingFee: number,
    eventHandlingFeePercentage: number,
    absorbFee: boolean,
    organisationFeePercentage: number
  ): IFees {
    let organisationFee = 0.0;
    let total = _total;

    if (organisationFeePercentage != null && organisationFeePercentage > 0 && isCardPayment) {
      organisationFee = parseFloat(total.toString()) * (organisationFeePercentage / 100.0);
      total += organisationFee;
    }

    let handlingFee = 0.0;
    if (isCardPayment) {
      if (eventHandlingFee != null && eventHandlingFee > 0) {
        handlingFee += eventHandlingFee;
      }
      if (eventHandlingFeePercentage != null && eventHandlingFeePercentage > 0.0) {
        handlingFee += (total + handlingFee) * (eventHandlingFeePercentage / 100.0);
      }
    }
    total += handlingFee;

    let seatyFee = 0.0;
    if (!absorbFee && isCardPayment) {
      seatyFee = parseFloat(total.toString()) * (feePercentage / 100.0);
      if (seatyFee != 0 && seatyFee < feeMin) {
        seatyFee = feeMin;
      }
      total += seatyFee;
    }

    return {
      organisationFee: organisationFee,
      handlingFee: handlingFee,
      seatyFee: seatyFee,
      allFees: handlingFee + organisationFee + seatyFee,
      total: total,
    };
  }

  public static getOrderSummary(
    isCardPayment: boolean,
    tickets: ITicket[],
    absorbFee: boolean,
    feeMin: number,
    feePercentage: number,
    activeDiscount: IDiscount,
    eventHandlingFee: number,
    eventHandlingFeePercentage: number,
    organisationFeePercentage: number
  ): IOrderSummary {
    let subTotal = linq.from(tickets).sum((ticket: ITicket) => {
      return ticket.TicketCategory.PriceAsInt * (ticket.Quantity == null ? 1 : ticket.Quantity);
    });

    let ticketQuantity = linq.from(tickets).sum((ticket: ITicket) => {
      return ticket.Quantity;
    });

    let total = subTotal;

    if (activeDiscount != null) {
      total -= activeDiscount.Amount * 100.0;
      if (total < 0) {
        total = 0;
      }
    }

    const fees = OrderHelper.getFees(total, isCardPayment, feeMin, feePercentage, eventHandlingFee, eventHandlingFeePercentage, absorbFee, organisationFeePercentage);

    return {
      organisationFee: fees.organisationFee,
      handlingFee: fees.handlingFee,
      seatyFee: fees.seatyFee,
      allFees: fees.allFees,
      subTotal: subTotal,
      ticketQuantity: ticketQuantity,
      total: fees.total,
      activeDiscount: activeDiscount,
    };
  }
}
