import linq from 'linq';
import React from 'react';
import CurrencyHelper from '../../../../../helpers/CurrencyHelper';
import NumberHelper from '../../../../../helpers/NumberHelper';
import { IOrganisation } from '../../../../../interfaces/IOrganisation';
import { IStatementRow } from './IStatementRow';

interface IProps {
  invoicePeriod: string;
  ledgerOrders: Array<IStatementRow>;
  ledgerPurchases: Array<IStatementRow>;
  ledgerRefunds: Array<IStatementRow>;
  showVAT: boolean;
  organisation: IOrganisation;
}

const StatementTotalsTable: React.FC<IProps> = (props) => {
  const { invoicePeriod, ledgerPurchases, ledgerOrders, ledgerRefunds, showVAT, organisation } = props;

  const jointLinq = linq.from(ledgerRefunds.concat(ledgerPurchases).concat(ledgerOrders));

  const feesLostForRefunds = linq
    .from(props.ledgerRefunds)
    .where((r) => !r.StripeConnect)
    .groupBy((g) => g.OrganisationId);

  const feesLost =
    organisation.OrganisationTag.toLowerCase() === 'seaty'
      ? linq
          .from(props.ledgerRefunds)
          .where((r) => !r.StripeConnect)
          .sum((g) => g.GatewayFeeRefunded ?? 0)
      : 0;

  return (
    <>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <th style={{ width: '10%' }}>{invoicePeriod}</th>
            <th style={{ width: '10%', textAlign: 'right' }}>Amount</th>
            {jointLinq.any((p) => p.StripeConnect) && <th style={{ width: '10%', textAlign: 'right' }}>Stripe fee</th>}
            <th style={{ width: '10%', textAlign: 'right' }}>Seaty fee</th>
            {showVAT && <th style={{ width: '10%', textAlign: 'right' }}>VAT</th>}
          </tr>

          {ledgerOrders &&
            ledgerOrders.length > 0 &&
            linq
              .from(ledgerOrders)
              .groupBy((p) => p.CurrencySymbol)
              .select((p) => (
                <tr>
                  <td>Orders ({p.first().CurrencyName})</td>
                  <td style={{ textAlign: 'right' }}>
                    {CurrencyHelper.formatCurrency(
                      p.first().CurrencySymbol,
                      p.sum((o) => o.TotalPrice),
                    )}
                  </td>

                  {organisation.OrganisationTag.toLowerCase() === 'seaty' || p.any((pp) => pp.StripeConnect) ? (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        p.first().CurrencySymbol,
                        p.sum((o) => o.GatewayFee),
                      )}
                    </td>
                  ) : jointLinq.any((p) => p.StripeConnect) ? (
                    <td></td>
                  ) : null}

                  {organisation.OrganisationTag.toLowerCase() === 'seaty' ? (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        '£',
                        p.sum((o) => o.SeatyFeeGBP),
                      )}
                    </td>
                  ) : p.any((pp) => pp.StripeConnect) ? (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        p.first().CurrencySymbol,
                        p.sum((o) => o.SeatyFee),
                      )}
                    </td>
                  ) : (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        p.first().CurrencySymbol,
                        p.sum((o) => o.SeatyFee + o.GatewayFee),
                      )}
                    </td>
                  )}

                  {showVAT && (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        '£',
                        p.sum((o) => o.VATAdjustment),
                      )}
                    </td>
                  )}
                </tr>
              ))}

          {ledgerPurchases &&
            ledgerPurchases.length > 0 &&
            linq
              .from(ledgerPurchases)
              .groupBy((p) => p.CurrencySymbol)
              .select((p) => (
                <tr>
                  <td>Purchases ({p.first().CurrencyName})</td>
                  <td style={{ textAlign: 'right' }}>
                    {CurrencyHelper.formatCurrency(
                      p.first().CurrencySymbol,
                      p.sum((o) => o.TotalPrice),
                    )}
                  </td>

                  {organisation.OrganisationTag.toLowerCase() === 'seaty' || p.any((pp) => pp.StripeConnect) ? (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        p.first().CurrencySymbol,
                        p.sum((o) => o.GatewayFee),
                      )}
                    </td>
                  ) : jointLinq.any((p) => p.StripeConnect) ? (
                    <td></td>
                  ) : null}

                  {organisation.OrganisationTag.toLowerCase() === 'seaty' ? (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        '£',
                        p.sum((o) => o.SeatyFeeGBP),
                      )}
                    </td>
                  ) : p.any((pp) => pp.StripeConnect) ? (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        p.first().CurrencySymbol,
                        p.sum((o) => o.SeatyFee),
                      )}
                    </td>
                  ) : (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        p.first().CurrencySymbol,
                        p.sum((o) => o.SeatyFee + o.GatewayFee),
                      )}
                    </td>
                  )}

                  {showVAT && (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        '£',
                        p.sum((o) => o.VATAdjustment),
                      )}
                    </td>
                  )}
                </tr>
              ))}

          {ledgerRefunds &&
            ledgerRefunds.length > 0 &&
            linq
              .from(ledgerRefunds)
              .groupBy((p) => p.CurrencySymbol)
              .select((p) => (
                <tr>
                  <td>Purchase Refunds ({p.first().CurrencyName})</td>
                  <td style={{ textAlign: 'right' }}>
                    {CurrencyHelper.formatCurrency(
                      p.first().CurrencySymbol,
                      p.sum((o) => 0 - o.RefundAmount + (!o.AbsorbFee ? o.GatewayFeeRefunded + o.SeatyFeeGBPRefunded : 0)),
                    )}
                  </td>

                  {organisation.OrganisationTag.toLowerCase() === 'seaty' || p.any((pp) => pp.StripeConnect) ? (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        p.first().CurrencySymbol,
                        p.sum((o) => 0 - o.GatewayFeeRefunded),
                      )}
                    </td>
                  ) : jointLinq.any((p) => p.StripeConnect) ? (
                    <td></td>
                  ) : null}

                  {organisation.OrganisationTag.toLowerCase() === 'seaty' ? (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        '£',
                        p.sum((o) => 0 - o.SeatyFeeGBPRefunded),
                      )}
                    </td>
                  ) : p.any((pp) => pp.StripeConnect) ? (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        p.first().CurrencySymbol,
                        p.sum((o) => 0 - o.SeatyFeeRefunded),
                      )}
                    </td>
                  ) : (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        p.first().CurrencySymbol,
                        p.sum((o) => 0 - (o.SeatyFeeRefunded + o.GatewayFeeRefunded)),
                      )}
                    </td>
                  )}

                  {showVAT && (
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        '£',
                        p.sum((o) => o.VATAdjustment),
                      )}
                    </td>
                  )}
                </tr>
              ))}

          {organisation.OrganisationTag.toLowerCase() === 'seaty' &&
            feesLostForRefunds &&
            feesLostForRefunds.length > 0 &&
            feesLostForRefunds
              .select((r) => {
                return (
                  <tr key={r.first().OrganisationId}>
                    <td colSpan={1}>#{r.first().OrganisationTag}</td>
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        r.first().CurrencySymbol,
                        r.sum((o) => 0 - o.RefundAmount),
                      )}
                    </td>

                    {jointLinq.any((p) => p.StripeConnect) && <td style={{ textAlign: 'right' }}>-</td>}

                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        jointLinq.first().CurrencySymbol,
                        r.sum((o) => 0 - (NumberHelper.isNumeric(o.GatewayFeeRefunded) ? o.GatewayFeeRefunded : 0)),
                      )}
                    </td>

                    <td style={{ textAlign: 'right' }}>-</td>
                  </tr>
                );
              })
              .toArray()}

          {jointLinq && jointLinq.count() > 0 && (
            <tr>
              <td></td>
              <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                {CurrencyHelper.formatCurrency(
                  jointLinq.first().CurrencySymbol,
                  jointLinq && jointLinq.count() > 0
                    ? linq.from(jointLinq).sum((o) => (o.RefundAmount && o.RefundAmount > 0 ? 0 - o.RefundAmount + (!o.AbsorbFee ? o.GatewayFeeRefunded + o.SeatyFeeGBPRefunded : 0) : o.TotalPrice))
                    : 0,
                )}
              </td>

              {(jointLinq.any((p) => p.StripeConnect) || organisation.OrganisationTag.toLowerCase() === 'seaty') && (
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {CurrencyHelper.formatCurrency(
                    jointLinq.first().CurrencySymbol,
                    linq.from(jointLinq).sum((o) => (o.RefundAmount && o.RefundAmount > 0 ? 0 - o.GatewayFeeRefunded : o.GatewayFee)),
                  )}
                </td>
              )}

              {organisation.OrganisationTag.toLowerCase() === 'seaty' ? (
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {CurrencyHelper.formatCurrency('£', linq.from(jointLinq).sum((o) => (o.RefundAmount && o.RefundAmount > 0 ? 0 - o.SeatyFeeGBPRefunded : o.SeatyFeeGBP)) - feesLost)}
                </td>
              ) : (
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {CurrencyHelper.formatCurrency(
                    jointLinq.first().CurrencySymbol,
                    linq.from(jointLinq).sum((o) => {
                      if (o.StripeConnect) {
                        return o.RefundAmount && o.RefundAmount > 0 ? 0 - o.SeatyFeeRefunded : o.SeatyFee;
                      } else {
                        return o.RefundAmount && o.RefundAmount > 0 ? 0 - (o.SeatyFeeRefunded + o.GatewayFeeRefunded) : o.SeatyFee + o.GatewayFee;
                      }
                    }),
                  )}
                </td>
              )}

              {showVAT && (
                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {CurrencyHelper.formatCurrency(
                    '£',
                    linq.from(jointLinq).sum((o) => o.VATAdjustment),
                  )}
                </td>
              )}
            </tr>
          )}

          {/* {feesLost > 0 && (
            <tr>
              <td style={{ fontWeight: 'bold', textAlign: 'left' }}>Total Stripe Losses</td>
              <td style={{ fontWeight: 'bold', textAlign: 'right' }}>-</td>

              {jointLinq.any((p) => p.StripeConnect) && <td style={{ fontWeight: 'bold', textAlign: 'right' }}>-</td>}

              <td style={{ fontWeight: 'bold', textAlign: 'right' }}> {CurrencyHelper.formatCurrency('£', feesLost)}</td>

              {showVAT && <td style={{ fontWeight: 'bold', textAlign: 'right' }}>-</td>}
            </tr>
          )} */}
        </tbody>
      </table>
    </>
  );
};

export default StatementTotalsTable;
