import { FunctionComponent, useRef, useState } from 'react';
import SVGHelper from '../../helpers/SVGHelper';
import SVGImage from '../../svg/SVGImage';
import Cropper, { Area, Point } from 'react-easy-crop';
import Modal from '../../components/Modal';
import SpacerTable from '../../components/SpacerTable';
import Slider from '../../components/Slider';

export interface IProps {
  onChange: (request: IImageUploadRequest) => void;
  className?: string;
  url: string;
  group: ImageRequestGroup;
  eventId?: number;
  organisationId?: number;
  tourId?: number;
  type?: ImageUploaderType;
  accept?: string;
  cropAspect?: number;
  cropRounded?: boolean;
}

export enum ImageRequestGroup {
  Event = 'bannerFilePhoto',
  Organisation = 'organisationlogo',
  Tour = 'tourlogo',
  Theme = 'themeHeader',
  ThemeBackground = 'themeBackground',
  SeatingPlanBackground = 'seatingPlanBackground',
  EventPersonnel = 'eventPersonnel',
  OrganisationPersonnel = 'organisationPersonnel',
}

export enum ImageUploaderType {
  Viewer = 'viewer',
  Button = 'button',
}

export interface IImageUploadRequest {
  url: string;
  eventId?: number;
  organisationId?: number;
  tourId?: number;
  file: File;
  group: ImageRequestGroup;
}

const ImageUploader: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const uploaderRef = useRef<HTMLInputElement>();
  const type = props.type ? props.type : ImageUploaderType.Viewer;
  const accept = props.accept && props.accept.length > 0 ? props.accept : '.jpeg, .jpg, .png';

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const [showCropper, setShowCropper] = useState(false);
  const [pendingChange, setPendingChange] = useState(null);

  const createImageCrop = async () => {
    const image = new Image();
    // Assuming this is your base64 image string or you can replace it with the image URL
    // Make sure to use `crossOrigin` if you are loading an image from a different origin to avoid CORS issues
    image.src = pendingChange.url;
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    // Adjust drawing parameters considering the zoom.
    // This ensures we draw the correctly zoomed portion of the image.
    ctx.drawImage(
      image,
      croppedAreaPixels.x, // Start clipping x
      croppedAreaPixels.y, // Start clipping y
      croppedAreaPixels.width, // Clipping width
      croppedAreaPixels.height, // Clipping height
      0, // Place the result at the start of the canvas
      0, // Place the result at the start of the canvas
      croppedAreaPixels.width, // Width of the result (same as clipping width)
      croppedAreaPixels.height, // Height of the result (same as clipping height)
    );

    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
  };

  const form = (
    <>
      <form style={type == ImageUploaderType.Button ? { width: 0, height: 0, opacity: 0 } : {}} encType="multipart/form-data">
        <input type="hidden" name="imagetype" value="1" />
        <input
          onChange={(e: any) => {
            var reader = new FileReader();
            const file = e.target.files[0];

            reader.onload = function (_e: any) {
              const change = {
                organisationId: props.organisationId,
                tourId: props.tourId,
                eventId: props.eventId,
                group: props.group,
                url: _e.target.result,
                file: file,
              };

              if (props.cropAspect) {
                setPendingChange(change);
              } else {
                props.onChange(change);
              }
            };

            if (e.target && e.target.files && e.target.files.length > 0) {
              reader.readAsDataURL(e.target.files[0]);
            }

            if (props.cropAspect && props.cropAspect > 0) {
              setShowCropper(true);
            }
          }}
          ref={uploaderRef}
          className="filePhoto uploadinput"
          accept={accept}
          type="file"
          name="files[]"
          multiple
        />
      </form>
    </>
  );

  const base64ToBlob = (base64, type = 'image/jpeg') => {
    const binaryString = window.atob(base64.split(',')[1]); // Decode base64
    const len = binaryString.length;
    const binaryArray = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      binaryArray[i] = binaryString.charCodeAt(i);
    }

    return new Blob([binaryArray], { type });
  };

  if (type == ImageUploaderType.Viewer) {
    return (
      <>
        <div
          style={{ overflow: 'hidden' }}
          className={'uploader filephotoimagecontainer' + (props.className == null ? '' : ' ' + props.className) + (props.url == null || props.url.length == 0 ? ' empty' : '')}
        >
          {props.cropAspect && showCropper && pendingChange && (
            <>
              <Modal className="admin-menu-modal cropper-modal" theme={null} onCloseClick={() => setShowCropper(false)}>
                <div className="content">
                  <div className="ticket-rip dark" />

                  <div className="body" style={{ paddingTop: 0 }}>
                    <div className="modal-header-dark">
                      <SpacerTable>
                        <h1>Crop Image</h1>
                      </SpacerTable>
                    </div>
                    <div className={`crop-container ${props.cropRounded ? ' crop-container-rounded' : ''}`}>
                      <Cropper image={pendingChange.url} crop={crop} zoom={zoom} aspect={props.cropAspect} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
                    </div>
                    <Slider initialZoom={zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" onChange={(zoom) => setZoom(Number(zoom))} />

                    <div className="spacer"></div>
                    <SpacerTable>
                      <button
                        className="button confirm large"
                        onClick={async () => {
                          const base64Image = await createImageCrop();
                          const imageBlob = base64ToBlob(base64Image);
                          const imageFile = new File([imageBlob], 'croppedImage.jpeg', { type: 'image/jpeg' });

                          props.onChange({ ...pendingChange, url: base64Image, file: imageFile });
                          setPendingChange(null);
                          setShowCropper(false);
                        }}
                      >
                        Done
                      </button>
                    </SpacerTable>
                  </div>

                  <div className="ticket-rip bottom" />
                </div>
              </Modal>
            </>
          )}
          {props.url != null && props.url.length != 0 ? (
            <>
              <img src={props.url} className="filephotoimage" />
            </>
          ) : (
            <span className="emptyimage">
              <SVGImage />
              <p>Click to add image</p>
            </span>
          )}
          {form}
        </div>
      </>
    );
  } else if (type == ImageUploaderType.Button) {
    return (
      <button
        className={'button-image-uploader ' + (props.className == null ? '' : ' ' + props.className) + (props.url == null || props.url.length == 0 ? ' empty' : '')}
        onClick={() => {
          if (uploaderRef.current) {
            uploaderRef.current.click();
          }
        }}
      >
        {form}
        {props.children}
      </button>
    );
  }
};

export default ImageUploader;
