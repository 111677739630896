import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
      <path d="M 25 4 C 12.328125 4 2 12.878906 2 23.996094 C 2 30.445313 5.53125 36.0625 10.960938 39.730469 C 10.953125 39.945313 10.96875 40.296875 10.6875 41.34375 C 10.335938 42.644531 9.625 44.472656 8.1875 46.535156 L 7.164063 48 L 8.953125 48 C 15.140625 48 18.722656 43.964844 19.277344 43.316406 C 21.121094 43.75 23.015625 43.984375 25 43.984375 C 37.667969 43.984375 48 35.105469 48 23.992188 C 48 12.878906 37.671875 4 25 4 Z M 25 6 C 36.578125 6 46 14.070313 46 23.996094 C 46 33.917969 36.578125 41.988281 25 41.988281 C 23.230469 41.988281 21.507813 41.785156 19.734375 41.371094 L 18.546875 41.09375 L 17.757813 42.015625 C 17.074219 42.816406 14.773438 45.167969 10.984375 45.828125 C 11.710938 44.523438 12.257813 43.195313 12.613281 41.863281 C 12.894531 40.832031 12.945313 40.289063 12.957031 39.8125 L 13.003906 38.699219 L 12.078125 38.074219 C 6.945313 34.601563 4 29.46875 4 23.996094 C 4 14.070313 13.421875 6 25 6 Z M 25 13.597656 C 23 13.597656 21.402344 14.199219 20.300781 15.402344 C 19.101563 16.601563 18.601563 18.199219 18.5 20.097656 L 21 20.097656 C 21.101563 19 21.300781 18 21.597656 17.402344 C 22.199219 16.300781 23.300781 15.699219 24.902344 15.699219 C 26.101563 15.699219 27.097656 16 27.597656 16.699219 C 28.097656 17.300781 28.402344 18.101563 28.402344 19 C 28.402344 19.699219 28.199219 20.300781 27.800781 20.902344 C 27.601563 21.300781 27.300781 21.601563 27 21.902344 L 25.902344 23 C 24.800781 24.101563 24.101563 25 23.800781 25.800781 C 23.5 26.601563 23.300781 27.699219 23.300781 29 L 25.800781 29 C 25.800781 27.800781 25.898438 27 26.199219 26.5 C 26.5 25.898438 27.101563 25.199219 28 24.300781 C 29.300781 23.101563 30.101563 22.199219 30.5 21.597656 C 30.898438 21 31.097656 20.199219 31.097656 19.199219 C 31.097656 17.597656 30.5 16.199219 29.402344 15.199219 C 28.300781 14.097656 26.800781 13.597656 25 13.597656 Z M 23.300781 31.300781 L 23.300781 34.199219 L 26 34.199219 L 26 31.300781 Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
