import linq from 'linq';
import GeneralApi from '../api/GeneralApi';
import { DiscountTypes } from '../enums/DiscountTypes';
import { IDiscount } from '../interfaces/IDiscount';
import { ITicket } from '../interfaces/ITicket';
import { IEvent } from '../interfaces/IEvent';
import moment from 'moment';
import TicketService from './TicketService';

export default class DiscountService {
  public static checkDiscountCode(eventId: number, code: string): Promise<IDiscount> {
    return GeneralApi.request('POST', '/api/DiscountCode', { Code: code, EventId: eventId });
  }

  public static getValidDiscountsForTickets(event: IEvent, discounts: Array<IDiscount>, tickets: ITicket[]): Array<IDiscount> {
    if (discounts == null || discounts.length === 0) return [];

    const seatedTickets = TicketService.groupSeatedTickets(tickets);
    const generalTickets = TicketService.groupGeneralTickets(tickets);

    const groupedTickets: Array<ITicket> = seatedTickets.concat(generalTickets);

    let result: Array<IDiscount> = [];
    let totalTickets = linq.from(groupedTickets).sum((c) => {
      return c.Quantity;
    });

    discounts.forEach((discount) => {
      let discountValid = true;
      if (discount.Quantity > totalTickets) {
        // Discount not valid because min quantity has not been met.
        discountValid = false;
      }

      if (discountValid && discount.Expires && discount.Expires.length > 0) {
        const now = event && moment().tz(event.TimeZoneIana);
        const expires = moment(discount.Expires).tz(event.TimeZoneIana);

        if (expires <= now) {
          discountValid = false;
        }
      }

      // Check the criteria of the discount.
      if (discountValid && discount.Criteria != null && discount.Criteria.length > 0) {
        discount.Stacks = 0;

        discount.Criteria.forEach((criteria) => {
          const ticketsMatchingGuid = linq
            .from(groupedTickets)
            .where((ticket) => ticket.TicketCategory.Guid == criteria.Guid)
            .sum((t) => t.Quantity);

          const amountRequiredInThisOrder = criteria.Amount;

          criteria.Stacked = Math.floor(ticketsMatchingGuid / amountRequiredInThisOrder);

          if (ticketsMatchingGuid < amountRequiredInThisOrder) {
            //Discount is not valid because there are not enough of the ticket in it to meet criteria.
            discountValid = false;
          }
        });

        discount.Stacks = linq.from(discount.Criteria).min((c) => c.Stacked);
      }

      let subTotal = linq.from(groupedTickets).sum((c) => {
        return c.Price / 100.0;
      });

      // Add it to valid discounts if valid.
      if (discountValid) {
        switch (discount.DiscountType) {
          case DiscountTypes.Percentage:
            discount.Amount = subTotal * (discount.Percentage / 100);
            break;

          case DiscountTypes.FixedAmountOffOrder:
            if (discount.Criteria && discount.Criteria.length > 0 && discount.Stack) {
              discount.Amount = discount.FixedAmount * discount.Stacks;
            } else {
              discount.Amount = discount.FixedAmount;
            }

            break;

          case DiscountTypes.FixedAmountOffEachTicket:
            discount.Amount = discount.FixedAmount * totalTickets;
            break;

          case DiscountTypes.CheapestTicketFree:
            const quantityAllowedForCheapestTicketFree = discount.CheapestQuantity == null ? 1 : discount.CheapestQuantity;

            const orderedGroupedTickets = linq
              .from(groupedTickets)
              .orderBy((t) => t.TicketCategory.PriceAsInt / t.Quantity)
              .toArray();

            const cheapestTicket = orderedGroupedTickets[0];

            let amount = 0;

            if (cheapestTicket.Quantity < quantityAllowedForCheapestTicketFree) {
              discountValid = false;
            } else {
              amount = ((cheapestTicket.TicketCategory.PriceAsInt / cheapestTicket.Quantity) * quantityAllowedForCheapestTicketFree) / 100.0;
            }

            discount.Amount = amount;
            break;
        }

        if (discountValid) {
          result.push(discount);
        }
      }
    });

    return result;
  }
}
