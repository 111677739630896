import React, { FunctionComponent } from 'react';
import SVGHelper from '../../helpers/SVGHelper';
import { IEventDate } from '../../interfaces/IEventDate';

export interface IProps {
  eventDate: IEventDate;
}

const EventInfoBanner: FunctionComponent<IProps> = (props) => {
  const { eventDate } = props;
  if (!eventDate) return null;
  const hasInformation = eventDate.InformationHeader != null && eventDate.InformationHeader.length > 0 && eventDate.Information != null && eventDate.Information.length > 0;

  if (!eventDate || !(eventDate.Captioned || eventDate.Signed || hasInformation)) return null;

  return (
    <div className="strip-block" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <div className="row content">
        {hasInformation && (
          <div style={{ textAlign: 'left' }} className="col-sm-6">
            <div>{eventDate.InformationHeader}</div>
            <div className="text">{eventDate.Information}</div>
          </div>
        )}
        {eventDate && (eventDate.Captioned || eventDate.Signed) && (
          <div style={{ textAlign: 'right' }} className="col-sm-6">
            <div className="event-badges">
              {eventDate.Signed && (
                <div className="event-badge">
                  <img src={SVGHelper.get('Closed Caption')} />
                  Closed Captioning
                </div>
              )}
              {eventDate.Signed && (
                <div className="event-badge">
                  <img src={SVGHelper.get('Sign Language')} />
                  Sign Language
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventInfoBanner;
