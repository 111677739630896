import linq from 'linq';
import React, { useState } from 'react';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import GuidHelper from '../../../helpers/GuidHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IPersonnelGroup } from '../../../interfaces/IPersonnelGroup';
import SVGDown from '../../../svg/SVGDown';
import SVGTrash from '../../../svg/SVGTrash';
import ImageUploader, { IImageUploadRequest, ImageRequestGroup } from '../ImageUploader';
import { IPersonnel } from '../../../interfaces/IPersonnel';
import { IOrganisation } from '../../../interfaces/IOrganisation';

interface IProps {
  personnelGroup: IPersonnelGroup;
  event?: IEvent;
  organisation?: IOrganisation;
  onUpdated: () => void;
  handleDeleteClick: (personnelGroup: IPersonnelGroup) => void;
  onImageRequested: (request: IImageUploadRequest) => void;
  imageRequests: { [key: string]: IImageUploadRequest };
}

const PersonnelGroup: React.FC<IProps> = (props) => {
  const { personnelGroup, event, organisation, onUpdated, imageRequests, onImageRequested } = props;
  const [expanded, setExpanded] = useState(true); // !personnelGroup.Id || personnelGroup.Id <= 0);

  const [dragOver, setDragOver] = useState<IPersonnel>(null);
  const [dragging, setDragging] = useState<IPersonnel>(null);
  const [draggedIndex, setDraggedIndex] = useState(null);

  if (personnelGroup.Personnel == null) {
    personnelGroup.Personnel = [];
  }

  const handleDragStart = (index, personnel: IPersonnel) => (e) => {
    setDragging(personnel);
    setDragOver(null);
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', null);
  };

  const handleDragOver = (index, personnel: IPersonnel) => (e) => {
    e.preventDefault();
    if (draggedIndex !== null && draggedIndex !== index) {
      setDragOver(personnel);
    }
  };

  const handleDragLeave = (e) => {
    e.target.classList.remove('drag-over');
    setDragOver(null);
  };

  const getItems = () => {
    return linq
      .from(personnelGroup.Personnel)
      .orderBy((s) => s.Index)
      .toArray();
  };

  const handleDrop = (e) => {
    if (!dragging || !dragOver) return;

    e.preventDefault();
    e.target.classList.remove('drag-over');

    if (dragOver.Index != dragging.Index) {
      // dragOver.ChangesMade = true;
      // dragging.ChangesMade = true;

      if (dragOver.Index > dragging.Index) {
        dragging.Index = dragOver.Index + 0.5;
      } else if (dragOver.Index < dragging.Index) {
        dragging.Index = dragOver.Index - 0.5;
      }

      const newList = getItems();
      newList.forEach((sp, index) => {
        sp.Index = index;
      });
      console.log(newList.map((sp) => `${sp.Index}: ${sp.Name}`));
      personnelGroup.Personnel = newList;
      props.onUpdated();
    }

    setDragOver(null);
    setDraggedIndex(null);
    setDragging(null);
  };

  const imageRequestGroup = event ? ImageRequestGroup.EventPersonnel : ImageRequestGroup.OrganisationPersonnel;

  return (
    <>
      <div className="expandable-row">
        <div className="main-area flex_from_left">
          <div className="info">
            <strong>
              {personnelGroup.Name.length == 0 ? 'Personnel Group' : personnelGroup.Name} ({personnelGroup.Personnel.length} Personnel)
            </strong>
          </div>
        </div>
        <div className="flex_from_right">
          <button style={{ marginLeft: '5px' }} className={`admin-button icon ${expanded ? 'flip' : ''}`} onClick={() => setExpanded(!expanded)}>
            <SVGDown />
          </button>

          <button style={{ marginLeft: '5px' }} className="admin-button icon bad" onClick={() => props.handleDeleteClick(personnelGroup)}>
            <SVGTrash />
          </button>
        </div>
      </div>

      {expanded && (
        <>
          <div className="field">
            <div className="row">
              <div className="col-sm-7">
                <InfoLabel
                  text="What is this personnel group called, e.g. Cast, Crew, Production Team, etc?"
                  tooltip="This name should define the group of personnel that you want to appear on your event page. E.g. Cast, Crew, Production Team, etc."
                ></InfoLabel>

                <InfoInput
                  autoFocus={true}
                  labelText="Group name"
                  onChange={(value) => {
                    personnelGroup.Name = value;
                    onUpdated();
                  }}
                  value={personnelGroup.Name}
                />
              </div>
              <div className="col-sm-3 col-sm-offset-2" style={{ paddingTop: '4px' }}></div>
            </div>
          </div>

          <div className="field">
            <div className="row">
              <div className="col-sm-3" style={{ marginBottom: '20px' }}>
                {personnelGroup.Personnel.length > 0 ? (
                  <InfoLabel text="Group personnel" tooltip="This is the list of personnel that will be associated with this group."></InfoLabel>
                ) : (
                  <InfoLabel text="No personnel defined." tooltip="In order to display personnel for this group. You will need to add some. Click 'Add Personnel'"></InfoLabel>
                )}
                <button
                  className="admin-button confirm"
                  onClick={() => {
                    personnelGroup.Personnel.push({
                      Name: '',
                      Guid: GuidHelper.new(),
                      Description: '',
                      ImageUrl: '',
                      Index: personnelGroup.Personnel?.length,
                      Role: '',
                    });
                    onUpdated();
                  }}
                >
                  Add Personnel
                </button>
              </div>
              <div className="col-sm-12">
                <div className="section">
                  <div className="personnel-group">
                    {personnelGroup.Personnel.map((personnel, index) => (
                      <div
                        className={`personnel ${
                          dragOver && (dragOver.Id ? dragOver.Id : dragOver.Guid) == (personnel.Id ? personnel.Id : personnel.Guid)
                            ? dragOver.Index > dragging.Index
                              ? ' place-down'
                              : ' place-up'
                            : ''
                        }`}
                        key={personnel.Guid}
                        draggable={true}
                        onDragStart={handleDragStart(index, personnel)}
                        onDragOver={handleDragOver(index, personnel)}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                      >
                        <div className="personnel-image" id={personnel.Guid}>
                          <ImageUploader
                            cropRounded={true}
                            cropAspect={4 / 4}
                            eventId={event ? event.Id : null}
                            organisationId={organisation ? organisation.Id : null}
                            url={imageRequests[`${imageRequestGroup}_${personnel.Guid}`] ? imageRequests[`${imageRequestGroup}_${personnel.Guid}`].url : personnel.ImageUrl}
                            onChange={(request) => onImageRequested(request)}
                            group={`${imageRequestGroup}_${personnel.Guid}` as any}
                          />
                        </div>
                        <div className="personnel-form" style={{ paddingTop: '4px' }}>
                          <InfoInput
                            labelText={`Name`}
                            onChange={(value) => {
                              personnel.Name = value;
                              onUpdated();
                            }}
                            value={personnel.Name}
                          />
                          <InfoInput
                            labelText={`Role`}
                            onChange={(value) => {
                              personnel.Role = value;
                              onUpdated();
                            }}
                            value={personnel.Role}
                          />
                        </div>

                        <div className="personnel-buttons" style={{ paddingTop: '4px' }}>
                          <button
                            className="admin-button danger"
                            onClick={() => {
                              personnelGroup.Personnel = personnelGroup.Personnel.filter((q) => q !== personnel);
                              onUpdated();
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: '30px' }} className="spacer"></div>
        </>
      )}
    </>
  );
};

export default PersonnelGroup;
