import linq from 'linq';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilState } from 'recoil';
import GeneralApi from '../../api/GeneralApi';
import loaderState from '../../atoms/loaderState';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import CacheHelper from '../../helpers/CacheHelper';
import SVGHelper from '../../helpers/SVGHelper';
import UserHelper from '../../helpers/UserHelper';
import UserEventOverviewModal from '../../modals/UserEventOverviewModal';
import MyTickets_Row from './MyTickets_Row';

export interface IAttendeeEventTicketOverview {
  AbsorbFee: boolean;
  BannerImageUrl: string;
  CurrencySymbol: string;
  CustomFee: number;
  Email: string;
  EventId: number;
  EventName: string;
  EventTag: string;
  FirstEventDate: string;
  ImageUrl: string;
  IsAdmin: number;
  IsMember: number;
  LastEventDate: string;
  MemberType: string;
  MemberTypeId: number;
  MembershipsEnabled: boolean;
  Organisation: string;
  Owed: string;
  OwedAsInt: number;
  Paid: string;
  PaidAsInt: number;
  Price: string;
  PriceAsInt: number;
  Quota: number;
  Referrals: number;
  ReferralsMade: number;
  Requests: number;
  Tickets: number;
  UserId: number;
}

interface IProps {}

const MyTicketsPage: React.FC<IProps> = (props) => {
  const [attendeeEvents, setAttendeeEvents] = useState<Array<IAttendeeEventTicketOverview>>([]);
  const [sortDirection, setsortDirection] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingEventData, setLoadingEventData] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loaders, setLoaders] = useRecoilState(loaderState);

  useEffect(() => {
    loadMyTickets();
  }, []);

  const handleSortClick = (property: string) => {
    let _enum = linq.from(attendeeEvents).orderBy((x) => x[property]);
    if (sortDirection) _enum = _enum.orderByDescending((x) => x[property]);
    setAttendeeEvents(_enum.toArray());
    setsortDirection(!sortDirection);
  };

  const loadMyTickets = (silent?: boolean) => {
    if (!silent) setLoading(true);

    window.setTimeout(function () {
      GeneralApi.request('POST', '/api/UserOrdersGroupedByEvent', {
        eventId: null,
      })
        .then((response: any[]) => {
          setAttendeeEvents(
            linq
              .from(response)
              .orderByDescending((x) => x.LastEventDate)
              .thenBy((x) => x.EventName)
              .toArray()
          );
          setLoading(false);
        })
        .catch((message) => {
          alert(message);
          setLoading(false);
        });
    }, 500);
  };

  return (
    <>
      <Helmet>
        <title>Seaty - My Tickets</title>
        <meta name="description" content={`Find all the tickets to all of your events.`} />
      </Helmet>

      <Header />
      <div className="page-title">
        {UserHelper.currentUser.Name}'s
        <div className="subtitle">Tickets</div>
      </div>

      <div className="seperator-box"></div>

      <div className="bubbles orderoverview">
        {!loading && (attendeeEvents == null || attendeeEvents.length == 0) ? (
          <div className="message-panel image">
            <img src={SVGHelper.get('Tickets')} />
            <h1>No tickets found</h1>You have not yet placed any ticket orders, why not go find an event and buy your first tickets!
          </div>
        ) : (
          <>
            {loading && <Loader>Loading your tickets...</Loader>}
            <div className="content-box" style={{ paddingTop: '1px' }}>
              <div className="container-outer" style={{ minHeight: '300px', borderRadius: '6px' }}>
                <div className="container-inner" style={{ backgroundColor: 'white' }}>
                  <table className="table grid-table">
                    <thead>
                      <tr>
                        <th className="grid-header">
                          <a onClick={() => handleSortClick('EventName')}>Event</a>/<a onClick={() => handleSortClick('FirstEventDate')}>Date</a>
                        </th>
                        <th></th>
                        <th className="grid-header" style={{ textAlign: 'right' }}>
                          <a onClick={() => handleSortClick('Tickets')}>Tickets</a>
                        </th>
                        <th className="grid-header" style={{ textAlign: 'right' }}>
                          <a onClick={() => handleSortClick('PriceAsInt')}>Total</a>
                        </th>
                        <th className="grid-header" style={{ textAlign: 'right' }}>
                          <a onClick={() => handleSortClick('OwedAsInt')}>Balance</a>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="smooth-details" style={{ border: 'none' }}>
                      {attendeeEvents.map((rowData, index) => (
                        <MyTickets_Row
                          onClick={() => {
                            if (loadingEventData) return;

                            setLoadingEventData(true);
                            CacheHelper.eventWithMessage(setLoaders, loaders, rowData.EventTag)
                              .then((event) => {
                                setSelectedEvent(event);
                                setLoadingEventData(false);
                              })
                              .catch(() => {
                                setLoadingEventData(false);
                              });
                          }}
                          isLast={index == attendeeEvents.length - 1}
                          key={'MyTickets_Row_' + rowData.EventTag}
                          data={rowData}
                        ></MyTickets_Row>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer spacer />

      {selectedEvent && (
        <UserEventOverviewModal
          onClose={() => {
            setSelectedEvent(null);
            loadMyTickets();
          }}
          event={selectedEvent}
        />
      )}
    </>
  );
};

export default MyTicketsPage;
