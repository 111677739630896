import linq from 'linq';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import AdminApi from '../../../../api/AdminApi';
import Footer from '../../../../components/Footer';
import Loader from '../../../../components/Loader';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import SVGTick from '../../../../svg/SVGTick';
import SVGList from '../../../../svg/SVGList';
import { AdminWithdrawModal } from './AdminWithdrawModal';
import { IWithdrawal } from '../../../../interfaces/IWithdrawal';

interface IProps {
  organisation: IOrganisation;
}

const AdminWithdrawals: React.FC<IProps> = (props) => {
  const [withdrawals, setWithdrawals] = useState<Array<IWithdrawal>>([]);
  const [activeWithdrawal, setActiveWithdrawal] = useState<IWithdrawal>(null);
  const [loading, setLoading] = useState('Loading withdraw data...');

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setLoading('Loading withdraw data...');

    AdminApi.request('GET', '/api/Withdraw')
      .then((response: any[]) => {
        setWithdrawals(response);
        setLoading(null);
      })
      .catch((message) => {
        alert(message);
        setLoading(null);
      });
  };

  const acceptWithdraw = (withdraw: IWithdrawal) => {
    setLoading('Accepting withdraw...');
    setActiveWithdrawal(null);

    AdminApi.request('PUT', '/api/Withdraw', withdraw)
      .then((response: any) => {
        load();
      })
      .catch((message) => {
        alert(message);
        setLoading(null);
      });
  };

  const formatSortCode = (sortCode: string): string | null => {
    if (!sortCode) return '';

    // Remove any non-numeric characters that might be present
    const numericSortCode = sortCode.replace(/\D/g, '');

    // Check if the sort code is exactly 6 digits long after removing non-numeric characters
    if (numericSortCode.length !== 6) {
      console.error('Invalid sort code: must be exactly 6 digits');
      return null; // Return null or throw an error if you prefer
    }

    // Insert dashes to format the sort code
    return `${numericSortCode.slice(0, 2)}-${numericSortCode.slice(2, 4)}-${numericSortCode.slice(4)}`;
  };

  return (
    <>
      <Helmet>
        <title>Seaty - Withdrawals</title>
        <meta name="description" content={`Admin page for managing event withdrawals.`} />
      </Helmet>

      {activeWithdrawal && <AdminWithdrawModal acceptWithdraw={(withdrawal) => acceptWithdraw(withdrawal)} withdrawal={activeWithdrawal} onClose={() => setActiveWithdrawal(null)} />}

      <div className="toolbar donotprint">
        <div className="title">Withdrawals</div>
        <div className="buttons"></div>
      </div>

      <div className="orderoverview" style={{ paddingBottom: '100px' }}>
        {loading && <Loader inline>{loading}</Loader>}
        <div>
          <div className="container-outer" style={{ minHeight: '300px', borderRadius: '6px' }}>
            <div className="container-inner" style={{ backgroundColor: 'white' }}>
              <table className="table grid-table">
                <thead>
                  <tr>
                    <th style={{ minWidth: '120px' }}></th>
                    <th>Request</th>
                  </tr>
                </thead>

                <tbody style={{ border: 'none' }}>
                  {linq
                    .from(withdrawals)
                    .orderByDescending((w) => moment(w.RequestDate).unix())
                    .toArray()
                    .map((withdraw: IWithdrawal, index) => {
                      const accountName = withdraw.AccountName ?? '';
                      const sortCode = formatSortCode(withdraw.SortCode ?? '');
                      const accountNumber = (withdraw.AccountNumber ?? '').replace('-', '').replace(' ', '');
                      const amount = withdraw.Amount;

                      return (
                        <tr key={index}>
                          <td className="donotprint" style={{ textAlign: 'left' }}>
                            {withdraw.AcceptedDate == null ? (
                              <button className="table-action-button" onClick={() => setActiveWithdrawal(withdraw)}>
                                <SVGList />
                              </button>
                            ) : (
                              <>
                                <div style={{ fontWeight: 'bold' }}>Accepted</div>

                                <div>{moment.utc(withdraw.AcceptedDate).format('ddd Do MMM YY')}</div>
                                <div>{moment.utc(withdraw.AcceptedDate).format('h:mma')}</div>
                              </>
                            )}
                          </td>
                          <td>
                            <div style={{ fontWeight: 'bold' }}>{withdraw.OrganisationName}</div>
                            <div>
                              {withdraw.EventName + ' '}
                              <Link to={`/${withdraw.EventTag}`}>#{withdraw.EventTag}</Link>
                            </div>
                            <div>
                              {moment.utc(withdraw.RequestDate).format('ddd Do MMM YY')} at {moment.utc(withdraw.RequestDate).format('h:mma')} by {withdraw.RequestedByName}
                            </div>
                            <div>{withdraw.RequestedByEmail}</div>
                            <div
                              style={{ paddingTop: '10px', fontWeight: 'bold' }}
                              onClick={() => {
                                navigator.clipboard.writeText(accountName);
                              }}
                            >
                              {accountName}
                            </div>
                            <div
                              onClick={() => {
                                navigator.clipboard.writeText(sortCode);
                              }}
                            >
                              {sortCode}
                            </div>
                            <div
                              onClick={() => {
                                navigator.clipboard.writeText(accountNumber);
                              }}
                            >
                              {accountNumber}
                            </div>
                            <div
                              style={{ paddingTop: '10px', fontWeight: 'bold' }}
                              onClick={() => {
                                navigator.clipboard.writeText(amount.replace('£', ''));
                              }}
                            >
                              {amount}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer spacer />
    </>
  );
};

export default AdminWithdrawals;
