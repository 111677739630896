import React from 'react';
import { TOOL_NONE } from './constants';
import ReactSVGPanZoom, { IViewerProps } from './viewer';

export default class SVGPanZoom extends React.Component<
  React.PropsWithChildren<IViewerProps>,
  {
    tool: any;
    value: any;
  }
> {
  Viewer: ReactSVGPanZoom;

  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue || {},
      tool: props.defaultTool || TOOL_NONE,
    };

    this.Viewer = null;
    this.changeTool = this.changeTool.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  animate() {
    window.document.body.classList.add('animating-viewer');
    window.setTimeout(() => {
      window.document.body.classList.remove('animating-viewer');
    }, 350);
  }

  changeTool(tool) {
    this.setState({ tool });
  }

  changeValue(value) {
    this.setState({ value });
  }

  pan(SVGDeltaX, SVGDeltaY) {
    this.Viewer.pan(SVGDeltaX, SVGDeltaY);
  }

  zoom(SVGPointX, SVGPointY, scaleFactor) {
    this.animate();
    this.Viewer.zoom(SVGPointX, SVGPointY, scaleFactor);
  }

  fitSelection(selectionSVGPointX, selectionSVGPointY, selectionWidth, selectionHeight) {
    this.Viewer.fitSelection(selectionSVGPointX, selectionSVGPointY, selectionWidth, selectionHeight);
  }

  fitToViewer(SVGAlignX, SVGAlignY) {
    this.Viewer.fitToViewer(SVGAlignX, SVGAlignY);
  }

  zoomOnViewerCenter(scaleFactor, xOffset?) {
    this.animate();
    this.Viewer.zoomOnViewerCenter(scaleFactor, xOffset);
  }

  setPointOnViewerCenter(SVGPointX, SVGPointY, zoomLevel) {
    this.Viewer.setPointOnViewerCenter(SVGPointX, SVGPointY, zoomLevel);
  }

  reset() {
    this.Viewer.reset();
  }

  // openMiniature() {
  //   this.Viewer.openMiniature();
  // }

  // closeMiniature() {
  //   this.Viewer.closeMiniature();
  // }

  render() {
    const { width, height, onChangeTool, onChangeValue, ...props } = this.props;
    const { tool, value } = this.state;

    return (
      <ReactSVGPanZoom
        className={``}
        width={width}
        height={height}
        tool={tool}
        onChangeTool={this.changeTool}
        value={value}
        onChangeValue={this.changeValue}
        ref={(Viewer) => (this.Viewer = Viewer)}
        {...props}
      />
    );
  }
}
