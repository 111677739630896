import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path
        d="M13,1.2C6.5,1.2,1.2,6.5,1.2,13S6.5,24.8,13,24.8S24.8,19.5,24.8,13S19.5,1.2,13,1.2z M19.2,9.4l-6.3,9.3
   c-0.2,0.3-0.5,0.5-0.8,0.5c-0.3,0-0.6-0.2-0.8-0.4l-3.7-3.7c-0.3-0.3-0.3-0.7,0-0.9l0.9-0.9c0.3-0.3,0.7-0.3,0.9,0l2.4,2.4l5.2-7.7
   c0.2-0.3,0.6-0.4,0.9-0.2l1.1,0.7C19.3,8.7,19.4,9.1,19.2,9.4z"
      />
    </svg>
  );
}) as FunctionComponent<IProps>;
