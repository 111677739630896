import linq from 'linq';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import StringHelper from '../../../../helpers/StringHelper';
import IMemberType from '../../../../interfaces/IMemberType';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import SVGPlus from '../../../../svg/SVGPlus';
import AddMemberTypeModal from './AddMemberTypeModal';
import AdminMemberMenu from './AdminMemberMenu';

interface IProps {
  organisation: IOrganisation;
}

const AdminMemberTypes: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [memberTypes, setMemberTypes] = useState<IMemberType[]>([]);
  const [editingMemberType, setEditingMemberType] = useState<IMemberType>(null);

  const { organisation } = props;
  const organisationTag = organisation.OrganisationTag;

  useEffect(() => {
    reload();
  }, []);

  const reload = () => {
    setLoading(true);
    AdminApi.request('GET', `/api/MemberTypes?${organisation ? `&organisationTag=${organisation.OrganisationTag}` : ''}`)
      .then((response) => {
        setMemberTypes(response.MemberTypes);
      })
      .catch((message) => {
        console.log(message);
      })
      .finally(() => setLoading(false));
  };

  var defaultMemberType = linq.from(memberTypes).firstOrDefault((mt) => mt.Default == true);

  return (
    <>
      <Helmet>
        <title>#{organisationTag} Members</title>
        <meta name="description" content={`Manage members of your organisation.`} />
      </Helmet>

      <div className="row">
        <div className="col-sm-3 col-md-2 donotprint">
          <AdminMemberMenu organisation={organisation} />
        </div>
        <div className="col-sm-9 col-md-10 donotprint">
          <div className="toolbar">
            <div className="title">Member Types</div>
            <div className="buttons">
              <button onClick={() => setEditingMemberType({ Id: null, Name: '', Quota: null, Default: false })}>
                <SVGPlus />
                Add member type
              </button>
            </div>
          </div>

          {loading && <Loader inline>Loading members...</Loader>}

          {memberTypes && memberTypes.length > 0 ? (
            <div className="orderoverview" style={{ paddingBottom: '100px' }}>
              <div className="container-outer" style={{ minHeight: '300px' }}>
                <div className="container-inner" style={{ backgroundColor: 'white' }}>
                  <table className="table grid-table">
                    <thead>
                      <tr>
                        <th className="grid-header">Name</th>
                        <th className="grid-header"></th>
                      </tr>
                    </thead>

                    <tbody className="smooth-details">
                      {linq
                        .from(memberTypes)
                        .orderBy((m) => m.Name)
                        .toArray()
                        .map((m) => {
                          return (
                            <tr key={m.Id} className="route" onClick={() => setEditingMemberType(m)}>
                              <td>{m ? m.Name : defaultMemberType ? defaultMemberType.Name : 'Default'}</td>
                              <td>
                                {m.Members.length} {StringHelper.AddSWhenMany(m.Members.length, 'Member')}
                              </td>
                              {/* <td className="tags" style={{ textAlign: 'right' }}>
                                {m.Default && <div className="tag">Default</div>}
                              </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <p>{organisation.Name} has no member types.</p>
          )}
        </div>
      </div>

      {editingMemberType && (
        <AddMemberTypeModal
          disableDelete={memberTypes.length <= 1}
          memberType={editingMemberType}
          organisation={organisation}
          onClose={() => {
            setEditingMemberType(null);
            reload();
          }}
        />
      )}
    </>
  );
};

export default AdminMemberTypes;
