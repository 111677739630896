import React, { FunctionComponent, useState } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import UserHelper from '../../helpers/UserHelper';
import './EventShare.scss';
import SVGQR from '../../svg/SVGQR';
import QRCode from 'qrcode.react';
import SVGTick from '../../svg/SVGTick';
import SVGLink from '../../svg/SVGLink';
import EnvironmentVariables from '../../EnvironmentVariables';

export interface IProps {
  eventTag?: string;
  tourTag?: string;
  organisationTag?: string;
}

const ShareLinks: FunctionComponent<IProps> = (props) => {
  const { organisationTag, tourTag, eventTag } = props;

  const [showQRCode, setShowQRCode] = useState(false);
  const [copied, setCopied] = useState(false);
  const [tag, setTag] = useState(eventTag || tourTag || organisationTag);

  const url = props.eventTag
    ? `${EnvironmentVariables.HOME_URL}/${tag}${UserHelper.currentUser ? `?referral=${UserHelper.currentUser.Guid}` : ''}`
    : props.organisationTag
      ? `${EnvironmentVariables.HOME_URL}/Organisation/${tag}`
      : `${EnvironmentVariables.HOME_URL}/Tour/${tag}`;

  const title = `Tickets to #${tag}`;
  const size = 32;

  return (
    <div className="event-share-container">
      {UserHelper.currentUser && !UserHelper.currentUser.Guest && props.eventTag && (
        <div style={{ marginBottom: '10px' }} className="text">
          Get credit for sales by sharing through these buttons!
        </div>
      )}

      <div className="event-share ">
        <button onClick={() => setShowQRCode(!showQRCode)} className="scan-button react-share__ShareButton">
          <SVGQR />
        </button>

        <button
          onClick={() => {
            setCopied(true);
            navigator.clipboard.writeText(url);

            window.setTimeout(() => {
              setCopied(false);
            }, 2000);
          }}
          className={`copy-button ${copied ? 'copy-button-copied' : ''}`}
        >
          {copied ? <SVGTick /> : <SVGLink />}
        </button>

        <FacebookShareButton url={url}>
          <FacebookIcon size={size} round />
        </FacebookShareButton>

        <FacebookMessengerShareButton url={url} appId="747095462055934">
          <FacebookMessengerIcon size={size} round />
        </FacebookMessengerShareButton>

        <TwitterShareButton url={url} title={title}>
          <XIcon size={size} round />
        </TwitterShareButton>

        <WhatsappShareButton url={url} title={title} separator=":: ">
          <WhatsappIcon size={size} round />
        </WhatsappShareButton>

        <LinkedinShareButton url={url}>
          <LinkedinIcon size={size} round />
        </LinkedinShareButton>

        <EmailShareButton url={url} subject={title} body="body">
          <EmailIcon size={size} round />
        </EmailShareButton>
      </div>
      {copied && <div className="event-share-message">Link copied!</div>}
      {showQRCode && (
        <div style={{ marginTop: '30px' }}>
          <div>
            <QRCode value={url} width={75} height={75} />
          </div>
          {props.eventTag && UserHelper.currentUser && !UserHelper.currentUser.Guest && (
            <div style={{ marginTop: '10px', marginBottom: '10px' }} className="text">
              You can share this QR code and we will give you referral credit for any sales for anyone that uses it and buys tickets to this event.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ShareLinks;
