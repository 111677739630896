import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd">
      <path d="M7,13l10,0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-10,0c-0.552,-0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1Z" />
      <path d="M13,17l0,-10c-0,-0.552 -0.448,-1 -1,-1c-0.552,0 -1,0.448 -1,1l0,10c-0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
