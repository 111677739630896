import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { IHistoryRow } from './IHistoryRow';
import AdminApi from '../../../api/AdminApi';
import Loader from '../../../components/Loader';
import { IEvent } from '../../../interfaces/IEvent';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import { ITour } from '../../../interfaces/ITour';
import HistoryRow from './HistoryRow';
import InfoLabel from '../../../components/InfoLabel';

export interface IProps {
  organisation: IOrganisation;
  tour: ITour;
  event?: IEvent;
}

const HistorySection: FunctionComponent<IProps> = (props) => {
  const { event, organisation, tour } = props;

  const [histories, setHistories] = useState<IHistoryRow[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState<IHistoryRow>(null);

  const formatDateString = (dateString: string) => {
    const dateStr = dateString.split('/')[1].toLowerCase().replace('.json', '');

    // Assuming the date string is always in the format "YYYY-MM-DD HHMMSS"
    const datePart = dateStr.slice(0, 10); // "2024-01-08"
    const timePart = dateStr.slice(11); // "115948"

    // Insert colons into the time part
    const hours = timePart.slice(0, 2); // "11"
    const minutes = timePart.slice(2, 4); // "59"
    const seconds = timePart.slice(4); // "48"

    // Reassemble the string
    return `${datePart} ${hours}:${minutes}:${seconds}`;
  };

  const refresh = (silent?: boolean) => {
    setLoading(!silent);

    const query = `/api/HistoryEvent?eventId=${event.Id}`;

    AdminApi.request('GET', query)
      .then((response) => {
        setLoading(false);
        setHistories(
          response.Dates.map((r) => {
            return {
              FileName: r,
              Date: formatDateString(r),
            };
          }),
        );
      })
      .catch((error) => alert('Error: ' + error));
  };

  useEffect(() => {
    refresh();
  }, []);

  if (loading) return <Loader inline>Loading history...</Loader>;

  return (
    <>
      <Helmet>
        <title>History</title>
        <meta name="description" content={`Look through the historical data for this entity.`} />
      </Helmet>
      <div className="info">In the history section you can see the history of saves. You can click on any save to see the data that was saved and information, such as who saved it.</div>

      <div className="toolbar">
        <div className="title">{event ? 'Event' : tour ? 'Tour' : 'Organisation'} History</div>
        <div className="buttons"></div>
      </div>

      {histories?.length > 0 ? (
        <div className="orderoverview" style={{ paddingBottom: '100px' }}>
          <div className="container-outer" style={{ minHeight: '300px' }}>
            <div className="container-inner" style={{ backgroundColor: 'white' }}>
              <table className="table grid-table">
                <thead>
                  <tr>
                    <th className="grid-header" style={{ width: '155px' }}>
                      Date
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {histories.map((p) => (
                    <HistoryRow handleRowClick={() => setSelectedRow(p)} key={p.Date} data={p} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="info">There are no results available.</div>
      )}

      {/* {selectedRow && selectedRow.OrderId && (
        <>
          <OrderDashboardModal
            eventTag={selectedRow.EventTag}
            onClose={() => {
              setSelectedRow(null);
              refresh(true);
            }}
            orderId={selectedRow.OrderId}
          />
        </>
      )} */}
    </>
  );
};

export default HistorySection;
