import React from 'react';
import { Helmet } from 'react-helmet-async';

import Sidebar from './Sidebar';

const PrivacyPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Seaty - Privacy Policy</title>
        <meta name="description" content={`Seaty.co.uk privacy policy.`} />
      </Helmet>
      <div className="docs">
        <Sidebar />

        <div className="content">
          <article>
            <header>
              <h1>Privacy Policy</h1>
              <p>Last udpate: April 20, 2018</p>
            </header>

            <section>
              <p>
                This privacy policy sets out how Seaty.co.uk uses and protects any information that you give Seaty.co.uk when you use this website. Seaty.co.uk is committed to ensuring that your
                privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in
                accordance with this privacy statement. Seaty.co.uk may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are
                happy with any changes. This policy is effective from 1st January 2014.
              </p>

              <h3>Trademark</h3>
              <p>Seaty is a registered trademark and trading name of Seaty Ltd. Information Comissionered Office Reference ZA543843.</p>

              <h3>What kinds of personal information about you do we process?</h3>
              <p>
                When you use or interact with Seaty Services, we may collect your personal data. This could be on behalf of us or at other times on behalf on an Organiser using Seaty to run their
                event.
              </p>
              <p>Personal information that we collect and process in connection with all of our products and services, if relevant, includes:</p>
              <ul>
                <li>Full name</li>
                <li>Email address</li>
                <li>Records of your contact with us, such as message and email history when you get in touch with us</li>
                <li>Semographic information such as preferences</li>
                <li>Other information relevant to customer surveys and/or offers</li>
              </ul>

              <h3>What we collect from all users</h3>
              <p>
                <strong>Information you voluntarily provide to Seaty Services</strong> such as when you sign up for an account to use our Services, get in touch with us with questions, or browse
                sections of our website. Personal data we may collect from you includes without limitation your name, email address and other information that enabled Users to be personally
                indentified.
              </p>
              <p>
                <strong>Information we collect automatically</strong> would be data that is sent to use from the device and browser you are using to collect to the website. This information may
                include without limitation, your IP address, characteristics about your access device or browser, information on the stats of oyur activities on Seaty or similar technologies.
              </p>
              <p>
                When you sign up for the use of Seaty Services or submit any Personal Data to us, we may therefore link other Non-Personal Data with your Personal Data. At such a time, we will likely
                treat any such combined data as your Personal Data until it can no longer be associated with you or used to identify you.
              </p>

              <h3>What we collect from event organisers</h3>
              <p>When you are an event Organiser we will collect additional Personal Data from you.</p>
              <p>
                <strong>When you use our payment processing services</strong> we will need to collect financial information from you (for example your bank account information or an address to verify
                your personal information) to facilitate payments and information required for taxation purposes.
              </p>
              <p>
                <strong>We may collect information from other third party sources</strong> such as websites, your bank, our payment processing partnerts (Stripe) and credit checking organisations.
              </p>

              <h3>What we do with the information we gather</h3>
              <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
              <ul>
                <li>Internal record keeping.</li>
                <li>We may use the information to improve our products and services.</li>
                <li>
                  From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to
                  customise the website according to your interests.
                </li>
                <li>
                  We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have
                  provided.
                </li>
              </ul>

              <h3>Security</h3>
              <p>
                We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial
                procedures to safeguard and secure the information we collect online.
              </p>

              <h3>How we use cookies</h3>
              <p>
                A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know
                when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by
                gathering and remembering information about your preferences. You can find more information on how we use cookies by reading about our{' '}
                <a href="/Docs/Cookies">Cookies and Related Technologies</a>
              </p>

              <h3>Links to other websites</h3>
              <p>
                Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other
                website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this
                privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
              </p>

              <h3>Controlling your personal information</h3>
              <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
              <ul>
                <li>
                  Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct
                  marketing purposes If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing
                  us at Support@Seaty.co.uk
                </li>
                <li>
                  We will not sell, distribute or lease your personal information to third parties. We may use your personal information to send you promotional information which we think you may find
                  interesting if you tell us that you wish this to happen.
                </li>
                <li>
                  If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct
                  any information found to be incorrect.
                </li>
              </ul>

              <h3>The EU General Data Protection Regulation (GDPR)</h3>
              <p>
                A privacy law known as the EU General Data Protection Regulation, or the "GDPR", becomes effective in May 2018. This GDPR requires that Seaty, and event Organisers using the Service,
                provide Users and Attendees with more information about how their personal data is processed.
              </p>
              <p>
                <strong>Information on what you need to know</strong>
              </p>

              <p>
                <strong>The legal grounds for the processing of your personal data</strong> tell us that we are required to tell you about the legal ground we rely on to process all personal data
                about you. In relation to this we will process your personal data only because:
              </p>

              <ul>
                <li>you provided your consent</li>
                <li>the processing is in our legitimate interest as an event organising and ticketing platform</li>
                <li>it is necessary for our agreed contractual relationship</li>
                <li>the processing is necessary for us to comply with our legal or regulatory obligations</li>
              </ul>

              <p>
                <strong>Retention of personal data</strong> may be required for as long as is needed to provide you with your services, or in the event of complying with our legal oblications,
                resolving disputes that may arise, and enforcing agreements. If you sign up for an account with Seaty, we will normally retain your personal data for a set period after your account is
                closed or if it has been inactive for 10 years.
              </p>

              <p>
                <strong>Know your rights</strong>
              </p>

              <p>
                Data protection laws provide you with rights in relation to personal data that we hold about you here at Seaty, including a right to request a copy of the Personal Data, request that
                we rectify, restrict or remove your personal data and unsubscribe from marketing communications.
              </p>

              <p>
                You can you can exercise these rights by contacting us or changing the "cookie settings" in your browser (see our cookie section for more information). Note that all requests to
                exercise data protection rights will be assessed by us on a case by case basis. There may be times where we are not legally required to comply with your request because of the laws in
                your jurisdiction or because of exemptions provided for in data protection legislation.
              </p>

              <p>
                If you have a complaint about how we handle your personal data, please get in touch with us to explain the situation. If you are not happy with how we have attempted to resolve your
                complaint, you may contact the relevant data protection authority.
              </p>

              <p>
                <strong>Seaty as a data controller and a data processor</strong>
              </p>

              <p>
                European data protection laws make a distinction between organisations that process personal data for their own purposes (known as "data controllers") and organisations that process it
                on behalf of other organisations (known as "data processors"). Seaty may act as either a data controller or a data processor in respect of your Personal Data, depending on the
                circumstances.
              </p>

              <p>
                For example, if you create an account with us to organise your events, Seaty will be a data controller in respect of the Personal Data that you provide as part of your account. We will
                also be a data controller of the Personal Data that we have obtained about the use of the Applications or Seaty Properties, which could relate to Organisers or Consumers. We use this
                to conduct research and analysis to help better understand and serve Users of the Services as well as to improve our platform and provide you with more targeted recommendations about
                events we think may be of interest to you.
              </p>

              <p>
                If you have a question or complaint about how your Personal Data is handled, these should always be directed to the relevant data controller since they are the ones with primary
                responsibility for your Personal Data.
              </p>

              <p>
                However, if you register for an event as a Consumer, we will process your personal data to help administer that event on behalf of the event organiser and to help the organiser target,
                and understand the success of, their event and event planning. In these circumstances, Seaty merely provides the "tools" for organisers; Seaty does not decide what Personal Data to
                request on registration forms or event pages, nor is it responsible for the continued accuracy any Personal Data provided. Any questions that you may have relating to your personal
                data and your rights under data protection law should therefore be directed to the organiser as the data controller, not to Seaty.
              </p>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default PrivacyPage;
