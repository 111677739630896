import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminPage from '../../views/Admin/AdminPage';
import Loader from '../Loader';
import GeneralApi from '../../api/GeneralApi';
import UserHelper from '../../helpers/UserHelper';
import LocationHelper from '../../helpers/LocationHelper';
import Modal from '../Modal';
import ThemeHelper from '../../helpers/ThemeHelper';
import SpacerTable from '../SpacerTable';
import BlockInfo, { InfoType } from '../BlockInfo';
import EnvironmentVariables from '../../EnvironmentVariables';
import CacheHelper from '../../helpers/CacheHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IOrder } from '../../interfaces/IOrder';
import { PaymentMethods } from '../../enums/PaymentMethods';
import linq from 'linq';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { IVenue } from '../../interfaces/IVenue';
import { ITicketCategory } from '../../interfaces/ITicketCategory';
import { ITicket } from '../../interfaces/ITicket';
import { IQuestionAnswer } from '../../interfaces/IQuestion';
import BlockHeader from '../BlockHeader';
import Block from '../Block';
import OrderQuestions from '../OrderQuestions';
import NumberHelper from '../../helpers/NumberHelper';
import { AdmissionTypes } from '../../views/Editor/event/TicketSetupSection';
import moment from 'moment';
import DateHelper from '../../helpers/DateHelper';
import StripeCompleteSummary from './StripeCompleteSummary';

export interface IProps {}

const StripeCheckoutCompletePage: FunctionComponent<IProps> = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState('Completing order...');
  const [error, setError] = useState('');
  const [event, setEvent] = useState<IEvent>(null);
  const [order, setOrder] = useState<IOrder>(null);

  const completeIntent = (intentId: string, secret: string, purchaseId: string, e: IEvent): Promise<any> => {
    return GeneralApi.request('POST', '/api/StripeComplete', {
      intent: intentId,
      secret: secret,
      purchaseId: purchaseId,
      sessionId: UserHelper.getSessionId(),
    })
      .then((response) => {
        setLoading(null);

        if (!response.Success) {
          if (response.Message) {
            setError(response.Message);
          } else {
            setError('An unknown error has happened. Code: Unknown 450');
          }
        } else if (response.Order) {
          const o: IOrder = response.Order;

          o.Seats.forEach((s) => {
            if (s.SeatCategoryId) {
              s.TicketCategory = linq.from(e.Categories).firstOrDefault((c) => c.Id == s.TicketCategoryId);
              const seatingPlan = linq.from(e.SeatingPlans).firstOrDefault((sp) => linq.from(sp.SeatCategories).any((sc) => sc.Id == s.SeatCategoryId));

              if (seatingPlan) {
                s.SeatCategory = linq.from(seatingPlan.SeatCategories).firstOrDefault((sc) => sc.Id == s.SeatCategoryId);
              }
            } else {
              const ticketCategoryGroup = linq.from(e.UnallocatedCategoryGroups).firstOrDefault((cg) => linq.from(cg.Categories).any((c) => c.Id == s.TicketCategoryId));
              const ticketCategory = linq.from(ticketCategoryGroup.Categories).firstOrDefault((c) => c.Id == s.TicketCategoryId);
              s.TicketCategory = ticketCategory;
            }
          });

          setOrder(response.Order);
        } else {
          setError('No order in the response. ');
        }
      })
      .catch((message) => {
        setLoading(null);
        setError(message);
        return Promise.reject(message);
      });
  };

  const eventUrlDetails = LocationHelper.getLocation().pathname;
  const purchaseId = eventUrlDetails.split('/')[6];
  const eventTag = eventUrlDetails.split('/')[1];
  const eventDate = eventUrlDetails.split('/')[3];
  const eventTime = eventUrlDetails.split('/')[4];

  var inputDateString = `${eventDate}/${eventTime}`;
  var processedDateString = inputDateString.replace(/(\d{2})([A-Z]{3})(\d{4})\/(\d{4})/, '$1 $2 $3 $4');
  var parsedDate = moment.utc(processedDateString, 'DD MMM YYYY HHmm');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const payment_intent: string = queryParams.get('payment_intent');
    const payment_intent_client_secret: string = queryParams.get('payment_intent_client_secret');
    const redirect_status: string = queryParams.get('redirect_status');

    CacheHelper.eventByTag(eventTag)
      .then((e) => {
        setEvent(e);

        if (redirect_status == 'succeeded') {
          completeIntent(payment_intent, payment_intent_client_secret, purchaseId, e);
        } else {
          setError('Your payment was not accepted. Please try again.');
        }
      })
      .catch(() => {
        alert('Event could not be found...');
      });
  }, []);

  const renderCompletedSection = () => {
    const ticketGuid = order.Guid;
    const ticketLink = `/Order/Tickets/${ticketGuid}`;

    return (
      <div className="spacer">
        {isAdmin ? (
          <SpacerTable>
            <h1>All done!</h1>
            You have created an order as a box office representative of {event.Organisation.Name}.
          </SpacerTable>
        ) : (
          <SpacerTable>
            <h1>Thank you!</h1>
            Your order has been completed, you can now go to your tickets. We have sent you a confirmation email.
          </SpacerTable>
        )}

        <div className="spacer" />

        {ticketLink && ticketGuid && ticketGuid.length > 0 && (
          <>
            <SpacerTable>
              <a href={ticketLink} className="button confirm large">
                View Tickets
              </a>
            </SpacerTable>
            <div className="spacer" />
          </>
        )}

        <StripeCompleteSummary
          orderAnswers={order.QuestionAnswers}
          eventDateFormatted={DateHelper.asDateAtTimeAmPmFromMoment(parsedDate)}
          orderId={order.Id}
          attendeeName={order.AttendeeName}
          attendeeEmail={order.Email}
          paymentMethod={PaymentMethods.CardSeaty}
          tickets={order.Seats}
          event={event}
          organisation={event.Organisation}
          venue={event.Venue}
          ticketCategories={event.Categories}
          currency={event.CurrencySymbol}
        />

        <div className="spacer" />

        <SpacerTable>
          <a
            onClick={() => {
              window.location.href = `${EnvironmentVariables.HOME_URL}/${eventTag}/tickets/${eventDate}/${eventTime}`;
            }}
            className="button large"
          >
            Go to Event
          </a>
        </SpacerTable>
      </div>
    );
  };

  const conent = (
    <>
      <Helmet>
        <meta property="og:url" content={`https://Seaty.co.uk/CompleteOrder`} />
        <meta property="fb:app_id" content="747095462055934" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Completing Order`} />
        <meta property="og:description" content={`Completing order.`} />
        <meta property="og:image" content={''} />
        <title>Order</title>
        <meta name="description" content={`Completing order.`} />
      </Helmet>

      {!event ? (
        <Loader>Loading event...</Loader>
      ) : (
        <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={() => (window.location.href = `${EnvironmentVariables.HOME_URL}/${eventTag}/tickets/${eventDate}/${eventTime}`)}>
          <div className="content">
            <div className="ticket-rip" />

            <div className="body">
              {order && renderCompletedSection()}
              {error ? <BlockInfo type={InfoType.Error}>{error}</BlockInfo> : loading ? <Loader inline>{loading}</Loader> : <></>}
            </div>

            <div className="ticket-rip bottom" />
          </div>
        </Modal>
      )}
    </>
  );

  if (isAdmin) {
    return <AdminPage hideFooter={false}>{conent}</AdminPage>;
  } else {
    return <>{conent}</>;
  }
};

export default StripeCheckoutCompletePage;
