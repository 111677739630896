import React from 'react';
import { toSVG } from 'transformation-matrix';
import {
  ACTION_PAN,
  ACTION_ZOOM,
  ALIGN_LEFT,
  ALIGN_TOP,
  MODE_PANNING,
  POSITION_BOTTOM,
  POSITION_LEFT,
  POSITION_RIGHT,
  POSITION_TOP,
  TOOL_AUTO,
  TOOL_NONE,
  TOOL_PAN,
  TOOL_ZOOM_IN,
  TOOL_ZOOM_OUT,
} from './constants';
import eventFactory from './events/event-factory';
import {
  getDefaultValue,
  isValueValid,
  reset,
  setPointOnViewerCenter,
  setSVGViewBox,
  setViewerSize,
  setZoomLevels,
} from './features/common';
import {
  onDoubleClick,
  onInterval,
  onMouseDown,
  onMouseEnterOrLeave,
  onMouseMove,
  onMouseUp,
  onWheel,
} from './features/interactions';
import { onTouchCancel, onTouchEnd, onTouchMove, onTouchStart } from './features/interactions-touch';
import { pan } from './features/pan';
import { fitSelection, fitToViewer, zoom, zoomOnViewerCenter } from './features/zoom';
import BorderGradient from './ui/border-gradient';
import detectTouch from './ui/detect-touch';
import parseViewBox from './utils/parseViewBox';

interface IValue {
  version: any;
  mode: string;
  focus: boolean;
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  f: number;
  viewerWidth: number;
  viewerHeight: number;
  SVGMinX: number;
  SVGMinY: number;
  SVGWidth: number;
  SVGHeight: number;
  startX?: number;
  startY?: number;
  endX?: number;
  endY?: number;
}

export interface IViewerProps {
  width: number;
  height: number;
  value?: IValue;
  onChangeValue?: (value: any) => void;
  tool: string;
  onChangeTool?: (tool: any) => void;
  background?: string;
  SVGBackground?: string;
  SVGStyle?: any;
  style?: any;
  className?: string;
  detectWheel?: boolean;
  detectAutoPan?: boolean;
  detectPinchGesture?: boolean;
  onZoom?: (e: any) => void;
  onPan?: (e: any) => void;

  onClick?: (e: any) => void;
  onTouchStart?: (e: any) => void;
  onTouchMove?: (e: any) => void;
  onTouchEnd?: (e: any) => void;
  onTouchCancel?: (e: any) => void;
  onDoubleClick?: () => void;
  onMouseUp?: () => void;
  onMouseMove?: () => void;
  onMouseDown?: () => void;

  preventPanOutside?: boolean;
  scaleFactor?: number;
  scaleFactorOnWheel?: number;
  scaleFactorMax?: number;
  scaleFactorMin?: number;
  modifierKeys?: Array<any>;
  disableDoubleClickZoomWithToolAuto?: boolean;
}

interface IState {
  defaultValue: IValue;
  pointerX: number;
  pointerY: number;
}

export default class ReactSVGPanZoom extends React.Component<React.PropsWithChildren<IViewerProps>, IState> {
  ViewerDOM: any;
  autoPanIsRunning: boolean;

  constructor(props, context) {
    super(props, context);

    const { width: viewerWidth, height: viewerHeight, scaleFactorMin, scaleFactorMax, children } = props;
    const { viewBox: SVGViewBox } = children.props;
    let defaultValue;
    if (SVGViewBox) {
      const [SVGMinX, SVGMinY, SVGWidth, SVGHeight] = parseViewBox(SVGViewBox);
      defaultValue = getDefaultValue(
        viewerWidth,
        viewerHeight,
        SVGMinX,
        SVGMinY,
        SVGWidth,
        SVGHeight,
        scaleFactorMin,
        scaleFactorMax,
      );
    } else {
      const { width: SVGWidth, height: SVGHeight } = children.props;
      defaultValue = getDefaultValue(
        viewerWidth,
        viewerHeight,
        0,
        0,
        SVGWidth,
        SVGHeight,
        scaleFactorMin,
        scaleFactorMax,
      );
    }

    this.ViewerDOM = null;
    this.state = {
      pointerX: null,
      pointerY: null,
      defaultValue,
    };
    this.autoPanLoop = this.autoPanLoop.bind(this);
    this.onWheel = this.onWheel.bind(this);
  }

  static defaultProps = {
    style: {},
    background: '#fff',
    SVGBackground: '#fff',
    SVGStyle: {},
    detectWheel: true,
    detectAutoPan: true,
    detectPinchGesture: true,
    modifierKeys: ['Alt', 'Shift', 'Control'],
    preventPanOutside: true,
    scaleFactor: 1.1,
    scaleFactorOnWheel: 1.06,
    onZoom: null,
    onPan: null,
    width: 500,
    height: 500,
    value: null,
    onChangeTool: null,
    onChangeValue: null,
    tool: TOOL_PAN,
  };

  /** React hooks **/
  componentDidUpdate(prevProps) {
    const value = this.getValue();
    const props = this.props;

    let nextValue = value;
    let needUpdate = false;

    // This block checks the size of the SVG
    const { viewBox: SVGViewBox } = (props.children as any).props;
    if (SVGViewBox) {
      // if the viewBox prop is specified
      const [x, y, width, height] = parseViewBox(SVGViewBox);

      if (value.SVGMinX !== x || value.SVGMinY !== y || value.SVGWidth !== width || value.SVGHeight !== height) {
        nextValue = setSVGViewBox(nextValue, x, y, width, height);
        needUpdate = true;
      }
    } else {
      // if the width and height props are specified
      const { width: SVGWidth, height: SVGHeight } = (props.children as any).props;
      if (value.SVGWidth !== SVGWidth || value.SVGHeight !== SVGHeight) {
        nextValue = setSVGViewBox(nextValue, 0, 0, SVGWidth, SVGHeight);
        needUpdate = true;
      }
    }

    // This block checks the size of the viewer
    if (prevProps.width !== props.width || prevProps.height !== props.height) {
      nextValue = setViewerSize(nextValue, props.width, props.height);
      needUpdate = true;
    }

    // This blocks checks the scale factors
    if (prevProps.scaleFactorMin !== props.scaleFactorMin || prevProps.scaleFactorMax !== props.scaleFactorMax) {
      nextValue = setZoomLevels(nextValue, props.scaleFactorMin, props.scaleFactorMax);
      needUpdate = true;
    }

    if (needUpdate) {
      this.setValue(nextValue);
    }
  }

  componentDidMount() {
    this.autoPanIsRunning = true;
    requestAnimationFrame(this.autoPanLoop);
    this.ViewerDOM.addEventListener('wheel', this.onWheel, false);
  }

  componentWillUnmount() {
    this.autoPanIsRunning = false;
    this.ViewerDOM.removeEventListener('wheel', this.onWheel);
  }

  /** ReactSVGPanZoom handlers **/
  getValue() {
    if (isValueValid(this.props.value)) return this.props.value;
    return this.state.defaultValue;
  }

  getTool() {
    if (this.props.tool) return this.props.tool;
    return TOOL_NONE;
  }

  setValue(nextValue) {
    let { onChangeValue, onZoom, onPan } = this.props;

    if (onChangeValue) onChangeValue(nextValue);
    if (nextValue.lastAction) {
      if (onZoom && nextValue.lastAction === ACTION_ZOOM) onZoom(nextValue);
      if (onPan && nextValue.lastAction === ACTION_PAN) onPan(nextValue);
    }
  }

  /** ReactSVGPanZoom methods **/
  pan(SVGDeltaX, SVGDeltaY) {
    let nextValue = pan(this.getValue(), SVGDeltaX, SVGDeltaY);
    this.setValue(nextValue);
  }

  zoom(SVGPointX, SVGPointY, scaleFactor) {
    let nextValue = zoom(this.getValue(), SVGPointX, SVGPointY, scaleFactor);
    this.setValue(nextValue);
  }

  fitSelection(selectionSVGPointX, selectionSVGPointY, selectionWidth, selectionHeight) {
    let nextValue = fitSelection(
      this.getValue(),
      selectionSVGPointX,
      selectionSVGPointY,
      selectionWidth,
      selectionHeight,
    );
    this.setValue(nextValue);
  }

  fitToViewer(SVGAlignX = ALIGN_LEFT, SVGAlignY = ALIGN_TOP) {
    let nextValue = fitToViewer(this.getValue(), SVGAlignX, SVGAlignY);
    this.setValue(nextValue);
  }

  zoomOnViewerCenter(scaleFactor, xoffset?) {
    let nextValue = zoomOnViewerCenter(this.getValue(), scaleFactor, xoffset);
    this.setValue(nextValue);
  }

  setPointOnViewerCenter(SVGPointX, SVGPointY, zoomLevel) {
    let nextValue = setPointOnViewerCenter(this.getValue(), SVGPointX, SVGPointY, zoomLevel);
    this.setValue(nextValue);
  }

  reset() {
    let nextValue = reset(this.getValue());
    this.setValue(nextValue);
  }

  /** ReactSVGPanZoom internals **/
  handleViewerEvent(event) {
    let { props, ViewerDOM } = this;

    if (!([TOOL_NONE, TOOL_AUTO, TOOL_PAN].indexOf(this.getTool()) >= 0)) return;

    if (event.target === ViewerDOM) return;

    let eventsHandler = {
      click: props.onClick,
      dblclick: props.onDoubleClick,

      mousemove: props.onMouseMove,
      mouseup: props.onMouseUp,
      mousedown: props.onMouseDown,

      touchstart: props.onTouchStart,
      touchmove: props.onTouchMove,
      touchend: props.onTouchEnd,
      touchcancel: props.onTouchCancel,
    };

    let onEventHandler = eventsHandler[event.type];
    if (!onEventHandler) return;

    onEventHandler(eventFactory(event, props.value, ViewerDOM));
  }

  autoPanLoop() {
    let coords = { x: this.state.pointerX, y: this.state.pointerY };
    let nextValue = onInterval(null, this.ViewerDOM, this.getTool(), this.getValue(), this.props, coords);
    if (this.getValue() !== nextValue) {
      this.setValue(nextValue);
    }

    if (this.autoPanIsRunning) {
      requestAnimationFrame(this.autoPanLoop);
    }
  }

  onWheel(event) {
    let nextValue = onWheel(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props);
    if (this.getValue() !== nextValue) this.setValue(nextValue);
  }

  /** React renderer **/
  render() {
    let {
      props,
      state: { pointerX, pointerY },
    } = this;
    let tool = this.getTool();
    let value = this.getValue();

    let panningWithToolAuto =
      tool === TOOL_AUTO && value.mode === MODE_PANNING && value.startX !== value.endX && value.startY !== value.endY;

    let blockChildEvents = [TOOL_ZOOM_IN, TOOL_ZOOM_OUT].indexOf(tool) >= 0;
    blockChildEvents = blockChildEvents || panningWithToolAuto;

    const touchAction =
      this.props.detectPinchGesture || [TOOL_PAN, TOOL_AUTO].indexOf(this.getTool()) !== -1 ? 'none' : undefined;

    const style = { display: 'block', touchAction };

    return (
      <div
        style={{ position: 'relative', width: value.viewerWidth, height: value.viewerHeight, ...props.style }}
        className={this.props.className}
      >
        <svg
          ref={(ViewerDOM) => (this.ViewerDOM = ViewerDOM)}
          width={value.viewerWidth}
          height={value.viewerHeight}
          style={style}
          onMouseDown={(event) => {
            let nextValue = onMouseDown(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props);
            if (this.getValue() !== nextValue) this.setValue(nextValue);
            this.handleViewerEvent(event);
          }}
          onMouseMove={(event) => {
            let { left, top } = this.ViewerDOM.getBoundingClientRect();
            let x = event.clientX - Math.round(left);
            let y = event.clientY - Math.round(top);

            let nextValue = onMouseMove(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props, { x, y });
            if (this.getValue() !== nextValue) this.setValue(nextValue);
            this.setState({ pointerX: x, pointerY: y });
            this.handleViewerEvent(event);
          }}
          onMouseUp={(event) => {
            let nextValue = onMouseUp(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props);
            if (this.getValue() !== nextValue) this.setValue(nextValue);
            this.handleViewerEvent(event);
          }}
          onClick={(event) => {
            this.handleViewerEvent(event);
          }}
          onDoubleClick={(event) => {
            let nextValue = onDoubleClick(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props);
            if (this.getValue() !== nextValue) this.setValue(nextValue);
            this.handleViewerEvent(event);
          }}
          onMouseEnter={(event) => {
            if (detectTouch()) return;
            let nextValue = onMouseEnterOrLeave(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props);
            if (this.getValue() !== nextValue) this.setValue(nextValue);
          }}
          onMouseLeave={(event) => {
            let nextValue = onMouseEnterOrLeave(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props);
            if (this.getValue() !== nextValue) this.setValue(nextValue);
          }}
          onTouchStart={(event) => {
            let nextValue = onTouchStart(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props);
            if (this.getValue() !== nextValue) this.setValue(nextValue);
            this.handleViewerEvent(event);
          }}
          onTouchMove={(event) => {
            let nextValue = onTouchMove(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props);
            if (this.getValue() !== nextValue) this.setValue(nextValue);
            this.handleViewerEvent(event);
          }}
          onTouchEnd={(event) => {
            let nextValue = onTouchEnd(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props);
            if (this.getValue() !== nextValue) this.setValue(nextValue);
            this.handleViewerEvent(event);
          }}
          onTouchCancel={(event) => {
            let nextValue = onTouchCancel(event, this.ViewerDOM, this.getTool(), this.getValue(), this.props);
            if (this.getValue() !== nextValue) this.setValue(nextValue);
            this.handleViewerEvent(event);
          }}
        >
          <rect
            fill={props.background}
            x={0}
            y={0}
            width={value.viewerWidth}
            height={value.viewerHeight}
            style={{ pointerEvents: 'none' }}
          />

          <g
            className="svg-pan-transformer"
            transform={toSVG(value)}
            style={blockChildEvents ? { pointerEvents: 'none' } : {}}
          >
            <rect
              fill={this.props.SVGBackground}
              style={this.props.SVGStyle}
              x={value.SVGMinX || 0}
              y={value.SVGMinY || 0}
              width={value.SVGWidth}
              height={value.SVGHeight}
            />
            <g>{(props.children as any).props.children}</g>
          </g>

          {!([TOOL_NONE, TOOL_AUTO].indexOf(tool) >= 0 && props.detectAutoPan && value.focus) ? null : (
            <g style={{ pointerEvents: 'none' }}>
              {!(pointerY <= 20) ? null : (
                <BorderGradient direction={POSITION_TOP} width={value.viewerWidth} height={value.viewerHeight} />
              )}

              {!(value.viewerWidth - pointerX <= 20) ? null : (
                <BorderGradient direction={POSITION_RIGHT} width={value.viewerWidth} height={value.viewerHeight} />
              )}

              {!(value.viewerHeight - pointerY <= 20) ? null : (
                <BorderGradient direction={POSITION_BOTTOM} width={value.viewerWidth} height={value.viewerHeight} />
              )}

              {!(value.focus && pointerX <= 20) ? null : (
                <BorderGradient direction={POSITION_LEFT} width={value.viewerWidth} height={value.viewerHeight} />
              )}
            </g>
          )}
        </svg>
      </div>
    );
  }
}
