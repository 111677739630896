import linq from 'linq';
import React from 'react';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import GuidHelper from '../../../helpers/GuidHelper';
import { IDevice, IOrganisation } from '../../../interfaces/IOrganisation';
import SVGDevice from '../../../svg/SVGDevice';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  organisation: IOrganisation;
  onOrganisationUpdated: (e: IOrganisation) => void;
  globalOptions: JSX.Element;
}

const DeviceSection: React.FC<IProps> = (props) => {
  const { organisation, onOrganisationUpdated } = props;

  const addDevice = () => {
    var newDevice: IDevice = {
      Guid: GuidHelper.new(),
      Alias: '',
      Passcode: '',
    };

    organisation.Devices.push(newDevice);
    onOrganisationUpdated({ ...organisation });
  };

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}
          <button className="option" onClick={addDevice}>
            <label>Add Device</label>
            <div className="icon">
              <SVGDevice />
            </div>
          </button>
        </div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Devices</h2>
            {organisation.Devices.map((device, index) => {
              device.Guid = device.Guid && device.Guid.length > 0 ? device.Guid : GuidHelper.new();
              return (
                <div className="field element" key={'event_device_list_item_' + device.Guid}>
                  <InfoLabel text="What alias does this device have?" tooltip="Enter an alias for this device. A name that would make it identifiable to you."></InfoLabel>
                  <InfoInput
                    maxLength={100}
                    labelText="Alias"
                    onChange={(value) => {
                      device.Alias = value;
                      onOrganisationUpdated({ ...organisation });
                    }}
                    value={device.Alias}
                  />
                  <InfoLabel
                    text="What passcode should be used to scan on this device?"
                    tooltip="Enter the passcode that will be entered on the device to access scanning for your events."
                  ></InfoLabel>

                  <InfoInput
                    maxLength={100}
                    labelText="Passcode"
                    onChange={(value) => {
                      device.Passcode = value;
                      onOrganisationUpdated({ ...organisation });
                    }}
                    value={device.Passcode}
                  />
                  <button
                    className="danger admin-button"
                    onClick={() => {
                      var remainingDevices = linq
                        .from(organisation.Devices)
                        .where((a) => a.Guid != device.Guid)
                        .toArray();

                      organisation.Devices = remainingDevices;
                      onOrganisationUpdated({ ...organisation });
                    }}
                  >
                    Delete
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="info">
            Here you can add device logins to your organisation.
            <br />
            <br /> A device adlias and passcode can be used with the Seaty android or ios application to log in to scan tickets. Device logins can only log in and scan and have less visiability of
            event details than organisation administrators.
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceSection;
