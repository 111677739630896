import React, { useEffect, useRef } from 'react';
import ThemeHelper from '../helpers/ThemeHelper';
import { ITheme } from '../interfaces/ITheme';
import './Theme.scss';
import SVGHelper from '../helpers/SVGHelper';

export interface IProps {
  theme?: ITheme;
  backgroundClassName?: string;
  className?: string;
  parentClassName?: string;
  headerChildren?: any;
  hideFooter?: boolean;
  hideHeader?: boolean;
}

const Theme: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  let theme = props.theme;
  if (!theme) theme = ThemeHelper.getDefaultTheme();
  const background = useRef();
  const scrollArea = useRef<HTMLDivElement>();

  // const splash: HTMLElement = document.querySelector('.splash');

  const randomProperties = function (particle) {
    const left = Math.floor(Math.random() * (99 - 1)) + 1;
    particle.style.setProperty('--left', left + '%');

    const top = Math.floor(Math.random() * (99 - 1)) + 1;
    particle.style.setProperty('--top', top + '%');

    const size = Math.floor(Math.random() * (6 - 2)) + 2;
    particle.style.setProperty('--size', size + 'px');
    particle.style.setProperty('--blur', size * 4 + 'px');
    particle.style.setProperty('--spread', size + 'px');

    const opacity = Math.random() + 0.1;
    particle.style.setProperty('--opacity', opacity);

    const duration = Math.floor(Math.random() * (800 - 300)) + 300;
    particle.style.setProperty('--duration', duration + 'ms');

    const delay = Math.floor(Math.random() * (1000 - 200)) + 200;
    particle.style.setProperty('--delay', delay + 'ms');

    const iteration = Math.floor(Math.random() * (10 - 4)) + 4;
    particle.style.setProperty('--iteration', iteration);
  };

  const removeSparkles = function () {
    let sparkle = document.getElementsByClassName('particle');

    for (let i = 0; i < sparkle.length; i++) {
      sparkle[i].parentNode.removeChild(sparkle[i]);
    }
  };

  const addSparkles = function (background) {
    let maxCount = Math.random() * 99 + 10;

    for (let i = 0; i < maxCount; i++) {
      let sparkle = document.createElement('div');
      sparkle.classList.add('particle');

      background.appendChild(sparkle);

      randomProperties(sparkle);
    }
  };

  const sparkle = function (background) {
    removeSparkles();
    addSparkles(background);
  };

  const spark = () => {
    if (background && background.current) {
      sparkle(background.current);
    }
  };

  useEffect(() => {
    if (theme && theme.Sparkle) {
      spark();
      const loop = window.setInterval(() => spark(), 2000);
      return () => {
        window.clearInterval(loop);
      };
    }

    // if (scrollArea.current) {
    //   console.log('scrollArea.current.scrollTop', scrollArea.current.scrollTop);

    //   window.setTimeout(() => {
    //     scrollArea.current.scrollTop = 0;
    //   }, 150);
    // }
  }, [background, theme, theme.Sparkle]);

  const backgroundImage = theme && theme.BackgroundImageUrl;

  const headerImageUrl = theme.HeaderImageUrl ? theme.HeaderImageUrl : ThemeHelper.getDefaultTheme().HeaderImageUrl;

  return (
    <div className={`theme ${props.parentClassName ? props.parentClassName : ''}`}>
      <div
        ref={background}
        className={`background ${props.backgroundClassName ? props.backgroundClassName : ''}${theme.ShowRadial ? ' show-radial' : ''}`}
        style={{
          backgroundImage:
            backgroundImage != null && typeof backgroundImage == 'string' && backgroundImage.length > 0
              ? `url('${backgroundImage}')`
              : '',
          backgroundColor:
            theme && theme.MainColour
              ? theme.MainColour.indexOf('#') > -1
                ? theme.MainColour
                : '#' + theme.MainColour
              : 'rgb(51, 102, 153)',
        }}
      ></div>

      <div className={props.className} ref={scrollArea}>
        <div
          className="header-image-container"
          style={{
            minHeight: props.hideHeader ? '30px' : '90px',
            padding: props.hideHeader ? 0 : theme.HeaderImagePadding,
          }}
        >
          {props.headerChildren}
          {theme && !props.hideHeader && <img alt="Event Header" className="header" src={headerImageUrl} />}
        </div>

        <div className="theme-children">{props.children}</div>

        {!props.hideFooter && (
          <>
            {theme.Id == 0 ? (
              <img
                style={{ width: '250px' }}
                className="footer"
                alt="Seaty made with love in Britain"
                src="https://seatylive.blob.core.windows.net/images/WithLove.png"
              />
            ) : (
              <img
                alt="Powered by Seaty"
                className="footer"
                src="https://seatylive.blob.core.windows.net/images/FooterBackground2.png"
              ></img>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Theme;
