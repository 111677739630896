import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminPage from '../../views/Admin/AdminPage';
import Loader from '../Loader';
import GeneralApi from '../../api/GeneralApi';
import UserHelper from '../../helpers/UserHelper';
import LocationHelper from '../../helpers/LocationHelper';
import Modal from '../Modal';
import ThemeHelper from '../../helpers/ThemeHelper';
import SpacerTable from '../SpacerTable';
import BlockInfo, { InfoType } from '../BlockInfo';
import EnvironmentVariables from '../../EnvironmentVariables';
import CacheHelper from '../../helpers/CacheHelper';
import { IEvent } from '../../interfaces/IEvent';
import { PaymentMethods } from '../../enums/PaymentMethods';
import linq from 'linq';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { IVenue } from '../../interfaces/IVenue';
import BlockHeader from '../BlockHeader';
import Block from '../Block';
import { IPurchase } from '../../interfaces/IPurchase';
import CurrencyHelper from '../../helpers/CurrencyHelper';

export interface IProps {}

export interface IStripeSummaryProps {
  orderId: number;
  event: IEvent;
  purchase: IPurchase;
  organisation: IOrganisation;
  venue: IVenue;
  currency: string;
  attendeeName: string;
  attendeeEmail: string;
  paymentMethod: PaymentMethods;
}

const PaymentSummary: React.FC<React.PropsWithChildren<IStripeSummaryProps>> = (props) => {
  const event = props.event;
  const children: Array<React.ReactElement<any>> = typeof (props.children === 'object') ? (props.children as Array<React.ReactElement<any>>) : [];
  const purchase = props.purchase;

  return (
    <div>
      {children}

      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>{props.event.Name}</BlockHeader>
            {props.venue.Name}, {props.venue.Postcode}
          </Block>
          {props.orderId == null || props.orderId == 0 ? null : (
            <Block>
              <BlockHeader>Order Number</BlockHeader>
              {props.orderId}
            </Block>
          )}
          {props.attendeeEmail && props.attendeeEmail.length > 0 && (
            <Block>
              <BlockHeader>Attendee</BlockHeader>
              {props.attendeeName}
              <br />
              {props.attendeeEmail}
            </Block>
          )}
          <Block>
            <table>
              <tbody>
                <tr>
                  <td className="regular-text-color">
                    <strong>{purchase.State == 'Balance' ? 'Balance payment amount' : 'Payment amount'}</strong>
                  </td>
                  <td className="regular-text-color right">
                    <strong>{CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PriceAsInt)}</strong>
                  </td>
                </tr>
                {purchase.TransactionFee == 0 || purchase.TransactionFee == null ? null : (
                  <tr>
                    <td className="regular-text-color">
                      <strong>Fees</strong>
                    </td>
                    <td className="regular-text-color right">
                      <strong>{CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.TransactionFee)}</strong>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="regular-text-color">
                    <strong className="extra">Total</strong>
                  </td>
                  <td className="regular-text-color right">
                    <strong className="extra">{CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PriceAsInt + purchase.TransactionFee)}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </Block>
        </tbody>
      </table>
    </div>
  );
};

const StripePaymentCompletePage: FunctionComponent<IProps> = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState('Completing payment...');
  const [error, setError] = useState('');
  const [event, setEvent] = useState<IEvent>(null);
  const [purchase, setPurchase] = useState<IPurchase>(null);

  const completeIntent = (intentId: string, secret: string, purchaseId: string, e: IEvent): Promise<any> => {
    return GeneralApi.request('POST', '/api/StripeComplete', {
      intent: intentId,
      secret: secret,
      purchaseId: purchaseId,
      sessionId: UserHelper.getSessionId(),
    })
      .then((response) => {
        setLoading(null);

        if (!response.Success) {
          if (response.Message) {
            setError(response.Message);
          } else {
            setError('An unknown error has happened. Code: Unknown 450');
          }
        } else if (response.Purchase) {
          setPurchase(response.Purchase);
        }
      })
      .catch((message) => {
        setLoading(null);
        setError(message);
        return Promise.reject(message);
      });
  };

  const eventUrlDetails = LocationHelper.getLocation().pathname;
  const purchaseId = eventUrlDetails.split('/')[3];
  const eventTag = eventUrlDetails.split('/')[1];

  const queryParams = new URLSearchParams(window.location.search);
  const returnTo: string = queryParams.get('returnTo');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const payment_intent: string = queryParams.get('payment_intent');
    const payment_intent_client_secret: string = queryParams.get('payment_intent_client_secret');
    const redirect_status: string = queryParams.get('redirect_status');

    CacheHelper.eventByTag(eventTag)
      .then((e) => {
        setEvent(e);

        if (redirect_status == 'succeeded') {
          completeIntent(payment_intent, payment_intent_client_secret, purchaseId, e);
        } else {
          setError('Your payment was not accepted. Please try again.');
        }
      })
      .catch(() => {
        alert('Event could not be found...');
      });
  }, []);

  const renderCompletedSection = () => {
    return (
      <div className="spacer">
        <SpacerTable>
          <h1>Thank you!</h1>
          Your payment has been accepted.
        </SpacerTable>

        <div className="spacer" />

        <PaymentSummary
          purchase={purchase}
          orderId={purchase.Id}
          attendeeName={purchase.AttendeeName}
          attendeeEmail={purchase.Email}
          paymentMethod={PaymentMethods.CardSeaty}
          event={event}
          organisation={event.Organisation}
          venue={event.Venue}
          currency={event.CurrencySymbol}
        />

        {returnTo && returnTo == 'MyTickets' && (
          <>
            <div className="spacer" />

            <SpacerTable>
              <button
                onClick={() => {
                  window.location.href = `/My/Tickets`;
                }}
                className="button large"
              >
                Back to My Tickets
              </button>
            </SpacerTable>
          </>
        )}
      </div>
    );
  };

  const conent = (
    <>
      <Helmet>
        <meta property="og:url" content={`https://Seaty.co.uk/CompleteOrder`} />
        <meta property="fb:app_id" content="747095462055934" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Completing Order`} />
        <meta property="og:description" content={`Completing order.`} />
        <meta property="og:image" content={''} />
        <title>Order</title>
        <meta name="description" content={`Completing order.`} />
      </Helmet>

      {!event ? (
        <Loader>Loading event...</Loader>
      ) : (
        <Modal theme={ThemeHelper.getEventTheme(event)}>
          <div className="content">
            <div className="ticket-rip" />

            <div className="body">
              {purchase && renderCompletedSection()}
              {error ? <BlockInfo type={InfoType.Error}>{error}</BlockInfo> : loading ? <Loader inline>{loading}</Loader> : <></>}
            </div>

            <div className="ticket-rip bottom" />
          </div>
        </Modal>
      )}
    </>
  );

  if (isAdmin) {
    return <AdminPage hideFooter={false}>{conent}</AdminPage>;
  } else {
    return <>{conent}</>;
  }
};

export default StripePaymentCompletePage;
