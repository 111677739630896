import linq from 'linq';
import AdminApi from '../api/AdminApi';
import { IEventDate } from '../interfaces/IEventDate';
import { IImageUploadRequest } from '../views/Editor/ImageUploader';
import UserHelper from './UserHelper';

export interface IAvailableDatesModel {
  visibleDates: linq.IOrderedEnumerable<IEventDate>;
  datesFromToString: string;
}

export default class EventDateHelper {
  public static save(eventDate: IEventDate, setBusyMessage: (message: string) => void, imageRequests: { [key: string]: IImageUploadRequest } = null): Promise<IEventDate> {
    setBusyMessage && setBusyMessage('Saving event date...');

    return AdminApi.request('PUT', '/api/EventDateEdit', eventDate)
      .then((ed: IEventDate) => {
        if (ed.ValidationError && ed.ValidationError.length > 0) {
          var error = ed.ValidationError;
          ed.ValidationError = null;

          return Promise.reject(error);
        }

        eventDate.Id = ed.Id;

        return ed;
      })
      .then((e) => {
        setBusyMessage && setBusyMessage('Updating event cache...');
        UserHelper.refreshUserToken();
        return AdminApi.request('PUT', '/api/EventEdited', e);
      })
      .then((e) => {
        setBusyMessage && setBusyMessage('All done!');
        return e;
      });
  }
}
