import React from 'react';
import InfoLabel from '../../../components/InfoLabel';
import ImageUploader, { IImageUploadRequest, ImageRequestGroup } from '../ImageUploader';
import Toolbar from '../toolbar/Toolbar';
import { ITour } from '../../../interfaces/ITour';

export interface IProps {
  tour: ITour;
  onImageRequested: (request: IImageUploadRequest) => void;
  imageRequests: { [key: string]: IImageUploadRequest };
  globalOptions: JSX.Element;
}

const ImageSection: React.FC<IProps> = (props) => {
  const { tour, imageRequests, onImageRequested } = props;

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields" style={{ maxWidth: '400px' }}>
            <h2>Image</h2>
            <div className="section" style={{ marginBottom: '0' }}>
              <div className="section">
                <InfoLabel text="What is your tours main image?" tooltip="This image will represent your tour in searches and be visible on the main tour page. "></InfoLabel>

                <ImageUploader
                  tourId={tour.Id}
                  url={imageRequests[ImageRequestGroup.Tour] ? imageRequests[ImageRequestGroup.Tour].url : tour.ImageUrl}
                  onChange={(request) => onImageRequested(request)}
                  group={ImageRequestGroup.Tour}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageSection;
