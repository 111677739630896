import React, { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg viewBox="0 0 50 50">
      <g id="surface1">
        <path d="M 35.714844 10.914063 L 13.890625 10.914063 C 6.230469 10.914063 0 17.144531 0 24.800781 C 0 32.460938 6.230469 38.691406 13.890625 38.691406 L 35.714844 38.691406 C 43.371094 38.691406 49.601563 32.460938 49.601563 24.800781 C 49.601563 17.144531 43.371094 10.914063 35.714844 10.914063 Z M 35.714844 36.707031 C 29.148438 36.707031 23.808594 31.367188 23.808594 24.800781 C 23.808594 18.238281 29.148438 12.898438 35.714844 12.898438 C 42.277344 12.898438 47.617188 18.238281 47.617188 24.800781 C 47.617188 31.367188 42.277344 36.707031 35.714844 36.707031 Z " />
      </g>
    </svg>
  );
}) as FunctionComponent<IProps>;
