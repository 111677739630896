import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import SpacerTable from '../SpacerTable';
import BlockInfo, { InfoType } from '../BlockInfo';
import Block from '../Block';
import Button from '../Button';
import EnvironmentVariables from '../../EnvironmentVariables';
import UserHelper from '../../helpers/UserHelper';
import { IPurchase } from '../../interfaces/IPurchase';
import BlockHeader from '../BlockHeader';
import { IEvent } from '../../interfaces/IEvent';
import CurrencyHelper from '../../helpers/CurrencyHelper';

interface IProps {
  purchase: IPurchase;
  event: IEvent;
}

const StripePaymentForm = (props: IProps) => {
  const { purchase, event } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const addressElement = elements.getElement('address');

    const { complete, value } = await addressElement.getValue();

    if (!complete) {
      setError('Address field is not complete.');
      setLoading(false);
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const returnTo: string = queryParams.get('returnTo');

    const returnUrl = `${EnvironmentVariables.HOME_URL}/${event.EventTag}/CompletePayment/${props.purchase.Id}${returnTo ? `?returnTo=${returnTo}` : ''}`;

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
        payment_method_data: {
          billing_details: {
            email: UserHelper.currentUser.Email,
          },
        },
      },
    });

    setLoading(false);

    if (result.error) {
      if (result.error.message) {
        setError(result.error.message);
      } else {
        setError('An unknown error has occurred.');
      }
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const allowedCountries = [];

  if (props.event.Organisation && props.event.Organisation.Currency && props.event.Organisation.Currency.StripeCountry) {
    allowedCountries.push(props.event.Organisation.Currency.StripeCountry);
  } else {
    allowedCountries.push('GB');
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Event</BlockHeader>
              {props.event.Name}
            </Block>
            <Block>
              <BlockHeader>Email</BlockHeader>
              {props.purchase.Email}
            </Block>

            <Block>
              <table>
                <tbody>
                  <tr>
                    <td className="regular-text-color">
                      <strong>{purchase.State == 'Balance' ? 'Balance payment amount' : 'Payment amount'}</strong>
                    </td>
                    <td className="regular-text-color right">
                      <strong>{CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PriceAsInt)}</strong>
                    </td>
                  </tr>
                  {purchase.TransactionFee == 0 || purchase.TransactionFee == null ? null : (
                    <tr>
                      <td className="regular-text-color">
                        <strong>Fees</strong>
                      </td>
                      <td className="regular-text-color right">
                        <strong>{CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.TransactionFee)}</strong>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="regular-text-color">
                      <strong className="extra">Total</strong>
                    </td>
                    <td className="regular-text-color right">
                      <strong className="extra">{CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PriceAsInt + purchase.TransactionFee)}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Block>
          </tbody>
        </table>

        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block>
              <div className="address">
                <AddressElement
                  options={{
                    mode: 'billing',
                    allowedCountries: allowedCountries,
                    defaultValues: {
                      name: props.purchase.AttendeeName ? props.purchase.AttendeeName : '',
                    },
                    // fields: {
                    //   phone: 'always',
                    // },
                    // validation: {
                    //   phone: {
                    //     required: 'always',
                    //   },
                    // },
                  }}
                />
              </div>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block>
              <PaymentElement />
            </Block>
          </tbody>
        </table>

        {error && (
          <>
            <div className="spacer" />
            <table className="blocks">
              <tbody>
                <BlockInfo type={InfoType.Error}>{error}</BlockInfo>
              </tbody>
            </table>
          </>
        )}

        <div className="spacer-x2" />
        <SpacerTable>
          <Button
            loading={loading}
            disabled={!stripe || loading}
            className="confirm large"
            text={`Pay ${CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PriceAsInt + purchase.TransactionFee)}`}
          />
        </SpacerTable>
      </form>
    </>
  );
};

export default StripePaymentForm;
