import linq from 'linq';
import React from 'react';
import Dropdown from '../../../components/Dropdown';
import { IQuestionCriteria } from '../../../interfaces/IQuestionCriteria';
import { IDropDownItem } from '../../../components/DropdownItem';

interface IQuestionCriteriaProps {
  criteria: IQuestionCriteria;
  tickets: any[];
  onRemoveClick: (criteria: IQuestionCriteria) => void;
  onCriteriaUpdated: () => void;
  currency: string;
}

const QuestionCriteria: React.FC<IQuestionCriteriaProps> = (props) => {
  const ticketChanged = function (t: IDropDownItem) {
    props.criteria.Guid = t.value;
    props.onCriteriaUpdated();
  };

  const onRemoveClick = function () {
    props.onRemoveClick(props.criteria);
  };

  var items = linq
    .from(props.tickets)
    .select(function (t) {
      return {
        color: t.Colour,
        value: t.Guid,
        description: t.Name + (t.SeatCategory == null || t.SeatCategory.Name == null ? '' : ' ' + t.SeatCategory.Name) + ' ' + t.PriceAsString,
      };
    })
    .toArray();

  return (
    <div className="row">
      <div className="col-sm-7">
        <Dropdown onChange={ticketChanged} value={props.criteria.Guid} items={items} description="Ticket" />
      </div>

      <div className="col-sm-3 col-sm-offset-2" style={{ paddingTop: '4px' }}>
        <button className="admin-button danger" onClick={onRemoveClick}>
          Remove Criteria
        </button>
      </div>
    </div>
  );
};

export default QuestionCriteria;
