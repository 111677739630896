import React, { useState } from 'react';
import SVGInfoSimple from '../svg/SVGInfoSimple';

export interface IProps {
  tooltip?: string;
  text: string;
  required?: boolean;
  icon?: any;
}

const InfoLabel: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <button className={`information-label${props.required ? ' required' : ''}`} onClick={() => setShow(!show)}>
        {props.text}
        {props.icon ? props.icon : <SVGInfoSimple />}

        {show && (
          <div className="information-tooltip animated fadeIn">
            <div>
              <strong>{props.text}</strong>
            </div>
            {props.tooltip}
            {props.children}
          </div>
        )}
        {show && <div className="click-off animated fadeIn" style={{ background: 'rgba(0,0,0,0.2)' }}></div>}
      </button>
    </>
  );
};

export default InfoLabel;
