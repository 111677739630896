import moment from 'moment';
import 'moment-timezone';
import React, { useState } from 'react';
import DateAndTimePicker from '../../../components/DateAndTimePicker';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import DateHelper from '../../../helpers/DateHelper';
import UserHelper from '../../../helpers/UserHelper';
import { IEventDate } from '../../../interfaces/IEventDate';
import SVGAsterisk from '../../../svg/SVGAsterisk';
import SVGClosedCaptioning from '../../../svg/SVGClosedCaptioning';
import SVGCopy from '../../../svg/SVGCopy';
import SVGDown from '../../../svg/SVGDown';
import SVGExternal from '../../../svg/SVGExternal';
import SVGInfoSimple from '../../../svg/SVGInfoSimple';
import SVGSigned from '../../../svg/SVGSigned';
import SVGSuspended from '../../../svg/SVGSuspended';
import SVGTrash from '../../../svg/SVGTrash';

export interface IProps {
  date: IEventDate;
  onChange: (date) => void;
  onDeleteClick: (date) => void;
  onCopyClick: (date) => void;
  showAll?: boolean;
}

const DateAndTimeListItem: React.FC<IProps> = (props) => {
  const showAll = props.showAll;

  const handleDeleteClick = function () {
    if (props.onDeleteClick) {
      props.onDeleteClick(props.date);
    }
  };

  const [state, setState] = useState({
    moment: moment(props.date.DateAsString),
    expanded: false,
  });

  var active = !props.date.Suspend;
  var signed = props.date.Signed;
  var captioned = props.date.Captioned;
  var changeMade = props.date.ChangeMade;

  return (
    <div>
      <div className="">
        <div className="row">
          <div className="col-sm-8 flex_from_left">
            <DateAndTimePicker
              disabled={props.date.HasOrders && UserHelper.currentUser.Id !== 1}
              key={'event_date_list_item_' + props.date.Guid}
              groupName={'event_date_list_item_' + props.date.Guid}
              initialValue={state.moment}
              onChange={(dateTime) => {
                if (dateTime && dateTime.isDateTime && dateTime.moment) {
                  setState({ ...state, moment: dateTime.moment });
                  props.onChange({ ...props.date, DateAsString: dateTime.moment.format('YYYY-MM-DD HH:mm:ss.SSS') });
                }
              }}
            />
          </div>
          <div className="col-sm-4 flex_from_right">
            {!showAll && (
              <>
                <button style={{ marginLeft: '5px' }} className={`admin-button icon ${state.expanded ? 'flip' : ''}`} onClick={(e) => setState({ ...state, expanded: !state.expanded })}>
                  <SVGDown />
                </button>

                <button style={{ marginLeft: '5px' }} className={`admin-button icon`} onClick={() => props.onCopyClick(props.date)}>
                  <SVGCopy />
                </button>

                {!props.date.HasOrders && (
                  <button style={{ marginLeft: '5px' }} className="admin-button icon bad" onClick={handleDeleteClick}>
                    <SVGTrash />
                  </button>
                )}
              </>
            )}

            {props.date.ChangeMade && (
              <div className="status-icon">
                <SVGAsterisk />
              </div>
            )}
            {props.date.Suspend && (
              <div className="status-icon">
                <SVGSuspended />
              </div>
            )}
            {props.date.Captioned && (
              <div className="status-icon">
                <SVGClosedCaptioning />
              </div>
            )}
            {props.date.Signed && (
              <div className="status-icon">
                <SVGSigned />
              </div>
            )}
            {((props.date.Information && props.date.Information.length > 0) || (props.date.InformationHeader && props.date.InformationHeader.length > 0)) && (
              <div className="status-icon">
                <SVGInfoSimple />
              </div>
            )}
            {props.date.External && (
              <div className="status-icon">
                <SVGExternal />
              </div>
            )}
          </div>
        </div>

        {!state.expanded ? null : (
          <>
            {props.date.HasOrders ? (
              <div className="info" style={{ marginTop: '25px' }}>
                <strong>Existing orders</strong>
                <br />
                Event dates cannot be changed once orders have been placed. Please contact Seaty support if you need to update the date of your event.
              </div>
            ) : null}
            <div className="row">
              <div className="col-sm-8" style={{ padding: '10px 15px' }}>
                <div className="section">
                  <InfoLabel
                    icon={<SVGSuspended />}
                    text="Is this date active?"
                    tooltip="If you suspend an event date it will only be accessible by an administrator and the general public will no longer be able to create new orders."
                  ></InfoLabel>
                  <Switch trueLabel="Yes, this date is active" falseLabel="No, suspend this date" onChange={(e) => props.onChange({ ...props.date, Suspend: !e })} checked={active} />
                </div>
              </div>
              <div className="col-sm-8" style={{ padding: '10px 15px' }}>
                <div className="section">
                  <InfoLabel
                    icon={<SVGSigned />}
                    text="Is this a signed date?"
                    tooltip="If this event date is signed we will put additional information to tell attendees on your event date page."
                  ></InfoLabel>
                  <Switch trueLabel="Yes, this is a signed date" falseLabel="No, this date is not signed" onChange={(e) => props.onChange({ ...props.date, Signed: e })} checked={signed} />
                </div>
              </div>
              <div className="col-sm-8" style={{ padding: '10px 15px' }}>
                <div className="section">
                  <InfoLabel
                    icon={<SVGClosedCaptioning />}
                    text="Does this date use closed captioning?"
                    tooltip="If this event date uses closed captioning we will put additional information to tell attendees on your event date page."
                  ></InfoLabel>
                  <Switch trueLabel="Yes, uses closed captioning" falseLabel="No closed captioning" onChange={(e) => props.onChange({ ...props.date, Captioned: e })} checked={captioned} />
                </div>
              </div>
              <div className="col-sm-8">
                <div className="section event-information">
                  <div className="section">
                    <InfoLabel
                      text="Any additional info for this date?"
                      tooltip="This is an optional field. You can enter some additional information that will appear at the top of the page for this event date."
                    ></InfoLabel>
                    <InfoInput labelText="Title" maxLength={100} onChange={(value) => props.onChange({ ...props.date, InformationHeader: value })} value={props.date.InformationHeader} />
                    <InfoInput rows={3} labelText="Message" onChange={(value) => props.onChange({ ...props.date, Information: value })} value={props.date.Information} />
                  </div>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="section event-information">
                  <div className="section">
                    <InfoLabel
                      icon={<SVGExternal />}
                      text="Is this an external event date?"
                      tooltip="External event dates are those that are being handles by another ticketing provider. Here at Seaty we believe in cooperation and friendship, so we are happy to help take your attendees to an external site if that helps you better run your events. We will present this event date and take the attendees wherever you specify in the URL that will appear when you select yes."
                    ></InfoLabel>
                    <Switch
                      trueLabel="Yes, link to an external site"
                      falseLabel="No, let Seaty provide ticketing serivces"
                      onChange={(e) => props.onChange({ ...props.date, External: e })}
                      checked={props.date.External}
                    />
                    {props.date.External && (
                      <InfoInput labelText="External URL" maxLength={500} onChange={(value) => props.onChange({ ...props.date, ExternalUrl: value })} value={props.date.ExternalUrl ?? ''} />
                    )}
                  </div>
                </div>
              </div>
              {props.date.HasOrders ? null : (
                <div style={{ paddingTop: '20px' }} className="col-sm-12">
                  <button className="admin-button bad" onClick={handleDeleteClick}>
                    Delete - {state.moment && DateHelper.asDateAtTimeAmPmFromMoment(state.moment)}
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DateAndTimeListItem;
