import './FAQ.scss';

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import SideBar from './Sidebar';

interface IProps {}

const FAQPage: React.FC<IProps> = (props) => {
  const renderWithFormatting = (text: string) => {
    return text.split('\n').map((line, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {line.split(/(\*\*[^*]+\*\*)/g).map((segment, segIndex) => {
          if (segment.startsWith('**') && segment.endsWith('**')) {
            // Remove ** markers and wrap in <strong>
            return <strong key={segIndex}>{segment.slice(2, -2)}</strong>;
          }
          return segment;
        })}
        <br />
      </React.Fragment>
    ));
  };

  const faqData = [
    {
      section: 'Getting Started',
      items: [
        {
          question: 'How do I sign up for Seaty?',
          answer: `To make the most of Seaty, you'll first need to sign up! 😊`,
          details: [
            {
              title: 'How do I join the Seaty family?',
              description: `To host an event with Seaty, you'll first need to join us by creating a user account. If you haven't done this yet, no worries! 
            Simply click on the 'sign up' button, found on the top right of most pages, and fill in the sign-up form. 👍`,
            },
            {
              title: 'What do I need to know about account verification?',
              description: `After signing up to Seaty, remember to verify your email address - this is a crucial step! Sometimes, verification emails might end up in your 'junk email', so don't forget to check there too. 🕵️‍♀️`,
            },
            {
              title: 'Why is my email address important to Seaty?',
              description: `Your email address is super important to us; it's like your unique ID in the Seaty universe. All your orders, requests, and payments will be linked to your email address. So make sure it's correct and checked regularly! 📧🔒`,
            },
            {
              title: 'What can I do with a Seaty account?',
              description: `With a Seaty account, you can enjoy a whole range of features designed to make event management and attendance easy and fun! 🎉 
                Whether you're organising an event or attending one, a Seaty account opens up loads of possibilities: 

                - **Create and Customise Your Own Events**: From theatre productions to community gatherings, you’re in full control. 
                Design seating plans, set up ticket types, and create a unique event page to share with attendees. 🎭 

                - **Manage Tickets with Ease**: If you're attending an event, Seaty makes it simple to keep track of all your tickets in one place. 
                View your seat assignments, view / pay off balances, and access all the details you need in seconds. 🎟️

                - **Track Orders and Payments**: Stay on top of ticket sales and payments effortlessly. 
                Organisers can view orders, track payment progress, and send reminders—all in real-time. 
                This means no more digging through spreadsheets or chasing up balances. 💰

                - **Request Seats and Receive Updates**: Use the request system to reserve seats when attending events that require approval, and get updates right in your account. 
                It’s an ideal tool for schools, theatre companies, and group events that need more flexible ticketing. ✉️

                With Seaty, organising or attending an event is streamlined, professional, and best of all, user-friendly. We're here to help make your next event a success from start to finish! 🚀😊`,
            },
          ],
        },
        {
          question: 'How do I set up my organisation in Seaty?',
          answer: `You will have to make an organisation before you can create any events. Think of your organisation as a central hub for all your events. 
          It’s like your command centre, where you can share information and manage settings for each event under one profile. ☂️
          
          You can make a new organisation when you sign up and go to "Create event" from the menu at the top. You will then be prompted to choose and existing, or create a new organisation to assign your event to.`,
          details: [
            {
              title: 'What are user admins?',
              description: `Admins have full control over events. Event creators remain as permanent admins. 🛡️`,
            },
          ],
        },
      ],
    },
    {
      section: 'Creating and Managing Events',
      items: [
        {
          question: 'How do I create an event?',
          answer: `After signing in, click ‘Create Event’ from the top menu or user options. Our event wizard will guide you through setup. From there, use the full event editor to personalise and finalise details.
          
          You can find video tutorials on creating a new event here: https://www.seaty.co.uk/Docs/Events`,
          details: [
            {
              title: 'Adding event details',
              description: `Include all event information, visible to your guests on Seaty. Some fields are required to publish an event, so be thorough! 📝`,
            },
          ],
        },
        {
          question: 'What is an Event Tag, and how do I use it?',
          answer: `The Event Tag is a unique identifier for your event, allowing attendees to access it directly via a dedicated URL. For example, Seaty.co.uk/YourEventTagHere. 👍`,
        },
        {
          question: 'How do I save or edit an event?',
          answer: `After entering details, click ‘Save’ to store your event information. To update an existing event, go to 'My Events' and make any changes needed, then save again to confirm. 💡`,
        },
        {
          question: 'How do I set event terms and conditions?',
          answer: `Add your event's terms and conditions in the event editor. They will appear during checkout, letting attendees know your policies before purchase. ✅`,
        },
      ],
    },
    {
      section: 'Seating Plans and Categories',
      items: [
        {
          question: 'How do I create a seating plan?',
          answer: `Creating a seating plan is simple with Seaty’s interactive tools! Once you’re in the event editor, go to the 'Seating Plan' section to start building your layout.
          
          You can find video tutorials on creating a new event and seating plans here: https://www.seaty.co.uk/Docs/Events`,
        },
        {
          question: 'Can I use an existing seating plan?',
          answer: `When you make a new event, you can choose to copy an existing seating plan. This is perfect for recurring events with the same layout. 🔄`,
        },
        {
          question: 'How do I add objects and save my seating plan?',
          answer: `In the seating plan editor, you can add seats, tables, stages, and more to design your layout. Remember to hit ‘Save’ once you’re happy with your plan. 💾`,
        },
        {
          question: 'How do I label my seats?',
          answer: `Each seat needs a unique label. Use Seaty's labelling tools to add group names, seat numbers, or auto-numbering to organise seating efficiently. 🏷️`,
        },
        {
          question: 'What are seat categories, and how do I create them?',
          answer: `Seat categories help you organise and price seats differently. Each category has its own color and can be assigned unique pricing in the Seated Tickets section.`,
        },
        {
          question: 'How do I mark disabled seats?',
          answer: `The best way to mark seats as disabled is to create a specific seat category for them with their own unique colour, name and description.`,
        },
        {
          question: 'Can I have hidden seat categories?',
          answer: `Yes! For complimentary or reserved seats, you can create hidden categories that only admins can access. Hidden seats can’t be requested by attendees. 👀`,
        },
        {
          question: 'Can I show an info or disabled / accessible icon on my seats?',
          answer: `Yes! In the event editor, when in category mode on the seating plan section, you can edit your seat category by clicking the edit button once the category is selected. From there, you can choose to show a wheelchair or an info icon on the seats in that category.`,
        },
      ],
    },
    {
      section: 'Event Pages',
      items: [
        {
          question: 'How do I access my event page, and what can I do there?',
          answer: `Your event page at Seaty.co.uk/YourEventTag shows all event information for attendees and allows admins to manage ticketing. 🎯`,
          details: [
            {
              title: 'Can guests book directly from the event page?',
              description: `Yes! Guests can choose dates and tickets, select seating if available, and complete the checkout process from your event page.`,
            },
          ],
        },
        {
          question: 'How do I create event dates?',
          answer: `Event dates are added initially in the Create Event wizard, then managed in the full editor. Use calendar or list view to organise dates efficiently. Suspended dates are hidden from attendees.`,
        },
      ],
    },
    {
      section: 'Tickets',
      items: [
        {
          question: 'I lost my tickets, or I havent got the email, what can I do?',
          answer: `If you sign up for a Seaty account using the email address you used to purchase tickets, you can access all your tickets in one place by going to the "My Tickets" section once signed in.`,
        },
        {
          question: 'What is the difference between Seated and General Admission tickets?',
          answer: `Seated tickets are assigned to specific seats, while general admission tickets allow attendees to choose their own seats. 🎟️`,
        },
        {
          question: 'What are seat requests, and how do they work?',
          answer: `Seat requests let attendees reserve seats without immediate payment. Once approved by admins, the seat request becomes a ticket order. Not all events allow ticket requests, and you will have to be logged in with a Seaty account to request tickets.`,
          details: [
            {
              title: 'How do I prevent the general public from requesting tickets?',
              description: `As an event administrator, you can add a password to your event to prevent the general public from requesting tickets. 
          This is useful when you only want certain people to be able to request tickets and owe money for tickets.`,
            },
            {
              title: 'How can I manage what people owe for requested tickets?',
              description: `You can view the event balances page to manage what is owed for any event. Here you can add payments that were made outside of Seaty and link them to any attendee.`,
            },
          ],
        },
        {
          question: 'How do I cancel an order as an admin?',
          answer: `Orders can be cancelled from various places through the order dashboard. You can go to the event menu and choose "Find order" and when found, you will see a red cancel button that will guide you through the process.`,
        },
      ],
    },
    {
      section: 'Payment and Withdrawals',
      items: [
        {
          question: 'How do I withdraw the money from my event?',
          answer: `Funds from ticket sales become available within 24 hours of your event’s end. Simply go to the Banking page to initiate a withdrawal by entering your bank details.`,
          details: [
            {
              title: 'How long does it take for funds to appear?',
              description: `Funds typically appear in your account within 1-5 working days. Each withdrawal is manually reviewed by Seaty staff.`,
            },
            {
              title: 'How do fees work?',
              description: `Fees are collected by Seaty either by absorbing them in ticket prices or passing them on to attendees. Set this preference in the event editor.
              
              You can see more information on fees at: https://www.seaty.co.uk/Home/Fees`,
            },
          ],
        },
        {
          question: 'What is Stripe Connect, and how do I use it?',
          answer: `Stripe Connect allows revenue to go directly to your business bank account, bypassing Seaty's standard processing. Set up a Stripe account and link it to Seaty for fast payment access. To get Stripe connect through Seaty you will need to contact us first at Support@Seaty.co.uk`,
          details: [
            {
              title: 'Are there additional terms with Stripe Connect?',
              description: `With Stripe Connect, you’re responsible for refunds. Keeping a balance in your Stripe account helps cover any potential refunds, especially if an event is cancelled.`,
            },
            {
              title: 'How do fees work, will I get a reduced rate as we are now handling card fees directly?',
              description: `With Stripe Connect, we will reduce our standard fee rate, as the card processing fee will now be handled through you and Stripe directly in your own Stripe account.`,
            },
          ],
        },
        {
          question: 'What payment options do I have for attendees?',
          answer: `For card payments, Seaty integrates with Stripe, letting organisers choose to absorb the 7.2% processing fee or pass it on to attendees. 
          Fees are clearly displayed at checkout for transparency, and we strive to keep them as low as possible.
          Attendees can pay by card, or by Apple pay & Google pay on supported devices.`,
        },
        {
          question: 'Is Seaty free for non-card payments?',
          answer: `Seaty is entirely free for organisations that choose not to process card payments online, allowing them to manage orders at no cost. 
          This provides flexibility for events that don’t need digital transactions.`,
        },
        {
          question: 'How does Seaty make the checkout process simple for attendees?',
          answer: `Attendees can purchase tickets without creating an account. Seaty keeps the checkout process streamlined, removing unnecessary steps to make it quick and easy.`,
        },
      ],
    },
    {
      section: 'Tools and Features for Organisers and Attendees',
      items: [
        {
          question: 'What tools does Seaty provide for organisers and attendees?',
          answer: `Seaty provides essential tools for both organisers and attendees. Attendees can add notes to tickets for personalisation, while organisers have access to features like seat requests, ticket notes, real-time seat locking, and detailed reporting, ensuring a smooth experience for everyone.`,
          details: [
            {
              title: 'Tools for organisers',
              description: `From real-time seat locking to ticket notes, our tools make event management smooth and user-friendly.`,
            },
          ],
        },
        {
          question: 'Can I swap someones tickets for a different date or different seats?',
          answer: `Absolutely, just first get the existing order number, then go to the date you want and select your new tickets, then click the swap button (available as an event administrator). From there you can enter the order number, match up the tickets and swap them.`,
        },
        {
          question: 'What is the Requests System, and how does it work?',
          answer: `Seaty's request-based ticketing system allows group members to request tickets, which organisers can then approve or deny. Perfect for events where attendees are allocated seats by request.`,
          details: [
            {
              title: 'How does the Requests System work?',
              description: `Once a request is approved, it generates a ticket order, allowing organisers to manage payments, track balances, and send reminders with ease. 
                            This system is especially useful for events that require internal ticket allocations or those where immediate payment isn't needed. 
                            Attendees can submit seat requests, and organisers have full control over approving or denying each request, streamlining ticket management for group-based events like theatre companies and schools. 🎭`,
            },
            {
              title: 'What are the benefits of using the Requests System?',
              description: `With the Requests System, organisers can accommodate group members or specific attendees without requiring upfront payment. 
                            This approach is excellent for community events or group sales, where organisers may want more flexibility in managing seating and payments. 
                            Seaty makes it easy to keep track of all requested tickets and provides tools for managing outstanding balances, reminders, and reporting for a seamless event experience.`,
            },
          ],
        },
        {
          question: 'How does Quota Management work in Seaty?',
          answer: `Seaty’s quota management helps organisers set and track sales targets for members. 
          Attendees can share unique links to earn credit for sales made through their referral, helping organisers track contributions toward group goals.`,
          details: [
            {
              title: 'How does quota management support events?',
              description: `Organisers can easily monitor each member's progress and adjust quotas as needed to meet sales targets, all through Seaty’s built-in tools.`,
            },
            {
              title: 'How do attendees get a referral link?',
              description: `To get a referral link, attendees need to sign up to Seaty, then they can sign in and go to your event page. From here, if they use the share tool to copy the link or generate a QR code, they will then have their own unique way to share your event that will give them credit for sale if someone purchases tickets throught the link.`,
            },
          ],
        },
        {
          question: 'What is the Custom Event Page, and how does it help with event promotion?',
          answer: `Each event on Seaty gets its own dedicated page with a unique URL, making it easy to share, promote, and centralise event details for your attendees.`,
        },
        {
          question: "What is Seaty's iOS Scanning App, and how does it work?",
          answer: `Seaty’s iOS app allows organisers to scan tickets and validate entry quickly, ensuring a seamless check-in experience on event day.
          
          You can find it here: https://apps.apple.com/gb/app/seaty/id1456517116`,
        },
      ],
    },
    {
      section: 'Additional Event Management Features',
      items: [
        {
          question: 'How do I create and manage seating plans?',
          answer: `Creating a seating plan is simple! After setting up your event, go to the 'Seating Plan' section in the editor to start building your layout.`,
          details: [
            {
              title: 'How do I add rows and columns?',
              description: `Set the number of rows and columns to form the base grid for your seating layout. Adjustments can be made anytime.`,
            },
            {
              title: 'How do I label my seats?',
              description: `Each seat needs a unique label. Use our design tools to enter labels manually or auto-number seats quickly. 🏷️`,
            },
          ],
        },
        {
          question: 'What is the Event Landing Page, and how does it work?',
          answer: `Your event landing page is the hub for your event on Seaty! Direct guests to it with Seaty.co.uk/YourEventTag for easy access to event details. 🏠`,
          details: [
            {
              title: 'How do I access my event page?',
              description: `Once your event is created, access the landing page via Seaty.co.uk/YourEventTag. Admins can also find it in the admin menu.`,
            },
          ],
        },
        {
          question: 'How do I set up seat requests for my event?',
          answer: `Enable seat requests in the event editor. Once enabled, attendees can request seats, which you can review, approve, or deny as necessary.`,
        },
        {
          question: "How do I set up my event's terms and conditions?",
          answer: `Add your event's terms and conditions in the event editor. These will be displayed to attendees at checkout and printed on tickets to ensure everyone is aware of your policies.`,
        },
      ],
    },
  ];
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [expanded, setExpanded] = useState<{ [key: string]: number | null }>({});

  // Filtered FAQ based on search term
  const filteredFAQ = faqData
    .map((section) => ({
      ...section,
      items: section.items.filter(
        (faq) =>
          faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
          faq.answer.toLowerCase().includes(searchTerm.toLowerCase()) ||
          faq.details?.some(
            (detail) =>
              detail.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
              detail.description.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
      ),
    }))
    .filter((section) => section.items.length > 0);

  const toggleExpand = (sectionTitle: string, index: number) => {
    setExpanded((prev) => ({
      ...prev,
      [sectionTitle]: prev[sectionTitle] === index ? null : index,
    }));
  };

  return (
    <>
      <Helmet>
        <title>Seaty - FAQ</title>
        <meta name="description" content="Seaty.co.uk FAQ." />
      </Helmet>

      <div className="docs faq-page">
        <SideBar />

        <div className="content">
          <article>
            <header>
              <h1>Frequently Asked Questions</h1>
            </header>

            <div className="row">
              <div>
                <h3>Want some Help?</h3>

                <p>
                  Hello! 👋 Welcome to Seaty, your partner when organising your next event's ticket sales. We're all
                  about keeping it simple, enjoyable, and stress-free! 😊
                  <br />
                  <br />
                  Need a chat? Don't hesitate to reach out! You can ping us an email anytime at{' '}
                  <a href="mailto:support@seaty.co.uk">Support@Seaty.co.uk</a> 💌. We're here to help, so let's make
                  event management a breeze together! 💼🎉
                </p>
              </div>

              {/* Search Bar */}
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search FAQs..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="faq-search-input"
                />
              </div>

              {filteredFAQ.length > 0 ? (
                filteredFAQ.map((section, sectionIndex) => (
                  <div key={`section__${sectionIndex}`} className="col col-sm-12">
                    {/* Section Header */}
                    <h2 className="faq-section-title">{section.section}</h2>

                    {section.items.map((faq, index) => (
                      <div key={`faqItem__${index}`} className="faq-item">
                        {/* Question */}
                        <h3
                          onClick={() => toggleExpand(section.section, index)}
                          className="faq-question"
                          style={{ cursor: 'pointer' }}
                        >
                          {faq.question}
                        </h3>

                        {/* Answer - Styled to be collapsible */}
                        <div
                          className={`faq-answer ${expanded[section.section] === index ? 'expanded' : 'collapsed'}`}
                          aria-hidden={expanded[section.section] !== index}
                        >
                          <p>{faq.answer}</p>
                          {faq.details && (
                            <dl>
                              {faq.details.map((detail, detailIndex) => (
                                <React.Fragment key={`detailIndex__${detailIndex}`}>
                                  <dt>{detail.title}</dt>
                                  <p>{renderWithFormatting(detail.description)}</p>
                                </React.Fragment>
                              ))}
                            </dl>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <div className="col col-sm-12">
                  <p>No results found. Try searching with different keywords.</p>
                </div>
              )}
            </div>
          </article>
        </div>
      </div>
    </>
  );
};
export default FAQPage;
