import React, { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg viewBox="0 0 50 50">
      <path d="M 12 0 C 10.906937 0 10 0.9069372 10 2 L 10 4 L 4 4 C 2.841 4 2 4.841 2 6 L 2 46 C 2 47.159 2.841 48 4 48 L 26.126953 48 L 26.693359 46 L 4 46 L 4 15 L 46 15 L 46 26.029297 C 46.707 26.039297 47.379 26.160906 48 26.378906 L 48 6 C 48 4.841 47.159 4 46 4 L 40 4 L 40 2 C 40 0.9069372 39.093063 0 38 0 L 36 0 C 34.906937 0 34 0.9069372 34 2 L 34 4 L 16 4 L 16 2 C 16 0.9069372 15.093063 0 14 0 L 12 0 z M 12 2 L 14 2 L 14 8 L 12 8 L 12 2 z M 36 2 L 38 2 L 38 8 L 36 8 L 36 2 z M 4 6 L 10 6 L 10 8 C 10 9.0930628 10.906937 10 12 10 L 14 10 C 15.093063 10 16 9.0930628 16 8 L 16 6 L 34 6 L 34 8 C 34 9.0930628 34.906937 10 36 10 L 38 10 C 39.093063 10 40 9.0930628 40 8 L 40 6 L 46 6 L 46 13 L 4 13 L 4 6 z M 45.900391 28.025391 C 44.900391 28.025391 43.999219 28.425 43.199219 29.125 L 30.095703 42.324219 C 29.995703 42.424219 29.896875 42.624609 29.796875 42.724609 L 28.095703 48.724609 C 27.895703 49.224609 28.196875 49.825781 28.796875 49.925781 C 28.896875 49.975781 28.995984 50 29.083984 50 C 29.170984 50 29.247875 49.975781 29.296875 49.925781 L 35.296875 48.324219 C 35.496875 48.324219 35.596875 48.225391 35.796875 48.025391 L 48.800781 35.025391 C 50.400781 33.425391 50.400781 30.824609 48.800781 29.224609 C 47.824781 28.286609 46.900391 28.025391 45.900391 28.025391 z M 45.900391 30.025391 C 46.449391 30.025391 46.852719 30.126672 47.386719 30.638672 C 47.782719 31.034672 48 31.563 48 32.125 C 48 32.61 47.838062 33.068453 47.539062 33.439453 L 44.632812 30.533203 C 45.057812 30.192203 45.473391 30.025391 45.900391 30.025391 z M 43.195312 31.925781 L 46.195312 34.925781 L 35.296875 45.824219 L 32.296875 42.925781 L 43.195312 31.925781 z M 48 38.636719 L 46 40.621094 L 46 46 L 40.580078 46 L 38.564453 48 L 46 48 C 47.159 48 48 47.159 48 46 L 48 38.636719 z M 31.296875 44.824219 L 33.396484 46.925781 L 30.496094 47.724609 L 31.296875 44.824219 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
