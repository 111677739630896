import linq from 'linq';
import { FunctionComponent, useRef } from 'react';
import './GeneralAdmissionDropdown.scss';

interface IDropDownProps {
  items: any;
  selectedValue: any;
  selectedItemChanged: (item) => void;
}

const GeneralAdmissionDropdown: FunctionComponent<IDropDownProps> = (props) => {
  const { items, selectedValue, selectedItemChanged } = props;
  const selectRef = useRef<HTMLSelectElement>(null); // Reference to the select element

  const options = props.items.map((item) => {
    return { value: item.value, label: item.description };
  });

  return (
    <>
      <select
        className="select-dropdown"
        ref={selectRef}
        value={selectedValue || ''}
        onChange={(e) => {
          const item = linq.from(items).firstOrDefault((i) => i.value == e.target.value);
          selectedItemChanged(item);
        }}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );

  // return (
  //   <div className="input-label-group" style={{ padding: '0' }}>
  //     <div role="presentation" className="dropdown">
  //       <button onClick={() => setShowDropdown(!showDropdown)} style={style} className="dropdown-toggle form-control" aria-haspopup="true" aria-expanded="false">
  //         {selectedItem && selectedItem.description} <span className="caret"></span>
  //       </button>
  //       {showDropdown && <div className="click-off" style={{ zIndex: ZIndexes.DROPDOWN_CLICKOFF }} onClick={() => setShowDropdown(false)}></div>}
  //       <ul className="dropdown-menu animated fadeIn" style={{ zIndex: ZIndexes.DROPDOWN_MENU, display: showDropdown ? 'block' : 'none' }}>
  //         {items}
  //       </ul>
  //     </div>
  //   </div>
  // );
};

interface IDropDownItemProps {
  item: any;
  clicked: (item) => void;
}

const GeneralAdmissionDropdownItem: FunctionComponent<IDropDownItemProps> = (props) => {
  const clicked = function () {
    if (props.clicked) {
      props.clicked(props.item);
    }
  };

  return (
    <li onClick={clicked}>
      <span
        className="form-control"
        style={{
          color: props.item.color == null ? null : props.item.color,
          backgroundColor: props.item.backgroundColor == null ? null : props.item.backgroundColor,
        }}
      >
        {props.item.description}
      </span>
    </li>
  );
};

export default GeneralAdmissionDropdown;
