import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 17.5 4 C 15.209318 4 13.364368 5.7748091 13.101562 8 L 10.5 8 C 8.5850452 8 7 9.5850452 7 11.5 L 7 17.5 C 7 21.071938 9.9280619 24 13.5 24 L 13.708984 24 C 15.055769 28.122343 18.599405 31.091013 23 31.695312 L 23 35 L 19.5 35 C 15.928062 35 13 37.928062 13 41.5 L 13 42.5 A 1.50015 1.50015 0 0 0 14.5 44 L 34.5 44 A 1.50015 1.50015 0 0 0 36 42.5 L 36 41.5 C 36 37.928062 33.071938 35 29.5 35 L 26 35 L 26 31.695312 C 30.400595 31.091012 33.944231 28.122343 35.291016 24 L 35.5 24 C 39.071938 24 42 21.071938 42 17.5 L 42 11.5 C 42 9.5850452 40.414955 8 38.5 8 L 35.898438 8 C 35.635632 5.7748091 33.790682 4 31.5 4 L 17.5 4 z M 17.5 7 L 31.5 7 C 32.346499 7 33 7.6535009 33 8.5 L 33 9.2539062 A 1.50015 1.50015 0 0 0 33 9.7402344 L 33 20.5 C 33 25.18184 29.255745 28.933751 24.585938 28.982422 A 1.50015 1.50015 0 0 0 24.414062 28.982422 C 19.744256 28.933751 16 25.18184 16 20.5 L 16 9.7460938 A 1.50015 1.50015 0 0 0 16 9.2597656 L 16 8.5 C 16 7.6535009 16.653501 7 17.5 7 z M 10.5 11 L 13 11 L 13 20.5 C 13 20.648044 13.078394 20.771251 13.083984 20.917969 C 11.344258 20.706603 10 19.302002 10 17.5 L 10 11.5 C 10 11.204955 10.204955 11 10.5 11 z M 36 11 L 38.5 11 C 38.795045 11 39 11.204955 39 11.5 L 39 17.5 C 39 19.302002 37.655746 20.706603 35.916016 20.917969 C 35.92161 20.771251 36 20.648044 36 20.5 L 36 11 z M 19.5 38 L 29.5 38 C 31.272042 38 32.651946 39.303051 32.900391 41 L 16.099609 41 C 16.348054 39.303051 17.727958 38 19.5 38 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
