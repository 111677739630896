import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
      <path d="M 25 5 C 18.461277 5 12.65085 8.1484513 9 13.009766 L 9 7 A 1.0001 1.0001 0 0 0 7.984375 5.9863281 A 1.0001 1.0001 0 0 0 7 7 L 7 17 L 8.296875 17 L 17 17 A 1.0001 1.0001 0 1 0 17 15 L 10.042969 15 C 13.271902 10.179957 18.754781 7 25 7 C 34.952513 7 43 15.047487 43 25 A 1.0001 1.0001 0 1 0 45 25 C 45 13.966513 36.033487 5 25 5 z M 5.984375 23.986328 A 1.0001 1.0001 0 0 0 5 25 C 5 36.033487 13.966513 45 25 45 C 31.540435 45 37.350312 41.849109 41 36.986328 L 41 43 A 1.0001 1.0001 0 1 0 43 43 L 43 33 L 41.9375 33 A 1.0001 1.0001 0 0 0 41.564453 33 L 33 33.001953 A 1.0001 1.0001 0 1 0 33 35.001953 L 39.957031 35 C 36.728911 39.819768 31.245742 43 25 43 C 15.047487 43 7 34.952513 7 25 A 1.0001 1.0001 0 0 0 5.984375 23.986328 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
