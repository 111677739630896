export enum AttendeeType {
  EmailMarketingOnly = 'Email Marketing Only',
  All = 'All',
}

export interface IAttendeeRow {
  IsMember: boolean;
  AttendeeName: string;
  Email: string;
  OrganisationId: number;
  OrderCount: number;
  AllowMarketing: boolean;
}
