import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import wizardOrganisationState from '../atoms/wizardOrganisationState';
import EventHelper from '../helpers/EventHelper';
import UserHelper from '../helpers/UserHelper';
import SVGSmallCalendarEvents from '../svg/SVGSmallCalendarEvents';
import SVGSmallEditEvent from '../svg/SVGSmallEditEvent';
import SVGSmallSignOut from '../svg/SVGSmallSignOut';
import SVGSmallTickets from '../svg/SVGSmallTickets';
import SVGUser from '../svg/SVGUser';
import AdminMenuDropdown, { Orientation } from '../views/Admin/AdminMenuDropdown';
import './Header.scss';
import Cookies from 'js-cookie';
import Constants from '../helpers/Constants';

interface IProps {
  className?: string;
}

const HeaderUser: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [wizardOrganisation, setWizardOrganisation] = useRecoilState(wizardOrganisationState);

  return (
    <>
      <AdminMenuDropdown
        className={props.className}
        icon={<SVGUser />}
        options={[
          {
            id: 1,
            name: 'My tickets',
            icon: <SVGSmallTickets />,
            orientation: Orientation.Left,
            onClick: () => {
              navigate('/My/Tickets');
            },
          },
          {
            id: 2,
            name: 'My events',
            icon: <SVGSmallCalendarEvents />,
            orientation: Orientation.Left,
            onClick: () => EventHelper.manageEvents(navigate),
          },
          {
            id: 3,
            name: 'Create event',
            icon: <SVGSmallEditEvent />,
            orientation: Orientation.Left,
            onClick: () => {
              setWizardOrganisation({ onClose: () => setWizardOrganisation(null) });
            },
          },
          {
            id: 0,
            name: 'Sign out',
            icon: <SVGSmallSignOut />,
            orientation: Orientation.Right,
            onClick: () => {
              window.localStorage.removeItem(Constants.SeatyUserVar);
              window.location.href = '/';
              Cookies.remove('last-managed-organisation');
            },
          },
        ]}
        right={true}
      >
        <h1>{UserHelper.currentUser.Name}</h1>
        {UserHelper.currentUser.Email}
      </AdminMenuDropdown>
    </>
  );
};

export default HeaderUser;
