import React from 'react';

import { IOption } from '../interfaces/IOption';

export interface ISelectOptionProps {
  onClick?(option: any): void;
  option: IOption;
}

export default class SelectOption extends React.Component<ISelectOptionProps, any> {
  handleClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.option);
    }
  }

  render() {
    return (
      <tr onClick={this.handleClick.bind(this)}>
        <td>
          {this.props.option.Text}
          {this.props.option.Tags && (
            <div className="select-tags">
              {this.props.option.Tags.map((tag) => (
                <span className="select-tag">{tag}</span>
              ))}
            </div>
          )}
        </td>
        <td className="right">{this.props.option.TextRight}</td>
        <td></td>
      </tr>
    );
  }
}
