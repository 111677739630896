import linq from 'linq';
import React from 'react';
import Switch from '../../../components/Switch';
import { IEvent } from '../../../interfaces/IEvent';
import { ITour } from '../../../interfaces/ITour';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  tour: ITour;
  onTourUpdated: (e: ITour) => void;
  globalOptions: JSX.Element;
  events: Array<IEvent>;
}

const EventsSection: React.FC<IProps> = (props) => {
  const { tour, onTourUpdated } = props;

  if (tour.TourTag == null) {
    tour.TourTag = '';
  }

  const onToggleChange = (value, e) => {
    if (value) {
      onTourUpdated({ ...tour, Events: [...tour.Events, e] });
    } else {
      onTourUpdated({
        ...tour,
        Events: [
          ...linq
            .from(tour.Events)
            .where((ev) => ev.Id !== e.Id)
            .toArray(),
        ],
      });
    }
  };

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Events with no tour</h2>
            <div style={{ marginTop: '0' }}>
              <div className="section">
                {props.events &&
                  linq
                    .from(props.events)
                    .where((e) => e.TourTag == null)
                    .orderBy((e) => e.Name)
                    .thenBy((e) => e.EventTag)
                    .toArray()
                    .map((e) => {
                      const name = `${e.Name} #${e.EventTag}`;
                      return (
                        <Switch key={e.Id} trueLabel={name} falseLabel={name} onChange={(value) => onToggleChange(value, e)} checked={tour.Events.filter((ev) => ev.Id === e.Id).length > 0}></Switch>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="info">
            Select the events you want to be a part of this tour and press <strong>save</strong> to commit, changes will only take effect after you save.
          </div>
        </div>
        {tour && tour.Id && tour.Id > 0 && (
          <>
            <div className="col-sm-8">
              <div className="fields">
                <h2>Events in this tour</h2>
                <div style={{ marginTop: '0' }}>
                  <div className="section">
                    {props.events &&
                      linq
                        .from(props.events)
                        .where((e) => e.TourTag == tour.TourTag)
                        .orderBy((e) => e.Name)
                        .thenBy((e) => e.EventTag)
                        .toArray()
                        .map((e) => {
                          const name = `${e.Name} #${e.EventTag}`;
                          return (
                            <Switch
                              key={e.Id}
                              trueLabel={name}
                              falseLabel={name}
                              onChange={(value) => onToggleChange(value, e)}
                              checked={tour.Events.filter((ev) => ev.Id === e.Id).length > 0}
                            ></Switch>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="info">
                Events that are <strong>already in this tour</strong> can be de-selected and when you save we will remove them from this tour.
              </div>
            </div>
          </>
        )}
        <div className="col-sm-8">
          <div className="fields">
            <h2>Events with existing tour</h2>
            <div style={{ marginTop: '0' }}>
              <div className="section">
                {props.events &&
                  linq
                    .from(props.events)
                    .where((e) => e.TourTag != null && e.TourTag != tour.TourTag)
                    .orderBy((e) => e.Name)
                    .thenBy((e) => e.EventTag)
                    .toArray()
                    .map((e) => {
                      const name = `${e.Name} #${e.EventTag}`;
                      return (
                        <Switch key={e.Id} trueLabel={name} falseLabel={name} onChange={(value) => onToggleChange(value, e)} checked={tour.Events.filter((ev) => ev.Id === e.Id).length > 0}></Switch>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="info">
            <strong>One tour per event</strong> <br />
            An event can only be part of one tour and it will be removed from any existing tours if selected and saved.
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsSection;
