import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 48 48">
      <path
        d="M21.8,34.5l14.1-14.1c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L19,31.7
   c-0.8,0.8-0.8,2,0,2.8C19.7,35.3,21,35.3,21.8,34.5z"
      />
      <path
        d="M12.1,27.7l6.8,6.8c0.8,0.8,2,0.8,2.8,0c0.8-0.8,0.8-2,0-2.8l-6.8-6.8
   c-0.8-0.8-2-0.8-2.8,0C11.3,25.7,11.3,26.9,12.1,27.7z"
      />
    </svg>
  );
}) as FunctionComponent<IProps>;
