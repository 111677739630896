import linq from 'linq';
import React, { useState } from 'react';
import Dropdown from '../../../components/Dropdown';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import { DiscountTypes } from '../../../enums/DiscountTypes';
import NumberHelper from '../../../helpers/NumberHelper';
import StringHelper from '../../../helpers/StringHelper';
import { IDiscount } from '../../../interfaces/IDiscount';
import { IEvent } from '../../../interfaces/IEvent';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';
import SVGMinus from '../../../svg/SVGMinus';
import SVGPlus from '../../../svg/SVGPlus';
import './Discount.scss';
import DiscountCriteria from './DiscountCriteria';
import SVGDown from '../../../svg/SVGDown';
import SVGTrash from '../../../svg/SVGTrash';
import { IDropDownItem } from '../../../components/DropdownItem';
import DateAndTimePicker from '../../../components/DateAndTimePicker';
import moment from 'moment';
import { DiscountModes } from '../../../enums/DiscountModes';
import SVGDiscount from '../../../svg/SVGDiscount';

interface IProps {
  discount: IDiscount;
  event: IEvent;
  onEventUpdated: () => void;
  handleDeleteClick: (discount: IDiscount) => void;
  setManageDiscountCodes: (discount: IDiscount) => void;
}

const Discount: React.FC<IProps> = (props) => {
  const { discount, event, onEventUpdated } = props;
  const [expanded, setExpanded] = useState(!discount.Id || discount.Id <= 0);

  const [percentageText, setPercentageText] = useState(discount.Percentage.toString());
  const [fixedAmountText, setFixedAmountText] = useState(discount.FixedAmount.toString());

  const showSeatingPlanNames = event.SeatingPlans && event.SeatingPlans.length > 1;

  const getEventTickets = (): ITicketCategory[] => {
    var tickets = [];

    event.AllocatedCategoryGroups.forEach((group, groupIndex) => {
      tickets = tickets.concat(
        linq
          .from(group.Categories)
          .where((x: any) => x.PriceAsInt > 0)
          .toArray(),
      );
    });

    event.UnallocatedCategoryGroups.forEach((group, groupIndex) => {
      tickets = tickets.concat(
        linq
          .from(group.Categories)
          .where((x: any) => x.PriceAsInt > 0)
          .toArray(),
      );
    });

    return tickets;
  };

  if (discount.ApplicationMode == null) {
    discount.ApplicationMode = 0;
  }
  if (discount.DiscountType == null) {
    discount.DiscountType = 0;
  }

  var criterias = discount.Criteria;
  if (criterias == null) {
    criterias = [];
  }
  var minimumQuantity = 1;

  if (criterias.length > 0) {
    minimumQuantity = linq.from(criterias).sum(function (c) {
      return c.Amount;
    });
  }

  if (discount.Quantity < minimumQuantity) {
    discount.Quantity = minimumQuantity;
  }

  if (discount.CheapestQuantity == null || isNaN(discount.CheapestQuantity)) {
    discount.CheapestQuantity = 1;
  }
  if (discount.CheapestQuantity > discount.Quantity) {
    discount.CheapestQuantity = discount.Quantity;
  } else if (discount.CheapestQuantity == 0 && discount.Quantity > 1) {
    discount.CheapestQuantity = 1;
  } else if (discount.CheapestQuantity < 1) {
    discount.CheapestQuantity = 1;
  }

  var tickets = getEventTickets();
  var summary = '';

  if (discount.ApplicationMode == 0) {
    var code = 'yet to be specified';
    if (discount.Code != null) {
      code = discount.Code;
    }
    summary += 'When an attendee uses discount code ' + code + ' to create an order ';
  } else {
    summary += 'When an attendee creates an order ';
  }

  summary += 'containing at least ' + discount.Quantity + ' ' + StringHelper.AddSWhenMany(discount.Quantity, 'ticket') + ' ';

  if (discount.Criteria == null) {
    discount.Criteria = [];
  }
  if (discount.Stack === undefined || discount.Stack === null) {
    discount.Stack = false;
  }

  const ticketsInCriteria = tickets.filter((t) => criterias.filter((c) => c.Guid === t.Guid).length > 0);
  const ticketsNotInCriteria = tickets.filter((t) => criterias.filter((c) => c.Guid === t.Guid).length === 0);

  if (discount.Criteria.length > 0) {
    ticketsInCriteria.forEach((ticket) => {
      const criteria = criterias.filter((c) => c.Guid === ticket.Guid)[0];
      var criteriaAmount = criteria.Amount;
      summary += `and ${criteriaAmount} of those tickets ${criteriaAmount == 1 ? 'is an ' : 'are '} ${ticket.Name} ${
        ticket.SeatCategory == null || ticket.SeatCategory.Name == null ? '' : ' ' + ticket.SeatCategory.Name
      } ${StringHelper.AddSWhenMany(criteriaAmount, 'ticket')} `;
    });
  }

  if (discount.DiscountType == 0) {
    summary += 'they will get ' + discount.Percentage + '% off the total price.';
  } else if (discount.DiscountType == 1) {
    var amount = '0.00';

    if (!isNaN(discount.FixedAmount)) {
      amount = discount.FixedAmount.toFixed(2);
    }

    summary += 'they will get ' + event.CurrencySymbol + amount + ' off the total price.';

    if (criterias.length > 0) {
      if (discount.Stack) {
        summary += ' This discount can stack and be added multiple times, each time the above criteria in an order is met.';
      } else {
        summary += ' This discout will not stack no matter how many tickets an attendee buys only the single fixed discount will be given when the above criteria is met. ';
      }
    }
  } else if (discount.DiscountType == 2) {
    summary += 'they will get the cheapest ' + discount.CheapestQuantity + ' ' + StringHelper.AddSWhenMany(discount.CheapestQuantity, 'ticket') + ' free.';
  } else if (discount.DiscountType == 3) {
    var amount = '0.00';

    if (!isNaN(discount.FixedAmount)) {
      amount = discount.FixedAmount.toFixed(2);
    }

    summary += 'they will get ' + event.CurrencySymbol + amount + ' off the price of each ticket.';
  }

  return (
    <>
      <div className="expandable-row">
        <div className="main-area flex_from_left">
          <div className="info">
            <strong>{discount.Name.length == 0 ? 'Discount' : discount.Name}</strong> {summary}
          </div>
        </div>
        <div className="flex_from_right">
          <button style={{ marginLeft: '5px' }} className={`admin-button icon ${expanded ? 'flip' : ''}`} onClick={() => setExpanded(!expanded)}>
            <SVGDown />
          </button>

          <button style={{ marginLeft: '5px' }} className="admin-button icon bad" onClick={() => props.handleDeleteClick(discount)}>
            <SVGTrash />
          </button>

          {discount.ApplicationMode == DiscountModes.SingleUseDiscountCodes && (
            <>
              <button style={{ marginLeft: '5px' }} className={`admin-button icon`} onClick={() => props.setManageDiscountCodes(discount)}>
                <SVGDiscount />
              </button>
            </>
          )}
        </div>
      </div>

      {expanded && (
        <>
          <div className="" style={{ overflow: 'visible' }}>
            <div className="field" style={{ border: '0' }}>
              <InfoLabel text="Discount options" tooltip="Please choose the discount name, how it will be applied to an order, criteria and any other options visible."></InfoLabel>
              <div className="section">
                <div className="row">
                  <div className="col-sm-7">
                    <InfoInput
                      autoFocus={true}
                      labelText="Discount name"
                      onChange={(value) => {
                        discount.Name = value;
                        onEventUpdated();
                      }}
                      value={discount.Name}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="field" style={{ border: '0' }}>
              <InfoLabel
                text="Will this discount expire?"
                tooltip="To add an expiry date for this discount click 'Add Expiry Date' then select a date and time this discount will expire and no longer apply."
              />
              <div className="section" style={{ marginBottom: '25px' }}>
                <div className="row">
                  <div className="col-sm-7">
                    {discount.Expires && discount.Expires.length > 0 ? (
                      <>
                        <DateAndTimePicker
                          groupName="Expires"
                          initialValue={moment(discount.Expires)}
                          onChange={(dateTime) => {
                            if (dateTime.isDateTime && dateTime.moment) {
                              discount.Expires = dateTime.moment.format('YYYY-MM-DD HH:mm:ss.SSS');
                              onEventUpdated();
                            }
                          }}
                        />
                        <button
                          className="admin-button bad"
                          onClick={() => {
                            discount.Expires = null;
                            onEventUpdated();
                          }}
                        >
                          Remove Expiry Date
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="admin-button confirm"
                          onClick={() => {
                            discount.Expires = moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS');
                            onEventUpdated();
                          }}
                        >
                          Add Expiry Date
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="field">
              <div className="section">
                <InfoLabel
                  text="How is the discount applied to an order?"
                  tooltip="This discount can be open to the public and applied automatically to any order that meets the criteria, or you can hide it behind a discount code that can be used at the time of checkout."
                ></InfoLabel>

                <div className="row">
                  <div className="col-sm-7">
                    <Dropdown
                      onChange={(item: IDropDownItem) => {
                        discount.ApplicationMode = item.value as number;
                        onEventUpdated();
                      }}
                      value={discount.ApplicationMode.toString()}
                      items={[
                        { value: DiscountModes.DiscountCode, description: 'With a discount code' },
                        { value: DiscountModes.SingleUseDiscountCodes, description: 'With many single use discount codes' },
                        { value: DiscountModes.Automatically, description: 'Automatically when valid' },
                      ]}
                      description="Applied"
                    />
                  </div>
                  {discount.ApplicationMode == DiscountModes.SingleUseDiscountCodes && (
                    <>
                      <div className="col-sm-7">
                        <button style={{ marginBottom: '15px' }} className="admin-button confirm" onClick={() => props.setManageDiscountCodes(discount)}>
                          Manage Discount Codes ({(discount.Codes ?? []).length})
                        </button>
                      </div>
                    </>
                  )}
                  {discount.ApplicationMode == DiscountModes.DiscountCode && (
                    <>
                      <div className="col-sm-7">
                        <InfoLabel text="What is the discount code?" tooltip="Anyone that wants to use this discount will need to enter this code at the time of checkout."></InfoLabel>
                        <InfoInput
                          labelText="Discount code"
                          onChange={(value: string) => {
                            discount.Code = value;
                            onEventUpdated();
                          }}
                          value={discount.Code}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="field">
              <div className="row">
                <div className="col-sm-12">
                  <div className="section">
                    {criterias.length > 0 ? (
                      <InfoLabel text="Discount criteria:" tooltip="This criteria outlines which tickets and how many of each are required in an order for the discount to be activated."></InfoLabel>
                    ) : (
                      <InfoLabel
                        text="No specific tickets are required to activate this discount"
                        tooltip="With no criteria your discount will apply when tickets of any type are added to an order. You can add a criteria of tickets that must be bought, and their quantities, by clicking Add Criteria."
                      ></InfoLabel>
                    )}
                    {criterias.map((criteria) => (
                      <DiscountCriteria
                        showSeatingPlanNames={showSeatingPlanNames}
                        key={`DiscountCriteria_${criteria.Guid}`}
                        onCriteriaUpdated={() => onEventUpdated()}
                        onRemoveClick={() => {
                          discount.Criteria = discount.Criteria.filter((c) => c.Guid !== criteria.Guid);
                          onEventUpdated();
                        }}
                        currency={event.CurrencySymbol}
                        tickets={linq
                          .from(ticketsNotInCriteria.concat(tickets.filter((t) => t.Guid === criteria.Guid)))
                          .orderBy((t) => t.Name)
                          .toArray()}
                        criteria={criteria}
                      />
                    ))}
                  </div>
                </div>
                <div className="col-sm-3" style={{ paddingBottom: '10px' }}>
                  {ticketsNotInCriteria.length > 0 && (
                    <button
                      className="admin-button confirm"
                      onClick={() => {
                        var ticket = linq
                          .from(getEventTickets())
                          .where(
                            (t) =>
                              linq
                                .from(discount.Criteria)
                                .where((c) => c.Guid == t.Guid)
                                .firstOrDefault() == null,
                          )
                          .firstOrDefault();

                        discount.Criteria.push({ Amount: 1, Guid: ticket.Guid });
                        onEventUpdated();
                      }}
                    >
                      Add Criteria
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="field">
              <div className="section">
                <InfoLabel
                  text="What is the minimum amount of tickets?"
                  tooltip="This is the minimum total amount of tickets that are required in an order in order for this discount to be applied."
                ></InfoLabel>

                <div className="row">
                  <div className="col-sm-7">
                    <table className="counter">
                      <tbody>
                        <tr>
                          <td className="counter-button">
                            <button
                              onClick={() => {
                                var amount = discount.Quantity - 1;
                                if (amount < 1) {
                                  amount = 1;
                                }
                                discount.Quantity = amount;
                                onEventUpdated();
                              }}
                              className="admin-button"
                            >
                              <SVGMinus />
                            </button>
                          </td>
                          <td className="counter-amount">{discount.Quantity}</td>
                          <td className="counter-button">
                            <button
                              onClick={() => {
                                var amount = discount.Quantity + 1;
                                discount.Quantity = amount;
                                onEventUpdated();
                              }}
                              className="admin-button"
                            >
                              <SVGPlus />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="field">
              <div className="section">
                <InfoLabel
                  text="What type of discount will be received?"
                  tooltip="You can choose if a discount will offer a percentage off of the cost of all tickets in the criteria, or if a set amount will be taken from the total for the order."
                ></InfoLabel>

                <div className="row">
                  <div className="col-sm-7">
                    <Dropdown
                      onChange={(item: IDropDownItem) => {
                        discount.DiscountType = parseInt(item.value);
                        onEventUpdated();
                      }}
                      value={discount.DiscountType.toString()}
                      items={[
                        { value: '0', description: 'Percentage off total' },
                        { value: '1', description: 'Amount off entire order' },
                        { value: '3', description: 'Amount off each ticket' },
                        { value: '2', description: 'Cheapest tickets free' },
                      ]}
                      description="Discount type"
                    />

                    {discount.DiscountType === DiscountTypes.FixedAmountOffOrder && criterias.length > 0 && (
                      <div>
                        <InfoLabel
                          text="Should this discount stack for each time the criteria is met?"
                          tooltip="When you have criteria for a discount and this option is enabled, we will take the amount specified from the order total for each time the whole criteria is met, so attendees can receive multiple discounts per order. When it is disabled only one fixed amount as specified will be taken from the order regardless of how many of any kind of ticket are ordered."
                        />

                        <Switch
                          trueLabel="Yes, give the discount each time criteria is met"
                          falseLabel="No, this discount does not stack"
                          onChange={(value) => {
                            discount.Stack = value;
                            onEventUpdated();
                          }}
                          checked={discount.Stack}
                        ></Switch>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 ">
                    {discount.DiscountType != 0 ? null : (
                      <div className="field">
                        <InfoInput
                          rightSymbol="%"
                          maxLength={2}
                          labelText={`Percentage${NumberHelper.isNumeric(percentageText) ? (parseInt(percentageText) >= 100 ? ' (Invalid over 100%)' : '') : ' (Invalid)'}`}
                          onChange={(value) => {
                            setPercentageText(value);
                            if (NumberHelper.isNumeric(value)) {
                              discount.Percentage = parseInt(value);
                              onEventUpdated();
                            }
                          }}
                          value={percentageText}
                        />
                      </div>
                    )}

                    {discount.DiscountType != 1 && discount.DiscountType != 3 ? null : (
                      <InfoInput
                        symbol={event.CurrencySymbol}
                        labelText={`Amount${NumberHelper.isNumeric(fixedAmountText) ? '' : ' (Invalid)'}`}
                        onChange={(value) => {
                          setFixedAmountText(value);
                          if (NumberHelper.isNumeric(value)) {
                            discount.FixedAmount = parseFloat(value);
                            onEventUpdated();
                          }
                        }}
                        value={fixedAmountText}
                      />
                    )}

                    {discount.DiscountType == 2 && (
                      <table className="counter">
                        <tbody>
                          <tr>
                            <td className="counter-button">
                              <button
                                onClick={() => {
                                  if (discount.CheapestQuantity == 0) return;
                                  discount.CheapestQuantity = discount.CheapestQuantity - 1;
                                  onEventUpdated();
                                }}
                                className="admin-button"
                              >
                                <SVGMinus />
                              </button>
                            </td>
                            <td className="counter-amount">{discount.CheapestQuantity}</td>
                            <td className="counter-button">
                              <button
                                onClick={() => {
                                  discount.CheapestQuantity = discount.CheapestQuantity + 1;
                                  onEventUpdated();
                                }}
                                className="admin-button"
                              >
                                <SVGPlus />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="spacer"></div>
        </>
      )}
    </>
  );
};

export default Discount;
