import { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../../../../api/AdminApi';
import Block from '../../../../components/Block';
import BlockHeader from '../../../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../../../components/BlockInfo';
import BlockTextBox from '../../../../components/BlockTextBox';
import Button from '../../../../components/Button';
import CheckBox from '../../../../components/CheckBox';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import SolidLine from '../../../../components/SolidLine';
import SpacerTable from '../../../../components/SpacerTable';
import GAHelper from '../../../../helpers/GAHelper';
import ThemeHelper from '../../../../helpers/ThemeHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import UserHelper from '../../../../helpers/UserHelper';
import NumberHelper from '../../../../helpers/NumberHelper';

export interface IProps {
  event?: IEvent;
  onClose: () => void;
  availableBalance: string;
}

interface IBankAccount {
  AccountName: string;
  SortCode: string;
  AccountNumber: string;
}

const WithdrawModal = ((props) => {
  const { event, availableBalance, onClose } = props;

  useEffect(() => {
    GAHelper.modal(`Withdraw`);
  }, []);

  const [loading, setLoading] = useState(null);
  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);
  const [withdrawComplete, setWithdrawComplete] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [awaitingCode, setAwaitingCode] = useState(false);
  const [sortCode, setSortCode] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState<IBankAccount>(null);
  const [acceptedSeatyTerms, setAcceptedSeatyTerms] = useState(false);

  const requestAuthCode = () => {
    setLoading('Checking credentials...');
    setError(null);

    UserHelper.requestSignInAuthCode(UserHelper.currentUser.Email, password)
      .then((result) => {
        setLoading(null);

        if (result.Valid) {
          setAwaitingCode(true);
        } else {
          setError(result.Message);
        }
      })
      .catch((message) => {
        setError(message);
        setLoading(null);
      });
  };

  const submitWithdraw = () => {
    setLoading('Requesting withdraw...');

    AdminApi.request('POST', `/api/Withdraw`, {
      EventId: event.Id,
      WithdrawToAccountName: selectedAccount ? selectedAccount.AccountName : accountName,
      WithdrawToSortCode: selectedAccount ? selectedAccount.SortCode : sortCode,
      WithdrawToAccountNumber: selectedAccount ? selectedAccount.AccountNumber : accountNumber,
      Password: password,
      AuthCode: authCode,
    })
      .then((response) => {
        setLoading(null);

        if (response.ErrorMessage) {
          setError(response.ErrorMessage);
        } else {
          setAwaitingCode(false);
          setWithdrawComplete(true);
        }
      })
      .catch((message) => {
        setLoading(null);
        setError(message);
      });
  };

  const getBankAccounts = () => {
    setLoading('Loading bank accounts...');

    AdminApi.request('GET', `/api/BankAccounts?eventId=${event.Id}`)
      .then((response) => {
        setBankAccounts(response ? response : []);
        setLoading(null);
        if (response && response.length > 0) {
          setSelectedAccount(response[0]);
        }
      })
      .catch((message) => {
        setLoading(null);
        setError(message);
      });
  };

  useEffect(() => {
    getBankAccounts();
  }, []);

  return (
    <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={onClose}>
      <div className="content">
        <div className="ticket-rip" />

        {loading && <Loader>{loading}</Loader>}

        <div className="body">
          <SpacerTable>
            <h1>{event.Name}</h1>
            <div>{event.Organisation.Name}</div>
            <div>
              {event.Venue.Name}, {event.Venue.Postcode}
            </div>
          </SpacerTable>

          <div className="spacer" />

          {awaitingCode ? (
            <>
              <table className="blocks">
                <tbody>
                  <Block>
                    <BlockHeader>Security code</BlockHeader>
                    <BlockTextBox
                      id="user-authcode"
                      onKeyPress={(e) => {
                        if (e.charCode == 13) submitWithdraw();
                      }}
                      type="text"
                      autoFocus={true}
                      value={authCode}
                      onChange={(e) => setAuthCode(e.toUpperCase().trim())}
                    />
                  </Block>
                  <BlockInfo>We have emailed you an security code. Please enter it below to confirm your email address and sign in to Seaty.</BlockInfo>
                  {error && <BlockInfo type={InfoType.Error}>{error}</BlockInfo>}
                </tbody>
              </table>
            </>
          ) : withdrawComplete ? (
            <table className="blocks">
              <tbody>
                <BlockInfo type={InfoType.Info}>You have requested a withdraw for {availableBalance}</BlockInfo>
              </tbody>
            </table>
          ) : (
            <>
              <table className="blocks">
                <tbody>
                  <BlockInfo type={InfoType.Info}>
                    You have {availableBalance} available to withdraw for this event. It can take up to 5 working days for your withdraw to be processed. All withdraws are recorded and an email
                    confirmation will be sent to all event administrators.
                  </BlockInfo>
                </tbody>
              </table>
              <div className="spacer" />
              <table className="blocks">
                <tbody>
                  {bankAccounts &&
                    bankAccounts.length > 0 &&
                    bankAccounts.map((account) => (
                      <CheckBox
                        onBoxClick={() => setSelectedAccount(account)}
                        key={account.SortCode + account.AccountNumber}
                        checked={selectedAccount && selectedAccount.SortCode === account.SortCode && selectedAccount.AccountNumber === account.AccountNumber}
                      >
                        <BlockHeader>{account.AccountName.toUpperCase()}</BlockHeader>
                        <BlockHeader rightText={account.SortCode}>Sort code</BlockHeader>
                        <BlockHeader rightText={account.AccountNumber}>Account number</BlockHeader>
                      </CheckBox>
                    ))}
                  {bankAccounts && bankAccounts.length > 0 && (
                    <CheckBox onBoxClick={() => setSelectedAccount(null)} checked={selectedAccount == null}>
                      Add a new bank account
                    </CheckBox>
                  )}
                  {!selectedAccount && (
                    <>
                      <Block>
                        <BlockHeader rightText={'(Required)'}>Account holder name</BlockHeader>
                        <BlockTextBox maxLength={1000} type="text" autoFocus value={accountName} onChange={(e) => setAccountName(e)} />
                      </Block>

                      <BlockInfo type={InfoType.Info}>
                        Please enter the account holder name exactly as it is held by the bank. This will be verified and not entering the correct name may result in a delay while we contact you to
                        verify your details.
                      </BlockInfo>

                      <Block>
                        <BlockHeader rightText={'(Required)'}>Sort code</BlockHeader>
                        <BlockTextBox
                          maxLength={6}
                          type="text"
                          value={sortCode}
                          onChange={(e) => {
                            if (NumberHelper.isNumeric(e)) {
                              setSortCode(e);
                            }
                          }}
                        />
                      </Block>

                      <BlockInfo type={InfoType.Info}>Please enter a 6 digit sort code.</BlockInfo>

                      <Block>
                        <BlockHeader rightText={'(Required)'}>Account number</BlockHeader>
                        <BlockTextBox
                          maxLength={8}
                          type="text"
                          value={accountNumber}
                          onChange={(e) => {
                            if (NumberHelper.isNumeric(e)) {
                              setAccountNumber(e);
                            }
                          }}
                        />
                      </Block>

                      <BlockInfo type={InfoType.Info}>Please enter an 8 digit bank account number.</BlockInfo>
                    </>
                  )}
                </tbody>
              </table>
            </>
          )}

          {!withdrawComplete && !awaitingCode && (
            <>
              <div className="spacer" />
              <table className="blocks">
                <tbody>
                  <Block>
                    <BlockHeader rightText={'(Required)'}>Account password</BlockHeader>
                    <BlockTextBox
                      onKeyPress={(e) => {
                        if (e.charCode == 13) requestAuthCode();
                      }}
                      id="password_field"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e)}
                    />
                  </Block>

                  <BlockInfo type={InfoType.Info}>Please provide your password so we can verify you have permission to submit this withdraw.</BlockInfo>

                  <CheckBox rightText={'(Required)'} title="Terms & Conditions" onBoxClick={() => setAcceptedSeatyTerms(!acceptedSeatyTerms)} key="terms_checkbox" checked={acceptedSeatyTerms}>
                    I have read and agree to the Seaty <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>
                  </CheckBox>

                  {error && <BlockInfo type={InfoType.Error}>{error}</BlockInfo>}
                </tbody>
              </table>
            </>
          )}

          <div className="spacer-x2" />

          {awaitingCode ? (
            <SpacerTable>
              <Button disabled={!authCode || authCode.length != 6} className="confirm large" onExecute={submitWithdraw} text={`Submit code`} />
              <Button
                onExecute={() => {
                  setAwaitingCode(false);
                  setAuthCode('');
                  setPassword('');
                }}
                text={`Cancel withdraw`}
              />
            </SpacerTable>
          ) : withdrawComplete ? (
            <SpacerTable>
              <Button className="large" onExecute={onClose} text={`Close`} />
            </SpacerTable>
          ) : (
            <SpacerTable>
              <Button
                disabled={
                  !acceptedSeatyTerms ||
                  password.length < 3 ||
                  loading != null ||
                  (selectedAccount == null && !withdrawComplete && (accountName.length < 2 || accountNumber.length < 8 || sortCode.length < 6))
                }
                className="confirm large"
                onExecute={requestAuthCode}
                text={`Request Withdraw`}
              />
            </SpacerTable>
          )}

          <SolidLine />

          <SpacerTable className="small-font">
            All withdrawals are subject to the Seaty <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
          </SpacerTable>
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export { WithdrawModal };
