import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import { IEvent } from '../../../../interfaces/IEvent';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import { OrderDashboardModal } from '../../../../modals/AdminOrderModal';
import SVGMultiply from '../../../../svg/SVGMultiply';
import AdminAttendeeRow from './AdminAttendeeRow';
import { AttendeeType, IAttendeeRow } from './IAttendeeRow';
import AdminAttendeeModal from './AdminAttendeeModal';

export interface IProps {
  organisation: IOrganisation;
  event?: IEvent;
  superAdmin?: boolean;
}

const AdminAttendee: FunctionComponent<IProps> = (props) => {
  const { event, organisation, superAdmin } = props;
  const organisationTag = organisation.OrganisationTag;
  const [category, setCategory] = useState<AttendeeType>(AttendeeType.All);

  const [filter, setFilter] = useState('');

  const [attendees, setAttendees] = useState<IAttendeeRow[]>([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [selectedRow, setSelectedRow] = useState<IAttendeeRow>(null);

  const refresh = (silent?: boolean) => {
    setLoading(!silent);

    const query = `/api/OrganisationAttendees?organisationTag=${organisationTag}`;

    AdminApi.request('GET', query)
      .then((response) => {
        setLoading(false);
        setAttendees(response);
      })
      .catch((error) => alert('Error: ' + error));
  };

  useEffect(() => {
    refresh();
  }, []);

  const onFilterKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      setFilter(query);
    }
  };

  const clearFilterClicked = () => {
    setFilter('');
    setQuery('');
  };

  const handleSearchChanged = (e) => {
    setQuery(e.target.value);
  };

  if (!organisation) return null;
  if (loading) return <Loader inline>Loading attendees...</Loader>;

  return (
    <>
      <Helmet>
        <title>#{organisationTag} Attendee</title>
        <meta name="description" content={`Manage purchases for your organisation.`} />
      </Helmet>

      <div className="toolbar">
        <div className="title">{event ? 'Event' : 'Organisation'} Attendee</div>
        <div className="buttons"></div>
      </div>

      <div className="row">
        <div className="col-sm-8">
          {attendees?.length > 0 && (
            <div className="admin-button-menu inline">
              <button className={category == AttendeeType.All ? 'selected' : ''} onClick={() => setCategory(AttendeeType.All)}>
                All
              </button>
              <button className={category == AttendeeType.EmailMarketingOnly ? 'selected' : ''} onClick={() => setCategory(AttendeeType.EmailMarketingOnly)}>
                Email Marketing Only
              </button>
            </div>
          )}
        </div>

        <div className="col-sm-4">
          <div className="donotprint" style={{ margin: '0' }}>
            <div className="input">
              <input id="searchinput" onChange={handleSearchChanged} type="text" placeholder="Search..." value={query} onKeyPress={onFilterKeyPress.bind(this)} style={{ margin: '0' }} />

              <button onClick={clearFilterClicked}>
                <SVGMultiply />
              </button>
            </div>
          </div>
        </div>
      </div>

      {attendees?.length > 0 ? (
        <div className="orderoverview" style={{ paddingBottom: '100px' }}>
          <div className="container-outer" style={{ minHeight: '300px' }}>
            <div className="container-inner" style={{ backgroundColor: 'white' }}>
              <table className="table grid-table">
                <thead>
                  <tr>
                    <th className="grid-header" style={{ width: '33px' }}></th>
                    <th className="grid-header">Attendee</th>
                    <th className="grid-header">Email</th>
                    <th className="grid-header" style={{ width: '250px' }}>
                      Marketing
                    </th>
                    <th className="grid-header" style={{ width: '155px' }}>
                      Orders
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {attendees
                    .filter((p) => category == AttendeeType.All || (category == AttendeeType.EmailMarketingOnly && p.AllowMarketing))
                    .filter((p) => !filter || (p.Email && p.Email.toLowerCase().includes(filter.toLowerCase())) || (p.AttendeeName && p.AttendeeName.toLowerCase().includes(filter.toLowerCase())))
                    .map((p) => (
                      <AdminAttendeeRow
                        showEventTag={!event}
                        currency={organisation.Currency.Symbol}
                        organisationTag={organisationTag}
                        handleRowClick={() => setSelectedRow(p)}
                        key={p.Email}
                        organisationId={organisation.Id}
                        data={p}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="info">There are no results available.</div>
      )}

      {selectedRow && (
        <AdminAttendeeModal
          organisation={organisation}
          email={selectedRow.Email}
          overviewRefreshed={() => {}}
          onClose={() => {
            setSelectedRow(null);
            refresh();
          }}
        />
      )}
    </>
  );
};

export default AdminAttendee;
