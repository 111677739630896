import linq from 'linq';
import 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import LocationHelper from '../../../../helpers/LocationHelper';
import NumberHelper from '../../../../helpers/NumberHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import SVGCompare from '../../../../svg/SVGCompare';
import SVGMinus from '../../../../svg/SVGMinus';
import SVGPlus from '../../../../svg/SVGPlus';
import SVGRefresh from '../../../../svg/SVGRefresh';
import './AdminCompare.scss';
import AdminCompareOptions from './AdminCompareOptions';
import { IOrganisation } from '../../../../interfaces/IOrganisation';

export interface IProps {
  organisation: IOrganisation;
}

interface IWeeklyOrder {
  OrdersThatWeek: number;
  WeeksBeforeStartWeek: number;
}

interface IEventComparison {
  FirstWeekNumber: number;
  LastWeekNumber: number;
  WeeklyOrders: IWeeklyOrder[];
  Event: IEvent;
}

interface IComparison {
  minWeek: string;
  maxWeek: string;
  Events: any[];
  EventWeeklyOrders: IEventComparison[];
}

function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}

const AdminCompare: FunctionComponent<IProps> = (props) => {
  const [state, setState] = useState<{ comparison: IComparison; loading: boolean }>({ comparison: null, loading: false });
  const organisation = props.organisation;

  const { comparison, loading } = state;

  const events =
    comparison &&
    linq
      .from(comparison.Events)
      .where((e) => e.OrganisationId == organisation.Id)
      .toArray();

  const stringValue = LocationHelper.getQueryVariable('eid');

  const [selectedEvents, setSelectedEvents] = useState<number[]>(
    linq
      .from([...(stringValue || !stringValue || stringValue.length == 0 || stringValue == 'eid' ? [] : JSON.parse(`[${replaceAll(replaceAll(stringValue, '[', ''), ']', '')}]`))])
      .distinct()
      .toArray(),
  );
  const [min, setMin] = useState<string>(NumberHelper.isNumeric(LocationHelper.getQueryVariable('min')) ? LocationHelper.getQueryVariable('min') : '');
  const [max, setMax] = useState<string>(NumberHelper.isNumeric(LocationHelper.getQueryVariable('max')) ? LocationHelper.getQueryVariable('max') : '');

  const compare = () => {
    setState({ ...state, loading: true });

    AdminApi.request('POST', `/api/Compare`, {
      organisationId: organisation.Id,
      eid: selectedEvents,
      min: NumberHelper.isNumeric(min) ? parseInt(min) : null,
      max: NumberHelper.isNumeric(max) ? parseInt(max) : null,
    })
      .then((response) => {
        setState({ comparison: response, loading: false });
      })
      .catch((message) => {
        alert(message);
        setState({ comparison: null, loading: false });
      });
  };

  useEffect(() => {
    compare();
  }, []);

  const handleCheckBoxClick = (e) => {
    if (linq.from(selectedEvents).any((id) => id == e.Id)) {
      setSelectedEvents(
        linq
          .from(selectedEvents)
          .where((ee) => ee != e.Id)
          .toArray(),
      );
    } else {
      setSelectedEvents(selectedEvents.concat([e.Id]));
    }
  };

  const deductFromWeek = () => {
    if (min == null || min == '') {
      setMin('-1');
      return;
    }
    setMin('' + (parseInt(min) - 1));
  };

  const resetFromWeek = () => {
    setMin('');
  };

  const addFromWeek = () => {
    if (min == null || min == '') {
      setMin('1');
      return;
    }

    setMin('' + (parseInt(min) + 1));
  };

  const deductToWeek = () => {
    if (max == null || max == '') {
      setMax('-1');
      return;
    }

    setMax('' + (parseInt(max) - 1));
  };

  const resetToWeek = () => {
    setMax('');
  };

  const addToWeek = () => {
    if (max == null || max == '') {
      setMax('1');
      return;
    }

    setMax('' + (parseInt(max) + 1));
  };

  const categories = [];

  if (comparison && comparison.EventWeeklyOrders && comparison.EventWeeklyOrders.length > 0) {
    const firstResult = comparison.EventWeeklyOrders[0];

    for (let i = firstResult.FirstWeekNumber; i >= firstResult.LastWeekNumber; i--) {
      categories.push(`${i}w`);
    }
  }

  return (
    <>
      <Helmet>
        <title>#{organisation.OrganisationTag} Compare Events</title>
        <meta name="description" content={`Use this page to compare ticket sales between events.`} />
      </Helmet>
      {loading ? (
        <Loader inline>Loading comparison...</Loader>
      ) : (
        <>
          <div className="toolbar">
            <div className="title">Compare Events</div>
            <div className="buttons">
              <button onClick={compare}>
                <SVGCompare />
                Compare selected events
              </button>
            </div>
          </div>

          <div className="compare-events">
            <div className="row section calculator ">
              <div className="col-sm-4 donotprint">
                <div className="info-row">
                  <div>
                    <span className="value">{min == '' ? 'All' : Math.abs(parseInt(min))}</span>
                    <span className="value-description">{min == '' || min == '0' ? 'weeks' : parseInt(min) > 0 ? 'weeks before' : 'weeks after'}</span>
                  </div>
                  <div className="buttons">
                    <button onClick={addFromWeek}>
                      <SVGMinus />
                    </button>
                    <button onClick={resetFromWeek}>
                      <SVGRefresh />
                    </button>
                    <button onClick={deductFromWeek}>
                      <SVGPlus />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 donotprint">
                <div className="info-row">
                  <div>
                    <span className="value">{max == '' ? 'All' : Math.abs(parseInt(max))}</span>
                    <span className="value-description">{max == '' || max == '0' ? 'weeks' : parseInt(max) > 0 ? 'weeks before' : 'weeks after'}</span>
                  </div>
                  <div className="buttons">
                    <button onClick={addToWeek}>
                      <SVGMinus />
                    </button>
                    <button onClick={resetToWeek}>
                      <SVGRefresh />
                    </button>
                    <button onClick={deductToWeek}>
                      <SVGPlus />
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="info">
                  <strong>Compare events</strong>
                  <br />
                  Choose which events you wish to compare from the list at the bottom of the page and press the "Compare selected events" button.
                  <br />
                  <br />
                  This comparison is shown in weeks before and after your first event date.
                </div>
              </div>
            </div>

            {comparison && comparison.EventWeeklyOrders && comparison.EventWeeklyOrders.length > 0 && (
              <div className="">
                <ReactApexChart
                  options={{
                    chart: {
                      height: 350,
                      type: 'line',
                      zoom: {
                        enabled: false,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'straight',
                      width: 2.5,
                    },
                    grid: {
                      row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.25,
                      },
                    },
                    xaxis: {
                      categories: categories,
                    },
                  }}
                  series={comparison.EventWeeklyOrders.map((e) => {
                    const data = [];

                    for (let i = e.FirstWeekNumber; i >= e.LastWeekNumber; i--) {
                      const weeklyOrder = linq.from(e.WeeklyOrders).firstOrDefault((o) => o.WeeksBeforeStartWeek == i);

                      if (weeklyOrder) {
                        data.push(weeklyOrder.OrdersThatWeek);
                      } else {
                        data.push(null);
                      }
                    }

                    return {
                      name: `#${e.Event.EventTag}`,
                      data: data,
                    };
                  })}
                  type="line"
                  height={450}
                />
              </div>
            )}

            <div className="pagebreak">
              <AdminCompareOptions
                events={events}
                setMin={setMin}
                min={min}
                setMax={setMax}
                max={max}
                onCompareClick={compare}
                selectedEvents={selectedEvents}
                handleEventClick={handleCheckBoxClick}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminCompare;
