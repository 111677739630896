import React, { useEffect } from 'react';
import { IEvent } from '../../../interfaces/IEvent';
import Toolbar from '../toolbar/Toolbar';
import SVGPersonnel from '../../../svg/SVGPersonnel';
import PersonnelGroup from './PersonnelGroup';
import { IImageUploadRequest } from '../ImageUploader';
import GuidHelper from '../../../helpers/GuidHelper';
import { IOrganisation } from '../../../interfaces/IOrganisation';

export interface IProps {
  event?: IEvent;
  onEventUpdated?: (e: IEvent) => void;
  organisation?: IOrganisation;
  onOrganisationUpdated?: (e: IOrganisation) => void;
  globalOptions: JSX.Element;
  onImageRequested: (request: IImageUploadRequest) => void;
  imageRequests: { [key: string]: IImageUploadRequest };
}

const PersonnelGroupsSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated, organisation, onOrganisationUpdated } = props;

  var personnelGroups = [];
  const personnelGroupComponents = [];

  if (event && event.PersonnelGroups == null) {
    event.PersonnelGroups = [];
  } else if (event) {
    personnelGroups = event.PersonnelGroups;
  }

  if (organisation && organisation.PersonnelGroups == null) {
    organisation.PersonnelGroups = [];
  } else if (organisation) {
    personnelGroups = organisation.PersonnelGroups;
  }

  personnelGroups.forEach((personnelGroup, index) => {
    personnelGroupComponents.push(
      <PersonnelGroup
        onImageRequested={props.onImageRequested}
        imageRequests={props.imageRequests}
        onUpdated={() => {
          if (onEventUpdated) onEventUpdated({ ...event });
          if (onOrganisationUpdated) onOrganisationUpdated({ ...organisation });
        }}
        handleDeleteClick={() => {
          if (event) event.PersonnelGroups = event.PersonnelGroups.filter((x) => x != personnelGroup);
          if (organisation) organisation.PersonnelGroups = organisation.PersonnelGroups.filter((x) => x != personnelGroup);
          if (onEventUpdated) onEventUpdated({ ...event });
          if (onOrganisationUpdated) onOrganisationUpdated({ ...organisation });
        }}
        event={event}
        organisation={organisation}
        personnelGroup={personnelGroup}
        key={'event_personnel_' + personnelGroup.Guid}
      />,
    );
  });

  const addNew = () => {
    const group = {
      Id: 0,
      Name: '',
      EventId: event ? event.Id : null,
      OrganisationId: organisation ? organisation.Id : null,
      Guid: GuidHelper.new(),
      Index: 0,
      Personnel: [],
    };

    if (event) event.PersonnelGroups.push(group);
    if (organisation) organisation.PersonnelGroups.push(group);

    if (onEventUpdated) onEventUpdated({ ...event });
    if (onOrganisationUpdated) onOrganisationUpdated({ ...organisation });
  };

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}
          <button className="option" onClick={addNew}>
            <label>Add Personnel Group</label>
            <div className="icon">
              <SVGPersonnel />
            </div>
          </button>
        </div>
      </Toolbar>
      <h2>Personnel</h2>
      <div className="row">
        <div className="col-xs-12">
          {personnelGroupComponents}

          <button className="admin-button" onClick={addNew}>
            Add personnel group/team
          </button>
        </div>
      </div>
    </>
  );
};

export default PersonnelGroupsSection;
