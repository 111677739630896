import { FunctionComponent, useEffect, useState } from 'react';
import Modal from './Modal';
import AdminMenuTour, { IAdminMenuTourProps } from './AdminMenuTour';
import loaderState, { Loader, LoaderHelper } from '../atoms/loaderState';
import { useRecoilState } from 'recoil';
import CacheHelper from '../helpers/CacheHelper';
import AdminMenuOrganisation from './AdminMenuOrganisation';

export interface IAdminMenuTourModalProps {
  tourTag: string;
  onCloseClick: () => void;
}

const AdminMenuTourModal: FunctionComponent<IAdminMenuTourModalProps> = (props) => {
  const { onCloseClick } = props;
  const [loaders, setLoaders] = useRecoilState(loaderState);
  const [organisation, setOrganisation] = useState(null);
  const [tour, setTour] = useState(null);

  useEffect(() => {
    if (!tour && props.tourTag) {
      LoaderHelper.add(setLoaders, loaders, Loader.AdminMenuTour, 'Loading event...');

      CacheHelper.tourByTag(props.tourTag).then((t) => {
        setTour(t);
        LoaderHelper.add(setLoaders, loaders, Loader.AdminMenuOrganisation, 'Loading organisation...');

        CacheHelper.organisationByTag(t.Organisation.OrganisationTag).then((o) => {
          setOrganisation(o);
          LoaderHelper.remove(setLoaders, loaders, Loader.AdminMenuOrganisation);
        });

        LoaderHelper.remove(setLoaders, loaders, Loader.AdminMenuTour);
      });
    }
  }, []);

  return (
    <Modal className="admin-menu-modal" theme={tour && tour.Theme} onCloseClick={onCloseClick}>
      {tour && <AdminMenuTour onCloseClick={onCloseClick} tour={tour} />}
      {organisation && <AdminMenuOrganisation onCloseClick={onCloseClick} organisation={organisation} />}
    </Modal>
  );
};

export default AdminMenuTourModal;
