import linq from 'linq';

import { IDiscount } from '../interfaces/IDiscount';
import { DiscountModes } from '../enums/DiscountModes';

export default class DiscountHelper {
  public static getBestAutomaticDiscount = (discounts: IDiscount[]) => {
    return linq
      .from(discounts)
      .where((d) => d.ApplicationMode == DiscountModes.Automatically)
      .orderByDescending((d: IDiscount) => d.Amount)
      .firstOrDefault();
  };
}
