import { loadStripe, Stripe } from '@stripe/stripe-js';
import EnvironmentVariables from '../EnvironmentVariables';
import { IPurchase } from '../interfaces/IPurchase';
import GeneralApi from '../api/GeneralApi';

export interface IIntent {
  purchaseId: number;
  clientSecret: string;
}

export default class StripeHelper {
  private static stripeAccounts: { [key: string]: Promise<Stripe> } = {};

  public static createIntent(purchase: IPurchase): Promise<IIntent> {
    return GeneralApi.request('POST', '/api/StripeIntent', {
      Purchase: purchase,
    })
      .then((response) => {
        return {
          purchaseId: response.Purchase.Id,
          clientSecret: response.payment_intent_client_secret,
        };
      })
      .catch((message) => {
        return Promise.reject(message);
      });
  }

  public static getAppearance() {
    return {
      theme: 'stripe' as any,
      rules: {
        '.Tab': {
          border: '2px solid #c4ccde',
          fontFamily: 'niveau-grotesk, sans-serif',
        },

        '.Tab:hover': {},

        '.Tab--selected': {},

        '.Input': {
          color: '#525f7f',
          border: '2px solid #c4ccde',
          borderRadius: '6px',
          fontFamily: 'niveau-grotesk, sans-serif',
          fontSize: '16px',
          padding: '12px 16px',
          fontWeight: '300',
        },

        '.Input--invalid': {},

        '.Label': {
          color: '#525f7f',
          fontFamily: 'niveau-grotesk, sans-serif',
          fontWeight: '700',
          fontSize: '18px',
          lineHeight: '20px',
        },

        // See all supported class names and selector syntax below
      },
    };
  }

  public static get(accountId: string): Promise<Stripe> {
    if (!StripeHelper.stripeAccounts[accountId]) {
      return (StripeHelper.stripeAccounts[accountId] = loadStripe(EnvironmentVariables.STRIPE_TOKEN, { stripeAccount: accountId ? accountId : undefined }));
    } else {
      return StripeHelper.stripeAccounts[accountId];
    }
  }
}
