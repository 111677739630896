import moment, { duration, Moment } from 'moment';

export default class OrderTimerHelper {
  public static dateLocked: Moment;
  public static lockTimer: number = null;
  public static onTimeout: () => void;
  public static stopped: boolean = true;
  public static interval: number = 360;

  public static start(interval = OrderTimerHelper.interval) {
    OrderTimerHelper.interval = interval;
    OrderTimerHelper.stopped = false;
    OrderTimerHelper.reset(interval);
  }

  public static stop() {
    OrderTimerHelper.stopped = true;
    OrderTimerHelper.clear();
  }

  public static clear() {
    if (OrderTimerHelper.lockTimer == null) return;
    clearTimeout(OrderTimerHelper.lockTimer);
  }

  private static update(interval) {
    if (OrderTimerHelper.stopped) {
      return;
    }

    const time = moment(OrderTimerHelper.dateLocked).add(interval, 'seconds').diff(moment());

    var diff = duration(time);

    var e = document.getElementById('countdown');

    if (e) {
      e.textContent = `${diff.minutes() > 0 ? diff.minutes() + `m ` : ''} ${diff.seconds()}s `;
    }

    if (time < 250) {
      OrderTimerHelper.stop();
      if (OrderTimerHelper.onTimeout) {
        OrderTimerHelper.onTimeout();
      }
    }
  }

  public static reset(interval = OrderTimerHelper.interval) {
    OrderTimerHelper.dateLocked = moment();
    OrderTimerHelper.update(interval);
    OrderTimerHelper.clear();

    var timerId = window.setInterval(() => {
      OrderTimerHelper.update(interval);
    }, 250);

    OrderTimerHelper.lockTimer = timerId;
  }
}
