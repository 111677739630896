import { FunctionComponent, useEffect, useState } from 'react';
import GeneralApi from '../api/GeneralApi';
import Block from '../components/Block';
import BlockHeader from '../components/BlockHeader';
import BlockInfo, { InfoType } from '../components/BlockInfo';
import BlockTextBox from '../components/BlockTextBox';
import Button from '../components/Button';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import SolidLine from '../components/SolidLine';
import SpacerTable from '../components/SpacerTable';
import GAHelper from '../helpers/GAHelper';
import ThemeHelper from '../helpers/ThemeHelper';
import UserHelper from '../helpers/UserHelper';
import Cookies from 'js-cookie';
import { request } from 'http';

export interface IProps {
  onClose: () => void;
  messageText?: string;
  onSignUpComplete?: () => void;
}

const SignUpModal = ((props) => {
  const [authCode, setAuthCode] = useState('');
  const [awaitingCode, setAwaitingCode] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [error, setError] = useState(null);
  const [busyMessage, setBusyMessage] = useState(null);
  const [signUpComplete, setSignUpComplete] = useState(false);

  const signUpDisabled = busyMessage != null || !email || email.length < 4 || !password || password.length < 6;

  const submitAuthCode = () => {
    if (!authCode || authCode.length != 6) {
      setError('Auth code is invalid.');
      return;
    }
    if (signUpDisabled) return;

    setError(null);
    setBusyMessage('Submitting your details...');

    GeneralApi.request('POST', '/api/Register', {
      firstname: firstName,
      secondname: secondName,
      email: email,
      password: password,
      authCode: authCode,
    })
      .then((result: { Message: string; Valid: boolean }) => {
        if (!result.Valid) {
          setError(result.Message);
          setBusyMessage(null);
          return false;
        }

        Cookies.remove('last-managed-organisation');
        setBusyMessage('Welcome to Seaty!');

        UserHelper.signIn(email, password, authCode)
          .then(() => {
            setSignUpComplete(true);

            if (props.onSignUpComplete) {
              props.onSignUpComplete();
            } else {
              window.location.reload();
            }
          })
          .catch((message) => {
            setError(message);
            setBusyMessage(null);
          });
      })
      .catch((message) => {
        setError(message);
      });
  };

  const requestAuthCode = () => {
    if (signUpDisabled) return;

    setError(null);
    setBusyMessage('Checking details...');

    UserHelper.requestRegisterAuthCode(email, password, firstName, secondName)
      .then((result) => {
        setBusyMessage(null);

        if (result.Valid) {
          setAwaitingCode(true);
        } else {
          setError(result.Message);
        }
      })
      .catch((message) => {
        setError(message);
        setBusyMessage(null);
      });
  };

  useEffect(() => {
    GAHelper.modal(`Sign Up`);
  }, []);

  return (
    <>
      <Modal onCloseClick={props.onClose}>
        <div className="content">
          <div className="ticket-rip" />

          <div className="body">
            {props.messageText ? (
              <SpacerTable className="small-font">{props.messageText}</SpacerTable>
            ) : (
              <SpacerTable className="small-font">
                Welcome to Seaty! 🎉 We're thrilled to have you join us. Please enter your details below to start your journey with us and create your account. 🚀✨
              </SpacerTable>
            )}
            <SolidLine />

            {busyMessage ? (
              <Loader inline={true}>{busyMessage}</Loader>
            ) : (
              <>
                <table className="blocks">
                  <tbody>
                    {awaitingCode ? (
                      <>
                        <Block>
                          <BlockHeader>Security code</BlockHeader>
                          <BlockTextBox
                            id="user-authcode"
                            onKeyPress={(e) => {
                              if (e.charCode == 13) submitAuthCode();
                            }}
                            type="text"
                            autoFocus={true}
                            value={authCode}
                            onChange={(e) => setAuthCode(e.toUpperCase().trim())}
                          />
                        </Block>
                        <BlockInfo>We have emailed you an security code. Please enter it below to confirm your email address and sign up to Seaty.</BlockInfo>
                      </>
                    ) : (
                      <Block>
                        <BlockHeader>First name</BlockHeader>
                        <BlockTextBox type="text" autoFocus={true} value={firstName} onChange={(e) => setFirstName(e)} />
                        <div className="spacer" />

                        <BlockHeader>Last name</BlockHeader>
                        <BlockTextBox type="text" value={secondName} onChange={(e) => setSecondName(e)} />
                        <div className="spacer" />

                        <BlockHeader>Email</BlockHeader>
                        <BlockTextBox type="email" value={email} onChange={(e) => setEmail(e)} />
                        <div className="spacer" />

                        <BlockHeader>Password</BlockHeader>
                        <BlockTextBox
                          onKeyPress={(e) => {
                            if (e.charCode == 13) requestAuthCode();
                          }}
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e)}
                        />
                      </Block>
                    )}

                    {error && <BlockInfo type={InfoType.Error}>{error}</BlockInfo>}
                  </tbody>
                </table>
                <div className="spacer-x2" />

                {awaitingCode ? (
                  <SpacerTable>
                    <Button disabled={!authCode || authCode.length != 6} className="confirm large" onExecute={submitAuthCode} text={`Submit code`} />
                    <Button
                      onExecute={() => {
                        setAwaitingCode(false);
                        setAuthCode('');
                        setPassword('');
                      }}
                      text={`Cancel sign up`}
                    />
                  </SpacerTable>
                ) : signUpComplete ? (
                  <SpacerTable>
                    <Button className="large" onExecute={props.onClose} text={`Close`} />
                  </SpacerTable>
                ) : (
                  <SpacerTable>
                    <Button disabled={signUpDisabled} className="confirm large" onExecute={requestAuthCode} text={`Sign Up`} />
                  </SpacerTable>
                )}
              </>
            )}

            <SolidLine />

            <SpacerTable className="small-font">
              All accounts are subject to the Seaty <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
            </SpacerTable>
          </div>

          <div className="ticket-rip bottom" />
        </div>
      </Modal>
    </>
  );
}) as FunctionComponent<IProps>;

export { SignUpModal };
