import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32px" height="32px">
      <path d="M 9 4 L 9 11 L 7 11 C 5.355469 11 4 12.355469 4 14 L 4 24 L 9 24 L 9 28 L 23 28 L 23 24 L 28 24 L 28 14 C 28 12.355469 26.644531 11 25 11 L 23 11 L 23 4 Z M 11 6 L 21 6 L 21 11 L 11 11 Z M 7 13 L 25 13 C 25.566406 13 26 13.433594 26 14 L 26 22 L 23 22 L 23 18 L 9 18 L 9 22 L 6 22 L 6 14 C 6 13.433594 6.433594 13 7 13 Z M 8 14 C 7.449219 14 7 14.449219 7 15 C 7 15.550781 7.449219 16 8 16 C 8.550781 16 9 15.550781 9 15 C 9 14.449219 8.550781 14 8 14 Z M 11 20 L 21 20 L 21 26 L 11 26 Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
