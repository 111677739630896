import React, { CSSProperties, useEffect, useState } from 'react';
import SVGInfo from '../svg/SVGInfo';
import GuidHelper from '../helpers/GuidHelper';

export interface IProps {
  labelText?: string;
  placeholder?: string;
  required?: boolean;
  labelColour?: string;
  fontSize?: string;
  info?: string;
  onBlur?: (e: any) => void;
  onChange: (e: string) => void;
  disabled?: boolean;
  symbol?: string;
  rightSymbol?: string;
  id?: string;
  maxLength?: number;
  classes?: string;
  value: string;
  inputClassName?: string;
  alwaysShowLabel?: boolean;
  rows?: number;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  readOnly?: boolean;
  autoFocus?: boolean;
}

const InfoInput: React.FC<IProps> = (props) => {
  const [id, setId] = useState(props.id ? props.id : GuidHelper.new());

  useEffect(() => {
    if (props.autoFocus) {
      window.setTimeout(() => {
        const input = document.getElementById(id);

        if (!input) return;
        (input as HTMLInputElement).select();
      }, 200);
    }
  }, []);

  let placeholder = props.placeholder && props.placeholder.length > 0 ? props.placeholder : props.labelText;

  if (props.required) {
    placeholder += ' *';
  }

  var input = null;

  var style = { ...props.style };

  if (props.fontSize != null) {
    style['font-size'] = props.fontSize;
  }

  var labelStyle = {};
  if (props.labelColour != null) {
    labelStyle['color'] = props.labelColour;
  }

  var info = null;
  if (props.info != null) {
    info = (
      <span className="info-tooltip tip" data-toggle="tooltip" data-placement="bottom" title={props.info}>
        <SVGInfo />
      </span>
    );
  }

  if (props.rows == null) {
    input = (
      <input
        readOnly={props.readOnly}
        id={id}
        style={style}
        maxLength={props.maxLength == null ? 1000 : props.maxLength}
        placeholder={placeholder}
        onBlur={props.onBlur}
        value={props.value == null ? '' : props.value}
        className={
          'form-control' +
          (props.classes != null ? ' ' + props.classes : '') +
          (props.symbol == null || props.symbol.length == 0 ? '' : ' symbol left') +
          (props.rightSymbol == null || props.rightSymbol.length == 0 ? '' : ' symbol right') +
          (props.value == null || props.value.length == 0 ? '' : ' has-value')
        }
        type="text"
        onChange={(e) => props.onChange(e.target.value)}
      />
    );
  } else {
    input = (
      <textarea
        id={id}
        readOnly={props.readOnly}
        style={style}
        rows={props.rows}
        maxLength={props.maxLength == null ? 1000 : props.maxLength}
        placeholder={placeholder}
        onBlur={props.onBlur}
        value={props.value == null ? '' : props.value}
        className={
          'form-control' +
          (props.symbol == null || props.symbol.length == 0 ? '' : ' symbol left') +
          (props.rightSymbol == null || props.rightSymbol.length == 0 ? '' : ' symbol right') +
          (props.value == null || props.value.length == 0 ? '' : ' has-value')
        }
        onChange={(e) => props.onChange(e.target.value)}
      />
    );
  }

  return (
    <div
      style={props.containerStyle}
      className={'input-label-group' + (props.disabled ? ' disabled' : '') + (props.readOnly ? ' readonly' : '') + (props.inputClassName == null ? '' : ' ' + props.inputClassName)}
    >
      {props.symbol == null || props.symbol.length == 0 ? null : <span className="symbol left">{props.symbol}</span>}
      {input}
      {props.rightSymbol == null || props.rightSymbol.length == 0 ? null : <span className="symbol right">{props.rightSymbol}</span>}
      {(props.value == null || props.value.length == 0) && !props.alwaysShowLabel ? null : (
        <label style={labelStyle} className="input-label animated fadeIn">
          {props.labelText}
        </label>
      )}
      {info}
    </div>
  );
};

export default InfoInput;
