export default class EnvironmentVariables {
  public static isDev() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  }

  public static STRIPE_TOKEN: string = EnvironmentVariables.isDev() ? 'pk_test_3n0TFyj7vItq9kjlfQizrJcA' : 'pk_live_qt9neNOnC9DUoJpdvC9ORpk8';
  public static STRIPE_CLIENT_ID: string = EnvironmentVariables.isDev() ? 'ca_CK3yVolxVfDTYqF9wiEq19SopXtar19l' : 'ca_CK3yA358xIt2Akp5wZCrj61jeYn40EQo';

  // public static STRIPE_TOKEN: string = 'pk_live_qt9neNOnC9DUoJpdvC9ORpk8';
  // public static STRIPE_CLIENT_ID: string = 'ca_CK3yA358xIt2Akp5wZCrj61jeYn40EQo';

  public static DEBUG_URL: string = 'https://seatyapi.eu.ngrok.io';

  public static HOME_URL: string = EnvironmentVariables.isDev() ? 'https://seaty.eu.ngrok.io' : 'https://www.Seaty.co.uk';

  public static GENERAL_API_URL: string = EnvironmentVariables.isDev() ? EnvironmentVariables.DEBUG_URL : 'https://seaty-core-api.azurewebsites.net';
  public static ADMIN_API_URL: string = EnvironmentVariables.isDev() ? EnvironmentVariables.DEBUG_URL : 'https://seaty-core-api-admin.azurewebsites.net';
  public static FEE_PERCENTAGE = 7.2;
  public static MIN_FEE = 50;
}
