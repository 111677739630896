import React from 'react';
import { IEvent } from '../../../interfaces/IEvent';
import SVGQuestion from '../../../svg/SVGQuestion';
import Toolbar from '../toolbar/Toolbar';
import Question from './Question';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const QuestionsSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;
  var rowStyles = {};
  var questions = [];

  if (event.Questions == null) {
    event.Questions = [];
  }

  event.Questions.forEach((question, index) => {
    questions.push(
      <Question
        onEventUpdated={() => onEventUpdated({ ...event })}
        handleDeleteClick={() => {
          event.Questions = event.Questions.filter((x) => x != question);
          onEventUpdated({ ...event });
        }}
        event={event}
        question={question}
        key={'event_question_' + index}
      />
    );
  });

  const addNew = () => {
    event.Questions.push({
      Id: 0,
      Name: '',
      QuestionType: 0,
      Criteria: [],
      Answers: [],
      Required: false,
    });

    onEventUpdated({ ...event });
  };

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}
          <button className="option" onClick={addNew}>
            <label>Add Question</label>
            <div className="icon">
              <SVGQuestion />
            </div>
          </button>
        </div>
      </Toolbar>
      <h2>Questions</h2>
      <div className="row" style={rowStyles}>
        <div className="col-xs-12">
          {questions}

          <button className="admin-button" onClick={addNew}>
            Add a question
          </button>
        </div>
      </div>
    </>
  );
};

export default QuestionsSection;
