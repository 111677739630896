import React from 'react';
import { Link } from 'react-router-dom';
import LocationHelper from '../../helpers/LocationHelper';
import SVGHelper from '../../helpers/SVGHelper';
import './Docs.scss';
import ISidebarItem from './ISidebarItem';
import './Sidebar.scss';
import sidebarItems from './SidebarItems';

const getSidebarComponent = (item: ISidebarItem, url: string) => {
  if (!item.url) {
    return (
      <React.Fragment key={item.name}>
        <h1>{item.name}</h1>
        {item.items && item.items.map((s) => getSidebarComponent(s, url))}
      </React.Fragment>
    );
  } else {
    return (
      <ul className={`sidebar-item${url.includes(item.url) ? ' selected' : ''}`} key={item.url} data-collapsable="false">
        <li>
          <Link to={item.url}>{item.name}</Link>
        </li>
      </ul>
    );
  }
};

interface IProps {
  content?: any;
}

const SideBar: React.FC<IProps> = (props: IProps) => {
  const url = LocationHelper.getLocation().pathname;

  return (
    <>
      <div className="sidebar">
        <Link className="logo" to="/">
          <img src={SVGHelper.get('Seaty')} />
        </Link>
        <nav>
          {sidebarItems.map((item) => {
            return <React.Fragment>{getSidebarComponent(item, url)}</React.Fragment>;
          })}
        </nav>
      </div>
    </>
  );
};

export default SideBar;
