import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="26px" height="26px">
      <path d="M 15.40625 13 L 6.820313 4.234375 C 6.4375 3.84375 6.441406 3.21875 6.828125 2.828125 L 8.363281 1.292969 C 8.757813 0.902344 9.390625 0.902344 9.78125 1.296875 L 20.707031 12.292969 C 20.902344 12.488281 21 12.742188 21 13 C 21 13.257813 20.902344 13.511719 20.707031 13.707031 L 9.78125 24.703125 C 9.390625 25.097656 8.757813 25.097656 8.363281 24.707031 L 6.828125 23.171875 C 6.441406 22.78125 6.4375 22.15625 6.820313 21.765625 Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
