import linq from 'linq';
import { CSSProperties, FunctionComponent } from 'react';
import DeviceHelper from '../../helpers/DeviceHelper';
import NumberHelper from '../../helpers/NumberHelper';
import SVGHelper from '../../helpers/SVGHelper';
import { ISeatingPlan } from '../../interfaces/ISeatingPlan';
import { ISeatingPlanObject } from '../../interfaces/ISeatingPlanObject';

export interface IProps {
  handleClick: () => void;
  seatingPlan: ISeatingPlan;
  height: number;
  width: number;
  object: ISeatingPlanObject;
  clickStart: (o: ISeatingPlanObject) => void;
  clickEnd: (o: ISeatingPlanObject) => void;
}

const SeatingPlanObject: FunctionComponent<IProps> = (props) => {
  const { object } = props;
  var myColumn = object.column;
  var myRow = object.row;

  var vectorStyle: CSSProperties = null;

  var image = null;

  let curveTopRight = 0;
  let curveBottomRight = 0;
  let curveBottomLeft = 0;

  const curveLittle = 8;
  const objectWidth = props.width + props.width * 0.006;

  if (object.designMode == 'standing') {
    image = null;
    vectorStyle = { fill: '#E6E6E6', border: '4px solid #C1C1C1' };

    var objects = props.seatingPlan.Objects;

    var anotherAbove = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'standing' && o.row == myRow - 1 && o.column == myColumn;
      })
      .firstOrDefault();
    var anotherBelow = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'standing' && o.row == myRow + 1 && o.column == myColumn;
      })
      .firstOrDefault();
    var anotherLeft = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'standing' && o.row == myRow && o.column == myColumn - 1;
      })
      .firstOrDefault();
    var anotherRight = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'standing' && o.row == myRow && o.column == myColumn + 1;
      })
      .firstOrDefault();

    if (anotherAbove != null) {
      vectorStyle.borderTop = '0 solid white';
    }
    if (anotherBelow != null) {
      vectorStyle.borderBottom = '0 solid white';
      // vectorStyle.height = '100%';
    }
    if (anotherLeft != null) {
      vectorStyle.borderLeft = '0 solid white';
    }
    if (anotherRight != null) {
      vectorStyle.borderRight = '0 solid white';
      // vectorStyle.width = '100%';
    }

    vectorStyle.borderRadius = '0';

    if (anotherAbove == null && anotherRight == null) curveTopRight = curveLittle;
    if (anotherBelow == null && anotherRight == null) curveBottomRight = curveLittle;
    if (anotherBelow == null && anotherLeft == null) curveBottomLeft = curveLittle;
  }

  if (object.designMode == 'stage') {
    image = null;
    vectorStyle = { fill: '#DCDCEF', border: '4px solid rgb(183 183 218)' };

    objects = props.seatingPlan.Objects;

    var anotherAbove = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'stage' && o.row == myRow - 1 && o.column == myColumn;
      })
      .firstOrDefault();
    var anotherBelow = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'stage' && o.row == myRow + 1 && o.column == myColumn;
      })
      .firstOrDefault();
    var anotherLeft = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'stage' && o.row == myRow && o.column == myColumn - 1;
      })
      .firstOrDefault();
    var anotherRight = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'stage' && o.row == myRow && o.column == myColumn + 1;
      })
      .firstOrDefault();

    if (anotherAbove != null) {
      vectorStyle.borderTop = 'none';
    }
    if (anotherBelow != null) {
      vectorStyle.borderBottom = 'none';
      // vectorStyle.height = '100%';
    }
    if (anotherLeft != null) {
      vectorStyle.borderLeft = 'none';
    }
    if (anotherRight != null) {
      vectorStyle.borderRight = 'none';
      // vectorStyle.width = '100%';
    }

    if (anotherAbove == null && anotherRight == null) curveTopRight = curveLittle;
    if (anotherBelow == null && anotherRight == null) curveBottomRight = curveLittle;
    if (anotherBelow == null && anotherLeft == null) curveBottomLeft = curveLittle;
  }

  if (object.designMode == 'label') {
    image = null;
    vectorStyle = { fill: 'rgb(238 238 244)' };

    objects = props.seatingPlan.Objects;

    var anotherAbove = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'label' && o.row == myRow - 1 && o.column == myColumn;
      })
      .firstOrDefault();
    var anotherBelow = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'label' && o.row == myRow + 1 && o.column == myColumn;
      })
      .firstOrDefault();
    var anotherLeft = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'label' && o.row == myRow && o.column == myColumn - 1;
      })
      .firstOrDefault();
    var anotherRight = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'label' && o.row == myRow && o.column == myColumn + 1;
      })
      .firstOrDefault();

    if (anotherAbove != null) {
      vectorStyle.borderTop = 'none';
    }
    if (anotherBelow != null) {
      vectorStyle.borderBottom = 'none';
      // vectorStyle.height = '100%';
    }
    if (anotherLeft != null) {
      vectorStyle.borderLeft = 'none';
    }
    if (anotherRight != null) {
      vectorStyle.borderRight = 'none';
      // vectorStyle.width = '100%';
    }

    if (anotherAbove == null && anotherRight == null) curveTopRight = curveLittle;
    if (anotherBelow == null && anotherRight == null) curveBottomRight = curveLittle;
    if (anotherBelow == null && anotherLeft == null) curveBottomLeft = curveLittle;
  }

  let sectorColour = null;

  if (object.designMode == 'tablearea') {
    image = null;
    const tableColour = `rgb(247, 215, 121)`;
    vectorStyle = { fill: tableColour };

    objects = props.seatingPlan.Objects;

    var anotherAbove = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'tablearea' && o.row == myRow - 1 && o.column == myColumn;
      })
      .firstOrDefault();
    var anotherBelow = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'tablearea' && o.row == myRow + 1 && o.column == myColumn;
      })
      .firstOrDefault();
    var anotherLeft = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'tablearea' && o.row == myRow && o.column == myColumn - 1;
      })
      .firstOrDefault();
    var anotherRight = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'tablearea' && o.row == myRow && o.column == myColumn + 1;
      })
      .firstOrDefault();

    // Top Right of Table
    if (anotherAbove == null && anotherRight == null && anotherBelow && anotherLeft) {
      sectorColour = tableColour;
      vectorStyle = { transform: `translate(${NumberHelper.roundDecimal(object.column * props.width)}px, ${NumberHelper.roundDecimal(object.row * props.height + objectWidth)}px) rotate(0)` };
    }
    // Bottom Right of Table
    else if (anotherAbove && anotherRight == null && anotherBelow == null && anotherLeft) {
      sectorColour = tableColour;
      vectorStyle = { transform: `translate(${NumberHelper.roundDecimal(object.column * props.width)}px, ${NumberHelper.roundDecimal(object.row * props.height)}px) rotate(90deg)` };
    } // Top Left of Table
    else if (anotherAbove == null && anotherRight && anotherBelow && anotherLeft == null) {
      sectorColour = tableColour;
      vectorStyle = {
        transform: `translate(${NumberHelper.roundDecimal(object.column * props.width + objectWidth)}px, ${NumberHelper.roundDecimal(object.row * props.height + objectWidth)}px) rotate(270deg)`,
      };
    }
    // Bottom Left of Table
    else if (anotherAbove && anotherRight && anotherBelow == null && anotherLeft == null) {
      sectorColour = tableColour;
      vectorStyle = { transform: `translate(${NumberHelper.roundDecimal(object.column * props.width + objectWidth)}px, ${NumberHelper.roundDecimal(object.row * props.height)}px) rotate(180deg)` };
    }
  }

  if (object.designMode == 'walkway') {
    image = null;
    vectorStyle = { fill: '#EFEFEF', border: '4px solid lightgray' };

    objects = props.seatingPlan.Objects;

    var anotherAbove = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'walkway' && o.row == myRow - 1 && o.column == myColumn;
      })
      .firstOrDefault();
    var anotherBelow = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'walkway' && o.row == myRow + 1 && o.column == myColumn;
      })
      .firstOrDefault();
    var anotherLeft = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'walkway' && o.row == myRow && o.column == myColumn - 1;
      })
      .firstOrDefault();
    var anotherRight = linq
      .from(objects)
      .where((o: ISeatingPlanObject) => {
        return o.designMode == 'walkway' && o.row == myRow && o.column == myColumn + 1;
      })
      .firstOrDefault();

    if (anotherAbove != null) {
      vectorStyle.borderTop = 'none';
    }
    if (anotherBelow != null) {
      vectorStyle.borderBottom = 'none';
      // vectorStyle.height = '100%';
    }
    if (anotherLeft != null) {
      vectorStyle.borderLeft = 'none';
    }
    if (anotherRight != null) {
      vectorStyle.borderRight = 'none';
      // vectorStyle.width = '100%';
    }

    if (anotherAbove == null && anotherRight == null) curveTopRight = curveLittle;
    if (anotherBelow == null && anotherRight == null) curveBottomRight = curveLittle;
    if (anotherBelow == null && anotherLeft == null) curveBottomLeft = curveLittle;
  }

  if (object.designMode == 'up') {
    image = SVGHelper.get('Up');
  }
  if (object.designMode == 'down') {
    image = SVGHelper.get('Down');
  }
  if (object.designMode == 'left') {
    image = SVGHelper.get('Left');
  }
  if (object.designMode == 'right') {
    image = SVGHelper.get('Right');
  }
  if (object.designMode == 'toilet') {
    image = SVGHelper.get('Toilet');
  }
  if (object.designMode == 'door') {
    image = SVGHelper.get('Door');
  }
  if (object.designMode == 'stairshorizontal') {
    image = SVGHelper.get('StairsHorizontal');
  }
  if (object.designMode == 'guitar') {
    image = SVGHelper.get('Guitar');
  }
  if (object.designMode == 'drum') {
    image = SVGHelper.get('Drum');
  }
  if (object.designMode == 'stairs') {
    image = SVGHelper.get('Stairs');
  }
  if (object.designMode == 'table') {
    image = SVGHelper.get('Table');
  }
  if (object.designMode == 'elevator') {
    image = SVGHelper.get('Elevator');
  }
  if (object.designMode == 'conductor') {
    image = SVGHelper.get('Conductor');
  }
  if (object.designMode == 'piano') {
    image = SVGHelper.get('Piano');
  }
  if (object.designMode == 'dj') {
    image = SVGHelper.get('Dj');
  }
  if (object.designMode == 'speaker') {
    image = SVGHelper.get('Speaker');
  }
  if (object.designMode == 'pillar') {
    image = SVGHelper.get('Pillar');
  }
  if (object.designMode == 'disabled') {
    image = SVGHelper.get('Disabled');
  }
  if (object.designMode == 'maleToilet') {
    image = SVGHelper.get('MaleToilet');
  }
  if (object.designMode == 'femaleToilet') {
    image = SVGHelper.get('FemaleToilet');
  }

  return (
    <g
      id={`object_${object.column}_${object.row}`}
      data-row={object.row}
      data-column={object.column}
      onMouseDown={!DeviceHelper.mobileAndTabletCheck() ? () => props.clickStart(props.object) : null}
      onMouseUp={!DeviceHelper.mobileAndTabletCheck() ? () => props.clickEnd(props.object) : null}
      onTouchStart={DeviceHelper.mobileAndTabletCheck() ? () => props.clickStart(props.object) : null}
      onTouchEnd={DeviceHelper.mobileAndTabletCheck() ? () => props.clickEnd(props.object) : null}
      className={`seatingplan-object ${object.objectGroupId} ${object.designMode}`}
    >
      {sectorColour ? (
        <>
          <g className="vector" style={vectorStyle}>
            <path d={`M 0 0 L 0 -${objectWidth} A ${objectWidth} ${objectWidth} 0 0 1 ${objectWidth} 0 L 0 0`} fill={sectorColour} />
          </g>
        </>
      ) : image ? (
        <image
          onClick={props.handleClick}
          x={NumberHelper.roundDecimal(object.column * props.width)}
          y={NumberHelper.roundDecimal(object.row * props.height)}
          height={props.width}
          width={props.width}
          className="icon"
          href={image}
        />
      ) : (
        <rect
          rx={curveTopRight > 0 && curveBottomRight > 0 ? curveTopRight : 0}
          ry={curveBottomLeft > 0 && curveBottomLeft > 0 ? curveBottomLeft : 0}
          onClick={props.handleClick}
          className="vector"
          style={vectorStyle}
          x={NumberHelper.roundDecimal(object.column * props.width)}
          y={NumberHelper.roundDecimal(object.row * props.height)}
          height={props.width + props.width * 0.06}
          width={objectWidth}
        />
      )}
    </g>
  );
};

export default SeatingPlanObject;
