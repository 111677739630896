import { IEvent } from '../../interfaces/IEvent';
import EventDatePicker from './EventDatePicker';

export interface IProps {
  event: IEvent;
  currentEventDateId?: number;
  loadEventDate: (eventDateId: number) => void;
  loading: boolean;
  isAdmin: boolean;
  onCenterClick: () => void;
  buttonText: any;
  minPrice?: number;
  maxPrice?: number;
  onPriceClick?: () => void;
}

const EventHeader: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { event, currentEventDateId, loading } = props;

  if (!event) return null;

  return (
    <div className={`ordertickets-container center${event.Dates.length === 1 ? ' one-date' : ''} donotprint${currentEventDateId ? '' : ' below-banner'}`}>
      <div className="bubbles row flex" style={{ maxWidth: '800px' }}>
        <div className="left-side">
          <div className="info">
            {event && <div className="name">{event.Name}</div>}
            {/* 
            {event && event.Organisation && <div className="organisation">{event.Organisation.Name}</div>} */}

            {event && event.Venue && <div className="venue">{event.Venue.Name}</div>}
          </div>
        </div>
        <div className="right-side">
          {!loading && !event.UseSeatingPlan && !event.UseUnallocatedTickets ? (
            <div>This event has no tickets set up yet.</div>
          ) : (
            <EventDatePicker
              buttonText={props.buttonText}
              event={props.event}
              isAdmin={props.isAdmin}
              loadEventDate={props.loadEventDate}
              loading={props.loading}
              onCenterClick={props.onCenterClick}
              children={props.children}
              currentEventDateId={props.currentEventDateId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventHeader;
