import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminPage from '../../views/Admin/AdminPage';
import Loader from '../Loader';
import GeneralApi from '../../api/GeneralApi';
import UserHelper from '../../helpers/UserHelper';
import LocationHelper from '../../helpers/LocationHelper';
import Modal from '../Modal';
import ThemeHelper from '../../helpers/ThemeHelper';
import SpacerTable from '../SpacerTable';
import BlockInfo, { InfoType } from '../BlockInfo';
import EnvironmentVariables from '../../EnvironmentVariables';
import CacheHelper from '../../helpers/CacheHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IOrder } from '../../interfaces/IOrder';
import { PaymentMethods } from '../../enums/PaymentMethods';
import linq from 'linq';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { IVenue } from '../../interfaces/IVenue';
import { ITicketCategory } from '../../interfaces/ITicketCategory';
import { ITicket } from '../../interfaces/ITicket';
import { IQuestionAnswer } from '../../interfaces/IQuestion';
import BlockHeader from '../BlockHeader';
import Block from '../Block';
import OrderQuestions from '../OrderQuestions';
import NumberHelper from '../../helpers/NumberHelper';
import { AdmissionTypes } from '../../views/Editor/event/TicketSetupSection';
import moment from 'moment';
import DateHelper from '../../helpers/DateHelper';

export interface IProps {}

export interface IStripeSummaryProps {
  orderId: number;
  event: IEvent;
  organisation: IOrganisation;
  venue: IVenue;
  ticketCategories: ITicketCategory[];
  currency: string;
  tickets: ITicket[];
  attendeeName: string;
  attendeeEmail: string;
  paymentMethod: PaymentMethods;
  eventDateFormatted: string;
  orderAnswers: IQuestionAnswer[];
}

const StripeCompleteSummary: React.FC<React.PropsWithChildren<IStripeSummaryProps>> = (props) => {
  const event = props.event;
  const children: Array<React.ReactElement<any>> = typeof (props.children === 'object') ? (props.children as Array<React.ReactElement<any>>) : [];

  let paymentInfo = '';

  switch (props.paymentMethod) {
    case PaymentMethods.CardSeaty: {
      paymentInfo = 'Paid with card using Stripe';
      break;
    }
    case PaymentMethods.Cash: {
      paymentInfo = 'Paid with cash';
      break;
    }
    case PaymentMethods.Cheque: {
      paymentInfo = 'Paid with cheque';
      break;
    }
    case PaymentMethods.BankTransfer: {
      paymentInfo = 'Paid by bank transfer';
      break;
    }
    case PaymentMethods.CardOutSideSeaty: {
      paymentInfo = 'Paid with card outside of Seaty';
      break;
    }
    default: {
      paymentInfo = 'Not taken';
    }
  }

  return (
    <div>
      {children}

      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>{props.event.Name}</BlockHeader>
            {props.eventDateFormatted}
            <br />
            {props.venue.Name}, {props.venue.Postcode}
          </Block>
          {props.orderId == null || props.orderId == 0 ? null : (
            <Block>
              <BlockHeader>Order Number</BlockHeader>
              {props.orderId}
            </Block>
          )}
          {props.attendeeEmail && props.attendeeEmail.length > 0 && (
            <Block>
              <BlockHeader>Attendee</BlockHeader>
              {props.attendeeName}
              <br />
              {props.attendeeEmail}
            </Block>
          )}
          {/* 
          {generalTicketsComponents}
          {seatedTicketsComponents} */}

          {props.event.AdmissionType === AdmissionTypes.Exchange && (
            <BlockInfo>All Seaty tickets for this event must be taken to the venue box office and exchanged prior to the event starting.</BlockInfo>
          )}

          {props.paymentMethod != PaymentMethods.Request && (
            <Block>
              <BlockHeader>Payment</BlockHeader>
              {paymentInfo}
            </Block>
          )}

          {props.orderAnswers && props.orderAnswers.length > 0 && (
            <Block>
              <OrderQuestions event={props.event} answers={props.orderAnswers} readOnly={true} />
            </Block>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StripeCompleteSummary;
