import React from 'react';

export interface IProps {}

const Blocks: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  let children: Array<React.ReactElement<any>> = typeof (props.children === 'object') ? (props.children as Array<React.ReactElement<any>>) : [];

  return (
    <table className="blocks">
      <tbody>{children}</tbody>
    </table>
  );
};

export default Blocks;
