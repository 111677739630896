import { IEvent } from '../interfaces/IEvent';
import { ITheme } from '../interfaces/ITheme';

export default class ThemeHelper {
  public static getDefaultTheme(): ITheme {
    return {
      Id: 0,
      HeaderImageUrl: 'https://seatylive.blob.core.windows.net/images/DefaultThemeHeader.png',
      HeaderImageHeight: '0',
      HeaderImageWidth: '0',
      BackgroundImageUrl: null,
      MainColour: '1b4a77',
      HeaderImagePadding: '10px 20px',
      ShowRadial: true,
    };
  }

  public static getEventTheme(event: IEvent): ITheme {
    if (!event) return ThemeHelper.getDefaultTheme();
    if (event.Theme) return event.Theme;
    if (event.Tour && event.Tour.Theme) return event.Tour.Theme;
    if (event.Organisation && event.Organisation.Theme) return event.Organisation.Theme;

    return ThemeHelper.getDefaultTheme();
  }
}
