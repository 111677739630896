import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CacheHelper from '../helpers/CacheHelper';
import { IOrganisation } from '../interfaces/IOrganisation';
import { ITour } from '../interfaces/ITour';
import SVGEditEvent from '../svg/SVGEditEvent';
import SVGOrders from '../svg/SVGOrders';
import SVGSummary from '../svg/SVGSummary';
import SVGOrganisation from '../svg/SVGOrganisation';
import SVGTour from '../svg/SVGTour';
import organisationMenuState from '../atoms/organisationMenuState';
import { useRecoilState } from 'recoil';
import tourMenuState from '../atoms/tourMenuState';

export interface IAdminMenuTourProps {
  tour: ITour;
  onCloseClick: () => void;
}

const AdminMenuTour: FunctionComponent<IAdminMenuTourProps> = (props) => {
  const { onCloseClick } = props;

  const [tour, setTour] = useState(props.tour);
  const [organisation, setOrganisation] = useState<IOrganisation>();

  useEffect(() => {
    if (!tour) return;
    CacheHelper.tourById(props.tour.Id).then((t) => {
      setTour(t);
      return CacheHelper.organisationById(t.OrganisationId).then((o) => {
        setOrganisation(o);
        t.Organisation = o;
        setTour({ ...t });
      });
    });
  }, [props.tour.Id]);

  if (!tour || !tour.Id) {
    return null;
  }

  return (
    <div className="admin-menu">
      <h1>
        Tour <strong>#{tour.TourTag.toUpperCase()}</strong>
      </h1>
      <div className="buttons">
        <div className="admin-menu-background"></div>
        {organisation && (
          <>
            <Link onClick={onCloseClick} className="edit" to={'/Tour/' + tour.TourTag}>
              <SVGTour />
              <label>Landing</label>
            </Link>
            <Link onClick={onCloseClick} className="edit" to={'/Organisation/' + organisation.OrganisationTag + '/Tour/' + tour.TourTag + '/Summary'}>
              <SVGSummary />
              <label>Summary</label>
            </Link>
            <Link onClick={onCloseClick} className="edit" to={'/Organisation/' + organisation.OrganisationTag + '/Tour/' + tour.TourTag + '/Events'}>
              <SVGOrders />
              <label>Tour events</label>
            </Link>
            <Link onClick={onCloseClick} className="edit" to={`/Organisation/${organisation.OrganisationTag}/Tour/` + tour.TourTag + '/Edit'}>
              <SVGEditEvent />
              <label>Edit</label>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminMenuTour;
