import React, { useState } from 'react';

import GeneralApi from '../../api/GeneralApi';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import Button from '../../components/Button';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import Loader from '../../components/Loader';
import SpacerTable from '../../components/SpacerTable';
import BlockTextBox from '../../components/BlockTextBox';
import { IEvent } from '../../interfaces/IEvent';

interface IProps {
  event: IEvent;
  onPasswordConfirmed: () => void;
}

const PrivateEvent: React.FC<IProps> = ({ event, onPasswordConfirmed }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const submitPassword = () => {
    setSubmitting(true);
    setError(null);
    GeneralApi.request('GET', `/api/EventPassword?eventId=${event.Id}&password=${password}`)
      .then((result) => {
        if (result == false) {
          setError('Password was not correct.');
        } else {
          onPasswordConfirmed();
        }

        setSubmitting(false);
      })
      .catch((ex) => {
        setError(ex);
        setSubmitting(false);
      });
  };

  return (
    <div className="building" style={{ padding: '80px 0', paddingBottom: '200px' }}>
      <div className="content" style={{ marginBottom: '30px' }}>
        <div className="ticket-rip" />
        <div className="body">
          <SpacerTable>
            <h1>{event.Name}</h1>
            <div>{event.Organisation.Name}</div>
          </SpacerTable>

          <div className="spacer" />

          <table className="blocks">
            <tbody>
              <BlockInfo>This event is private{event.PrivatePasswordRequired ? ' and can only be accessed with a password' : ''}.</BlockInfo>
              {event.PrivatePasswordRequired && (
                <Block>
                  <BlockHeader>Password</BlockHeader>
                  <BlockTextBox
                    id="password_field"
                    onKeyPress={(e) => {
                      if (e.charCode == 13) submitPassword();
                    }}
                    autoFocus={true}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e)}
                  />
                </Block>
              )}
              {error && <BlockInfo type={InfoType.Error}>{error}</BlockInfo>}
            </tbody>
          </table>

          {submitting && <Loader inline>Please wait...</Loader>}

          <div className="spacer" />
          {event.PrivatePasswordRequired && (
            <SpacerTable>
              <Button
                disabled={submitting || password.length == 0}
                text={'Confirm'}
                onExecute={() => {
                  submitPassword();
                }}
              />
            </SpacerTable>
          )}
        </div>
        <div className="ticket-rip bottom" />
      </div>
    </div>
  );
};

export default PrivateEvent;
