import linq from 'linq';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilState } from 'recoil';
import AdminApi from '../../../../api/AdminApi';
import orderState from '../../../../atoms/orderState';
import BlockDatePicker from '../../../../components/BlockDatePicker';
import InfoLabel from '../../../../components/InfoLabel';
import Loader from '../../../../components/Loader';
import NumberHelper from '../../../../helpers/NumberHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import SVGLoading from '../../../../svg/SVGLoading';
import SVGMultiply from '../../../../svg/SVGMultiply';
import SVGOrders from '../../../../svg/SVGOrders';
import SVGRefresh from '../../../../svg/SVGRefresh';
import SVGTick from '../../../../svg/SVGTick';
import './AdminRoster.scss';

export interface IProps {
  event: IEvent;
}

interface IRosterTicket {
  GroupName: string;
  SeatName: string;
  DateMarked: string;
  SeatCategoryId: number;
  TicketCategoryId: number;
  TicketCategoryName: string;
  TicketCategoryColor: string;
}

interface IRosterOrder {
  AdminNotes: JSX.Element;
  UserNotes: JSX.Element;
  OrderId: number;
  AttendeeEmail: string;
  AttendeeName: string;
  EventDate: string;
  Tickets: IRosterTicket[];
  Notes: string;
}

const RosterTicketRow: FunctionComponent<{ t: IRosterTicket }> = (props) => {
  const [marked, setMarked] = useState(props.t.DateMarked != null);
  const [loading, setLoading] = useState(false);
  const t = props.t;

  useEffect(() => {
    const v = props.t.DateMarked != null;

    if (marked != v) {
      setMarked(v);
    }
  }, [props.t.DateMarked]);

  return (
    <tr style={{ color: t.TicketCategoryColor }}>
      <td>
        <button
          disabled={loading}
          className={`table-action-button ${!loading && marked ? 'good' : ''} ${loading ? 'loading' : ''}`}
          onClick={() => {
            setLoading(true);
            AdminApi.request('POST', `/api/Roster`, { ...t, DateMarked: marked ? 'MARKED' : null })
              .then((result) => {
                if (result.DateMarked) {
                  setMarked(true);
                } else {
                  setMarked(false);
                }
                setLoading(false);
              })
              .catch((error) => console.log('Error: ' + error));
          }}
        >
          {!loading && marked && <SVGTick />}
          {loading && <SVGLoading />}
        </button>
      </td>
      <td style={{ fontWeight: 600 }} colSpan={3}>
        {t.SeatCategoryId != null ? t.GroupName + t.SeatName + ' - ' : ''}
        {t.TicketCategoryName}
      </td>
    </tr>
  );
};

const AdminRoster: FunctionComponent<IProps> = (props) => {
  const event = props.event;
  const eventTag = event.EventTag;

  const [filter, setFilter] = useState('');

  const [rosters, setRosters] = useState<IRosterOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [day, setDay] = useState<moment.Moment>(moment());
  const [, setOrder] = useRecoilState(orderState);

  const getRosters = (_event, _day) => {
    setLoading(true);
    AdminApi.request('GET', `/api/Roster?eventId=${_event.Id}&date=${_day.format('YYYY-MM-DD')}`)
      .then((result) => {
        setRosters(result);
      })
      .catch((error) => alert('Error: ' + error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!event) return;

    getRosters(event, day);
  }, [event, day]);

  if (!event) return null;

  const onFilterKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      setFilter(query);
    }
  };

  const filterClicked = () => {
    setFilter(query);
  };

  const clearFilterClicked = () => {
    setFilter('');
    setQuery('');
  };

  const handleSearchChanged = (e) => {
    setQuery(e.target.value);
  };

  const filteredRosters = linq
    .from(rosters)
    .where((r) => {
      if (filter == null || filter.length == 0) return true;

      if (NumberHelper.isNumeric(filter)) {
        return r.OrderId == parseInt(filter);
      }

      return r.AttendeeEmail.toLowerCase().indexOf(filter.toLowerCase()) !== -1 || r.AttendeeName.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
    })
    .orderBy((r) => moment.utc(r.EventDate).unix())
    .thenBy((r) => r.Tickets[0].TicketCategoryName)
    .thenBy((r) => r.AttendeeName);

  return (
    <>
      <Helmet>
        <title>#{eventTag} Roster</title>
        <meta name="description" content={`Manage roster for your events.`} />
      </Helmet>

      <div className="roster-page">
        <div className="toolbar">
          <div className="title">Roster</div>
          <div className="buttons">
            <button onClick={() => getRosters(event, day)}>
              <SVGRefresh />
              Refresh
            </button>
          </div>
        </div>

        <div className="orderoverview" style={{ paddingTop: '30px', paddingBottom: '100px' }}>
          <div className="donotprint" style={{ margin: '0' }}>
            <InfoLabel text="What day would you like to view?" tooltip="Choose a day that you would like to see all orders grouped by their event date in a list for." />
            <div className="row">
              <div className="col-sm-6">
                <div className="field">
                  <BlockDatePicker
                    readOnly={true}
                    disabled={loading}
                    initialValue={moment()}
                    groupName={`DateTime`}
                    onChange={(_date) => {
                      setDay(_date.moment);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="donotprint" style={{ margin: '0' }}>
                  {rosters.length > 0 && (
                    <div className="input">
                      <input id="searchinput" onChange={handleSearchChanged} type="text" placeholder="Search..." value={query} onKeyPress={onFilterKeyPress.bind(this)} style={{ margin: '0' }} />

                      <button onClick={clearFilterClicked}>
                        <SVGMultiply />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {rosters.length == 0 && (
            <div style={{ marginTop: '20px' }} className="info">
              <div>
                <strong>No Tickets Found on Day</strong>
              </div>
              We could not find any tickets on any event dates in your chosen day.
              <br />
              <br />
              The roster system is designed to group together all your event dates on a single day in order of event date, attendee name, and then ticket. You can then use the roster, much like a
              school register, to mark off attendance at an event. It is an alternative method to ticket scanning when you want to mark off used tickets. If you have a single day with lots of event
              date times, like a Santa's grotto, the rostering system can be a really useful way to mark people off as they enter. If more than one person is updating the roster at a time, make sure
              you refresh often.
            </div>
          )}

          {loading && <Loader inline>Loading rosters...</Loader>}
          {filteredRosters
            .groupBy((r) => r.EventDate)
            .select((g) => (
              <div className="pagebreak" style={{ padding: '0 16px' }} key={g.key()}>
                <div className="container-outer" style={{ borderRadius: '6px' }}>
                  <div className="container-inner" style={{ backgroundColor: 'white' }}>
                    <table style={{ margin: 0 }} className="table grid-table">
                      <thead>
                        <tr>
                          <th className="grid-header" style={{ width: '70px' }}></th>
                          <th className="grid-header" colSpan={2}>
                            <div>
                              {moment.utc(g.first().EventDate).format('h:mma')} - {moment.utc(g.first().EventDate).format('dddd Do MMMM YYYY')}
                            </div>
                            <div>{g.count()} Orders</div>
                          </th>
                          <th className="donotprint" style={{ textAlign: 'right' }}>
                            <button onClick={() => getRosters(event, day)} className="table-action-button">
                              <SVGRefresh />
                            </button>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="smooth-details" style={{ border: 'none' }}>
                        {g
                          .orderBy((r) => r.AttendeeName)
                          .select((r) => (
                            <>
                              <tr key={r.OrderId} className="field" style={{ display: 'table-row' }}>
                                <td></td>
                                <td className="grid-cell">
                                  {r.AttendeeName} <strong>#{r.OrderId}</strong>
                                </td>
                                <td>
                                  {r.AdminNotes && (
                                    <div>
                                      <h3>{r.UserNotes && r.UserNotes != r.AdminNotes ? 'Admin ' : ''}Notes</h3>
                                      <div>{r.AdminNotes}</div>
                                    </div>
                                  )}
                                  {r.UserNotes && r.UserNotes != r.AdminNotes && (
                                    <div>
                                      <h3>Attendee Notes:</h3>
                                      <div className="notes">{r.UserNotes}</div>
                                    </div>
                                  )}
                                </td>
                                <td className="donotprint" style={{ textAlign: 'right' }}>
                                  <button
                                    onClick={() => {
                                      setOrder({ orderId: r.OrderId, event: event });
                                    }}
                                    className="table-action-button"
                                  >
                                    <SVGOrders />
                                  </button>
                                </td>
                              </tr>

                              {linq
                                .from(r.Tickets)
                                .orderBy((t: IRosterTicket) => {
                                  if (t.SeatCategoryId != null) {
                                    return t.GroupName;
                                  } else {
                                    return t.TicketCategoryName;
                                  }
                                })
                                .thenBy((t: IRosterTicket) => {
                                  if (t.SeatCategoryId != null) {
                                    if (NumberHelper.isNumeric(t.SeatName)) {
                                      return parseInt(t.SeatName);
                                    } else {
                                      return t.SeatName;
                                    }
                                  } else {
                                    return t.TicketCategoryName;
                                  }
                                })
                                .select((t) => <RosterTicketRow t={t} />)
                                .toArray()}
                            </>
                          ))
                          .toArray()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))
            .toArray()}
        </div>
      </div>
    </>
  );
};

export default AdminRoster;
