import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 11.5 6 C 8.4802259 6 6 8.4802259 6 11.5 L 6 36.5 C 6 39.519774 8.4802259 42 11.5 42 L 36.5 42 C 39.519774 42 42 39.519774 42 36.5 L 42 11.5 C 42 8.4802259 39.519774 6 36.5 6 L 11.5 6 z M 11.5 9 L 36.5 9 C 37.898226 9 39 10.101774 39 11.5 L 39 36.5 C 39 37.898226 37.898226 39 36.5 39 L 11.5 39 C 10.101774 39 9 37.898226 9 36.5 L 9 11.5 C 9 10.101774 10.101774 9 11.5 9 z M 24.5 13 C 18.595 13 16.5 16.502 16.5 19.5 C 16.5 24.022 20.764797 25.442594 22.966797 26.058594 C 25.413797 26.779594 25.5 27.824484 25.5 28.271484 C 25.5 28.854484 25.259172 29.241688 25.076172 29.429688 C 24.552172 29.971688 23.624734 30.075562 22.677734 29.976562 C 20.612734 29.759563 18.51075 28.965844 17.34375 28.464844 C 17.17975 28.393844 17 28.516312 17 28.695312 L 17 33.357422 C 17 33.570422 17.129078 33.760078 17.330078 33.830078 C 19.065078 34.430078 21.291 35 23.5 35 C 29.405 35 31.5 31.364 31.5 28.25 C 31.5 24.985 29.309281 22.692641 24.988281 21.431641 C 22.511281 20.678641 22.5 19.822453 22.5 19.439453 C 22.5 18.973453 22.727672 18.654094 22.888672 18.496094 C 23.384672 18.007094 24.399984 17.918109 25.333984 18.037109 C 27.397984 18.220109 29.491297 19.017391 30.654297 19.525391 C 30.818297 19.597391 31 19.474922 31 19.294922 L 31 14.642578 C 31 14.429578 30.870922 14.239922 30.669922 14.169922 C 28.934922 13.570922 26.709 13 24.5 13 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
