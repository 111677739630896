import { atom } from 'recoil';

const wizardOrganisationState = atom<{
  onClose?: () => void;
  createNew?: boolean;
}>({
  key: 'wizardOrganisation',
  default: null,
});

export default wizardOrganisationState;
