import { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../../../../api/AdminApi';
import Block from '../../../../components/Block';
import BlockHeader from '../../../../components/BlockHeader';
import BlockTextBox from '../../../../components/BlockTextBox';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import OrderDashboard from '../../../../components/order_dashboard/OrderDashboard';
import SpacerTable from '../../../../components/SpacerTable';
import DateHelper from '../../../../helpers/DateHelper';
import RequestHelper, { RequestState } from '../../../../helpers/RequestHelper';
import ThemeHelper from '../../../../helpers/ThemeHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import { IRequest } from '../../../../interfaces/IRequest';
import BlockInfo from '../../../../components/BlockInfo';

interface IProps {
  event: IEvent;
  request: IRequest;
  onClose: () => void;
  readOnly?: boolean;
}

const RequestModal: FunctionComponent<IProps> = (props) => {
  const { event, onClose } = props;

  const [busyMessage, setBusyMessage] = useState('Loading request...');
  const [errorMessage, setErrorMessage] = useState(null);
  const [request, setRequest] = useState<IRequest>(props.request);
  const [state, setState] = useState(RequestHelper.getState(request));
  const [refuseReason, setRefuseReason] = useState('');
  const [showOrder, setShowOrder] = useState(false);

  useEffect(() => {
    AdminApi.request('GET', `/api/Request?eventId=${event.Id}&requestId=${request.Id}`)
      .then((result: IRequest) => {
        setRequest({ ...props.request, ...result });
        setBusyMessage(null);
      })
      .catch((message) => {
        setErrorMessage(message);
        setBusyMessage(null);
      });
  }, []);

  const actionRequest = (refuse?: boolean) => {
    setBusyMessage('Actioning request...');

    setState(refuse ? RequestState.Refusing : RequestState.Accepting);

    AdminApi.request('POST', '/api/Request', { Id: request.Id, RefusedReason: refuseReason, EventId: event.Id, Refused: refuse ? true : false })
      .then((result) => {
        request.OrderId = result.Id;
        setState(refuse ? RequestState.Refused : RequestState.Accepted);

        AdminApi.request('POST', '/api/EventDateHandshake', { eventDateId: request.EventDateId }).then((r) => {
          console.log(`Sold Out: ${r.SoldOut}`);
        });
        setBusyMessage(null);
      })
      .catch((message) => {
        setErrorMessage(message);
        setBusyMessage(null);
      });
  };

  return (
    <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>{event.Name}</h1>
            <div>{event.Organisation.Name}</div>
            <div>
              {event.Venue.Name}, {event.Venue.Postcode}
            </div>
          </SpacerTable>

          <div className="spacer" />

          {busyMessage ? (
            <Loader inline>{busyMessage}</Loader>
          ) : showOrder ? (
            <OrderDashboard event={event} orderId={request.OrderId} />
          ) : (
            <>
              <table className="blocks">
                <tbody>
                  <Block>
                    <BlockHeader>Request Number</BlockHeader>#{request.Id}
                  </Block>
                  <Block>
                    <BlockHeader>Requested Date</BlockHeader>
                    <div>{DateHelper.asDateAtTimeAmPm(request.DateRequested)}</div>
                  </Block>
                  <Block>
                    <BlockHeader>Requested by</BlockHeader>
                    <div>
                      {request.RequestedByName != null && request.RequestedByName.length > 1 ? <span>{request.RequestedByName} </span> : null}
                      <div>{request.RequestedByEmail}</div>
                    </div>
                  </Block>
                  <Block>
                    <BlockHeader>Requested</BlockHeader>
                    <div>
                      {request.TotalCount} tickets for {DateHelper.asDateAtTimeAmPm(event.Dates.filter((d) => d.Id === request.EventDateId)[0].Date)}
                    </div>
                  </Block>

                  {request.OrderId && (
                    <Block className="route" onClick={() => setShowOrder(true)}>
                      <BlockHeader>Order Number</BlockHeader>#{request.OrderId}
                    </Block>
                  )}

                  {state == RequestState.Refused && (
                    <>
                      {request.RefusedByEmail && (
                        <Block>
                          <BlockHeader>Refused by</BlockHeader>
                          <div>{request.RefusedByName}</div>
                          <div>{request.RefusedByEmail}</div>
                        </Block>
                      )}
                      <Block>
                        <BlockHeader>Refused Reason</BlockHeader>
                        <div>{request.RefusedReason}</div>
                      </Block>
                      <Block>
                        <BlockHeader>Refused Date</BlockHeader>
                        <div>{DateHelper.asDateAtTimeAmPm(request.RefusedReason)}</div>
                      </Block>
                    </>
                  )}

                  {state == RequestState.Refusing && (
                    <Block>
                      <BlockHeader>Why are you refusing this request?</BlockHeader>
                      <BlockTextBox
                        placeholder={'Refuse reason...'}
                        autoFocus={true}
                        value={refuseReason}
                        onChange={(e) => {
                          setRefuseReason(e);
                        }}
                      />
                    </Block>
                  )}

                  {request.Discounts &&
                    request.Discounts.length > 0 &&
                    request.Discounts.map((discount) => (
                      <Block>
                        <div className="discount">
                          <BlockHeader>{discount.Name}</BlockHeader>
                          Discount will be applied when requerst is accepted.
                        </div>
                      </Block>
                    ))}

                  {request.QuestionAnswers &&
                    request.QuestionAnswers.map((qa) => (
                      <Block>
                        <div className="question">
                          <BlockHeader>{qa.QuestionName}</BlockHeader>
                          {qa.Text}
                        </div>
                      </Block>
                    ))}

                  {request.Notes && request.Notes.length > 0 && (
                    <Block>
                      <BlockHeader>Notes</BlockHeader>
                      {request.Notes}
                    </Block>
                  )}

                  <BlockInfo>
                    Accepting a request will issue the requested tickets to the attendee via email, please ensure you know who has requested them as the request system is not a form of payment. A
                    balance for payment will be owed by that attendee email and can be viewed from the event balances screen. You can mark a payment made outside of Seaty as an admin from there, or an
                    attendee can pay a balance by card from their "My Tickets" page.
                  </BlockInfo>
                </tbody>
              </table>

              {!request.OrderId && (
                <>
                  <div className="spacer-x2" />

                  <table className="blocks tickets">
                    <tbody>
                      {request.Seats.map((ticket) => (
                        <Block>
                          <BlockHeader>
                            <td className="ticket-font" style={{ color: ticket.SeatCategoryColour }}>
                              {ticket.Group + ticket.Name} {ticket.SeatCategoryName}
                            </td>
                            <td className="right">
                              <span style={{ color: ticket.CategoryColour }}>
                                {ticket.CategoryName} {ticket.PriceAsString}
                              </span>
                            </td>
                          </BlockHeader>

                          {ticket.QuestionAnswers &&
                            ticket.QuestionAnswers.map((qa) => (
                              <div className="question" style={{ marginTop: '6px' }}>
                                <BlockHeader>{qa.QuestionName}</BlockHeader>
                                {qa.Text}
                              </div>
                            ))}
                        </Block>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}

          {!busyMessage && !props.readOnly && (
            <>
              <div className="spacer" />

              {showOrder ? (
                <SpacerTable>
                  <Button className="" onExecute={() => setShowOrder(false)} text={'Back to request'} />
                </SpacerTable>
              ) : state == RequestState.Accepted || state == RequestState.Refused ? (
                <div></div>
              ) : state == RequestState.Refusing ? (
                <SpacerTable>
                  <Button className={`bad${refuseReason.length == 0 ? ' disabled' : ''}`} onExecute={refuseReason.length == 0 ? null : () => actionRequest(true)} text={'Refuse request'} />
                  <Button className="" onExecute={() => setState(RequestState.Awaiting)} text={'Cancel'} />
                </SpacerTable>
              ) : (
                <SpacerTable>
                  <Button className="confirm" onExecute={() => actionRequest(false)} text={'Accept request'} />
                  <Button className="bad" onExecute={() => setState(RequestState.Refusing)} text={'Refuse request'} />
                </SpacerTable>
              )}
            </>
          )}
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
};

export default RequestModal;
