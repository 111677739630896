import { IVenue } from '../interfaces/IVenue';

export default class VenueHelper {

  public static getDefault(): IVenue {
    return {
      Id: 0,
      City: '',
      Description: '',
      Directions: '',
      Name: '',
      Postcode: '',
      StreetAddress: '',
    };
  }
}
