import React from 'react';

export interface IProps {
  rightText?: string;
}

const BlockHeader: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  return (
    <table className="block-header">
      <tbody>
        <tr>
          {props.children && (props.children as any).length > 0 && props.children[0].type && props.children[0].type === 'td' ? props.children : <td>{props.children}</td>}
          {props.rightText && <td className="right">{props.rightText}</td>}
        </tr>
      </tbody>
    </table>
  );
};

export default BlockHeader;
