import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 37.425781 6.0644531 C 37.102031 6.0469531 36.775937 6.0959375 36.460938 6.2109375 L 35.990234 6.3691406 L 5.3398438 17.189453 C 4.8798438 17.349453 4.5004688 17.64 4.2304688 18 L 12.080078 18 L 34.160156 10.199219 C 34.600156 11.429219 35.750234 12.199219 36.990234 12.199219 C 37.320234 12.199219 37.660234 12.149297 37.990234 12.029297 L 40.099609 18 L 43.279297 18 L 39.820312 8.1992188 L 39.650391 7.7304688 C 39.430391 7.1004688 38.979141 6.6005469 38.369141 6.3105469 C 38.069141 6.1655469 37.749531 6.0819531 37.425781 6.0644531 z M 34.421875 16.107422 C 34.161563 16.091641 33.893359 16.128203 33.630859 16.220703 C 32.850859 16.500703 32.350547 17.22 32.310547 18 L 36.289062 18 C 36.289062 17.81 36.259453 17.629453 36.189453 17.439453 C 35.911953 16.659453 35.202813 16.154766 34.421875 16.107422 z M 24.099609 16.150391 C 23.589609 16.150391 23.070313 16.240156 22.570312 16.410156 C 21.690313 16.720156 20.949922 17.28 20.419922 18 L 27.980469 18 C 26.980469 16.83 25.549609 16.150391 24.099609 16.150391 z M 7.5 21 C 6.1364058 21 5 22.136406 5 23.5 L 5 39.5 C 5 40.863594 6.1364058 42 7.5 42 L 40.5 42 C 41.863594 42 43 40.863594 43 39.5 L 43 23.5 C 43 22.136406 41.863594 21 40.5 21 L 7.5 21 z M 11 24 L 37 24 C 37 25.657 38.343 27 40 27 L 40 36 C 38.343 36 37 37.343 37 39 L 11 39 C 11 37.343 9.657 36 8 36 L 8 27 C 9.657 27 11 25.657 11 24 z M 24 26 C 21.239 26 19 28.462 19 31.5 C 19 34.538 21.239 37 24 37 C 26.761 37 29 34.538 29 31.5 C 29 28.462 26.761 26 24 26 z M 24 29 C 25.084 29 26 30.145 26 31.5 C 26 32.855 25.084 34 24 34 C 22.916 34 22 32.855 22 31.5 C 22 30.145 22.916 29 24 29 z M 13.5 29.5 A 2 2 0 0 0 13.5 33.5 A 2 2 0 0 0 13.5 29.5 z M 34.5 29.5 A 2 2 0 0 0 34.5 33.5 A 2 2 0 0 0 34.5 29.5 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
