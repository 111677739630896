import moment from 'moment';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import NumberHelper from '../helpers/NumberHelper';
import './BlockDatePicker.scss';

export interface ITimePickerValue {
  moment: moment.Moment;
  isTime: boolean;
  string: string;
  allHaveValue: boolean;
}

export interface IProps {
  onChange?(value: ITimePickerValue): void;
  autoFocus?: boolean;
  onFocus?(): void;
  onLostFocus?(): void;
  groupName?: string;
  initialValue?: moment.Moment;
  disabled?: boolean;
  onEnterPressed?: () => void;
}

const BlockTimePicker: FunctionComponent<IProps> = (props) => {
  const { initialValue, disabled } = props;

  const input = useRef();
  const groupName = props.groupName ? props.groupName : '';

  const [hour, setHour] = useState(initialValue && initialValue ? initialValue.format('HH') : '');
  const [minute, setMinute] = useState(initialValue && initialValue ? initialValue.format('mm') : '');
  const [initialised, setInitialised] = useState(false);

  const hourRef = useRef();

  const [error, setError] = useState(false);

  useEffect(() => {
    if (props.autoFocus) {
      window.setTimeout(() => {
        if (!input.current) return;
        (input.current as HTMLInputElement).focus();
      }, 100);
    }
  }, [input.current]);

  useEffect(() => {
    const time = `${hour}:${minute}`;

    const timeMoment = moment(time, 'HH:mm');
    const isValid = timeMoment.isValid() && minute.length === 2;
    const allHaveValue = hour.length > 0 && minute.length > 0;

    const response: ITimePickerValue = {
      allHaveValue: allHaveValue,
      isTime: isValid,
      moment: isValid ? timeMoment : null,
      string: time,
    };

    allHaveValue && !isValid ? setError(true) : setError(false);

    if (initialised) {
      props.onChange(response);
    }

    setInitialised(true);
  }, [hour, minute]);

  const inputValueChange = (e, setter) => {
    const target = e.target;
    const value = target.value;

    if (NumberHelper.isNumeric(value) || value.length === 0) {
      setter(value);

      if (value.length > 1) {
        const next = document.getElementById(target.id == 'hourInput' + groupName ? 'minuteInput' + groupName : '');
        if (next) (next as any).select();
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Submit') {
      if (props.onEnterPressed) props.onEnterPressed();
    }
  };

  return (
    <div className={`text-box-container time-picker${disabled ? ' disabled' : ''}`}>
      <div className="">:</div>
      <input
        onKeyPress={handleKeyPress}
        autoComplete={'off'}
        id={'hourInput' + groupName}
        pattern="[0-9]*"
        value={hour}
        maxLength={2}
        placeholder="HH"
        onFocus={props.onFocus}
        onBlur={props.onLostFocus}
        ref={input}
        className={`textbox${error ? ' bad' : ''}`}
        type={'text'}
        onChange={!disabled && ((e) => inputValueChange(e, setHour))}
      />
      <input
        onKeyPress={handleKeyPress}
        autoComplete={'off'}
        id={'minuteInput' + groupName}
        pattern="[0-9]*"
        value={minute}
        maxLength={2}
        placeholder="MM"
        onFocus={props.onFocus}
        onBlur={props.onLostFocus}
        ref={input}
        className={`textbox${error ? ' bad' : ''}`}
        type={'text'}
        onChange={!disabled && ((e) => inputValueChange(e, setMinute))}
      />
    </div>
  );
};

export default BlockTimePicker;
