import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 9.5 9 C 5.9290921 9 3 11.929092 3 15.5 L 3 32.5 C 3 36.070908 5.9290921 39 9.5 39 L 38.5 39 C 42.070908 39 45 36.070908 45 32.5 L 45 15.5 C 45 11.929092 42.070908 9 38.5 9 L 9.5 9 z M 9.5 12 L 38.5 12 C 40.445092 12 42 13.554908 42 15.5 L 42 32.5 C 42 34.445092 40.445092 36 38.5 36 L 9.5 36 C 7.5549079 36 6 34.445092 6 32.5 L 6 15.5 C 6 13.554908 7.5549079 12 9.5 12 z M 11 18 C 9.895 18 9 18.895 9 20 L 9 22 C 9 23.105 9.895 24 11 24 L 15 24 C 16.105 24 17 23.105 17 22 L 17 20 C 17 18.895 16.105 18 15 18 L 11 18 z M 10.5 28 A 1.50015 1.50015 0 1 0 10.5 31 L 13.5 31 A 1.50015 1.50015 0 1 0 13.5 28 L 10.5 28 z M 18.5 28 A 1.50015 1.50015 0 1 0 18.5 31 L 21.5 31 A 1.50015 1.50015 0 1 0 21.5 28 L 18.5 28 z M 26.5 28 A 1.50015 1.50015 0 1 0 26.5 31 L 29.5 31 A 1.50015 1.50015 0 1 0 29.5 28 L 26.5 28 z M 34.5 28 A 1.50015 1.50015 0 1 0 34.5 31 L 37.5 31 A 1.50015 1.50015 0 1 0 37.5 28 L 34.5 28 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
