import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="26px" height="26px">
      <path d="M 18 3.273438 C 18 2.820313 17.699219 2.210938 17.25 2.058594 C 16.800781 1.90625 16.351563 2.058594 15.898438 2.363281 C 11.101563 5.707031 6.300781 12.089844 6.300781 12.089844 C 6.300781 12.089844 6 12.390625 6 12.996094 C 6 13.609375 6.300781 13.910156 6.300781 13.910156 C 6.300781 13.910156 11.101563 20.292969 15.898438 23.636719 C 16.351563 23.941406 16.800781 24.09375 17.25 23.941406 C 17.699219 23.789063 18 23.179688 18 22.726563 Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
