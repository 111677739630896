import linq from 'linq';
import moment from 'moment';
import React from 'react';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import Button from '../../../components/Button';
import SpacerTable from '../../../components/SpacerTable';
import DateHelper from '../../../helpers/DateHelper';
import IUserOrderOverview from '../../../interfaces/IUserOrderOverview';
import { Section } from '../../../modals/UserEventOverview';

export interface IProps {
  overview: IUserOrderOverview;
  setSection: (section: Section) => void;
  refunds: boolean;
}

const PaymentsSection: React.FC<IProps> = (props) => {
  const { setSection, overview } = props;
  const payments = linq
    .from(overview.Payments)
    .where((p) => (props.refunds && p.IsRefund) || (!props.refunds && !p.IsRefund))
    .toArray();

  return (
    <>
      <SpacerTable>
        <h1>{props.refunds ? 'Refunds' : 'Payments'}</h1>
        {payments.length == 0 ? <span>None found</span> : <span>{payments.length + ' totalling ' + overview.TotalPaidAsString}</span>}
      </SpacerTable>
      <div className="spacer" />

      <table className="blocks">
        <tbody>
          {linq
            .from(payments)
            .orderByDescending((p: any) => moment(p.DatePaymentMade).utc())
            .select((p: any) => {
              var paymentType = p.IsRefund ? 'Refund' : 'Payment';
              var paymentDetails;
              if (p.TypeId == 1) {
                paymentDetails = <span>Cash</span>;
              } else if (p.TypeId == 2) {
                paymentDetails = <span>Cheque</span>;
              } else if (p.TypeId == 3) {
                paymentDetails = <span>Card outside of Seaty</span>;
              } else if (p.TypeId == 4) {
                paymentDetails = <span>Bank transfer</span>;
              } else if (p.TypeId == 5) {
                paymentDetails = <span>Card online</span>;
              }

              return (
                <Block key={p.Id + '_' + moment(p.DatePaymentMade).utc()}>
                  <BlockHeader>
                    <td>
                      {paymentType} #{p.Id}
                    </td>
                    <td className="right">
                      {p.IsRefund ? '-' : ''}
                      {p.AmountPaidAsString}
                    </td>
                  </BlockHeader>

                  <div>{DateHelper.asDateAtTimeAmPm(p.DatePaymentMade)}</div>
                  <div>
                    {paymentDetails} processed by {p.ProcessedBy}
                  </div>
                  <div className="tags">{p.OrderId != null ? <span className="tag ice">Order #{p.OrderId}</span> : null}</div>
                </Block>
              );
            })
            .toArray()}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <Button onExecute={() => setSection(Section.Dashboard)} text={'Back to overview'} />
      </SpacerTable>
    </>
  );
};

export default PaymentsSection;
