import linq from 'linq';
import SeatingPlanLabel from '../components/seating_plan_svg/SeatingPlanLabel';
import { IEvent } from '../interfaces/IEvent';
import { ISeatCategory } from '../interfaces/ISeatCategory';
import { ISeatingPlan } from '../interfaces/ISeatingPlan';
import { ISeatingPlanObject } from '../interfaces/ISeatingPlanObject';
import { ISeatingPlanSeat } from '../interfaces/ISeatingPlanSeat';
import GuidHelper from './GuidHelper';
import NumberHelper from './NumberHelper';

export interface IStageObjectGroup {
  id: number;
  text?: string;
  objects: Array<ISeatingPlanObject>;
}

export default class SeatingPlanHelper {
  public static elementWidth: number = 32;

  public static assignGroups(seatingPlan: ISeatingPlan): ISeatingPlan {
    if (!seatingPlan.Objects) return seatingPlan;

    seatingPlan.Objects.forEach((o) => (o.objectGroupId = null));

    // Utility to get surrounding objects of the same type
    const getSurroundingObjects = (objects, target) => {
      const directions = [
        { row: -1, col: 0 },
        { row: 1, col: 0 },
        { row: 0, col: -1 },
        { row: 0, col: 1 },
      ];

      return directions
        .map((dir) =>
          linq
            .from(objects)
            .firstOrDefault(
              (o) =>
                o.designMode === target.designMode &&
                o.row === target.row + dir.row &&
                o.column === target.column + dir.col,
            ),
        )
        .filter(Boolean);
    };

    // Utility to spread group ID to surrounding objects
    const spreadObjectGroupId = (objects, target, groupId) => {
      const surroundingObjects = getSurroundingObjects(objects, target);
      target.objectGroupId = groupId;

      surroundingObjects
        .filter((obj) => obj.objectGroupId == null)
        .forEach((obj) => spreadObjectGroupId(objects, obj, groupId));
    };

    let index = 0;

    // Helper function to assign group Ids based on design mode
    const assignGroupIdsByDesignMode = (mode) => {
      while (linq.from(seatingPlan.Objects).any((o) => o.designMode === mode && o.objectGroupId == null)) {
        spreadObjectGroupId(
          seatingPlan.Objects,
          linq.from(seatingPlan.Objects).firstOrDefault((o) => o.designMode === mode && o.objectGroupId == null),
          index,
        );
        index++;
      }
    };

    ['stage', 'label', 'standing'].forEach(assignGroupIdsByDesignMode);

    return seatingPlan;
  }

  public static getLabels(seatingPlan: ISeatingPlan): any[] {
    let seatingPlanLabels = [];

    if (!seatingPlan.Objects) return seatingPlanLabels;

    const _objects: ISeatingPlanObject[] = seatingPlan.Objects;

    // Helper function to create labels for a particular design mode
    const createLabelsByDesignMode = (mode: string, text: string) => {
      const groupIds = linq
        .from(_objects)
        .where((o) => o.designMode === mode)
        .distinct((o) => o.objectGroupId)
        .select((o) => o.objectGroupId)
        .toArray();

      groupIds.forEach((groupId) => {
        const groupObjects = linq
          .from(_objects)
          .where((o) => o.designMode === mode && o.objectGroupId === groupId)
          .toArray();

        const minColumn = linq.from(groupObjects).min((o) => o.column);
        const maxColumn = linq.from(groupObjects).max((o) => o.column);
        const minRow = linq.from(groupObjects).min((o) => o.row);
        const maxRow = linq.from(groupObjects).max((o) => o.row);

        const column = 0.5 + NumberHelper.roundDecimal((maxColumn + minColumn) / 2);
        const rows = maxRow - minRow + 1;

        // Additional label creation logic for 'label' mode
        if (mode === 'label') {
          const columnsAllTheSame = groupObjects.every((o) => o.column === groupObjects[0].column);
          const midPoint = Math.round(seatingPlan.Columns / 2);

          seatingPlanLabels.push(
            <SeatingPlanLabel
              key={
                columnsAllTheSame && groupObjects.length > 1
                  ? `labelGroup_${minRow}_${maxRow}_${minColumn}_${maxColumn}`
                  : `labelGroup_${groupId}`
              }
              rightSide={column > midPoint}
              vertical={columnsAllTheSame && groupObjects.length > 1}
              rows={rows}
              height={SeatingPlanHelper.elementWidth}
              width={SeatingPlanHelper.elementWidth}
              column={column}
              row={minRow}
              text={groupObjects.find((o) => o.text)?.text || '...'}
            />,
          );
        } else {
          seatingPlanLabels.push(
            <SeatingPlanLabel
              key={`${mode}_${minRow}_${column}`}
              rows={rows}
              height={SeatingPlanHelper.elementWidth}
              width={SeatingPlanHelper.elementWidth}
              column={column}
              row={minRow}
              text={text}
            />,
          );
        }
      });
    };

    createLabelsByDesignMode('stage', 'Stage');
    createLabelsByDesignMode('label', '...');
    createLabelsByDesignMode('standing', 'Standing');

    return seatingPlanLabels;
  }

  public static repairAllocatedCategoryGroups(event: IEvent): IEvent {
    if (!event) return null;
    if (!event.SeatingPlans || event.SeatingPlans.length == 0) return event;

    if (!event.UseSeatingPlan) {
      event.AllocatedCategoryGroups = [];
      event.Categories =
        event.Categories && event.Categories.length > 0
          ? linq
              .from(event.Categories)
              .where((c) => c.SeatCategory == null)
              .toArray()
          : [];
      return event;
    }

    const length = event.AllocatedCategoryGroups.length;

    if (event.AllocatedCategoryGroups && event.AllocatedCategoryGroups.length > 0) {
      event.AllocatedCategoryGroups = linq
        .from(event.AllocatedCategoryGroups)
        .select((a) => {
          return {
            ...a,
            Categories: linq
              .from(a.Categories)
              .distinct((a) => a.SeatCategory.Id)
              .toArray(),
          };
        })
        .toArray();
    }

    if (length != event.AllocatedCategoryGroups.length) {
      console.log('ALLOCATED CATEGORY GROUPS DO NOT MATCH');
    }

    return event;
  }

  public static copyPlan(fromPlan: ISeatingPlan, toPlan: ISeatingPlan, keepOriginalSeatCategories: boolean = false) {
    fromPlan.Id = 0;
    toPlan.ChangesMade = true;

    if (fromPlan.BackgroundUrl) {
      toPlan.BackgroundUrl = fromPlan.BackgroundUrl;
      toPlan.BackgroundX = fromPlan.BackgroundX;
      toPlan.BackgroundY = fromPlan.BackgroundY;
      toPlan.BackgroundWidth = fromPlan.BackgroundWidth;
    }

    toPlan.Rows = fromPlan.Rows;
    toPlan.Columns = fromPlan.Columns;

    toPlan.Objects = [];

    fromPlan.Objects.forEach((o: ISeatingPlanObject) => {
      toPlan.Objects.push({ ...o });
    });

    if (keepOriginalSeatCategories) {
      toPlan.Seats = [];

      fromPlan.SeatCategories.forEach((sc) => {
        let mostSimilarSeatCategory = linq.from(toPlan.SeatCategories).firstOrDefault((s) => {
          return s.Name.toLowerCase() === sc.Name.toLowerCase();
        });

        if (!mostSimilarSeatCategory) mostSimilarSeatCategory = toPlan.SeatCategories[0];

        linq
          .from(fromPlan.Seats)
          .where((s) => (!s.SeatCategoryId ? s.SeatCategoryGuid == sc.Guid : s.SeatCategoryId == sc.Id))
          .forEach((s) => {
            const newSeat = {
              ...s,
              Id: 0,
              SeatCategory: mostSimilarSeatCategory,
              SeatCategoryGuid: mostSimilarSeatCategory.Guid,
              SeatCategoryId: mostSimilarSeatCategory.Id,
            };
            toPlan.Seats.push(newSeat);
          });
      });
    } else {
      toPlan.SeatCategories = [];
      toPlan.Seats = [];

      fromPlan.SeatCategories.forEach((sc) => {
        const seatCategory = { ...sc, Id: 0, Guid: GuidHelper.new() };

        linq
          .from(fromPlan.Seats)
          .where((s) => (!s.SeatCategoryId ? s.SeatCategoryGuid == sc.Guid : s.SeatCategoryId == sc.Id))
          .forEach((s) => {
            const newSeat = {
              ...s,
              Id: 0,
              SeatCategory: seatCategory,
              SeatCategoryGuid: seatCategory.Guid,
              SeatCategoryId: seatCategory.Id,
            };
            toPlan.Seats.push(newSeat);
          });

        toPlan.SeatCategories.push(seatCategory);
      });
    }
  }

  public static clearSeatCategories(fromPlan): ISeatingPlan {
    fromPlan.SeatCategories.forEach((seatCategory) => {
      seatCategory.Guid = GuidHelper.new();

      fromPlan.Seats.filter((s) => s.SeatCategoryId == seatCategory.Id).forEach((seat) => {
        seat.SeatCategoryGuid = seatCategory.Guid;
        seat.SeatCategoryId = 0;
        seat.SeatCategory = seatCategory;
        seat.Id = 0;
      });
      seatCategory.Id = 0;
    });

    // event.SeatingPlans = [fromPlan];
    // event.AllocatedCategoryGroups = [];
    return fromPlan;
  }

  public static getDefaultSeat(seatingPlan: ISeatingPlan, column: number, row: number): ISeatingPlanSeat {
    return {
      OffsetY: 0.0,
      OffsetX: 0.0,
      Locked: false,
      Selected: false,
      Row: row,
      Column: column,
      Group: '-',
      Name: '-',
      SeatCategory: seatingPlan.SeatCategories[0],
      SeatCategoryId: seatingPlan.SeatCategories[0].Id,
      SeatCategoryGuid: seatingPlan.SeatCategories[0].Guid,
      SeatCategoryColour: seatingPlan.SeatCategories[0].Colour,
      SeatCategoryName: seatingPlan.SeatCategories[0].Name,
    };
  }

  public static getDefaultSeatingPlan(): ISeatingPlan {
    return {
      Id: 0,
      Index: 0,
      SeatScale: 1.0,
      Guid: GuidHelper.new(),
      Name: '',
      Seats: [],
      Objects: [],
      Rows: 12,
      Columns: 24,
      SeatCategories: [
        {
          Id: 0,
          Name: 'Standard',
          Colour: '#31759d',
          Guid: GuidHelper.new(),
          Description: '',
          Message: '',
          Icon: '',
        },
      ],
    };
  }

  public static refreshMiddleSeats(seatingPlan: ISeatingPlan) {
    seatingPlan.Seats.forEach((seat) => {
      var seatsToRightAndLeft = linq
        .from(seatingPlan.Seats)
        .where((s) => {
          return (
            !s.Disabled &&
            s.Group == seat.Group &&
            s.SeatCategoryId == seat.SeatCategoryId &&
            s.Row == seat.Row &&
            (s.Column == seat.Column + 1 || s.Column == seat.Column - 1)
          );
        })
        .toArray();

      var seatsToTopAndBottom = linq
        .from(seatingPlan.Seats)
        .where(function (s) {
          return (
            !s.Disabled &&
            s.Group == seat.Group &&
            s.SeatCategoryId == seat.SeatCategoryId &&
            s.Column == seat.Column &&
            (s.Row == seat.Row + 1 || s.Row == seat.Row - 1)
          );
        })
        .toArray();

      if (seatsToRightAndLeft.length == 2 || seatsToTopAndBottom.length == 2) {
        seat.isMiddleSeat = true;
      } else {
        seat.isMiddleSeat = false;
      }
    });
  }

  public static loadSeatingPlanForEdit(seatingPlan: ISeatingPlan): ISeatingPlan {
    seatingPlan.Seats.forEach((seat) => {
      let seatCategory = linq
        .from(seatingPlan.SeatCategories)
        .where((sc) => sc.Id == seat.SeatCategoryId)
        .firstOrDefault();

      if (seatCategory == null) seatCategory = seatingPlan.SeatCategories[0];

      seatingPlan.Seats.forEach((s) => (s.Selected = true));

      seat.SeatCategory = seatCategory;
      seat.SeatCategoryGuid = seatCategory.Guid;
      seat.SeatCategoryId = seatCategory.Id;
    });

    return seatingPlan;
  }

  public static getSingleSeats(seatingPlan: ISeatingPlan) {
    const singleSeats = [];

    var selectedSeats = linq
      .from(seatingPlan.Seats)
      .where((s) => s.Selected)
      .toArray();

    selectedSeats.forEach((seat) => {
      var seatsInMyRow = linq
        .from(seatingPlan.Seats)
        .where((s) => s.Row == seat.Row && s.Disabled != true)
        .toArray();

      var seatToRight = linq
        .from(seatsInMyRow)
        .where((s) => !s.Selected && s.Column == seat.Column + 1)
        .firstOrDefault();

      if (seatToRight != null) {
        var seatToRightOfRight = linq
          .from(seatsInMyRow)
          .where((s) => s.Column == seat.Column + 2)
          .firstOrDefault();

        if (seatToRightOfRight == null || seatToRightOfRight.Selected == true) {
          if (
            linq
              .from(singleSeats)
              .where((s) => s.Row == seatToRight.Row && s.Column == seatToRight.Column)
              .firstOrDefault() == null
          ) {
            singleSeats.push(seatToRight);
          }
        }
      }

      var seatToLeft = linq
        .from(seatsInMyRow)
        .where(function (s) {
          return !s.Selected && s.Column == seat.Column - 1;
        })
        .firstOrDefault();

      if (seatToLeft != null) {
        var seatToLeftOfLeft = linq
          .from(seatsInMyRow)
          .where(function (s) {
            return s.Column == seat.Column - 2;
          })
          .firstOrDefault();

        if (seatToLeftOfLeft == null || seatToLeftOfLeft.Selected == true) {
          if (
            linq
              .from(singleSeats)
              .where(function (s) {
                return s.Row == seatToLeft.Row && s.Column == seatToLeft.Column;
              })
              .firstOrDefault() == null
          ) {
            singleSeats.push(seatToLeft);
          }
        }
      }
    });

    return singleSeats;
  }
}
