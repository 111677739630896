export default class ScriptHelper {
  public static loadScript(url: string, type: string = null, charset: string = null, async: boolean = false, defer: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (type === undefined || type === null) type = 'text/javascript';
      if (url) {
        var script: HTMLScriptElement = document.querySelector("script[src*='" + url + "']");
        if (!script) {
          var heads = document.getElementsByTagName('head');
          if (heads && heads.length) {
            var head = heads[0];
            if (head) {
              script = document.createElement('script');
              script.setAttribute('src', url);
              script.setAttribute('type', type);
              async && script.setAttribute('async', 'true');
              defer && script.setAttribute('defer', 'true');

              (script as any).onreadystatechange = resolve;
              script.onload = resolve;
              script.onerror = reject;
              if (charset) script.setAttribute('charset', charset);
              head.appendChild(script);
            }
          } else {
            reject("No heads.")
          }
        } else {
          resolve(null);
        }
      }
    });
  }

  public static async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
}
