import linq from 'linq';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AdminApi from '../../api/AdminApi';
import wizardEventState from '../../atoms/wizardEventState';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import BlockTextBox from '../../components/BlockTextBox';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import SpacerTable from '../../components/SpacerTable';
import CacheHelper from '../../helpers/CacheHelper';
import OrganisationHelper from '../../helpers/OrganisationHelper';
import StringHelper from '../../helpers/StringHelper';
import { IOrganisation } from '../../interfaces/IOrganisation';
import SVGEditEvent from '../../svg/SVGEditEvent';
import SVGInfo from '../../svg/SVGInfo';
import SVGNew from '../../svg/SVGNew';
import SVGSave from '../../svg/SVGSave';
import { EventWizardSection } from './WizardEvent';

interface IWizardResult {
  Organisations: IWizardOrganisation[];
}

interface IWizardOrganisation {
  Id: number;
  Name: string;
  Tag: string;
}

export enum OrganisationWizardSection {
  OrganisationSelector,
  NewOrganisation,
  OrganisationSaved,
}

export interface IProps {
  onClose: () => void;
  startFrom?: OrganisationWizardSection;
}

const WizardOrganisation: React.FC<IProps> = (props) => {
  const [, setWizardEvent] = useRecoilState(wizardEventState);

  const { onClose } = props;
  const [section, setSection] = useState(props.startFrom ? props.startFrom : OrganisationWizardSection.OrganisationSelector);

  const [organisation, setOrganisation] = useState<IOrganisation>(OrganisationHelper.getDefault());
  const [organisations, setOrganisations] = useState<IWizardResult>(null);

  const [error, setError] = useState(null);
  const [busy, setBusy] = useState('Setting things up...');

  useEffect(() => {
    AdminApi.request('GET', '/api/OrganisationWizard')
      .then((results) => {
        setOrganisations(results);
      })
      .catch((message) => alert(message))
      .finally(() => setBusy(null));
  }, []);

  const organisationSelectorSection = () => {
    return (
      <>
        <SpacerTable>
          <h1>Let's get you started!</h1>
          Welcome to the new event wizard. Select the organisation you want to create your new event for, or make a new one!
        </SpacerTable>

        <div className="spacer" />

        {organisations && organisations.Organisations && organisations.Organisations.length > 0 && (
          <>
            <table className="blocks">
              <tbody>
                {linq
                  .from(organisations.Organisations)
                  .orderBy((o) => o.Name)
                  .thenBy((o) => o.Tag)
                  .toArray()
                  .map((o) => (
                    <Block
                      key={o.Id}
                      className="route"
                      onClick={() => {
                        setBusy('Loading organisation...');
                        CacheHelper.organisationById(o.Id)
                          .then((org) => {
                            setWizardEvent({ organisation: org });
                            onClose();
                          })
                          .catch((err) => setError(err))
                          .finally(() => setBusy(null));
                      }}
                    >
                      <BlockHeader>{o.Name}</BlockHeader>
                    </Block>
                  ))}
              </tbody>
            </table>
            <div className="spacer" />
          </>
        )}

        <table className="blocks">
          <tbody>
            <Block
              className="action has-icon"
              onClick={() => {
                setOrganisation(OrganisationHelper.getDefault());
                setSection(OrganisationWizardSection.NewOrganisation);
              }}
            >
              <SVGNew />
              <BlockHeader>Make a new organisation</BlockHeader>
              <div>Start from scratch and create a brand new organisation </div>
            </Block>
          </tbody>
        </table>
      </>
    );
  };

  const [organisationName, setOrganisationName] = useState('');
  const [organisationTag, setOrganisationTag] = useState('');
  const [organisationDescription, setOrganisationDescription] = useState('');
  const [organisationStreet, setOrganisationStreet] = useState('');
  const [organisationCity, setOrganisationCity] = useState('');
  const [organisationPostcode, setOrganisationPostcode] = useState('');
  const [organisationWebsite, setOrganisationWebsite] = useState('');
  const [organisationBoxOfficeEmail, setOrganisationBoxOfficeEmail] = useState('');
  const [organisationBoxOfficeTel, setOrganisationBoxOfficeTel] = useState('');

  const validOrganisationSave = organisationName.length > 2 && organisationTag.length > 2 && organisationStreet.length > 2 && organisationCity.length > 2 && organisationPostcode.length > 2;

  const createOrganisation = () => {
    setError(null);
    setBusy('Saving organisation...');

    OrganisationHelper.save(
      {
        ...organisation,
        Name: organisationName,
        OrganisationTag: organisationTag,
        StreetAddress: organisationStreet,
        City: organisationCity,
        Postcode: organisationPostcode,
        WebsiteUrl: organisationWebsite,
        BoxOfficeEmail: organisationBoxOfficeEmail,
        BoxOfficeTel: organisationBoxOfficeTel,
        Description: organisationDescription,
      },
      setBusy,
      null,
    )
      .then((o: IOrganisation) => {
        setBusy(null);
        setOrganisation(o);
        setSection(OrganisationWizardSection.OrganisationSaved);
      })
      .catch((error) => {
        setError(error);
        setBusy(null);
      });
  };

  const newOrganisationSection = () => {
    return (
      <>
        <SpacerTable>
          <h1>Create an Organisation</h1>
          Tell us some of the basics about your organisation. When your new organisation is set up you will be given access to the full organisation editor.
        </SpacerTable>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>What is the name of your organisation?</BlockHeader>
              <BlockTextBox autoFocus={true} placeholder="Organisation name" value={organisationName} onChange={(e) => setOrganisationName(e)} rows={1} />
            </Block>
            <Block>
              <BlockHeader>What unique organisation tag would you like?</BlockHeader>
              <BlockTextBox
                maxLength={22}
                placeholder="Organisation tag"
                value={organisationTag}
                onChange={(value) => {
                  !StringHelper.hasSpecialCharacters(value) && setOrganisationTag(value);
                }}
                rows={1}
              />
            </Block>
            <BlockInfo>
              A link to Seaty.co.uk/Organisation/{organisationTag.length > 0 ? organisationTag.toLowerCase() : 'tag'} will be created where all events for your organisation can be found. Tags must
              contain no special characters.
            </BlockInfo>

            <Block>
              <BlockHeader>What is your organisations address?</BlockHeader>
              <BlockTextBox placeholder="Street" value={organisationStreet} onChange={(e) => setOrganisationStreet(e)} rows={1} />
              <BlockTextBox placeholder="City or town" value={organisationCity} onChange={(e) => setOrganisationCity(e)} rows={1} />
              <BlockTextBox style={{ width: '50%' }} placeholder="Postcode" value={organisationPostcode} onChange={(e) => setOrganisationPostcode(e)} rows={1} />
            </Block>

            <Block>
              <BlockHeader rightText="(optional)">How would you describe your organisation?</BlockHeader>
              <BlockTextBox rows={2} placeholder="Description" value={organisationDescription} onChange={(e) => setOrganisationDescription(e)} />
            </Block>
            <Block>
              <BlockHeader rightText="(optional)">What is your website address?</BlockHeader>
              <BlockTextBox placeholder="www.your-organisation.co.uk (example)" value={organisationWebsite} onChange={(e) => setOrganisationWebsite(e)} rows={1} />
            </Block>
            <Block>
              <BlockHeader rightText="(optional)">What is your box office email address?</BlockHeader>
              <BlockTextBox placeholder="Email address" value={organisationBoxOfficeEmail} onChange={(e) => setOrganisationBoxOfficeEmail(e)} rows={1} />
            </Block>
            <Block>
              <BlockHeader rightText="(optional)">What is your box office telephone number? </BlockHeader>
              <BlockTextBox placeholder="Telephone number" value={organisationBoxOfficeTel} onChange={(e) => setOrganisationBoxOfficeTel(e)} rows={1} />
            </Block>
          </tbody>
        </table>
        <div className="spacer" />

        <table className="blocks">
          <tbody>
            {validOrganisationSave ? (
              <Block className={`action has-icon`} onClick={() => createOrganisation()}>
                <SVGSave />
                <BlockHeader>Save & create new organisation</BlockHeader>
                <div>Submit your organisation details and go to event options.</div>
              </Block>
            ) : (
              <Block className={`has-icon `}>
                <SVGInfo />
                <BlockHeader>Awaiting details</BlockHeader>
                <div>You must enter the required fields before you can save your organisation.</div>
              </Block>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const organisationSavedSection = () => {
    return (
      <>
        <SpacerTable>
          <h1>Congrationations!</h1>
          You organisation is now saved, you can access it at any time by going to My Events from the main menu.
        </SpacerTable>

        <div className="spacer" />

        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Organisation name</BlockHeader>
              {organisation.Name}
              <div className="spacer" />
              <BlockHeader>Organisation tag</BlockHeader>
              Seaty.co.uk/Organisation/{organisation.OrganisationTag}
              <div className="spacer" />
              <BlockHeader>Address</BlockHeader>
              {organisation.StreetAddress}
              <br />
              {organisation.City}
              <br />
              {organisation.Postcode}
            </Block>
          </tbody>
        </table>
        <div className="spacer" />

        <table className="blocks">
          <tbody>
            <Block
              className="action has-icon"
              onClick={() => {
                setWizardEvent({ organisation: organisation, startFrom: EventWizardSection.EventOptions });
                onClose();
              }}
            >
              <SVGNew />
              <BlockHeader>Make an event</BlockHeader>
              <div>See options for creating a new event for {organisation.Name}.</div>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />

        <table className="blocks">
          <tbody>
            <Block
              onClick={() => {
                window.location.href = `/Organisation/${organisation.OrganisationTag}/Edit`;
              }}
              className="action has-icon"
            >
              <SVGEditEvent />
              <BlockHeader>Open organisation editor</BlockHeader>
              <div>Go to the full editor and add more detail to your organisation.</div>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
      </>
    );
  };

  return (
    <Modal onCloseClick={props.onClose} theme={organisation && organisation.Theme ? organisation.Theme : null}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          {busy && <Loader inline={true}>{busy}</Loader>}

          <div style={{ display: busy ? 'none' : 'block' }}>
            {section === OrganisationWizardSection.OrganisationSelector && organisationSelectorSection()}
            {section === OrganisationWizardSection.NewOrganisation && newOrganisationSection()}
            {section === OrganisationWizardSection.OrganisationSaved && organisationSavedSection()}

            {error && (
              <table className="blocks" style={{ marginTop: '25px' }}>
                <tbody>
                  <BlockInfo type={InfoType.Error}>{error}</BlockInfo>
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
};

export default WizardOrganisation;
