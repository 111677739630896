import { FunctionComponent } from 'react';
import Modal from './Modal';
import AdminMenuOrganisation, { IAdminMenuOrganisationProps } from './AdminMenuOrganisation';

const AdminMenuOrganisationModal: FunctionComponent<IAdminMenuOrganisationProps> = (props) => {
  const organisation = props.organisation;

  if (!organisation || !organisation.Id) {
    return null;
  }

  return (
    <>
      <Modal className="admin-menu-modal" theme={organisation.Theme} onCloseClick={props.onCloseClick}>
        <AdminMenuOrganisation {...props} />
      </Modal>
    </>
  );
};

export default AdminMenuOrganisationModal;
