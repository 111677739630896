import React, { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg viewBox="0 0 50 50">
      <g id="surface1">
        <path d="M 13.890625 10.914063 C 6.230469 10.914063 0 17.144531 0 24.800781 C 0 32.460938 6.230469 38.691406 13.890625 38.691406 L 35.714844 38.691406 C 43.371094 38.691406 49.601563 32.460938 49.601563 24.800781 C 49.601563 17.144531 43.371094 10.914063 35.714844 10.914063 Z M 13.890625 12.898438 C 20.476563 12.898438 25.792969 18.214844 25.792969 24.800781 C 25.792969 31.390625 20.476563 36.707031 13.890625 36.707031 C 7.300781 36.707031 1.984375 31.390625 1.984375 24.800781 C 1.984375 18.214844 7.300781 12.898438 13.890625 12.898438 Z M 20.988281 12.898438 L 35.714844 12.898438 C 42.300781 12.898438 47.617188 18.214844 47.617188 24.800781 C 47.617188 31.390625 42.300781 36.707031 35.714844 36.707031 L 20.988281 36.707031 C 25.042969 34.277344 27.777344 29.859375 27.777344 24.800781 C 27.777344 19.746094 25.042969 15.328125 20.988281 12.898438 Z " />
      </g>
    </svg>
  );
}) as FunctionComponent<IProps>;
