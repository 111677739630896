import React, { useEffect, useState } from 'react';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import GuidHelper from '../../../helpers/GuidHelper';
import SVGHelper from '../../../helpers/SVGHelper';
import { IEvent } from '../../../interfaces/IEvent';
import Toolbar from '../toolbar/Toolbar';
import './MapSection.scss';

export interface IThreeWordAddress {
  Guid: string;
  Lng: number;
  Lat: number;
  New: boolean;
  Name: string;
  Description: string;
  Address: string;
  IconUrl: string;
}

export interface IEventEditor_ThreeWordAddressListItemProps {
  threeWordAddress: IThreeWordAddress;
  onEnterViewMode: () => void;
  onEnterEditMode: (guid) => void;
  onChange: (threeWordAddress: IThreeWordAddress) => void;
  onDeleteClick: (threeWordAddress: IThreeWordAddress) => void;
}

enum ThreeWordAddressMode {
  Edit = 2,
  View = 1,
}

const EventEditor_ThreeWordAddressListItem: React.FC<IEventEditor_ThreeWordAddressListItemProps> = (props) => {
  const [mode, setMode] = useState(props.threeWordAddress.New ? ThreeWordAddressMode.Edit : ThreeWordAddressMode.View);
  const [name, setName] = useState(props.threeWordAddress.Name);
  const [description, setDescription] = useState(props.threeWordAddress.Description);
  const [address, setAddress] = useState(props.threeWordAddress.Address);
  const [imageUrl, setImageUrl] = useState(props.threeWordAddress.IconUrl == null || props.threeWordAddress.IconUrl.length == 0 ? 'MapPin.svg' : props.threeWordAddress.IconUrl);
  const [error, setError] = useState(null);

  const handleCancelClick = function () {
    if (props.threeWordAddress.New) {
      if (props.onDeleteClick) {
        props.onDeleteClick(props.threeWordAddress);
      }
    } else {
      enterViewMode();
    }
  };

  const handleDeleteClick = function () {
    if (props.onDeleteClick) {
      props.onDeleteClick(props.threeWordAddress);
    }
  };

  const handleAddressChange = function (e) {
    setAddress(e);
  };

  const handleNameChange = function (e) {
    setName(e);
  };

  const handleDescriptionChange = function (e) {
    setDescription(e);
  };

  const handleToolbarButtonClick = function (e) {
    var element: HTMLElement = e.target;

    var designMode = element.dataset.designmode;

    while (designMode == null) {
      designMode = element.parentElement.dataset.designmode;
    }

    setImageUrl(designMode);
  };

  const enterViewMode = function () {
    setMode(ThreeWordAddressMode.View);

    if (props.onEnterViewMode) {
      props.onEnterViewMode();
    }
  };

  const enterEditMode = function () {
    setMode(ThreeWordAddressMode.Edit);

    if (props.onEnterEditMode) {
      props.onEnterEditMode(props.threeWordAddress.Guid);
    }
  };

  const handleConfirmClick = function () {
    setError('');

    fetch('https://api.what3words.com/v2/forward?addr=' + address.replace('///', '') + '&key=2G23X72K&lang=en&format=json&display=full', {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {},
    })
      .then((response) => {
        return response.json();
      })
      .then((response: any) => {
        if (response.status.code) {
          setError('This is not a valid three word address.');
        } else {
          props.threeWordAddress.Address = address.replace('///', '');
          props.threeWordAddress.Name = name;
          props.threeWordAddress.Description = description;
          props.threeWordAddress.IconUrl = imageUrl;
          props.threeWordAddress.New = false;
          props.threeWordAddress.Lat = response.geometry.lat;
          props.threeWordAddress.Lng = response.geometry.lng;

          props.onChange(props.threeWordAddress);
          enterViewMode();
        }
      })
      .catch(() => setError('Error: This is not a valid three word address.'));
  };

  const onViewItemClick = function () {
    enterEditMode();
  };

  const renderViewMode = function () {
    return (
      <a className="field img" onClick={onViewItemClick}>
        <img src={SVGHelper.get(props.threeWordAddress.IconUrl)}></img>
        <div>
          <strong>{props.threeWordAddress.Name}</strong>
        </div>
        <div>{props.threeWordAddress.Description}</div>
        <div>{props.threeWordAddress.Address}</div>
      </a>
    );
  };

  const findAddress = function () {
    var win = window.open('https://map.what3words.com/', '_blank');
    win.focus();
  };

  const renderEditMode = function () {
    return (
      <div style={{ paddingTop: '4px' }}>
        <button onClick={findAddress} className="admin-button">
          Find a three word address
        </button>

        <div className="field element">
          <InfoInput maxLength={100} rows={1} labelText="What.3.Words" onChange={handleAddressChange} value={address} />
          <InfoInput maxLength={200} rows={1} labelText="Name" onChange={handleNameChange} value={name} />
          <InfoInput maxLength={200} rows={2} labelText="Description" onChange={handleDescriptionChange} value={description} />

          <div className="design-toolbar">
            <span data-designmode="MapPin.svg" onClick={handleToolbarButtonClick} className={'toolbar-button designmode-seats ' + (imageUrl == 'MapPin.svg' ? 'selected' : '')}>
              <img src={SVGHelper.get('MapPin')} className="icon" />
              <span className="description">Marker</span>
            </span>
            <span data-designmode="MapPin_Parking.svg" onClick={handleToolbarButtonClick} className={'toolbar-button designmode-stage ' + (imageUrl == 'MapPin_Parking.svg' ? 'selected' : '')}>
              <img src={SVGHelper.get('MapPin_Parking')} className="icon" />
              <span className="description">Parking</span>
            </span>
            <span data-designmode="MapPin_BoxOffice.svg" onClick={handleToolbarButtonClick} className={'toolbar-button designmode-stage ' + (imageUrl == 'MapPin_BoxOffice.svg' ? 'selected' : '')}>
              <img src={SVGHelper.get('MapPin_BoxOffice')} className="icon" />
              <span className="description">Box Office</span>
            </span>
            <span data-designmode="MapPin_Entry.svg" onClick={handleToolbarButtonClick} className={'toolbar-button designmode-stage ' + (imageUrl == 'MapPin_Entry.svg' ? 'selected' : '')}>
              <img src={SVGHelper.get('MapPin_Entry')} className="icon" />
              <span className="description">Entrance</span>
            </span>
            <span data-designmode="MapPin_Exit.svg" onClick={handleToolbarButtonClick} className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_Exit.svg' ? 'selected' : '')}>
              <img src={SVGHelper.get('MapPin_Exit')} className="icon" />
              <span className="description">Exit</span>
            </span>
            <span data-designmode="MapPin_Bar.svg" onClick={handleToolbarButtonClick} className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_Bar.svg' ? 'selected' : '')}>
              <img src={SVGHelper.get('MapPin_Bar')} className="icon" />
              <span className="description">Bar</span>
            </span>
            <span data-designmode="MapPin_Food.svg" onClick={handleToolbarButtonClick} className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_Food.svg' ? 'selected' : '')}>
              <img src={SVGHelper.get('MapPin_Food')} className="icon" />
              <span className="description">Food</span>
            </span>
            <span data-designmode="MapPin_IceCream.svg" onClick={handleToolbarButtonClick} className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_IceCream.svg' ? 'selected' : '')}>
              <img src={SVGHelper.get('MapPin_IceCream')} className="icon" />
              <span className="description">Ice Cream</span>
            </span>
            <span data-designmode="MapPin_Info.svg" onClick={handleToolbarButtonClick} className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_Info.svg' ? 'selected' : '')}>
              <img src={SVGHelper.get('MapPin_Info')} className="icon" />
              <span className="description">Info</span>
            </span>
            <span data-designmode="MapPin_RedCross.svg" onClick={handleToolbarButtonClick} className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_RedCross.svg' ? 'selected' : '')}>
              <img src={SVGHelper.get('MapPin_RedCross')} className="icon" />
              <span className="description">Red Cross</span>
            </span>
            <span
              data-designmode="MapPin_Restaurant.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_Restaurant.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin_Restaurant')} className="icon" />
              <span className="description">Restaurant</span>
            </span>
          </div>

          <div style={{ color: 'red', display: error ? 'block' : 'none' }}>{error}</div>
          <div className="row">
            <div className="col-sm-4">
              <button className="admin-button confirm" style={{ marginBottom: '0' }} onClick={handleConfirmClick}>
                Confirm
              </button>
            </div>
            <div className="col-sm-4">
              <button className="admin-button" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
            <div className="col-sm-4">
              <button style={{ marginBottom: '0', display: !props.threeWordAddress.New ? 'block' : 'none' }} className="admin-button bad" onClick={handleDeleteClick}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (mode == ThreeWordAddressMode.Edit) {
    return renderEditMode();
  } else {
    return renderViewMode();
  }
};

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const MapSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;

  const [editingThreeWordAddress, setEditingThreeWordAddress] = useState<string>(null);
  const [map, setMap] = useState<google.maps.Map<HTMLElement>>(null);
  const [markers, setMarkers] = useState([]);

  const updateMapMarkers = () => {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }

    const _markers = [];

    if (map && event.UseThreeWordAddress) {
      var threeWordAddresses = event.ThreeWordAddresses;
      for (var i = 0; i < threeWordAddresses.length; i++) {
        var threeWordAddress = threeWordAddresses[i];

        if (threeWordAddress.IconUrl == null || threeWordAddress.IconUrl.length == 0) {
          threeWordAddress.IconUrl = 'MapPin.svg';
        }

        threeWordAddress.Lat = Number(threeWordAddress.Lat);
        threeWordAddress.Lng = Number(threeWordAddress.Lng);

        var infowindow = new google.maps.InfoWindow({
          content: `<strong>${threeWordAddress.Name}</strong><div>${threeWordAddress.Description}</div>`,
        });

        var marker = new google.maps.Marker({
          position: {
            lat: threeWordAddress.Lat,
            lng: threeWordAddress.Lng,
          },
          map: map,
          title: threeWordAddress.Name,
          icon: {
            url: SVGHelper.get(threeWordAddress.IconUrl),
            scaledSize: new google.maps.Size(60, 60),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(30, 60),
          },
        });

        google.maps.event.addListener(
          marker,
          'click',
          (function (marker, infowindow) {
            return function () {
              infowindow.open(map, marker);
            };
          })(marker, infowindow)
        );

        _markers.push(marker);
      }
    }

    setMarkers(_markers);
  };

  const onZoomChanged = () => {
    event.MapZoom = map.getZoom().toString();
    onEventUpdated({ ...event });
  };

  const onCenterChanged = () => {
    var loc = map.getCenter();

    event.Lat = loc.lat().toString();
    event.Lng = loc.lng().toString();
    onEventUpdated({ ...event });
  };

  useEffect(() => {
    const listener1 = map ? map.addListener('zoom_changed', onZoomChanged) : null;
    const listener2 = map ? map.addListener('center_changed', onCenterChanged) : null;

    if (map) {
      updateMapMarkers();
    }

    return () => {
      listener1 && google.maps.event.removeListener(listener1);
      listener2 && google.maps.event.removeListener(listener2);
    };
  }, [map, event]);

  useEffect(() => {
    var lat = event.Lat != null && event.Lat.length > 0 ? parseFloat(event.Lat) : 55.3781;
    var lng = event.Lng != null && event.Lng.length > 0 ? parseFloat(event.Lng) : 3.436;
    var mapZoom = event.MapZoom != null && event.MapZoom.length > 0 ? parseInt(event.MapZoom) : 6;

    var uluru = { lat: lat, lng: lng };

    var google_map = new google.maps.Map(document.getElementById('googleMap'), {
      zoom: mapZoom,
      center: uluru,
      disableDefaultUI: true, // a way to quickly hide all controls
      scaleControl: true,
      zoomControl: true,
      streetViewControl: false,
      mapTypeControl: false,
    });

    setMap(google_map);
  }, []);

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>

      <div className="maps animated fadeIn">
        <div className="row">
          <div className="col-sm-8">
            <div className="fields">
              <h2>Map options</h2>
              <div className="section">
                <InfoLabel
                  text="Show a map on your event?"
                  tooltip="Showing a map is a great way to help people find your event. Just enable the map and then set the pointer over your event location."
                />

                <Switch trueLabel="Yes, show a map" falseLabel="No, don't show a map" onChange={(value) => onEventUpdated({ ...event, ShowMap: value })} checked={event.ShowMap} />
              </div>
            </div>

            <div className="fields">
              <h2>Three Word Address</h2>
              <div
                className="section"
                style={{
                  display: editingThreeWordAddress ? 'none' : 'block',
                }}
              >
                <InfoLabel
                  text="Point out specific locations using three word addresses?"
                  tooltip="Three word addresses work by allowing you to pin point locations on a map. You can use them to help people find the exact entrance to your venue or to help point out the car park etc. You can have more than one three word address to help point out multiple locations."
                />

                <Switch trueLabel="Yes, use them" falseLabel="No, thanks" onChange={(value) => onEventUpdated({ ...event, UseThreeWordAddress: value })} checked={event.UseThreeWordAddress} />
              </div>

              <div
                className="smooth-details"
                style={{
                  display: event.UseThreeWordAddress ? 'block' : 'none',
                }}
              >
                {event.ThreeWordAddresses.map((threeWordAddress) => {
                  if (!threeWordAddress.Guid) {
                    threeWordAddress.Guid = GuidHelper.new();
                  }

                  return (
                    <EventEditor_ThreeWordAddressListItem
                      onEnterViewMode={() => setEditingThreeWordAddress(null)}
                      onEnterEditMode={(guid: string) => setEditingThreeWordAddress(guid)}
                      onChange={() => {
                        updateMapMarkers();
                        if (map) {
                          map.setCenter(new google.maps.LatLng(threeWordAddress.Lat, threeWordAddress.Lng));
                        }
                      }}
                      onDeleteClick={(address) => {
                        event.ThreeWordAddresses = event.ThreeWordAddresses.filter((e) => e.Guid != address.Guid);
                        onEventUpdated({ ...event });
                        setEditingThreeWordAddress(null);
                      }}
                      key={'event_threewordaddress_list_item_' + threeWordAddress.Guid}
                      threeWordAddress={threeWordAddress}
                    />
                  );
                })}
                <button
                  className="admin-button"
                  style={{
                    display: editingThreeWordAddress ? 'none' : 'block',
                  }}
                  onClick={() => {
                    var newAddress = {
                      Id: 0,
                      Address: '',
                      Description: '',
                      Guid: GuidHelper.new(),
                      New: true,
                      IconUrl: 'MapPin.svg',
                    };

                    event.ThreeWordAddresses.push(newAddress);
                    setEditingThreeWordAddress(newAddress.Guid);
                    onEventUpdated({ ...event });
                  }}
                >
                  Add a three word address
                </button>
              </div>
            </div>
          </div>
        </div>

        <div style={{ opacity: event.ShowMap ? '1' : '0.25' }} className="container">
          <input
            style={{
              display: event.UseThreeWordAddress ? 'none' : 'block',
            }}
            id="pac-input"
            className="form-control mapSearch"
            type="text"
            placeholder="Search for a location..."
          />
          <img
            style={{
              display: event.UseThreeWordAddress ? 'none' : 'block',
            }}
            className="pointer"
            src={SVGHelper.get('MapPin')}
          ></img>
          <div id="googleMap" className="map"></div>
        </div>
      </div>
    </>
  );
};

export default MapSection;
