import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 9 L 5 9 L 5 5 L 9 5 L 9 3 L 5 3 z M 15 3 L 15 5 L 19 5 L 19 9 L 21 9 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 15 3 z M 3 15 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 9 21 L 9 19 L 5 19 L 5 15 L 3 15 z M 19 15 L 19 19 L 15 19 L 15 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 15 L 19 15 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
