import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 12.5 5 C 10.032499 5 8 7.0324991 8 9.5 L 8 33 L 6.5 33 A 1.50015 1.50015 0 0 0 5 34.5 L 5 36.5 C 5 40.071938 7.9280619 43 11.5 43 L 33.5 43 C 37.071938 43 40 40.071938 40 36.5 L 40 9.5 C 40 7.0324991 37.967501 5 35.5 5 L 12.5 5 z M 12.5 8 L 35.5 8 C 36.346499 8 37 8.6535009 37 9.5 L 37 36.5 C 37 38.450062 35.450062 40 33.5 40 C 31.549938 40 30 38.450062 30 36.5 L 30 34.5 A 1.50015 1.50015 0 0 0 28.5 33 L 11 33 L 11 9.5 C 11 8.6535009 11.653501 8 12.5 8 z M 16.5 13 A 1.5 1.5 0 0 0 16.5 16 A 1.5 1.5 0 0 0 16.5 13 z M 21.5 13 A 1.50015 1.50015 0 1 0 21.5 16 L 31.5 16 A 1.50015 1.50015 0 1 0 31.5 13 L 21.5 13 z M 16.5 19 A 1.5 1.5 0 0 0 16.5 22 A 1.5 1.5 0 0 0 16.5 19 z M 21.5 19 A 1.50015 1.50015 0 1 0 21.5 22 L 31.5 22 A 1.50015 1.50015 0 1 0 31.5 19 L 21.5 19 z M 16.5 25 A 1.5 1.5 0 0 0 16.5 28 A 1.5 1.5 0 0 0 16.5 25 z M 21.5 25 A 1.50015 1.50015 0 1 0 21.5 28 L 31.5 28 A 1.50015 1.50015 0 1 0 31.5 25 L 21.5 25 z M 8 36 L 27 36 L 27 36.5 C 27 37.815038 27.494068 38.973486 28.173828 40 L 11.5 40 C 9.5499381 40 8 38.450062 8 36.5 L 8 36 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
