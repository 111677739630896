import { FunctionComponent, useState } from 'react';
import NumberHelper from '../../../../helpers/NumberHelper';
import SVGMinus from '../../../../svg/SVGMinus';
import SVGPlus from '../../../../svg/SVGPlus';
import StringHelper from '../../../../helpers/StringHelper';

export interface IProps {
  title: string;
  subtitle?: string;
  titleColour?: string;
  subtitleColour?: string;
  amount: string;
  people?: string;
  percentage?: number;
  total: string;
  child?: any;
  wording?: string;
  peopleWording?: string;
}

const EventSummary_TicketSalesRow: FunctionComponent<IProps> = (props) => {
  const [showChild, setShowChild] = useState(false);

  return (
    <>
      <div onClick={() => setShowChild(!showChild)} className={`field${props.child ? ' field--parent' : ''}`}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '35px', paddingRight: '8px' }}>
                {props.child && (
                  <>
                    {showChild ? (
                      <button className="toggle collapse">
                        <SVGMinus />
                      </button>
                    ) : (
                      <button className="toggle expand">
                        <SVGPlus />
                      </button>
                    )}
                  </>
                )}
              </td>
              <td style={{ width: 'calc(40% - 35px)' }}>
                <div style={{ color: props.titleColour ? props.titleColour : '' }}>{props.title}</div>
                <div style={{ color: props.subtitleColour ? props.subtitleColour : '' }}>{props.subtitle}</div>
              </td>
              <td className="right" style={{ width: '10%' }}>
                {NumberHelper.isNumeric(props.percentage) ? (
                  <span style={{ color: '#bbbbbb' }}>
                    {' '}
                    {props.percentage == 100 ? 100 : props.percentage.toFixed(1)}%
                  </span>
                ) : null}
              </td>
              <td className="right" style={{ width: '25%' }}>
                <div>
                  {props.people ? (
                    <div>
                      {props.amount}
                      <div>
                        {props.people} <span style={{ color: '#bbbbbb' }}>{props.peopleWording}</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {props.amount}
                      {props.wording && (
                        <span style={{ color: '#bbbbbb' }}>
                          {' '}
                          {StringHelper.AddSWhenMany(props.amount, props.wording)}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </td>
              <td className="right" style={{ width: '25%' }}>
                <div>{props.total}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {showChild && props.child ? (
        <div style={{ paddingLeft: '25px' }} className="animated fadeIn">
          {props.child}
        </div>
      ) : null}
    </>
  );
};

export default EventSummary_TicketSalesRow;
