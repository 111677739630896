import ReactPixel from '@juanlatorre/react-facebook-pixel';

export default class PixelHelper {
  public static initialised = false;

  public static init(id, data, options) {
    const consent = localStorage.getItem('pixel_consent');
    if (consent === 'true') {
      ReactPixel.init(id, data, options);
      PixelHelper.initialised = true;
    }
  }

  public static pageView() {
    if (!PixelHelper.initialised) return;
    ReactPixel.pageView();
  }

  public static trackSingleCustom(id, string, data) {
    if (!PixelHelper.initialised) return;
    ReactPixel.trackSingleCustom(id, string, data);
  }

  public static trackSingle(id, string, data) {
    if (!PixelHelper.initialised) return;
    ReactPixel.trackSingle(id, string, data);
  }
}
