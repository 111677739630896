import React from 'react';
import { Helmet } from 'react-helmet-async';
import Sidebar from './Sidebar';
import Constants from '../../helpers/Constants';

const CookiesPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Seaty - Cookie Policy</title>
        <meta name="description" content={`Seaty.co.uk cookie policy.`} />
      </Helmet>
      <div className="docs">
        <Sidebar />

        <div className="content">
          <article>
            <header>
              <h1>Cookies and Related Technologies</h1>
            </header>

            <section>
              <p>
                When you use the internet your web browser will often store text based files on your computer. These files are called Cookies and are designed to help websites function. Cookies can be
                used in many ways by a website for things such as user account management, storing user preferences and helping a web provider understand how their website is used. This page provides
                information on how Seaty uses cookies and other related technologies to make sure the website is functioning correctly, prevent fraud, and analyse and improve services in accordance
                with our <a href="https://stripe.com/privacy">Privacy Policy</a>.
              </p>

              <h3 id="how-we-use-cookies">1. How Seaty Uses Cookies.</h3>

              <p>
                We use cookies on our website. Our Cookies are used to help provide effective, safe and personal Services. Cookies are changed periodically as we improve or add to our Services, but in
                general we use cookies for the following purposes:
              </p>

              <p>
                <strong>a. Operating Our Services.</strong>
                Some of our cookies are required and essential to the inner workings of our website and Services. These cookies are used in different ways, including:
              </p>

              <ul>
                <li>
                  <strong>User Authentication Cookies.</strong>
                  Cookies are used to remember your login state so you don't have to login every time you go to a different page on the website. As an example, if you log into Seaty to buy a ticket
                  and then click on the "My Tickets" section you won't need to login again.
                </li>

                <li>
                  <strong>Fraud Detection and Prevention Cookies.</strong>
                  We deploy Cookies and related technologies through Seaty to help us learn about how computers and browsers interact and use our Services. Information like this helps us monitor for
                  and detect potentially harmful or illegal use of our website or Services.
                </li>

                <li>
                  <strong>Website Features and Services Cookies.</strong>
                  We often use cookies to remember how you like to use our website. This is so that you don't have to do the same thing over and over such as log into your account. For example, we use
                  a cookie to remember what zoom level you chose for an event seating plan. So you do not have to set it again when you refresh the page. Cookies like this are designed to give you a
                  better and simpler experience when using our website.
                </li>
              </ul>

              <p>
                <strong>b. To Analyse and Improve Seaty.</strong>
                We can use cookies to help us make Seaty work better for you. Cookies can help to tell us how our website is reached by our user base and can give us insights into improvements that we
                can make to help make things better in the future.
              </p>

              <p>
                <strong>c. For Better Advertising.</strong>
                We can use Cookies to help us make advertising more effective on our website. We may use a cookie for something such as preventing you from seeing the same advertisment multiple times
                or measure how many times an avert is seen.
              </p>

              <h3>2. How We Use Related Technologies</h3>

              <p>
                We use Google Analytics, which uses cookies and similar technologies, to collect and Analyse information about use of the Services and report on activities and trends. This service may
                also collect information regarding the use of other websites, apps and online resources. You can learn about Google’s practices on the{' '}
                <a href="https://www.google.com/policies/privacy/partners/">Google website</a>. Please refer to the table below for more on how we use third party analytics.
              </p>

              <h3 id="how-to-manage-cookies">3. How To Manage Cookies</h3>

              <p>
                Your web browser may allow you to update your cookie preferences, including to delete and disable Seaty cookies. You can consult the help section of your web browser or follow the
                links below to understand your options, but please note that if you choose to disable the cookies, some features of our website may not operate as intended.
              </p>

              <ul>
                <li>
                  Chrome - <a href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a>
                </li>
                <li>
                  Firefox - <a href="https://support.mozilla.org/products/firefox/cookies">https://support.mozilla.org/products/firefox/cookies</a>
                </li>
                <li>
                  Safari - <a href="https://support.apple.com/kb/PH21411">https://support.apple.com/kb/PH21411</a>
                </li>
                <li>
                  Opera - <a href="http://www.opera.com/help/tutorials/security/cookies/">http://www.opera.com/help/tutorials/security/cookies/</a>
                </li>
                <li>
                  Explorer - <a href="https://support.microsoft.com/en-us/products/windows?os=windows-10">https://support.microsoft.com/en-us/products/windows?os=windows-10</a>
                </li>
              </ul>

              <h3 id="cookie-table">4. Cookie Table</h3>

              <p>Cookies that we commonly use to run our website are listed below. Please note that this list is not exhaustive, but does describe the main reasons we typically set cookies.</p>

              <h4 id="stripe-cookies">Preference cookies</h4>

              <p>These cookies are used to store general user preferences and error handling data to make your life easier while using the site.</p>

              <table className="alternate">
                <thead>
                  <tr>
                    <th>Cookie Name</th>
                    <th>Purpose</th>
                    <th>Persistent or session</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Constants.CookieAcceptedName}</td>
                    <td>Stores if the cookie policy has been accepted locally</td>
                    <td>Persistent</td>
                  </tr>
                  <tr>
                    <td>booking-admin-menu</td>
                    <td>Only applicable to event admins, stores if you have expanded the admin toolbar in the booking page or not so it can set your last used value when you refresh the page</td>
                    <td>Persistent</td>
                  </tr>
                </tbody>
              </table>

              <h4 id="stripe-cookies">Event specific cookies</h4>

              <p>These cookies are used to store preferences that are specific to events.</p>

              <table className="alternate">
                <thead>
                  <tr>
                    <th>Cookie Name</th>
                    <th>Purpose</th>
                    <th>Persistent or session</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>last-managed-organisation</td>
                    <td>
                      Used to store the last organisation you visited on the dashboard when you are an event administrator. Using this we can then take you right to the same organisation when you want
                      to manage your events in the future without you searching for it every time.
                    </td>
                    <td>Persistent</td>
                  </tr>
                  <tr>
                    <td>eventTag_OrdersAndPaymentsSortDirection</td>
                    <td>Stores sorting direction for orders and payments page</td>
                    <td>Persistent</td>
                  </tr>
                  <tr>
                    <td>eventTag_OrdersAndPaymentsSortBy</td>
                    <td>Stores sorting method for orders and payments page</td>
                    <td>Persistent</td>
                  </tr>
                  <tr>
                    <td>eventTag_HideMoney</td>
                    <td>Remembers if you wish to hide money on the orders and payments page</td>
                    <td>Persistent</td>
                  </tr>
                  <tr>
                    <td>eventTag_HideEmail</td>
                    <td>Remembers if you wish to hide emails on the orders and payments page</td>
                    <td>Persistent</td>
                  </tr>
                  <tr>
                    <td>eventTag_IncludeReferrals</td>
                    <td>Remembers if you wish to hide referrals on the orders and payments page</td>
                    <td>Persistent</td>
                  </tr>
                  <tr>
                    <td>eventTag_ShowOnlyMembers</td>
                    <td>Remembers if you want to show only members on the orders and payments page</td>
                    <td>Persistent</td>
                  </tr>
                  <tr>
                    <td>eventTag-zoom-cookie</td>
                    <td>Remembers your zoom level for a seating plan</td>
                    <td>Persistent</td>
                  </tr>
                </tbody>
              </table>

              <h4 id="third-party-cookies">Third party cookies</h4>

              <table className="alternate">
                <thead>
                  <tr>
                    <th>Cookie Name</th>
                    <th>Purpose</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Google</td>
                    <td>Used for analytics, maps and service improvement</td>
                    <td>
                      <a href="http://tools.google.com/dlpage/gaoptout">Google opt out</a>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>Stripe</td>
                    <td>Used to make payments</td>
                    <td>You cannot opt out of Stripe cookies when using Seaty, they help protect you and us from fraud</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>Facebook</td>
                    <td>Used to help event organisers measure the effectiveness of their events and advertisements</td>
                    <td>
                      <a href="https://www.facebook.com/policy/cookies/">Facebook cookie policy</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default CookiesPage;
