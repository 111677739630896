import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="48px" height="48px">
      <defs>
        <clipPath id="clip-1">
          <path
            d="M0,256v-256h256v256zM212.6576,201l5.73002,-5.73002c3.69126,-3.69126 5.72513,-8.6048 5.72513,-13.82635c0,-5.22155 -2.03386,-10.13509 -5.73002,-13.82635c-3.69126,-3.69615 -8.6048,-5.73002 -13.82635,-5.73002c-5.22155,0 -10.13509,2.03386 -13.82635,5.73002l-5.73002,5.72513l-5.73002,-5.72513c-3.69126,-3.69615 -8.6048,-5.73002 -13.82635,-5.73002c-5.22155,0 -10.13509,2.03386 -13.82635,5.73002c-3.69615,3.69126 -5.73002,8.6048 -5.73002,13.82635c0,5.22155 2.03386,10.13509 5.73002,13.82635l5.72513,5.73002l-5.72513,5.73002c-3.69615,3.69126 -5.73002,8.6048 -5.73002,13.82635c0,5.22155 2.03386,10.13509 5.73002,13.82635c3.69126,3.69615 8.6048,5.73002 13.82635,5.73002c5.22155,0 10.13509,-2.03386 13.82635,-5.73002l5.73002,-5.72513l5.73002,5.73002c3.69126,3.69126 8.6048,5.72513 13.82635,5.72513c5.22155,0 10.13509,-2.03386 13.82635,-5.73002c3.69615,-3.69126 5.73002,-8.6048 5.73002,-13.82635c0,-5.22155 -2.03386,-10.13509 -5.73002,-13.82635z"
            id="overlayBgMask"
            fill="none"
          ></path>
        </clipPath>
      </defs>
      <g
        clip-path="url(#clip-1)"
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style={{ mixBlendMode: 'normal' }}
      >
        <g transform="scale(5.33333,5.33333)" fill="#000000">
          <path d="M37.42578,6.06445c-0.32375,-0.0175 -0.64984,0.03148 -0.96484,0.14648l-0.4707,0.1582l-30.65039,10.82031c-0.46,0.16 -0.83937,0.45055 -1.10937,0.81055h7.84961l22.08008,-7.80078c0.44,1.23 1.59008,2 2.83008,2c0.33,0 0.67,-0.04992 1,-0.16992l2.10938,5.9707h3.17969l-3.45898,-9.80078l-0.16992,-0.46875c-0.22,-0.63 -0.67125,-1.12992 -1.28125,-1.41992c-0.3,-0.145 -0.61961,-0.22859 -0.94336,-0.24609zM34.42188,16.10742c-0.26031,-0.01578 -0.52852,0.02078 -0.79102,0.11328c-0.78,0.28 -1.28031,0.9993 -1.32031,1.7793h3.97852c0,-0.19 -0.02961,-0.37055 -0.09961,-0.56055c-0.2775,-0.78 -0.98664,-1.28469 -1.76758,-1.33203zM24.09961,16.15039c-0.51,0 -1.0293,0.08976 -1.5293,0.25977c-0.88,0.31 -1.62039,0.86984 -2.15039,1.58984h7.56055c-1,-1.17 -2.43086,-1.84961 -3.88086,-1.84961zM7.5,21c-1.36359,0 -2.5,1.13641 -2.5,2.5v16c0,1.36359 1.13641,2.5 2.5,2.5h33c1.36359,0 2.5,-1.13641 2.5,-2.5v-16c0,-1.36359 -1.13641,-2.5 -2.5,-2.5zM11,24h26c0,1.657 1.343,3 3,3v9c-1.657,0 -3,1.343 -3,3h-26c0,-1.657 -1.343,-3 -3,-3v-9c1.657,0 3,-1.343 3,-3zM24,26c-2.761,0 -5,2.462 -5,5.5c0,3.038 2.239,5.5 5,5.5c2.761,0 5,-2.462 5,-5.5c0,-3.038 -2.239,-5.5 -5,-5.5zM24,29c1.084,0 2,1.145 2,2.5c0,1.355 -0.916,2.5 -2,2.5c-1.084,0 -2,-1.145 -2,-2.5c0,-1.355 0.916,-2.5 2,-2.5zM13.5,29.5c-1.10457,0 -2,0.89543 -2,2c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2c0,-1.10457 -0.89543,-2 -2,-2zM34.5,29.5c-1.10457,0 -2,0.89543 -2,2c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2c0,-1.10457 -0.89543,-2 -2,-2z"></path>
        </g>
      </g>
      <g
        fill="#000000"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style={{ mixBlendMode: 'normal' }}
      >
        <g transform="translate(155.66544,171.66544) scale(4.88909,4.88909)" id="overlay">
          <path d="M8.828,6l2.586,-2.586c0.781,-0.781 0.781,-2.047 0,-2.828c-0.781,-0.781 -2.047,-0.781 -2.828,0l-2.586,2.586l-2.586,-2.586c-0.781,-0.781 -2.047,-0.781 -2.828,0c-0.781,0.781 -0.781,2.047 0,2.828l2.586,2.586l-2.586,2.586c-0.781,0.781 -0.781,2.047 0,2.828c0.781,0.781 2.047,0.781 2.828,0l2.586,-2.586l2.586,2.586c0.781,0.781 2.047,0.781 2.828,0c0.781,-0.781 0.781,-2.047 0,-2.828z"></path>
        </g>
      </g>
    </svg>
  );
}) as FunctionComponent<IProps>;
