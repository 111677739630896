import './LazyImage.scss';

import React, { CSSProperties, useEffect, useState } from 'react';

const placeHolder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=';

interface IProps {
  src: string;
  alt: string;
  className?: string;
  style?: CSSProperties;
}

export const LazyImage: React.FC<IProps> = ({ src, alt, className, style }) => {
  const [imageSrc, setImageSrc] = useState(placeHolder);
  const [imageElement, setImageElement] = useState(null);

  const onLoad = (event) => {
    event.target.classList.add('loaded');
  };

  const onError = (event) => {
    event.target.classList.add('has-error');
  };

  useEffect(() => {
    if (imageElement && imageSrc !== src) {
      let observer;
      let didCancel = false;

      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                setImageSrc(src);
                observer.unobserve(imageElement);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          }
        );
        observer.observe(imageElement);
      } else {
        // Old browsers fallback
        setImageSrc(src);
      }
      return () => {
        didCancel = true;
        // on component cleanup, we remove the listner
        if (observer && observer.unobserve) {
          observer.unobserve(imageElement);
        }
      };
    }
  }, [src, imageSrc, imageElement]);

  return <img ref={(el) => setImageElement(el)} style={style} className={`lazy-image${className ? ' ' + className : ''}`} src={imageSrc} alt={alt} onLoad={onLoad} onError={onError} />;
};
