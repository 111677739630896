import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import BlockDatePicker, { IDatePickerValue } from './BlockDatePicker';
import BlockTimePicker, { ITimePickerValue } from './BlockTimePicker';
import './DatePicker.scss';

export interface IDateTimePickerValue {
  moment: moment.Moment;
  isDateTime: boolean;
  allHaveValue: boolean;
  isFuture: boolean;
  date: IDatePickerValue;
  time: ITimePickerValue;
}

export interface IProps {
  groupName: string;
  initialValue?: moment.Moment;
  onChange?(value: IDateTimePickerValue): void;
  disabled?: boolean;
  disableTime?: boolean;
  enforceFuture?: boolean;
  showDateLabel?: boolean;
}

const DateAndTimePicker: React.FC<IProps> = (props) => {
  const [date, setDate] = useState<IDatePickerValue>(
    props.initialValue
      ? {
          allHaveValue: true,
          isDate: true,
          moment: props.initialValue,
          string: props.initialValue.format('DD/MM/YYYY'),
        }
      : null,
  );
  const [time, setTime] = useState<ITimePickerValue>(
    props.initialValue
      ? {
          allHaveValue: true,
          isTime: true,
          moment: props.initialValue,
          string: props.initialValue.format('HH:mm'),
        }
      : null,
  );
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    const isValidDateTime = time && time.isTime && time.allHaveValue && date && date.isDate && date.allHaveValue;
    const mo = isValidDateTime && moment(date.moment.format('YYYY-MM-DD') + ' ' + time.moment.format('HH:mm'));
    const isFuture = isValidDateTime && mo.isAfter();

    const response: IDateTimePickerValue = {
      allHaveValue: date && time && date.allHaveValue && time.allHaveValue,
      isDateTime: date && time && date.isDate && time.isTime,
      moment: isValidDateTime ? mo : null,
      isFuture: isFuture,
      date: date,
      time: time,
    };

    if (initialised) {
      props.onChange(response);
    }
    setInitialised(true);
  }, [date, time]);

  const isValidDateTime = time && time.isTime && time.allHaveValue && date && date.isDate && date.allHaveValue;
  const isFuture = isValidDateTime && moment(date.moment.format('YYYY-MM-DD') + ' ' + time.moment.format('HH:mm')).isAfter();

  const dateFormatted =
    isValidDateTime && (isFuture || !props.enforceFuture) ? (
      props.showDateLabel && (
        <>
          {date.moment.format('dddd Do MMMM YYYY')} at {time.moment.format('h:mma')}
        </>
      )
    ) : !isFuture && props.enforceFuture ? (
      <>Please enter a date in the future</>
    ) : (
      <>Please enter a valid date and time</>
    );

  return (
    <>
      <div className="date-time-picker-container">
        <table className="date-time-picker">
          <tbody>
            <tr>
              <td style={{ width: '60%', paddingRight: '8px' }}>
                <BlockDatePicker
                  disabled={props.disabled}
                  initialValue={props.initialValue}
                  groupName={`DateTime${props.groupName}`}
                  onChange={(_date) => {
                    setDate(_date);
                  }}
                />
              </td>
              <td style={{ width: '40%', paddingLeft: '8px' }}>
                <BlockTimePicker
                  disabled={props.disabled}
                  initialValue={props.initialValue}
                  groupName={`DateTime${props.groupName}`}
                  onChange={(_time) => {
                    setTime(_time);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ fontSize: '14px', padding: '0', paddingTop: '6px', fontStyle: 'italic' }}>{dateFormatted}</div>
      </div>
      {/* 
      <div className="spacer-x05" />
      <Info>{dateFormatted}.</Info>
      <div className="spacer-x05" /> */}
    </>
  );
};

export default DateAndTimePicker;
