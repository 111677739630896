import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AdminApi from '../../../api/AdminApi';
import organisationMenuState from '../../../atoms/organisationMenuState';
import Loader from '../../../components/Loader';
import LocationHelper from '../../../helpers/LocationHelper';
import OrganisationHelper from '../../../helpers/OrganisationHelper';
import StringHelper from '../../../helpers/StringHelper';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import SVGAdmin from '../../../svg/SVGAdmin';
import SVGSave from '../../../svg/SVGSave';
import SVGWarning from '../../../svg/SVGWarning';
import '../Editor.scss';
import { IImageUploadRequest } from '../ImageUploader';
import ThemeSection from '../ThemeSection';
import AwardSection from './AwardSection';
import DeviceSection from './DeviceSection';
import MembershipSection from './MembershipSection';
import OrganisationSection from './OrganisationSection';
import PermissionsSection from './PermissionsSection';
import StripeSection from './StripeSection';
import EmailHelper from '../../../helpers/EmailHelper';
import ImageSection from './ImageSection';
import EventHelper from '../../../helpers/EventHelper';
import Constants from '../../../helpers/Constants';
import PersonnelGroupsSection from '../event/PersonnelGroupsSection';
import SVGOutlineDetails from '../../../svg/outline/SVGOutlineDetails';
import SVGOutlineImage from '../../../svg/outline/SVGOutlineImage';
import SVGOutlineStripe from '../../../svg/outline/SVGOutlineStripe';
import SVGOutlinePermissions from '../../../svg/outline/SVGOutlinePermissions';
import SVGOutlineTheme from '../../../svg/outline/SVGOutlineTheme';
import SVGOutlineAward from '../../../svg/outline/SVGOutlineAward';
import SVGOutlineDevice from '../../../svg/outline/SVGOutlineDevice';
import SVGOutlinePersonnel from '../../../svg/outline/SVGOutlinePersonnel';
import SVGOutlineMembership from '../../../svg/outline/SVGOutlineMembership';
import SideMenu from '../../../components/SideMenu';

export interface IOrganisationWarning {
  title: string;
  text: string;
}

interface IProps {}

export enum Section {
  Organisation = 'Organisation',
  Image = 'Image',
  Theme = 'Theme',
  Stripe = 'Stripe',
  Award = 'Award',
  Permissions = 'Permissions',
  Memberships = 'Memberships',
  Device = 'Device',
  PersonnelGroups = 'Personnel',
}

interface IOrganisationSection {
  heading: string;
  subHeading?: (o: IOrganisation) => React.ReactNode;
  icon: string;
  section: Section;
  visible?: (o: IOrganisation) => boolean;
  svg?: any;
}

const organisationSections: IOrganisationSection[] = [
  {
    heading: 'Organisation',
    subHeading: (o: IOrganisation) => {
      return o && o.Name && <>{o.Name.length > 0 ? <span className="sub">{o.Name}</span> : <span className="sub">Information</span>}</>;
    },
    icon: 'Information',
    section: Section.Organisation,
    svg: <SVGOutlineDetails />,
  },
  {
    heading: 'Image',
    icon: 'Image',
    section: Section.Image,
    svg: <SVGOutlineImage />,
  },
  {
    heading: 'Stripe',
    icon: 'Stripe',
    section: Section.Stripe,
    svg: <SVGOutlineStripe />,
  },
  {
    heading: 'Permissions',
    icon: 'Permissions',
    section: Section.Permissions,
    svg: <SVGOutlinePermissions />,
  },
  {
    heading: 'Theme',
    icon: 'Theme',
    section: Section.Theme,
    svg: <SVGOutlineTheme />,
  },
  {
    heading: 'Membership',
    icon: 'Member',
    section: Section.Memberships,
    svg: <SVGOutlineMembership />,
  },
  {
    heading: 'Awards',
    icon: 'Award',
    section: Section.Award,
    svg: <SVGOutlineAward />,
  },
  {
    heading: 'Devices',
    icon: 'QRCode',
    section: Section.Device,
    svg: <SVGOutlineDevice />,
  },
  {
    heading: 'Personnel',
    subHeading: (e: IOrganisation) => {
      return <span className="sub">{e.PersonnelGroups == null || e.PersonnelGroups.length == 0 ? 'No personnel groups' : e.PersonnelGroups.length + ' personnel groups'}</span>;
    },
    icon: 'Personnel.svg',
    section: Section.PersonnelGroups,
    svg: <SVGOutlinePersonnel />,
  },
];

const OrganisationEditorPage: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [section, setSection] = useState<Section>(Section.Organisation);
  const [busyMessage, setBusyMessage] = useState<string>('Loading organisation...');
  const [imageRequests, setImageRequests] = useState<{ [key: string]: IImageUploadRequest }>({});
  const [organisationHistory, setOrganisationHistory] = useState<IOrganisation[]>([]);
  const [actualOrganisationTag, setActualOrganisationTag] = useState<string>(null);
  const [, setOrganisationMenu] = useRecoilState(organisationMenuState);

  const [organisation, setOrganisationState] = useState<IOrganisation>(null);
  const setOrganisation = (organisation) => {
    setOrganisationHistory([...(organisationHistory.length > 5 ? organisationHistory.filter((e, i) => i != 0) : organisationHistory), JSON.parse(JSON.stringify(organisation))]);
    setOrganisationState(organisation);
  };

  const loadOrganisation = (organisationTag): Promise<any> => {
    setOrganisationHistory([]);
    return AdminApi.request('GET', `/api/OrganisationEdit?organisationTag=${organisationTag}`)
      .then((_org: IOrganisation) => {
        setOrganisationState(_org);
        setBusyMessage(null);
        setActualOrganisationTag(_org.OrganisationTag);

        return _org;
      })
      .catch((message) => {
        alert(message);
      });
  };

  useEffect(() => {
    const urlDetails = LocationHelper.getLocation().pathname;
    const organisationTag = urlDetails.split('/')[2];

    const queryParams = new URLSearchParams(window.location.search);
    const sectionParam: string = queryParams.get('section');

    if (sectionParam && Object.values(Section).includes(sectionParam as any)) {
      setSection(sectionParam as any);
    }

    if (urlDetails.split('/')[1] == 'Organisation' && urlDetails.split('/')[2] == 'Create') {
      const newOrganisation = OrganisationHelper.getDefault();
      setOrganisation(newOrganisation);
      setBusyMessage(null);
      return;
    }

    loadOrganisation(organisationTag);
  }, []);

  const getWarnings = (): IOrganisationWarning[] => {
    if (!organisation) return [];

    const warnings: IOrganisationWarning[] = [];

    if (organisation.Description && EventHelper.getRichTextCharLength(organisation.Description) > Constants.MaxEventDescriptionLength) {
      warnings.push({ title: 'Description too long', text: `You must enter an organisation description of ${Constants.MaxEventDescriptionLength} characters or less.` });
    }

    if (!organisation.Name || organisation.Name.length <= 2) {
      warnings.push({ title: 'Invalid name', text: 'You must enter an organisation name longer than 2 letters.' });
    }

    if (!organisation.StreetAddress || organisation.StreetAddress.length <= 2) {
      warnings.push({ title: 'Invalid street address', text: 'You must enter a valid street address.' });
    }

    if (!organisation.City || organisation.City.length <= 2) {
      warnings.push({ title: 'Invalid city', text: 'You must enter a valid city.' });
    }

    if (!organisation.Postcode || organisation.Postcode.length <= 4) {
      warnings.push({ title: 'Invalid postcode', text: 'You must enter a valid postcode.' });
    }

    if (!EmailHelper.validate(organisation.BoxOfficeEmail)) {
      warnings.push({ title: 'Invalid box office email', text: 'You must enter a valid box office email address.' });
    }

    if (organisation.OrganisationTag == null) {
      organisation.OrganisationTag = '';
    }

    if (!organisation.OrganisationTag || organisation.OrganisationTag.length <= 2) {
      warnings.push({ title: 'Organisation tag length', text: 'You must enter an organisation tag longer than 2 letters.' });
    }

    if (StringHelper.hasSpecialCharacters(organisation.OrganisationTag)) {
      warnings.push({ title: 'Invalid organisation tag', text: 'Organisation tags cannot contain special letters, make sure its just plain text.' });
    }

    return warnings;
  };

  const [optionsOpen, setOptionsOpen] = useState(false);
  const warnings: IOrganisationWarning[] = getWarnings();

  const globalOptions = (
    <>
      <div className={`option dropdown ${warnings.length > 0 ? ' warning' : ''} list${optionsOpen ? ' open' : ''}`}>
        {warnings.length === 0 ? (
          <label>Save</label>
        ) : (
          <label>
            {warnings.length} {StringHelper.AddSWhenMany(warnings.length, 'Task')}
          </label>
        )}

        {optionsOpen && <div className="click-off" onClick={() => setOptionsOpen(false)}></div>}
        <span className="icon">{warnings.length === 0 ? <SVGSave /> : <SVGWarning />}</span>

        <button className="action" disabled={warnings.length > 0 || organisationHistory.length === 0} onClick={() => (warnings.length === 0 ? save() : setOptionsOpen(true))}></button>
        <button className="notch" onClick={() => setOptionsOpen(!optionsOpen)}></button>

        <div className="dropdown-options">
          {warnings.map((warning, index) => (
            <div className="info-row warning" key={index}>
              <span className="dropdown-option-icon">
                <SVGWarning />
              </span>
              <span className="description">{warning.title}</span>
              {warning.text && <p>{warning.text}</p>}
            </div>
          ))}

          {organisation && organisation.Id && organisation.Id > 0 && actualOrganisationTag && (
            <button
              onClick={() => {
                setOrganisationMenu({ ...organisation, OrganisationTag: actualOrganisationTag });
                setOptionsOpen(false);
              }}
            >
              <span className="dropdown-option-icon">
                <SVGAdmin />
              </span>
              <span className="description">Options</span>
              <p>Open the administrator options dialog.</p>
            </button>
          )}
        </div>
      </div>
    </>
  );

  const onImageRequested = (request: IImageUploadRequest) => {
    imageRequests[request.group] = request;
    setImageRequests({ ...imageRequests });
    setOrganisationHistory([...(organisationHistory.length > 5 ? organisationHistory.filter((e, i) => i != 0) : organisationHistory), JSON.parse(JSON.stringify(organisation))]);
  };

  const sections: { [key: string]: any } = {};
  sections[Section.Image] = <ImageSection globalOptions={globalOptions} onImageRequested={onImageRequested} imageRequests={imageRequests} organisation={organisation} />;
  sections[Section.Organisation] = (
    <OrganisationSection
      setBusyMessage={setBusyMessage}
      globalOptions={globalOptions}
      onImageRequested={onImageRequested}
      imageRequests={imageRequests}
      organisation={organisation}
      onOrganisationUpdated={(e: IOrganisation) => setOrganisation(e)}
    />
  );
  sections[Section.Theme] = (
    <ThemeSection
      onImageRequested={onImageRequested}
      imageRequests={imageRequests}
      setBusyMessage={setBusyMessage}
      globalOptions={globalOptions}
      organisation={organisation}
      onOrganisationUpdated={(e: IOrganisation) => setOrganisation(e)}
    />
  );

  sections[Section.Device] = <DeviceSection globalOptions={globalOptions} organisation={organisation} onOrganisationUpdated={(e: IOrganisation) => setOrganisation(e)} />;

  sections[Section.Award] = <AwardSection globalOptions={globalOptions} organisation={organisation} onOrganisationUpdated={(e: IOrganisation) => setOrganisation(e)} />;

  sections[Section.Permissions] = <PermissionsSection globalOptions={globalOptions} organisation={organisation} onOrganisationUpdated={(e: IOrganisation) => setOrganisation(e)} />;

  sections[Section.Memberships] = <MembershipSection globalOptions={globalOptions} organisation={organisation} onOrganisationUpdated={(e: IOrganisation) => setOrganisation(e)} />;

  sections[Section.PersonnelGroups] = (
    <PersonnelGroupsSection
      onImageRequested={onImageRequested}
      imageRequests={imageRequests}
      globalOptions={globalOptions}
      organisation={organisation}
      onOrganisationUpdated={(e: IOrganisation) => setOrganisation(e)}
    />
  );

  sections[Section.Stripe] = <StripeSection globalOptions={globalOptions} organisation={organisation} />;

  const save = () => {
    return OrganisationHelper.save(organisation, setBusyMessage, imageRequests)
      .then((o) => {
        setOrganisationHistory([]);
        setImageRequests({});
        setBusyMessage('All done!');

        navigate('/Organisation/' + o.OrganisationTag + '/Edit');

        window.setTimeout(() => {
          setBusyMessage('Reloading...');
          loadOrganisation(o.OrganisationTag);
        }, 1000);
      })
      .catch((message) => {
        alert(message);
        setBusyMessage(null);
      });
  };

  return (
    <>
      <Helmet>
        <title>{busyMessage ? 'Organisation Editor' : organisation && organisation.Id > 0 ? `Edit #${organisation.OrganisationTag}` : `Create Organisation`}</title>
        <meta name="description" content={`Edit your organisation details.`} />
      </Helmet>

      {busyMessage && <Loader inline>{busyMessage}</Loader>}

      {!busyMessage && organisation && (
        <div className="admin-dashboard-editor">
          <div className="row">
            <div className="col-sm-3 col-md-2  donotprint">
              <SideMenu navigator>
                {organisationSections.map((s: IOrganisationSection) => (
                  <button
                    key={s.section}
                    style={{ display: s.visible ? (!s.visible(organisation) ? 'none' : null) : null }}
                    className={`${section == s.section ? 'selected' : ''}${s.svg ? ' has-image' : ''}`}
                    onClick={() => {
                      setSection(s.section);
                      LocationHelper.updateParam('section', s.section);
                    }}
                  >
                    {s.svg}
                    {s.heading}
                  </button>
                ))}
              </SideMenu>
            </div>
            <div className="col-sm-9 col-md-10  donotprint">{sections[section]}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrganisationEditorPage;
