import { FunctionComponent } from 'react';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';

interface IProps {
  organisationName: string;
  onClick: () => void;
  isMember: boolean;
  memberTypeName: string;
  quota?: number;
}

const MembershipButton: FunctionComponent<IProps> = (props) => {
  return (
    <Block className="route" onClick={props.onClick}>
      <BlockHeader>Membership</BlockHeader>
      {!props.isMember ? (
        `Not a member of ${props.organisationName}`
      ) : (
        <div>
          {props.memberTypeName} {props.quota != null && <span style={{ opacity: '0.5' }}>{props.quota == 0 ? 'No' : props.quota} quota</span>}
        </div>
      )}
    </Block>
  );
};

export default MembershipButton;
