import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
      <path
        d="M12.5,4C10,4,8,6,8,8.5v31C8,42,10,44,12.5,44h23c2.5,0,4.5-2,4.5-4.5v-21c0-0.4-0.2-0.8-0.4-1.1l0,0l-13-13
	C26.3,4.2,25.9,4,25.5,4H12.5z M12.5,7H24v8.5c0,2.5,2,4.5,4.5,4.5H37v19.5c0,0.8-0.7,1.5-1.5,1.5h-23c-0.8,0-1.5-0.7-1.5-1.5v-31
	C11,7.7,11.7,7,12.5,7z M27,9.1l7.9,7.9h-6.4c-0.8,0-1.5-0.7-1.5-1.5V9.1z"
      />
    </svg>
  );
}) as FunctionComponent<IProps>;
