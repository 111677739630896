import { FunctionComponent, useEffect, useState } from 'react';
import ThemeHelper from '../helpers/ThemeHelper';
import Modal from './Modal';
import AdminMenuEvent, { IAdminMenuEventProps } from './AdminMenuEvent';
import AdminApi from '../api/AdminApi';
import loaderState, { Loader, LoaderHelper } from '../atoms/loaderState';
import { useRecoilState } from 'recoil';
import CacheHelper from '../helpers/CacheHelper';
import { IEventDate } from '../interfaces/IEventDate';
import AdminMenuOrganisation from './AdminMenuOrganisation';
import AdminMenuTour from './AdminMenuTour';

export interface IAdminMenuEventModalProps {
  eventTag?: string;
  onCloseClick?: () => void;
  eventDate?: IEventDate;
}

const AdminMenuEventModal: FunctionComponent<IAdminMenuEventModalProps> = (props) => {
  const { onCloseClick } = props;
  const [loaders, setLoaders] = useRecoilState(loaderState);
  const [requestCount, setRequestCount] = useState(0);
  const [event, setEvent] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [tour, setTour] = useState(null);

  const refreshRequestCount = (tag) => {
    if (!tag) {
      setRequestCount(0);
      return;
    }

    AdminApi.request('GET', `/api/RequestCount?eventTag=${tag}`).then((response) => {
      setRequestCount(response);
    });
  };

  useEffect(() => {
    if (!event && props.eventTag) {
      LoaderHelper.add(setLoaders, loaders, Loader.AdminMenuEvent, 'Loading event...');

      CacheHelper.eventByTag(props.eventTag).then((e) => {
        setEvent(e);
        LoaderHelper.add(setLoaders, loaders, Loader.AdminMenuOrganisation, 'Loading organisation...');

        CacheHelper.organisationByTag(e.Organisation.OrganisationTag).then((o) => {
          setOrganisation(o);
          LoaderHelper.remove(setLoaders, loaders, Loader.AdminMenuOrganisation);
        });

        if (e.TourId) {
          LoaderHelper.add(setLoaders, loaders, Loader.AdminMenuTour, 'Loading tour...');

          CacheHelper.tourById(e.TourId).then((t) => {
            setTour(t);
            LoaderHelper.remove(setLoaders, loaders, Loader.AdminMenuTour);
          });
        }

        LoaderHelper.remove(setLoaders, loaders, Loader.AdminMenuEvent);
      });

      refreshRequestCount(props.eventTag);
    } else {
      refreshRequestCount(event.EventTag);
    }
  }, []);

  const theme = ThemeHelper.getEventTheme(event);

  return (
    <Modal className="admin-menu-modal" theme={theme} onCloseClick={onCloseClick}>
      {event && <AdminMenuEvent onCloseClick={onCloseClick} requestCount={requestCount} event={event} organisation={organisation} tour={tour} {...props} />}
      {tour && <AdminMenuTour onCloseClick={onCloseClick} tour={tour} />}
      {organisation && <AdminMenuOrganisation onCloseClick={onCloseClick} organisation={organisation} />}
    </Modal>
  );
};

export default AdminMenuEventModal;
