import React, { useEffect, useState } from 'react';
import AdminApi from '../../../api/AdminApi';
import { IEventDate } from '../../../interfaces/IEventDate';
import DateAndTimeListItem from '../event/DateAndTimeListItem';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  eventDate: IEventDate;
  onEventDateUpdated: (e: IEventDate) => void;
  globalOptions: JSX.Element;
}

const DateSection: React.FC<IProps> = (props) => {
  const { eventDate, onEventDateUpdated } = props;

  const [timeZones, setTimeZones] = useState(null);

  useEffect(() => {
    AdminApi.request('GET', '/api/TimeZone').then((result) => {
      setTimeZones(result);
    });
  }, []);

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>

      <DateAndTimeListItem showAll date={eventDate} onChange={(date) => {}} onCopyClick={null} onDeleteClick={null} />
    </>
  );
};

export default DateSection;
