import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
      {' '}
      <path d="M 2 2 A 1.0001 1.0001 0 1 0 2 4 L 3.8789062 4 C 4.1048086 4 4.2933102 4.1414634 4.3574219 4.359375 L 8.6191406 18.847656 C 8.993174 20.11937 10.169409 21 11.496094 21 L 22.472656 21 C 23.821072 21 25.011657 20.088693 25.367188 18.789062 L 27.964844 9.2636719 A 1.0001 1.0001 0 0 0 27 8 L 7.5136719 8 L 6.2773438 3.7949219 A 1.0001 1.0001 0 0 0 6.2753906 3.7949219 C 5.9634991 2.7348335 4.9830041 2 3.8789062 2 L 2 2 z M 19 10 C 19.25575 10 19.511531 10.097469 19.707031 10.292969 L 22.705078 13.292969 C 22.798078 13.384969 22.872828 13.495141 22.923828 13.619141 C 23.024828 13.862141 23.024828 14.138813 22.923828 14.382812 C 22.872828 14.505812 22.797078 14.615031 22.705078 14.707031 L 19.707031 17.707031 C 19.512031 17.902031 19.256 18 19 18 C 18.744 18 18.487969 17.902031 18.292969 17.707031 C 17.901969 17.316031 17.901969 16.683969 18.292969 16.292969 L 19.585938 15 L 13 15 C 12.447 15 12 14.553 12 14 C 12 13.447 12.447 13 13 13 L 19.585938 13 L 18.292969 11.707031 C 17.901969 11.316031 17.901969 10.683969 18.292969 10.292969 C 18.488469 10.097469 18.74425 10 19 10 z M 12 23 A 2 2 0 0 0 10 25 A 2 2 0 0 0 12 27 A 2 2 0 0 0 14 25 A 2 2 0 0 0 12 23 z M 22 23 A 2 2 0 0 0 20 25 A 2 2 0 0 0 22 27 A 2 2 0 0 0 24 25 A 2 2 0 0 0 22 23 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
