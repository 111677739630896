import linq from 'linq';
import { FunctionComponent, useState } from 'react';
import SVGMultiply from '../../svg/SVGMultiply';
import SVGArrow from '../../svg/SVGNext';
import SVGPrevious from '../../svg/SVGPrevious';
import './AdminMenuDropdown.scss';
import usePortal from '../../components/usePortal';
import { createPortal } from 'react-dom';

export enum Orientation {
  Left = 'left',
  Right = 'right',
}

interface IDropDownOption {
  id: number;
  name: string;
  category?: string;
  onClick?: () => void;
  icon?: any;
  orientation?: Orientation;
}

interface IAdminMenuDropdownProps {
  title?: string;
  value?: number;
  icon?: any;
  selectNoneOption?: IDropDownOption;
  options?: IDropDownOption[];
  onValueChange?: (value: number) => void;
  valueText?: string;
  placeholder?: string;
  onClear?: () => void;
  right?: boolean;
  buttons?: { icon?: any; name: string; onClick: () => void }[];
  className?: string;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
}

interface AdminMenuDropdownMenuProps {
  setShowMenu: any;
  buttons?: { icon?: any; name: string; onClick: () => void }[];
  options?: IDropDownOption[];
  selectNoneOption?: IDropDownOption;
  onValueChange?: (value: number) => void;
}

const AdminMenuDropdownMenu: FunctionComponent<React.PropsWithChildren<AdminMenuDropdownMenuProps>> = (props) => {
  const target = usePortal('modals-are-us');
  const { setShowMenu } = props;

  const [filter, setFilter] = useState('');
  const [query, setQuery] = useState('');

  const orderedOptions = linq.from(props.options).where((o) => {
    return filter.length == 0 || o.name.toLowerCase().includes(filter.toLowerCase());
  });

  const onFilterKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      setFilter(query);
    }
  };

  const clearFilterClicked = () => {
    setFilter('');
    setQuery('');
  };

  const handleSearchChanged = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <div onClick={() => setShowMenu(false)} className="admin-header-dropdown_open"></div>
      <div className="admin-header-dropdown-options">
        {orderedOptions && (orderedOptions.count() > 6 || filter.length > 0) && (
          <div className="input">
            <input
              autoComplete="off"
              autoFocus
              id="searchinput"
              onChange={handleSearchChanged}
              type="text"
              placeholder="Search..."
              value={query}
              onKeyPress={onFilterKeyPress.bind(this)}
              style={{ margin: '0' }}
            />

            <button onClick={clearFilterClicked}>
              <SVGMultiply />
            </button>
          </div>
        )}

        {props.selectNoneOption && (
          <button
            id={`dropdown_option_${props.selectNoneOption}`}
            className="admin-header-dropdown-options-option"
            onClick={() => {
              props.onValueChange(props.selectNoneOption.id);
              setShowMenu(false);
            }}
          >
            {props.selectNoneOption.name}
          </button>
        )}

        {props.children && <div className="admin-header-dropdown-children">{props.children}</div>}

        {props.buttons &&
          props.buttons.map((button) => (
            <button
              id={`dropdown_option_${button.name}`}
              key={button.name}
              className={`admin-header-dropdown-options-option ${button.icon ? ' has-icon' : ''}`}
              onClick={() => {
                if (button.onClick) {
                  button.onClick();
                }
                setShowMenu(false);
              }}
            >
              {button.icon}
              {button.name}
            </button>
          ))}

        {orderedOptions
          .select((o) => {
            const orientation = o.orientation ? o.orientation : Orientation.Left;

            return (
              <button
                id={`dropdown_option_${o.id}`}
                key={o.id}
                style={{ textAlign: orientation == Orientation.Left ? 'left' : 'right' }}
                className={`admin-header-dropdown-options-option${o.icon ? (orientation == Orientation.Left ? ' has-icon' : ' has-icon-right') : ''}`}
                onClick={() => {
                  if (o.onClick) {
                    o.onClick();
                  } else {
                    props.onValueChange(o.id);
                  }
                  setShowMenu(false);
                }}
              >
                {o.icon}
                {o.name}
                {o.child}
              </button>
            );
          })
          .toArray()}
      </div>
    </>
  );
};

const AdminMenuDropdown: FunctionComponent<React.PropsWithChildren<IAdminMenuDropdownProps>> = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const selectedOption = linq.from(props.options).firstOrDefault((o) => o.id == props.value);

  const selectedText = props.valueText ? props.valueText : selectedOption ? selectedOption.name : null;

  return (
    <div
      className={
        'admin-header-dropdown' +
        (props.className ? ' ' + props.className : '') +
        (selectedText == null ? ' no-value' : '') +
        (props.icon == null ? '' : ' has-icon') +
        (props.right == null ? '' : ' has-right')
      }
    >
      <div className="admin-header-dropdown-title">
        <span>
          {props.title}{' '}
          {props.onClear && (
            <button onClick={props.onClear} className="admin-header-button admin-header-dropdown-mini-button">
              <SVGMultiply />
            </button>
          )}
          {props.onNextClick && (
            <button onClick={props.onNextClick} className="admin-header-button admin-header-dropdown-mini-button _next">
              <SVGArrow />
            </button>
          )}
          {props.onPreviousClick && (
            <button onClick={props.onPreviousClick} className="admin-header-button admin-header-dropdown-mini-button _previous">
              <SVGPrevious />
            </button>
          )}
        </span>
      </div>
      <button
        className="admin-header-dropdown-value"
        onClick={() => {
          setShowMenu(true);

          if (selectedOption) {
            window.setTimeout(() => {
              var optionId = `dropdown_option_${selectedOption.id}`;
              var el = document.getElementById(optionId);
              if (el) {
                el.focus();
              }
            }, 25);
          }
        }}
      >
        {props.icon ? <>{props.icon}</> : <>{props.valueText ? props.valueText : selectedOption ? selectedOption.name : props.placeholder ? props.placeholder : '...'}</>}
      </button>

      {showMenu && <AdminMenuDropdownMenu setShowMenu={setShowMenu} {...props} />}
    </div>
  );
};

export default AdminMenuDropdown;
