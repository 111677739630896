import React from 'react';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import { IEvent } from '../../../interfaces/IEvent';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const MarketingSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Marketing & Surveys</h2>
            <div className="section event-information">
              <div className="section">
                <InfoLabel
                  text="Ask attendees if you can use their email address to contact them with a one off survey about this event?"
                  tooltip="If you enable this option then every time an order is in progress the attendee, or admin, will be asked if it is okay for your organisation to use their email address after the event has ended with a one off survey about the event."
                />

                <Switch trueLabel="Yes, ask them" falseLabel="No, do not ask" onChange={(value) => onEventUpdated({ ...event, AllowSurvey: value })} checked={event.AllowSurvey}></Switch>
              </div>
              <div className="section">
                <InfoLabel
                  text="Ask attendees if you can use their email address to contact them about current and future shows?"
                  tooltip="If you enable this option then every time an order is in progress the attendee, or admin, will be asked if it is okay for your organisation to use their email address for marketing purposes for current and future events."
                />

                <Switch trueLabel="Yes, ask them" falseLabel="No, do not ask" onChange={(value) => onEventUpdated({ ...event, AllowMarketing: value })} checked={event.AllowMarketing}></Switch>

                <InfoLabel
                  text="Do you have a Facebook Ads Pixel ID?"
                  tooltip="Visit Facebooks Business documentation on creating your own event pixel. This can then be used to track throuoghput from your Facebook Ads to Seaty. We will tell Facebook whenever somebody buys tickets for your events to help you better target your audience."
                />
                <InfoInput labelText="Pixel ID" onChange={(value) => onEventUpdated({ ...event, FacebookPixelId: value })} value={event.FacebookPixelId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingSection;
