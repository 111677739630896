import React from 'react';
import EnvironmentVariables from '../../../EnvironmentVariables';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  organisation: IOrganisation;
  globalOptions: JSX.Element;
}

const StripeSection: React.FC<IProps> = (props) => {
  const { organisation } = props;
  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-6">
          {!organisation.StripeConnectEnabled ? (
            <div className="fields">
              <h2>Stripe</h2>
              Stripe connect is not enabled for this organisation. If you would like to use Stripe to take payments directly to your own Stripe account contact Support@Seaty.co.uk
            </div>
          ) : organisation.Id === 0 || organisation.Id === null ? (
            <div className="fields">
              <h2>Save required</h2>Before you can connect to Stripe you must first save your organisation.
            </div>
          ) : (
            <div className="fields">
              <h2>Stripe Connect</h2>
              {organisation.StripeAccountId == null ? (
                <div className="section" style={{ marginBottom: '0' }}>
                  <div style={{ paddingBottom: '15px' }}>
                    Connect your organisation's Stripe account to Seaty.
                    <br />
                    <br />
                    You will receive payments for orders as they come in directly to your Stripe account via Stripe Connect.
                    <br />
                    <br />
                    In connecting your organisations account via Stripe connect you agree to assume all responsibility for fully refunding an event should it be cancelled. You must also agree to
                    retain money for refunds due to customers. Seaty accepts no liability for owing any money back to attendees that you have taken via Stripe connect. By connecting or clicking the
                    button below you agree to these terms as a representative of your organisation.
                  </div>

                  <a
                    href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${EnvironmentVariables.STRIPE_CLIENT_ID}&scope=read_write&state=${organisation.Guid}&redirect_uri=${window.location.origin}`}
                    className="stripe-connect"
                  ></a>
                </div>
              ) : (
                <div className="section">Your organisation's Stripe account is connected to Seaty.</div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StripeSection;
