import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 23.978516 4 A 1.50015 1.50015 0 0 0 22.939453 4.4394531 L 16.939453 10.439453 A 1.50015 1.50015 0 1 0 19.060547 12.560547 L 22.498047 9.1230469 L 22.480469 29.498047 A 1.50015 1.50015 0 1 0 25.480469 29.501953 L 25.498047 9.1191406 L 28.939453 12.560547 A 1.50015 1.50015 0 1 0 31.060547 10.439453 L 25.060547 4.4394531 A 1.50015 1.50015 0 0 0 23.978516 4 z M 17.509766 15.638672 A 1.50015 1.50015 0 0 0 16.777344 15.851562 C 12.13986 18.404235 9 23.346578 9 29 C 9 37.266622 15.733378 44 24 44 C 32.266622 44 39 37.266622 39 29 C 39 23.346578 35.86014 18.404235 31.222656 15.851562 A 1.50015 1.50015 0 0 0 30.507812 15.654297 A 1.50015 1.50015 0 0 0 29.777344 18.478516 C 33.48586 20.519844 36 24.455422 36 29 C 36 35.645378 30.645378 41 24 41 C 17.354622 41 12 35.645378 12 29 C 12 24.455422 14.51414 20.519844 18.222656 18.478516 A 1.50015 1.50015 0 0 0 17.509766 15.638672 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
