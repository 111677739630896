import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ITheme } from '../interfaces/ITheme';
import '../less/modal.scss';
import Theme from './Theme';
import usePortal from './usePortal';

export interface IProps {
  theme?: ITheme;
  onCloseClick?(): void;
  className?: string;
  hideHeader?: boolean;
}

const Modal: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const target = usePortal('modals-are-us');
  var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)

  useEffect(() => {
    document.body.classList.add('modal-open');
    root.classList.add('modal-open');

    return () => {
      document.body.classList.remove('modal-open');
      root.classList.remove('modal-open');
    };
  }, []);

  return createPortal(
    <>
      <div className="seaty-modal-container">
        <Theme
          hideHeader={props.hideHeader}
          headerChildren={
            props.onCloseClick && (
              <div
                className="close"
                onClick={() => {
                  var body = document.body;
                  root.classList.remove('modal-open');
                  body.classList.remove('modal-open');
                  props.onCloseClick();
                }}
              ></div>
            )
          }
          backgroundClassName={`seaty-modal-background${props.theme && props.theme.ShowRadial ? ' show-radial' : ''}`}
          className={'seaty-modal' + (props.className ? ' ' + props.className : '')}
          theme={props.theme}
        >
          {props.children}
        </Theme>
      </div>
    </>,
    target,
  );
};

export default Modal;
