import React, { FunctionComponent, useEffect, useState } from 'react';
import Block from '../components/Block';
import Button from '../components/Button';
import Modal from '../components/Modal';
import SpacerTable from '../components/SpacerTable';
import GAHelper from '../helpers/GAHelper';
import SolidLine from '../components/SolidLine';
import CheckBox from '../components/CheckBox';
import BlockInfo from '../components/BlockInfo';
import PixelHelper from '../helpers/PixelHelper';
import Constants from '../helpers/Constants';

export interface IProps {
  onAccept: () => void;
  onRefuse: () => void;
}

const CookieModal = ((props) => {
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [useGoogleAnalytics, setUseGoogleAnalytics] = useState(false);
  const [useFacebookPixel, setUseFacebookPixel] = useState(false);

  useEffect(() => {
    GAHelper.modal('Cookie');
  }, []);

  const acceptAll = () => {
    localStorage && localStorage.setItem(Constants.CookieAcceptedName, 'true');
    localStorage && localStorage.setItem('ga_consent', 'true');
    localStorage && localStorage.setItem('pixel_consent', 'true');

    GAHelper.init();
    PixelHelper.initialised = true;
    props.onAccept();

    if (window.location.pathname !== '/') {
      window.location.reload();
    }
  };

  const acceptSelected = () => {
    localStorage && localStorage.setItem(Constants.CookieAcceptedName, 'true');
    if (useGoogleAnalytics) {
      localStorage && localStorage.setItem('ga_consent', 'true');
      GAHelper.init();
    } else {
      localStorage && localStorage.setItem('ga_consent', 'false');
    }

    if (useFacebookPixel) {
      localStorage && localStorage.setItem('pixel_consent', 'true');
      PixelHelper.initialised = true;
    } else {
      localStorage && localStorage.setItem('pixel_consent', 'false');
    }

    props.onAccept();
  };

  return (
    <Modal className="">
      {!showMoreOptions ? (
        <div className="welcomemodal content">
          <div className="ticket-rip" />
          <div className="welcomeimage">
            <img className="" alt="Seaty made with love in Britain" src="https://seatylive.blob.core.windows.net/images/SeatyCookies.png" />
          </div>
          <div className="body">
            <SpacerTable>
              <h1>Events, one cookie at a time!</h1>
            </SpacerTable>

            <div className="spacer" />

            <table className="blocks">
              <tbody>
                <Block>
                  Seaty uses cookies as specified in our <a href="/Docs/Cookies">Cookie Policy</a> in order to give you the best experience we can.
                  <br />
                  <br />
                  If this is okay for you, simply click on "Alrighty!" to confirm that you agree to the use of cookies for preferences, security, statistics and marketing.
                </Block>
              </tbody>
            </table>

            <div className="spacer-x2" />

            <SpacerTable>
              <Button className="confirm" onExecute={acceptAll} text="Alrighty!" />
              <Button
                onExecute={() => {
                  setShowMoreOptions(true);
                }}
                text="More options"
              />
            </SpacerTable>
            <SolidLine />

            <SpacerTable className="small-font">
              For more information, see the Seaty <a href="/Docs/Cookies">Cookie Policy</a>, <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and{' '}
              <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
            </SpacerTable>
          </div>

          <div className="ticket-rip bottom" />
        </div>
      ) : (
        <div className="content">
          <div className="ticket-rip" />

          <div className="body">
            <SpacerTable>
              <h1>All cookie preferences</h1>
            </SpacerTable>

            <div className="spacer" />

            <table className="blocks">
              <tbody>
                <BlockInfo>
                  You are totally in control of your privacy and it is up to you which cookies Seaty may and may not use. You can agree to the usage of all cookies stated down below by clicking
                  "Accept all".
                </BlockInfo>
              </tbody>
            </table>

            <div className="spacer-x2" />

            <SpacerTable>
              <Button className="confirm" onExecute={acceptAll} text="Accept all" />
            </SpacerTable>

            <div className="spacer" />

            <table className="blocks">
              <tbody>
                <CheckBox title="General settings (Mandatory)" checked={true}>
                  We remember your personal settings here so that you don't have to make them again each time you visit - e.g. for user authentication security.
                </CheckBox>
                <CheckBox title="Stripe.com (Mandatory)" checked={true}>
                  Stripe.com, as a payment gateway provider, uses cookies for various essential purposes to ensure the security and functionality of their platform. Enabling these cookies is mandatory
                  for security reasons because they play a critical role in protecting sensitive payment data, preventing fraud, and ensuring the smooth and secure operation of the payment gateway.
                </CheckBox>
                <CheckBox title="Google analytics" onBoxClick={() => setUseGoogleAnalytics(!useGoogleAnalytics)} checked={useGoogleAnalytics}>
                  We use Google Analytics to better understand the way the Seaty is used. Google Analytics uses the information collected on behalf of Seaty to analyze use of the web site, to generate
                  reports on web site activities, and to provide other services related to web site and internet use to Seaty as the operator of the web site. This does not involve the transmission of
                  personal data to Google, and all data saved by Google is anonymized.
                </CheckBox>
                <CheckBox title="Meta pixel" onBoxClick={() => setUseFacebookPixel(!useFacebookPixel)} checked={useFacebookPixel}>
                  On our website, we use the Meta Pixel. The Meta Pixel is a remarketing pixel implemented on our websites that allows us to target you directly via the Meta Network by serving ads to
                  visitors of our websites when they visit the social networks Facebook and Instagram. The meta pixel are code snippets which are able to identify your browser type via the browser ID
                  - the individual fingerprint of your browser - and to recognise that you have visited our websites and what exactly you have looked at on our websites. When you visit our websites,
                  the pixel establishes a direct connection to Meta's servers. Meta is able to identify you by your browser ID, as this is linked to other data about you stored by Meta on your
                  Facebook or Instagram user account. Meta then delivers individualised ads from us on Facebook or on Instagram that are tailored to your needs. We ourselves are not in a position to
                  identify you personally via the meta pixel, as apart from your browser ID no other data is stored with us via the pixel. For more information about the Meta Pixel, the details of
                  data processing via this service and Meta's privacy policy, please visit{' '}
                  <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Meta Privacy Policy - How Meta collects and uses user data for Facebook</a> and{' '}
                  <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Meta Privacy Policy - How Meta collects and uses user data for Instagram.</a>.<br />
                  <br /> Right of withdrawal - You can find out how to withdraw the consent you have given via our cookie layer in the section "Withdrawal of consent given for the use of cookies and
                  other identifiers/tags". Meta Platforms Ireland Ltd. is a subsidiary of Meta Platforms, Inc. based in the USA. It cannot be ruled out that your data collected by Facebook will also
                  be transmitted to the USA.
                </CheckBox>
              </tbody>
            </table>

            <div className="spacer-x2" />

            <SpacerTable>
              <Button className="confirm" onExecute={acceptAll} text="Accept all" />
              <Button onExecute={acceptSelected} text="Accept selected" />
            </SpacerTable>

            <SolidLine />

            <SpacerTable className="small-font">
              For more information, see the Seaty <a href="/Docs/Cookies">Cookie Policy</a>, <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and{' '}
              <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
            </SpacerTable>
          </div>

          <div className="ticket-rip bottom" />
        </div>
      )}
    </Modal>
  );
}) as FunctionComponent<IProps>;

export default CookieModal;
