import linq from 'linq';
import moment from 'moment';
import React from 'react';
import DatePicker from '../../../components/DatePicker';
import InfoInput from '../../../components/InfoInput';
import DateHelper from '../../../helpers/DateHelper';
import GuidHelper from '../../../helpers/GuidHelper';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import SVGAward from '../../../svg/SVGAward';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  organisation: IOrganisation;
  onOrganisationUpdated: (e: IOrganisation) => void;
  globalOptions: JSX.Element;
}

const AwardSection: React.FC<IProps> = (props) => {
  const { organisation, onOrganisationUpdated } = props;

  const addAward = () => {
    var newAward = {
      Id: 0,
      DateAsString: DateHelper.getFormattedDate() + ' ' + DateHelper.getFormattedTime().split('.')[0],
      Description: '',
      Guid: GuidHelper.new(),
    };

    organisation.Awards.push(newAward);
    onOrganisationUpdated({ ...organisation });
  };

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}
          <button className="option" onClick={addAward}>
            <label>Add Award</label>
            <div className="icon">
              <SVGAward />
            </div>
          </button>
        </div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Awards</h2>
            {organisation.Awards.map((award, index) => {
              award.Guid = award.Guid && award.Guid.length > 0 ? award.Guid : GuidHelper.new();
              return (
                <div className="field element" key={'event_award_list_item_' + award.Guid}>
                  <DatePicker
                    disableTime={true}
                    onDateChange={(date) => {
                      award.DateAsString = date.format('YYYY-MM-DD HH:mm:ss.SSS');
                      onOrganisationUpdated({ ...organisation });
                    }}
                    date={moment(award.DateAsString)}
                  />
                  <InfoInput
                    maxLength={1000}
                    rows={4}
                    labelText="Description"
                    onChange={(value) => {
                      award.Description = value;
                      onOrganisationUpdated({ ...organisation });
                    }}
                    value={award.Description}
                  />
                  <button
                    className="danger admin-button"
                    onClick={() => {
                      var remainingAwards = linq
                        .from(organisation.Awards)
                        .where((a) => a.Guid != award.Guid)
                        .toArray();

                      organisation.Awards = remainingAwards;
                      onOrganisationUpdated({ ...organisation });
                    }}
                  >
                    Delete
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="info">Here you can add awards your organisation has received that will be shown on your organisation page. These are purely visual. </div>
        </div>
      </div>
    </>
  );
};

export default AwardSection;
