import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 24.052734 3 C 23.870581 2.9980964 23.68625 3.00525 23.5 3.0234375 C 20.903 3.2774375 19 5.6126562 19 8.2226562 L 19 15 C 19 16.105 19.895 17 21 17 L 27 17 C 28.105 17 29 16.105 29 15 L 29 8 C 29 5.260625 26.785034 3.0285535 24.052734 3 z M 24 6 C 25.105 6 26 6.896 26 8 C 26 9.104 25.105 10 24 10 C 22.895 10 22 9.104 22 8 C 22 6.896 22.895 6 24 6 z M 7.5 12 C 5.0324991 12 3 14.032499 3 16.5 L 3 37.5 C 3 39.967501 5.0324991 42 7.5 42 L 40.5 42 C 42.967501 42 45 39.967501 45 37.5 L 45 16.5 C 45 14.032499 42.967501 12 40.5 12 L 31 12 L 31 15 L 40.5 15 C 41.346499 15 42 15.653501 42 16.5 L 42 37.5 C 42 38.346499 41.346499 39 40.5 39 L 7.5 39 C 6.6535009 39 6 38.346499 6 37.5 L 6 16.5 C 6 15.653501 6.6535009 15 7.5 15 L 17 15 L 17 12 L 7.5 12 z M 15 21 A 3 3 0 0 0 15 27 A 3 3 0 0 0 15 21 z M 26.5 23 A 1.50015 1.50015 0 1 0 26.5 26 L 36.5 26 A 1.50015 1.50015 0 1 0 36.5 23 L 26.5 23 z M 11.5 29 C 10.672 29 10 29.672 10 30.5 C 10 32 10.000391 35 14.900391 35 L 15.099609 35 C 19.999609 35 20 32 20 30.5 C 20 29.672 19.328 29 18.5 29 L 11.5 29 z M 26.5 30 A 1.50015 1.50015 0 1 0 26.5 33 L 36.5 33 A 1.50015 1.50015 0 1 0 36.5 30 L 26.5 30 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
