import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
      <path d="M 6.9804688 3.9882812 A 1.0001 1.0001 0 0 0 6.1679688 4.4453125 L 4.84375 6.4296875 L 4.7070312 6.2929688 A 1.0001 1.0001 0 1 0 3.2929688 7.7070312 L 4.2929688 8.7070312 A 1.0001 1.0001 0 0 0 5.8320312 8.5546875 L 7.8320312 5.5546875 A 1.0001 1.0001 0 0 0 6.9804688 3.9882812 z M 12 6 A 1.0001 1.0001 0 1 0 12 8 L 26 8 A 1.0001 1.0001 0 1 0 26 6 L 12 6 z M 6.9804688 11.988281 A 1.0001 1.0001 0 0 0 6.1679688 12.445312 L 4.84375 14.429688 L 4.7070312 14.292969 A 1.0001 1.0001 0 1 0 3.2929688 15.707031 L 4.2929688 16.707031 A 1.0001 1.0001 0 0 0 5.8320312 16.554688 L 7.8320312 13.554688 A 1.0001 1.0001 0 0 0 6.9804688 11.988281 z M 12 14 A 1.0001 1.0001 0 1 0 12 16 L 26 16 A 1.0001 1.0001 0 1 0 26 14 L 12 14 z M 6 20 C 4.3549904 20 3 21.35499 3 23 C 3 24.64501 4.3549904 26 6 26 C 7.6450096 26 9 24.64501 9 23 C 9 21.35499 7.6450096 20 6 20 z M 6 22 C 6.5641294 22 7 22.435871 7 23 C 7 23.564129 6.5641294 24 6 24 C 5.4358706 24 5 23.564129 5 23 C 5 22.435871 5.4358706 22 6 22 z M 12 22 A 1.0001 1.0001 0 1 0 12 24 L 26 24 A 1.0001 1.0001 0 1 0 26 22 L 12 22 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
