import React, { FunctionComponent, useEffect } from 'react';
import Modal from '../../../../components/Modal';
import SpacerTable from '../../../../components/SpacerTable';
import GAHelper from '../../../../helpers/GAHelper';
import ThemeHelper from '../../../../helpers/ThemeHelper';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import AdminAttendeeOverview from './AdminAttendeeOverview';

export interface IProps {
  organisation: IOrganisation;
  email: string;
  onClose: () => void;
  overviewRefreshed: (overview: any) => void;
}

const AdminAttendeeModal = ((props) => {
  const { organisation } = props;

  useEffect(() => {
    GAHelper.modal('Organisation Attendee Overview');
  }, []);

  return (
    <Modal theme={organisation.Theme ? organisation.Theme : ThemeHelper.getDefaultTheme()} onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>{organisation.Name}</h1>
          </SpacerTable>

          <div className="spacer" />

          <AdminAttendeeOverview organisation={props.organisation} email={props.email} overviewRefreshedCallback={props.overviewRefreshed} />
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export default AdminAttendeeModal;
