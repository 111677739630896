import { FunctionComponent, useEffect, useState } from 'react';
import Block from '../../../../components/Block';
import BlockHeader from '../../../../components/BlockHeader';
import BlockTextBox from '../../../../components/BlockTextBox';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import SolidLine from '../../../../components/SolidLine';
import SpacerTable from '../../../../components/SpacerTable';
import GAHelper from '../../../../helpers/GAHelper';
import { IWithdrawal } from '../../../../interfaces/IWithdrawal';
import Blocks from '../../../../components/Blocks';
import SVGCopy from '../../../../svg/SVGCopy';

export interface IProps {
  withdrawal: IWithdrawal;
  acceptWithdraw: (withdrawal: IWithdrawal) => void;
  onClose: () => void;
}

const AdminWithdrawModal = ((props) => {
  const { onClose, withdrawal } = props;

  useEffect(() => {
    GAHelper.modal(`AdminWithdraw`);
  }, []);

  return (
    <Modal onCloseClick={onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>Withdraw</h1>
            <div>{withdrawal.EventName}</div>
          </SpacerTable>

          <div className="spacer" />

          <Blocks>
            <Block>
              <BlockHeader>Organisation name</BlockHeader>
              <BlockTextBox type="text" value={withdrawal.OrganisationName} disabled copyButton />
            </Block>
            <Block>
              <BlockHeader>Account name</BlockHeader>
              <BlockTextBox disabled style={{ marginBottom: '10px' }} type="text" value={withdrawal.AccountName} id="withdraw-account-name" copyButton />
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: '50%', paddingRight: '5px' }}>
                      <BlockHeader>Sort code</BlockHeader>
                      <BlockTextBox disabled type="text" id="withdraw-sort-code" value={withdrawal.SortCode} copyButton />
                    </td>
                    <td style={{ width: '50%', paddingLeft: '5px' }}>
                      <BlockHeader>Account number</BlockHeader>
                      <BlockTextBox disabled type="text" id="withdraw-account-number" value={withdrawal.AccountNumber} copyButton />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Block>
            <Block>
              <BlockHeader>Amount</BlockHeader>
              <BlockTextBox type="text" value={withdrawal.Amount} disabled id="withdraw-amount" copyButton />
            </Block>
          </Blocks>

          <div className="spacer-x2" />

          <SpacerTable>
            <Button className="confirm large" onExecute={() => props.acceptWithdraw(withdrawal)} text={`Accept withdrawal`} />
            <Button onExecute={props.onClose} text={`Cancel`} />
          </SpacerTable>

          <SolidLine />

          <SpacerTable className="small-font">
            All withdrawals are subject to the Seaty <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
          </SpacerTable>
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export { AdminWithdrawModal };
