import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 7.5 5 C 6.1364058 5 5 6.1364058 5 7.5 L 5 13.5 C 5 14.863594 6.1364058 16 7.5 16 L 13.5 16 C 14.863594 16 16 14.863594 16 13.5 L 16 7.5 C 16 6.1364058 14.863594 5 13.5 5 L 7.5 5 z M 8 8 L 13 8 L 13 13 L 8 13 L 8 8 z M 20.5 9 A 1.50015 1.50015 0 1 0 20.5 12 L 41.5 12 A 1.50015 1.50015 0 1 0 41.5 9 L 20.5 9 z M 7.5 18.5 C 6.1364058 18.5 5 19.636406 5 21 L 5 27 C 5 28.363594 6.1364058 29.5 7.5 29.5 L 13.5 29.5 C 14.863594 29.5 16 28.363594 16 27 L 16 21 C 16 19.636406 14.863594 18.5 13.5 18.5 L 7.5 18.5 z M 8 21.5 L 13 21.5 L 13 26.5 L 8 26.5 L 8 21.5 z M 20.5 22.5 A 1.50015 1.50015 0 1 0 20.5 25.5 L 41.5 25.5 A 1.50015 1.50015 0 1 0 41.5 22.5 L 20.5 22.5 z M 7.5 32 C 6.1364058 32 5 33.136406 5 34.5 L 5 40.5 C 5 41.863594 6.1364058 43 7.5 43 L 13.5 43 C 14.863594 43 16 41.863594 16 40.5 L 16 34.5 C 16 33.136406 14.863594 32 13.5 32 L 7.5 32 z M 8 35 L 13 35 L 13 40 L 8 40 L 8 35 z M 20.5 36 A 1.50015 1.50015 0 1 0 20.5 39 L 41.5 39 A 1.50015 1.50015 0 1 0 41.5 36 L 20.5 36 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
