import AdminApi from '../api/AdminApi';
import EnvironmentVariables from '../EnvironmentVariables';
import { ITour } from '../interfaces/ITour';
import { IImageUploadRequest, ImageRequestGroup } from '../views/Editor/ImageUploader';
import ThemeHelper from './ThemeHelper';
import UserHelper from './UserHelper';

export default class TourHelper {
  public static getDefault(): ITour {
    var Tour: ITour = {
      ImageUrl: '',
      DisableTheme: true,
      Id: 0,
      Name: '',
      Description: '',
      TourTag: '',
      Theme: ThemeHelper.getDefaultTheme(),
      LogoUrl: null,
      Events: [],
      Organisation: null,
      OrganisationId: null,
    };

    return Tour;
  }

  public static save(tour: ITour, setBusyMessage: (message: string) => void, imageRequests: { [key: string]: IImageUploadRequest } = null): Promise<ITour> {
    setBusyMessage && setBusyMessage('Saving tour...');

    return AdminApi.request('PUT', '/api/TourEdit', tour)
      .then((t: ITour) => {
        if (t.ValidationError && t.ValidationError.length > 0) {
          var error = t.ValidationError;
          t.ValidationError = null;

          return Promise.reject(error);
        }
        tour.Id = t.Id;

        if (imageRequests) {
          return TourHelper.saveImages(t, setBusyMessage, imageRequests);
        } else {
          return t;
        }
      })
      .then((t) => {
        setBusyMessage && setBusyMessage('Updating tour cache...');
        UserHelper.refreshUserToken();
        return AdminApi.request('PUT', '/api/TourEdited', t);
      })
      .then((t) => {
        setBusyMessage && setBusyMessage('All done!');
        return t;
      });
  }

  public static saveImages(tour: ITour, setBusyMessage: (message: string) => void, imageRequests: { [key: string]: IImageUploadRequest } = null): Promise<ITour> {
    setBusyMessage && setBusyMessage('Saving event images...');

    return Promise.all(
      Object.keys(imageRequests).map((key) => {
        const imageRequest = imageRequests[key];

        const formData = new FormData();
        formData.append('tourId', tour.Id.toString());
        formData.append('group', imageRequest.group);
        formData.append('file', imageRequest.file);

        return fetch(`${EnvironmentVariables.ADMIN_API_URL}/api/TourImageUpload?tourId=${tour.Id}&group=${imageRequest.group}`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            Authorization: `Bearer ${UserHelper.currentUser.Token}`,
          },
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          .then((response: any) => {
            if (imageRequest.group == ImageRequestGroup.Tour) {
              tour.LogoUrl = response.url;
            }
          });
      })
    ).then(() => {
      return tour;
    });
  }
}
