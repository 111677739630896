import React from 'react';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  organisation: IOrganisation;
  onOrganisationUpdated: (e: IOrganisation) => void;
  globalOptions: JSX.Element;
}

const MembershipSection: React.FC<IProps> = (props) => {
  const { organisation, onOrganisationUpdated } = props;

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Membership options</h2>
            <div style={{ marginTop: '0' }}>
              <div className="section">
                <InfoLabel
                  text="Do you want to enable memberships?"
                  tooltip="With memberships enabled you will be able to mark users as members of your organisation. Memberships make it easier to track sales and money due for specific people in your organisation. You can set up sales quotas that you will be able to track from the orders and payments screen."
                ></InfoLabel>

                <Switch
                  trueLabel="Yes, enable memberships"
                  falseLabel="No, turn off memberships"
                  onChange={(value) => onOrganisationUpdated({ ...organisation, MembershipsEnabled: value })}
                  checked={organisation.MembershipsEnabled}
                ></Switch>

                {organisation.MembershipsEnabled ? (
                  <div>
                    <InfoLabel
                      text="Restrict requests to members only?"
                      tooltip="This option will restrict anyone who is not a member of your organisation from requesting tickets. You can assign and add members on the orders and payments screen."
                    ></InfoLabel>

                    <Switch
                      trueLabel="Yes, member only requests"
                      falseLabel="No, any user can request"
                      onChange={(value) => onOrganisationUpdated({ ...organisation, MemberOnlyRequests: value })}
                      checked={organisation.MemberOnlyRequests}
                    ></Switch>

                    <InfoLabel
                      text="Do you want to automatically accept requests from members?"
                      tooltip="You can save time by automatically accepting requests for tickets that come in from people you have marked as members. You will receive an order confirmation when they place an order and you can track balances owed in the orders and payments page as usual."
                    ></InfoLabel>

                    <Switch
                      trueLabel="Yes, auto accept requests"
                      falseLabel="No, all requests need approval"
                      onChange={(value) => onOrganisationUpdated({ ...organisation, AutoAcceptMemberRequests: value })}
                      checked={organisation.AutoAcceptMemberRequests}
                    ></Switch>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">
            Memberships are a great way to manage people that interact with your organisation. If for example you are an theatre group that has members who are required to sell specific quotas per
            event.
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">When you enable memberships, you will see a new menu option on the main organisation menu where you can add members and member types.</div>
        </div>
      </div>
    </>
  );
};

export default MembershipSection;
