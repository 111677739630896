import linq from 'linq';
import React, { useState, useEffect } from 'react';
import ColourHelper from '../../../helpers/ColourHelper';
import GuidHelper from '../../../helpers/GuidHelper';
import { ICategoryGroup, IEvent } from '../../../interfaces/IEvent';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';
import SVGTicket from '../../../svg/SVGTicket';
import Toolbar from '../toolbar/Toolbar';
import SeatedTicket from './SeatedTicket';
import Loader from '../../../components/Loader';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const SeatedTicketsSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;
  const [showColourPicker, setShowColourPicker] = useState(false);
  const [loading, setLoading] = useState(true);

  const getItems = () => {
    return linq
      .from(event.AllocatedCategoryGroups)
      .orderBy((s) => s.Index)
      .toArray();
  };

  const resetIndexes = () => {
    getItems().forEach((i, index) => (i.Index = index));
    props.onEventUpdated({ ...event });
  };

  useEffect(() => {
    if (
      event.AllocatedCategoryGroups.filter((acg) => acg.Categories == null || acg.Categories.length == 0).length > 0
    ) {
      event.AllocatedCategoryGroups = event.AllocatedCategoryGroups.filter(
        (acg) => acg.Categories != null && acg.Categories.length > 0,
      );
    }
    resetIndexes();
    setLoading(false);
  }, []);

  const addNew = () => {
    var color = ColourHelper.getRandomColor();

    event.Dates.forEach((ed) => {
      if (ed) ed.ChangeMade = true;
    });

    const categories: Array<ITicketCategory> = [];

    event.SeatingPlans.forEach((seatingPlan) => {
      linq
        .from(seatingPlan.SeatCategories)
        .orderBy((sc) => sc.Name)
        .toArray()
        .forEach((seatCategory) => {
          categories.push({
            Id: 0,
            People: 1,
            Description: '',
            Name: '',
            HasOrders: false,
            PriceAsString: '',
            Colour: color,
            Guid: GuidHelper.new(),
            SeatCategory: seatCategory,
            GeneralAdmission: false,
            PriceAsInt: 0,
          });
        });
    });

    var allocatedCategoryGroup: ICategoryGroup = {
      Id: 0,
      Index: event.AllocatedCategoryGroups.length,
      SelectedEventDates: event.Dates.map((d) => d.Guid),
      HasOrders: false,
      Categories: categories,
      Guid: GuidHelper.new(),
      Expanded: true,
    };

    event.AllocatedCategoryGroups.push(allocatedCategoryGroup);
    resetIndexes();
  };

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}
          <button className="option" onClick={addNew}>
            <label>Add Seated Ticket</label>
            <div className="icon">
              <SVGTicket />
            </div>
          </button>
        </div>
      </Toolbar>
      <h2>Seated tickets</h2>
      {loading ? (
        <Loader>Loading tickets...</Loader>
      ) : (
        <div className="animated fadeIn">
          <table style={{ width: '100%' }}>
            <tbody>
              {getItems().map((group: ICategoryGroup, index) => (
                <tr key={'SeatedTicket_' + group.Guid + '_' + group.Id} style={{ display: 'table-row' }}>
                  <td>
                    <SeatedTicket
                      showColourPicker={(value) => setShowColourPicker(value)}
                      symbol={event.CurrencySymbol}
                      handleChange={() => onEventUpdated({ ...event })}
                      onDeleteCategoryGroupClick={() => {
                        event.AllocatedCategoryGroups = event.AllocatedCategoryGroups.filter((x) => x != group);
                        onEventUpdated({ ...event });
                      }}
                      eventDates={event.Dates}
                      index={index}
                      group={group}
                      key={'allocated_category_group_' + index}
                      event={event}
                      ticketCount={event.AllocatedCategoryGroups ? event.AllocatedCategoryGroups.length : 0}
                      onMoveDownClicked={() => {
                        group.Index = group.Index + 1.5;

                        const newList = getItems();
                        newList.forEach((sp, index) => (sp.Index = index));
                        event.AllocatedCategoryGroups = newList;
                        props.onEventUpdated({ ...event });
                      }}
                      onMoveUpClicked={() => {
                        group.Index = group.Index - 1.5;

                        const newList = getItems();
                        newList.forEach((sp, index) => (sp.Index = index));
                        event.AllocatedCategoryGroups = newList;
                        props.onEventUpdated({ ...event });
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button className="admin-button" onClick={addNew}>
            Add a seated ticket
          </button>
        </div>
      )}
    </>
  );
};

export default SeatedTicketsSection;
