import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M16 5A7 7 0 1016 19 7 7 0 1016 5zM32 19c-3.859 0-7-3.141-7-7s3.141-7 7-7 7 3.141 7 7S35.859 19 32 19zM32 8c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4S34.206 8 32 8zM20 34v-9.5c0-1.328.474-2.548 1.261-3.5H9.5C7.57 21 6 22.57 6 24.5V32c0 5.514 4.486 10 10 10 2.133 0 4.108-.677 5.733-1.82C20.641 38.373 20 36.262 20 34zM32 44c-5.514 0-10-4.486-10-10v-9.5c0-1.93 1.57-3.5 3.5-3.5h13c1.93 0 3.5 1.57 3.5 3.5V34C42 39.514 37.514 44 32 44zM25.5 24c-.275 0-.5.225-.5.5V34c0 3.859 3.141 7 7 7s7-3.141 7-7v-9.5c0-.275-.225-.5-.5-.5H25.5z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
