import linq from 'linq';
import { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../../../api/AdminApi';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../../components/BlockInfo';
import BlockTextBox from '../../../components/BlockTextBox';
import Modal from '../../../components/Modal';
import SpacerTable from '../../../components/SpacerTable';
import GAHelper from '../../../helpers/GAHelper';
import { ISeatingPlan } from '../../../interfaces/ISeatingPlan';
import Radio from '../../../components/Radio';
import Button from '../../../components/Button';

export interface IProps {
  onClose: () => void;
  hasOrders: boolean;
  onSeatingPlanSelected: (seatingPlan: ISeatingPlan) => void;
}

const SeatingPlanSearchModal = ((props) => {
  let [query, setQuery] = useState('');
  const [loadingMessage, setLoadingMessage] = useState(null);
  let [timeoutId, setTimeoutId] = useState(null);
  const [results, setResults] = useState<Array<ISeatingPlan>>(null);
  const [error, setError] = useState(null);
  const [searchType, setSearchType] = useState('MyEvents');

  useEffect(() => {
    GAHelper.modal(`Seating Plan Search`);
  }, []);

  const handleSearchChanged = (value) => {
    setLoadingMessage(null);
    setError(null);

    query = value;
    setQuery(value);
    setResults(null);

    if (timeoutId) window.clearTimeout(timeoutId);

    setTimeoutId(
      window.setTimeout(function () {
        if (value != null && value.length > 1) {
          runSearch();
        }
      }, 500),
    );
  };

  const runSearch = () => {
    setLoadingMessage('Searching for seating plans...');
    setError(null);

    AdminApi.request('POST', '/api/AvailableSeatingPlans', {
      query: query.trim(),
      allEvents: searchType == 'AllEvents',
    })
      .then((result) => {
        if (result && result.length > 0) {
          setLoadingMessage(null);
          setResults(result);
        } else {
          setError(`No seating plans found.`);
          setLoadingMessage(null);
        }
      })
      .catch((message) => {
        setError(message);
        setLoadingMessage(null);
        setResults(null);
      });
  };

  var hasResults = results != null && results.length > 0;

  const groupedByVenue =
    hasResults &&
    linq
      .from(results)
      .groupBy((r) => r.VenueName.toUpperCase().trim())
      .select((r) => {
        return {
          VenueName: r.key(),
          Results: r.toArray(),
          Count: r.count(),
        };
      })
      .toArray();

  return (
    <Modal onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>Find a Seating Plan</h1>
            Enter a venue, event or organisation name and we will show you the seating plans that best match.
          </SpacerTable>

          {props.hasOrders && (
            <>
              <div className="spacer" />
              <table className="blocks">
                <tbody>
                  <BlockInfo type={InfoType.Info}>
                    As your event has existing orders, seat categories will not be copied. All seats will be marked as your first existing seat category and you will have to re-assign seat categories
                    as required, after the new seating plan has been loaded.
                  </BlockInfo>
                </tbody>
              </table>
            </>
          )}

          <div className="spacer" />

          <table className="blocks">
            <tbody>
              <Block>
                <BlockHeader>Which events would you like to search?</BlockHeader>
                <Radio
                  selectedId={searchType}
                  onValueChanged={(option) => {
                    setError(null);
                    setSearchType(option.Id);
                  }}
                  options={[
                    {
                      Text: `My Events`,
                      Info: 'Search only events you are the admin of.',
                      Id: 'MyEvents',
                    },
                    {
                      Text: `All Events`,
                      Info: 'Search all seating plans on Seaty.',
                      Id: 'AllEvents',
                    },
                  ]}
                />
              </Block>
              <Block>
                <BlockHeader>Search</BlockHeader>
                <BlockTextBox type="text" autoFocus value={query} placeholder={'Event or venue name'} onChange={(e) => handleSearchChanged(e)} />
              </Block>
              {loadingMessage && <BlockInfo type={InfoType.Info}>{loadingMessage}</BlockInfo>}
              {error && <BlockInfo type={InfoType.Error}>{error}</BlockInfo>}
            </tbody>
          </table>

          <div className="spacer" />

          <SpacerTable>
            <Button
              className="small confirm"
              onExecute={() => {
                handleSearchChanged(query);
              }}
              text={`Search`}
            />
          </SpacerTable>

          {hasResults && (
            <>
              <div className="spacer-x2" />
              <SpacerTable>
                <h1>{hasResults && (results.length == 1 ? `Found seating plan` : `Found multiple seating plans`)}</h1>
              </SpacerTable>
              <div className="spacer" />
            </>
          )}

          {hasResults && (
            <table className="blocks">
              <tbody>
                {groupedByVenue.map((v) => (
                  <>
                    <Block key={'result_' + v.VenueName}>
                      <BlockHeader>
                        {v.VenueName && v.VenueName.length > 0 ? v.VenueName : 'Unknown Venue'} ({v.Count})
                      </BlockHeader>
                    </Block>
                    {linq
                      .from(v.Results)
                      .orderBy((v) => v.EventName)
                      .toArray()
                      .map((result, index) => (
                        <Block
                          className="route"
                          onClick={() => {
                            props.onSeatingPlanSelected(result);
                          }}
                          key={'result_' + result.Id + '+' + index}
                        >
                          <BlockHeader>
                            {result.EventName} #{result.EventTag}
                          </BlockHeader>
                          {result.Name && result.Name.length > 0 && <BlockHeader>{result.Name}</BlockHeader>}
                          <div>{result.VenueName}</div>
                          <div>{result.Organisation}</div>
                          <div className="tags">
                            {result.SeatCategories.map((s) => {
                              return (
                                <div className="tag" key={`Seat_${s.Id}`} style={{ backgroundColor: s.Colour }}>
                                  {s.SeatCount} {s.Name}
                                </div>
                              );
                            })}
                          </div>
                        </Block>
                      ))}
                  </>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export default SeatingPlanSearchModal;
