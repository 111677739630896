import { FunctionComponent, useEffect, useRef, useState } from 'react';
import './BlockTextBox.scss';
import GuidHelper from '../helpers/GuidHelper';
import SVGCopy from '../svg/SVGCopy';
import SVGTick from '../svg/SVGTick';

export interface IProps {
  onChange?(value: string, meta?: any): void;
  autoFocus?: boolean;
  rows?: number;
  name?: string;
  inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  value?: string;
  pattern?: string;
  autoComplete?: string;
  type?: string;
  id?: string;
  onFocus?(): void;
  onLostFocus?(): void;
  placeholder?: string;
  loading?: boolean;
  onKeyPress?: (e) => void;
  meta?: any;
  maxLength?: number;
  style?: any;
  disabled?: boolean;
  error?: string;
  symbol?: string;
  copyButton?: boolean;
}

const BlockTextBox: FunctionComponent<IProps> = (props) => {
  const [id, setId] = useState(props.id ? props.id : GuidHelper.new());
  const [disableCopyButton, setDisableCopyButton] = useState(false);

  useEffect(() => {
    if (props.autoFocus) {
      window.setTimeout(() => {
        const input = document.getElementById(id);

        if (!input) return;
        (input as HTMLInputElement).select();
      }, 200);
    }
  }, []);

  const onChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e.target.value, props.meta);
    }
  };

  var input = null;

  const className = `textbox${props.disabled ? ' disabled' : ''}${props.error ? ' bad' : ''}`;

  if (props.rows && props.rows > 1) {
    input = (
      <textarea
        readOnly={props.disabled}
        style={props.style}
        maxLength={props.maxLength}
        onKeyPress={props.onKeyPress}
        placeholder={props.placeholder}
        rows={props.rows}
        className={className}
        id={id}
        onChange={onChange}
        name={props.name}
        value={props.value == null || props.value == undefined ? '' : props.value}
        inputMode={props.inputMode}
        autoComplete={props.autoComplete}
      ></textarea>
    );
  } else {
    input = (
      <input
        readOnly={props.disabled}
        style={props.style}
        maxLength={props.maxLength}
        onKeyPress={props.onKeyPress}
        placeholder={props.placeholder}
        onFocus={props.onFocus}
        onBlur={props.onLostFocus}
        className={className}
        type={props.type == null || props.type.length == 0 ? 'text' : props.type}
        id={id}
        onChange={onChange}
        name={props.name}
        value={props.value == null || props.value == undefined ? '' : props.value}
        inputMode={props.inputMode}
        pattern={props.pattern}
        autoComplete={props.autoComplete}
      />
    );
  }

  return (
    <div className={`text-box-container${props.copyButton ? ' text-box-container-has-buttons' : ''}${props.symbol == null || props.symbol.length == 0 ? '' : ' has-symbol left'}`}>
      {props.copyButton ? (
        <div className="text-box-buttons">
          <button
            disabled={disableCopyButton}
            onClick={() => {
              if (disableCopyButton) return;
              setDisableCopyButton(true);
              navigator.clipboard.writeText(props.value);

              window.setTimeout(() => {
                setDisableCopyButton(false);
              }, 1200);
            }}
          >
            {disableCopyButton ? (
              <>
                <SVGTick />
                <span className="text-box-button-text">Copied!</span>
              </>
            ) : (
              <>
                <SVGCopy />
              </>
            )}
          </button>
        </div>
      ) : null}
      {props.symbol == null || props.symbol.length == 0 ? null : <span className="symbol">{props.symbol}</span>}
      {input}
      {props.error && <p className="input-error">{props.error}</p>}
      {props.loading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default BlockTextBox;
