import React, { useState } from 'react';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import UserSearchModal from '../../../modals/UserSearchModal';
import SVGAdmin from '../../../svg/SVGAdmin';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  organisation: IOrganisation;
  onOrganisationUpdated: (e: IOrganisation) => void;
  globalOptions: JSX.Element;
}

const PermissionsSection: React.FC<IProps> = (props) => {
  const { organisation, onOrganisationUpdated } = props;
  const [searchingForAdmin, setSearchingForAdmin] = useState(false);

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}
          <button className="option" onClick={() => setSearchingForAdmin(true)}>
            <label>Add Admin</label>
            <div className="icon">
              <SVGAdmin />
            </div>
          </button>
        </div>
      </Toolbar>
      <div className="row">
        {searchingForAdmin && (
          <UserSearchModal
            onSubmit={(user) => {
              organisation.Users.push(user);
              props.onOrganisationUpdated({ ...organisation });
              setSearchingForAdmin(false);
            }}
            onClose={() => setSearchingForAdmin(false)}
          />
        )}
        <div className="col-sm-8">
          <div className="fields">
            <h2>Administrators</h2>
            {organisation.Users.map((user, index) => (
              <div className="field element" key={'org_administrator_list_item_' + index}>
                <div className="row col">
                  <div className="col col-sm-8">
                    <label>{user.FullName}</label>
                    <div style={{ opacity: 0.7 }}>{user.Email}</div>
                  </div>
                  {organisation.CreatedBy.Id == user.Id ? null : (
                    <div className="col col-sm-4 col-xs-6">
                      <button
                        className="admin-button danger"
                        onClick={() => {
                          organisation.Users = organisation.Users.filter((x) => x != user);
                          onOrganisationUpdated({ ...organisation });
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="info">
            Here you can add administrators that will have access and control over this organisation and its events. Please ensure the email you are trying to add is{' '}
            <strong>registered as a Seaty user</strong> first, before trying to add them.
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionsSection;
