import React from 'react';

export interface ISolidLineProps {
  className?: string;
}

export default class SolidLine extends React.Component<ISolidLineProps, any> {
  render() {
    return (
      <table className="spacer">
        <tbody>
          <tr className="spacer">
            <td className="spacer"></td>
            <td></td>
            <td className="spacer"></td>
          </tr>
          <tr>
            <td className="spacer"></td>
            <td></td>
            <td className="spacer"></td>
          </tr>
        </tbody>
      </table>
    );
  }
}
