import linq from 'linq';
import moment from 'moment';
import 'moment-timezone';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import NumberHelper from '../../../../helpers/NumberHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import { IEventDate } from '../../../../interfaces/IEventDate';
import SVGCheck from '../../../../svg/SVGCheck';
import SVGLock from '../../../../svg/SVGLock';
import SVGMultiply from '../../../../svg/SVGMultiply';
import './AdminLocks.scss';
import { useLocation } from 'react-router-dom';
import SideMenu from '../../../../components/SideMenu';

interface IEventDateLocks {
  EventDateId: any;
  Locks: ILockedTickets[];
  EventDate: string;
}

interface ILockedTickets {
  EventDate: string;
  AdminName: string;
  DateLocked: string;
  SeatCategoryName: string;
  Group: string;
  Name: string;
  SeatCategoryId: number;
  Key: string;
}

interface IProps {
  event: IEvent;
  eventDate?: IEventDate;
}

const AdminLocks: FunctionComponent<IProps> = (props) => {
  const location = useLocation();
  const [state, setState] = useState<{ locks: Array<IEventDateLocks>; loading: string; error: string }>({ locks: null, loading: null, error: null });

  const [checkedLocks, setCheckedLocks] = useState<{ [key: string]: ILockedTickets }>({});
  const [filter, setFilter] = useState('');
  const [query, setQuery] = useState('');
  const [selectedDateId, setSelectedDateId] = useState<Number>(props.eventDate ? props.eventDate.Id : null);

  const event = props.event;

  const { locks, loading } = state;

  const reload = () => {
    setState({ ...state, error: null, loading: 'Loading locks...' });
    setCheckedLocks({});

    AdminApi.request('GET', `/api/LockByAdmin?eventId=${event.Id}`)
      .then((response: Array<IEventDateLocks>) => {
        setState({
          ...state,
          error: null,
          loading: null,
          locks: linq
            .from(response)
            .select((eventDateLock: IEventDateLocks) => {
              return {
                ...eventDateLock,
                Locks: linq
                  .from(eventDateLock.Locks)
                  .select((l: ILockedTickets) => {
                    return {
                      ...l,
                      EventDateId: eventDateLock.EventDateId,
                      Name: l.Name.toUpperCase(),
                      Group: l.Group.toUpperCase(),
                      Key: `${eventDateLock.EventDate}_${l.Group}_${l.Name}_${l.SeatCategoryId}`,
                    };
                  })
                  .toArray(),
              };
            })
            .toArray(),
        });
      })
      .catch((message) => {
        setState({ ...state, error: message, loading: null });
      });
  };

  useEffect(() => {
    if (props.eventDate) setSelectedDateId(props.eventDate.Id);
    reload();
  }, [props.eventDate]);

  const onFilterKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      setFilter(query);
    }
  };

  const clearFilterClicked = () => {
    setFilter('');
    setQuery('');
  };

  const handleSearchChanged = (e) => {
    setQuery(e.target.value);
  };

  const filteredLocks: ILockedTickets[] = !locks
    ? []
    : linq
        .from(locks)
        .selectMany((eventDateLocks: IEventDateLocks) => {
          return eventDateLocks.Locks.map((lock) => {
            return { ...lock, EventDate: eventDateLocks.EventDate };
          });
        })
        .where((s) => {
          if (selectedDateId != null && selectedDateId != s.EventDateId) return false;
          if (!filter) return true;

          const lowerFilter = filter.toUpperCase();
          return (
            s.SeatCategoryName.toUpperCase().includes(lowerFilter) ||
            (s.Group + s.Name).includes(lowerFilter) ||
            s.AdminName.toUpperCase().includes(lowerFilter) ||
            moment(s.DateLocked).format('DD MMM YY, HH:mm').toUpperCase().includes(lowerFilter)
          );
        })
        .orderBy((s) => s.SeatCategoryName)
        .thenBy((s) => (NumberHelper.isNumeric(s.Group) ? parseInt(s.Group) : s.Group))
        .thenBy((s) => (NumberHelper.isNumeric(s.Name) ? parseInt(s.Name) : s.Name))
        .toArray();

  let allVisibleLocksSelected = true;

  filteredLocks.forEach((lock) => {
    const isSelected = checkedLocks[lock.Key];

    if (!isSelected) {
      allVisibleLocksSelected = false;
    }
  });

  return (
    <>
      <Helmet>
        <title>#{event.EventTag} Locks</title>
        <meta name="description" content={`Use this page to manage locked seats for this event.`} />
      </Helmet>
      {loading ? (
        <Loader inline>{state.loading}</Loader>
      ) : (
        <>
          <div className="toolbar">
            <div className="title">Locks</div>
            <div className="buttons">
              <div className="input">
                <input id="searchinput" onChange={handleSearchChanged} type="text" placeholder="Search..." value={query} onKeyPress={onFilterKeyPress.bind(this)} style={{ margin: '0' }} />

                <button onClick={clearFilterClicked}>
                  <SVGMultiply />
                </button>
              </div>

              <button
                disabled={Object.keys(checkedLocks).length == 0}
                className={`${Object.keys(checkedLocks).length == 0 ? 'disabled' : ''}`}
                onClick={() => {
                  setState({ ...state, loading: 'Unlocking tickets...' });
                  const locks = linq
                    .from(Object.values(checkedLocks))
                    .groupBy((c) => c.EventDateId)
                    .select((g) => {
                      return { eventId: event.Id, eventDateId: g.first().EventDateId, seats: g.toArray() };
                    })
                    .toArray();

                  AdminApi.request('DELETE', `/api/LockByAdmin`, locks)
                    .then(() => {
                      reload();
                    })
                    .catch((message) => {
                      setState({ ...state, loading: null });
                      alert(message);
                    });
                }}
              >
                <SVGLock />
                Unlock {Object.keys(checkedLocks).length} Tickets
              </button>
              {/* 
              <button onClick={reload}>
                <SVGRefresh />
                Refresh
              </button> */}
            </div>
          </div>

          <div className="row">
            {!props.eventDate && (
              <div className={'col-sm-3 col-md-2 donotprint'}>
                <SideMenu>
                  <button className={selectedDateId == null ? 'selected' : ''} onClick={() => setSelectedDateId(null)}>
                    All
                  </button>
                  {locks &&
                    locks.map((lock) => {
                      return (
                        <button key={lock.EventDateId} className={selectedDateId == lock.EventDateId ? 'selected' : ''} onClick={() => setSelectedDateId(lock.EventDateId)}>
                          {moment(lock.EventDate).format('DD MMM YY, HH:mm')}
                        </button>
                      );
                    })}
                </SideMenu>
              </div>
            )}
            <div className={props.eventDate ? 'col-sm-12 donotprint' : 'col-sm-9 col-md-10  donotprint'}>
              <div className="event-locks">
                <div className="orderoverview" style={{ paddingBottom: '100px' }}>
                  <div className="container-outer" style={{ minHeight: '300px' }}>
                    <div className="container-inner" style={{ backgroundColor: 'white' }}>
                      <table className="table grid-table">
                        <thead>
                          <tr>
                            <th className="check-cell">
                              <button
                                onClick={() => {
                                  if (allVisibleLocksSelected) {
                                    filteredLocks.forEach((lock) => {
                                      delete checkedLocks[lock.Key];
                                    });
                                  } else {
                                    filteredLocks.forEach((lock) => {
                                      checkedLocks[lock.Key] = lock;
                                    });
                                  }

                                  setCheckedLocks({ ...checkedLocks });
                                }}
                                className={`${allVisibleLocksSelected ? 'selected' : ''}`}
                              >
                                <SVGCheck />
                              </button>
                            </th>
                            {!selectedDateId ? <th className="grid-header">Event Date</th> : null}
                            <th className="grid-header">Name</th>
                            <th className="grid-header">Category</th>
                            <th className="grid-header">Locked By</th>
                            <th className="grid-header">Date Locked</th>
                          </tr>
                        </thead>

                        <tbody className="smooth-details">
                          {linq
                            .from(filteredLocks)
                            .select((lock: ILockedTickets) => {
                              return (
                                <React.Fragment key={lock.Key}>
                                  <tr>
                                    <td className="check-cell">
                                      <button
                                        onClick={() => {
                                          if (checkedLocks[lock.Key]) {
                                            delete checkedLocks[lock.Key];
                                          } else {
                                            checkedLocks[lock.Key] = lock;
                                          }
                                          setCheckedLocks({ ...checkedLocks });
                                        }}
                                        className={`${checkedLocks[lock.Key] ? ' selected' : ''}`}
                                      >
                                        <SVGCheck />
                                      </button>
                                    </td>
                                    {!selectedDateId ? <td>{moment(lock.EventDate).format('DD MMM YY, HH:mm')}</td> : null}
                                    <td className="ticket-font">
                                      {lock.Group}
                                      {lock.Name}
                                    </td>
                                    <td>{lock.SeatCategoryName}</td>
                                    <td>{lock.AdminName}</td>
                                    <td>{moment(lock.DateLocked).format('DD MMM YY, HH:mm')}</td>
                                  </tr>
                                </React.Fragment>
                              );
                            })
                            .toArray()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminLocks;
