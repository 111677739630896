import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M36.128,23.628l-8.804,1.799l6.402,8.188c0.919,1.175,0.659,2.882-0.569,3.73l-0.131,0.09 c-1.239,0.856-2.944,0.48-3.708-0.818l-5.233-8.89l-5.313,8.911c-0.768,1.289-2.467,1.658-3.701,0.805l-0.144-0.099 c-1.228-0.848-1.488-2.555-0.569-3.73l6.402-8.188l-8.821-1.79c-1.55-0.314-2.46-1.931-1.924-3.419l0.009-0.026 c0.49-1.362,2.002-2.059,3.356-1.547l8.701,3.288l-0.769-10.166c-0.114-1.505,1.076-2.788,2.585-2.788h0.288 c1.509,0,2.699,1.284,2.585,2.788l-0.769,10.166l8.692-3.272c1.374-0.517,2.904,0.209,3.371,1.601l0,0 C38.562,21.739,37.654,23.316,36.128,23.628z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
