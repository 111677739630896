import moment from 'moment';
import { FunctionComponent } from 'react';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { AttendeeType, IAttendeeRow } from './IAttendeeRow';
import SVGMemberType from '../../../../svg/SVGMemberType';
import SVGMember from '../../../../svg/SVGMember';

export interface IProps {
  organisationId: number;
  organisationTag: string;
  currency: string;
  data: IAttendeeRow;
  handleRowClick: (data: any) => void;
  showEventTag: boolean;
}

const AdminAttendeeRow: FunctionComponent<IProps> = (props) => {
  const { data, handleRowClick, currency } = props;

  const notClickable = false;

  return (
    <>
      <tr onClick={!notClickable ? handleRowClick : null} className={`${notClickable ? '' : 'route'}`} style={{ display: 'table-row' }}>
        <td className="grid-table-icon">{data.IsMember && <SVGMember />}</td>
        <td>{data.AttendeeName}</td>
        <td>{data.Email}</td>
        <td>
          <div className={`status-tag tag-${data.AllowMarketing ? 'good' : '-default'}`}>{data.AllowMarketing ? 'Marketing Approved' : 'No Email Marketing'}</div>
        </td>
        {props.showEventTag && <td>{data.OrderCount}</td>}
      </tr>
    </>
  );
};

export default AdminAttendeeRow;
