import { atom } from 'recoil';
import { IOrganisation } from '../interfaces/IOrganisation';
import { ITour } from '../interfaces/ITour';
import { EventWizardSection } from '../views/Admin/WizardEvent';

const wizardEventState = atom<{
  organisation: IOrganisation;
  tour?: ITour;
  startFrom?: EventWizardSection;
  onClose?: () => void;
}>({
  key: 'wizardEvent',
  default: null,
});

export default wizardEventState;
