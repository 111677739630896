import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path d="M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11s11-4.9,11-11S18.1,1,12,1z M12,3c5,0,9,4,9,9s-4,9-9,9s-9-4-9-9S7,3,12,3z" />
      <g>
        <circle cx="12" cy="12" r="1.6" />
        <path
          d="M12,14.1c-1.2,0-2.1-1-2.1-2.1s1-2.1,2.1-2.1s2.1,1,2.1,2.1S13.2,14.1,12,14.1z M12,11c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1
		s1-0.5,1-1C13,11.4,12.5,11,12,11z"
        />
      </g>
      <g>
        <circle cx="17.4" cy="12" r="1.6" />
        <path
          d="M17.4,14.1c-1.2,0-2.1-1-2.1-2.1s1-2.1,2.1-2.1s2.1,1,2.1,2.1S18.6,14.1,17.4,14.1z M17.4,11c-0.6,0-1,0.5-1,1
		c0,0.6,0.5,1,1,1s1-0.5,1-1C18.4,11.4,18,11,17.4,11z"
        />
      </g>
      <g>
        <circle cx="6.6" cy="12" r="1.6" />
        <path
          d="M6.6,14.1c-1.2,0-2.1-1-2.1-2.1s1-2.1,2.1-2.1s2.1,1,2.1,2.1S7.8,14.1,6.6,14.1z M6.6,11c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1
		s1-0.5,1-1C7.6,11.4,7.2,11,6.6,11z"
        />
      </g>
    </svg>
  );
}) as FunctionComponent<IProps>;
