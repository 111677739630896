import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="26px" height="26px">
      <path d="M 10.59375 13 L 19.179688 4.234375 C 19.5625 3.84375 19.558594 3.21875 19.171875 2.828125 L 17.636719 1.292969 C 17.242188 0.902344 16.609375 0.902344 16.21875 1.296875 L 5.292969 12.292969 C 5.097656 12.488281 5 12.742188 5 13 C 5 13.257813 5.097656 13.511719 5.292969 13.707031 L 16.21875 24.703125 C 16.609375 25.097656 17.242188 25.097656 17.636719 24.707031 L 19.171875 23.171875 C 19.558594 22.78125 19.5625 22.15625 19.179688 21.765625 Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
