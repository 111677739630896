import './Dropdown.scss';

import React, { CSSProperties, useState } from 'react';

export interface IDropDownItem {
  value: any;
  description: string;
  color?: string;
  backgroundColor?: string;
}

export interface IItemProps {
  item: IDropDownItem;
  clicked: (item, e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  selected?: boolean;
}

const DropdownItem: React.FC<IItemProps> = (props) => {
  var style: CSSProperties = {
    color: props.item.color == null ? null : props.item.color,
    backgroundColor: props.item.backgroundColor == null ? null : props.item.backgroundColor,
  };

  return (
    <li
      className={`${props.selected ? ' selected' : ''}`}
      onClick={(e) => {
        props.clicked(props.item, e);
      }}
    >
      <span className="form-control" style={style}>
        {props.item.description.toString()}
      </span>
    </li>
  );
};

export default DropdownItem;
