import GeneralApi from '../api/GeneralApi';
import UserHelper from '../helpers/UserHelper';
import { ITicket } from '../interfaces/ITicket';
import { ITicketAvailability } from './TicketService';

export default class LockService {
  public static LockTickets(eventDateId: number, tickets: Array<ITicket>, abortController?: AbortController, clearPurchases: boolean = false, purchaseId: number = null): Promise<ITicketAvailability> {
    return GeneralApi.request(
      'POST',
      '/api/Lock',
      {
        sessionId: UserHelper.getSessionId(),
        eventDateId: eventDateId,
        seats: tickets,
        clearPurchases: clearPurchases,
        purchaseId: purchaseId,
      },
      true,
      abortController,
    )
      .then((result: ITicketAvailability) => {
        result.Tickets = (result as any).Seats;
        result.EventDateId = eventDateId;

        return result;
      })
      .catch((message) => {
        return {
          AllAvailable: false,
          ErrorMessage: message,
        } as ITicketAvailability;
      });
  }

  public static DeleteSessionLocks(eventDateId: number, clearPurchases = false): Promise<ITicketAvailability> {
    return GeneralApi.request('DELETE', '/api/Lock', { sessionId: UserHelper.getSessionId(), eventDateId: eventDateId, clearPurchases: clearPurchases });
  }
}
