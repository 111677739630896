import { Elements } from '@stripe/react-stripe-js';
import GeneralApi from '../../api/GeneralApi';
import { ITicketAvailability } from '../../services/TicketService';
import { IDiscount } from '../../interfaces/IDiscount';
import UserHelper from '../../helpers/UserHelper';
import { useState, useEffect } from 'react';
import StripeHelper from '../../helpers/StripeHelper';
import Loader from '../Loader';
import StripeCheckoutForm from './StripeCheckoutForm';
import { PaymentType } from './PaymentControl';
import { IEventDate } from '../../interfaces/IEventDate';
import { ITicket } from '../../interfaces/ITicket';
import BlockInfo, { InfoType } from '../BlockInfo';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { IEvent } from '../../interfaces/IEvent';
import ThemeHelper from '../../helpers/ThemeHelper';

interface IStripeCheckoutProps {
  availability: ITicketAvailability;
  eventDateId: number;
  attendeeEmail: string;
  attendeeName: string;
  notes: string;
  adminNotes: string;
  questionAnswers: any;
  discount: IDiscount;
  discountCode: string;
  acceptedMarketing: boolean;
  acceptedSurvey: boolean;
  collectAtBoxOffice: boolean;
  stripeAccountId: string;
  eventDateString: string;
  eventTimeString: string;
  eventTag: string;
  tickets: ITicket[];
  event: IEvent;
  organisation: IOrganisation;
  eventDate: IEventDate;
  referralGuid: string;
}

const StripeCheckout: React.FC<IStripeCheckoutProps> = (props) => {
  const { availability, eventDateId, attendeeEmail, attendeeName, notes, adminNotes, questionAnswers, discount, discountCode, acceptedMarketing, acceptedSurvey, collectAtBoxOffice, stripeAccountId } =
    props;
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [purchaseId, setPurchaseId] = useState(null);
  const [loading, setLoading] = useState('Starting payment...');

  const createIntentToPurchase = (availability: ITicketAvailability): Promise<any> => {
    setError(null);
    setClientSecret(null);
    setPurchaseId(null);

    return StripeHelper.createIntent({
      SessionId: UserHelper.getSessionId(),
      EventId: availability.EventId,
      EventDateId: eventDateId,
      Email: attendeeEmail,
      Notes: notes,
      AdminNotes: adminNotes,
      QuestionAnswers: questionAnswers,
      Seats: availability.Tickets,
      Discount: discount as any,
      DiscountCode: discountCode,
      AttendeeName: attendeeName,
      AllowMarketing: acceptedMarketing,
      AllowSurvey: acceptedSurvey,
      CollectAtBoxOffice: collectAtBoxOffice,
      Type: 'Purchase',
      ReferralGuid: props.referralGuid,
    })
      .then((response) => {
        setPurchaseId(response.purchaseId);
        setClientSecret(response.clientSecret);
        setLoading(null);
      })
      .catch((message) => {
        setError(message);
        setLoading(null);
      });
  };

  useEffect(() => {
    createIntentToPurchase(availability);
  }, []);

  return (
    <>
      <div className="spacer-x2" />
      {error ? (
        <>
          <div className="spacer" />
          <table className="blocks">
            <tbody>
              <BlockInfo type={InfoType.Error}>{error}</BlockInfo>
            </tbody>
          </table>
        </>
      ) : loading ? (
        <Loader theme={ThemeHelper.getEventTheme(props.event)}>{loading}</Loader>
      ) : !clientSecret ? (
        <Loader theme={ThemeHelper.getEventTheme(props.event)}>Getting client secret...</Loader>
      ) : !purchaseId ? (
        <Loader theme={ThemeHelper.getEventTheme(props.event)}>Getting purchase number...</Loader>
      ) : (
        <Elements
          options={{
            clientSecret: clientSecret,
            appearance: StripeHelper.getAppearance(),
            fonts: [
              {
                cssSrc: 'https://use.typekit.net/jli8mqj.css',
              },
            ],
          }}
          stripe={StripeHelper.get(stripeAccountId)}
        >
          <StripeCheckoutForm
            tickets={props.tickets}
            event={props.event}
            organisation={props.organisation}
            eventDate={props.eventDate}
            eventTag={props.eventTag}
            eventDateString={props.eventDateString}
            eventTimeString={props.eventTimeString}
            attendeeEmail={props.attendeeEmail}
            attendeeName={props.attendeeName}
            purchaseId={purchaseId}
          ></StripeCheckoutForm>
          {error}
        </Elements>
      )}
    </>
  );
};

export default StripeCheckout;
