import React, { FunctionComponent, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import Block from '../components/Block';
import BlockHeader from '../components/BlockHeader';
import Button from '../components/Button';
import Modal from '../components/Modal';
import SolidLine from '../components/SolidLine';
import SpacerTable from '../components/SpacerTable';
import BlockTextBox from '../components/BlockTextBox';
import GAHelper from '../helpers/GAHelper';
import ThemeHelper from '../helpers/ThemeHelper';
import { IEvent } from '../interfaces/IEvent';
import { ISeatCategory } from '../interfaces/ISeatCategory';
import CheckBox from '../components/CheckBox';
import Radio from '../components/Radio';

export interface IProps {
  onClose: () => void;
  category: ISeatCategory;
  event: IEvent;
  onChange: (category: ISeatCategory) => void;
}

const SeatCategoryModal = ((props) => {
  const { category, event, onChange, onClose } = props;

  const [name, setName] = useState(category.Name);
  const [description, setDescription] = useState(category.Description);
  const [color, setColor] = useState(category.Colour);
  const [icon, setIcon] = useState(category.Icon ? category.Icon : '');
  const [showColourPicker, setShowColourPicker] = useState(false);

  useEffect(() => {
    GAHelper.modal(`Seat Category`);
  }, []);

  const submit = () => {
    category.Name = name;
    category.Description = description;
    category.Colour = color;
    category.Icon = icon;
    onChange(category);
  };

  return (
    <>
      <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={props.onClose}>
        <div className="content">
          <div className="ticket-rip" />

          <div className="body">
            <SpacerTable>
              <h1>Edit Seat Category</h1>
            </SpacerTable>

            <div className="spacer" />

            <table className="blocks">
              <tbody>
                <Block>
                  <BlockHeader>Name</BlockHeader>
                  <BlockTextBox
                    onKeyPress={(e) => {
                      if (e.charCode == 13) (document.querySelector('#description_field') as any).select();
                    }}
                    type="text"
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e)}
                  />
                </Block>
                <Block>
                  <BlockHeader>Description (Optional)</BlockHeader>
                  <BlockTextBox
                    id="description_field"
                    onKeyPress={(e) => {
                      if (e.charCode == 13) submit();
                    }}
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e)}
                  />
                </Block>
                <Block>
                  <BlockHeader>Colour</BlockHeader>
                  <div className="colour-picker-container">
                    <button
                      style={{ backgroundColor: color }}
                      className="colour-picker"
                      onClick={() => setShowColourPicker(true)}
                    ></button>
                    {showColourPicker && (
                      <>
                        <div className="click-off" onClick={() => setShowColourPicker(false)}></div>
                        <ChromePicker
                          disableAlpha={true}
                          color={color}
                          onChange={(value) => {
                            setColor(value.hex);
                          }}
                        />
                      </>
                    )}
                  </div>
                </Block>

                <Block>
                  <BlockHeader>Does this seat category need an icon?</BlockHeader>
                  <Radio
                    selectedId={icon}
                    onValueChanged={(option) => {
                      setIcon(option.Id);
                    }}
                    options={[
                      {
                        Text: `No icon`,
                        Info: 'No icon will be added to these seats.',
                        Id: '',
                      },
                      {
                        Text: `Wheelchair icon`,
                        Info: 'We will add a wheelchair icon to these seats.',
                        Id: 'wheelchair',
                      },
                      {
                        Text: 'Info icon',
                        Info: 'We will add an info icon to these seats. Be sure to add a description above so there is actually additional information on this seat.',
                        Id: 'info',
                      },
                    ]}
                  />
                </Block>
                {/* 
                <CheckBox
                  title="Wheelchair / Accessible Seat Category"
                  onBoxClick={() => (icon == 'wheelchair' ? setIcon('') : setIcon('wheelchair'))}
                  key="contact_marketing_checkbox"
                  checked={icon == 'wheelchair'}
                >
                  Is this seat category specifically for wheelchair or accessible seats? We will show a disability icon
                  on these seats.
                </CheckBox> */}
              </tbody>
            </table>
            <div className="spacer-x2" />

            <SpacerTable>
              <Button
                disabled={icon == 'info' && (!description || description.length == 0)}
                className="confirm large"
                onExecute={submit}
                text={`Submit`}
              />
            </SpacerTable>
            <div className="spacer" />

            <SpacerTable>
              <Button className="large" onExecute={onClose} text={`Cancel`} />
            </SpacerTable>
            <SolidLine />

            <SpacerTable className="small-font">
              All changes are subject to the Seaty{' '}
              <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and{' '}
              <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
            </SpacerTable>
          </div>

          <div className="ticket-rip bottom" />
        </div>
      </Modal>
    </>
  );
}) as FunctionComponent<IProps>;

export { SeatCategoryModal };
