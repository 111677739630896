import linq from 'linq';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import IMember from '../../../../interfaces/IMember';
import IMemberType from '../../../../interfaces/IMemberType';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import SVGMultiply from '../../../../svg/SVGMultiply';
import SVGPlus from '../../../../svg/SVGPlus';
import AddMemberModal from './AddMemberModal';
import AdminMemberMenu from './AdminMemberMenu';

interface IProps {
  organisation: IOrganisation;
}

const AdminMembers: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState<IMember[]>();
  const [memberTypes, setMemberTypes] = useState<IMemberType[]>([]);
  const [memberTypeFilter, setMemberTypeFilter] = useState(-1);
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState('');
  const [editingMember, setEditingMember] = useState<IMember>(null);

  const { organisation } = props;
  const organisationTag = organisation.OrganisationTag;

  useEffect(() => {
    reload();
  }, []);

  const reload = () => {
    setLoading(true);
    AdminApi.request('GET', `/api/Members?${organisation ? `&organisationTag=${organisation.OrganisationTag}` : ''}`)
      .then((response) => {
        setMemberTypes(response.MemberTypes);
        setMembers(response.Members);
      })
      .catch((message) => {
        console.log(message);
      })
      .finally(() => setLoading(false));
  };

  var defaultMemberType = linq.from(memberTypes).firstOrDefault((mt) => mt.Default == true);

  return (
    <>
      <Helmet>
        <title>#{organisationTag} Members</title>
        <meta name="description" content={`Manage members of your organisation.`} />
      </Helmet>

      <div className="row">
        <div className="col-sm-3 col-md-2 donotprint">
          <AdminMemberMenu organisation={organisation} />
        </div>
        <div className="col-sm-9 col-md-10 donotprint">
          <div className="toolbar">
            <div className="title">Members</div>
            <div className="buttons">
              <button onClick={() => setEditingMember({ Id: null, Name: '', Email: '', MemberTypeId: null })}>
                <SVGPlus />
                Add member
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-8">
              <div className="admin-button-menu inline">
                <button className={memberTypeFilter == -1 ? 'selected' : ''} onClick={() => setMemberTypeFilter(-1)}>
                  All
                </button>
                {linq
                  .from(memberTypes)
                  .orderBy((mt) => mt.Name)
                  .toArray()
                  .map((memberType) => (
                    <button className={memberTypeFilter == memberType.Id ? 'selected' : ''} onClick={() => setMemberTypeFilter(memberType.Id)}>
                      {memberType.Name}
                    </button>
                  ))}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="donotprint" style={{ margin: '0' }}>
                <div className="input">
                  <input
                    id="searchinput"
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                    type="text"
                    placeholder="Search..."
                    value={query}
                    onKeyPress={(e: any) => {
                      if (e.key == 'Enter') {
                        setFilter(query);
                      }
                    }}
                    style={{ margin: '0' }}
                  />

                  <button
                    onClick={() => {
                      setFilter('');
                      setQuery('');
                    }}
                  >
                    <SVGMultiply />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {loading && <Loader inline>Loading members...</Loader>}
          {members && memberTypes && members.length > 0 ? (
            <>
              <div className="orderoverview" style={{ paddingBottom: '100px' }}>
                <div className="container-outer" style={{ minHeight: '300px' }}>
                  <div className="container-inner" style={{ backgroundColor: 'white' }}>
                    <table className="table grid-table">
                      <thead>
                        <tr>
                          <th className="grid-header">Name</th>
                          <th className="grid-header">Email</th>
                          <th className="grid-header">Type</th>
                        </tr>
                      </thead>

                      <tbody className="smooth-details">
                        {linq
                          .from(members)
                          .where((m) => memberTypeFilter == -1 || m.MemberTypeId == memberTypeFilter || (m.MemberTypeId == null && memberTypeFilter == 0))
                          .where((m) => !filter || m.Email.toLocaleLowerCase().includes(filter.toLowerCase()) || m.Name.toLocaleLowerCase().includes(filter.toLowerCase()))
                          .orderBy((m) => m.Name.toLowerCase())
                          .toArray()
                          .map((m) => {
                            var memberType = linq.from(memberTypes).firstOrDefault((mt) => mt.Id == m.MemberTypeId);

                            return (
                              <tr key={m.Id} className="route" onClick={() => setEditingMember(m)}>
                                <td>{m.Name}</td>
                                <td>{m.Email}</td>
                                <td>{memberType ? memberType.Name : defaultMemberType ? defaultMemberType.Name : 'Default'}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p>{organisation.Name} has no members.</p>
          )}
        </div>
      </div>

      {editingMember && (
        <AddMemberModal
          memberTypes={memberTypes}
          organisation={organisation}
          onClose={() => {
            setEditingMember(null);
            reload();
          }}
          member={editingMember}
        />
      )}
    </>
  );
};

export default AdminMembers;
