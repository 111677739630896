import { FunctionComponent, useState } from 'react';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import SpacerTable from '../../components/SpacerTable';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { IOrganisation } from '../../interfaces/IOrganisation';
import MemberService from '../../services/MemberService';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import BlockTextBox from '../../components/BlockTextBox';

interface IProps {
  organisation: IOrganisation;
  overview: any;
  setError: (error: string) => void;
}

const MembershipDetailsSection: FunctionComponent<IProps> = (props) => {
  const organisation = props.organisation;
  const overview = props.overview;
  const member = overview.Member;

  const [memberName, setMemberName] = useState(overview.Member && overview.Member.FriendlyName ? overview.Member.FriendlyName : '');

  const setError = props.setError;
  const [busyMessage, setBusyMessage] = useState(null);

  const saveMemberDetailsClick = () => {
    setBusyMessage('Updating member');
    MemberService.UpdateOrganisationMember({
      id: member.Id,
      email: member.Email,
      organisationId: organisation.Id,
      removeIfExists: false,
      friendlyName: memberName,
    })
      .then(() => {
        overview.Member.FriendlyName = memberName;
        setBusyMessage(null);
      })
      .catch(() => {
        setError('Error occurred when talking to Seaty.');
        setBusyMessage(null);
      });
  };

  if (busyMessage != null) return <Loader inline={true}>{busyMessage}</Loader>;

  return (
    <>
      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>What is the name of the member?</BlockHeader>
            <BlockTextBox
              placeholder={'Member name'}
              autoFocus={true}
              value={memberName}
              onChange={(e) => {
                setMemberName(e);
              }}
            />
          </Block>
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <Button className="confirm" onExecute={saveMemberDetailsClick} text={'Save member name'} />
      </SpacerTable>
    </>
  );
};

export default MembershipDetailsSection;
