import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Loader from '../Loader';
import GeneralApi from '../../api/GeneralApi';
import UserHelper from '../../helpers/UserHelper';
import Modal from '../Modal';
import ThemeHelper from '../../helpers/ThemeHelper';
import SpacerTable from '../SpacerTable';
import BlockInfo, { InfoType } from '../BlockInfo';
import EnvironmentVariables from '../../EnvironmentVariables';
import { IEvent } from '../../interfaces/IEvent';
import { Elements } from '@stripe/react-stripe-js';
import StripeHelper from '../../helpers/StripeHelper';
import StripePaymentForm from './StripePaymentForm';
import CacheHelper from '../../helpers/CacheHelper';
import LocationHelper from '../../helpers/LocationHelper';
import { IPurchase } from '../../interfaces/IPurchase';
import Block from '../Block';

export interface IProps {}

const StripePaymentPage: FunctionComponent<IProps> = () => {
  const [loading, setLoading] = useState('Starting payment...');
  const [error, setError] = useState('');
  const [event, setEvent] = useState<IEvent>(null);
  const [purchase, setPurchase] = useState<IPurchase>(null);
  const [stripeAccountId, setStripeAccountId] = useState(null);

  const url = LocationHelper.getLocation().pathname;
  const eventTag = url.split('/')[1];
  const purchaseId = url.split('/')[3];
  const clientSecret = url.split('/')[4];

  const getPurchase = (): Promise<any> => {
    setError(null);

    return GeneralApi.request('GET', `/api/StripeIntent?sessionId=${UserHelper.getSessionId()}&purchaseId=${purchaseId}`)
      .then((response) => {
        setPurchase(response);
        setStripeAccountId(response.StripeAccountId);
        setLoading(null);
      })
      .catch((message) => {
        setError(message && message.length > 0 ? message : 'Error when setting up intent to pay.');
        setLoading(null);
      });
  };

  useEffect(() => {
    CacheHelper.eventByTag(eventTag)
      .then((e) => {
        setEvent(e);

        getPurchase();
      })
      .catch(() => {
        alert('Event could not be found...');
      });
  }, []);

  const theme = ThemeHelper.getEventTheme(event);

  return (
    <>
      <Helmet>
        <meta property="og:url" content={`https://Seaty.co.uk/`} />
        <meta property="fb:app_id" content="747095462055934" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Payment`} />
        <meta property="og:description" content={`Payment.`} />
        <meta property="og:image" content={''} />
        <title>Payment</title>
        <meta name="description" content={`Payment.`} />
      </Helmet>

      {!event ? (
        <Loader>Loading event...</Loader>
      ) : (
        <Modal theme={theme}>
          <div className="content">
            <div className="ticket-rip" />

            <div className="body">
              <div className="spacer">
                {error ? (
                  <BlockInfo type={InfoType.Error}>{error}</BlockInfo>
                ) : loading ? (
                  <Loader inline>{loading}</Loader>
                ) : !clientSecret ? (
                  <Loader inline>Getting client secret...</Loader>
                ) : !purchase ? (
                  <Loader inline>Getting purchase...</Loader>
                ) : (
                  <Elements
                    options={{
                      clientSecret: clientSecret,
                      appearance: StripeHelper.getAppearance(),
                      fonts: [
                        {
                          cssSrc: 'https://use.typekit.net/jli8mqj.css',
                        },
                      ],
                    }}
                    stripe={StripeHelper.get(stripeAccountId)}
                  >
                    <SpacerTable>
                      <h1>{purchase.Type == 'Balance' ? 'Balance Payment' : 'Payment'}</h1>
                      Please enter the payment details and submit.
                    </SpacerTable>

                    <div className="spacer" />
                    <StripePaymentForm event={event} purchase={purchase}></StripePaymentForm>
                  </Elements>
                )}
              </div>
            </div>

            <div className="ticket-rip bottom" />
          </div>
        </Modal>
      )}
    </>
  );
};

export default StripePaymentPage;
