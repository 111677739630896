import linq from 'linq';
import moment from 'moment';
import { FunctionComponent } from 'react';
import { IEvent } from '../../../../interfaces/IEvent';
import SVGCheck from '../../../../svg/SVGCheck';

export interface IComparisonEvent {
  DateTo: string;
  DateFrom: string;
  Name: string;
  Organisation: any;
}

export interface IProps {
  events: Array<IEvent>;
  min: string;
  setMin: (value: string) => void;
  max: string;
  setMax: (value: string) => void;
  selectedEvents: number[];
  handleEventClick: (event: IEvent) => void;
  onCompareClick: () => void;
}

const AdminCompareOptions: FunctionComponent<IProps> = (props) => {
  let { events, selectedEvents } = props;

  return (
    <>
      <div className="orderoverview" style={{ paddingBottom: '100px' }}>
        <div className="container-outer" style={{ minHeight: '300px' }}>
          <div className="container-inner" style={{ backgroundColor: 'white' }}>
            <table className="table grid-table">
              <thead>
                <tr>
                  <th></th>
                  <th className="grid-header">Event</th>
                  <th className="grid-header">Event Tag</th>
                  <th className="grid-header">Date</th>
                  {/* <th className="grid-header">Organisation</th> */}
                </tr>
              </thead>

              <tbody className="smooth-details">
                {linq
                  .from(events)
                  .orderBy((e) => e.TourId)
                  .thenByDescending((e) => moment(e.DateFrom).unix())
                  .toArray()
                  .map((e) => {
                    var isSelected =
                      linq
                        .from(selectedEvents)
                        .where((ee) => ee == e.Id)
                        .toArray().length > 0;
                    return (
                      <tr key={e.Id}>
                        <td className="check-cell">
                          <button onClick={() => props.handleEventClick(e)} className={`${isSelected ? ' selected' : ''}`}>
                            <SVGCheck />
                          </button>
                        </td>
                        <td>{e.Name}</td>
                        <td>
                          <span style={{ opacity: 0.7 }}>#{e.EventTag}</span>
                        </td>
                        <td>{e.DateFrom == e.DateTo ? e.DateFrom : `${e.DateFrom} - ${e.DateTo}`}</td>
                        {/* <td>{e.Organisation.Name}</td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCompareOptions;
