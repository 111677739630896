import linq from 'linq';
import moment from 'moment';
import { FunctionComponent, useEffect, useState, useRef } from 'react';
import AdminApi from '../../api/AdminApi';
import GeneralApi from '../../api/GeneralApi';
import NumberHelper from '../../helpers/NumberHelper';
import OrderHelper from '../../helpers/OrderHelper';
import StripeHelper from '../../helpers/StripeHelper';
import UserHelper from '../../helpers/UserHelper';
import { IDiscount } from '../../interfaces/IDiscount';
import { IEvent } from '../../interfaces/IEvent';
import { ITicket } from '../../interfaces/ITicket';
import Block from '../Block';
import BlockHeader from '../BlockHeader';
import BlockInfo, { InfoType } from '../BlockInfo';
import BlockTextBox from '../BlockTextBox';
import Button from '../Button';
import CheckBox from '../CheckBox';
import DateAndTimePicker from '../DateAndTimePicker';
import Loader from '../Loader';
import Radio from '../Radio';
import SpacerTable from '../SpacerTable';
import EnvironmentVariables from '../../EnvironmentVariables';
import ThemeHelper from '../../helpers/ThemeHelper';
import { IPayment } from '../../interfaces/IPayment';

export enum PaymentType {
  Cash = 1,
  Cheque = 2,
  CardOutSideSeaty = 3,
  BankTransfer = 4,
  CardSeaty = 5,
  RefundByAdmin = 6,
  HandlingFee = 7,
  HandlingFeeRefunds = 8,
  RefundedToCardWithSeaty = 9,
}

interface IProps {
  event: IEvent;
  currency: string;
  discounts?: Array<IDiscount>;
  tickets?: Array<ITicket>;
  orderId?: number;
  attendeeName?: string;
  balance?: number;
  payeeEmail: string;
  eventId: number;
  feePercentage: number;
  organisationFeePercentage: number;
  absorbFee: boolean;
  isAdmin: boolean;
  minimumFee: number;
  paymentMadeCallback: () => void;
  payment?: IPayment;
}

const PaymentControl: FunctionComponent<IProps> = (props) => {
  const [paymentType, setPaymentType] = useState<PaymentType>(props.payment ? props.payment.TypeId : props.isAdmin ? PaymentType.Cash : PaymentType.CardSeaty);
  const [notes, setNotes] = useState<string>(props.payment ? props.payment.Notes : '');
  const [paymentAmountType, setPaymentAmountType] = useState(props.tickets == null ? 'fullbalance' : 'other');
  const [customPaymentAmount, setCustomPaymentAmount] = useState('0.00');
  const [paymentErrorMessage, setPaymentErrorMessage] = useState(null);
  const [busyMessage, setBusyMessage] = useState('Loading payment control...');
  const [completed, setCompleted] = useState(false);
  const [datePaymentMade, setDatePaymentMade] = useState(props.payment ? moment(props.payment.DatePaymentMade) : moment());
  const [tickets, setTickets] = useState(
    props.tickets &&
      props.tickets.length > 0 &&
      props.tickets.map((ticket: any) => {
        return { ...ticket, Selected: !(ticket.PaymentTaken || ticket.Cancelled) };
      }),
  );

  const { currency, discounts, event, isAdmin } = props;
  const hasDiscounts = discounts != null && discounts.length > 0;
  const [popup, setPopup] = useState<Window | null>(null);
  const popupCheckInterval = useRef<number | null>(null);

  const openPopup = (url) => {
    const width = 1000;
    const height = 1200;

    const left = Math.ceil(window.outerWidth / 2 + window.screenX - width / 2);
    const top = Math.ceil(window.outerHeight / 2 + window.screenY - height / 2);

    const win = window.open(url, '_blank', `width=${width},height=${height},top=${top},left=${left},scrollbars=yes,location=no,menubar=no,toolbar=no`);
    if (!win || win.closed || typeof win.closed == 'undefined') {
      // Fallback behavior or messaging for when the popup fails
      alert('Failed to open popup.');
    }
    setPopup(win);

    // Setting up an interval to check if the window is closed
    popupCheckInterval.current = window.setInterval(() => {
      if (win && win.closed) {
        clearInterval(popupCheckInterval.current);
        setPopup(null);
        // Handle window close event
      }
    }, 100);
  };

  useEffect(() => {
    // Clean up interval on unmount
    return () => {
      if (popupCheckInterval.current) {
        clearInterval(popupCheckInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    StripeHelper.get(event.Organisation.StripeAccountId);
    setBusyMessage(null);
  }, []);

  const [contactingStripe, setContactingStripe] = useState(false);

  const continueToPayment = () => {
    setBusyMessage('Initialising payment...');

    var callback = function (result) {
      if (result.Valid) {
        setCompleted(true);
        setPaymentErrorMessage(null);

        if (props.paymentMadeCallback) {
          props.paymentMadeCallback();
        }
      } else {
        setPaymentErrorMessage(result.ErrorMessage);
      }

      setBusyMessage(null);
    };

    if (paymentType == PaymentType.CardSeaty) {
      if (props.orderId != null) {
        submitOrderCardPayment(callback);
      } else {
        submitBalanceCardPayment();
      }
    } else {
      if (props.orderId != null) {
        submitOrderPayment(callback);
      } else {
        submitBalancePayment(callback);
      }
    }
  };

  const submitOrderCardPayment = (callback) => {
    setBusyMessage('Submitting card payment...');
    var tickets = linq
      .from(props.tickets)
      .where(function (s) {
        return s.Selected;
      })
      .select(function (seat) {
        return {
          Group: seat.Group,
          Name: seat.Name,
          OrderId: props.orderId,
          SeatCategoryId: seat.SeatCategoryId,
          CategoryId: seat.TicketCategoryId,
          TicketCategoryId: seat.TicketCategoryId,
        };
      })
      .toArray();

    // GeneralApi.request('POST', '/api/OrderCardPayment', {
    //   notes: notes,
    //   orderId: props.orderId,
    //   seats: tickets,
    //   paymentMethodId: paymentMethodId,
    //   paymentIntentId: paymentIntentId,
    //   existingPurchase: existingPurchase,
    // })
    //   .then((response) => {
    //     if (response.requires_action) {
    //       stripe.handleCardAction(response.payment_intent_client_secret).then((cardActionResult: any) => {
    //         if (cardActionResult.error) {
    //           callback({ ErrorMessage: cardActionResult.error, Valid: false });
    //         } else {
    //           submitOrderCardPayment(null, cardActionResult.paymentIntent.id, callback, response.Purchase);
    //         }
    //       });
    //     } else if (response.success) {
    //       callback({ Valid: true });
    //     } else if (response.message) {
    //       callback({ ErrorMessage: response.message, Valid: false });
    //     } else if (response.ErrorMessage) {
    //       callback({ ErrorMessage: response.ErrorMessage, Valid: false });
    //     } else {
    //       callback({ ErrorMessage: 'Unable to process payment', Valid: false });
    //     }
    //   })
    //   .catch((message) => callback({ ErrorMessage: message, Valid: false }));
  };

  const editPayment = () => {
    setBusyMessage('Editing payment...');

    AdminApi.request('POST', '/api/EditPayment', {
      payment: { ...props.payment, TypeId: paymentType, Notes: notes, DatePaymentMade: datePaymentMade.format('YYYY-MM-DD HH:mm') } as IPayment,
      eventId: props.eventId,
    })
      .then((result: IPayment) => {
        props.payment.TypeId = result.TypeId;
        props.payment.Notes = result.Notes;
        props.payment.DatePaymentMade = result.DatePaymentMade;

        setPaymentType(result.TypeId);
        setDatePaymentMade(moment(result.DatePaymentMade));
        setNotes(result.Notes);

        setBusyMessage(null);

        console.log(result);
      })
      .catch((message) => {
        alert(message);
        setBusyMessage(null);
        console.log(message);
      });
  };

  const submitOrderPayment = (callback) => {
    setBusyMessage('Submitting order payment...');
    var tickets = linq
      .from(props.tickets)
      .where(function (s) {
        return s.Selected;
      })
      .select(function (seat) {
        return {
          Group: seat.Group,
          Name: seat.Name,
          OrderId: props.orderId,
          SeatCategoryId: seat.SeatCategoryId,
          CategoryId: seat.TicketCategoryId,
          TicketCategoryId: seat.TicketCategoryId,
        };
      })
      .toArray();

    AdminApi.request('POST', '/api/OrderPayment', {
      notes: notes,
      orderId: props.orderId,
      seats: tickets,
      type: paymentType,
      datePaymentMade: datePaymentMade.format('YYYY-MM-DD HH:mm'),
    })
      .then(() => {
        callback({ Valid: true });
      })
      .catch((message) => {
        callback({ ErrorMessage: message, Valid: false });
      });
  };

  const submitBalanceCardPayment = (): Promise<any> => {
    var amount = 0.0;

    if (paymentAmountType == 'fullbalance') {
      amount = props.balance / 100.0;
    } else {
      amount = parseFloat(customPaymentAmount);
    }

    const name = UserHelper.currentUser.Name;

    return StripeHelper.createIntent({
      SessionId: UserHelper.getSessionId(),
      Email: props.payeeEmail,
      AttendeeName: name,
      EventId: event.Id,
      PriceAsInt: amount * 100,
      Notes: notes,
      Type: 'Balance',
    }).then((result) => {
      const paymentUrl = `${EnvironmentVariables.HOME_URL}/${props.event.EventTag}/InitialisePayment/${result.purchaseId}/${result.clientSecret}?returnTo=MyTickets`;
      window.location.href = paymentUrl;
      // openPopup(paymentUrl);
      //setBusyMessage(null);
    });
  };

  const submitBalancePayment = (callback) => {
    var amount = 0.0;

    if (paymentAmountType == 'fullbalance') {
      amount = props.balance / 100.0;
    } else {
      amount = parseFloat(customPaymentAmount);
    }

    const amountValue = Math.round(amount * 100.0);

    AdminApi.request('POST', '/api/BalancePayment', {
      payeeEmail: props.payeeEmail,
      eventId: event.Id,
      amount: amountValue,
      notes: notes,
      isRefund: false,
      type: paymentType,
      datePaymentMade: datePaymentMade.format('YYYY-MM-DD HH:mm'),
    })
      .then((response) => {
        callback({ Valid: true });
      })
      .catch((message) => {
        callback({ ErrorMessage: message, Valid: false });
      });
  };

  const renderAmountSelector = () => {
    if (props.balance <= 0) return null;
    if (props.payment) return null;

    return (
      <>
        <Block>
          <BlockHeader>
            <BlockHeader>How much is being paid?</BlockHeader>
          </BlockHeader>
          <Radio
            selectedId={paymentAmountType}
            onValueChanged={(option) => setPaymentAmountType(option.Id)}
            options={[
              {
                Text: `Full balance of ${currency + (props.balance / 100.0).toFixed(2)}`,
                Id: 'fullbalance',
              },
              { Text: 'Other amount', Id: 'other' },
            ]}
          />
        </Block>
        {paymentAmountType === 'other' && (
          <Block>
            <BlockHeader>Amount</BlockHeader>
            <BlockTextBox
              type="number"
              style={{ minWdith: '150px', width: '50%' }}
              placeholder={'Enter an amount'}
              value={customPaymentAmount}
              autoFocus={true}
              symbol={event.CurrencySymbol}
              onChange={(e) => {
                setCustomPaymentAmount(e);
              }}
            />
          </Block>
        )}
      </>
    );
  };

  var selectedTicketsPrice =
    linq
      .from(props.tickets)
      .where(function (t) {
        return t.Selected;
      })
      .sum(function (t) {
        return t.PriceAsInt;
      }) / 100.0;

  var paymentAmount = 0.0;
  var cardFee = 0;

  if (paymentAmountType != 'other' && props.balance != null && props.balance > 0) {
    paymentAmount = props.balance / 100.0;
  } else if (paymentAmountType == 'other' && props.balance != null && props.balance > 0) {
    paymentAmount = NumberHelper.isNumeric(customPaymentAmount) ? parseFloat(customPaymentAmount) : 0.0;
  } else {
    paymentAmount = parseFloat(selectedTicketsPrice.toString());
  }

  if (hasDiscounts) {
    paymentAmount -=
      linq.from(discounts).sum(function (d) {
        return d.Amount;
      }) / 100.0;
  }

  if (paymentType === PaymentType.CardSeaty) {
    if (!props.absorbFee) {
      const fees = OrderHelper.getFees(paymentAmount, paymentType === PaymentType.CardSeaty, props.minimumFee / 100.0, props.feePercentage, 0, 0, props.absorbFee, props.organisationFeePercentage);

      cardFee = fees.organisationFee + fees.seatyFee;
      paymentAmount = fees.total;
    }
  }

  if (busyMessage != null) {
    return <Loader inline={true}>{busyMessage}</Loader>;
  }

  if (completed) {
    return (
      <table className="blocks">
        <tbody>
          <BlockInfo type={InfoType.Info}>
            Payment of <span>{currency + parseFloat(paymentAmount.toString()).toFixed(2)}</span> has been submitted.
          </BlockInfo>
        </tbody>
      </table>
    );
  }

  const isCustomValueAboveTotal = NumberHelper.isNumeric(customPaymentAmount) && parseFloat(customPaymentAmount) > props.balance / 100.0;

  return (
    <>
      <SpacerTable>
        <h1>
          {props.payment ? 'Edit ' : ''}
          {props.orderId ? `Order #${props.orderId} Payment` : 'Balance Payment'}
        </h1>
      </SpacerTable>
      <div className="spacer" />

      <table className="blocks">
        <tbody>
          {props.balance != null && props.balance > 0 ? renderAmountSelector() : null}

          {props.tickets != null &&
            props.tickets.length > 0 &&
            linq
              .from(props.tickets)
              .where(function (t: ITicket) {
                return !t.Cancelled && !t.PaymentTaken;
              })
              .select((ticket) => {
                const content = (
                  <>
                    <BlockHeader>
                      <td className="ticket-font" style={{ color: ticket.SeatCategoryColour }}>
                        {ticket.Group + ticket.Name} {ticket.SeatCategory as any}
                      </td>
                      <td className="right">
                        <span style={{ color: ticket.CategoryColour }}>
                          {ticket.CategoryName} {ticket.PriceAsString}
                        </span>
                      </td>
                    </BlockHeader>
                    {ticket.PaymentTaken ? 'Paid' : null}
                  </>
                );

                if (!hasDiscounts)
                  return (
                    <CheckBox
                      key={ticket.Group + ticket.Name}
                      checked={ticket.Selected}
                      onBoxClick={() => {
                        ticket.Selected = !ticket.Selected;
                        setTickets({ ...tickets });
                      }}
                    >
                      {content}
                    </CheckBox>
                  );

                return <Block key={ticket.Group + ticket.Name}>{content}</Block>;
              })}

          {hasDiscounts ? (
            <>
              {discounts.map((discount) => (
                <Block key={discount.Name}>
                  <BlockHeader>{discount.Name}</BlockHeader>
                  Discount of {currency + (discount.Amount / 100.0).toFixed(2)} applied for this order
                </Block>
              ))}
              <BlockInfo type={InfoType.Info}>Active discounts mean payment must be for all tickets in the order.</BlockInfo>
            </>
          ) : null}

          {isAdmin && (
            <>
              <Block>
                <BlockHeader>What type of payment is being made?</BlockHeader>
                <Radio
                  selectedId={paymentType}
                  onValueChanged={(paymentMethod) => {
                    setPaymentType(paymentMethod.Id);
                  }}
                  options={[
                    { Text: 'Paid with cash', Id: PaymentType.Cash },
                    { Text: 'Paid with cheque', Id: PaymentType.Cheque },
                    {
                      Text: 'Paid by bank transfer',
                      Id: PaymentType.BankTransfer,
                    },
                    {
                      Text: 'Paid with card outside of Seaty',
                      Id: PaymentType.CardOutSideSeaty,
                    },
                  ]}
                />

                {paymentType == PaymentType.CardSeaty && <div className="spacer-x05" />}
              </Block>
            </>
          )}
          {cardFee > 0 && paymentType === PaymentType.CardSeaty && <BlockInfo type={InfoType.Info}>Fee for online payment of {currency + parseFloat(cardFee.toString()).toFixed(2)}</BlockInfo>}
          {paymentType != PaymentType.CardSeaty && (
            <>
              <Block>
                <BlockHeader>Date payment made</BlockHeader>
                <DateAndTimePicker
                  initialValue={datePaymentMade}
                  groupName={`DateTimeDatePaymentMade`}
                  onChange={(_date) => {
                    if (_date.isDateTime) {
                      setDatePaymentMade(_date.moment);
                    }
                  }}
                />
              </Block>
            </>
          )}

          {isAdmin && (
            <Block>
              <BlockHeader>Payment notes</BlockHeader>
              <BlockTextBox
                value={notes}
                onChange={(e) => {
                  setNotes(e);
                }}
                rows={2}
              />
            </Block>
          )}

          {isCustomValueAboveTotal && paymentAmountType === 'other' && <BlockInfo type={InfoType.Error}>Custom amount cannot be above total amount owed.</BlockInfo>}
          {paymentErrorMessage && <BlockInfo type={InfoType.Error}>{paymentErrorMessage}</BlockInfo>}
        </tbody>
      </table>
      <div className="spacer" />

      <SpacerTable>
        {props.payment ? (
          <Button className="confirm" onExecute={editPayment} text={`Submit edit`} />
        ) : (
          <Button
            disabled={(isCustomValueAboveTotal && paymentAmountType === 'other') || paymentAmount === 0 || contactingStripe}
            className="confirm"
            onExecute={continueToPayment}
            text={paymentType === PaymentType.CardSeaty ? `Go to payment` : `Submit payment`}
          />
        )}
      </SpacerTable>

      {event && popup && <Loader theme={ThemeHelper.getEventTheme(event)}>Awaiting payment...</Loader>}
    </>
  );
};

export default PaymentControl;
