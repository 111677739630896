import React, { useState } from 'react';
import { IOrganisation } from '../interfaces/IOrganisation';

interface IProps {
  organisation: IOrganisation;
}

const NeedHelp: React.FC<IProps> = (props: IProps) => {
  const organisation = props.organisation;

  return (
    <>
      <div className="strip-block">
        Need help?
        <div className="text">
          <div style={{ marginBottom: '15px' }}>
            Have a question about this event? Best to get in touch with the event's box office. <br />
            Having an issue with Seaty or need help with something else? Feel free to get in touch!
          </div>
          <div>
            {organisation.BoxOfficeEmail && (
              <div style={{ marginBottom: '15px' }}>
                <a href={`mailto:${organisation.BoxOfficeEmail}`} target="_top">
                  For event and ticket questions, email the box office at {organisation.BoxOfficeEmail}
                </a>
              </div>
            )}
            {organisation.BoxOfficeTel && <div style={{ marginBottom: '15px' }}>Call the box office on {organisation.BoxOfficeTel}</div>}
          </div>
          <div>
            <a className="bottomLink" href="mailto:support@seaty.co.uk">
              For technical issues with the website, email Seaty at Support@Seaty.co.uk
            </a>
          </div>
        </div>
      </div>

      <div className="strip-block" style={{ background: 'white' }}>
        Setting up your own event?
        <div className="text">
          If you're looking for a simple ticketing system for your next event, look no further than Seaty!
          <div>
            <a href={`/Home/MoreInfo`}>Find out more</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NeedHelp;
