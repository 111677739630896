import ReactGA from 'react-ga4';

export default class GAHelper {
  public static initialised = false;

  public static init() {
    const consent = localStorage.getItem('ga_consent');
    if (consent === 'true') {
      ReactGA.initialize([
        {
          trackingId: 'G-GMMS3WREQN',
          gaOptions: {}, // optional
          gtagOptions: {}, // optional
        },
      ]);

      GAHelper.initialised = true;
    }
  }

  public static visit(page: string) {
    if (!GAHelper.initialised) return;
    ReactGA.send({ hitType: 'pageview', page: page, title: 'Page view' });
  }

  public static modal(page) {
    if (!GAHelper.initialised) return;
    ReactGA.event({
      category: 'User interaction',
      action: 'Modal open',
      label: page, // optional
    });
  }

  public static event = (categoryName, eventName, value?: number) => {
    if (!GAHelper.initialised) return;
    ReactGA.event({
      category: categoryName, // Required
      action: eventName, // Required
      value: value,
      nonInteraction: false,
    });
  };

  public static exception = (detail: string) => {
    if (!GAHelper.initialised) return;
    ReactGA.event({
      category: 'Errors',
      action: 'Exception',
      label: detail, // optional
    });
  };
}
