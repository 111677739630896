import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import Button from '../../components/Button';
import SpacerTable from '../../components/SpacerTable';
import Theme from '../../components/Theme';
import StringHelper from '../../helpers/StringHelper';
import SVGHelper from '../../helpers/SVGHelper';
import ThemeHelper from '../../helpers/ThemeHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { ITour } from '../../interfaces/ITour';
import SVGImage from '../../svg/SVGImage';
import SVGMinus from '../../svg/SVGMinus';
import SVGPlus from '../../svg/SVGPlus';
import SVGToggleOff from '../../svg/SVGToggleOff';
import SVGToggleOn from '../../svg/SVGToggleOn';
import ImageUploader, { IImageUploadRequest, ImageRequestGroup } from './ImageUploader';
import './ThemeSection.scss';
import Toolbar from './toolbar/Toolbar';
import { ITheme } from '../../interfaces/ITheme';
import SVGMultiply from '../../svg/SVGMultiply';

export interface IProps {
  tour?: ITour;
  onTourUpdated?: (e: ITour) => void;
  organisation?: IOrganisation;
  onOrganisationUpdated?: (e: IOrganisation) => void;
  event?: IEvent;
  onEventUpdated?: (e: IEvent) => void;
  globalOptions: JSX.Element;
  setBusyMessage: (value: string) => void;
  onImageRequested: (request: IImageUploadRequest) => void;
  imageRequests: { [key: string]: IImageUploadRequest };
}

const ThemeSection: React.FC<IProps> = (props) => {
  const { imageRequests, onImageRequested } = props;
  const [showColourPicker, setShowColourPicker] = useState(false);

  let theme = props.event ? props.event.Theme : props.organisation ? props.organisation.Theme : props.tour.Theme;

  if (!theme) {
    theme = ThemeHelper.getDefaultTheme();
  }

  if (!theme.MainColour) {
    theme.MainColour = ThemeHelper.getDefaultTheme().MainColour;
  }

  const onUpdated = (theme: ITheme) => {
    if (props.onEventUpdated) {
      props.onEventUpdated({ ...props.event, Theme: { ...theme } });
    }
    if (props.onOrganisationUpdated) {
      props.onOrganisationUpdated({ ...props.organisation, Theme: { ...theme } });
    }
    if (props.onTourUpdated) {
      props.onTourUpdated({ ...props.tour, Theme: { ...theme } });
    }
  };

  const disableTheme = theme ? (props.event ? props.event.DisableTheme : props.organisation ? props.organisation.DisableTheme : props.tour ? props.tour.DisableTheme : true) : true;

  const toolbar = (
    <Toolbar>
      <div className="options">
        {props.globalOptions}

        <div
          className="option"
          onClick={() => {
            const t = !disableTheme ? null : ThemeHelper.getDefaultTheme();

            if (props.event) {
              props.onEventUpdated({ ...props.event, DisableTheme: !disableTheme, Theme: t });
            } else if (props.organisation) {
              props.onOrganisationUpdated({ ...props.organisation, DisableTheme: !disableTheme, Theme: t });
            } else if (props.tour) {
              props.onTourUpdated({ ...props.tour, DisableTheme: !disableTheme, Theme: t });
            }
          }}
        >
          <label>{disableTheme ? 'Enable' : 'Disable'}</label>
          <div className="icon">{disableTheme ? <SVGToggleOff /> : <SVGToggleOn />}</div>
        </div>
      </div>
    </Toolbar>
  );

  if (disableTheme || !theme) {
    return (
      <>
        {toolbar}
        <div className="info">
          <strong>Theme Disabled</strong>
          <br />
          Themes are used in priority of event theme first, then tour theme, then organisation theme. So if you have no event theme and no tour theme, an organisation theme will be used.
        </div>
      </>
    );
  }

  const headerImageUrl = !theme ? '' : imageRequests[ImageRequestGroup.Theme] ? imageRequests[ImageRequestGroup.Theme].url : theme.HeaderImageUrl;
  const backgroundImageUrl = !theme ? '' : imageRequests[ImageRequestGroup.ThemeBackground] ? imageRequests[ImageRequestGroup.ThemeBackground].url : theme.BackgroundImageUrl;

  const padding = StringHelper.replaceAll(theme.HeaderImagePadding ? theme.HeaderImagePadding.toLowerCase() : '0 0', 'px', '');
  const paddingX = padding.indexOf(' ') > -1 ? padding.split(' ')[1] : '0';
  const paddingY = padding.indexOf(' ') > -1 ? padding.split(' ')[0] : '0';

  return (
    <>
      {toolbar}

      <h2>Background</h2>
      <Toolbar>
        <div className="options">
          <div className="option dropdown color-picker">
            <label>Colour</label>
            <span
              className="icon"
              style={{
                backgroundColor: theme.MainColour,
              }}
            ></span>

            <button className="notch" onClick={() => setShowColourPicker(!showColourPicker)}></button>

            {showColourPicker && (
              <>
                <div className="click-off" onClick={() => setShowColourPicker(false)}></div>
                <ChromePicker
                  disableAlpha={true}
                  color={theme.MainColour}
                  onChange={(value) => {
                    onUpdated({ ...theme, MainColour: value.hex });
                  }}
                />
              </>
            )}
          </div>

          {backgroundImageUrl && backgroundImageUrl.length > 0 ? (
            <>
              <div
                className="option"
                onClick={() => {
                  delete imageRequests[ImageRequestGroup.ThemeBackground];
                  onUpdated({ ...theme, BackgroundImageUrl: null });
                }}
              >
                <label>Clear</label>
                <div className="icon">
                  <SVGMultiply />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="option">
                <label>Add Image</label>
                <div className="icon">
                  <SVGImage />
                </div>

                <ImageUploader
                  eventId={props.event ? props.event.Id : null}
                  organisationId={props.organisation ? props.organisation.Id : null}
                  tourId={props.tour ? props.tour.Id : null}
                  url={backgroundImageUrl}
                  onChange={(request) => onImageRequested(request)}
                  group={ImageRequestGroup.ThemeBackground}
                />
              </div>
            </>
          )}

          <div className="option" onClick={() => onUpdated({ ...theme, Sparkle: !theme.Sparkle })}>
            <label>{theme.Sparkle ? 'Sparkle' : 'Standard'}</label>
            <div className="icon">
              <img className="smaller" src={SVGHelper.get('Sparkle')} />
            </div>
          </div>
        </div>
      </Toolbar>

      <h2>Header</h2>
      <Toolbar>
        <div className="options">
          {headerImageUrl && headerImageUrl.length > 0 ? (
            <>
              <div
                className="option"
                onClick={() => {
                  delete imageRequests[ImageRequestGroup.Theme];
                  onUpdated({ ...theme, HeaderImagePadding: '0px 0px', HeaderImageUrl: null });
                }}
              >
                <label>Clear</label>
                <div className="icon">
                  <SVGMultiply />
                </div>
              </div>
              <div className="option counter">
                <label>Padding X</label>
                <div className="control">
                  <button disabled={parseInt(paddingX) <= 0} onClick={() => onUpdated({ ...theme, HeaderImagePadding: `${paddingY}px ${parseInt(paddingX) > 0 ? parseInt(paddingX) - 5 : 0}px` })}>
                    <SVGMinus />
                  </button>
                  <input type="text" readOnly value={paddingX} />
                  <button onClick={() => onUpdated({ ...theme, HeaderImagePadding: `${paddingY}px ${parseInt(paddingX) + 5}px` })}>
                    <SVGPlus />
                  </button>
                </div>
              </div>
              <div className="option counter">
                <label>Padding Y</label>
                <div className="control">
                  <button disabled={parseInt(paddingY) <= 0} onClick={() => onUpdated({ ...theme, HeaderImagePadding: `${parseInt(paddingY) > 0 ? parseInt(paddingY) - 5 : 0}px ${paddingX}px` })}>
                    <SVGMinus />
                  </button>
                  <input type="text" readOnly value={paddingY} />
                  <button onClick={() => onUpdated({ ...theme, HeaderImagePadding: `${parseInt(paddingY) + 5}px ${paddingX}px` })}>
                    <SVGPlus />
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="option">
                <label>Add Image</label>
                <div className="icon">
                  <SVGImage />
                </div>

                <ImageUploader
                  eventId={props.event ? props.event.Id : null}
                  organisationId={props.organisation ? props.organisation.Id : null}
                  tourId={props.tour ? props.tour.Id : null}
                  url={headerImageUrl}
                  onChange={(request) => onImageRequested(request)}
                  group={ImageRequestGroup.Theme}
                />
              </div>
            </>
          )}
        </div>
      </Toolbar>

      <h2>Preview</h2>
      <div className="theme-container">
        <Theme
          backgroundClassName={'theme-background'}
          headerChildren={<div className="close" onClick={() => alert('Example close dialog button...')}></div>}
          className="seaty-modal"
          theme={{ ...theme, BackgroundImageUrl: backgroundImageUrl, HeaderImageUrl: headerImageUrl }}
        >
          <div className="content">
            <div className="ticket-rip" />

            <div className="body">
              {props.event && (
                <SpacerTable>
                  <h1>{props.event.Name}</h1>
                  <div>{props.event.Organisation.Name}</div>
                  <div>
                    {props.event.Venue.Name}, {props.event.Venue.Postcode}
                  </div>
                </SpacerTable>
              )}

              {props.organisation && (
                <SpacerTable>
                  <h1>Event Name</h1>
                  <div>{props.organisation.Name}</div>
                  <div>Venue Name, Postcode</div>
                </SpacerTable>
              )}

              {props.tour && (
                <SpacerTable>
                  <h1>{props.tour.Name}</h1>
                  <div>Organisation Name</div>
                  <div>Venue Name, Postcode</div>
                </SpacerTable>
              )}

              <div className="spacer" />

              <table className="blocks">
                <tbody>
                  <BlockInfo type={InfoType.Info}>
                    Welcome to the theme editor! This is an example of how dialogs and emails will appear to users interacting with your event. You can use the toolbar at the top to choose your own
                    colour / branding.
                    <br />
                    <br />
                    Organisations, tours and events can have themes. Events will always try to use their own theme first, if they have none they will use their tours theme, if they are not part of a
                    tour, they will use their organisations theme. If you have not added a theme, they will use the default Seaty theme.
                    <br />
                    <br />
                    If you use your own custom header image, take into account the close button looking correct and make sure it isnt too tall. The standard width for a header image is 500 pixels, and
                    heights under 400 pixels look best.
                    <br />
                    <br />
                    Remember that background images are optional and repeating, so keep them small and make sure they are seamless for the best results.
                  </BlockInfo>
                  <Block>
                    <BlockHeader>
                      <td style={{ color: '#52c454' }}>A1</td>
                      <td
                        className="right"
                        style={{
                          color: '#386477',
                        }}
                      >
                        Example Ticket
                      </td>
                    </BlockHeader>

                    <table>
                      <tbody>
                        <tr>
                          <td>Adult</td>
                          <td className="right">£15.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </Block>
                  <Block>
                    <BlockHeader>
                      <td style={{ color: '#52c454' }}>A2</td>
                      <td
                        className="right"
                        style={{
                          color: '#386477',
                        }}
                      >
                        Example Ticket
                      </td>
                    </BlockHeader>

                    <table>
                      <tbody>
                        <tr>
                          <td>Child</td>
                          <td className="right">£8.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </Block>
                  <Block>
                    <BlockHeader>
                      <td style={{ color: '#52c454' }}>A3</td>
                      <td
                        className="right"
                        style={{
                          color: '#386477',
                        }}
                      >
                        Example Ticket
                      </td>
                    </BlockHeader>

                    <table>
                      <tbody>
                        <tr>
                          <td>Child</td>
                          <td className="right">£8.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </Block>
                </tbody>
              </table>

              <div className="spacer-x2" />

              <SpacerTable>
                <Button className="button confirm large" onExecute={() => alert("Sample tickets! (you know you're in the event editor right?)")} text="View Tickets" />
              </SpacerTable>
            </div>
            <div className="ticket-rip bottom" />
          </div>
        </Theme>
      </div>
    </>
  );
};

export default ThemeSection;
