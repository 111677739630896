import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path d="M 4 3 C 2.896 3 2 3.895 2 5 L 2 8 C 3.104 8 4 8.895 4 10 C 4 11.105 3.104 12 2 12 L 2 15 C 2 16.105 2.896 17 4 17 L 20 17 C 21.104 17 22 16.105 22 15 L 22 12 C 20.896 12 20 11.105 20 10 C 20 8.895 20.896 8 22 8 L 22 5 C 22 3.895 21.104 3 20 3 L 4 3 z M 4 5 L 20 5 L 20 6.5371094 C 18.805 7.2291094 18 8.522 18 10 C 18 11.478 18.805 12.770891 20 13.462891 L 20 15 L 4 15 L 4 13.462891 C 5.195 12.770891 6 11.478 6 10 C 6 8.522 5.195 7.2291094 4 6.5371094 L 4 5 z M 4.9003906 19 L 17.339844 21.929688 C 17.549844 21.979688 17.750937 22 17.960938 22 C 19.170938 22 20.270312 21.190234 20.570312 19.990234 L 20.820312 19 L 18.759766 19 L 18.630859 19.5 C 18.540859 19.85 18.170781 20.070469 17.800781 19.980469 L 13.619141 19 L 4.9003906 19 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
