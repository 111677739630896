import React, { CSSProperties, FunctionComponent } from 'react';
import NumberHelper from '../../helpers/NumberHelper';

export interface IProps {
  text: string;
  width: number;
  height: number;
  rows: number;
  column: number;
  row: number;
  vertical?: boolean;
  rightSide?: boolean;
  onClick?: () => void;
  background?: string;
}

const SeatingPlanLabel: FunctionComponent<IProps> = (props) => {
  let style: CSSProperties = {};

  // if (props.vertical) {
  //   if (props.rightSide) {
  //     style = { background: props.background, transformBox: 'fill-box', transformOrigin: 'center', transform: 'rotate(90deg)' };
  //   } else {
  //     style = { background: props.background, transformBox: 'fill-box', transformOrigin: 'center', transform: 'rotate(-90deg)' };
  //   }
  // }
  const x = NumberHelper.roundDecimal(props.column * props.width);
  const y = NumberHelper.roundDecimal((props.row + props.rows / 2) * props.height + 2);

  return (
    <text
      transform={props.vertical ? (props.rightSide ? `rotate(90, ${x}, ${y})` : `rotate(-90, ${x}, ${y})`) : null}
      onClick={props.onClick}
      fontSize={30}
      style={style}
      className={'seating-plan-label' + (props.vertical ? ' vertical' : '')}
      textAnchor="middle"
      alignmentBaseline="middle"
      height={props.height}
      width={props.width}
      x={x}
      y={y}
    >
      {props.text}
    </text>
  );
};

export default SeatingPlanLabel;
