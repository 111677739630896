import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M 1 0 A 1 1 0 0 0 0 1 L 0 7 A 1 1 0 0 0 1 8 L 5 8 L 5 18 A 1 1 0 0 0 6 19 L 26 19 A 1 1 0 0 0 27 18 L 27 8 L 31 8 A 1 1 0 0 0 32 7 L 32 1 A 1 1 0 0 0 31 0 L 1 0 z M 2 2 L 5 2 L 5 6 L 2 6 L 2 2 z M 7 2 L 8 2 L 8 12 A 1 1 0 0 0 9 13 A 2 2 0 0 1 11 15 A 1 1 0 0 0 12 16 L 20 16 A 1 1 0 0 0 21 15 A 2 2 0 0 1 23 13 A 1 1 0 0 0 24 12 L 24 2 L 25 2 L 25 17 L 7 17 L 7 2 z M 10 2 L 12.142578 2 A 3.981 3.981 0 0 0 19.857422 2 L 22 2 L 22 11.126953 A 4.015 4.015 0 0 0 19.126953 14 L 12.873047 14 A 4.015 4.015 0 0 0 10 11.126953 L 10 2 z M 14.269531 2 L 17.730469 2 A 2 2 0 0 1 14.269531 2 z M 27 2 L 30 2 L 30 6 L 27 6 L 27 2 z M 16 7 A 1 1 0 0 0 16 9 A 1 1 0 0 0 16 7 z M 15.970703 20 A 1 1 0 0 0 15 21 L 15 28.585938 L 13.707031 27.292969 A 1 1 0 0 0 12.292969 28.707031 L 15.292969 31.707031 A 1 1 0 0 0 16.708984 31.707031 L 19.708984 28.707031 A 1 1 0 0 0 18.294922 27.292969 L 18.292969 27.292969 L 17 28.585938 L 17 21 A 1 1 0 0 0 15.970703 20 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
