import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 50">
      <path
        d="M12,0c-1.1,0-2,0.9-2,2v2H4C3.5,4,2.9,4.2,2.6,4.6C2.2,4.9,2,5.5,2,6v40c0,0.5,0.2,1.1,0.6,1.4C2.9,47.8,3.5,48,4,48h42
	c0.5,0,1.1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4V6c0-0.5-0.2-1.1-0.6-1.4C47.1,4.2,46.5,4,46,4h-6V2c0-1.1-0.9-2-2-2h-2
	c-1.1,0-2,0.9-2,2v2H16V2c0-1.1-0.9-2-2-2H12z M12,2h2v6h-2V2z M36,2h2v6h-2V2z M4,6h6v2c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2V6h18v2
	c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2V6h6v7H4V6z M4,15h42v31H4V15z M10,20.3v20.5c0,0.7,0.6,1.3,1.3,1.3h27.5c0.7,0,1.3-0.6,1.3-1.3
	V20.3c0-0.7-0.6-1.3-1.3-1.3H11.3C10.6,19,10,19.6,10,20.3z M12,21h5v5h-5V21z M19,21h5v5h-5V21z M26,21h5v5h-5V21z M33,21h5v5h-5
	V21z M12,28h5v5h-5V28z M19,28h5v5h-5V28z M33,28h5v5h-5V28z M12,35h5v5h-5V35z M19,35h5v5h-5V35z M26,35h5v5h-5V35z M33,35h5v5h-5
	V35z"
      />
    </svg>
  );
}) as FunctionComponent<IProps>;
