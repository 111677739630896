import linq from 'linq';
import React, { useState } from 'react';
import Dropdown from '../../../components/Dropdown';
import { IDiscountCriteria } from '../../../interfaces/IDiscountCriteria';
import SVGMinus from '../../../svg/SVGMinus';
import SVGPlus from '../../../svg/SVGPlus';
import { IDropDownItem } from '../../../components/DropdownItem';

interface IProps {
  criteria: IDiscountCriteria;
  tickets: any[];
  onRemoveClick: (criteria: IDiscountCriteria) => void;
  onCriteriaUpdated: () => void;
  currency: string;
  showSeatingPlanNames: boolean;
}

const DiscountCriteria: React.FC<IProps> = (props) => {
  const [amount, setAmount] = useState(props.criteria.Amount == null ? 1 : props.criteria.Amount);

  const ticketChanged = function (t: IDropDownItem) {
    props.criteria.Guid = t.value;
    props.onCriteriaUpdated();
  };

  const onRemoveClick = function () {
    props.onRemoveClick(props.criteria);
  };

  const handleMinusClick = function () {
    if (amount === 1) return;
    setAmount(amount - 1);
    props.criteria.Amount = amount - 1;
    props.onCriteriaUpdated();
  };

  const handlePlusClick = function () {
    setAmount(amount + 1);
    props.criteria.Amount = amount + 1;
    props.onCriteriaUpdated();
  };

  var items = linq
    .from(props.tickets)
    .select(function (t) {
      return {
        color: t.Colour,
        value: t.Guid,
        description:
          t.Name +
          (t.SeatCategory == null || t.SeatCategory.Name == null ? '' : ' ' + (props.showSeatingPlanNames ? t.SeatCategory.SeatingPlanName + ' ' : '') + t.SeatCategory.Name) +
          ' ' +
          t.PriceAsString,
      };
    })
    .toArray();

  return (
    <div className="row">
      <div className="col-sm-7">
        <table className="counter">
          <tbody>
            <tr>
              <td className="counter-button">
                <button onClick={handleMinusClick} className="admin-button">
                  <SVGMinus />
                </button>
              </td>
              <td className="counter-amount">{amount}</td>
              <td className="counter-button">
                <button onClick={handlePlusClick} className="admin-button">
                  <SVGPlus />
                </button>
              </td>
              <td>
                <Dropdown onChange={ticketChanged} value={props.criteria.Guid} items={items} description="Ticket" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="col-sm-3 col-sm-offset-2" style={{ paddingTop: '4px' }}>
        <button className="admin-button danger" onClick={onRemoveClick}>
          Remove
        </button>
      </div>
    </div>
  );
};

export default DiscountCriteria;
