import linq from 'linq';
import React from 'react';
import { useRecoilState } from 'recoil';
import loaderState from '../../../../atoms/loaderState';
import tourMenuState from '../../../../atoms/tourMenuState';
import CacheHelper from '../../../../helpers/CacheHelper';
import NumberHelper from '../../../../helpers/NumberHelper';
import { ITour } from '../../../../interfaces/ITour';

interface IProps {
  tour: ITour;
  hide?: boolean;
  events: any;
}

const TourRow: React.FC<IProps> = (props) => {
  const [loaders, setLoaders] = useRecoilState(loaderState);
  const [, setTourMenu] = useRecoilState(tourMenuState);

  var soldTickets = linq.from(props.events).sum((e: any) => e.SoldTickets);

  if (!NumberHelper.isNumeric(soldTickets)) {
    soldTickets = 0;
  }

  var availableTickets = linq.from(props.events).sum((e: any) => e.AvailableTickets);

  if (isNaN(availableTickets)) {
    availableTickets = 0;
  }

  var soldTicketsValue = linq.from(props.events).sum((e: any) => e.SoldTicketsValue);

  var soldPercentage = (soldTickets / availableTickets) * 100.0;
  if (isNaN(soldPercentage)) {
    soldPercentage = 0;
  }

  if (props.hide) return null;

  return (
    <>
      <tr className="event-group tour">
        <td className="event-image-container">
          <img className="event-image" style={{ display: 'inline-block' }} src={props.tour.LogoUrl} />
        </td>
        <td>
          <div className="event-details">
            {props.tour.Id !== 0 ? (
              <button
                className="name"
                onClick={() => {
                  CacheHelper.tourByTagWithMessage(setLoaders, loaders, props.tour.TourTag).then((org) => {
                    setTourMenu(org);
                  });
                }}
              >
                {props.tour.Name}
              </button>
            ) : (
              <div className="name">{props.tour.Name}</div>
            )}
            {props.tour.TourTag && props.tour.TourTag.length > 0 && <div className="">#{props.tour.TourTag}</div>}
          </div>
        </td>
        <td>
          {availableTickets > 0 && (
            <>
              {soldPercentage.toFixed(1)}
              <span style={{ color: '#b2bbc3' }}>%</span>
            </>
          )}
        </td>
        <td>
          {soldTickets}
          <span style={{ color: '#b2bbc3' }}>/{availableTickets}</span>
        </td>
        <td style={{ textAlign: 'right' }}>
          <span style={{ color: '#b2bbc3' }}>{props.events[0].CurrencySymbol}</span>
          <span>{(soldTicketsValue / 100.0).toFixed(2)}</span>
        </td>
      </tr>
    </>
  );
};

export default TourRow;
