import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 24 3.9980469 C 12.972292 3.9980469 4 12.970339 4 23.998047 C 4 27.273363 4.8627078 30.334853 6.2617188 33.064453 L 4.09375 40.826172 C 3.5887973 42.629575 5.3719261 44.41261 7.1757812 43.908203 L 14.943359 41.740234 C 17.670736 43.136312 20.727751 43.998047 24 43.998047 C 35.027708 43.998047 44 35.025755 44 23.998047 C 44 12.970339 35.027708 3.9980469 24 3.9980469 z M 24 6.9980469 C 33.406292 6.9980469 41 14.591755 41 23.998047 C 41 33.404339 33.406292 40.998047 24 40.998047 C 20.998416 40.998047 18.190601 40.217527 15.742188 38.853516 A 1.50015 1.50015 0 0 0 14.609375 38.71875 L 7.2226562 40.779297 L 9.2851562 33.396484 A 1.50015 1.50015 0 0 0 9.1503906 32.261719 C 7.7836522 29.811523 7 27.002565 7 23.998047 C 7 14.591755 14.593708 6.9980469 24 6.9980469 z M 24 13 C 20.704135 13 18 15.704135 18 19 A 1.50015 1.50015 0 1 0 21 19 C 21 17.325865 22.325865 16 24 16 C 25.674135 16 27 17.325865 27 19 C 27 20.586213 26.356675 21.053097 25.226562 22.109375 C 24.09645 23.165653 22.5 24.806615 22.5 27.5 A 1.50015 1.50015 0 1 0 25.5 27.5 C 25.5 25.840385 26.15355 25.347503 27.273438 24.300781 C 28.393325 23.25406 30 21.648787 30 19 C 30 15.704135 27.295865 13 24 13 z M 24 31 A 2 2 0 0 0 24 35 A 2 2 0 0 0 24 31 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
