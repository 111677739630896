import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="566.7px" height="180.7px" viewBox="0 0 566.7 180.7">
      <path
        d="M351.1,152.7L307.5,24.1c-1.1-2.9-3.5-5.1-6.5-6c-8.3-2.3-32.4-5.7-51.8,0c-2.9,0.9-5.3,3.1-6.4,5.9
	l-36.6,109.2c-1.4,3.8-4.9,6.3-9,6.3h-35.2c-3.4,0-6.2-2.8-6.2-6.2v-25.8c0-3.4,2.8-6.2,6.2-6.2h24c2.6,0,4.9-1.6,5.8-4l5.1-13.3
	c1.6-4.1-1.4-8.4-5.8-8.4h-29.1c-3.4,0-6.2-2.8-6.2-6.2V52.3c0-3.4,2.8-6.2,6.2-6.2h46.1c2.5,0,4.8-1.5,5.8-3.9l6.7-16.4
	c1.7-4.1-1.3-8.6-5.8-8.6h-84.5c-3.4,0-6.2,2.8-6.2,6.2v135.8c0,3.4,2.8,6.2,6.2,6.2h64.3h9.3h20.8c2.4,0,4.7-1.4,5.7-3.6l7.5-16.5
	c1-2.2,3.2-3.6,5.7-3.6h63c2.4,0,4.7,1.4,5.7,3.6l7.5,16.5c1,2.2,3.2,3.6,5.7,3.6h16.5C348.8,165.5,353.4,158.9,351.1,152.7z
	 M256.1,46.3c0,0,5.5-2.4,19.7-2.4s19,2.4,19,2.4c1.9,0.8,4.6,1.9,4.4,4.3l-3.1,42.2c-0.1,2.5-2,3.1-4.4,4.3c0,0-4.5,2.6-15.9,2.6
	c-11.6,0-15.7-2.7-15.7-2.7c-1.7-1-4.1-1.9-4.4-4.3l-4.1-42.1C251.5,48.3,253.9,47.2,256.1,46.3z M240.1,110.9h-4.6
	c-3.5,0-4.8-2.2-4.2-4.1l8.7-30c0.7-2.6,1.9-4.1,4.2-4.1h1.7c2.5,0,4.7,1.3,4.2,4.1l-5.9,30C243.8,109.6,242.4,110.9,240.1,110.9z
	 M297.8,117.7c0,0-8.6,4.9-21.9,4.9v0c0,0,0,0,0,0c0,0,0,0,0,0v0c-13.3,0-21.9-4.9-21.9-4.9c-2.2-1.4-4.4-2.3-4-4.8l1.1-7
	c0.4-2.6,1.5-5.3,4.1-3.7c0,0,9.9,6.1,20.7,6.1c10.8,0,20.7-6.1,20.7-6.1c2.7-1.6,3.7,1.1,4.1,3.7l1.1,7
	C302.2,115.4,299.9,116.3,297.8,117.7z M316.3,110.9h-4.6c-2.3,0-3.7-1.3-4.2-4.1l-5.9-30c-0.5-2.8,1.7-4.1,4.2-4.1h1.7
	c2.3,0,3.5,1.5,4.2,4.1l8.7,30C321.2,108.7,319.8,110.9,316.3,110.9z"
      />
      <path
        d="M525.8,21.7l-20.2,40.1c-3.4,6.8-13.2,6.8-16.6,0l-20.1-40c-1.6-3.1-4.8-5.1-8.3-5.1h-13.6h-15h-94
	c-6.4,0-10.9,6.3-8.8,12.4l2.9,8.2c1.3,3.7,4.8,6.2,8.8,6.2h24.7c5.1,0,9.3,4.2,9.3,9.3v103.5c0,5.1,4.2,9.3,9.3,9.3h13.1
	c5.1,0,9.3-4.2,9.3-9.3V52.7c0-5.1,4.2-9.3,9.3-9.3h25.1c3.4,0,6.6,1.9,8.2,4.9l31.4,58.5c0.7,1.4,1.1,2.9,1.1,4.4v45.1
	c0,5.1,4.2,9.3,9.3,9.3h12.9c5.1,0,9.3-4.2,9.3-9.3v-46c0-1.6,0.4-3.1,1.1-4.4L555,30.4c3.4-6.2-1.1-13.7-8.2-13.7h-12.8
	C530.6,16.6,527.4,18.6,525.8,21.7z"
      />
      <path
        d="M108.9,123.2c0,13.1-4.8,23.5-14.6,31.1c-9.7,7.6-23.2,11.4-40.5,11.4c-13.4,0-25.4-2.1-36.2-6.2
	c-3.7-1.4-6.1-5-6.1-8.9l0-8.1c0-6.7,6.7-11.3,13-9c4.7,1.7,8.9,3.1,12.6,4c7,1.8,13.4,2.7,19.2,2.7c6.9,0,12.3-1.3,16-3.9
	c3.7-2.6,5.6-6.4,5.6-11.6c0-2.9-0.8-5.4-2.4-7.6c-1.6-2.2-4-4.4-7.2-6.4c-3.2-2.1-9.6-5.3-19.4-9.9c-9.1-4.2-16-8.2-20.5-12
	c-4.6-3.8-8.2-8.3-10.9-13.4c-2.7-5.1-4.1-11.1-4.1-17.9c0-12.9,4.5-23,13.4-30.4C35.6,19.7,48,16,63.8,16c7.8,0,15.2,0.9,22.2,2.7
	c4.1,1,8.3,2.3,12.6,3.8c5.2,1.9,7.9,7.7,5.7,12.8l-2.8,6.5c-2,4.7-7.3,6.9-12,5.2c-4.3-1.5-8.1-2.7-11.3-3.5
	c-5.2-1.3-10.3-1.9-15.4-1.9c-6,0-10.6,1.4-13.8,4.1c-3.2,2.7-4.8,6.3-4.8,10.7c0,2.7,0.6,5.1,1.9,7.1s3.4,4,6.2,5.9
	c2.8,1.9,9.5,5.3,20.1,10.2c14,6.5,23.5,13,28.7,19.6C106.3,105.7,108.9,113.8,108.9,123.2z"
      />
    </svg>
  );
}) as FunctionComponent<IProps>;
