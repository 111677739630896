import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="67px"
      height="67px"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <path d="M93 498l-78 0 0 -77 78 0 0 77zm-47 -107l0 -70 17 0 0 70 -17 0zm0 -99l0 -76 17 0 0 76 -17 0zm0 -105l0 -70 17 0 0 70 -17 0zm0 -99l0 0 -30 0 0 -78 78 0 0 78 -30 0 0 0 -17 0zm77 -48l70 0 0 17 -70 0 0 -17zm99 0l76 0 0 17 -76 0 0 -17zm105 0l70 0 0 17 -70 0 0 -17zm99 -30l78 0 0 78 -30 0 0 0 -18 0 0 0 -30 0 0 -78zm47 107l0 70 -18 0 0 -70 18 0zm0 99l0 76 -18 0 0 -76 18 0zm0 105l0 70 -18 0 0 -70 18 0zm30 99l0 77 -78 0 0 -77 78 0zm-107 47l-70 0 0 -18 70 0 0 18zm-99 0l-76 0 0 -18 76 0 0 18zm-105 0l-70 0 0 -18 70 0 0 18zm67 -240c14,0 26,12 26,26 0,14 -12,26 -26,26 -14,0 -26,-12 -26,-26 0,-14 12,-26 26,-26z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
