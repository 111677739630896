import './Toolbar.scss';

import React from 'react';

export interface IProps {}

const Toolbar: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  return (
    <>
      <div className="toolbar-container">{props.children}</div>
    </>
  );
};

export default Toolbar;
