import React from 'react';

import { IOption } from '../interfaces/IOption';

export interface ILookupOptionProps {
  onClick?(option: any): void;
  option: IOption;
  lookupText: string;
}

export default class LookupOption extends React.Component<ILookupOptionProps, any> {
  handleClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.option);
    }
  }

  getHighlightedText(text: string, higlight: string) {
    // Split text on higlight term, include term itself into parts, ignore case
    var parts = text.split(new RegExp(`(${higlight})`, 'gi'));
    const components = parts.map((part, index) => (part.toLowerCase() === higlight.toLowerCase() ? <b key={'b_' + index.toString()}>{part}</b> : <span key={'p_' + index.toString()}>{part}</span>));

    return <span>{components}</span>;
  }

  render() {
    var tags: any = [];

    if (this.props.option.Tags != null && this.props.option.Tags.length > 0) {
      tags = this.props.option.Tags.map((t, index) => (
        <span key={'tag_' + index.toString()} className="tag">
          {t}
        </span>
      ));
    }

    return (
      <div className="lookup-option" onClick={this.handleClick.bind(this)}>
        {this.props.option.Header != null && this.props.option.Header.length > 0 ? <div>{this.getHighlightedText(this.props.option.Header, this.props.lookupText)}</div> : null}
        <div>{this.getHighlightedText(this.props.option.Text, this.props.lookupText)}</div>
        {tags.length > 0 ? tags : null}
      </div>
    );
  }
}
