import React from 'react';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import BlockInfo from '../../../components/BlockInfo';
import OrderQuestions from '../../../components/OrderQuestions';
import { PaymentMethods } from '../../../enums/PaymentMethods';
import CurrencyHelper from '../../../helpers/CurrencyHelper';
import OrderHelper from '../../../helpers/OrderHelper';
import { IDiscount } from '../../../interfaces/IDiscount';
import { IEvent } from '../../../interfaces/IEvent';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import { IQuestionAnswer } from '../../../interfaces/IQuestion';
import { ISeatCategory } from '../../../interfaces/ISeatCategory';
import { ITicket } from '../../../interfaces/ITicket';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';
import { IVenue } from '../../../interfaces/IVenue';
import { AdmissionTypes } from '../../Editor/event/TicketSetupSection';
import linq from 'linq';
import NumberHelper from '../../../helpers/NumberHelper';

export interface IProps {
  orderId: number;
  event: IEvent;
  organisation: IOrganisation;
  venue: IVenue;
  seatCategories: ISeatCategory[];
  ticketCategories: ITicketCategory[];
  currency: string;
  publicDiscounts: IDiscount[];
  tickets: ITicket[];
  attendeeName: string;
  attendeeEmail: string;
  activeDiscount: IDiscount;
  paymentMethod: PaymentMethods;
  eventDateFormatted: string;
  orderAnswers: IQuestionAnswer[];
}

export interface ISummaryState {}

const SummarySection: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const event = props.event;
  const children: Array<React.ReactElement<any>> = typeof (props.children === 'object') ? (props.children as Array<React.ReactElement<any>>) : [];
  const summary = OrderHelper.getOrderSummary(
    props.paymentMethod == PaymentMethods.CardSeaty,
    props.tickets,
    props.event.AbsorbFee,
    props.event.CardFeeMinimum,
    props.event.CardFeePercentage,
    props.activeDiscount,
    props.event.HandlingFee,
    props.event.HandlingFeePercentage,
    props.event.Organisation.ServiceFee,
  );

  const generalTicketsComponents = props.tickets
    .filter((t) => !t.SeatCategory)
    .map((ticket: ITicket) => {
      return (
        <Block key={'ticket_' + ticket.Group + '_' + ticket.Name + '_GENERAL_' + ticket.TicketCategory.Id}>
          <BlockHeader>
            <td style={{ color: ticket.TicketCategory.Colour }}>
              {ticket.Quantity} x {ticket.TicketCategory.Name}
            </td>
            <td
              className="right"
              style={{
                color: ticket.TicketCategory.Colour,
              }}
            >
              {CurrencyHelper.formatCurrency(props.currency, ticket.TicketCategory.PriceAsInt)}
            </td>
          </BlockHeader>
          {ticket.QuestionAnswers && ticket.QuestionAnswers.length > 0 && (
            <OrderQuestions quantity={ticket.Quantity} event={props.event} ticket={ticket} answers={ticket.QuestionAnswers} readOnly={true} />
          )}
        </Block>
      );
    });

  let seatedTickets = linq
    .from(props.tickets)
    .where((t) => t.SeatCategory)
    .orderBy((t) => (NumberHelper.isNumeric(t.Group) ? parseInt(t.Group) : t.Group))
    .thenBy((t) => (NumberHelper.isNumeric(t.Name) ? parseInt(t.Name) : t.Name));

  const sections = event.SeatingPlans.map((sp) => {
    const ticketsInSeatingPlan = seatedTickets.where((t) => linq.from(sp.SeatCategories).any((sc) => sc.Id == t.SeatCategory.Id)).toArray();

    return {
      seatingPlan: sp,
      seatedTickets: ticketsInSeatingPlan,
    };
  });

  const seatedTicketsComponents = linq
    .from(sections)
    .where((s) => s.seatedTickets.length > 0)
    .orderBy((s) => s.seatingPlan.Index)
    .toArray()
    .map((s) => {
      return (
        <React.Fragment key={s.seatingPlan.Id}>
          {sections.length > 1 && (
            <Block>
              <BlockHeader>{s.seatingPlan.Name}</BlockHeader>
            </Block>
          )}
          {s.seatedTickets.map((ticket: ITicket) => {
            return (
              <Block key={'ticket_' + ticket.Group + '_' + ticket.Name + '_SEATED_' + ticket.TicketCategory.Id}>
                <BlockHeader>
                  <td className="ticket-font" style={{ color: ticket.SeatCategory.Colour }}>
                    {ticket.Group}
                    {ticket.Name}
                  </td>
                  <td
                    className="right"
                    style={{
                      color: ticket.SeatCategory.Colour,
                    }}
                  >
                    {ticket.SeatCategory.Name}
                  </td>
                </BlockHeader>

                <table>
                  <tbody>
                    <tr>
                      <td>{ticket.TicketCategory.Name}</td>
                      <td className="right">{CurrencyHelper.formatCurrency(props.currency, ticket.TicketCategory.PriceAsInt)}</td>
                    </tr>
                  </tbody>
                </table>
                {ticket.QuestionAnswers && ticket.QuestionAnswers.length > 0 && <OrderQuestions event={props.event} ticket={ticket} answers={ticket.QuestionAnswers} readOnly={true} />}
              </Block>
            );
          })}
        </React.Fragment>
      );
    });

  let paymentInfo = '';

  switch (props.paymentMethod) {
    case PaymentMethods.CardSeaty: {
      paymentInfo = 'Paid with card using Stripe';
      break;
    }
    case PaymentMethods.Cash: {
      paymentInfo = 'Paid with cash';
      break;
    }
    case PaymentMethods.Cheque: {
      paymentInfo = 'Paid with cheque';
      break;
    }
    case PaymentMethods.BankTransfer: {
      paymentInfo = 'Paid by bank transfer';
      break;
    }
    case PaymentMethods.CardOutSideSeaty: {
      paymentInfo = 'Paid with card outside of Seaty';
      break;
    }
    default: {
      paymentInfo = 'Not taken';
    }
  }

  return (
    <div>
      {children}

      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>{props.event.Name}</BlockHeader>
            {props.eventDateFormatted}
            <br />
            {props.venue.Name}, {props.venue.Postcode}
          </Block>
          {props.orderId == null || props.orderId == 0 ? null : (
            <Block>
              <BlockHeader>Order Number</BlockHeader>
              {props.orderId}
            </Block>
          )}
          {props.attendeeEmail && props.attendeeEmail.length > 0 && (
            <Block>
              <BlockHeader>Attendee</BlockHeader>
              {props.attendeeName}
              <br />
              {props.attendeeEmail}
            </Block>
          )}

          {generalTicketsComponents}
          {seatedTicketsComponents}

          {props.event.AdmissionType === AdmissionTypes.Exchange && (
            <BlockInfo>All Seaty tickets for this event must be taken to the venue box office and exchanged prior to the event starting.</BlockInfo>
          )}

          {summary.activeDiscount == null ? null : (
            <Block>
              <BlockHeader>
                <td>Discount</td>
                <td className="right">{CurrencyHelper.formatCurrency(props.currency, summary.activeDiscount.Amount * 100.0)}</td>
              </BlockHeader>
              {summary.activeDiscount.Name}
            </Block>
          )}
          {props.paymentMethod != PaymentMethods.Request && (
            <Block>
              <BlockHeader>Payment</BlockHeader>
              {paymentInfo}
            </Block>
          )}

          {props.orderAnswers && props.orderAnswers.length > 0 && (
            <Block>
              <OrderQuestions event={props.event} answers={props.orderAnswers} readOnly={true} />
            </Block>
          )}

          <Block>
            <table>
              <tbody>
                {summary.seatyFee == 0 || summary.seatyFee == null ? null : (
                  <tr>
                    <td className="regular-text-color">
                      <strong>Fees</strong>
                    </td>
                    <td className="regular-text-color right">
                      <strong>{CurrencyHelper.formatCurrency(props.currency, summary.allFees)}</strong>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="regular-text-color">
                    <strong className="extra">Total</strong>
                  </td>
                  <td className="regular-text-color right">
                    <strong className="extra">{CurrencyHelper.formatCurrency(props.currency, summary.total)}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </Block>
        </tbody>
      </table>
    </div>
  );
};

export default SummarySection;
