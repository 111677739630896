import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="48px" height="48px" fillRule="evenodd" clipRule="evenodd">
      <path d="M1.47,5.53l6,6c0.293,0.293 0.767,0.293 1.06,0l6,-6c0.293,-0.292 0.293,-0.768 0,-1.06c-0.292,-0.293 -0.768,-0.293 -1.06,-0l-5.47,5.469c-0,0 -5.47,-5.469 -5.47,-5.469c-0.292,-0.293 -0.768,-0.293 -1.06,-0c-0.293,0.292 -0.293,0.768 -0,1.06Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
