import React, { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
      <path d="M 37 4.0039062 C 34.69525 4.0037812 32.389766 4.8822188 30.634766 6.6367188 L 25.634766 11.636719 C 23.142766 14.128719 22.430516 17.727281 23.478516 20.863281 L 27.070312 17.271484 C 27.220312 16.244484 27.673891 15.253844 28.462891 14.464844 L 33.462891 9.4648438 C 34.437891 8.4908438 35.72 8.0019531 37 8.0019531 C 38.28 8.0019531 39.561156 8.4898437 40.535156 9.4648438 C 42.484156 11.414844 42.484156 14.586156 40.535156 16.535156 L 35.535156 21.535156 C 34.746156 22.324156 33.756516 22.776734 32.728516 22.927734 L 29.134766 26.521484 C 30.062766 26.831484 31.029047 26.996094 31.998047 26.996094 C 34.303047 26.996094 36.608281 26.118281 38.363281 24.363281 L 43.363281 19.363281 C 46.873281 15.854281 46.872281 10.145719 43.363281 6.6367188 C 41.608281 4.8827187 39.30475 4.0040313 37 4.0039062 z M 30.960938 16.980469 A 2.0002 2.0002 0 0 0 29.585938 17.585938 L 17.585938 29.585938 A 2.0002 2.0002 0 1 0 20.414062 32.414062 L 32.414062 20.414062 A 2.0002 2.0002 0 0 0 30.960938 16.980469 z M 18.449219 23.023438 C 15.997141 22.898656 13.506469 23.765766 11.636719 25.634766 L 6.6367188 30.634766 C 3.1277188 34.143766 3.1277187 39.854281 6.6367188 43.363281 C 8.3917188 45.117281 10.696 45.994141 13 45.994141 C 15.304 45.994141 17.608281 45.116328 19.363281 43.361328 L 24.363281 38.361328 C 26.855281 35.869328 27.569484 32.270766 26.521484 29.134766 L 22.927734 32.728516 C 22.777734 33.755516 22.324156 34.746156 21.535156 35.535156 L 16.535156 40.535156 C 14.586156 42.485156 11.413844 42.485156 9.4648438 40.535156 C 7.5158438 38.585156 7.5158438 35.413844 9.4648438 33.464844 L 14.464844 28.464844 C 15.253844 27.675844 16.244484 27.221312 17.271484 27.070312 L 20.863281 23.478516 C 20.079281 23.216516 19.266578 23.065031 18.449219 23.023438 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
