import linq from 'linq';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import SVGWithdraw from '../../../../svg/SVGWithdraw';
import { AdminSection } from '../../AdminSections';
import { WithdrawModal } from './WithdrawModal';

interface IWithdrawRequest {
  Guid: string;
  AcceptedDateAsString: string;
  DeclinedReason: string;
  AcceptedDate: string;
  DeclinedDateAsString: string;
  WithdrawToAccountNumber: string;
  DeclinedDate: string;
  Amount: number;
  WithdrawToSortCode: string;
  RequestDateAsString: string;
}

export interface IProps {
  event: IEvent;
}

interface IWithdrawBalances {
  TotalBalance: number;
  Withdraws: IWithdrawRequest[];
}

interface IEventBalance {
  WithdrawableOnlinePaymentBalance: IWithdrawBalances;
  OnlinePaymentBalance: any;
}

const AdminBanking: FunctionComponent<IProps> = ({ event }) => {
  const [balance, setBalance] = useState<IEventBalance>(null);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [busyMessage, setBusyMessage] = useState('Loading banking...');

  const load = () => {
    setBusyMessage('Loading balances...');

    AdminApi.request('GET', `/Api/EventBalance?eventTag=${event.EventTag}&currencySymbol=${event.CurrencySymbol}`)
      .then((_balance) => {
        setBalance(_balance);
        setBusyMessage(null);
      })
      .catch((message) => console.log(message));
  };
  useEffect(load, []);

  if (busyMessage) return <Loader inline>{busyMessage}</Loader>;

  const stripeConnectEnabled = event.Organisation.StripeConnectEnabled;

  return (
    <>
      <Helmet>
        <title>
          #{event.EventTag} {AdminSection.Banking}
        </title>
        <meta name="description" content={`Balances for #${event.EventTag}`} />
      </Helmet>

      {balance && event && (
        <>
          <div className="toolbar">
            <div className="title">{AdminSection.Banking}</div>
            <div className="buttons">
              {!stripeConnectEnabled && (
                <button
                  className={balance.WithdrawableOnlinePaymentBalance.TotalBalance > 0 ? '' : 'disabled'}
                  onClick={balance.WithdrawableOnlinePaymentBalance.TotalBalance > 0 ? () => setShowWithdrawModal(true) : null}
                >
                  <SVGWithdraw />
                  Request Withdraw
                </button>
              )}
            </div>
          </div>
          {stripeConnectEnabled && (
            <div className="row section">
              <div className="col-sm-8">
                <div className="fields">
                  <h2>Stripe Connect Sales</h2>
                  <div className="field">
                    <label className="inline">Ticket sales</label>
                    <label className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.OnlinePaymentBalance.TotalPrice)}</label>
                  </div>
                  {balance.OnlinePaymentBalance.TotalOrganisationFees > 0 && (
                    <div className="field">
                      <label className="inline">Organisation fees</label>
                      <label className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.OnlinePaymentBalance.TotalOrganisationFees)}</label>
                    </div>
                  )}
                  {balance.OnlinePaymentBalance.TotalHandlingFees > 0 && (
                    <div className="field">
                      <label className="inline">Handling fees charged</label>
                      <label className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.OnlinePaymentBalance.TotalHandlingFees)}</label>
                    </div>
                  )}
                  {balance.OnlinePaymentBalance.TotalAbsorbedSeatyFees > 0 && (
                    <div className="field">
                      <label className="inline">Seaty fees absorbed</label>
                      <label className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.OnlinePaymentBalance.TotalAbsorbedSeatyFees)}</label>
                    </div>
                  )}
                  {balance.OnlinePaymentBalance.TotalPassedOnSeatyFees > 0 && (
                    <div className="field">
                      <label className="inline">Seaty fees passed to attendees</label>
                      <label className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.OnlinePaymentBalance.TotalPassedOnSeatyFees)}</label>
                    </div>
                  )}
                  {/* {balance.OnlinePaymentBalance.TotalAbsorbedGatewayFees > 0 && (
                    <div className="field">
                      <label className="inline">Stripe fees absorbed</label>
                      <label className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.OnlinePaymentBalance.TotalAbsorbedGatewayFees)}</label>
                    </div>
                  )}
                  {balance.OnlinePaymentBalance.TotalPassedOnGatewayFees > 0 && (
                    <div className="field">
                      <label className="inline">Stripe fees passed to attendees</label>
                      <label className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.OnlinePaymentBalance.TotalPassedOnGatewayFees)}</label>
                    </div>
                  )} */}
                  {balance.OnlinePaymentBalance.TotalRefunded > 0 && (
                    <div className="field">
                      <label className="inline">Total refunds issued</label>
                      <label className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.OnlinePaymentBalance.TotalRefunded)}</label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!stripeConnectEnabled && (
            <>
              <div className="row section">
                <div className="col-sm-7">
                  <h2>Card Payments</h2>
                  <div style={{ overflow: 'hidden' }}>
                    <div id="withdrawAmount" className="field">
                      Available to request withdraw
                      <div className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.WithdrawableOnlinePaymentBalance.TotalBalance)}</div>
                    </div>

                    <div className="field">
                      Will be available after your events end
                      <div className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.OnlinePaymentBalance.TotalBalance)}</div>
                    </div>

                    {balance.OnlinePaymentBalance.DeductableFees > 0 && (
                      <div className="field">
                        Fees absorbed
                        <div className="right">{CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.OnlinePaymentBalance.DeductableFees)}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-1"></div>
                <div className="col-sm-4">
                  <div className="info">
                    <div>
                      <strong>Pending balance</strong>
                    </div>
                    The balance for each event date and time will move from pending to available 12 hours after it has elapsed. <br /> <br />
                    The "Request Withdraw" button will then become active and you can click it to submit your bank details. <br /> <br />
                    Once requested, your withdraw will be paid out to your nominated account once a review and approval has been made by the Seaty team.
                  </div>
                </div>
              </div>

              <div className="spacer"></div>

              <div className="row section">
                <div className="col-sm-7">
                  <h2>Withdraw Requests</h2>
                  <div style={{ overflow: 'hidden' }}>
                    {balance.WithdrawableOnlinePaymentBalance.Withdraws.length == 0 ? (
                      <div className="field">No withdraw requests have been made for this event. You can request a withdraw when you have an available balance.</div>
                    ) : (
                      <>
                        {linq
                          .from(balance.WithdrawableOnlinePaymentBalance.Withdraws)
                          .orderByDescending((o) => moment(o.AcceptedDate ? o.AcceptedDateAsString : o.DeclinedDate ? o.DeclinedDateAsString : o.RequestDateAsString).unix())
                          .toArray()
                          .map((withdrawRequest: IWithdrawRequest, index) => (
                            <div className="field" key={`With_${withdrawRequest.Guid}`}>
                              <div className="row">
                                <div className="col-sm-7">
                                  {!withdrawRequest.AcceptedDate && !withdrawRequest.DeclinedDate ? (
                                    <>Requested on {withdrawRequest.RequestDateAsString}</>
                                  ) : (
                                    <>
                                      {withdrawRequest.DeclinedDate != null && (
                                        <div style={{ color: 'darkred' }}>
                                          Declined on {withdrawRequest.DeclinedDateAsString} with the reason: <br />
                                          {withdrawRequest.DeclinedReason}
                                        </div>
                                      )}
                                      {withdrawRequest.AcceptedDate != null && <div style={{ color: 'green' }}>Accepted on {withdrawRequest.AcceptedDateAsString}</div>}
                                    </>
                                  )}
                                </div>
                                <div className="col-sm-5 right-sm">{`${CurrencyHelper.formatCurrency(event.CurrencySymbol, withdrawRequest.Amount)} to account ****${
                                  withdrawRequest.WithdrawToAccountNumber == null ? '' : withdrawRequest.WithdrawToAccountNumber.substring(withdrawRequest.WithdrawToAccountNumber.length - 4)
                                }`}</div>
                              </div>
                            </div>
                          ))}
                        <div className="field total">
                          <div className="row">
                            <div className="col-sm-7">Total</div>
                            <div className="col-sm-5 right-sm">
                              {CurrencyHelper.formatCurrency(
                                event.CurrencySymbol,
                                linq.from(balance.WithdrawableOnlinePaymentBalance.Withdraws).sum((o: any) => o.Amount),
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-4">
                  <div className="info">Once a request is made the it will be reviewed by our team and you will receive an email notification when it has been processed.</div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {showWithdrawModal && (
        <WithdrawModal
          onClose={() => {
            setShowWithdrawModal(false);
            load();
          }}
          event={event}
          availableBalance={CurrencyHelper.formatCurrency(event.CurrencySymbol, balance.WithdrawableOnlinePaymentBalance.TotalBalance)}
        />
      )}
    </>
  );
};

export default AdminBanking;
