import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 10 4 A 4 4 0 0 0 10 12 A 4 4 0 0 0 10 4 z M 36 4 A 4 4 0 0 0 36 12 A 4 4 0 0 0 36 4 z M 22.585938 8.2050781 C 22.456219 8.2116094 22.325422 8.2444375 22.201172 8.3046875 L 19.951172 9.3925781 C 19.454172 9.6335781 19.245328 10.231516 19.486328 10.728516 L 21.052734 13.966797 L 18.134766 18.34375 L 13.703125 15.001953 A 1.5005909 1.5005909 0 0 0 13.320312 14.794922 C 12.492798 14.294728 11.530121 14 10.5 14 L 9.5 14 C 6.4802259 14 4 16.480226 4 19.5 L 4 26 A 1.50015 1.50015 0 0 0 4.0117188 26.193359 A 1.50015 1.50015 0 0 0 5.5 28 L 25.5 28 A 1.50015 1.50015 0 1 0 25.5 25 L 16 25 L 16 21.150391 L 18.033203 21.929688 L 18.027344 21.921875 A 1.50015 1.50015 0 0 0 19.748047 21.332031 L 23.748047 15.332031 L 25.25 14.605469 C 25.747 14.365469 25.954844 13.767531 25.714844 13.269531 L 23.537109 8.7695312 C 23.356359 8.3967813 22.975094 8.1854844 22.585938 8.2050781 z M 35.5 14 C 32.480226 14 30 16.480226 30 19.5 L 30 29 A 1.50015 1.50015 0 0 0 31 30.433594 L 31 42.5 A 1.50015 1.50015 0 1 0 34 42.5 L 36 30 L 38 42.5 A 1.50015 1.50015 0 1 0 41 42.5 L 41 30.433594 A 1.50015 1.50015 0 0 0 42 29 L 42 19.5 C 42 16.480226 39.519774 14 36.5 14 L 35.5 14 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
