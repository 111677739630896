import linq from 'linq';
import React, { FunctionComponent, useEffect, useState } from 'react';
import GeneralApi from '../api/GeneralApi';
import Block from '../components/Block';
import BlockHeader from '../components/BlockHeader';
import BlockInfo from '../components/BlockInfo';
import Modal from '../components/Modal';
import SpacerTable from '../components/SpacerTable';
import GAHelper from '../helpers/GAHelper';
import { IEvent } from '../interfaces/IEvent';

export interface IProps {
  onClose?: () => void;
  onSubmit: (event: IEvent) => void;
  title: string;
  description: string;
  setLoadingMessage: (value: string) => void;
}

const UserEventSearchModal = ((props) => {
  const { setLoadingMessage } = props;
  const [results, setResults] = useState<Array<IEvent>>(null);

  useEffect(() => {
    GAHelper.modal(`User Event Search`);
  }, []);

  useEffect(() => {
    setLoadingMessage('Searching for events...');

    GeneralApi.request('GET', '/api/UserEvents')
      .then((results) => {
        setResults(results);
        setLoadingMessage(null);
      })
      .catch((message) => {
        setLoadingMessage(null);
        alert(message);
      });
  }, []);

  var hasResults = results != null && results.length > 0;

  return (
    <Modal onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>{props.title}</h1>
            {props.description}
          </SpacerTable>

          <div className="spacer" />

          {hasResults ? (
            <>
              <table className="blocks">
                <tbody>
                  {linq
                    .from(results)
                    .orderBy((event) => event.Name)
                    .select((event) => {
                      return (
                        <Block className="route" onClick={() => props.onSubmit(event)} key={'result_' + event.Id}>
                          <BlockHeader>{event.Name}</BlockHeader>
                          <div>{event.EventTag}</div>
                        </Block>
                      );
                    })
                    .toArray()}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table className="blocks">
                <tbody>
                  <BlockInfo>You have not created any events.</BlockInfo>
                </tbody>
              </table>
            </>
          )}
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export default UserEventSearchModal;
