import { atom } from 'recoil';

interface ILoaderState {
  id: string;
  message: string;
}

export enum Loader {
  ExcelExport = 'ExcelExport',
  AdminMenuEvent = 'AdminMenu',
  AdminMenuOrganisation = 'AdminMenuOrganisation',
  AdminMenuTour = 'AdminMenuTour',
}

const loaderState = atom<ILoaderState[]>({
  key: 'loaders',
  default: [],
});

export class LoaderHelper {
  public static add(setLoaders, loaders, key, description) {
    setLoaders([...loaders, { id: key, message: description }]);
  }

  public static remove(setLoaders, loaders, key) {
    setLoaders(loaders.filter((l) => l.id != key));
  }
}

export default loaderState;
