import linq from 'linq';
import moment from 'moment';
import EventHelper from '../../helpers/EventHelper';
import SVGHelper from '../../helpers/SVGHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IEventDate } from '../../interfaces/IEventDate';
import SVGGoArrow from '../../svg/SVGGoArrow';
import SVGGoBackArrow from '../../svg/SVGGoBackArrow';

interface IEventDatePickerProps {
  event: IEvent;
  currentEventDateId?: number;
  loadEventDate: (eventDateId: number) => void;
  loading: boolean;
  isAdmin: boolean;
  onCenterClick: () => void;
  buttonText: string;
}

const EventDatePicker: React.FC<React.PropsWithChildren<IEventDatePickerProps>> = ({ event, currentEventDateId, loading, isAdmin, onCenterClick, buttonText, loadEventDate }) => {
  let currentEventDateIndex = 0;
  let onFirst = false;
  let onLast = false;
  let dates: linq.IOrderedEnumerable<IEventDate>;

  if (event) {
    const dateDetails = EventHelper.getAvailableDates(event, isAdmin);
    dates = dateDetails.visibleDates;

    currentEventDateIndex = event && dates.indexOf((d) => d.Id === currentEventDateId);
    onFirst = event && currentEventDateId && dates && dates.count() > 0 && dates.first().Id === currentEventDateId;
    onLast = event && currentEventDateId && dates && dates.count() > 0 && dates.last().Id === currentEventDateId;
  }

  return (
    <div className="btn-ordertickets-group">
      {event && (
        <div
          style={{ opacity: dates.count() > 0 ? 1 : 0 }}
          onClick={(evt) => {
            if (onFirst) return;
            var nextEventDateId = dates.toArray()[currentEventDateIndex - 1].Id;
            loadEventDate(nextEventDateId);
            evt.stopPropagation();
          }}
          className={`btn-ordertickets left arrow${dates.count() == 1 ? ' hidden' : ''}${onFirst || loading ? ' dis' : ''}`}
        >
          <SVGGoBackArrow />
        </div>
      )}
      <div className="btn-ordertickets middle" onClick={onCenterClick}>
        {loading && <div className="loading">Loading...</div>}

        <div>
          {event &&
            (currentEventDateId != null ? (
              <>
                <div id="selectedDate">{moment(event.EventDateIds[currentEventDateId]).format('ddd Do MMM YY')}</div>
                <div id="selectedTime" className="time">
                  {moment(event.EventDateIds[currentEventDateId]).format('h:mm A')}
                </div>
              </>
            ) : (
              <>{buttonText}</>
            ))}
        </div>
      </div>
      {event && (
        <div
          style={{ opacity: dates.count() > 0 ? 1 : 0 }}
          onClick={(evt) => {
            if (onLast) return;
            var nextEventDateId = dates.toArray()[currentEventDateIndex + 1].Id;
            loadEventDate(nextEventDateId);
            evt.stopPropagation();
          }}
          className={`btn-ordertickets right arrow${dates.count() == 1 ? ' hidden' : ''}${onLast || loading ? ' dis' : ''}`}
        >
          <SVGGoArrow />
        </div>
      )}
    </div>
  );
};

export default EventDatePicker;
