import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="26px" height="26px">
      <path d="M 8 22.726563 C 8 23.179688 8.300781 23.789063 8.75 23.941406 C 9.199219 24.09375 9.648438 23.941406 10.101563 23.636719 C 14.898438 20.292969 19.699219 13.910156 19.699219 13.910156 C 19.699219 13.910156 20 13.609375 20 13.003906 C 20 12.394531 19.699219 12.089844 19.699219 12.089844 C 19.699219 12.089844 14.898438 5.707031 10.101563 2.363281 C 9.648438 2.058594 9.199219 1.90625 8.75 2.058594 C 8.300781 2.210938 8 2.820313 8 3.273438 Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
