import moment from 'moment';
import NumberHelper from './NumberHelper';

export default class DateHelper {
  public static fromTo(minDate, maxDate) {
    let dates = '';

    if (minDate == maxDate) {
      dates = `${moment.utc(minDate).format('Do MMM YYYY')} at ${moment.utc(minDate).format('h:mm a')}`;
    } else if (moment(minDate).month() === moment(maxDate).month() && moment(minDate).year() === moment(maxDate).year()) {
      dates = moment.utc(minDate).format('MMMM YYYY');
    } else if (moment(minDate).year() === moment(maxDate).year()) {
      dates = `${moment.utc(minDate).format('MMM')} to ${DateHelper.asDateMonthYear(maxDate)}`;
    } else {
      dates = `${DateHelper.asDateMonthYear(minDate)} to ${DateHelper.asDateMonthYear(maxDate)}`;
    }

    return dates;
  }

  public static asDateAtTimeAmPm(date: string) {
    return moment.utc(date).format('ddd Do MMM YY') + ' at ' + moment.utc(date).format('h:mma');
  }

  public static asDateAtTimeAmPmFromMoment(date: moment.Moment) {
    return date.format('ddd Do MMM YY') + ' at ' + moment.utc(date).format('h:mma');
  }

  public static asDateMonthYear(date: string) {
    return moment.utc(date).format('MMM YYYY');
  }

  public static getFormattedDate() {
    var date = new Date();
    return date.getFullYear() + '-' + NumberHelper.padWithZeros(date.getMonth() + 1, 2) + '-' + NumberHelper.padWithZeros(date.getDate(), 2);
  }

  public static getFormattedTime() {
    var date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var strTime = NumberHelper.padWithZeros(hours, 2) + ':' + NumberHelper.padWithZeros(minutes, 2) + '.000';
    return strTime;
  }

  public static parseDateTimeString(dt) {
    var dateString = dt.split(' ')[0];
    var timeString = dt.split(' ')[1];

    var d: any = {
      dateString: dateString,
      timeString: timeString,
      day: dateString.split('-')[2],
      month: dateString.split('-')[1],
      year: dateString.split('-')[0],
    };

    d.dateGB = d.day + '/' + d.month + '/' + d.year;
    d.timeGB = d.timeString.split(':')[0] + ':' + d.timeString.split(':')[1];
    d.timeGB = d.timeGB.split('.')[0];

    return d;
  }
}
