import moment from 'moment';
import { forwardRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NumberHelper from '../helpers/NumberHelper';
import SVGHelper from '../helpers/SVGHelper';
import './BlockDatePicker.scss';

export interface IDatePickerValue {
  moment: moment.Moment;
  isDate: boolean;
  string: string;
  allHaveValue: boolean;
}

export interface IProps {
  onChange?(value: IDatePickerValue): void;
  autoFocus?: boolean;
  onFocus?(): void;
  onLostFocus?(): void;
  groupName?: string;
  readOnly?: boolean;
  disabled?: boolean;
  initialValue?: moment.Moment;
}

const BlockDatePicker: FunctionComponent<IProps> = (props) => {
  const { initialValue, disabled } = props;
  const input = useRef();
  const groupName = props.groupName ? props.groupName : '';

  const [day, setDay] = useState(initialValue && initialValue ? initialValue.format('DD') : '');
  const [month, setMonth] = useState(initialValue && initialValue ? initialValue.format('MM') : '');
  const [year, setYear] = useState(initialValue && initialValue ? initialValue.format('YYYY') : '');

  const [error, setError] = useState(false);
  const [currentValue, setCurrentValue] = useState<IDatePickerValue>();

  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    if (props.autoFocus) {
      window.setTimeout(() => {
        if (!input.current) return;
        (input.current as HTMLInputElement).focus();
      }, 100);
    }
  }, [input.current]);

  useEffect(() => {
    const date = `${day}/${month}/${year}`;

    const dateMoment = moment(date, 'DD/MM/YYYY');
    const isValid = dateMoment.isValid() && year.length === 4;
    const allHaveValue = day.length > 0 && month.length > 0 && year.length > 0;

    const response: IDatePickerValue = {
      allHaveValue: allHaveValue,
      isDate: isValid,
      moment: isValid ? dateMoment : null,
      string: date,
    };

    allHaveValue && !isValid && year.length === 4 ? setError(true) : setError(false);

    if (initialised) {
      props.onChange(response);
    }

    if (response.isDate) {
      setCurrentValue(response);
    } else {
      setCurrentValue(null);
    }

    setInitialised(true);
  }, [day, month, year]);

  const inputValueChange = (e, setter) => {
    const target = e.target;
    const value = target.value;

    if (NumberHelper.isNumeric(value) || value.length === 0) {
      setter(value);

      if ((target.id == 'yearInput' + groupName && value.length > 3) || (target.id != 'yearInput' + groupName && value.length > 1)) {
        const next = document.getElementById(
          target.id == 'dayInput' + groupName
            ? 'monthInput' + groupName
            : target.id == 'monthInput' + groupName
            ? 'yearInput' + groupName
            : target.id == 'yearInput' + groupName
            ? 'hourInput' + groupName
            : ''
        );
        if (next) (next as any).select();
      }
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick, disabled }: any, ref: any) => (
    <button className="date-icon" onClick={!disabled && onClick} ref={ref} style={{ backgroundImage: `url(${SVGHelper.get('Calendar_Blue')})` }}></button>
  ));

  return (
    <div className={`text-box-container date-picker${disabled ? ' disabled' : ''}`}>
      <div className="slash">/</div>
      <div className="slash slash--two">/</div>

      <input
        readOnly={props.readOnly}
        id={'dayInput' + groupName}
        pattern="[0-9]*"
        value={day}
        maxLength={2}
        placeholder="DD"
        onFocus={props.onFocus}
        onBlur={props.onLostFocus}
        className={`day textbox${error ? ' bad' : ''}`}
        type={'text'}
        onChange={disabled ? null : (e) => inputValueChange(e, setDay)}
        autoComplete={'off'}
      />
      <input
        readOnly={props.readOnly}
        id={'monthInput' + groupName}
        pattern="[0-9]*"
        value={month}
        maxLength={2}
        placeholder="MM"
        onFocus={props.onFocus}
        onBlur={props.onLostFocus}
        className={`month textbox${error ? ' bad' : ''}`}
        type={'text'}
        onChange={disabled ? null : (e) => inputValueChange(e, setMonth)}
        autoComplete={'off'}
      />
      <input
        readOnly={props.readOnly}
        id={'yearInput' + groupName}
        pattern="[0-9]*"
        value={year}
        maxLength={4}
        placeholder="YYYY"
        onFocus={props.onFocus}
        onBlur={props.onLostFocus}
        className={`year textbox${error ? ' bad' : ''}`}
        type={'text'}
        onChange={disabled ? null : (e) => inputValueChange(e, setYear)}
        autoComplete={'off'}
      />
      {!disabled ? (
        <ReactDatePicker
          selected={currentValue ? currentValue.moment.toDate() : null}
          onChange={(date) => {
            var m = moment(date);

            setDay(m.format('DD'));
            setMonth(m.format('MM'));
            setYear(m.format('YYYY'));

            var hourInput = document.getElementById('hourInput' + groupName);
            hourInput && (hourInput as HTMLInputElement).select();
          }}
          customInput={<ExampleCustomInput disabled={disabled} />}
        />
      ) : (
        <div className="react-datepicker-wrapper">
          <div className="date-icon disabled" style={{ backgroundImage: `url(${SVGHelper.get('Calendar_Blue')})` }}></div>
        </div>
      )}
    </div>
  );
};

export default BlockDatePicker;
