import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Modal from '../components/Modal';
import SpacerTable from '../components/SpacerTable';
import GAHelper from '../helpers/GAHelper';
import ThemeHelper from '../helpers/ThemeHelper';
import Swap from '../views/Order/Swap';
import _ from 'lodash';
import ITicketSwap from '../services/SwapService';

export interface IProps {
  isMember: boolean;
  ticketSwap: ITicketSwap;
  onClose: () => void;
}

const SwapModal: FunctionComponent<IProps> = (props) => {
  const [completedOrderId, setCompletedOrderId] = useState<number>(null);

  const { event, orderConfig, seatCategories, ticketCategories, allTicketsAvailable } = props.ticketSwap;

  useEffect(() => {
    GAHelper.modal(`${event.EventTag} Swap`);
  }, []);

  let onClose = () => {
    props.onClose();
  };

  const showOrder = orderConfig && (allTicketsAvailable || completedOrderId != null);

  return (
    <>
      <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={onClose}>
        <div className="content">
          <div className="ticket-rip" />
          <div className="body">
            {showOrder ? (
              <Swap
                isMember={props.isMember}
                isAdmin={true}
                stripeConnectAccountId={event.Organisation.StripeAccountId}
                ticketCategories={ticketCategories}
                seatCategories={seatCategories}
                theme={ThemeHelper.getEventTheme(event)}
                backClicked={onClose}
                onOrderCompleted={(orderId) => setCompletedOrderId(orderId)}
                orderConfig={orderConfig}
              />
            ) : (
              <SpacerTable>
                <h1>We're sorry!</h1>
                The tickets you are trying to order are not available. This could mean they have just been reserved by
                another attendee or that your order has timed out.
              </SpacerTable>
            )}
          </div>
          <div className="ticket-rip bottom" />
        </div>
      </Modal>
    </>
  );
};

export default SwapModal;
