import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="48px" height="48px">
      <defs>
        <clipPath id="clip-1">
          <path
            d="M0,256v-256h256v256zM212.6576,201l5.73002,-5.73002c3.69126,-3.69126 5.72513,-8.6048 5.72513,-13.82635c0,-5.22155 -2.03386,-10.13509 -5.73002,-13.82635c-3.69126,-3.69615 -8.6048,-5.73002 -13.82635,-5.73002c-5.22155,0 -10.13509,2.03386 -13.82635,5.73002l-5.73002,5.72513l-5.73002,-5.72513c-3.69126,-3.69615 -8.6048,-5.73002 -13.82635,-5.73002c-5.22155,0 -10.13509,2.03386 -13.82635,5.73002c-3.69615,3.69126 -5.73002,8.6048 -5.73002,13.82635c0,5.22155 2.03386,10.13509 5.73002,13.82635l5.72513,5.73002l-5.72513,5.73002c-3.69615,3.69126 -5.73002,8.6048 -5.73002,13.82635c0,5.22155 2.03386,10.13509 5.73002,13.82635c3.69126,3.69615 8.6048,5.73002 13.82635,5.73002c5.22155,0 10.13509,-2.03386 13.82635,-5.73002l5.73002,-5.72513l5.73002,5.73002c3.69126,3.69126 8.6048,5.72513 13.82635,5.72513c5.22155,0 10.13509,-2.03386 13.82635,-5.73002c3.69615,-3.69126 5.73002,-8.6048 5.73002,-13.82635c0,-5.22155 -2.03386,-10.13509 -5.73002,-13.82635z"
            id="overlayBgMask"
            fill="none"
          ></path>
        </clipPath>
      </defs>
      <g
        clip-path="url(#clip-1)"
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style={{ mixBlendMode: 'normal' }}
      >
        <g transform="scale(5.33333,5.33333)" fill="#000000">
          <path d="M10.5,8c-3.57194,0 -6.5,2.92806 -6.5,6.5v19c0,3.57194 2.92806,6.5 6.5,6.5h27c3.57194,0 6.5,-2.92806 6.5,-6.5v-19c0,-3.57194 -2.92806,-6.5 -6.5,-6.5zM10.5,11h27c1.95006,0 3.5,1.54994 3.5,3.5v1.10547l-17,9.18945l-17,-9.18945v-1.10547c0,-1.95006 1.54994,-3.5 3.5,-3.5zM7,19.01563l16.28711,8.80469c0.44489,0.24028 0.98089,0.24028 1.42578,0l16.28711,-8.80469v14.48438c0,1.95006 -1.54994,3.5 -3.5,3.5h-27c-1.95006,0 -3.5,-1.54994 -3.5,-3.5z"></path>
        </g>
      </g>
      <g
        fill="#000000"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style={{ mixBlendMode: 'normal' }}
      >
        <g transform="translate(155.66544,171.66544) scale(4.88909,4.88909)" id="overlay">
          <path d="M8.828,6l2.586,-2.586c0.781,-0.781 0.781,-2.047 0,-2.828c-0.781,-0.781 -2.047,-0.781 -2.828,0l-2.586,2.586l-2.586,-2.586c-0.781,-0.781 -2.047,-0.781 -2.828,0c-0.781,0.781 -0.781,2.047 0,2.828l2.586,2.586l-2.586,2.586c-0.781,0.781 -0.781,2.047 0,2.828c0.781,0.781 2.047,0.781 2.828,0l2.586,-2.586l2.586,2.586c0.781,0.781 2.047,0.781 2.828,0c0.781,-0.781 0.781,-2.047 0,-2.828z"></path>
        </g>
      </g>
    </svg>
  );
}) as FunctionComponent<IProps>;
