import { IEvent } from '../../interfaces/IEvent';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { ITour } from '../../interfaces/ITour';

export enum AdminSection {
  Summary = 'Summary',
  Banking = 'Banking',
  Feed = 'Feed',
  Requests = 'Requests',
  Scans = 'Scans',
  Compare = 'Compare',
  Statements = 'Statements',
  Balances = 'Balances',
  Tours = 'Tours',
  Edit = 'Edit',
  Events = 'Events',
  Roster = 'Roster',
  Attendees = 'Attendees',
  Members = 'Members',
  MemberTypes = 'MemberTypes',
  Tickets = 'Tickets',
  Locks = 'Locks',
  Withdrawals = 'Withdrawals',
  Ledger = 'Ledger',
  Search = 'Search',
  Landing = 'Landing',
  EditDetails = 'EditDetails',
  EditDates = 'EditDates',
  EditVenue = 'EditVenue',
  EditImage = 'EditImage',
  EditTheme = 'EditTheme',
  EditMap = 'EditMap',
  EditMarketing = 'EditMarketing',
  EditQuestions = 'EditQuestions',
  EditTicketSetup = 'EditTicketSetup',
  EditGeneralTickets = 'EditGeneralTickets',
  EditSeatedTickets = 'EditSeatedTickets',
  EditDiscounts = 'EditDiscounts',
  Print = 'Print',
}

export interface IAdminSection {
  section: AdminSection;
  eventCondition?: (event: IEvent) => boolean;
  tourCondition?: (tour: ITour) => boolean;
  organisationCondition?: (organisation: IOrganisation) => boolean;
  sections?: IAdminSection[];
}

export default class AdminSections {
  public static getSections(): AdminSection[] {
    return Object.values(AdminSection).map((s) => s as AdminSection);
  }

  public static getOrganisationSections(): IAdminSection[] {
    return [
      {
        section: AdminSection.Summary,
      },
      {
        section: AdminSection.Landing,
      },
      {
        section: AdminSection.Feed,
      },
      {
        section: AdminSection.Withdrawals,
        organisationCondition: (o) => o.OrganisationTag.toLowerCase() == 'seaty',
      },
      {
        section: AdminSection.Ledger,
        organisationCondition: (o) => o.OrganisationTag.toLowerCase() == 'seaty',
      },
      {
        section: AdminSection.Events,
      },
      {
        section: AdminSection.Attendees,
      },
      {
        section: AdminSection.Compare,
      },
      {
        section: AdminSection.Tours,
      },
      {
        section: AdminSection.Statements,
      },
      {
        section: AdminSection.Members,
        organisationCondition: (o) => o.MembershipsEnabled,
        sections: [{ section: AdminSection.MemberTypes }],
      },
      {
        section: AdminSection.Edit,
      },
    ];
  }

  public static getTourSections(): IAdminSection[] {
    return [
      {
        section: AdminSection.Summary,
      },
      {
        section: AdminSection.Landing,
      },
      {
        section: AdminSection.Events,
      },
      {
        section: AdminSection.Edit,
      },
    ];
  }

  public static getEventSections(): IAdminSection[] {
    return [
      {
        section: AdminSection.Summary,
      },
      {
        section: AdminSection.Landing,
      },
      {
        section: AdminSection.Banking,
      },
      {
        section: AdminSection.Feed,
      },
      {
        section: AdminSection.Balances,
      },
      {
        section: AdminSection.Requests,
        eventCondition: (e) => e.RequestingEnabled,
      },
      {
        section: AdminSection.Locks,
        eventCondition: (e) => e.UseSeatingPlan,
      },
      {
        section: AdminSection.Scans,
      },
      {
        section: AdminSection.Roster,
      },
      {
        section: AdminSection.Edit,
        sections: [
          { section: AdminSection.EditDetails },
          { section: AdminSection.EditDates },
          { section: AdminSection.EditVenue },
          { section: AdminSection.EditImage },
          { section: AdminSection.EditTheme },
          { section: AdminSection.EditMap },
          { section: AdminSection.EditTicketSetup },
          { section: AdminSection.EditGeneralTickets },
          { section: AdminSection.EditSeatedTickets },
          { section: AdminSection.EditDiscounts },
          { section: AdminSection.EditQuestions },
          { section: AdminSection.EditMarketing },
        ],
      },
    ];
  }

  public static getEventDateSections(): IAdminSection[] {
    return [
      {
        section: AdminSection.Summary,
      },
      {
        section: AdminSection.Tickets,
      },
      {
        section: AdminSection.Locks,
        eventCondition: (e) => e.UseSeatingPlan,
      },
      {
        section: AdminSection.Scans,
      },
      {
        section: AdminSection.Print,
      },
    ];
  }
}
