import React, { useState } from 'react';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import BlockTextBox from '../../components/BlockTextBox';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import SpacerTable from '../../components/SpacerTable';
import StringHelper from '../../helpers/StringHelper';
import TourHelper from '../../helpers/TourHelper';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { ITour } from '../../interfaces/ITour';
import SVGEditEvent from '../../svg/SVGEditEvent';
import SVGInfo from '../../svg/SVGInfo';
import SVGSave from '../../svg/SVGSave';
import SVGTicket from '../../svg/SVGTicket';

export enum TourWizardSection {
  NewTour,
  TourSaved,
}

export interface IProps {
  onClose: () => void;
  organisation: IOrganisation;
  startFrom?: TourWizardSection;
}

const WizardTour: React.FC<IProps> = (props) => {
  const { onClose, organisation } = props;

  const [state, setState] = useState<{
    busy: string;
    section: TourWizardSection;
    tour: ITour;
    error: string;
    tourName: string;
    tourTag: string;
  }>({
    busy: null,
    section: props.startFrom ? props.startFrom : TourWizardSection.NewTour,
    tour: null,
    error: null,
    tourName: '',
    tourTag: '',
  });

  const { section, busy, tour, error, tourName, tourTag } = state;

  const validTourSave = tourName.length > 2 && tourTag.length > 2;

  const saveTour = () => {
    setState({ ...state, error: null, busy: 'Saving tour...' });

    let t = {
      ...tour,
      Name: tourName,
      TourTag: tourTag,
      OrganisationId: organisation.Id,
    };

    TourHelper.save(
      t,
      (b) => {
        setState({ ...state, busy: b });
      },
      null,
    )
      .then((e: ITour) => {
        setState({ ...state, busy: null, tour: e, section: TourWizardSection.TourSaved });
      })
      .catch((error) => {
        setState({ ...state, busy: null, error: error });
      });
  };

  const tourDetailSection = () => {
    return (
      <>
        <SpacerTable>
          <h1>Create an Tour</h1>
          Tell us some of the basics about your tour for #{organisation.OrganisationTag}. When your new tour is set up you will be given access to the full tour editor where you can add events to this
          tour.
        </SpacerTable>

        <div className="spacer" />

        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>What is the name of your tour?</BlockHeader>
              <BlockTextBox autoFocus={true} placeholder="Tour name" value={tourName} onChange={(e) => setState({ ...state, tourName: e })} rows={1} />
            </Block>
            <Block>
              <BlockHeader>What unique tour tag would you like?</BlockHeader>
              <BlockTextBox
                maxLength={22}
                placeholder="Tour tag"
                value={tourTag}
                onChange={(value) => {
                  !StringHelper.hasSpecialCharacters(value) && setState({ ...state, tourTag: value });
                }}
                rows={1}
              />
            </Block>
            <BlockInfo>A tour tag is your unique link to Seaty.co.uk/Tour/{tourTag.length > 0 ? tourTag.toLowerCase() : 'tag'} and an easy way to refer to your tour.</BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />

        <table className="blocks">
          <tbody>
            {validTourSave ? (
              <Block className={`action has-icon`} onClick={() => saveTour()}>
                <SVGSave />
                <BlockHeader>Save & create new tour</BlockHeader>
                <div>Submit these details and create a new tour for {organisation.Name}.</div>
              </Block>
            ) : (
              <Block className={`disabled has-icon `}>
                <SVGInfo />
                <BlockHeader>Awaiting details</BlockHeader>
                <div>You must enter the required fields before you can save your tour.</div>
              </Block>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const tourSavedSection = () => {
    return (
      <>
        <SpacerTable>
          <h1>Congrationations!</h1>
          You tour is now saved and you can close this dialog to access the full tour editor.
        </SpacerTable>

        <div className="spacer" />

        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Organisation</BlockHeader>
              {organisation.Name}
            </Block>
            <Block>
              <BlockHeader>Tour name</BlockHeader>
              {tourName}
            </Block>
            <Block>
              <BlockHeader>Tour tag</BlockHeader>
              Seaty.co.uk/{tourTag}
            </Block>
          </tbody>
        </table>
        <div className="spacer" />

        <table className="blocks">
          <tbody>
            <Block
              className="action has-icon"
              onClick={() => {
                window.location.href = `/Organisation/${organisation.OrganisationTag}/Tour/${tour.TourTag}/Edit`;
              }}
            >
              <SVGEditEvent />
              <BlockHeader>Open tour editor</BlockHeader>
              <div>Go to the full editor and add more detail to your tour.</div>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block
              className="action has-icon"
              onClick={() => {
                window.location.href = `/${tour.TourTag}`;
              }}
            >
              <SVGTicket />
              <BlockHeader>Go to your tour</BlockHeader>
              <div>Go to your tour landing page at Seaty.co.uk/{tour.TourTag}</div>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
      </>
    );
  };

  return (
    <Modal onCloseClick={props.onClose} theme={tour && tour.Theme ? tour.Theme : organisation && organisation.Theme ? organisation.Theme : null}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          {busy && <Loader inline={true}>{busy}</Loader>}

          <div style={{ display: busy ? 'none' : 'block' }}>
            {section === TourWizardSection.NewTour && tourDetailSection()}
            {section === TourWizardSection.TourSaved && tourSavedSection()}

            {error && (
              <table className="blocks" style={{ marginTop: '25px' }}>
                <tbody>
                  <BlockInfo type={InfoType.Error}>{error}</BlockInfo>
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
};

export default WizardTour;
