import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import eventMenuState from '../atoms/eventMenuState';
import loaderState from '../atoms/loaderState';
import Header from '../components/Header';
import Loader from '../components/Loader';
import CacheHelper from '../helpers/CacheHelper';
import DateHelper from '../helpers/DateHelper';
import LocationHelper from '../helpers/LocationHelper';
import SVGHelper from '../helpers/SVGHelper';
import { IEvent } from '../interfaces/IEvent';
import { IEventDate } from '../interfaces/IEventDate';
import SVGGoToEvent from '../svg/SVGGoToEvent';
import SVGOptions from '../svg/SVGOptions';
import SVGPrint from '../svg/SVGPrint';
import './BlankTicketsPage.scss';

interface IProps {
  ticketGuid?: string;
}

const BlankTicketsPage: React.FC<IProps> = (props) => {
  const [busyMessage, setBusyMessage] = useState('Loading tickets...');
  const [event, setEvent] = useState<IEvent>(null);
  const [eventDate, setEventDate] = useState<IEventDate>(null);
  const [loaders, setLoaders] = useRecoilState(loaderState);
  const [, setEventMenu] = useRecoilState(eventMenuState);

  const eventUrlDetails = LocationHelper.getLocation().pathname;
  const eventTag = eventUrlDetails.split('/')[1];
  const eventDatePart = eventUrlDetails.split('/')[3];
  const eventTimePart = eventUrlDetails.split('/')[4];

  useEffect(() => {
    CacheHelper.eventWithMessage(setLoaders, loaders, eventTag)
      .then((eventData) => {
        setEvent(eventData);

        let matchingEventDateId = 0;
        Object.keys(eventData.EventDateIds).forEach((eventDateIdKey) => {
          const dateString = eventData.EventDateIds[eventDateIdKey];
          if (moment(dateString).format('DDMMMYYYY/HHmm') === eventDatePart + '/' + eventTimePart) {
            matchingEventDateId = parseInt(eventDateIdKey);
          }
        });

        if (matchingEventDateId) {
          const ed = eventData.Dates.filter((d) => d.Id === matchingEventDateId);

          if (ed && ed.length > 0) {
            setEventDate(ed[0]);
          }
        }

        setBusyMessage(null);
      })
      .catch(() => {
        alert('Cannot find event data...');
      });
  }, []);

  if (busyMessage) return <Loader>{busyMessage}</Loader>;

  const tickets = [];

  for (let i = 0; i < 4; i++) {
    tickets.push(
      <div className="ticket" style={{ position: 'relative' }}>
        <div style={{ right: '5px', top: '20px', position: 'absolute', textAlign: 'right' }}>
          {event.Organisation.LogoUrl ? (
            <img alt="Organisation logo" style={{ marginBottom: '8px', width: 'auto', maxWidth: '300px', height: '50px' }} src={event.Organisation.LogoUrl} />
          ) : (
            <h2>{event.Organisation.Name} presents</h2>
          )}

          <h1>{event.Name}</h1>
          {eventDate && <h2>{DateHelper.asDateAtTimeAmPm(eventDate.Date)}</h2>}
          {event.Venue && event.Venue.Name && event.Venue.Name.length > 0 && <h2>{event.Venue.Name}</h2>}
          {event.Venue && event.Venue.StreetAddress && event.Venue.StreetAddress.length > 0 && (
            <h2>
              {event.Venue.StreetAddress}
              {event.Venue.Postcode && event.Venue.Postcode.length > 0 && ', ' + event.Venue.Postcode}
            </h2>
          )}

          <img alt="Powered by Seaty" className="powered-by" src={SVGHelper.get('PoweredBy')} />
        </div>

        <div
          style={{
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            padding: '10px',
            border: '1px dashed #102b35',
            borderBottom: '1px dashed #102b35',
            height: '50px',
            width: '250px',
            textAlign: 'left',
          }}
        >
          <p style={{ fontSize: '11px', color: '#102b35' }}>Order #</p>
        </div>

        <div style={{ padding: '10px', border: '1px dashed #102b35', borderBottom: '1px dashed #102b35', borderTop: '0', height: '50px', width: '250px', textAlign: 'left' }}>
          <p style={{ fontSize: '11px', color: '#102b35' }}>Ticket #</p>
        </div>

        <div
          style={{
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
            padding: '10px',
            border: '1px dashed #102b35',
            borderTop: '0',
            height: '50px',
            borderBottom: '1px dashed #102b35',
            width: '250px',
            textAlign: 'left',
          }}
        >
          <p style={{ fontSize: '11px', color: '#102b35' }}>Authorised by</p>
        </div>

        <div style={{ fontSize: '10px', paddingTop: '40px' }}>
          {event.TermsAndConditions && (
            <div>
              Terms and conditions outlined by event organiser: {event.TermsAndConditions}
              <br />
            </div>
          )}
          This ticket was printed in agreement with terms of service found at http://Seaty.co.uk/Docs/TermsOfService prior to an order being placed and is intended for receipt of an order when no
          printer is available at an event
        </div>
      </div>,
    );
  }

  return (
    <div className="blank-tickets-page">
      <Helmet>
        <title>{eventTag} Blank Tickets</title>
        <meta name="description" content={`Use these tickets on the door if you have no printer.`} />
      </Helmet>
      <Header />
      <div className="toolbar donotprint" style={{ borderBottom: '4px solid #ebebeb' }}>
        <div className="buttons">
          <Link to={`/${event.EventTag}`}>
            <SVGGoToEvent />
            Go to event
          </Link>
          <button onClick={() => (window as any).print()}>
            <SVGPrint />
            Print tickets
          </button>
          {event.CurrentUserHasAdminPermissions && (
            <button onClick={() => setEventMenu({ eventTag: event.EventTag })}>
              <SVGOptions />
              Options
            </button>
          )}
        </div>
      </div>

      <div className="section-to-print">
        <div className="tickets">{tickets}</div>
      </div>
    </div>
  );
};

export default BlankTicketsPage;
