import linq from 'linq';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilState } from 'recoil';
import AdminApi from '../../../../api/AdminApi';
import tourMenuState from '../../../../atoms/tourMenuState';
import wizardTourState from '../../../../atoms/wizardTourState';
import Loader from '../../../../components/Loader';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import { ITour } from '../../../../interfaces/ITour';
import SVGPlus from '../../../../svg/SVGPlus';

interface IProps {
  organisation: IOrganisation;
}

const AdminTours: React.FC<IProps> = (props) => {
  const [, setTourMenu] = useRecoilState(tourMenuState);
  const [, setWizardTourState] = useRecoilState(wizardTourState);
  const [loading, setLoading] = useState(true);
  const [tours, setTours] = useState<ITour[]>();

  const { organisation } = props;
  const organisationTag = organisation.OrganisationTag;

  useEffect(() => {
    reload();
  }, []);

  const reload = () => {
    setLoading(true);
    AdminApi.request('GET', `/api/OrganisationTours?${organisation ? `&organisationTag=${organisation.OrganisationTag}` : ''}`)
      .then((tours: ITour[]) => {
        setTours(tours);
      })
      .catch((message) => {
        console.log(message);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Helmet>
        <title>#{organisationTag} Tours</title>
        <meta name="description" content={`Manage tours for your organisation.`} />
      </Helmet>

      <div className="toolbar">
        <div className="title">Tours</div>
        <div className="buttons">
          <button onClick={() => setWizardTourState({ organisation: organisation, onClose: () => reload() })}>
            <SVGPlus />
            Create Tour
          </button>
        </div>
      </div>

      {loading && <Loader inline>Loading your tours...</Loader>}

      {tours && tours.length > 0 ? (
        <>
          <div className="orderoverview" style={{ paddingBottom: '100px' }}>
            <div className="container-outer" style={{ minHeight: '300px' }}>
              <div className="container-inner" style={{ backgroundColor: 'white' }}>
                <table className="table grid-table">
                  <thead>
                    <tr>
                      <th style={{ width: '150px' }}></th>
                      <th className="grid-header"></th>
                      <th className="grid-header">Events</th>
                    </tr>
                  </thead>

                  <tbody className="smooth-details">
                    {linq
                      .from(tours)
                      .orderBy((t) => t.TourTag)
                      .toArray()
                      .map((t) => (
                        <tr className="event-row" style={{ height: '100px' }}>
                          <td className="event-image-container">
                            <img className="event-image" style={{ display: 'inline-block' }} src={t.LogoUrl} />
                          </td>

                          <td>
                            <div className="event-details">
                              <button className="name" onClick={() => setTourMenu(t)}>
                                {t.Name}
                              </button>
                              <div className="">#{t.TourTag}</div>
                            </div>
                          </td>
                          <td>{t.Events && t.Events.length}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>{organisation.Name} has no tours.</p>
      )}
    </>
  );
};

export default AdminTours;
