import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 38.625 5.3027344 C 38.212379 5.2832604 37.788912 5.3397128 37.371094 5.4824219 L 18.195312 12.019531 L 9.078125 12.720703 C 6.2262276 12.939659 4 15.343269 4 18.203125 L 4 25.796875 C 4 28.656731 6.2262276 31.060341 9.078125 31.279297 A 1.50015 1.50015 0 0 0 9.1933594 31.283203 L 7.8554688 31.283203 L 8.7480469 39.318359 C 8.9798106 41.401585 10.765299 42.998047 12.861328 42.998047 C 15.129584 42.998047 17.001953 41.127631 17.001953 38.859375 L 17.001953 31.888672 L 18.197266 31.980469 L 37.371094 38.517578 A 1.50015 1.50015 0 0 0 37.373047 38.517578 C 39.600738 39.276745 42.001953 37.557349 42.001953 35.203125 L 42.001953 26.203125 C 43.465579 25.608643 44.5 24.17695 44.5 22.5 C 44.5 20.82305 43.465579 19.391357 42.001953 18.796875 L 42.001953 8.7949219 A 1.50015 1.50015 0 0 0 42.001953 8.7929688 C 42.000653 7.0276863 40.648587 5.6203021 39.033203 5.3476562 C 38.898588 5.3249358 38.76254 5.3092257 38.625 5.3027344 z M 38.597656 8.2890625 C 38.835225 8.3139789 39.001741 8.5056572 39.001953 8.796875 L 39.001953 35.203125 C 39.001953 35.590901 38.706153 35.802567 38.339844 35.677734 L 18.984375 29.080078 A 1.50015 1.50015 0 0 0 18.615234 29.003906 L 15.615234 28.771484 A 1.50015 1.50015 0 0 0 14.001953 30.267578 L 14.001953 38.859375 C 14.001953 39.505119 13.507072 39.998047 12.861328 39.998047 C 12.268001 39.998047 11.795588 39.574164 11.730469 38.986328 L 10.689453 29.619141 A 1.50015 1.50015 0 0 0 9.3085938 28.289062 C 7.992491 28.188018 7 27.117019 7 25.796875 L 7 18.203125 C 7 16.882981 7.992491 15.811982 9.3085938 15.710938 L 17 15.121094 L 17 24.5 A 1.50015 1.50015 0 1 0 20 24.5 L 20 14.574219 L 38.339844 8.3222656 C 38.431252 8.2910444 38.518467 8.280757 38.597656 8.2890625 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
