import linq from 'linq';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AdminApi from '../../../api/AdminApi';
import eventMenuState from '../../../atoms/eventMenuState';
import Loader from '../../../components/Loader';
import CacheHelper from '../../../helpers/CacheHelper';
import EventDateHelper from '../../../helpers/EventDateHelper';
import LocationHelper from '../../../helpers/LocationHelper';
import StringHelper from '../../../helpers/StringHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IEventDate } from '../../../interfaces/IEventDate';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import SVGAdmin from '../../../svg/SVGAdmin';
import SVGSave from '../../../svg/SVGSave';
import SVGWarning from '../../../svg/SVGWarning';
import '../Editor.scss';
import { IImageUploadRequest } from '../ImageUploader';
import DateSection from './DateSection';
import SideMenu from '../../../components/SideMenu';

export interface IEventWarning {
  title: string;
  text: string;
}

interface IEventDateSection {
  heading: string;
  subHeading?: (event: IEventDate) => React.ReactNode;
  icon: string;
  section: Section;
  visible?: (event: IEventDate) => boolean;
}

interface IProps {}

export enum Section {
  Details = 'Details',
}

const DateEditorPage: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [, setEventMenu] = useRecoilState(eventMenuState);
  const [section, setSection] = useState<Section>(Section.Details);
  const [busyMessage, setBusyMessage] = useState<string>('Loading event...');
  const [imageRequests, setImageRequests] = useState<{ [key: string]: IImageUploadRequest }>({});

  const [organisation, setOrganisation] = useState<IOrganisation>(null);
  const [event, setEventState] = useState<IEvent>(null);
  const [eventDate, setEventDateState] = useState<IEventDate>(null);
  const [eventDateHistory, setEventDateHistory] = useState<IEventDate[]>([]);
  const [actualEventTag, setActualEventTag] = useState<string>(null);

  const eventSections: IEventDateSection[] = [
    {
      heading: 'Event date',
      subHeading: (e: IEvent) => {
        return <>{e.Name.length > 0 ? <span className="sub">{e.Name}</span> : <span className="sub">Information</span>}</>;
      },
      icon: 'Event',
      section: Section.Details,
    },
  ];

  const setEventDate = (_eventDate: IEventDate) => {
    setEventDateHistory([...(eventDateHistory.length > 5 ? eventDateHistory.filter((e, i) => i != 0) : eventDateHistory), JSON.parse(JSON.stringify(eventDate))]);
    setEventDateState(_eventDate);
  };

  const loadEventDate = (_eventDate: IEventDate) => {
    setEventDateState(_eventDate);
    setBusyMessage(null);

    return _eventDate;
  };

  const fetchEventDate = (eventTag, abortController?: AbortController): Promise<any> => {
    setEventDateHistory([]);
    return AdminApi.request('GET', `/api/EventDateEdit?eventTag=${eventTag}`, null, abortController)
      .then((_event: IEvent) => {
        return loadEventDate(_event);
      })
      .catch((message) => {
        console.log(message);
      });
  };

  const urlDetails = LocationHelper.getLocation().pathname;
  const organisationTag = urlDetails.split('/')[2];
  const eventTag = urlDetails.split('/')[4];
  const eventDatePart = urlDetails.split('/')[6];
  const eventTimePart = urlDetails.split('/')[7];

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sectionParam: string = queryParams.get('section');

    if (sectionParam && Object.values(Section).includes(sectionParam as any)) {
      setSection(sectionParam as any);
    }

    const ac = new AbortController();

    CacheHelper.organisationByTag(organisationTag).then((o) => {
      setOrganisation(o);

      return fetchEventDate(eventTag, ac);
    });

    return () => {
      ac.abort();
    };
  }, []);

  const getEventDateWarnings = (): IEventWarning[] => {
    if (!eventDate) return [];

    const warnings: IEventWarning[] = [];

    if (event.Dates && event.Dates.length > 0) {
      linq
        .from(event.Dates)
        .groupBy((ed) => ed.DateAsString)
        .where((g) => g.count() > 1)
        .toArray()
        .forEach((g) => {
          warnings.push({
            title: `Duplicate event dates`,
            text: `There are two event dates with the same time of ${moment(g.first().DateAsString).format('DD/MM/YYYY HH:mm')}. `,
          });
        });
    }

    return warnings;
  };

  const [optionsOpen, setOptionsOpen] = useState(false);
  const warnings: IEventWarning[] = getEventDateWarnings();

  if (!(warnings.length > 0 || eventDateHistory.length === 0)) {
    window.onbeforeunload = function () {
      return 'You have made changes to this event date, are you sure you want to leave?';
    };
  } else {
    window.onbeforeunload = null;
  }

  const globalOptions = (
    <>
      <div className={`option dropdown ${warnings.length > 0 ? ' warning' : ''} list${optionsOpen ? ' open' : ''}`}>
        {warnings.length === 0 ? (
          <label>Save</label>
        ) : (
          <label>
            {warnings.length} {StringHelper.AddSWhenMany(warnings.length, 'Task')}
          </label>
        )}

        {optionsOpen && <div className="click-off" onClick={() => setOptionsOpen(false)}></div>}
        <span className="icon">{warnings.length === 0 ? <SVGSave /> : <SVGWarning />}</span>

        <button className="action" disabled={warnings.length > 0 || eventDateHistory.length === 0} onClick={() => (warnings.length === 0 ? save(eventDate) : setOptionsOpen(true))}></button>
        <button className="notch" onClick={() => setOptionsOpen(!optionsOpen)}></button>

        <div className="dropdown-options">
          {eventDate && eventDate.Id && eventDate.Id > 0 && actualEventTag && (
            <button
              onClick={() => {
                setEventMenu({ eventTag: actualEventTag });
                setOptionsOpen(false);
              }}
            >
              <span className="dropdown-option-icon">
                <SVGAdmin />
              </span>
              <span className="description">Options</span>
              <p>Open the administrator options dialog.</p>
            </button>
          )}

          {warnings.map((warning, index) => (
            <div className="info-row warning" key={index}>
              <span className="dropdown-option-icon">
                <SVGWarning />
              </span>
              <span className="description">{warning.title}</span>
              {warning.text && <p>{warning.text}</p>}
            </div>
          ))}
        </div>
      </div>
    </>
  );

  const sections: { [key: string]: any } = {};
  sections[Section.Details] = <DateSection globalOptions={globalOptions} eventDate={eventDate} onEventDateUpdated={(e: IEventDate) => setEventDate(e)} />;

  const save = (ed: IEventDate) => {
    return EventDateHelper.save(ed, setBusyMessage, imageRequests)
      .then(() => {
        setEventDateHistory([]);
        setImageRequests({});
        setBusyMessage('All done!');

        navigate(`/Organisation/${organisation.OrganisationTag}/Event/${event.EventTag}/${eventDatePart}/${eventTimePart}Edit?section=${section as any}`);

        window.setTimeout(() => {
          setBusyMessage('Reloading event...');
          loadEventDate(ed);
        }, 100);

        return ed;
      })
      .catch((message) => {
        alert(message);
        setBusyMessage(null);
        return Promise.reject(message);
      });
  };

  return (
    <>
      <Helmet>
        <title>{eventDate && eventDate.Id > 0 ? `Edit Date` : `Create Date`}</title>
        <meta name="description" content={`Edit your event date details.`} />
      </Helmet>

      {busyMessage && <Loader inline>{busyMessage}</Loader>}

      {eventDate && (
        <div className="admin-dashboard-editor">
          <div className="row">
            <div className="col-sm-3 col-md-2 donotprint">
              <SideMenu>
                {eventSections.map((s: IEventDateSection) => (
                  <button
                    key={s.section}
                    style={{ display: s.visible ? (!s.visible(eventDate) ? 'none' : null) : null }}
                    className={`${section == s.section ? 'selected' : ''}`}
                    onClick={() => {
                      setSection(s.section);
                      LocationHelper.updateParam('section', s.section);
                    }}
                  >
                    {s.heading}
                  </button>
                ))}
              </SideMenu>
            </div>
            <div className="col-sm-9 col-md-10 donotprint">{sections[section]}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default DateEditorPage;
