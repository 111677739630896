import React from 'react';
import InfoLabel from '../../../components/InfoLabel';
import { IEvent } from '../../../interfaces/IEvent';
import ImageUploader, { IImageUploadRequest, ImageRequestGroup } from '../ImageUploader';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  event: IEvent;
  onImageRequested: (request: IImageUploadRequest) => void;
  imageRequests: { [key: string]: IImageUploadRequest };
  globalOptions: JSX.Element;
}

const ImageSection: React.FC<IProps> = (props) => {
  const { event, imageRequests, onImageRequested } = props;

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields" style={{ maxWidth: '400px' }}>
            <h2>Image</h2>
            <div className="section" style={{ marginBottom: '0' }}>
              <div className="section">
                <InfoLabel text="What is your event's main image?" tooltip="This image will represent your event in searches and be visible on the main event page. "></InfoLabel>

                <ImageUploader
                  eventId={event.Id}
                  url={imageRequests[ImageRequestGroup.Event] ? imageRequests[ImageRequestGroup.Event].url : event.ImageUrl}
                  onChange={(request) => onImageRequested(request)}
                  group={ImageRequestGroup.Event}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageSection;
