import linq from 'linq';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import AdminApi from '../api/AdminApi';
import Header from '../components/Header';
import Loader from '../components/Loader';
import CurrencyHelper from '../helpers/CurrencyHelper';
import LocationHelper from '../helpers/LocationHelper';
import { IEvent } from '../interfaces/IEvent';
import { IUser } from '../interfaces/IUser';
import SVGGoToEvent from '../svg/SVGGoToEvent';
import SVGPrint from '../svg/SVGPrint';
import './AttendeeStatementPage.scss';

interface IProps {
  ticketGuid?: string;
}
interface IStatementTicket {
  GeneralAdmission: boolean;
  Quantity: number;
  Cancelled: boolean;
}

interface IStatementOrder {
  PriceAsInt: number;
  Seats: IStatementTicket[];
  OrderNumber: string;
  DateOrdered: string;
  EventDate: any;
  UserNotes: string;
  Price: string;
}

interface IEventStatement {
  User: IUser;
  Event: IEvent;
  EventId: number;
  UserId: number;
  IsAdmin: boolean;
  Orders: IStatementOrder[];
  Dates: any[];
  Payments: any[];
  Referrals: any[];
  Requests: any[];
  TotalPaymentsAmount: string;
  TotalTickets: number;
  TotalReferralTickets: number;
  TotalPaidAsInt: number;
  TotalPaidAsString: string;
  TotalOwedAsInt: number;
  TotalOwedAsString: string;
  TotalOrdersPriceAsInt: number;
  TotalOrdersPriceAsString: string;
  TotalReferralsPriceAsInt: number;
  TotalReferralsPriceAsString: string;
  TotalOrdersAndReferralsPriceAsInt: number;
  TotalOrdersAndReferralsPriceAsString: string;
  IsMember: boolean;
  Member: number;
}

const AttendeeStatementPage: React.FC<IProps> = (props) => {
  const [busyMessage, setBusyMessage] = useState('Loading statement...');
  const [event, setEvent] = useState(null);
  const [statement, setStatement] = useState<IEventStatement>(null);

  const email = LocationHelper.getQueryVariable('email');

  const eventUrlDetails = LocationHelper.getLocation().pathname;
  const eventTag = eventUrlDetails.split('/')[1];

  useEffect(() => {
    AdminApi.request('GET', `/api/Statement?eventTag=${eventTag}&email=${LocationHelper.getQueryVariable('email')}`)
      .then((response: IEventStatement) => {
        setEvent(response.Event);
        setStatement(response);
        setBusyMessage(null);
      })
      .catch((message) => {
        alert(message);
        setBusyMessage('Sorry, we cannot create your statement.');
      });
  }, []);

  if (busyMessage) return <Loader>{busyMessage}</Loader>;

  var totalAmountOutstandingAsInt = linq.from(statement.Orders).sum((o) => o.PriceAsInt) - linq.from(statement.Payments).sum((o) => o.AmountPaidAsInt);
  var totalAmountOutstandingAsString = CurrencyHelper.formatCurrency(event.CurrencySymbol, totalAmountOutstandingAsInt);

  return (
    <div className="statement-page">
      <Helmet>
        <title>{eventTag} Statement</title>
        <meta name="description" content={`Statement for this event.`} />
      </Helmet>
      <Header />

      <div className="toolbar donotprint" style={{ borderBottom: '4px solid #ebebeb' }}>
        <div className="buttons">
          <Link to={`/${event.Tag}`}>
            <SVGGoToEvent />
            Go to event
          </Link>
          <button onClick={() => (window as any).print()}>
            <SVGPrint />
            Print statement
          </button>
        </div>
      </div>

      <div className="section-to-print">
        <div className="summary">
          <table style={{ width: '100%' }}>
            <tr>
              <td>
                {totalAmountOutstandingAsInt > 0 && (
                  <>
                    <p>
                      {event.LogoUrl && <img alt="Event logo" src={event.LogoUrl} />}
                      <span style={{ fontWeight: 900 }}>Current statement balance</span>
                      <br />
                      <span style={{ fontSize: '28px' }}>{totalAmountOutstandingAsString}</span>
                      <br />
                      <span>Owed to {statement.Event.Organisation as any}</span>
                    </p>
                    <br />
                  </>
                )}

                {totalAmountOutstandingAsInt == 0 && (
                  <>
                    {' '}
                    <p>
                      <span style={{ fontWeight: 900 }}>Balance for this event has been settled, thank you!</span>
                    </p>
                    <br />
                  </>
                )}

                {totalAmountOutstandingAsInt < 0 && (
                  <>
                    <p>
                      <span style={{ fontWeight: 900 }}>Statement balance</span>
                      <br />
                      <span style={{ fontSize: '28px' }}>{totalAmountOutstandingAsString}</span>
                      <br />
                      <span>Overpaid by {statement.User.Email}</span>
                    </p>
                    <br />
                  </>
                )}
              </td>
              <td style={{ textAlign: 'right' }}>
                <span style={{ fontWeight: 900 }}>Statement</span>
                <br />
                {moment().format('dddd Do MMMM YYYY')}
                <br />
                <span style={{ fontWeight: 900 }}>Event</span>
                <br />
                {statement.Event.Name}
                <br />
                <span style={{ fontWeight: 900 }}>Email</span>
                <br />
                {email}
                <br />
              </td>
            </tr>
          </table>

          <br />
          <br />

          <p>
            <span style={{ fontWeight: 900 }}>Orders placed for {statement.Event.Name}</span>
          </p>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr style={{ fontWeight: 900, fontSize: '12px', borderBottom: '1px solid lightgray' }}>
                <th style={{ width: '10%' }}>Order #</th>
                <th style={{ width: '18%' }}>Date ordered</th>
                <th style={{ width: '18%' }}>Event date</th>
                <th style={{ width: '39%' }}>Details</th>
                <th style={{ width: '15%', textAlign: 'right' }}>Price</th>
              </tr>

              {statement.Orders.map((order) => {
                const activeTickets = linq
                  .from(order.Seats)
                  .where((s) => !s.Cancelled)
                  .sum((s) => {
                    return s.GeneralAdmission ? s.Quantity : 1;
                  });
                const cancelledTickets = linq
                  .from(order.Seats)
                  .where((s) => s.Cancelled)
                  .sum((s) => {
                    return s.GeneralAdmission ? s.Quantity : 1;
                  });

                return (
                  <tr key={order.OrderNumber} style={{ borderBottom: '1px dashed lightgray' }}>
                    <td>{order.OrderNumber}</td>
                    <td style={{ fontSize: '9px' }}>
                      {moment(order.DateOrdered).format('ddd Do MMM YY')}
                      <br />
                      {moment(order.DateOrdered).format('HH:mm')}
                    </td>
                    <td style={{ fontSize: '9px' }}>
                      {moment(order.EventDate.Date).format('ddd Do MMM YY')}
                      <br />
                      {moment(order.EventDate.Date).format('HH:mm')}
                    </td>
                    <td>
                      {activeTickets > 0 && `${activeTickets} active`}
                      {activeTickets > 0 && cancelledTickets > 0 && ', '}
                      {cancelledTickets > 0 && `${cancelledTickets} cancelled`} tickets
                      {order.UserNotes && <div style={{ fontSize: '10px', fontStyle: 'italic' }}>Attendee Note: {order.UserNotes}</div>}
                    </td>
                    <td style={{ textAlign: 'right' }}>{order.Price}</td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={5} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  <span style={{ paddingRight: '10px' }}>Total orders</span>
                  <span>
                    {CurrencyHelper.formatCurrency(
                      statement.Event.CurrencySymbol,
                      linq.from(statement.Orders).sum((o) => o.PriceAsInt),
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <br />

          {statement.Payments.length > 0 ? (
            <>
              <p>
                <span style={{ fontWeight: 900 }}>Payments logged for {statement.Event.Name}</span>
              </p>
              <table style={{ width: '100%', marginBottom: '30px' }}>
                <tbody>
                  <tr style={{ fontWeight: 900, borderBottom: '1px solid lightgray' }}>
                    <th style={{ width: '10%' }}>Payment #</th>
                    <th style={{ width: '18%' }}>Date Made</th>
                    <th style={{ width: '18%' }}>Order #</th>
                    <th style={{ width: '39%' }}>Type</th>
                    <th style={{ width: '15%', textAlign: 'right' }}>Price</th>
                  </tr>

                  {statement.Payments.map((payment) => (
                    <tr key={`Payment_${payment.Id}`}>
                      <td>{payment.Id}</td>
                      <td style={{ fontSize: '9px' }}>
                        {moment(payment.DatePaymentMade).format('ddd Do MMM YY')}
                        <br />
                        {moment(payment.DatePaymentMade).format('HH:mm')}
                      </td>
                      <td>{payment.OrderIds.length == 0 ? <div>Balance</div> : payment.OrderIds.map((o) => <div key={o}>{o}</div>)}</td>
                      <td>{payment.PaymentTypeAsString}</td>
                      <td style={{ textAlign: 'right' }}>{payment.AmountPaidAsString}</td>
                    </tr>
                  ))}

                  <tr>
                    <td colSpan={5} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                      <span style={{ paddingRight: '10px' }}>Total payments</span>
                      <span>
                        {CurrencyHelper.formatCurrency(
                          event.CurrencySymbol,
                          linq.from(statement.Payments).sum((p) => p.AmountPaidAsInt),
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <p>You have not yet made any payments for {statement.Event.Name}</p>
          )}

          <br />

          <p style={{ fontStyle: 'italic' }}>This statement was generated by {statement.Event.Organisation as any}.</p>

          <br />

          <p style={{ fontSize: '12px' }}>All orders and payments in this statement were placed in agreement with terms of service found at http://Seaty.co.uk/Docs/TermsOfService.</p>
        </div>
      </div>
    </div>
  );
};

export default AttendeeStatementPage;
