import React from 'react';
import Block from './Block';
import BlockHeader from './BlockHeader';

export interface IProps {
  checked: boolean;
  onBoxClick?: () => void;
  onClick?: () => void;
  title?: string;
  className?: string;
  rightText?: string;
  fullClick?: boolean;
}

const CheckBox: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  return (
    <Block className={props.className ? props.className : ''} onClick={props.onClick}>
      {props.title && props.title.length > 0 ? <BlockHeader rightText={props.rightText}>{props.title}</BlockHeader> : null}
      <div className={'checkbox' + (props.checked ? ' checked' : '') + (props.fullClick ? ' full-click' : '')}>
        <table>
          <tbody>
            <tr>
              <td
                className="check"
                onClick={(e) => {
                  e.stopPropagation();
                  if (props.onBoxClick) {
                    props.onBoxClick();
                  }
                }}
              >
                <div
                  className="border"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.charCode === 13 || e.charCode === 32) {
                      props.onBoxClick && props.onBoxClick();
                    }
                  }}
                >
                  <div className="checkbox-background">
                    <span className="tick"></span>
                  </div>
                </div>
              </td>
              <td
                onClick={(e) => {
                  if (props.fullClick) {
                    e.stopPropagation();
                    if (props.onBoxClick) {
                      props.onBoxClick();
                    }
                  }
                }}
              >
                {props.children}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Block>
  );
};

export default CheckBox;
