import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
      <path d="M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11s11-4.9,11-11S18.1,1,12,1z M12,3c5,0,9,4,9,9s-4,9-9,9s-9-4-9-9S7,3,12,3z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
