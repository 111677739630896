import linq from 'linq';
import React from 'react';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import { IEvent } from '../../../interfaces/IEvent';
import Toolbar from '../toolbar/Toolbar';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const MembershipQuotasSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;

  if (event.Organisation.OrganisationTag == null) {
    event.Organisation.OrganisationTag = '';
  }

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>

      <h2>Membership quotas</h2>

      <div className="row">
        <div className="col-sm-8">
          {event.Organisation.MemberTypes.length === 0 && <p style={{ padding: '15px' }}>You have no member types for this organisation. To add a member type, go to the organisation editor.</p>}
          {linq
            .from(event.Organisation.MemberTypes)
            .orderBy((mt) => mt.Name)
            .toArray()
            .map((memberType, index) => (
              <div className="field" key={'org_membertype_list_item_' + index}>
                <div className="row">
                  <div className="col-sm-6">
                    <InfoLabel text={memberType.Name} tooltip="You can manage organisation member types and individual members in your organisation member section. " />

                    <InfoInput
                      maxLength={5}
                      labelText="Quota"
                      onChange={(value) => {
                        memberType.Quota = value;
                        onEventUpdated({ ...event, Organisation: { ...event.Organisation } });
                      }}
                      value={memberType.Quota}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="col-sm-4">
          <div className="info">
            <strong>Quotas</strong>
            <br />
            Ticket quotas will show up against balances in the event balance section against members that have the same member type as the quota member type. They help you manage who is meeting their
            targets.
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipQuotasSection;
