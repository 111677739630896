import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 23.998047 9 A 2.5 2.5 0 0 0 22.431641 13.445312 L 14.857422 21.019531 L 8.0546875 15.917969 A 2.5 2.5 0 0 0 5.9980469 12 A 2.5 2.5 0 0 0 5.5 16.947266 A 1.50015 1.50015 0 0 0 5.5 17.072266 L 6.5 38.072266 A 1.50015 1.50015 0 0 0 7.9980469 39.5 L 39.998047 39.5 A 1.50015 1.50015 0 0 0 41.498047 38.072266 L 42.498047 17.072266 A 1.50015 1.50015 0 0 0 42.498047 17 A 2.5 2.5 0 0 0 42.498047 12 A 2.5 2.5 0 0 0 40.296875 15.675781 A 1.50015 1.50015 0 0 0 40.099609 15.800781 L 33.140625 21.019531 L 25.566406 13.445312 A 2.5 2.5 0 0 0 23.998047 9 z M 24 16.121094 L 31.9375 24.060547 A 1.50015 1.50015 0 0 0 33.898438 24.199219 L 39.347656 20.111328 L 38.832031 30.957031 L 9.1660156 30.957031 L 8.6503906 20.111328 L 14.099609 24.199219 A 1.50015 1.50015 0 0 0 16.060547 24.060547 L 24 16.121094 z M 9.3085938 33.957031 L 38.689453 33.957031 L 38.568359 36.5 L 9.4296875 36.5 L 9.3085938 33.957031 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
