import Constants from '../helpers/Constants';
import GAHelper from '../helpers/GAHelper';
import UserHelper from '../helpers/UserHelper';
import linq from 'linq';

interface ApiErrorCode {
  code: number;
  description: string;
  userMessage: string;
}

export default class Api {
  public static async fetch(url, method, token, data?, abortController?: AbortController) {
    const apiErrorCodes: ApiErrorCode[] = [
      {
        code: 400,
        description: 'Bad Request',
        userMessage: 'The request was invalid. Please check your input and try again.',
      },
      {
        code: 401,
        description: 'Unauthorized',
        userMessage:
          'User account issue. We will attempt to clear your user cache and reload the page. If this problem persists, try and reload the page yourself after clearing your browser cache. Please contact support if this problem persists.',
      },
      {
        code: 403,
        description: 'Forbidden',
        userMessage: 'You do not have permission to access this resource. If you believe this is an error, contact support.',
      },
      {
        code: 404,
        description: 'Not Found',
        userMessage: 'The requested resource was not found. Check the URL and try again. If the problem persists, contact support.',
      },
      {
        code: 408,
        description: 'Request Timeout',
        userMessage: 'The request took too long to process. Please try again later. If the problem persists, contact support.',
      },
      {
        code: 500,
        description: 'Internal Server Error',
        userMessage: 'An unexpected error occurred on the server. Please try refreshing the page or come back later. If the issue continues, contact support.',
      },
      {
        code: 502,
        description: 'Bad Gateway',
        userMessage: 'The server received an invalid response. Please refresh your browser cache and try again. If the problem persists, contact support.',
      },
      {
        code: 503,
        description: 'Service Unavailable',
        userMessage: 'The service is currently unavailable. Please try again later. If the issue continues, contact support.',
      },
      {
        code: 504,
        description: 'Gateway Timeout',
        userMessage: 'The server is taking too long to respond. Please try again later. If the issue continues, contact support.',
      },
    ];

    return fetch(url, {
      method: method,
      signal: abortController?.signal,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: data ? JSON.stringify(data) : null,
    })
      .then((response) => {
        if (!response.ok) {
          const error = linq.from(apiErrorCodes).firstOrDefault((e) => e.code == response.status);

          if (error) {
            if (error.code == 401) {
              localStorage.removeItem(Constants.SeatyUserVar);
              return UserHelper.getGuestToken()
                .then((g) => {
                  return this.fetch(url, method, g.Token, data, abortController);
                })
                .catch(() => {
                  (window.location as any).reload(true);
                  return Promise.reject(error.userMessage);
                });
            } else {
              return Promise.reject(error.userMessage);
            }
          }

          return Promise.reject('Server did not respond.');
        } else {
          return response.json();
        }
      })
      .then((response: any) => {
        if (response.ErrorMessage && response.ErrorMessage.length > 0) {
          return Promise.reject(response.ErrorMessage);
        }
        if (response.ExceptionMessage && response.ExceptionMessage.length > 0) {
          return Promise.reject(response.ExceptionMessage);
        }
        if (response.error) {
          return Promise.reject(JSON.stringify(response.error));
        }

        return response;
      });
  }

  public static async throwError(message) {
    GAHelper.exception(JSON.stringify(UserHelper.currentUser) + ', ' + JSON.stringify(message));
    if (typeof message !== 'string') {
      return Promise.reject('Sorry, something went wrong. Please try clearing your browser cache if this problem persists.');
    }
    return Promise.reject(message);
  }

  public static async request(method: string, url: string, data?: any, resetOnFail?: boolean, abortController?: AbortController): Promise<any> {
    let token = UserHelper.currentUser ? UserHelper.currentUser.Token : UserHelper.guestUser ? UserHelper.guestUser.Token : null;
    if (!token || token.length < 2) {
      const guestUser = await UserHelper.getGuestToken();
      token = guestUser.Token;
    }

    if (!token) {
      alert('No token found...');
      return;
    }

    return Api.fetch(url, method, token, data, abortController).catch((message) => {
      var aborted = message && message.code && message.code == 20;

      if (aborted) {
        return Api.throwError('Aborted by the client.');
      }

      if (!UserHelper.currentUser && resetOnFail) {
        return UserHelper.getGuestToken()
          .then((user) => {
            return Api.fetch(url, method, user.Token, data).catch((message_2) => {
              return Api.throwError(message_2 + ' (ATT2)');
            });
          })
          .catch(() => {
            return Api.throwError(message + ' (NEWID)');
          });
      } else {
        return Api.throwError(message);
      }
    });
  }
}
