import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
      <path d="M 39.414063 4.585938 L 46.414063 11.585938 C 46.804688 11.976563 47 12.488281 47 13 C 47 13.511719 46.804688 14.023438 46.414063 14.414063 L 39.414063 21.414063 C 38.632813 22.195313 37.367188 22.195313 36.585938 21.414063 C 35.804688 20.632813 35.804688 19.367188 36.585938 18.585938 L 40.171875 15 L 17 15 C 15.894531 15 15 14.105469 15 13 C 15 11.894531 15.894531 11 17 11 L 40.171875 11 L 36.585938 7.414063 C 35.804688 6.632813 35.804688 5.367188 36.585938 4.585938 C 37.367188 3.804688 38.632813 3.804688 39.414063 4.585938 Z M 7 13 C 7 11.894531 6.105469 11 5 11 C 3.894531 11 3 11.894531 3 13 C 3 14.105469 3.894531 15 5 15 C 6.105469 15 7 14.105469 7 13 Z M 13 13 C 13 11.894531 12.105469 11 11 11 C 9.894531 11 9 11.894531 9 13 C 9 14.105469 9.894531 15 11 15 C 12.105469 15 13 14.105469 13 13 Z M 3.585938 35.585938 C 2.804688 36.367188 2.804688 37.632813 3.585938 38.414063 L 10.585938 45.414063 C 11.367188 46.195313 12.632813 46.195313 13.414063 45.414063 C 14.195313 44.632813 14.195313 43.367188 13.414063 42.585938 L 9.828125 39 L 33 39 C 34.105469 39 35 38.105469 35 37 C 35 35.894531 34.105469 35 33 35 L 9.828125 35 L 13.414063 31.414063 C 13.804688 31.023438 14 30.511719 14 30 C 14 29.488281 13.804688 28.976563 13.414063 28.585938 C 12.632813 27.804688 11.367188 27.804688 10.585938 28.585938 Z M 43 37 C 43 38.105469 43.894531 39 45 39 C 46.105469 39 47 38.105469 47 37 C 47 35.894531 46.105469 35 45 35 C 43.894531 35 43 35.894531 43 37 Z M 37 37 C 37 38.105469 37.894531 39 39 39 C 40.105469 39 41 38.105469 41 37 C 41 35.894531 40.105469 35 39 35 C 37.894531 35 37 35.894531 37 37 Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
