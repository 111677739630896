import React, { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg viewBox="0 0 50 50">
      <path d="M 8 0.083984375 L 7.1894531 2.1875 L 6.0136719 5.2382812 L 0.49804688 5.5351562 L 4.7851562 9.0175781 L 3.3652344 14.353516 L 8 11.353516 L 12.636719 14.351562 L 11.214844 9.0175781 L 15.501953 5.5351562 L 9.9882812 5.2382812 L 8 0.083984375 z M 8 4.9140625 L 8.7714844 6.9121094 L 10.908203 7.0273438 L 9.2460938 8.3769531 L 9.796875 10.445312 L 8 9.2832031 L 6.203125 10.447266 L 6.7539062 8.3769531 L 5.0917969 7.0273438 L 7.2304688 6.9121094 L 8 4.9140625 z M 20 6.0175781 L 20 7.9824219 L 50 7.9824219 L 50 6.0175781 L 20 6.0175781 z M 8 17.583984 L 7.1894531 19.6875 L 6.0136719 22.738281 L 0.49804688 23.035156 L 4.7851562 26.517578 L 3.3652344 31.853516 L 8 28.853516 L 12.636719 31.851562 L 11.214844 26.517578 L 15.501953 23.035156 L 9.9882812 22.738281 L 8 17.583984 z M 8 22.414062 L 8.7714844 24.412109 L 10.908203 24.527344 L 9.2460938 25.876953 L 9.796875 27.945312 L 8 26.783203 L 6.203125 27.947266 L 6.7539062 25.876953 L 5.0917969 24.527344 L 7.2304688 24.412109 L 8 22.414062 z M 20 24.017578 L 20 25.982422 L 50 25.982422 L 50 24.017578 L 20 24.017578 z M 8 34.583984 L 7.1894531 36.6875 L 6.0136719 39.738281 L 0.49804688 40.035156 L 4.7851562 43.517578 L 3.3652344 48.853516 L 8 45.853516 L 12.636719 48.851562 L 11.214844 43.517578 L 15.501953 40.035156 L 9.9882812 39.738281 L 8 34.583984 z M 8 39.414062 L 8.7714844 41.412109 L 10.908203 41.527344 L 9.2460938 42.876953 L 9.796875 44.945312 L 8 43.783203 L 6.203125 44.947266 L 6.7539062 42.876953 L 5.0917969 41.527344 L 7.2304688 41.412109 L 8 39.414062 z M 20 42.017578 L 20 43.982422 L 50 43.982422 L 50 42.017578 L 20 42.017578 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
