import { FunctionComponent, useState } from 'react';
import AdminApi from '../../../../api/AdminApi';
import Block from '../../../../components/Block';
import BlockHeader from '../../../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../../../components/BlockInfo';
import BlockTextBox from '../../../../components/BlockTextBox';
import Button from '../../../../components/Button';
import Info from '../../../../components/Info';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import Radio from '../../../../components/Radio';
import SpacerTable from '../../../../components/SpacerTable';
import EmailHelper from '../../../../helpers/EmailHelper';
import IMember from '../../../../interfaces/IMember';
import IMemberType from '../../../../interfaces/IMemberType';
import { IOrganisation } from '../../../../interfaces/IOrganisation';

interface IProps {
  onClose: () => void;
  organisation: IOrganisation;
  memberTypes: IMemberType[];
  member?: IMember;
}

const AddMemberModal: FunctionComponent<IProps> = (props) => {
  const [busyMessage, setBusyMessage] = useState(null);
  const [email, setEmail] = useState(props.member.Email);
  const [name, setName] = useState(props.member.Name);
  const [infoMessage, setInfoMessage] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [memberTypeId, setMemberTypeId] = useState(props.member ? props.member.MemberTypeId : props.memberTypes[0].Id);

  const addMemberClick = () => {
    setBusyMessage('Updating member...');

    AdminApi.request('PUT', '/api/Member', {
      id: props.member ? props.member.Id : null,
      email: email,
      memberTypeId: memberTypeId,
      organisationId: props.organisation.Id,
      removeIfExists: false,
      friendlyName: name,
    })
      .then((response) => {
        setInfoMessage('Member saved. You can now close this window.');
        setBusyMessage(null);
      })
      .catch((message) => {
        setInfoMessage(null);
        setErrorMessage(message);
        setBusyMessage(null);
      });
  };

  const deleteMember = () => {
    setBusyMessage('Deleting member...');

    AdminApi.request('DELETE', '/api/Member', {
      id: props.member.Id,
      organisationId: props.organisation.Id,
    })
      .then((response) => {
        setInfoMessage('Member deleted. You can now close this window.');
        setBusyMessage(null);
      })
      .catch((message) => {
        setInfoMessage(null);
        setErrorMessage(message);
        setBusyMessage(null);
      });
  };

  const renderAddMember = () => {
    if (busyMessage) return <Loader inline={true}>{busyMessage}</Loader>;

    if (infoMessage) {
      return (
        <>
          <table className="blocks">
            <tbody>
              <BlockInfo>{infoMessage}</BlockInfo>
            </tbody>
          </table>
        </>
      );
    }

    if (errorMessage != null) {
      return (
        <>
          <table className="blocks">
            <tbody>
              <BlockInfo type={InfoType.Error}>{errorMessage}</BlockInfo>
            </tbody>
          </table>
          <div className="spacer" />
          <SpacerTable>
            <Button onExecute={() => setErrorMessage(null)} text={'Dismiss'} />
          </SpacerTable>
        </>
      );
    }

    return (
      <>
        {deleting ? (
          <table className="blocks">
            <tbody>
              <Block>
                <Info>Are you sure you want to delete this member?</Info>
              </Block>
            </tbody>
          </table>
        ) : (
          <table className="blocks">
            <tbody>
              <Block>
                <BlockHeader>What is the name of the member?</BlockHeader>
                <BlockTextBox
                  placeholder={'Member name'}
                  autoFocus={true}
                  value={name}
                  onChange={(e) => {
                    setName(e);
                  }}
                />
              </Block>
              <Block>
                <BlockHeader>What is their email address?</BlockHeader>
                <BlockTextBox
                  type="email"
                  placeholder={'Email address'}
                  autoFocus={false}
                  value={email}
                  onChange={(e) => {
                    setEmail(e);
                  }}
                />
              </Block>
              <Block>
                <BlockHeader>What is their member type?</BlockHeader>
                <Radio
                  selectedId={memberTypeId}
                  onValueChanged={(option) => setMemberTypeId(option.Id)}
                  options={props.memberTypes.map((mt) => {
                    return { Id: mt.Id, Text: mt.Name };
                  })}
                />
              </Block>
            </tbody>
          </table>
        )}
        <div className="spacer" />

        {!deleting && (
          <SpacerTable>
            <Button disabled={!EmailHelper.validate(email) || name.length < 2} className="confirm" onExecute={() => addMemberClick()} text={'Save member'} />
            {props.member && props.member.Id > 0 && (
              <Button
                className="bad"
                onExecute={() => {
                  setDeleting(true);
                }}
                text={'Delete member'}
              />
            )}
          </SpacerTable>
        )}

        {deleting && (
          <SpacerTable>
            <Button className="bad" onExecute={() => deleteMember()} text={'Yes, delete this member type'} />
            <Button
              disabled={name.length < 2}
              onExecute={() => {
                setDeleting(false);
              }}
              text={'Cancel'}
            />
          </SpacerTable>
        )}
      </>
    );
  };

  return (
    <Modal theme={props.organisation.Theme} onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>Member Editor</h1>
            {props.organisation.Name}
          </SpacerTable>

          <div className="spacer" />

          {renderAddMember()}
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
};

export default AddMemberModal;
