import React from 'react';
import Button from '../../../components/Button';
import PaymentControl from '../../../components/stripe/PaymentControl';
import SpacerTable from '../../../components/SpacerTable';
import UserHelper from '../../../helpers/UserHelper';
import { IEvent } from '../../../interfaces/IEvent';
import IUserOrderOverview from '../../../interfaces/IUserOrderOverview';
import { Section } from '../../../modals/UserEventOverview';

export interface IProps {
  event: IEvent;
  overview: IUserOrderOverview;
  setSection: (section: Section) => void;
  onPaymentAccepted: () => void;
}

const BalanceSection: React.FC<IProps> = (props) => {
  const { event, overview, setSection } = props;

  return (
    <>
      <PaymentControl
        isAdmin={false}
        organisationFeePercentage={event.Organisation.ServiceFee}
        minimumFee={event.CardFeeMinimum}
        feePercentage={event.CardFeePercentage}
        absorbFee={event.AbsorbFee}
        eventId={event.Id}
        paymentMadeCallback={props.onPaymentAccepted}
        payeeEmail={UserHelper.currentUser.Email}
        currency={event.CurrencySymbol}
        balance={overview.TotalOwedAsInt}
        event={event}
      />

      <div className="spacer" />
      <SpacerTable>
        <Button onExecute={() => setSection(Section.Dashboard)} text={'Back to overview'} />
      </SpacerTable>
    </>
  );
};

export default BalanceSection;
