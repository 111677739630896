import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 12.5 6 C 8.9280619 6 6 8.9280619 6 12.5 L 6 17 L 6 21.150391 C 6.485 21.051391 6.986 21 7.5 21 L 9 21 L 9 17 L 39 17 L 39 19 L 39 27 L 39 28.5 C 39 30.450062 37.450062 32 35.5 32 C 33.549938 32 32 30.450062 32 28.5 C 32 25.480226 29.519774 23 26.5 23 L 7.5 23 C 4.4802259 23 2 25.480226 2 28.5 C 2 32.071938 4.9280619 35 8.5 35 L 20.5 35 L 35.5 35 C 39.071938 35 42 32.071938 42 28.5 L 42 27 L 42 19 L 42 12.5 C 42 8.9280619 39.071938 6 35.5 6 L 12.5 6 z M 12.5 9 L 35.5 9 C 37.450062 9 39 10.549938 39 12.5 L 39 14 L 9 14 L 9 12.5 C 9 10.549938 10.549938 9 12.5 9 z M 7.5 26 L 26.5 26 C 27.898226 26 29 27.101774 29 28.5 C 29 29.815038 29.494068 30.973486 30.173828 32 L 20.5 32 L 8.5 32 C 6.5499381 32 5 30.450062 5 28.5 C 5 27.101774 6.1017741 26 7.5 26 z M 42 33.970703 C 41.166 34.960703 40.110156 35.757156 38.910156 36.285156 C 38.553156 37.838156 37.16 39 35.5 39 L 12.5 39 C 11.107 39 9.9008906 38.181 9.3378906 37 L 8.5 37 C 7.668 37 6.8635156 36.87925 6.1035156 36.65625 C 6.6505156 39.69025 9.31 42 12.5 42 L 35.5 42 C 39.084 42 42 39.084 42 35.5 L 42 33.970703 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
