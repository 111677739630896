import CookiesPage from './CookiesPage';
import CreatingAnEvent from './CreatingAnEvent';
import FAQPage from './FAQPage';
import ISidebarItem from './ISidebarItem';
import PrivacyPage from './PrivacyPage';
import TermsPage from './TermsPage';
import WhatsNew from './WhatsNew';

const sidebarItems: ISidebarItem[] = [
  {
    name: 'Agreements',
    items: [
      {
        name: 'Terms of Service',
        url: '/Docs/TermsOfService',
        component: <TermsPage />,
      },
      {
        name: 'Privacy Policy',
        url: '/Docs/Privacy',
        component: <PrivacyPage />,
      },
      {
        name: 'Cookies and Related Technologies',
        url: '/Docs/Cookies',
        component: <CookiesPage />,
      },
    ],
  },
  {
    name: 'Help',
    items: [
      {
        name: 'Frequently Asked Questions',
        url: '/Docs/FAQ',
        component: <FAQPage />,
      },
      {
        name: 'Creating Events',
        url: '/Docs/Events',
        component: <CreatingAnEvent />,
      },
    ],
  },
];

export default sidebarItems;
