import { addDays, addMonths, addWeeks, endOfWeek, format, getWeek, isSameDay, lastDayOfWeek, startOfWeek, subMonths, subWeeks } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import './Week.scss';

interface IProps {
  showDetailsHandle?: (dayStr: string) => void;
  onWeekChange?: (weekStart: Date) => void;
  dayItems?: any[];
  initialWeek?: Date;
}

const Calendar: React.FC<IProps> = (props: IProps) => {
  const [currentMonth, setCurrentMonth] = useState<Date>(props.initialWeek ? props.initialWeek : new Date());
  const [currentWeek, setCurrentWeek] = useState<number>(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState<Date>(props.initialWeek ? props.initialWeek : new Date());
  const [weekState, setWeekState] = useState<{ start: Date; end: Date }>();

  useEffect(() => {
    props.onWeekChange && props.onWeekChange(startOfWeek(currentMonth, { weekStartsOn: 1 }));
  }, [currentMonth]);

  useEffect(() => {
    setWeekState({ start: startOfWeek(currentMonth, { weekStartsOn: 1 }), end: endOfWeek(currentMonth, { weekStartsOn: 1 }) });
  }, [currentWeek]);

  const changeMonthHandle = (btnType: string) => {
    if (btnType === 'prev') {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === 'next') {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType: string) => {
    if (btnType === 'prev') {
      setCurrentMonth((prevMonth) => subWeeks(prevMonth, 1));
      setCurrentWeek((prevWeek) => getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === 'next') {
      setCurrentMonth((prevMonth) => addWeeks(prevMonth, 1));
      setCurrentWeek((prevWeek) => getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onDateClickHandle = (day: Date, dayStr: string) => {
    setSelectedDate(day);
    props.showDetailsHandle && props.showDetailsHandle(dayStr);
  };

  const renderHeader = () => {
    const dateFormat = 'MMM yyyy';
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <button className="admin-button" onClick={() => changeWeekHandle('prev')}>
            Previous
          </button>
        </div>
        <div className="col col-center">
          <span>
            {weekState && weekState.start && weekState.start.getMonth() != weekState.end.getMonth()
              ? `${moment(weekState.start).format('MMMM YYYY')} - ${moment(weekState.end).format('MMMM YYYY')}`
              : format(currentMonth, dateFormat)}
          </span>
        </div>
        <div className="col col-end">
          <button className=" admin-button" onClick={() => changeWeekHandle('next')}>
            Next
          </button>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {moment(startDate).add(i, 'days').format('ddd')} <strong>{moment(startDate).add(i, 'days').format('Do')}</strong>
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div className={`col cell ${isSameDay(day, new Date()) ? 'today' : isSameDay(day, selectedDate) ? 'selected' : ''}`} key={day.toString()}>
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>

            {props.dayItems && props.dayItems.length >= i && props.dayItems[i]}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day.toString()}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default Calendar;
