import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path d="M3.175 13.775l-1.962.391c.141.704.352 1.398.629 2.063l1.846-.77C3.462 14.917 3.29 14.351 3.175 13.775zM3.167 10.264L1.205 9.879C1.069 10.572 1 11.286 1 12v.021L3 12C3 11.415 3.056 10.83 3.167 10.264zM4.502 7.02L2.838 5.91C2.44 6.507 2.099 7.147 1.825 7.813l1.85.762C3.898 8.031 4.177 7.508 4.502 7.02zM6.98 4.528L5.861 2.87c-.596.402-1.154.864-1.66 1.371l1.417 1.412C6.033 5.236 6.491 4.858 6.98 4.528zM10.215 3.178L9.824 1.217C9.118 1.357 8.424 1.57 7.76 1.848l.772 1.846C9.074 3.466 9.64 3.293 10.215 3.178zM14.107 1.21c0 0-.445-.074-.619-.097-.172-.023-.614-.071-.614-.071C12.584 1.019 11.969 1 11.969 1L12 3c.264 0 .529.015.793.038.158.014.718.091.843.114C17.832 3.928 21 7.647 21 12c0 4.963-4.038 9-9 9-2.504 0-4.872-1.053-6.562-2.855-.078-.083-.155-.166-.23-.253-.161-.185-.312-.378-.459-.577-.074-.101-.155-.198-.225-.302l-1.66 1.115c.039.058.085.111.125.168l-.006.004c.048.069.106.128.155.195.179.244 1.111 1.28 1.111 1.28C6.294 21.811 9.068 23 12 23c6.065 0 11-4.935 11-11C23 6.719 19.182 2.204 14.107 1.21z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
