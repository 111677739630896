import React, { useState } from 'react';
import InfoInput from '../../../components/InfoInput';
import Switch from '../../../components/Switch';
import NumberHelper from '../../../helpers/NumberHelper';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';

interface IProps {
  symbol: string;
  category: ITicketCategory;
  index: number;
  onPriceChange: (value: string) => void;
  onHideChange: (value: boolean) => void;
}

const SeatedTicketCategory: React.FC<IProps> = (props) => {
  const [price, setPrice] = useState<string>((props.category.PriceAsInt / 100.0).toFixed(2));
  const [valid, setValid] = useState<boolean>(NumberHelper.isNumeric((props.category.PriceAsInt / 100.0).toFixed(2)));

  var category = props.category;

  return (
    <div className="row">
      <div className="col-sm-8">
        <InfoInput
          symbol={props.symbol}
          onBlur={() => {
            props.onPriceChange(price);
            if (valid) setPrice(parseFloat(price).toFixed(2));
          }}
          labelColour={valid ? category.SeatCategory.Colour : 'red'}
          labelText={category.SeatCategory.Name + (valid ? '' : ' (Invalid)')}
          onChange={(value) => {
            setValid(NumberHelper.isNumeric(value));
            setPrice(value);
            props.onPriceChange(value);
          }}
          value={price}
        />
      </div>
      <div className="col-sm-4" style={{ padding: '10px' }}>
        <Switch trueLabel="Public" falseLabel="Private" onChange={(value) => props.onHideChange(!value)} checked={!props.category.Hide} />
      </div>
    </div>
  );
};

export default SeatedTicketCategory;
