import React, { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 8.5 7 C 5.4802259 7 3 9.4802259 3 12.5 L 3 35.5 C 3 38.519774 5.4802259 41 8.5 41 L 39.5 41 C 42.519774 41 45 38.519774 45 35.5 L 45 12.5 C 45 9.4802259 42.519774 7 39.5 7 L 8.5 7 z M 8.5 10 L 39.5 10 C 40.898226 10 42 11.101774 42 12.5 L 42 35.5 C 42 36.898226 40.898226 38 39.5 38 L 8.5 38 C 7.1017741 38 6 36.898226 6 35.5 L 6 12.5 C 6 11.101774 7.1017741 10 8.5 10 z M 24.013672 14 A 1.50015 1.50015 0 0 0 22.65625 14.832031 L 20.226562 19.724609 L 14.785156 20.509766 A 1.50015 1.50015 0 0 0 13.957031 23.072266 L 17.886719 26.875 L 16.958984 32.244141 A 1.50015 1.50015 0 0 0 19.130859 33.830078 L 24 31.287109 L 28.869141 33.830078 A 1.50015 1.50015 0 0 0 31.041016 32.244141 L 30.113281 26.875 L 34.042969 23.072266 A 1.50015 1.50015 0 0 0 33.214844 20.509766 L 27.773438 19.724609 L 25.34375 14.832031 A 1.50015 1.50015 0 0 0 24.013672 14 z M 24 18.871094 L 25.4375 21.763672 A 1.50015 1.50015 0 0 0 26.566406 22.580078 L 29.761719 23.041016 L 27.457031 25.271484 A 1.50015 1.50015 0 0 0 27.023438 26.603516 L 27.568359 29.765625 L 24.693359 28.265625 A 1.50015 1.50015 0 0 0 23.306641 28.265625 L 20.431641 29.765625 L 20.976562 26.603516 A 1.50015 1.50015 0 0 0 20.542969 25.271484 L 18.238281 23.041016 L 21.433594 22.580078 A 1.50015 1.50015 0 0 0 22.5625 21.763672 L 24 18.871094 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
