import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 48 48">
      <path d="M15.5,22.5c-.8,0-1.5.7-1.5,1.5,0,.8.7,1.5,1.5,1.5,0,0,0,0,0,0h17c.8,0,1.5-.7,1.5-1.5,0-.8-.7-1.5-1.5-1.5,0,0,0,0,0,0H15.5Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
