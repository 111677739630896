import linq from 'linq';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AdminApi from '../../api/AdminApi';
import showSignInState from '../../atoms/showSignInState';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import CacheHelper from '../../helpers/CacheHelper';
import LocationHelper from '../../helpers/LocationHelper';
import UserHelper from '../../helpers/UserHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IEventDate } from '../../interfaces/IEventDate';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { ITour } from '../../interfaces/ITour';
import DateEditorPage from '../Editor/date/DateEditorPage';
import EventEditorPage from '../Editor/event/EventEditorPage';
import OrganisationEditorPage from '../Editor/organisation/OrganisationEditorPage';
import TourEditorPage from '../Editor/tour/TourEditorPage';
import './Admin.scss';
import AdminHeader from './AdminHeader';
import AdminSections, { AdminSection } from './AdminSections';
import AdminBalances from './Sections/Balances/AdminBalances';
import AdminBanking from './Sections/Banking/AdminBanking';
import AdminCompare from './Sections/Compare/AdminCompare';
import AdminEvents from './Sections/Events/AdminEvents';
import AdminFeed from './Sections/Feed/AdminFeed';
import AdminLedger from './Sections/Ledger/AdminLedger';
import AdminLocks from './Sections/Locks/AdminLocks';
import AdminMembers from './Sections/Members/AdminMembers';
import AdminMemberTypes from './Sections/Members/AdminMemberTypes';
import AdminRequests from './Sections/Requests/AdminRequests';
import AdminRoster from './Sections/Roster/AdminRoster';
import AdminScans from './Sections/Scans/AdminScans';
import AdminStatements from './Sections/Statements/AdminStatements';
import AdminEventDateSummary from './Sections/Summary/AdminEventDateSummary';
import AdminEventSummary from './Sections/Summary/AdminEventSummary';
import AdminOrganisationSummary from './Sections/Summary/AdminOrganisationSummary';
import AdminTourSummary from './Sections/Summary/AdminTourSummary';
import AdminTours from './Sections/Tours/AdminTours';
import AdminWithdrawals from './Sections/Withdrawals/AdminWithdrawals';
import AdminAttendee from './Sections/Attendees/AdminAttendees';
import AdminMoreOptions from './Sections/Menu/AdminMoreOptions';
import TicketsPage from '../TicketsPage';

export interface IProps {
  hideFooter?: boolean;
  loadEventDate?: (event: IEvent, eventDateId: number, updateUrl: boolean) => void;
}

const AdminPage: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const [section, setSection] = useState<AdminSection>(null);
  const [busyMessage, setBusyMessage] = useState('Loading...');
  const [loadingOrganisationsMessage, setLoadingOrganisationsMessage] = useState('Loading...');
  const [organisations, setOrganisations] = useState<IOrganisation[]>([]);
  const [showSignIn, setShowSignIn] = useRecoilState(showSignInState);

  const location = useLocation();

  useEffect(() => {
    if (!UserHelper.currentUser || UserHelper.currentUser.Guest) {
      setShowSignIn(true);
      return;
    }

    AdminApi.request('GET', `/api/UserOrganisations`)
      .then((response: string) => {
        if (response == null) {
          alert('No response from user organisations (1).');
        } else {
          const organisations = JSON.parse(response) as IOrganisation[];
          if (organisations.length == 0) {
            alert('Cannot find any organisations for this user (1).');
          } else {
            setOrganisations(organisations);
          }
        }
      })
      .finally(() => {
        setLoadingOrganisationsMessage(null);
      });
  }, []);

  const getOrganisationTagFromUrl = (url) => {
    const tag = url.split('/')[2];
    return tag;
  };

  const getTourTagFromUrl = (url) => {
    const mode = url.split('/')[3];
    const tag = url.split('/')[4];

    if (mode == 'Tour') {
      return tag;
    } else {
      return null;
    }
  };

  const getEventDateFromUrl = (url) => {
    const mode = url.split('/')[5];
    const date = url.split('/')[6];
    const time = url.split('/')[7];

    if (mode == 'Date') {
      return date + '/' + time;
    } else {
      return null;
    }
  };

  const getEventTagFromUrl = (url) => {
    const mode = url.split('/')[3];
    const tag = url.split('/')[4];

    if (tag == 'Create') {
      return null;
    } else if (mode == 'Event') {
      return tag;
    } else {
      return null;
    }
  };

  const [state, setState] = useState<{
    organisationTag: string;
    eventTag: string;
    tourTag: string;
    eventDateTime: string;
    organisation: IOrganisation;
    tour: ITour;
    event: IEvent;
    eventDate: IEventDate;
    error: string;
  }>({
    eventTag: getEventTagFromUrl(LocationHelper.getLocation().pathname),
    tourTag: getTourTagFromUrl(LocationHelper.getLocation().pathname),
    organisationTag: getOrganisationTagFromUrl(LocationHelper.getLocation().pathname),
    eventDateTime: getEventDateFromUrl(LocationHelper.getLocation().pathname),
    organisation: null,
    tour: null,
    event: null,
    eventDate: null,
    error: null,
  });

  const organisation = state.organisation;
  const tour = state.tour;
  const event = state.event;
  const date = state.eventDate;

  const setError = (message) => {
    setState({ ...state, error: message, organisation: null, tour: null, event: null, eventDate: null });
  };

  useEffect(() => {
    const fragments = location.pathname.split('/');
    const organisationLandingPage = fragments.length == 3 && fragments[1] == 'Organisation';
    const tourLandingPage = fragments.length == 3 && fragments[1] == 'Tour';
    const eventLandingPage = fragments.length == 2;
    const bookingPage = fragments.length > 1 && fragments[2] == 'tickets';
    const sectionName = bookingPage ? 'tickets' : fragments[fragments.length - 1].toLowerCase();

    if (bookingPage) {
      setSection(AdminSection.Tickets);
    } else if (eventLandingPage) {
      setSection(AdminSection.Landing);
    } else if (tourLandingPage) {
      setSection(AdminSection.Landing);
    } else if (organisationLandingPage) {
      setSection(AdminSection.Landing);
    } else if (fragments && fragments.length == 8) {
      if (fragments[5].toLowerCase() == 'scans') setSection(AdminSection.Scans);
    } else {
      AdminSections.getSections().forEach((s) => {
        if (sectionName == s.toString().toLowerCase()) {
          setSection(s);
        }
      });
    }

    const _organisationTag = tourLandingPage || bookingPage ? '' : getOrganisationTagFromUrl(location.pathname);
    const _eventTag = tourLandingPage
      ? ''
      : eventLandingPage || bookingPage
        ? fragments[1]
        : getEventTagFromUrl(location.pathname);
    const _tourTag = tourLandingPage
      ? location.pathname.split('/')[2]
      : bookingPage
        ? ''
        : getTourTagFromUrl(location.pathname);
    const _eventDateTime = bookingPage ? '' : getEventDateFromUrl(location.pathname);

    const newState = {
      ...state,
      organisationTag: _organisationTag,
      eventTag: _eventTag,
      tourTag: _tourTag,
      eventDateTime: _eventDateTime,
    };

    if (_eventTag) {
      const getDateFromFragments = (_event, _fragments) => {
        if (bookingPage) {
          const date = _fragments[3];
          const time = _fragments[4];

          return linq
            .from(_event.Dates)
            .firstOrDefault((d) => moment(d.Date).format('DDMMMYYYY/HHmm') == `${date}/${time}`);
        } else {
          return (
            _eventDateTime &&
            _event &&
            _event.Dates &&
            linq.from(_event.Dates).firstOrDefault((d) => moment(d.Date).format('DDMMMYYYY/HHmm') === _eventDateTime)
          );
        }
      };

      const isAlreadyLoaded = state && state.event && state.event.EventTag.toLowerCase() == _eventTag.toLowerCase();
      if (isAlreadyLoaded) {
        let _eventDate = getDateFromFragments(state.event, fragments);

        setState({ ...state, eventDate: _eventDate, eventDateTime: _eventDateTime });
        return;
      }

      setBusyMessage('Loading event...');

      CacheHelper.eventByTag(_eventTag)
        .then((_event) => {
          let _eventDate = getDateFromFragments(_event, fragments);

          return CacheHelper.organisationByTag(_event.Organisation.OrganisationTag).then((_organisation) => {
            setState({
              ...newState,
              organisation: _organisation,
              event: _event,
              tour: _event.Tour,
              eventDate: _eventDate,
              organisationTag: _event.Organisation.OrganisationTag,
              eventTag: _eventTag,
              tourTag: _tourTag,
              eventDateTime: _eventDateTime,
            });
          });
        })
        .finally(() => setBusyMessage(null))
        .catch((message) => setError(message));
    } else if (_tourTag) {
      setBusyMessage('Loading tour...');
      CacheHelper.tourByTag(_tourTag)
        .then((_tour) => {
          return CacheHelper.organisationByTag(_tour.Organisation.OrganisationTag).then((_organisation) => {
            setState({
              ...newState,
              tourTag: _tourTag,
              organisationTag: _organisation.OrganisationTag,
              organisation: _organisation,
              tour: _tour,
              event: null,
            });
          });
        })
        .finally(() => setBusyMessage(null))
        .catch((message) => setError(message));
    } else {
      if (!state.organisation || state.organisation.OrganisationTag != _organisationTag) {
        setBusyMessage('Loading organisation...');
        CacheHelper.organisationByTag(_organisationTag)
          .then((_organisation) => {
            setState({ ...newState, tour: null, event: null, organisation: _organisation });
          })
          .finally(() => setBusyMessage(null))
          .catch((message) => setError(message));
      } else {
        setState({ ...newState, tour: null, event: null });
      }
    }
  }, [location]);

  if (!UserHelper.currentUser || UserHelper.currentUser.Guest) {
    return (
      <>
        <Header />
      </>
    );
  }

  return (
    <>
      <div className="admin-dashboard">
        <AdminHeader
          organisations={organisations}
          tourTag={state.tourTag}
          cachedTour={tour}
          eventTag={state.eventTag}
          organisationTag={state.organisationTag}
          cachedOrganisation={organisation}
          date={date}
          cachedEvent={event}
          section={section}
          loadEventDate={props.loadEventDate}
        ></AdminHeader>

        <div
          className={`admin-dashboard-section ${section} ${date && section == AdminSection.Tickets ? ' no-padding' : ''}`}
        >
          {busyMessage || loadingOrganisationsMessage ? (
            <Loader inline>{busyMessage || loadingOrganisationsMessage}</Loader>
          ) : (
            <>
              {organisation && !event && !tour && (
                <>
                  {section == AdminSection.Statements && <AdminStatements organisation={organisation} />}
                  {section == AdminSection.Events && <AdminEvents organisation={organisation} />}
                  {section == AdminSection.Tours && <AdminTours organisation={organisation} />}
                  {section == AdminSection.Members && <AdminMembers organisation={organisation} />}
                  {section == AdminSection.MemberTypes && <AdminMemberTypes organisation={organisation} />}
                  {section == AdminSection.Edit && <OrganisationEditorPage />}
                  {section == AdminSection.Summary && <AdminOrganisationSummary organisation={organisation} />}
                  {section == AdminSection.Feed && <AdminFeed superAdmin={true} organisation={organisation} />}
                  {section == AdminSection.Attendees && <AdminAttendee superAdmin={true} organisation={organisation} />}
                  {section == AdminSection.Compare && <AdminCompare organisation={organisation} />}
                  {organisation.OrganisationTag.toLowerCase() == 'seaty' && (
                    <>
                      {section == AdminSection.Ledger && <AdminLedger organisation={organisation} />}
                      {section == AdminSection.Withdrawals && <AdminWithdrawals organisation={organisation} />}
                    </>
                  )}
                </>
              )}

              {tour && !event && (
                <>
                  {section == AdminSection.Summary && <AdminTourSummary organisation={organisation} tour={tour} />}
                  {section == AdminSection.Events && <AdminEvents organisation={organisation} tour={tour} />}
                  {section == AdminSection.Edit && <TourEditorPage />}
                </>
              )}

              {event && !date && (
                <>
                  {section == AdminSection.Summary && <AdminEventSummary event={event} />}
                  {section == AdminSection.Banking && <AdminBanking event={event} />}
                  {section == AdminSection.Balances && <AdminBalances organisation={organisation} event={event} />}
                  {organisation && section == AdminSection.Feed && (
                    <AdminFeed organisation={organisation} event={event} />
                  )}
                  {section == AdminSection.Requests && <AdminRequests event={event} />}
                  {section == AdminSection.Scans && <AdminScans event={event} />}
                  {section == AdminSection.Roster && <AdminRoster event={event} />}
                  {section == AdminSection.Locks && <AdminLocks event={event} />}
                  {section == AdminSection.Edit && <EventEditorPage />}
                </>
              )}

              {date && (
                <>
                  {section == AdminSection.Summary && <AdminEventDateSummary event={event} eventDate={date} />}
                  {section == AdminSection.Scans && <AdminScans event={event} eventDate={date} />}
                  {section == AdminSection.Locks && <AdminLocks event={event} eventDate={date} />}
                  {section == AdminSection.Edit && <DateEditorPage />}
                  {section == AdminSection.Print && <TicketsPage />}
                </>
              )}

              {props.children}
            </>
          )}
        </div>
        {props.hideFooter ? null : <Footer admin={true} />}
      </div>
    </>
  );
};

export default AdminPage;
