import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 48 48">
      <path d="M24,14c-.8,0-1.5.7-1.5,1.5v7h-7c-.8,0-1.5.7-1.5,1.5,0,.8.7,1.5,1.5,1.5,0,0,0,0,0,0h7v7c0,.8.7,1.5,1.5,1.5.8,0,1.5-.7,1.5-1.5,0,0,0,0,0,0v-7h7c.8,0,1.5-.7,1.5-1.5,0-.8-.7-1.5-1.5-1.5,0,0,0,0,0,0h-7v-7c0-.8-.7-1.5-1.5-1.5,0,0,0,0,0,0Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
