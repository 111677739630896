import linq from 'linq';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import EventHelper from '../../helpers/EventHelper';
import { ITour } from '../../interfaces/ITour';
import EventDates from './EventDates';
import { IEvent } from '../../interfaces/IEvent';
import { IEventDate } from '../../interfaces/IEventDate';

interface IProps {
  isAdmin?: boolean;
  tour: ITour;
  showPastDates: boolean;
  onVenueLinkClick?: () => void;
  onEventDateClicked?: (e, date) => void;
}

const TourModalDates: FunctionComponent<IProps> = (props) => {
  const { tour, isAdmin, showPastDates } = props;

  const events = linq
    .from(tour.Events)
    .select((e) => {
      return { ...e, PastDates: EventHelper.getPastDates(e, isAdmin), FutureDates: EventHelper.getFutureDates(e, isAdmin) };
    })
    .orderBy((e) => (e.FutureDates.length > 0 ? moment.utc(e.FutureDates[0].first().DateAsString).unix() : moment()))
    .toArray();

  return (
    <>
      <EventDates onEventDateClicked={props.onEventDateClicked} events={events} showPastDates={showPastDates} isAdmin={isAdmin} onVenueLinkClick={props.onVenueLinkClick && props.onVenueLinkClick} />
    </>
  );
};

export default TourModalDates;
