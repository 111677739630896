import React, { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg viewBox="0 0 600 600">
      <g>
        <path
          className="seatyIcon__path seatyIcon__path--1"
          d="M177.3,357.9c1.9,15,16.1,20.6,26.6,26.4c0,0,24.6,16.3,95.4,16.3c69.4,0,97-15.6,97-15.6
		c14.7-7.4,25.9-11,26.6-26.4l18.9-257c1.1-14.8-15-21.3-26.6-26.4c0,0-29.4-14.8-115.8-14.8S179.1,75.1,179.1,75.1
		c-13.2,4.9-27.9,11.7-26.6,26.4L177.3,357.9z"
        />
        <path
          className="seatyIcon__path seatyIcon__path--2"
          d="M571.5,443c3.9,11.5-4.5,25.1-25.6,25.1h-27.7c-14.1,0-22.8-7.7-25.6-25.1l-35.8-182.4
		c-3-17.3,10.4-25.1,25.6-25.1h10.5c14.1,0,21.3,9.2,25.6,25.1L571.5,443z"
        />
        <path
          className="seatyIcon__path seatyIcon__path--3"
          d="M457.5,480.3l-6.9-42.5c-2.5-15.6-9-32.3-25.2-22.4c0,0-60.3,37-126.1,37c-65.8,0-126.1-37-126.1-37
		c-16.2-9.9-22.7,6.8-25.2,22.4l-6.9,42.5c-2.3,15.3,11.5,20.9,24.6,29.3c0,0,52.5,30.1,133.6,30.1v0c0,0,0,0,0,0c0,0,0,0,0,0v0
		c81.1,0,133.6-30.1,133.6-30.1C446,501.2,459.8,495.6,457.5,480.3z"
        />
        <path
          className="seatyIcon__path seatyIcon__path--4"
          d="M28.5,443C24.6,454.5,33,468,54.1,468h27.7c14.1,0,22.8-7.7,25.6-25.1l35.8-182.4c3-17.3-10.4-25.1-25.6-25.1
		h-10.5c-14.1,0-21.3,9.2-25.6,25.1L28.5,443z"
        />
      </g>
    </svg>
  );
}) as FunctionComponent<IProps>;
