import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 50">
      <path
        d="M42,3c-0.3,0.2-0.5,0.5-0.7,0.8L39.9,8l-4.2-1.4c-0.4-0.1-0.7-0.1-1,0.1C34.2,6.9,33.9,7.4,34,8c0,0.5,0.4,1,0.9,1.2
      l4.2,1.4l-1.4,4.2c-0.2,0.4,0,1,0.2,1.3c0.3,0.4,0.8,0.6,1.3,0.4c0.5-0.1,0.9-0.4,1-0.9l1.4-4.2l4.2,1.4c0.4,0.2,1,0,1.3-0.2
      c0.4-0.3,0.6-0.8,0.4-1.3c-0.1-0.5-0.4-0.9-0.9-1l-4-1.6L44,4.5c0.2-0.5,0-1.1-0.4-1.4C43.1,2.8,42.5,2.7,42,3L42,3z"
      />
      <path
        d="M12.7,17.6c-1.3,0.7-2.2,1.7-2.5,2.9s-0.1,2.3,0.4,3.4c0.4,0.9,1.2,1.7,2,2.1L8.9,37c-1-0.2-1.9-0.1-3,0.4
      c-1.3,0.7-2.2,1.7-2.5,2.9c-0.3,1.1-0.1,2.3,0.4,3.4s1.4,1.9,2.5,2.4c1.1,0.4,2.5,0.4,3.8-0.3s2.2-1.7,2.5-2.9
      c0.3-1.1,0.1-2.3-0.4-3.4c-0.4-0.9-1.2-1.6-2.1-2l3.7-11c1,0.2,2,0,2.9-0.5c1-0.5,1.7-1.3,2.1-2.1l11,3.7c-0.1,1,0,2,0.5,2.9
      c0.5,1,1.4,1.9,2.5,2.4c1.1,0.4,2.5,0.4,3.8-0.3c1.3-0.7,2.2-1.7,2.5-2.9c0.3-1.1,0.1-2.3-0.4-3.4c-0.4-0.9-1.2-1.7-2-2.1l1.5-4.6
      c0.1-0.3,0-0.6-0.2-0.8c-0.2-0.2-0.5-0.2-0.8,0c-0.2,0.1-0.2,0.2-0.3,0.4l-1.5,4.6c-1-0.2-2,0-2.9,0.5c-1,0.5-1.8,1.2-2.1,2l-11-3.7
      c0.2-1,0.1-2-0.3-2.9c-0.5-1-1.4-1.9-2.5-2.4C15.4,16.9,14.1,16.9,12.7,17.6z M13.6,19.2c0.9-0.4,1.6-0.4,2.3-0.1
      c0.7,0.2,1.2,0.8,1.5,1.4c0.3,0.7,0.4,1.3,0.2,2.1c-0.2,0.7-0.6,1.3-1.5,1.7s-1.6,0.4-2.3,0.1c-0.7-0.2-1.2-0.8-1.5-1.4
      c-0.3-0.7-0.4-1.3-0.2-2.1C12.3,20.2,12.7,19.6,13.6,19.2z M33.2,25.8c0.9-0.4,1.6-0.4,2.3-0.1c0.7,0.2,1.2,0.8,1.5,1.4
      c0.3,0.7,0.4,1.3,0.2,2.1c-0.2,0.7-0.6,1.3-1.5,1.7s-1.6,0.4-2.3,0.1c-0.7-0.2-1.2-0.8-1.5-1.4c-0.3-0.7-0.4-1.3-0.2-2.1
      C31.8,26.8,32.3,26.3,33.2,25.8z M6.7,39c0.9-0.4,1.6-0.4,2.3-0.1c0.7,0.2,1.2,0.8,1.5,1.4c0.3,0.7,0.4,1.3,0.2,2.1
      c-0.2,0.7-0.6,1.3-1.5,1.7c-0.9,0.4-1.6,0.4-2.3,0.1c-0.7-0.2-1.2-0.8-1.5-1.4S5,41.5,5.2,40.8C5.4,40,5.8,39.5,6.7,39z"
      />
    </svg>
  );
}) as FunctionComponent<IProps>;
