import React from 'react';

export interface IProps {
  className?: string;
}

const SpacerTable: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  return (
    <table className={props.className}>
      <tbody>
        <tr>
          <td className="spacer"></td>
          <td>{props.children}</td>
          <td className="spacer"></td>
        </tr>
      </tbody>
    </table>
  );
};
export default SpacerTable;
