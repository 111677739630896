import moment from 'moment';
import UserHelper from '../helpers/UserHelper';
import { IEvent } from '../interfaces/IEvent';
import { IEventDate } from '../interfaces/IEventDate';
import { ISeatCategory } from '../interfaces/ISeatCategory';
import { ISeatingPlanSeat } from '../interfaces/ISeatingPlanSeat';
import { ITicketCategory } from '../interfaces/ITicketCategory';
import { IOrderConfig } from '../views/Order/Order';
import LockService from './LockService';
import TicketService from './TicketService';
import linq from 'linq';

export default interface ITicketSwap {
  seatCategories: ISeatCategory[];
  ticketCategories: ITicketCategory[];
  orderConfig: IOrderConfig;
  allTicketsAvailable: boolean;
  dateLocked: moment.Moment;
  event: IEvent;
  eventDate: IEventDate;
  selectedSeats?: Array<ISeatingPlanSeat>;
  generalAdmissionTickets?: Array<ITicketCategory>;
  error?: string;
}

export default class SwapService {
  public static StartSwap(
    event: IEvent,
    eventDate: IEventDate,
    selectedSeats?: Array<ISeatingPlanSeat>,
    generalAdmissionTickets?: Array<ITicketCategory>,
  ): Promise<ITicketSwap> {
    const result: ITicketSwap = {
      event: event,
      eventDate: eventDate,
      selectedSeats: selectedSeats,
      generalAdmissionTickets: generalAdmissionTickets,
      seatCategories: [],
      ticketCategories: [],
      orderConfig: null,
      allTicketsAvailable: false,
      dateLocked: null,
    };

    const hasSeatingPlans = event.UseSeatingPlan && event.SeatingPlans && event.SeatingPlans.length > 0;
    if (event.UseSeatingPlan && hasSeatingPlans) {
      const seatCategories = linq
        .from(event.SeatingPlans)
        .selectMany((sp) => sp.SeatCategories)
        .where((sc) => eventDate.SeatCategoryIds.filter((id) => id === sc.Id).length > 0)
        .toArray();

      result.seatCategories = seatCategories;
    }

    const ticketCategories = linq
      .from(event.Categories.concat(generalAdmissionTickets))
      .where((tc) => eventDate.TicketCategoryIds.filter((id) => id === tc.Id).length > 0)
      .toArray();

    result.ticketCategories = ticketCategories;

    const tickets = TicketService.getSelectedSeatedTickets(selectedSeats).concat(
      TicketService.getSelectedGeneralAdmissionTickets(true, generalAdmissionTickets),
    );

    return LockService.LockTickets(eventDate.Id, tickets)
      .then((lockTicketsResult) => {
        if (lockTicketsResult.AllAvailable) {
          result.dateLocked = moment();
          result.allTicketsAvailable = true;

          result.orderConfig = {
            event: event,
            eventDate: eventDate,
            tickets: tickets,
            user: UserHelper.currentUser,
          };
          return result;
        } else {
          return { error: 'Tickets not available' } as ITicketSwap;
        }
      })
      .catch((err) => {
        return { error: `Swap initialise failed: ${err}` } as ITicketSwap;
      });
  }
}
