import React from 'react';

import Block from './Block';
import Info from './Info';

export interface IProps {
  type?: InfoType;
}

export enum InfoType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Info = 'default',
}

const BlockInfo: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  return (
    <Block className={'info' + (props.type ? ' ' + props.type : '')}>
      <Info type={props.type}>{props.children}</Info>
    </Block>
  );
};

export default BlockInfo;
