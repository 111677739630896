import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 7.4765625 5.9785156 A 1.50015 1.50015 0 0 0 6 7.5 L 6 40.5 A 1.50015 1.50015 0 0 0 7.5 42 L 40.5 42 A 1.50015 1.50015 0 1 0 40.5 39 L 9 39 L 9 7.5 A 1.50015 1.50015 0 0 0 7.4765625 5.9785156 z M 40.576172 8.9824219 A 1.50015 1.50015 0 0 0 39.228516 9.7050781 L 32.886719 19.851562 L 29.699219 15.599609 A 1.50015 1.50015 0 0 0 27.212891 15.728516 L 22.361328 23.814453 L 19.199219 19.599609 A 1.50015 1.50015 0 0 0 16.738281 19.689453 L 12.238281 26.689453 A 1.50015 1.50015 0 1 0 14.761719 28.310547 L 18.095703 23.125 L 21.300781 27.400391 A 1.50015 1.50015 0 0 0 23.787109 27.271484 L 28.638672 19.185547 L 31.800781 23.400391 A 1.50015 1.50015 0 0 0 34.271484 23.294922 L 41.771484 11.294922 A 1.50015 1.50015 0 0 0 40.576172 8.9824219 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
