import { FunctionComponent, useState } from 'react';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import SpacerTable from '../../components/SpacerTable';
import Button from '../../components/Button';
import MemberService from '../../services/MemberService';
import AdminApi from '../../api/AdminApi';
import Loader from '../../components/Loader';
import { IOrganisation } from '../../interfaces/IOrganisation';
import MembershipDetailsSection from './MembershipDetailsSection';
import Radio from '../../components/Radio';
import { IEvent } from '../../interfaces/IEvent';
import BlockInfo from '../../components/BlockInfo';
import IMember from '../../interfaces/IMember';

enum Section {
  Membership = 'Membership',
  MembershipDetails = 'MembershipDetails',
  MembershipType = 'MembershipType',
}

interface IProps {
  organisation: IOrganisation;
  overview: any;
  setError: (error: string) => void;
  event?: IEvent;
}

const MembershipSection: FunctionComponent<IProps> = (props) => {
  const organisation = props.organisation;
  const overview = props.overview;
  const member = overview.Member;
  const memberTypes = overview.MemberTypes;

  const setError = props.setError;
  const [memberTypeId, setMemberTypeId] = useState(overview.Member ? overview.Member.MemberTypeId : null);
  const [section, setSection] = useState<Section>(Section.Membership);
  const [busyMessage, setBusyMessage] = useState(null);

  const addMembership = () => {
    setBusyMessage('Adding membership...');

    MemberService.UpdateOrganisationMember({
      id: null,
      friendlyName: overview.User.Name,
      email: overview.Email,
      organisationId: organisation.Id,
      removeIfExists: false,
    })
      .then((result: IMember) => {
        overview.Member = result;
        setMemberTypeId(result.MemberTypeId);
        overview.IsMember = true;
      })
      .catch(() => {
        setError('Error occurred when adding membership.');
        setBusyMessage(null);
      })
      .finally(() => setBusyMessage(null));
  };

  const removeMembership = () => {
    setBusyMessage('Removing membership...');

    AdminApi.request('DELETE', '/api/Member', {
      id: overview.Member.Id,
      organisationId: organisation.Id,
    })
      .then((result) => {
        overview.IsMember = false;
        overview.Member = null;
      })
      .catch(() => {
        setError('Error occurred when removing membership.');
      })
      .finally(() => setBusyMessage(null));
  };

  const memberTypeClicked = (memberType) => {
    setBusyMessage('Updating membership type...');

    MemberService.UpdateOrganisationMemberType({
      id: overview.Member.Id,
      email: overview.Email,
      organisationId: organisation.Id,
      memberTypeId: memberType.Id,
    })
      .then(() => {
        overview.MemberType = memberType;
        setMemberTypeId(memberType.Id);
      })
      .catch(() => {
        setError('Error when updating member type.');
      })
      .finally(() => setBusyMessage(null));
  };

  if (busyMessage != null) return <Loader inline={true}>{busyMessage}</Loader>;

  if (section == Section.MembershipDetails)
    return (
      <>
        <MembershipDetailsSection setError={setError} organisation={organisation} overview={overview} />
        <div className="spacer" />
        <SpacerTable>
          <Button onExecute={() => setSection(Section.Membership)} text={'Back to membership'} />
        </SpacerTable>
      </>
    );

  return (
    <>
      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>Email</BlockHeader>
            {overview.Email}
          </Block>

          {!member && <BlockInfo>This email address is not a member of {organisation.Name}.</BlockInfo>}

          {member && (
            <>
              <Block className="route" onClick={() => setSection(Section.MembershipDetails)}>
                <BlockHeader>Member name</BlockHeader>
                {member.FriendlyName == null || member.FriendlyName.length == 0 ? 'No member name set' : member.FriendlyName}
              </Block>
              <Block>
                <BlockHeader>Member type</BlockHeader>
                <Radio
                  selectedId={memberTypeId}
                  onValueChanged={(option) => {
                    memberTypeClicked(memberTypes.filter((mt) => mt.Id == option.Id)[0]);
                  }}
                  options={memberTypes.map((memberType) => {
                    return { Text: memberType.Name, Info: !props.event ? '' : `${memberType.Quota == 0 ? 'No' : memberType.Quota} quota`, Id: memberType.Id };
                  })}
                />
              </Block>
            </>
          )}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        {!member && <Button className="confirm" onExecute={addMembership} text={'Grant membership'} />}
        {member && <Button className="bad" onExecute={removeMembership} text={'Remove membership'} />}
      </SpacerTable>
    </>
  );
};

export default MembershipSection;
