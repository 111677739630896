import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26pt" height="26pt" viewBox="0 0 26 26" version="1.1">
      <g id="surface10935110">
        <path d="M 7 3 C 4.800781 3 3 4.800781 3 7 L 3 19 C 3 21.199219 4.800781 23 7 23 L 19 23 C 21.199219 23 23 21.199219 23 19 L 23 7 C 23 4.800781 21.199219 3 19 3 Z M 9 7 L 11 7 L 11 9 L 15 9 L 15 7 L 17 7 L 17 9 L 19 9 L 19 11 L 17 11 L 17 15 L 19 15 L 19 17 L 17 17 L 17 19 L 15 19 L 15 17 L 11 17 L 11 19 L 9 19 L 9 17 L 7 17 L 7 15 L 9 15 L 9 11 L 7 11 L 7 9 L 9 9 Z M 11 11 L 11 15 L 15 15 L 15 11 Z M 11 11 " />
      </g>
    </svg>
  );
}) as FunctionComponent<IProps>;
