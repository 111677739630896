import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path d="M 15.5 2 C 13.032499 2 11 4.0324991 11 6.5 L 11 41.5 C 11 43.967501 13.032499 46 15.5 46 L 32.5 46 C 34.967501 46 37 43.967501 37 41.5 L 37 6.5 C 37 4.0324991 34.967501 2 32.5 2 L 15.5 2 z M 15.5 5 L 32.5 5 C 33.346499 5 34 5.6535009 34 6.5 L 34 41.5 C 34 42.346499 33.346499 43 32.5 43 L 15.5 43 C 14.653501 43 14 42.346499 14 41.5 L 14 6.5 C 14 5.6535009 14.653501 5 15.5 5 z M 21 7 C 19.895 7 19 7.895 19 9 C 19 10.105 19.895 11 21 11 L 27 11 C 28.105 11 29 10.105 29 9 C 29 7.895 28.105 7 27 7 L 21 7 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
