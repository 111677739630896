export default class StringHelper {
  public static AddSWhenMany = (count, string) => {
    if (count != 1) {
      string = string + 's';
    }
    return string;
  };

  public static hasSpecialCharacters(value: string) {
    const hasSpecials = /^[a-zA-Z0-9- ]*$/.test(value);
    const hasSpaces = value.indexOf(' ') >= 0;
    return hasSpaces || !hasSpecials;
  }

  public static replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }
}
