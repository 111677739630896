export default class NumberHelper {
  public static roundDecimal = (decimal: number): number => {
    return +decimal.toFixed(3);
  };

  public static isNumeric(input) {
    return input - 0 == input && ('' + input).replace(/^\s+|\s+$/g, '').length > 0;
  }

  public static padWithZeros(num, size) {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }
}
