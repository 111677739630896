import moment from 'moment';
import 'moment-timezone';
import React, { useState } from 'react';
import BlockTimePicker from '../../../components/BlockTimePicker';
import { IEventDate } from '../../../interfaces/IEventDate';
import SVGAsterisk from '../../../svg/SVGAsterisk';
import SVGCancel from '../../../svg/SVGCancel';
import SVGSaveSmall from '../../../svg/SVGSaveSmall';
import SVGSuspended from '../../../svg/SVGSuspended';
import SVGTrash from '../../../svg/SVGTrash';

export interface IProps {
  eventDate: IEventDate;
  isEditing: boolean;
  onTimeClick: (date) => void;
  onSaveClick: (date) => void;
  onDeleteClick: (date) => void;
  onCancelClick: () => void;
  onSuspendClick: (date) => void;
}

const DateAndTimeWeekItem: React.FC<IProps> = (props) => {
  const [edited, setEdited] = useState(false);
  const [editingEventDateValue, setEditingEventDateValue] = useState(null);

  const editable = !props.eventDate.HasOrders; // || UserHelper.currentUser.Id == 1;

  const onSaveClick = () => {
    if (editingEventDateValue) {
      props.onSaveClick({ ...props.eventDate, DateAsString: editingEventDateValue });
    } else {
      alert('Invalid time.');
    }
  };

  return (
    <>
      {props.isEditing && editable ? (
        <>
          <BlockTimePicker
            onEnterPressed={edited && onSaveClick}
            disabled={!editable}
            groupName={props.eventDate.Guid}
            initialValue={moment(props.eventDate.DateAsString)}
            onChange={(_time) => {
              if (_time.isTime && _time.moment) {
                setEditingEventDateValue(`${moment(props.eventDate.DateAsString).format('YYYY-MM-DD')} ${_time.moment.format('HH:mm')}`);

                if (moment(props.eventDate.DateAsString).format('HH:mm') != _time.moment.format('HH:mm')) {
                  setEdited(true);
                }
              } else {
                setEditingEventDateValue(null);
              }
            }}
          />
        </>
      ) : (
        <button
          className={`event-date-time${props.eventDate.HasOrders ? ' has-orders' : ''}${props.eventDate.Suspend ? ' suspended' : ''}`}
          onClick={() => {
            setEdited(false);
            props.onTimeClick(props.eventDate);
            setEditingEventDateValue(null);

            if (!editable) return;

            window.setTimeout(() => {
              const hourInput: any = document.getElementById(`hourInput${props.eventDate.Guid}`);

              if (hourInput) {
                hourInput.select();
              }
            }, 50);
          }}
        >
          {moment(props.eventDate.DateAsString).format('HH:mm')}

          {props.eventDate.ChangeMade && (
            <div className="button-status-icon-right">
              <SVGAsterisk />
            </div>
          )}
          {props.eventDate.Suspend && (
            <div className="button-status-icon-left">
              <SVGSuspended />
            </div>
          )}
        </button>
      )}

      {props.isEditing && (
        <div className="admin-buttons edit-buttons">
          <button onClick={props.onCancelClick} className="admin-button icon">
            <SVGCancel />
          </button>
          {!edited && (
            <>
              <button onClick={props.onSuspendClick} className="admin-button icon">
                <SVGSuspended />
              </button>
              {!props.eventDate.HasOrders && (
                <button onClick={() => props.onDeleteClick(props.eventDate)} className="admin-button icon">
                  <SVGTrash />
                </button>
              )}
            </>
          )}
          {editable && edited && (
            <button onClick={onSaveClick} className="admin-button icon">
              <SVGSaveSmall />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default DateAndTimeWeekItem;
