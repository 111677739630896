import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AdminApi from '../../../api/AdminApi';
import tourMenuState from '../../../atoms/tourMenuState';
import Loader from '../../../components/Loader';
import CacheHelper from '../../../helpers/CacheHelper';
import LocationHelper from '../../../helpers/LocationHelper';
import StringHelper from '../../../helpers/StringHelper';
import TourHelper from '../../../helpers/TourHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import { ITour } from '../../../interfaces/ITour';
import SVGAdmin from '../../../svg/SVGAdmin';
import SVGSave from '../../../svg/SVGSave';
import SVGWarning from '../../../svg/SVGWarning';
import '../Editor.scss';
import { IImageUploadRequest } from '../ImageUploader';
import ThemeSection from '../ThemeSection';
import EventsSection from './EventsSection';
import MarketingSection from './MarketingSection';
import TourSection from './TourSection';
import ImageSection from './ImageSection';
import EventHelper from '../../../helpers/EventHelper';
import Constants from '../../../helpers/Constants';
import SVGOutlineDetails from '../../../svg/outline/SVGOutlineDetails';
import SVGOutlineImage from '../../../svg/outline/SVGOutlineImage';
import SVGOutlineEvent from '../../../svg/outline/SVGOutlineEvent';
import SVGOutlineTheme from '../../../svg/outline/SVGOutlineTheme';
import SVGOutlineMarketing from '../../../svg/outline/SVGOutlineMarketing';
import SideMenu from '../../../components/SideMenu';

export interface ITourWarning {
  title: string;
  text: string;
}

interface IProps {}

export enum Section {
  Tour = 'Tour',
  Image = 'Image',
  Events = 'Events',
  Theme = 'Theme',
  Marketing = 'Marketing',
}

interface ITourSection {
  heading: string;
  subHeading?: (o: ITour) => React.ReactNode;
  icon: string;
  section: Section;
  visible?: (o: ITour) => boolean;
  svg?: any;
}

const tourSections: ITourSection[] = [
  {
    heading: 'Tour',
    subHeading: (o: ITour) => {
      return o && o.Name && <>{o.Name.length > 0 ? <span className="sub">{o.Name}</span> : <span className="sub">Information</span>}</>;
    },
    icon: 'Tour',
    section: Section.Tour,
    svg: <SVGOutlineDetails />,
  },
  {
    heading: 'Image',
    icon: 'Image',
    section: Section.Image,
    svg: <SVGOutlineImage />,
  },
  {
    heading: 'Events',
    icon: 'Event',
    section: Section.Events,
    svg: <SVGOutlineEvent />,
  },
  {
    heading: 'Theme',
    icon: 'Theme',
    section: Section.Theme,
    svg: <SVGOutlineTheme />,
  },
  {
    heading: 'Marketing',
    icon: 'Marketing',
    section: Section.Marketing,
    svg: <SVGOutlineMarketing />,
  },
];

const TourEditorPage: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [section, setSection] = useState<Section>(Section.Tour);
  const [busyMessage, setBusyMessage] = useState<string>('Loading tour...');
  const [imageRequests, setImageRequests] = useState<{ [key: string]: IImageUploadRequest }>({});
  const [tourHistory, setTourHistory] = useState<ITour[]>([]);
  const [actualTourTag, setActualTourTag] = useState<string>(null);
  const [events, setEvents] = useState<Array<IEvent>>([]);
  const [organisation, setOrganisation] = useState<IOrganisation>(null);
  const [, setTourMenu] = useRecoilState(tourMenuState);

  const [tour, setTourState] = useState<ITour>(null);
  const setTour = (tour) => {
    setTourHistory([...(tourHistory.length > 5 ? tourHistory.filter((e, i) => i != 0) : tourHistory), JSON.parse(JSON.stringify(tour))]);
    setTourState(tour);
  };

  const loadTour = (tourTag): Promise<any> => {
    setTourHistory([]);
    return AdminApi.request('GET', `/api/TourEdit?tourTag=${tourTag}`)
      .then((_org: ITour) => {
        setTourState(_org);
        setBusyMessage(null);
        setActualTourTag(_org.TourTag);

        return _org;
      })
      .catch((message) => {
        alert(message);
      });
  };

  useEffect(() => {
    const urlDetails = LocationHelper.getLocation().pathname;
    const organisationTag = urlDetails.split('/')[2];
    const tourTag = urlDetails.split('/')[4];

    const queryParams = new URLSearchParams(window.location.search);
    const sectionParam: string = queryParams.get('section');

    if (sectionParam && Object.values(Section).includes(sectionParam as any)) {
      setSection(sectionParam as any);
    }

    AdminApi.request('GET', '/api/UserEvents')
      .then((results) => {
        setEvents(results);
      })
      .catch((message) => {
        alert(message);
      });

    CacheHelper.organisationByTag(organisationTag).then((organisation) => {
      setOrganisation(organisation);

      if (urlDetails.split('/')[3] == 'Tour' && urlDetails.split('/')[4] == 'Create') {
        const newTour = TourHelper.getDefault();
        newTour.Organisation = organisation;
        newTour.OrganisationId = organisation.Id;

        setTour(newTour);
        setBusyMessage(null);
        return;
      }

      loadTour(tourTag);
    });
  }, []);

  const getWarnings = (): ITourWarning[] => {
    if (!tour) return [];

    const warnings: ITourWarning[] = [];

    if (tour.Description && EventHelper.getRichTextCharLength(tour.Description) > Constants.MaxEventDescriptionLength) {
      warnings.push({ title: 'Description too long', text: `You must enter a tour description of ${Constants.MaxEventDescriptionLength} characters or less.` });
    }

    if (!tour.Name || tour.Name.length <= 2) {
      warnings.push({ title: 'Invalid name', text: 'You must enter an tour name longer than 2 letters.' });
    }

    if (tour.TourTag == null) {
      tour.TourTag = '';
    }

    if (!tour.TourTag || tour.TourTag.length <= 2) {
      warnings.push({ title: 'Tour tag length', text: 'You must enter a tour tag longer than 2 letters.' });
    }

    if (StringHelper.hasSpecialCharacters(tour.TourTag)) {
      warnings.push({ title: 'Invalid tour tag', text: 'Tour tags cannot contain special letters, make sure its just plain text.' });
    }

    return warnings;
  };

  const [optionsOpen, setOptionsOpen] = useState(false);

  const warnings: ITourWarning[] = getWarnings();

  const globalOptions = (
    <>
      <div className={`option dropdown ${warnings.length > 0 ? ' warning' : ''} list${optionsOpen ? ' open' : ''}`}>
        {warnings.length === 0 ? (
          <label>Save</label>
        ) : (
          <label>
            {warnings.length} {StringHelper.AddSWhenMany(warnings.length, 'Task')}
          </label>
        )}

        {optionsOpen && <div className="click-off" onClick={() => setOptionsOpen(false)}></div>}

        <span className="icon">{warnings.length === 0 ? <SVGSave /> : <SVGWarning />}</span>
        <button className="action" disabled={warnings.length > 0 || tourHistory.length === 0} onClick={() => (warnings.length === 0 ? save() : setOptionsOpen(true))}></button>
        <button className="notch" onClick={() => setOptionsOpen(!optionsOpen)}></button>

        <div className="dropdown-options">
          {warnings.map((warning, index) => (
            <div className="info-row warning" key={index}>
              <span className="dropdown-option-icon">
                <SVGWarning />
              </span>
              <span className="description">{warning.title}</span>
              {warning.text && <p>{warning.text}</p>}
            </div>
          ))}

          {tour && tour.Id && tour.Id > 0 && actualTourTag && (
            <button
              onClick={() => {
                setTourMenu({ ...tour, Organisation: organisation, TourTag: actualTourTag });
                setOptionsOpen(false);
              }}
            >
              <span className="dropdown-option-icon">
                <SVGAdmin />
              </span>
              <span className="description">Options</span>
              <p>Open the administrator options dialog.</p>
            </button>
          )}
        </div>
      </div>
    </>
  );

  const onImageRequested = (request: IImageUploadRequest) => {
    imageRequests[request.group] = request;
    setImageRequests({ ...imageRequests });
    setTourHistory([...(tourHistory.length > 5 ? tourHistory.filter((e, i) => i != 0) : tourHistory), JSON.parse(JSON.stringify(tour))]);
  };

  const sections: { [key: string]: any } = {};
  sections[Section.Image] = <ImageSection globalOptions={globalOptions} onImageRequested={onImageRequested} imageRequests={imageRequests} tour={tour} />;
  sections[Section.Tour] = (
    <TourSection setBusyMessage={setBusyMessage} globalOptions={globalOptions} onImageRequested={onImageRequested} imageRequests={imageRequests} tour={tour} onTourUpdated={(e: ITour) => setTour(e)} />
  );
  sections[Section.Marketing] = (
    <MarketingSection
      setBusyMessage={setBusyMessage}
      globalOptions={globalOptions}
      onImageRequested={onImageRequested}
      imageRequests={imageRequests}
      tour={tour}
      onTourUpdated={(e: ITour) => setTour(e)}
    />
  );
  sections[Section.Theme] = (
    <ThemeSection
      onImageRequested={onImageRequested}
      imageRequests={imageRequests}
      setBusyMessage={setBusyMessage}
      globalOptions={globalOptions}
      tour={tour}
      onTourUpdated={(e: ITour) => setTour(e)}
    />
  );

  sections[Section.Events] = <EventsSection events={events} globalOptions={globalOptions} tour={tour} onTourUpdated={(e: ITour) => setTour(e)} />;

  const save = () => {
    setBusyMessage('Saving...');

    return TourHelper.save(tour, setBusyMessage, imageRequests)
      .then(() => {
        setTourHistory([]);
        setImageRequests({});
        setBusyMessage('All done!');
        navigate('/Organisation/' + organisation.OrganisationTag + '/Tour/' + tour.TourTag + '/Edit');

        window.setTimeout(() => {
          setBusyMessage('Reloading...');
          loadTour(tour.TourTag);
        }, 1000);
      })
      .catch((message) => {
        alert(message);
        setBusyMessage(null);
      });
  };

  return (
    <>
      <Helmet>
        <title>{busyMessage ? 'Tour Editor' : tour && tour.Id > 0 ? `Edit #${tour.TourTag}` : `Create Tour`}</title>
        <meta name="description" content={`Edit your tour details.`} />
      </Helmet>

      {busyMessage && <Loader inline>{busyMessage}</Loader>}

      {!busyMessage && tour && (
        <div className="admin-dashboard-editor">
          <div className="row">
            <div className="col-sm-3 col-md-2 donotprint">
              <SideMenu navigator>
                {tourSections.map((s: ITourSection) => (
                  <button
                    key={s.section}
                    style={{ display: s.visible ? (!s.visible(tour) ? 'none' : null) : null }}
                    className={`${section == s.section ? 'selected' : ''}${s.svg ? ' has-image' : ''}`}
                    onClick={() => {
                      setSection(s.section);
                      LocationHelper.updateParam('section', s.section);
                    }}
                  >
                    {s.svg}
                    {s.heading}
                  </button>
                ))}
              </SideMenu>
            </div>
            <div className="col-sm-9 col-md-10  donotprint">{sections[section]}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default TourEditorPage;
