import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32px" height="32px">
      <path d="M 11 2 C 9.3550302 2 8 3.3550302 8 5 L 8 27 C 8 28.64497 9.3550302 30 11 30 L 21 30 C 22.64497 30 24 28.64497 24 27 L 24 5 C 24 3.3550302 22.64497 2 21 2 L 11 2 z M 11 4 L 12 4 C 12 5.105 12.895 6 14 6 L 18 6 C 19.105 6 20 5.105 20 4 L 21 4 C 21.56503 4 22 4.4349698 22 5 L 22 27 C 22 27.56503 21.56503 28 21 28 L 11 28 C 10.43497 28 10 27.56503 10 27 L 10 5 C 10 4.4349698 10.43497 4 11 4 z M 16 25 C 15.449 25 15 25.449 15 26 C 15 26.551 15.449 27 16 27 C 16.551 27 17 26.551 17 26 C 17 25.449 16.551 25 16 25 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
