export enum FeedType {
  AdminOrder = 'Admin Order',
  RequestOrder = 'Request Order',
  FailedOnlinePurchase = 'Incomplete Online Purchase',
  OnlinePurchase = 'Purchase Order',
  RefundedPurchase = 'Refunded Purchase',
  OnlineBalancePayment = 'Online Balance Payment',
  All = 'All',
}

export interface IFeedRow {
  RefundId: string;
  Exception: string;
  PurchaseId?: number;
  AttendeeName: string;
  Id: number;
  Amount: number;
  Refunded: boolean;
  Email: string;
  Name: string;
  Type: FeedType;
  Date: string;
  EventId: number;
  EventTag: string;
  EventDate: string;
  OrderId?: number;
}
