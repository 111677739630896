import AdminApi from '../api/AdminApi';
import { IOption } from '../interfaces/IOption';

export default class AttendeeHelper {
  public static lookupAttendees(lookupText: string): Promise<IOption[]> {
    return AdminApi.request('POST', '/api/FindUser', {
      text: lookupText,
    }).then((r) => {
      if (!r || !r.Users) {
        return [];
      }

      return r.Users.map((a) => {
        var tags = [];

        return {
          Id: a.Id,
          Header: `${a.FirstName} ${a.SecondName}`.trim(),
          Text: a.Email,
          Tags: tags,
        };
      });
    });
  }
}
