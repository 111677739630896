import React from 'react';
import { QuestionTypes } from '../enums/QuestionTypes';
import { IEvent } from '../interfaces/IEvent';
import { IQuestion, IQuestionAnswer } from '../interfaces/IQuestion';
import { ITicket } from '../interfaces/ITicket';
import BlockHeader from './BlockHeader';
import Select from './Select';
import BlockTextBox from './BlockTextBox';

export interface IProps {
  answers: IQuestionAnswer[];
  event: IEvent;
  onChange?: (answers: IQuestionAnswer[]) => any;
  readOnly: boolean;
  ticket?: ITicket;
  quantity?: number;
}

const OrderQuestions: React.FC<IProps> = (props) => {
  const { onChange, event, ticket, answers, readOnly } = props;

  const quantity = props.quantity == null ? 1 : props.quantity;

  let questions: IQuestion[];
  if (ticket) {
    questions = event.Questions ? event.Questions.filter((q) => q.Criteria != null && q.Criteria.length > 0 && q.Criteria.filter((c) => c.Guid === ticket.TicketCategory.Guid).length > 0) : [];
  } else {
    questions = event.Questions ? event.Questions.filter((q) => q.Criteria == null || q.Criteria.length === 0) : [];
  }

  if (questions.length === 0) return <></>;

  return (
    <>
      {questions.map((question, questionIndex) => {
        const questionComponents = [];

        for (var answerIndex = 1; answerIndex <= quantity; answerIndex++) {
          const existingAnswers = answers.filter((a) => a.QuestionId === question.Id && a.Index === answerIndex);
          const existingAnswer = existingAnswers.length > 0 ? existingAnswers[0] : null;

          questionComponents.push(
            <div style={{ marginTop: ticket || questionIndex > 0 ? '14px' : null }} className={readOnly ? 'question' : ''} key={`Question_${question.Id}_${answerIndex}`}>
              <BlockHeader>
                {quantity > 1 ? `Ticket #${answerIndex}: ` : ''}
                {question.Name}
                {question.Required ? ' (Required)' : ''}
              </BlockHeader>

              {question.QuestionType === QuestionTypes.Dropdown && (
                <Select
                  readOnly={readOnly}
                  nothingSelectedText="Choose an answer"
                  onSelectedValueChange={(value, meta) => {
                    if (!onChange) return;
                    onChange([
                      ...answers.filter((q) => (q.QuestionId === question.Id && q.Index != meta) || q.QuestionId !== question.Id),
                      { Index: meta, QuestionName: question.Name, Guid: value.Id as string, Text: value.Text, QuestionId: question.Id, QuestionType: question.QuestionType },
                    ]);
                  }}
                  meta={answerIndex}
                  selectedId={existingAnswer ? existingAnswer.Guid : null}
                  options={question.Answers.map((answer) => {
                    return { Id: answer.Guid, Text: answer.Text };
                  })}
                />
              )}

              {question.QuestionType === QuestionTypes.Text &&
                (readOnly ? (
                  existingAnswer && existingAnswer.Text && existingAnswer.Text.length > 0 ? (
                    existingAnswer.Text
                  ) : (
                    'No answer given.'
                  )
                ) : (
                  <BlockTextBox
                    rows={1}
                    meta={answerIndex}
                    onChange={(value, meta) => {
                      if (!onChange) return;
                      onChange([
                        ...answers.filter((q) => (q.QuestionId === question.Id && q.Index != meta) || q.QuestionId !== question.Id),
                        { Index: meta, QuestionName: question.Name, Text: value, QuestionId: question.Id, QuestionType: question.QuestionType },
                      ]);
                    }}
                    value={existingAnswer ? existingAnswer.Text : ''}
                  />
                ))}
            </div>,
          );
        }
        return questionComponents;
      })}
    </>
  );
};

export default OrderQuestions;
