import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

interface ISection {
  title: string;
  content?: ReactNode;
  sections?: ISection[];
}

const TermsPage: React.FC = () => {
  const UPDATED_DATE = 'May 15, 2024';

  const sections: ISection[] = [
    {
      title: 'Modification to TOS',
      content: (
        <>
          <p>
            SEATY reserves the right, at its sole discretion, to modify or replace any of the terms or conditions of this TOS at any time. It is your responsibility to check this TOS every so often
            for changes. Your continued use of the Services following the publishing of any changes to this TOS constitutes complete acceptance of any changes. If any change to this TOS is not
            acceptable to you, your sole course of action is to cease accessing, browsing and otherwise using all Services.
          </p>
        </>
      ),
    },
    {
      title: 'Description of Seaty',
      content: (
        <>
          <p>
            Seaty provides the means for users, otherwise referred to as "you", who are usually either Event Organisers, otherwise referred to as "Organisers", or event attendees, otherwise referred
            to as "Buyers" or "Attendees". Seaty offers services to Organisers to track and, by agreement, collect payments with respect to the sale of tickets and services for events registered on
            the Site, including Service Fees (as defined below), otherwise referred to as "Event Ticket Fees", from users who want to attend such events, the Buyers. Organisers may visit the Site,
            fill in a form describing the details of their event, including ticket categories, pricing, venue, etc. Organisers can book tickets for anyone directly for an Event, or optionally accept
            and review requests from Buyers for tickets to their events and accept or deny them. There are no associated Service Fees for tickets booked directly by Organisers for Buyers and any
            payments due are to be settled and collected outside of Seaty. By agreement, Organisers can opt to have Seaty collect Event Ticket Fees online directly from Buyers, which includes a
            Service Fee (as defined below). All payments are all transacted through a secure provider and held, by Seaty, until after an event has ended. This TOS applies to you and your use of the
            Services as an Organiser or an Attendee. Seaty offers services to Attendees to browse and purchase tickets to Organisers events. Event Ticket Fees which may be made up of Seaty Service
            Fees and Event Organiser Handling Fees may be included or added on to the price of any ticket or order purchased using our service.
          </p>
        </>
      ),
    },
    {
      title: 'Your Use of the Services (For Event Organisers)',
      sections: [
        {
          title: 'Access and Use of Services',
          content: (
            <>
              <p>
                Seaty provides a comprehensive suite of tools designed for event organisers to effectively create, promote, and manage events. As an event organiser, you are granted a
                non-transferable, non-exclusive right to access and utilize our Services, including but not limited to:
              </p>
              <ul>
                <li>Creating event pages to detail and promote your events to potential attendees.</li>
                <li>Managing event details, updates, and attendee communications through your Seaty dashboard.</li>
                <li>Tracking sales and managing the collection of Event Ticket Fees directly through the platform.</li>
              </ul>
              <p>This access is provided under the condition that all event pages and associated activities:</p>
              <ol>
                <li>Adhere to the terms outlined in this Terms of Service (TOS) agreement.</li>
                <li>Comply with all applicable local, national, and international laws and regulations.</li>
              </ol>
            </>
          ),
        },
        {
          title: 'Restrictions on Use',
          content: (
            <>
              <p>In using the Seaty platform, you agree to refrain from actions that would compromise the integrity, functionality, or security of our Services, including but not limited to:</p>
              <ul>
                <li>Modifying, reproducing, or creating derivative works from any part of the Services or Site Content without explicit permission.</li>
                <li>Engaging in any activity that interferes with or disrupts the operation of the Services.</li>
                <li>Using the Services for unauthorized commercial purposes beyond collecting Event Ticket Fees as outlined in this TOS.</li>
              </ul>
            </>
          ),
        },
        {
          title: 'Software Use',
          content: (
            <>
              <p>If applicable, any software provided by Seaty for event management or ticketing purposes is to be used strictly according to this TOS. This includes:</p>
              <ul>
                <li>A non-exclusive, revocable licence to use Seaty software for internal purposes related to your events.</li>
                <li>Compliance with all relevant export controls and laws pertaining to the use of the software.</li>
              </ul>
            </>
          ),
        },
        {
          title: 'Event Ticket Sales',
          content: (
            <>
              <p>
                As an organiser, when you list your event and sell tickets through Seaty, you enter into a direct transactional relationship with attendees purchasing tickets. Seaty serves as the
                platform to facilitate these transactions but is not a party to the sale. You are responsible for:
              </p>
              <ul>
                <li>The accuracy and legality of the event listings and ticket sales.</li>
                <li>Handling any attendee complaints or issues related to the event or tickets sold.</li>
              </ul>
            </>
          ),
        },
        {
          title: 'Feedback and Reviews',
          content: (
            <>
              <p>Event organisers are encouraged to maintain high standards of service to ensure positive reviews from attendees. In the event of negative feedback:</p>
              <ul>
                <li>Organisers are responsible for addressing and resolving any issues directly related to their events.</li>
                <li>
                  Seaty reserves the right to take action against unfounded negative reviews to protect its reputation but does not hold liability for reviews related to individual event experiences.
                </li>
              </ul>
            </>
          ),
        },
        {
          title: 'Payment Handling and Agent Role',
          content: (
            <>
              <p>
                By using our platform, you authorise Seaty to act as your commercial agent for the purpose of processing ticket sales and managing event funds. Seaty will receive payments from
                attendees on your behalf, hold these funds until after your event concludes, and then disburse the funds to you, less any applicable service fees. This does not apply if you use Stripe
                Connect to manage payments directly.
              </p>
            </>
          ),
        },
        {
          title: 'Funds Management and Interest',
          content: (
            <>
              <p>
                Seaty holds all event funds until after the event to ensure attendee refunds if necessary. Seaty may earn interest on these held funds. By agreeing to these terms, you consent to Seaty
                retaining any interest earned on the funds while they are held. This does not apply if you use Stripe Connect to manage payments directly.
              </p>
            </>
          ),
        },
        {
          title: 'Your Agreement',
          content: (
            <>
              <p>
                By utilizing the Services provided by Seaty for event organisation and ticket sales, you acknowledge and agree to these terms, which are integral to the broader Terms of Service
                governing the use of the Seaty platform.
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: 'Your Use of the Services (For Event Attendees)',
      sections: [
        {
          title: 'Access and Use of Services',
          content: (
            <>
              <p>As a guest or event attendee using Seaty, you're granted access to a variety of services designed to enhance your event-going experience. This includes:</p>
              <ul>
                <li>Exploring a range of events.</li>
                <li>Viewing and managing your ticket orders.</li>
                <li>Purchasing tickets through the Seaty platform.</li>
                <li>Receiving important information directly from event organisers.</li>
              </ul>
              <p>These services are provided to ensure a straightforward and enjoyable process for discovering and attending events.</p>
            </>
          ),
        },
        {
          title: 'Restrictions on Use',
          content: (
            <>
              <p>In utilising the Seaty platform, you agree to use our services responsibly and within the bounds of our terms, including not:</p>
              <ul>
                <li>Modifying, reproducing, or creating derivative works from any part of the Services or Site Content.</li>
                <li>Engaging in activities that disrupt the Services or other users' experiences.</li>
                <li>Using the Services for any unauthorised commercial purposes.</li>
              </ul>
            </>
          ),
        },
        {
          title: 'Purchasing Tickets',
          content: (
            <>
              <p>
                When you purchase tickets through Seaty, you enter into an agreement directly with the event organiser, not Seaty. We facilitate the transaction, but the event organiser is responsible
                for the event and any issues related to your purchase. As such:
              </p>
              <ul>
                <li>Any inquiries or complaints regarding the event or tickets should be directed to the event organiser.</li>
                <li>Seaty is not liable for event cancellations or any dissatisfaction with the event you attend.</li>
              </ul>
            </>
          ),
        },
        {
          title: 'Payment and Refund Policy',
          content: (
            <>
              <p>
                When purchasing tickets through Seaty, you acknowledge that Seaty acts as an agent for the event organisers, handling payments on their behalf. In the event of a cancellation or
                significant issue with the event, refunds will be processed according to our policies and the event organiser's terms. Event organisers may choose to handle payments directly through
                Stripe Connect, but are still bound by our Terms of Service as outlined on this page.
              </p>
            </>
          ),
        },
        {
          title: 'Feedback and Reviews',
          content: (
            <>
              <p>
                We value your feedback and encourage you to share your experiences with the events you attend through the Seaty platform. However, it's important to understand the following terms
                regarding reviews:
              </p>
              <ul>
                <li>
                  You agree not to post unwarranted negative reviews or feedback about Seaty or our services on our website or any third-party review platforms, especially those not directly related
                  to the services provided by Seaty itself, such as the quality of an event you attended.
                </li>
                <li>
                  Constructive feedback regarding your event experience should be directed towards the event organiser responsible for the event. Seaty serves as a platform to facilitate ticket
                  transactions and is not the provider of the event itself.
                </li>
                <li>
                  At Seaty, we are committed to providing a high-quality service and appreciate your feedback on our website and services. We encourage you to share any concerns, issues, or
                  suggestions for improvement directly with us before posting a public review. This can be done by sending an email to our support team at{' '}
                  <a href="mailto:Support@Seaty.co.uk">Support@Seaty.co.uk</a>. We value the opportunity to resolve any issues or complaints you may have and are dedicated to ensuring your
                  satisfaction. By engaging with us directly, you help us to continually improve our service and address concerns in a constructive and timely manner.
                </li>
                <li>
                  In the event that a negative review or feedback targeting Seaty is identified and deemed by us to be unfair, unfounded, or damaging to our reputation, we reserve the explicit right
                  to take appropriate action. This action may include, but is not limited to, challenging the review, seeking removal of the feedback, and/or taking legal steps to protect our
                  reputation and goodwill.
                </li>
                <li>
                  By choosing to use the Seaty platform for purchasing tickets and engaging with event content, you expressly agree to these terms regarding feedback and reviews. This agreement
                  includes your acknowledgment of our right to address and rectify unfair public depictions of our platform and services.
                </li>
              </ul>
            </>
          ),
        },
        {
          title: 'Your Agreement',
          content: (
            <>
              <p>By using the Seaty platform to explore events and purchase tickets, you agree to these terms, contributing to a respectful and enjoyable community for all attendees.</p>
            </>
          ),
        },
      ],
    },
    {
      title: 'Nature of Service and Transaction Relationships',
      sections: [
        {
          title: 'Agreement Between Attendee and Event Organiser',
          content: (
            <>
              <p>
                When an Attendee purchases a ticket for an event listed on Seaty, they are entering into a direct contractual relationship with the Event Organiser. The agreement for the sale and
                purchase of the event ticket is solely between the Attendee and the Event Organiser. Seaty acts as a platform to facilitate this transaction and is not a party to the contract.
              </p>
            </>
          ),
        },
        {
          title: `Seaty's Role`,
          content: (
            <>
              <p>
                Seaty's role is strictly limited to providing a service that allows Event Organisers to list events and Attendees to purchase tickets. Seaty does not host, endorse, or assume
                responsibility for the actual events. The responsibility for the event's execution, including but not limited to its quality, cancellation, or any other aspect related to the event,
                lies entirely with the Event Organiser.
              </p>
            </>
          ),
        },
        {
          title: 'No Accountability of Seaty for Event and Ticket Sales',
          content: (
            <>
              <p>
                Attendees and Event Organisers acknowledge that Seaty is not accountable for the sale or provision of tickets beyond facilitating the transaction through its platform. Any complaints,
                refunds, or disputes related to the event or ticket must be resolved between the Attendee and the Event Organiser, without involving Seaty.
              </p>
            </>
          ),
        },
        {
          title: 'Service Fees',
          content: (
            <>
              <p>
                Attendees and Event Organisers agree that Seaty may charge service fees for the use of its platform. These fees are for the provision of services by Seaty to facilitate the sale of
                tickets and are separate from the ticket price set by Event Organisers. The transaction between Seaty and any party regarding service fees is governed by these Terms and Conditions.
                Service fees may either be handled and consumed by the Event Organisers, or passed on to the Attendees, depending on the event configuration as defined by the Event Organisers.
              </p>
            </>
          ),
        },
        {
          title: 'Acceptance of Terms',
          content: (
            <>
              <p>
                By using Seaty's services, both Attendees and Event Organisers agree to the terms outlined in this section. They acknowledge that their transaction is with each other and that Seaty's
                role is limited to providing a platform for facilitating this transaction.
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: 'Cancellation/Alteration of Events',
      sections: [
        {
          title: 'Event Cancellation Policy',
          content: (
            <>
              <p>
                In the event of a cancellation or any alteration to the event that necessitates refunds, it is the responsibility of the Event Organiser to directly manage and process refunds for
                tickets to attendees. Seaty is not responsible for issuing refunds for any events that are cancelled, postponed, or fail to go ahead as planned, as the contractual agreement for ticket
                sales rests solely between the Attendee and the Event Organiser.
              </p>
            </>
          ),
        },
        {
          title: `Seaty's Role and Limitation of Liability`,
          content: (
            <>
              <p>
                Attendees and Event Organisers acknowledge that Seaty is a platform facilitating the connection between Event Organisers and attendees for the purpose of ticket sales. Seaty is not
                responsible for the organisation, promotion, or execution of events listed on its platform. Consequently, Seaty assumes no responsibility or liability for events that are canceled,
                postponed, or fail to proceed as advertised. All inquiries, complaints, and requests for refunds due to a canceled or unsuccessful event must be directed to the Event Organiser, who is
                entirely responsible for addressing such matters. Event Organiser's are obligated to provide and keep up to date their contact details in the event editor when they create an event,
                which will be shown on the event landing page. Seaty is not a party to the contract of sale for event tickets, does not host or endorse events, and assumes no responsibility for the
                cancellation of events or the issuance of refunds.
              </p>
            </>
          ),
        },
        {
          title: 'Financial Responsibilities',
          content: (
            <>
              <p>
                The Event Organiser is responsible for issuing refunds to attendees in accordance with their refund policy as stated at the time of ticket purchase. The terms of refund policies must
                be clear, accessible, and in compliance with applicable laws and regulations. Event Organisers agree to indemnify Seaty against any claims, costs, damages, and expenses arising from
                their failure to adequately fulfill their obligations regarding event cancellations, including but not limited to the issuance of refunds to attendees.
              </p>
            </>
          ),
        },
        {
          title: `Attendee's Recourse for Refunds`,
          content: (
            <>
              <p>
                Attendees understand and agree that any claims for refunds in the case of cancelled or altered events must be directed exclusively towards the Event Organiser. Attendees agree not to
                pursue Seaty for refunds, including initiating credit/debit card chargebacks against Seaty, for events that are cancelled or do not proceed as planned. The Attendee's recourse for
                refunds, complaints about cancellations, or any disputes related to event cancellation is solely and entirely with the Event Organiser.
              </p>
            </>
          ),
        },
        {
          title: `Fees Payable to Seaty`,
          content: (
            <>
              <p>
                It is understood that any fees payable to Seaty for the use of its platform services are separate from the transaction between the Attendee and the Event Organiser. When a refund is
                given, Seaty may retain any service fees as agreed upon at the time of the original ticket transaction should the cancellation of an event cause the loss of significant fees to Seaty.
              </p>
            </>
          ),
        },
        {
          title: 'Agreement of Terms',
          content: (
            <>
              <p>
                By using Seaty's platform, both attendees and Event Organisers agree to these terms. They acknowledge that the financial transaction and contractual relationship regarding event
                attendance are exclusively between the attendee and the Event Organiser. Seaty's role is limited to providing a service for the sale and purchase of tickets, and it collects service
                fees separately for this purpose. Both parties, by accepting these Terms of Service or by using the site, agree that Seaty is not liable for canceled events or for the fulfillment of
                refunds and complaints related to such cancellations.
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: 'Payment Methods',
      sections: [
        {
          title: 'Overview',
          content: (
            <>
              <p>There are two types of payment processing options Organisers may choose when using the Services:</p>
              <ol>
                <li>
                  <strong>Organiser Managed Payment Processing (OMPP)</strong>: This option involves collecting Event Ticket Fees using any means available outside of Seaty Services.
                </li>
                <li>
                  <strong>Seaty Payment Processing (SPP)</strong>: This option requires the agreement of Seaty administrators for the collection of Event Ticket Fees.
                </li>
              </ol>
              <p>
                Depending on the payment processing option the Organiser selects when using the Services, monetary payments will be made to the Organiser directly by Buyers (via OMPP) or by Seaty (via
                SPP), in each case as described below.
              </p>
            </>
          ),
        },
        {
          title: 'Organiser Managed Payment Processing',
          content: (
            <>
              <p>
                <strong>(a) Independent Relationship</strong>
              </p>
              <p>
                When using OMPP, Organisers and Buyers enter an agreement whereby any monies due for collection from event ticket(s) are entirely outside of all Seaty services. OMPP ticket orders
                include:
              </p>
              <ol>
                <li>Any ticket that has been requested by a user and then accepted by an Organiser without online payment services (SPP).</li>
                <li>Any ticket that has been booked directly by an administrator without our online payment services (SPP).</li>
              </ol>
              <p>
                As a Buyer purchasing a ticket on this platform, you acknowledge that you are not purchasing any goods from Seaty. Seaty is utilised to facilitate the transaction of goods directly
                from an independent Event Organiser to a Buyer (Attendee) and will not be held responsible, by an Organisation or a Buyer, for the delivery or support of said goods, nor accountable
                for refunds where we do not possess the funds for those goods.
              </p>

              <p>
                <strong>(b) Service Fees</strong>
              </p>
              <p>
                Organisers will collect all monies directly from Buyers under the OMPP option. Seaty is not involved in, nor responsible for, the collection of payment, and all Services are provided
                as a means to track orders and payments between Organisers and Seaty users. Seaty does not charge any Service Fees for the use of OMPP related ticket orders.
              </p>
            </>
          ),
        },
        {
          title: 'Seaty Payment Processing',
          content: (
            <>
              <p>
                <strong>(a) Payment Process</strong>
              </p>
              <p>
                When an Event Organiser elects to use Seaty for the collection of Event Ticket Fees, payment processing is conducted directly through a secure provider chosen by Seaty. Under the SPP
                option:
              </p>
              <ul>
                <li>
                  Seaty will collect all Event Ticket Fees on behalf of the Event Organiser from Buyers and deduct all applicable Service Fees from the Event Ticket Fees collected by Seaty, then pass
                  the remainder to the Organiser within an agreed period, no sooner than 4 days, after the event end date for the event to which the Event Ticket Fees correspond.
                </li>
                <li>
                  Seaty reserves the right to withhold funds at any time as deemed necessary for the processing and settlement of all refunds, disputed charges, chargebacks, customer complaints,
                  allegations of fraud, and other discrepancies and as otherwise permitted pursuant to this TOS.
                </li>
                <li>
                  Seaty may decide to advance a portion of Event Ticket Fees to the Event Organiser before the specified period, under terms and conditions established by Seaty from time to time.
                </li>
              </ul>
              <p style={{ marginBottom: '30px' }}>
                <strong>
                  Event Organisers agree that all Event Ticket Fees for a given event are earned by the Event Organiser only following the conclusion of the applicable event and all Event Ticket Fees
                  ultimately due will be net of all Service Fees, refunds, disputed charges, chargebacks, and other deductions, whether due to customer complaints, allegations of fraud, discrepancies
                  related to the applicable event or otherwise. No payments shall be made to an Event Organiser by Seaty for any event that is cancelled. If payments have been made by Seaty to an
                  Event Organiser for a cancelled event, the Event Organiser will immediately refund all such payments to Seaty upon the cancellation of such event. All sales, fees, charges, and funds
                  are payable in Great British Pounds, or other foreign currencies accepted by Seaty.
                </strong>
              </p>

              <p>
                <strong>(b) Refunds</strong>
              </p>
              <p>Under the SPP option:</p>
              <ol>
                <li>
                  It is the responsibility of the Organiser to communicate its refund policy to Buyers and to issue refunds to Buyers. An Organiser must ensure that its refund policy is consistent
                  with the terms of this TOS and the payment and refund mechanics of the Site. Organisers are permitted to issue refunds to Buyers, provided the refund is communicated before
                  seventy-two (72) hours after the event end date and within three (3) months after the transaction date for the underlying ticket purchase.
                </li>
                <li>
                  Seaty reserves the right to force a refund of any or all tickets at any time for any reason, including if Seaty receives complaints from a significant number of Buyers or determines
                  that an Organiser has engaged in fraudulent activity or made misrepresentations. In such cases, the Organiser shall owe the amount of such refund to Seaty.
                </li>
              </ol>

              <p>
                <strong>(c) Chargebacks</strong>
              </p>
              <p>
                Under the SPP option, any transaction chargebacks initiated by a Buyer for any reason with respect to an event shall be charged back to the Organiser. All communications and disputes
                regarding chargebacks are between the Organiser and Buyer, and Seaty will not be responsible or liable for, and the Organiser agrees to fully indemnify Seaty and its affiliates for,
                chargebacks issued in the course of using the SPP option.
              </p>
            </>
          ),
        },
        {
          title: 'Non-Exclusive Remedies',
          content: (
            <>
              <p>
                <strong>(a) Payment</strong>
              </p>
              <p>
                In the event that an Organiser fails to pay any amount owed to Seaty pursuant to this TOS when due, such amount shall accrue interest from the due date until paid in full at a rate
                equal to the lesser of (a) ten percent (10%) per annum, or (b) the maximum amount permitted by applicable law. Should any amounts be owed by the Organiser to Seaty under this TOS or
                otherwise, Seaty may:
              </p>
              <ol>
                <li>Deduct such amounts from the Organiser's outstanding balance, for that particular event or for any other event the Organiser lists through the Services.</li>
                <li>
                  Send an invoice to the Organiser for such amounts if the outstanding balance is insufficient to cover these costs, in which case the Organiser must pay Seaty the invoiced amounts
                  within thirty (30) days from the invoice date.
                </li>
              </ol>
              <p>
                If payment for any amounts due to Seaty is not made when due, Seaty reserves the right, at its sole discretion and without limiting its other rights and remedies, to terminate the
                Organiser's registration for the Services and cancel all other events listed by the Organiser.
              </p>

              <p>
                <strong>(b) Confirmation</strong>
              </p>
              <p>
                Upon receipt of a credit card authorisation for each individual ticket purchase, Seaty generates a ticket order for the related Organiser's event. Organisers agree to unconditionally
                accept, honour, and fulfil all ticketing commitments confirmed by Seaty through the Services. It is the Organiser's responsibility to verify the Buyer's membership status, confirmation
                number, and any event restrictions prior to the event.
              </p>

              <p>
                <strong>(c) Taxes; Withholding</strong>
              </p>
              <ol>
                <li>
                  You are solely responsible for determining and fulfilling all obligations related to the collection, remittance, and reporting of all Taxes that apply to your use of the Services.
                  This includes using any tax tools or calculators provided by Seaty, understanding that these tools do not guarantee compliance with all tax requirements. You release Seaty from any
                  liability related to your use of these tax tools. If you collect Taxes through the Services, these will be treated like Event Ticket Fees, and Seaty will disburse such amounts to you
                  alongside the underlying Event Ticket Fees.
                </li>
                <li>All Fees charged by Seaty do not include any Taxes, except as explicitly stated in an invoice or billing statement. You agree to pay any Taxes that are imposed on such Fees.</li>
                <li>Seaty reserves the right to withhold payment of any amounts owed to you if it is deemed necessary by applicable laws, regulations, or governmental demands.</li>
              </ol>
            </>
          ),
        },
      ],
    },
    {
      title: 'Your Registration Obligations',
      content: (
        <>
          <p>To be a registered user of the Services, whether as an Organiser or an Attendee, you agree to:</p>
          <ol>
            <li>Provide true, accurate, current, and complete information about yourself as prompted by the Site registration form (the "Registration Data").</li>
            <li>Maintain and promptly update the Registration Data to keep it true, accurate, current, and complete.</li>
          </ol>
          <p>
            If you provide any information that is untrue, inaccurate, not current, or incomplete, or if Seaty has reasonable grounds to suspect that such information is untrue, inaccurate, not
            current, or incomplete, Seaty reserves the right to suspend or terminate your accounts and refuse your current or future use of the Services (or any portion thereof).
          </p>
          <p>
            Seaty is concerned about the safety and privacy of all its users, particularly children. Therefore, you must be at least 18 years of age, or the legal age of majority in your jurisdiction
            if it has an older age of majority, to register for an account.
          </p>
        </>
      ),
    },
    {
      title: 'Account Security and Responsibility',
      content: (
        <>
          <p>
            As part of the Site registration process, you will create a password and account. You are responsible for maintaining the confidentiality of your password and account, and are fully
            responsible for all activities that occur under your account, including, without limitation, all actions by sub-users registered under your account. You agree to:
          </p>
          <ol>
            <li>Immediately notify Seaty of any unauthorized use of your password or account or any other breach of security.</li>
            <li>Ensure that you exit from your account at the end of each session.</li>
          </ol>
          <p>
            Seaty cannot and will not be liable for any loss, damage, or other liability arising from your failure to comply with this section or from any unauthorized access to or use of your
            account.
          </p>
          <p>
            In the event of any dispute between two or more parties as to account ownership, you agree that Seaty shall be the sole arbiter of such dispute in its sole discretion and that Seaty’s
            decision (which may include termination or suspension of any account subject to dispute) shall be final and binding on all parties.
          </p>
        </>
      ),
    },
    {
      title: 'Content',
      sections: [
        {
          title: 'Site Content',
          content: (
            <>
              <p>
                You agree that all material, including without limitation information, data, software, text, design elements, graphics, images, and other content (collectively, "Content"), contained
                in or delivered via the Services or otherwise made available by Seaty in connection with the Services (collectively, "Site Content"), is protected by copyrights, trademarks, service
                marks, trade secrets, or other intellectual property and proprietary rights and laws. Seaty may own the Site Content or portions of the Site Content may be made available to Seaty
                through arrangements with third parties.
              </p>
              <p>Except as expressly authorized by Seaty in writing or in connection with your use of the intended functionality of the Services, you agree not to:</p>
              <ul>
                <li>Sell, licence, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit, or create derivative works of any Site Content.</li>
                <li>Post any Site Content on any other website or in a networked computer environment for any purpose.</li>
              </ul>
              <p>
                Reproducing, copying, or distributing any Site Content for any other purpose is strictly prohibited without the express prior written permission of Seaty. You shall use the Site
                Content only for purposes that are permitted by this TOS and any applicable laws and regulations (foreign and domestic). Any rights not expressly granted herein are reserved.
              </p>
            </>
          ),
        },
        {
          title: 'Your Content',
          content: (
            <>
              <p>
                You acknowledge and agree that if you contribute, provide, or make available any Content to the Site ("Your Content"), you hereby grant to Seaty a non-exclusive, worldwide, perpetual,
                irrevocable, royalty-free, transferable, re-licensable (through multiple tiers) right and licence to use, reproduce, adapt, modify, distribute, translate, publish, create derivative
                works from, perform, display, and otherwise exploit Your Content, in whole or in part, in any media now known or hereafter developed, for any purpose whatsoever. You represent and
                warrant that you have all the rights, power, and authority necessary to grant the foregoing licence, and that all Your Content:
              </p>
              <ol>
                <li>Does not infringe, violate, misappropriate, or otherwise conflict with the rights of any third party.</li>
                <li>Complies with all applicable laws and regulations (foreign and domestic).</li>
              </ol>
              <p>
                In addition, Your Content must be accurate and truthful. Seaty reserves the right to remove any of Your Content from the Site at any time if Seaty believes, in its sole discretion,
                that it does not comply with this TOS. Furthermore, you agree that Seaty may use your name and logo (whether or not you have made it available through the Site) for the purpose of
                identifying you as an existing or past customer of Seaty both on the Site and in marketing and promotional materials.
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: 'Conduct',
      sections: [
        {
          title: 'Certain Restrictions',
          content: (
            <>
              <p>
                You understand that you are liable for all Content, in whatever form, that you provide or otherwise make available to or through the Services, including to Buyers and other users of
                the Services. You agree not to use the Services to:
              </p>
              <ul>
                <li>
                  Transmit, email, post, upload or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous,
                  invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;
                </li>
                <li>Harm minors in any way;</li>
                <li>Facilitate gambling, gaming, lotteries, raffles, contests, sweepstakes and/or any other activity featuring the award of a prize;</li>
                <li>Impersonate any person or entity, including, but not limited to, an Seaty representative, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                <li>Forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content transmitted through the Services;</li>
                <li>
                  Transmit, email, post, upload or otherwise make available any Content that you do not have a right to make available under any law or under contractual or fiduciary relationships
                  (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);
                </li>
                <li>
                  Transmit, email, post, upload or otherwise make available any Content that infringes any patent, trademark, trade secret, copyright or other intellectual property or proprietary
                  rights of any person or entity;
                </li>
                <li>
                  Transmit, email, post, upload or otherwise make available any Content related to the sale or provision of goods and services other than the sale or provision of tickets and
                  registrations to events listed on the Site and other goods and services being sold or provided in conjunction with such events;
                </li>
                <li>
                  Transmit, email, post, upload or otherwise make available any Content that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or
                  limit the functionality of any computer software or hardware or telecommunications equipment or to otherwise interact with the Services in a manner not permitted by this TOS or
                  expressly authorised by Seaty;
                </li>
                <li>
                  Interfere with or disrupt the Services or servers or networks connected to the Services, or disobey any requirements, procedures, policies or regulations of networks connected to the
                  Services;
                </li>
                <li>
                  Intentionally or unintentionally violate any applicable law or regulation (foreign or domestic), including without limitation (I) regulations promulgated by the U.K. Government, any
                  rules of any national or other securities exchange and (II) laws and regulations (foreign or domestic) regarding the sale or resale of tickets (including without limitation with
                  respect to licensure requirements, maximums or limits on ticket prices, etc.);
                </li>
                <li>Stalk or otherwise harass any person or entity.</li>
              </ul>
            </>
          ),
        },
        {
          title: 'Certain Remedial Rights',
          content: (
            <>
              <p>
                You acknowledge that Seaty does not pre-screen any Content provided or made available by you or any third party in connection with the Services, but that Seaty and its designees shall
                have the right (but not the obligation) in their sole discretion to:
              </p>
              <ol>
                <li>Monitor, alter, edit, or remove any of your Content, in whole or in part.</li>
                <li>Rescind and terminate your right to use the Services at any time (with or without notice) for any reason or no reason.</li>
              </ol>
              <p>
                You acknowledge and agree that Seaty may preserve your Content and may also disclose your Content for any reason, including without limitation if required to do so by law or in the
                good faith belief that such preservation or disclosure is reasonably necessary to:
              </p>
              <ol>
                <li>Comply with legal process.</li>
                <li>Enforce this TOS.</li>
                <li>Respond to claims that any of your Content violates the rights of third parties.</li>
                <li>Protect the rights, property, or personal safety of Seaty, its users, and/or the public.</li>
              </ol>
              <p>You understand that the technical processing and transmission of the Services, including Your Content, may involve:</p>
              <ol>
                <li>Transmissions over various networks.</li>
                <li>Changes to conform and adapt to technical requirements of connecting networks or devices.</li>
              </ol>
            </>
          ),
        },
      ],
    },
    {
      title: 'Sub-domains and Links',
      content: (
        <>
          <p>
            Seaty may provide you with the right to use a sub-domain or link within the Site (e.g., Seaty.co.uk/[event-tag]). All such sub-domains and links are the sole property of Seaty. In the
            event Seaty provides you with a sub-domain or link, your right to use such sub-domain or link may be terminated by Seaty at any time (with or without notice) for any reason or no reason.
          </p>
        </>
      ),
    },
    {
      title: 'Special Considerations for International Use',
      content: (
        <>
          <p>
            Recognising the global nature of the Internet, you agree to comply with all local rules regarding online conduct and acceptable Content. By way of illustration and not limitation, you
            agree to comply with all applicable laws and regulations (foreign and domestic) regarding the transmission of technical data exported from the United Kingdom or the country in which you
            reside.
          </p>
          <p>
            In addition, the Services are subject to United Kingdom export controls. No part of the Services may be exported or re-exported into, or to a national or resident of, any country to which
            the U.K. has embargoed goods and/or services of the same type as the Services. By using the Services or any part thereof (including by downloading any Software), you represent and warrant
            that you are not located in, and you are not a national or resident of, any such country.
          </p>
        </>
      ),
    },
    {
      title: 'Additional Services',
      content: (
        <>
          <p>
            Seaty may, upon request, and for such fees as Seaty may establish from time to time in its sole discretion, provide additional services to you beyond the functionality of the Site,
            including without limitation leasing ticket scanning and other equipment, providing consultants and staffers to assist you on the date of your event, and providing dedicated account
            management services. All such additional services, whether provided prior to, during or following your event, shall be deemed a part of the Services and subject to all the terms and
            conditions of this TOS. Such additional services shall be set forth in a written Agreement in conjunction with this TOS, Seaty Equipment Lease Agreement, and/or other written agreement
            between you and an authorised officer of Seaty, and shall set forth the Service Fees and the other terms and conditions relating to such additional services. As a condition to the lease of
            any equipment, you shall enter into a separate Seaty Equipment Lease Agreement.
          </p>
        </>
      ),
    },
    {
      title: 'Indemnity',
      content: (
        <>
          <p>
            You agree to defend, indemnify and hold Seaty, and its affiliates, and each of its and their respective officers, directors, agents, co-branders, other partners, and employees, harmless
            from any and all damage (whether direct, indirect, incidental, consequential or otherwise), loss, liability, cost and expense (including, without limitation, reasonable attorneys and
            accounting fees) resulting from any claim, demand, suit, proceeding (whether before an arbitrator, court, mediator or otherwise), or investigation made by any third party (each a "Claim")
            due to or arising out of: your Content; your or any of your affiliates, or any of your or your affiliates officers, directors, agents or employees, use of, contribution to or connection
            with the Services or violation of any rights of another; your event(s); and/or your violation of this TOS. Seaty shall provide notice to you of any such Claim, provided that the failure or
            delay by Seaty in providing such notice shall not limit your obligations hereunder. Seaty reserves the right to assume the exclusive defence and control of any matter which is subject to
            indemnification under this Section, and in such case, you agree to cooperate with all reasonable requests in assisting Seaty defence of such matter.
          </p>
        </>
      ),
    },
    {
      title: 'Service Modifications/Suspensions',
      content: (
        <>
          <p>
            Seaty reserves the right at any time to, and from time to time may, modify, suspend or discontinue, temporarily or permanently, the Services (or any part thereof) for any reason or no
            reason with or without notice. Seaty will not be responsible to you for a refund, in whole or part, of Service Fees for any reason, except for Service Fees (excluding the portion of
            Service Fees paid over to Seaty payment providers and other third party service providers used by Seaty in connection with providing the Services) related to refunds issued to Buyers. You
            agree that Seaty shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Services.
          </p>
        </>
      ),
    },
    {
      title: 'Termination',
      content: (
        <>
          <p>
            Seaty, in its sole discretion, may terminate your password, accounts (or any part thereof) and/or your right to use the Services, and remove and discard any and all of Your Content within
            the Services, at any time for any reason or no reason, including, without limitation, for lack of use, failure to timely pay any fees or other monies due Seaty, or if Seaty believes that
            you have violated or acted inconsistently with the letter or spirit of this TOS. You agree that any termination of your right to use the Services may be effected without prior notice, and
            acknowledge and agree that Seaty may immediately deactivate or delete your account and all related Content and files related to your account and/or bar any further access to such files or
            the Services. Further, you agree that Seaty shall not be liable to you or any third-party for any termination of your right to use or otherwise access the Services. All provisions of this
            TOS that by their nature should survive termination of your right to use the Services shall survive (including, without limitation, all limitations on liability, releases, and
            indemnification obligations, disclaimers of warranties, and intellectual property protections and licences).
          </p>
        </>
      ),
    },
    {
      title: 'Links',
      content: (
        <>
          <p>
            The Services may provide, or third parties may provide, links to other Internet websites or resources. Because Seaty has no control over such websites and resources, you acknowledge and
            agree that Seaty is not responsible for the availability of such websites or resources, and does not endorse and is not responsible or liable for any Content, advertising, products,
            services or other materials on or available from such websites or resources. You further acknowledge and agree that Seaty shall not be responsible or liable for any damage or loss caused
            or alleged to be caused by or in connection with any use of or reliance on any such Content, advertising, products, services or other materials available on or through any such website or
            resource.
          </p>
        </>
      ),
    },
    {
      title: 'Disclaimer of Warranties',
      content: (
        <>
          <p>
            The Services are provided on an "as is" and "as available" basis. Seaty hereby expressly disclaims all warranties of any kind, express or implied, including, but not limited to, implied
            warranties of merchantability, title, non-infringement, and fitness for a particular purpose. Seaty makes no warranty that:
          </p>
          <ol>
            <li>The Services will meet your requirements.</li>
            <li>The Services will be uninterrupted, timely, secure, or error-free.</li>
            <li>The results that may be obtained from the use of the Services will be accurate or reliable.</li>
            <li>The Services themselves (or any part thereof) will meet your expectations.</li>
            <li>Any errors in the Services will be corrected.</li>
          </ol>
          <p>
            <strong>
              Seaty is not responsible and shall have no liability for the content, products, services, actions, or inactions of any user, buyer, or other non-organiser, organiser, or third party
              before, during, and/or after an event; and Seaty will have no liability with respect to any warranty disclaimed in points (i) through (v) above. You acknowledge that Seaty has no control
              over and does not guarantee the quality, safety, or legality of events advertised, the truth or accuracy of any users' (including buyers', other non-organisers' and organisers') content
              or listings, or the ability of any user (including buyers and organisers) to perform, or actually complete a transaction. In addition, Seaty is not affiliated with, and has no agency or
              employment relationship with, any third-party service provider used in conjunction with the Services and Seaty has no responsibility for, and hereby disclaims all liability arising from,
              the acts or omissions of any such third-party service provider. The foregoing disclaimers shall not apply to the extent prohibited by applicable law.
            </strong>
          </p>
          <p>
            Notwithstanding the foregoing, you may report the misconduct of users, buyers, other non-organisers, organisers, and/or third parties in connection with the Site or any Services to Seaty.
            Seaty, in its sole discretion, may investigate the claim and take necessary action.
          </p>
        </>
      ),
    },
    {
      title: 'Limitation of Liability',
      content: (
        <>
          <p>
            Seaty, its payment processing partners, licensors, and any applicable card schemes shall not be liable under any circumstances or under any legal theory, whether in tort, contract, or
            otherwise, with respect to the Services, or any other subject matter of this TOS, for:
          </p>
          <ol>
            <li>
              Any indirect, incidental, special, consequential, punitive, or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible
              losses (even if any of them have been advised of the possibility of such damages).
            </li>
            <li>The cost of procurement of substitute services.</li>
            <li>Amounts in excess of GBP £50.00 in the aggregate for all claims from a given organiser with respect to the Services.</li>
            <li>Any matters beyond any of their reasonable control.</li>
          </ol>
          <p>
            Seaty, its payment processing partners, licensors, and any applicable card schemes shall have no liability with respect to any of your content or any content of any other user of the
            Services. The foregoing limitations shall not apply to the extent prohibited by applicable law.
          </p>
        </>
      ),
    },
    {
      title: 'Release',
      content: (
        <>
          <p>
            In consideration of being permitted to access and use the Services, you herewith agree to release Seaty, and its affiliates, and every of its and their individual officers, directors,
            agents, co-branders, alternative partners, and employees from all damages (whether direct, indirect, incidental, of import or otherwise), losses, liabilities, costs, and expenses of every
            kind and nature, known and unknown, arising out of or in any manner connected with disputes between you and third parties (including alternative organisers, buyers, and alternative
            non-organisers) in reference to the Services, your access and use of the Services, or your event.
          </p>
        </>
      ),
    },
    {
      title: 'Privacy',
      content: (
        <>
          <p>
            All information provided by you or collected by Seaty in reference to the Services is ruled by Seaty’s Privacy Policy, a replica of that is found at http://Seaty.co.uk/Docs/Privacy, which
            is herewith incorporated by reference into this TOS. Seaty strongly recommends that you review the Privacy Policy closely. Seaty shall not be responsible for protecting any info submitted
            to the site and isn't liable for the protection of privacy of electronic message or different info transferred through the net or any other network that you could use. Please bear in mind
            that if you opt to disclose personally identifiable info on the Services, this info could become public. Seaty doesn't control and shall not be answerable for the acts of you or any other
            users (whether Organisers, Buyers, different non-Organisers or otherwise) of the Services.
          </p>
        </>
      ),
    },
    {
      title: 'Notice',
      content: (
        <>
          <p>
            Notices to you may be created via either email or regular mail to the address in Seaty’s records. The Services can also provide notices of changes to the present TOS or alternative matters
            by displaying notices or links to notices to you typically on the Services. Any notice from you to us shall be sent by email to the email address Support@Seaty.co.uk.
          </p>
        </>
      ),
    },
    {
      title: 'Trademark Information',
      content: (
        <>
          <p>
            The trademarks, service marks, and logos of Seaty (the "Seaty Trademarks") used and displayed in reference to the Services are registered and unregistered trademarks or service marks of
            Seaty. Different company, product, and service names employed in reference to the Services could also be trademarks or service marks owned by third parties (the "Third Party Trademarks",
            and, conjointly with Seaty trademarks, the "Trademarks"). The giving of the Services shall not be construed as granting, by implication, estoppel, or otherwise, any licence or right to use
            any Trademark displayed in reference to the Services without the previous written consent of Seaty specific for every such use. The trademarks may not be used to disparage Seaty, any third
            party or Seaty’s or third party's products or services, or in any manner (in Seaty’s sole judgment) which will damage any goodwill within the trademarks. Use of any trademarks as a part of
            a link to or from any web site is prohibited unless Seaty approves the institution of such a link by previous written consent specific for every such link. All goodwill generated from the
            employment of any Seaty Trademark shall habituate to Seaty’s profit.
          </p>
        </>
      ),
    },
    {
      title: 'General',
      sections: [
        {
          title: 'Entire Agreement',
          content: (
            <>
              <p>
                This TOS constitutes the whole agreement between you and Seaty and governs your use of the Services as an Organiser, superseding any previous or contemporaneous agreements, proposals,
                discussions or communications between you and Seaty on the topic matter hereof, apart from any written Ticketing Services Agreement, Seaty equipment Lease Agreement or supplement
                Agreement between you and an authorised officer of Seaty with reference to a such event or events. You furthermore could also be subject to extra terms and conditions which will apply
                once you use affiliate services, third party Content or third party software package or the Services in a manner apart from as ruled by this TOS.
              </p>
            </>
          ),
        },
        {
          title: 'Choice of Law',
          content: (
            <>
              <p>
                This TOS and therefore the provision of the Services to you are ruled by the laws of the united kingdom, in and of itself laws are applied to agreements entered into and to be
                performed entirely within the united kingdom by its residents.
              </p>
            </>
          ),
        },
        {
          title: 'Arbitration',
          content: (
            <>
              <p>
                Any disputation or claim arising out of or concerning this TOS or the supply of the Services shall be solely settled by binding arbitration in accordance with united kingdom law. Any
                such disputation or claim shall be arbitrated on an individual basis, and shall not be consolidated in any arbitration with any claim or disputation of any other party. The arbitration
                shall be conducted in West Midlands, united kingdom, and judgment on the arbitration award is also entered in any court having jurisdiction thence. Subject to the preceding arbitration
                provision, you and Seaty conform to experience the non-public jurisdiction of the courts placed within the city of Birmingham, united kingdom.
              </p>
            </>
          ),
        },
        {
          title: 'Waiver and Invalid Provisions',
          content: (
            <>
              <p>
                The failure or delay of Seaty to exercise or enforce any right or provision of this TOS shall not represent a relinquishment of such right or provision. No oral relinquishment,
                amendment or modification shall be effective in any circumstance at all. If any provision of this TOS is found by an intermediator or court of competent jurisdiction to be invalid, the
                parties even so agree that the intermediator or court ought to endeavour to present effect to the parties' intentions as mirrored within the provision, and also the different
                provisions of this TOS shall stay in full force and effect.
              </p>
            </>
          ),
        },
        {
          title: 'Filing a Claim',
          content: (
            <>
              <p>
                You agree that notwithstanding any statute or law to the contrary, any claim or explanation for action arising out of or associated with use of the Services or this TOS must be filed
                inside two (2) months once such claim or reason for action arose or be forever barred.
              </p>
            </>
          ),
        },
        {
          title: 'Violations of TOS',
          content: (
            <>
              <p>Please let us know of any violations of this TOS by email to Support@Seaty.co.uk.</p>
            </>
          ),
        },
        {
          title: 'Segment Titles',
          content: (
            <>
              <p>The segment titles in this TOS are just for reference and have no legal or contractual impact.</p>
            </>
          ),
        },
      ],
    },
  ];

  const renderSections = (sections: ISection[], parent?: ISection) => {
    return sections.map((section) => {
      const id = section.title.toLowerCase().replace(' ', '-');

      return (
        <section className={`doc-section ${parent ? 'doc-child-section' : ''}`} key={`DOC_${id}${parent ? parent.title : ''}`}>
          {parent ? <h3 id={id}>{section.title}</h3> : <h2 id={id}>{section.title}</h2>}
          {section.content && <div className="doc-section-content">{section.content}</div>}
          {section.sections && <div className="doc-section-child">{renderSections(section.sections, section)}</div>}
        </section>
      );
    });
  };

  const renderKeys = (sections: ISection[], parent?: ISection) => {
    return sections.map((section) => {
      const id = section.title.toLowerCase().replace(' ', '-');

      return (
        <li key={`KEY_${id}${parent ? parent.title : ''}`} className={`doc-key ${parent ? 'doc-child-key' : ''}`}>
          <a href={`#${id}`}>{section.title}</a>
          {section.sections && <div className="doc-section-child">{renderKeys(section.sections, section)}</div>}
        </li>
      );
    });
  };

  return (
    <>
      <Helmet>
        <title>Seaty - Terms & Conditions</title>
        <meta name="description" content={`Seaty.co.uk terms & conditions.`} />
      </Helmet>
      <div className="docs">
        <Sidebar />

        <div className="content">
          <article>
            <header>
              <h1>Terms of Service</h1>
              <p>Udpated on {UPDATED_DATE}</p>
            </header>
            <p>
              Welcome to Seaty! We're thrilled to have you on board. Our platform is designed with the safety and smooth operation of events in mind, for both creators and consumers alike. Before you
              dive in, it's important to familiarise yourself with our Terms of Service, ("TOS").
            </p>
            <br />
            <p>
              The following TOS regulate all use by you as an organisation or individual when using (I) the Seaty websites and domains, including all webpages, subdomains and subparts therein
              contained, otherwise referred to as "Site", (II) all and any services available on or through the Site or otherwise provided by Seaty, and (III) all Software (as defined below),
              otherwise referred to as "Services"). The Services and Seaty are owned and operated by Seaty Ltd ("Seaty"), registered in the United Kingdom. The Services are only offered subject to
              your acceptance, without modification, of all of the terms and conditions contained within this document and all other operating rules, procedures and policies that may be published from
              time to time on the Site by Seaty.
            </p>
            <br />
            <p>
              <strong>
                By accessing or using any part of the services offered by Seaty, you agree to all of the terms and conditions contained herein and all other operating rules, policies, and procedures
                that may be published from time to time on the site by Seaty. If you do not agree to any of these terms, conditions, rules, policies, or procedures, please do not use or access the
                services. This TOS may only be modified (i) on an event by event basis through a written ticketing services agreement or written addendum agreement to this TOS, signed by you and an
                authorised officer of Seaty, or (ii) by Seaty as defined below.
              </strong>
            </p>
            <h2>Contents</h2>
            <ul className="contents-list">{renderKeys(sections)}</ul>

            {renderSections(sections)}
          </article>
        </div>
      </div>
    </>
  );
};

export default TermsPage;
