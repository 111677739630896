import { FunctionComponent, useEffect, useState } from 'react';
import { LazyImage } from '../../components/LazyImage';
import CacheHelper from '../../helpers/CacheHelper';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { ITour } from '../../interfaces/ITour';
import ReactQuill from 'react-quill';
import { DatesModal } from '../../modals/Dates/DatesModal';
import { IEvent } from '../../interfaces/IEvent';
import linq from 'linq';
import moment from 'moment';
import Constants from '../../helpers/Constants';
import ShareLinks from '../EventPage/ShareLinks';

export interface IProps {
  tour: ITour;
  noBorder?: boolean;
  organisation: IOrganisation;
  isAdmin: boolean;
}

const TourDetails: FunctionComponent<IProps> = (props) => {
  const tour = props.tour;
  const [organisation, setOrganisation] = useState<IOrganisation>();
  const [datePickerEvent, setDatePickerEvent] = useState<IEvent>(null);

  useEffect(() => {
    CacheHelper.organisationById(tour.OrganisationId).then((o) => setOrganisation(o));
  }, []);

  let isPast = true;

  tour.Events.forEach((event) => {
    event.Dates.forEach((ed) => {
      (ed as any).IsPast = moment(ed.DateAsString).add(12, 'hours') <= moment();
    });

    const isEventPast = !linq.from(event.Dates).any((ed) => !ed.IsPast);
    if (!isEventPast) isPast = false;
  });

  const isSoldOut = !linq.from(tour.Events).any((e) => !e.SoldOut);
  const isSuspended = !linq.from(tour.Events).any((e) => !e.Suspend);

  const percentageOfNotLockedSold = linq.from(tour.Events).sum((e) => e.PercentageOfNotLockedSold) / tour.Events.length;

  const availabilityClassName =
    isSoldOut || isPast || isSuspended
      ? ' sold-out'
      : percentageOfNotLockedSold > Constants.RedPercentageEventDate
        ? ' almost-sold-out'
        : percentageOfNotLockedSold > Constants.YellowPercentageEventDate
          ? ' selling-well'
          : ' good-availability';

  return (
    <>
      <div className="strip-block" style={{ background: 'white', border: props.noBorder ? '0' : null }}>
        <div style={{ marginBottom: '40px' }} className="row">
          <div className="col-sm-5 left">{tour.LogoUrl && <LazyImage style={{ display: 'inline-block' }} alt="Tour" src={tour.LogoUrl} />}</div>

          <div className="col-sm-7">
            <div style={{ marginBottom: '15px' }}>
              {tour.Name}
              <div className="text">Presented by {tour.Organisation.Name}</div>
            </div>
            <div className="chunky-buttons" style={{ marginBottom: '40px' }}>
              <button className={`has-button-status${availabilityClassName}`} onClick={() => setDatePickerEvent(props.tour.Events[0])}>
                Book tickets
                <div className="button-status">
                  {isSuspended
                    ? 'Suspended'
                    : isPast
                      ? 'Elapsed'
                      : isSoldOut
                        ? 'Sold out'
                        : percentageOfNotLockedSold > Constants.RedPercentageEventDate
                          ? 'Limited tickets'
                          : percentageOfNotLockedSold > Constants.YellowPercentageEvent
                            ? 'Getting busy'
                            : 'Good availability'}
                </div>
              </button>
            </div>
            <div style={{ marginBottom: '30px' }}>
              Share this tour
              <ShareLinks tourTag={tour.TourTag} />
            </div>
            About this tour
            <div className="text" style={{ marginBottom: '40px' }}>
              {tour.Description && <ReactQuill theme="snow" readOnly={true} modules={{ toolbar: false }} value={tour.Description} />}
            </div>
            {organisation && (organisation.Id == 280 || organisation.Id == 332) && (
              <div style={{ marginBottom: '30px' }} id="mc_embed_signup">
                <form
                  action="https://strictlytheatreco.us15.list-manage.com/subscribe/post?u=31884894ba5579789edbc8573&amp;id=372c3af0c9"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                  noValidate
                >
                  <div id="mc_embed_signup_scroll">
                    Join the {organisation.Name} mailing list
                    <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Email address" required />
                    <div style={{ position: 'absolute', left: '-10000px' }} aria-hidden="true">
                      <input type="text" name="b_31884894ba5579789edbc8573_372c3af0c9" tabIndex={-1} />
                    </div>
                    <div className="clear chunky-buttons">
                      <input style={{ margin: '0' }} type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>

      {datePickerEvent && <DatesModal currentEventDate={null} onClose={() => setDatePickerEvent(null)} isAdmin={props.isAdmin} eventTag={datePickerEvent.EventTag} />}
    </>
  );
};

export default TourDetails;
