import linq from 'linq';
import React from 'react';

import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import Button from '../../../components/Button';
import SpacerTable from '../../../components/SpacerTable';
import DateHelper from '../../../helpers/DateHelper';
import StringHelper from '../../../helpers/StringHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IEventDate } from '../../../interfaces/IEventDate';
import { IOrder } from '../../../interfaces/IOrder';
import { ITicket } from '../../../interfaces/ITicket';
import { Section } from '../../../modals/UserEventOverview';

export interface IProps {
  event: IEvent;
  selectedOrder: IOrder;
  setSection: (section: Section) => void;
  setSelectedTicket: (ticket: ITicket) => void;
}

const OrderSection: React.FC<IProps> = (props) => {
  const { event, setSection, selectedOrder, setSelectedTicket } = props;

  const eventDate: IEventDate = event.Dates.filter((d) => d.Id == selectedOrder.EventDateId)[0];

  var availableTicketsCount = linq
    .from(selectedOrder.Seats)
    .where(function (s: any) {
      return !s.Cancelled;
    })
    .toArray().length;

  return (
    <>
      <SpacerTable>
        <h1>Order #{selectedOrder.Id}</h1>
      </SpacerTable>
      <div className="spacer" />

      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>Event date</BlockHeader>
            {DateHelper.asDateAtTimeAmPm(eventDate.Date)}
          </Block>
          <Block
            className="route"
            onClick={() => {
              setSelectedTicket(null);
              setSection(Section.Notes);
            }}
          >
            <BlockHeader>{selectedOrder.UserNotes && selectedOrder.UserNotes.length > 0 ? `Notes` : `Add note`}</BlockHeader>
            {selectedOrder.UserNotes && <div className="notes"> {selectedOrder.UserNotes}</div>}
          </Block>

          {selectedOrder.Discounts.map((discount) => {
            var discountName = 'Discount';

            if (discount.Name != null && discount.Name.length > 0) {
              if (discountName.toLowerCase().indexOf('discount') !== -1) {
                discountName = discount.Name;
              } else {
                discountName = discount.Name + ' discount';
              }
            }

            return (
              <Block key={'Discount_' + discount.Name + '_' + discount.Id}>
                <BlockHeader>
                  <td>{discountName}</td>
                  <td className="right">
                    {event.CurrencySymbol}
                    {(discount.Amount / 100.0).toFixed(2)}
                  </td>
                </BlockHeader>
              </Block>
            );
          })}

          <Block>
            <BlockHeader>
              <td>Price</td>
              <td className="right">{selectedOrder.Price}</td>
            </BlockHeader>
          </Block>
        </tbody>
      </table>

      <div className="spacer-x2" />

      <SpacerTable>
        <h1>
          {availableTicketsCount} {StringHelper.AddSWhenMany(availableTicketsCount, 'Ticket')}
        </h1>
        Click on a ticket below to add or change notes shown on the ticket.
      </SpacerTable>
      <div className="spacer" />

      <table className="blocks">
        <tbody>
          {selectedOrder.Seats.filter((s) => !s.Cancelled).map((orderSeat) => (
            <Block
              className={orderSeat.Cancelled ? '' : 'route'}
              onClick={
                !orderSeat.Cancelled &&
                (() => {
                  setSelectedTicket(orderSeat);
                  setSection(Section.Notes);
                })
              }
            >
              <BlockHeader>
                <td className="ticket-font" style={{ color: orderSeat.SeatCategoryColour }}>
                  {orderSeat.Group + orderSeat.Name} {orderSeat.SeatCategoryName}
                </td>
                <td className="right">
                  <span style={{ color: orderSeat.CategoryColour }}>
                    {orderSeat.CategoryName} {orderSeat.PriceAsString}
                  </span>
                </td>
              </BlockHeader>
              {orderSeat.Cancelled && <div>This ticket has been cancelled and is no longer valid.</div>}
              {!orderSeat.Cancelled && orderSeat.UserNotes && <div className="notes"> {orderSeat.UserNotes}</div>}
            </Block>
          ))}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <Button
          className="confirm"
          onExecute={() => {
            var win = window.open('/Order/Tickets/' + selectedOrder.Guid, '_blank');
            win.focus();
          }}
          text={'View tickets'}
        />
      </SpacerTable>

      <div className="spacer" />
      <SpacerTable>
        <Button
          onExecute={() => {
            setSection(Section.ShareTickets);
          }}
          text={'Share tickets'}
        />
      </SpacerTable>

      <div className="spacer" />
      <SpacerTable>
        <Button onExecute={() => setSection(Section.Orders)} text={'Back to orders'} />
      </SpacerTable>
    </>
  );
};

export default OrderSection;
