import linq from 'linq';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import SVGPrint from '../../../../svg/SVGPrint';
import AdminStatement from './Statement/AdminStatement';
import { IEvent } from '../../../../interfaces/IEvent';
import SideMenu from '../../../../components/SideMenu';
import SVGOutlineCardPayment from '../../../../svg/outline/SVGOutlineCardPayment';
import SVGOutlineOrder from '../../../../svg/outline/SVGOutlineOrder';
import SVGOutlineMonths from '../../../../svg/outline/SVGOutlineMonths';
import SVGOutlineEvent from '../../../../svg/outline/SVGOutlineEvent';
import SVGOutlineMonth from '../../../../svg/outline/SVGOutlineMonth';
import SVGOutlineEvents from '../../../../svg/outline/SVGOutlineEvents';

interface IProps {
  organisation: IOrganisation;
}

enum StatementMode {
  ByMonths = 'ByMonths',
  ByEvents = 'ByEvents',
}

const AdminStatements: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [moments, setMoments] = useState<Array<moment.Moment>>(null);
  const [selectedMoment, setSelectedMoment] = useState(null);
  const [expandedYears, setExpandedYears] = useState<number[]>([]);
  const [mode, setMode] = useState(StatementMode.ByMonths);
  const [includeNoneOnlineOrders, setIncludeNoneOnlineOrders] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState<IEvent>(null);
  const [events, setEvents] = useState<IEvent[]>(null);

  const { organisation } = props;
  const organisationTag = organisation.OrganisationTag;

  useEffect(() => {
    loadData(false);
  }, []);

  const loadData = (silent) => {
    setLoading(!silent);

    Promise.all([
      AdminApi.request('GET', `/api/LedgerEvents?organisationTag=${organisationTag}`).then((result) => {
        setEvents(result);

        if (result.length > 0) {
          setSelectedEvent(result[0]);
        }
      }),

      AdminApi.request('GET', `/api/LedgerMonths?organisationTag=${organisationTag}`).then((result) => {
        console.log(result);
        const m = linq
          .from(result)
          .orderByDescending((mm: any) => moment.utc(`${mm.Month}/01`).unix())
          .toArray()
          .map((mm: any) => moment.utc(`${mm.Month}/01`));

        setMoments(m);

        if (result.length > 0) {
          setSelectedMoment(m[0]);
          setExpandedYears([m[0].year()]);
        }
      }),
    ])
      .finally(() => {
        setLoading(false);
      })
      .catch((message) => {
        alert(message);
        setLoading(false);
      });
  };

  if (loading || !organisation) return <Loader inline>Loading statements...</Loader>;

  return (
    <>
      <Helmet>
        <title>#{organisationTag} Statements</title>
        <meta name="description" content={`Manage statements for your organisation.`} />
      </Helmet>

      <div className="row">
        <div className="col-sm-3 col-md-2 donotprint">
          <SideMenu
            title="Statements"
            items={[
              {
                text: 'Mode',
                label: true,
                items: [
                  { icon: <SVGOutlineCardPayment />, text: 'Online only', selected: !includeNoneOnlineOrders, onClick: () => setIncludeNoneOnlineOrders(false) },
                  { icon: <SVGOutlineOrder />, text: 'All orders', selected: includeNoneOnlineOrders, onClick: () => setIncludeNoneOnlineOrders(true) },
                ],
              },
              {
                text: 'Group by',
                label: true,
                items: [
                  { icon: <SVGOutlineMonths />, text: 'Months', selected: mode == StatementMode.ByMonths, onClick: () => setMode(StatementMode.ByMonths) },
                  { icon: <SVGOutlineEvents />, text: 'Events', selected: mode == StatementMode.ByEvents, onClick: () => setMode(StatementMode.ByEvents) },
                ],
              },
              ...(mode == StatementMode.ByEvents
                ? [
                    {
                      text: '#' + props.organisation.OrganisationTag.toUpperCase() + ' Events',
                      label: true,
                      items: linq
                        .from(events)
                        .select((event: IEvent) => {
                          return { icon: <SVGOutlineEvent />, text: event.Name, selected: selectedEvent && selectedEvent.Id == event.Id, onClick: () => setSelectedEvent(event) };
                        })
                        .toArray(),
                    },
                  ]
                : linq
                    .from(moments)
                    .groupBy((m) => m.year())
                    .select((group) => {
                      return {
                        icon: <SVGOutlineEvent />,
                        text: group.key(),
                        onClick: () => (expandedYears.includes(group.key()) ? setExpandedYears(expandedYears.filter((e) => e != group.key())) : setExpandedYears([...expandedYears, group.key()])),
                        expanded: expandedYears.includes(group.key()),
                        expandable: true,
                        items: group
                          .select((m: moment.Moment) => {
                            return {
                              icon: <SVGOutlineMonth />,
                              text: m.format('MMMM YYYY'),
                              selected: selectedMoment && selectedMoment.format('YYYY-MM') == m.format('YYYY-MM'),
                              onClick: () => setSelectedMoment(m),
                            };
                          })
                          .toArray(),
                      };
                    })
                    .toArray()),
            ]}
          />
        </div>
        <div className="col-sm-9 col-md-10">
          {selectedMoment ? (
            <>
              <div className="toolbar donotprint">
                {mode == StatementMode.ByMonths && <div className="title">{selectedMoment && selectedMoment.format('MMMM YYYY')} Statement</div>}
                {mode == StatementMode.ByEvents && <div className="title">{selectedEvent && '#' + selectedEvent.EventTag.toUpperCase()} Statement</div>}
                <div className="buttons">
                  <button onClick={() => (window as any).print()}>
                    <SVGPrint />
                    Print statement
                  </button>
                </div>
              </div>

              <AdminStatement
                includeNoneOnlineOrders={includeNoneOnlineOrders}
                showTransactions={true}
                event={mode == StatementMode.ByEvents ? selectedEvent : null}
                monthString={mode == StatementMode.ByMonths ? selectedMoment.format('YYYY-MM') + '-01' : null}
                organisation={organisation}
              />
            </>
          ) : (
            <p>
              <br />
              Please select an available statement.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminStatements;
