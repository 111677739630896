import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="48px" height="48px">
      <defs>
        <clipPath id="clip-1">
          <path
            d="M0,256v-256h256v256h-87.98359c2.40665,-1.28916 4.6361,-2.94016 6.62037,-4.92442l19.4976,-19.4976c3.66106,1.19228 7.30269,1.78841 10.89248,1.78841c6.24649,0 15.5903,-1.89209 24.61661,-10.91839c12.99192,-12.99192 17.13248,-32.50895 10.56201,-49.73215c-1.5681,-4.10169 -5.70867,-11.4303 -10.60089,-16.32251c-4.89222,-4.89222 -12.21435,-9.03278 -16.32251,-10.60089c-5.36524,-2.0476 -11.00263,-3.09085 -16.75666,-3.09085c-12.46058,0 -24.1695,4.84686 -32.96901,13.64637c-10.12138,10.12138 -13.23167,22.67268 -9.11054,35.48964l-19.51703,19.51703c-10.95079,10.95079 -10.95079,28.76365 0,39.71444c1.98508,1.98751 4.21555,3.64056 6.62333,4.9309z"
            id="overlayBgMask"
            fill="none"
          ></path>
        </clipPath>
      </defs>
      <g
        clip-path="url(#clip-1)"
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style={{ mixBlendMode: 'normal' }}
      >
        <g transform="scale(5.33333,5.33333)" fill="#000000">
          <path d="M24.05273,3c-0.18215,-0.0019 -0.36648,0.00525 -0.55273,0.02344c-2.597,0.254 -4.5,2.58922 -4.5,5.19922v6.77734c0,1.105 0.895,2 2,2h6c1.105,0 2,-0.895 2,-2v-7c0,-2.73937 -2.21497,-4.97145 -4.94727,-5zM24,6c1.105,0 2,0.896 2,2c0,1.104 -0.895,2 -2,2c-1.105,0 -2,-0.896 -2,-2c0,-1.104 0.895,-2 2,-2zM7.5,12c-2.4675,0 -4.5,2.0325 -4.5,4.5v21c0,2.4675 2.0325,4.5 4.5,4.5h33c2.4675,0 4.5,-2.0325 4.5,-4.5v-21c0,-2.4675 -2.0325,-4.5 -4.5,-4.5h-9.5v3h9.5c0.8465,0 1.5,0.6535 1.5,1.5v21c0,0.8465 -0.6535,1.5 -1.5,1.5h-33c-0.8465,0 -1.5,-0.6535 -1.5,-1.5v-21c0,-0.8465 0.6535,-1.5 1.5,-1.5h9.5v-3zM15,21c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM26.5,23c-0.54095,-0.00765 -1.04412,0.27656 -1.31683,0.74381c-0.27271,0.46725 -0.27271,1.04514 0,1.51238c0.27271,0.46725 0.77588,0.75146 1.31683,0.74381h10c0.54095,0.00765 1.04412,-0.27656 1.31683,-0.74381c0.27271,-0.46725 0.27271,-1.04514 0,-1.51238c-0.27271,-0.46725 -0.77588,-0.75146 -1.31683,-0.74381zM11.5,29c-0.828,0 -1.5,0.672 -1.5,1.5c0,1.5 0.00039,4.5 4.90039,4.5h0.19922c4.9,0 4.90039,-3 4.90039,-4.5c0,-0.828 -0.672,-1.5 -1.5,-1.5zM26.5,30c-0.54095,-0.00765 -1.04412,0.27656 -1.31683,0.74381c-0.27271,0.46725 -0.27271,1.04514 0,1.51238c0.27271,0.46725 0.77588,0.75146 1.31683,0.74381h10c0.54095,0.00765 1.04412,-0.27656 1.31683,-0.74381c0.27271,-0.46725 0.27271,-1.04514 0,-1.51238c-0.27271,-0.46725 -0.77588,-0.75146 -1.31683,-0.74381z"></path>
        </g>
      </g>
      <g
        fill="#000000"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style={{ mixBlendMode: 'normal' }}
      >
        <g transform="translate(139.66181,155.62156) scale(6.47976,6.47976)" id="overlay">
          <path d="M12.473,8.898c-1.442,1.442 -2.995,1.335 -4.501,0.43c-1.611,1.611 -3.853,3.853 -3.988,3.988c-0.911,0.911 -2.389,0.911 -3.3,0c-0.911,-0.911 -0.911,-2.389 0,-3.3c0.137,-0.138 2.416,-2.417 3.988,-3.988c-0.905,-1.507 -1.012,-3.06 0.43,-4.501c1.5,-1.5 3.685,-1.886 5.547,-1.175l-3.149,3.148l0.5,2.5l2.5,0.5l3.148,-3.148c0.711,1.861 0.325,4.046 -1.175,5.546z"></path>
        </g>
      </g>
    </svg>
  );
}) as FunctionComponent<IProps>;
