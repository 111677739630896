import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 48 48">
      <path d="M12.5,6c-3.6,0-6.5,2.9-6.5,6.5v23c0,3.6,2.9,6.5,6.5,6.5h23c3.6,0,6.5-2.9,6.5-6.5V12.5c0-3.6-2.9-6.5-6.5-6.5H12.5ZM12.5,9h23c2,0,3.5,1.5,3.5,3.5v1.5H9v-1.5c0-2,1.5-3.5,3.5-3.5ZM9,17h30v18.5c0,2-1.5,3.5-3.5,3.5H12.5c-2,0-3.5-1.5-3.5-3.5v-18.5ZM24.1,19.1c-.3,0-.5.2-.6.5l-.7,2h-2.2c-.3,0-.6.2-.6.5,0,.3,0,.6.2.8l1.8,1.4-.6,2c0,.3,0,.6.3.8.2.2.6.2.8,0l1.7-1.3,1.7,1.3c.1,0,.3.1.4.1s.3,0,.4-.1c.2-.2.3-.5.3-.8l-.6-2,1.8-1.4c.2-.2.3-.5.2-.8,0-.3-.4-.5-.6-.5h-2.2l-.7-2c0-.3-.4-.5-.6-.5ZM19,28.9c-.3,0-.5.2-.6.5l-.7,2h-2.2c-.3,0-.6.2-.6.5,0,.3,0,.6.2.8l1.8,1.4-.6,2c0,.3,0,.6.3.8.2.2.6.2.8,0l1.7-1.3,1.7,1.3c.1,0,.3.1.4.1s.3,0,.4-.1c.2-.2.3-.5.3-.8l-.6-2,1.8-1.4c.2-.2.3-.5.2-.8,0-.3-.4-.5-.6-.5h-2.2l-.7-2c0-.3-.4-.5-.6-.5ZM29.1,28.8c-.3,0-.5.2-.6.5l-.7,2h-2.2c-.3,0-.6.2-.6.5,0,.3,0,.6.2.8l1.8,1.4-.6,2c0,.3,0,.6.3.8.2.2.6.2.8,0l1.7-1.3,1.7,1.3c.1,0,.3.1.4.1s.3,0,.4-.1c.2-.2.3-.5.3-.8l-.6-2,1.8-1.4c.2-.2.3-.5.2-.8,0-.3-.4-.5-.6-.5h-2.2l-.7-2c0-.3-.4-.5-.6-.5Z" />
    </svg>
  );
}) as FunctionComponent<IProps>;
